function sortRecords(forms){
    let fatherObj = "", motherObj = "", childrenArr = [];
    forms?.forEach((item, i)=>{
            const isFather =  item?.famStat==="parent" && item?.genderStat === "Male";
            const isMother =  item?.famStat==="parent" && item?.genderStat === "Female";
            const isChild =  item?.famStat?.includes('child');

            if(isFather){fatherObj = item}
            if(isMother){motherObj = item}
            if(isChild){childrenArr.push(item)}
        });

    const sortedForms = {father:fatherObj, mother:motherObj, children:childrenArr};
    return sortedForms
}



function sortFamilyData(forms){
    const {father, mother, children} = sortRecords(forms);
    const sortChildren = sortStrings(children, 'famStat');
    const familyAll = [father, mother, ...sortChildren];
    const res = [];
    for(let i=0; i<familyAll.length; i++){
      let item = familyAll[i];
      if(item.surname){res.push(item)}
    }
    return res
}


const sortStrings=(arr,order)=>{
    var res =[]; 
    if(order==="ARRAY"){
      res =  arr.sort((a, b)=>{
        return a -b
      });
     }else{
        res =  arr.sort((a, b)=>{
          let fa = a[order].toLowerCase(),
              fb = b[order].toLowerCase();
          if(fa < fb){ return -1}
          if(fa > fb){ return 1}
          return  0
      });
     }
 
       return res
}

export default sortRecords;
export {sortFamilyData}