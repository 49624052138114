import React from 'react';
import {TextInput, SelectionTag, Fileupload, 
    RadioButtonsCustom,} from './InputComponents';


const FormRegField =({title, optArr, placeholder, type, value,item, onEdit,
    onChange, required, name, onEditToggle, autoComplete, editToggle, 
    opt1, opt2,})=>{
    
    /*const inuputStyle = `
    form-control 
    w-full
    px-1 sm:px-3
    py-1
    text-xs sm:text-sm
    font-normal
    text-gray-700
    bg-mainColor bg-clip-padding
    border border-solid border-gray-300
    transition
    ease-in-out
    focus:text-gray-700 focus:bg-white focus:border-[cyan] 
    focus:outline-none
    `;*/
    return(
        <div>
            {type === 'opts'?
                <SelectionTag
                 title={title}
                 optArr={optArr} 
                 name={name}
                 onChange={onChange}
                /> :
                type === 'radio'?
                <RadioButtonsCustom
                    title={title}
                    //defSelected={defSelected} 
                    name={name}
                    onChange={onChange} 
                    opt1={opt1}
                    opt2={opt2}  
                /> :
                type === 'file'?
                <Fileupload 
                    //className={inuputStyle}
                    title={title}
                    type={'file'} 
                    placeholder={placeholder}
                    onChange={onChange}
                    required= {"" || required}
                    autoComplete={"" || autoComplete}
                    name="photo"
                    />:
                <TextInput 
                    //className={inuputStyle}
                    title={title}
                    type={type || "text"} 
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    required= {"" || required}
                    autoComplete={"" || autoComplete}
                    name={name}
                    inputStyle="" 
                />
            }
            <label className="hidden form-check-label cursor-pointer font-bold  text-[teal] pl-2"
                onClick={onEditToggle}
                >
                {onEdit===item? editToggle : "Edit"}             
            </label>
        </div>
    );
}

/*
const SelectionTag=({optArr, defSelected, title, name, onChange, isReadOnly})=>{
    const [selected, setSelected] = React.useState(defSelected? defSelected : "");
    const optsArr = optArr || [];
    
    
    function onChangeHandler(e){
      if(!isReadOnly){
        setSelected(e.target.value==="Select" || e.target.value.includes("Select")? "" 
        : e.target.value );
        onChange(e);
      }
    }
    return(
        <>
              <select 
                  className="w-full
                  px-3
                  py-1.5
                  text-xs sm:text-sm
                  font-normal
                  text-gray-700
                  bg-mainColor bg-clip-padding
                  border border-solid border-gray-300
                  transition
                  ease-in-out
                  focus:text-gray-700 focus:bg-white focus:border-[cyan] 
                  focus:outline-none" 
                  value={selected} onChange={onChangeHandler} name={name} >
                {optsArr.map((item,index)=>{
                 return (
                  <option key={`${index}+val`} value={item}>{item}</option>                 
                  );
                })}
              </select>
        </>
    );
  }*/




export default FormRegField;
