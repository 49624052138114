import getPastorCorner from '../../assets/data/pastorCornerMain';
import getPastorCornerLatest1 from '../../assets/data/pastorCornerLatest1';
//import pastorCornerPhotos from '../../assets/pastorCorner/_index';
//import generateRandomBetween from '../../utils/generateRandomBtwn';


function getPastorCornerMessages(){
    const msg1 = getPastorCorner();
    const msg2 = getPastorCornerLatest1();
    const allMsgsManual = [...msg1, ...msg2];
    //const len = pastorCornerPhotos.length;

    const allMsgsManualNew = allMsgsManual.map((item,i)=>{
        //const randomPhoto = generateRandomBetween(0,len-1);
        let msgID = generateMessageID(item.title, item.sn);
        
        return {...item,msgID:msgID, details:{...item.details,}}
    });
    
    const allMsgs = [...allMsgsManualNew];
    const sortedMsg = allMsgs.sort((a, b)=>b.sn - a.sn);
    
    return sortedMsg
}


function generateMessageID(title, sn){
    let msgID = `${title.toLowerCase().replace(/\s{2,}/g, ' ').replace(/ /g, "-")}.static${sn}`;
        msgID = msgID.replace(/[,’'"!]/g, "");

    return msgID;

}





  
  
export default getPastorCornerMessages;
export {generateMessageID}


const onlineData = [
    {sn: '',  _id:'', bibleRef:'', body:'', pray:'',
    likes:[
       { _key:"", postedBy:{
        image:'', userName:''
       }}
    ],
    comments:[
        { _key:"", comment:'', datePosted:"", postedBy:{
         image:'', userName:''
        }}
     ],

}
];