import {infoToConfirmFunction} from './infoToConfirm';


function searchRecordHandler(allData, searchVals){
        const {filteredRes, filteredOthersRes} = filterHandler(allData, searchVals);

        return {filteredRes:filteredRes, filteredOthersRes:filteredOthersRes, 
            beginSearch:true}
    }

    function filterHandler(allData, filterVals){
        const {surname, firstname,} = filterVals;

        const filteredIndv = allData.filter((member, i)=>{
            return member.surname.toLocaleLowerCase() === surname.toLocaleLowerCase() && 
                (member.firstname.toLocaleLowerCase() === firstname.toLocaleLowerCase() ||
                member.othernames.toLocaleLowerCase() === firstname.toLocaleLowerCase())
        });
        const filteredOthers = allData.filter((member, i)=>{
            return member.surname.toLocaleLowerCase() === surname.toLocaleLowerCase() &&
                (member.firstname +" "+member.othernames).toLocaleLowerCase().includes(firstname.toLocaleLowerCase());
        });
        const filteredOthersExceptIndiv = filteredOthers?.filter((item, i)=>{
           return filteredIndv[0]?.toString() !== item?.toString()
        });


        return {filteredRes:filteredIndv, filteredOthersRes: 
            filteredOthersExceptIndiv,}
    }


    function searchParentRecordHandler(seldIndv, allData){
        const {surname, firstname, family} = seldIndv;
        const searchVals = {surname:surname, firstname:firstname};
        //const {filteredRes, filteredOthersRes} = filterHandler(allData, searchVals);

        let parentsObj = {};
        allData?.forEach((item, i)=>{
                const isFather =  item.family ===  family  && item.famStat==="parent" && item.genderStat === "Male";
                const isMother =  item.family ===  family  && item.famStat==="parent" && item.genderStat === "Female";

                if(isFather){parentsObj = {...parentsObj, father:item}}
                if(isMother){parentsObj = {...parentsObj, mother:item}}
            });
        const fatherRes = infoToConfirmFunction(parentsObj.father);
        const motherRes = infoToConfirmFunction(parentsObj.mother);
        //const fat = parentsObj.father? extractInfoToConfirm(parentsObj.father) : {len:0};
        //const mot = parentsObj.mother? extractInfoToConfirm(parentsObj.mother) : {len:0};
        const mostInfo = fatherRes.confirmValsRes.length > motherRes.confirmValsRes.length? 
            {parent:'father', parentData: parentsObj.father, ...fatherRes} : 
            {parent:'mother', parentData: parentsObj.mother, ...motherRes};
        //console.log(mostInfo);
        return mostInfo
    }


    export {searchRecordHandler, searchParentRecordHandler}