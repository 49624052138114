import childrenDay1 from './childrenDay2022-1.jpg';
import childrenDay2 from './childrenDay2022-2.jpg';
import childrenDay3 from './childrenDay2022-3.jpg';
import childrenDay4 from './childrenDay2022-4.jpg';
import childrenDay5 from './childrenDay2022-5.jpg';
import childrenDay6 from './childrenDay2022-6.jpg';
import childrenDay7 from './childrenDay2022-7.jpg';
import childrenDay8 from './childrenDay2022-8.jpg';
import childrenDay9 from './childrenDay2022-9.jpg';
import childrenDay10 from './childrenDay2022-10.jpg';
import childrenDay11 from './childrenDay2022-11.jpg';
import childrenDay12 from './childrenDay2022-12.jpg';
import childrenDay13 from './childrenDay2022-13.jpg';
import childrenDay14 from './childrenDay2022-14.jpg';
import childrenDay15 from './childrenDay2022-15.jpg';
import childrenDay16 from './childrenDay2022-16.jpg';
import childrenDay17 from './childrenDay2022-17.jpg';
import childrenDay18 from './childrenDay2022-18.jpg';
import childrenDay19 from './childrenDay2022-19.jpg';
import childrenDay20 from './childrenDay2022-20.jpg';
import childrenDay21 from './childrenDay2022-21.jpg';
import childrenDay22 from './childrenDay2022-22.jpg';
import childrenDay23 from './childrenDay2022-23.jpg';
import childrenDay24 from './childrenDay2022-24.jpg';
import childrenDay24b from './childrenDay2022-24b.jpg';
import childrenDay25 from './childrenDay2022-25.jpg';
import childrenDay26 from './childrenDay2022-26.jpg';
import childrenDay27 from './childrenDay2022-27.jpg';
import childrenDay28 from './childrenDay2022-28.jpg';
import childrenDay29 from './childrenDay2022-29.jpg';
import childrenDay30 from './childrenDay2022-30.jpg';


const childrenDay2022 = [
    {sn:1, src:childrenDay1,title:'Children Day Celebration 2022', desc:'Children Day Celebration 2022', 
        bCol:'blue', date:'2022-05-29'},
    {sn:2, src:childrenDay2,},{sn:3, src:childrenDay3,},{sn:4, src:childrenDay4,},
    {sn:5, src:childrenDay5,},{sn:6, src:childrenDay6,},{sn:7, src:childrenDay7,},
    {sn:8, src:childrenDay8,},{sn:9, src:childrenDay9,},{sn:10, src:childrenDay10,},
    {sn:11, src:childrenDay11,},{sn:12, src:childrenDay12,},{sn:13, src:childrenDay13,},
    {sn:14, src:childrenDay14,},{sn:15, src:childrenDay15,},{sn:16, src:childrenDay16,},
    {sn:17, src:childrenDay17,},{sn:18, src:childrenDay18,},{sn:19, src:childrenDay19,},
    {sn:20, src:childrenDay20,},{sn:21, src:childrenDay21,},{sn:22, src:childrenDay22,},
    {sn:23, src:childrenDay23,},{sn:24, src:childrenDay24,},{sn:25, src:childrenDay24b,},
    {sn:26, src:childrenDay25,},
    {sn:27, src:childrenDay26,},{sn:28, src:childrenDay27,},{sn:29, src:childrenDay28,},
    {sn:30, src:childrenDay29,},{sn:31, src:childrenDay30,}
    ];


    export default childrenDay2022