
const TextInput =({placeholder, value,autoComplete, name, 
        onChangeHandler, maxLength, required,type, inputStyle})=>{

    return(
        <input type={type} 
            placeholder={placeholder}
            className={`px-3 py-1 border border-solid border-blue-400 ${inputStyle}`} 
            required={required || ""} 
            autoComplete={autoComplete || ""}
            value={value}
            name={name}
            onChange={onChangeHandler}
            maxLength={maxLength}
        />
    );
}

const Button1 =({title, onClick, btnStyle, myStyle})=>{

    return(
        <div className={`px-4 py-1 w-fit  
            ${myStyle? myStyle: 'hover:bg-blue-300 active:bg-gray-200 cursor-pointer bg-blue-500 text-white' }
            ${btnStyle}`}
            onClick={onClick}>
            {title}
        </div>
    );
}


export {TextInput, Button1}