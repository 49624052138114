import React from 'react';
import PhotoGallery from '../components/gallery/Index';
import Footer from '../components/footer/Index';
import logo from '../assets/gofamintLogo.png';
import SEO from '../components/SEO';
import ScrollToTopButton from '../components/pcorner/components/ScrollToTopButton';

const Media = () => {
  const header = "text-center md:px-10 text-xl text-[mediumblue] sm:text-4xl font-bold pt-10";
  React.useEffect(()=>{
    //Auto scroll to top on load
      window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
      });
  },[]);
  
  return (
    <div className="bg-mainColor flex flex-col">
      <SEO title="Media - GOFAMINT PS Ogba" />
      <div className='justify-center hidden'>
       <img src={logo} alt="logo" className="w-[80px] xsmc:w-[120px] smc:w-[150px] md:w-[220px]" />
      </div>
      <div className="w-full text-gray-600 flex  justify-center p-10 pt-0 flex-col relative">
          <h2 className={header}>GOFAMINT Pacesetters Ogba Photo Gallery<br/>
          </h2>
      </div>
      <PhotoGallery/>
      <ScrollToTopButton/>
      <Footer/>
    </div>
  );
};

export default Media;