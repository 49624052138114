import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  userRecords:[],
  accessdeniedList:[],
  accesspermitList:[],
  userFetchedData:[],
  userFetchedDataOthers:[],
  membersStat:[],
  pdfReportData:[],
  userRecordsOthers:[],
  userSearchedRecords:[],
  currentStep:{step:0, about:'', details:''},
  saveMyRecords:false,
  myInbox:[],
  myInboxObj:{},
}

export const userSubReducer = createSlice({
  name: 'userSub',
  initialState,
  
  reducers: {
    
    disUserRecords: (state, action) => {
      state.userRecords = action.payload
      },
    disUserRecordsOthers: (state, action) => {
      state.userRecordsOthers = action.payload
      },

    disUserSearchedRecords: (state, action) => {
      state.userSearchedRecords = action.payload
      },
    disAccessdeniedList: (state, action) => {
        state.accessdeniedList = action.payload
     },
    disAccesspermitList: (state, action) => {
      state.accesspermitList = action.payload
   },
   disUserFetchedData: (state, action) => {
    state.userFetchedData = action.payload
  },
  disUserFetchedDataOthers: (state, action) => {
    state.userFetchedDataOthers = action.payload
    },
    disMemberStat: (state, action) => {
      state.membersStat = action.payload
    },
    disPdfReportData: (state, action) => {
      state.pdfReportData = action.payload
    },
    disCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },
    disSaveMyRecords: (state, action) => {
      state.saveMyRecords = action.payload
    },
    disMyInbox: (state, action) => {
      state.myInbox = action.payload
    },
    disMyInboxObj: (state, action) => {
      state.myInboxObj = action.payload
    },
    disLogoutUserSubReset: (state) => {
      state.userRecords=[];
      state.accessdeniedList=[];
      state.accesspermitList=[];
      state.userFetchedData=[];
      state.userFetchedDataOthers=[];
      state.membersStat=[];
      state.pdfReportData=[];
      state.userRecordsOthers=[];
      state.userSearchedRecords=[];
      state.myInbox=[];
      state.myInboxObj={};
    },
  },
})


export const { disUserRecords, disUserRecordsOthers, disAccessdeniedList, disAccesspermitList,
  disUserFetchedData, disUserFetchedDataOthers, disMemberStat, disPdfReportData,
 disUserSearchedRecords, disCurrentStep, disSaveMyRecords, disMyInbox,
disMyInboxObj, disLogoutUserSubReset } = userSubReducer.actions

  
export default userSubReducer.reducer