import React from "react";
import CustomizeModal from "../CustomizeModal";
import EnlargeView from "./EnlargeView";
import AlbumPhotosCustomised from "./PhotoAlbumCustomised";
import PulseLoader from "react-spinners/PulseLoader";
import PhotoViewerForPhone from "./PhotoViewerForPhone";
import useWindowDimensions from '../../utils/useWindowDimension';



const AlbumMain =({photos, loading, title,details, headerBCol})=>{
    const [show, setShow] = React.useState(false);
    const [showPhoneModal, setShowPhoneModal] = React.useState(false);
    const [seltdIndex, setSeltdIndex] = React.useState(0);
    const [photoIsClicked, setPhotoIsClicked] = React.useState(false);
    const {width, weight} = useWindowDimensions();
    
    const [photo, setPhoto] = React.useState({});
    const [photosArr, setPhotosArr] = React.useState([]);
    const isBigScreen = width > 600;

    //const [title, setTitle] = React.useState('');
    

    function handleSelectedPhoto(i){
        setPhotoIsClicked(true);
        if(isBigScreen){
            setSeltdIndex(i);
            setPhoto(photos[i]);
            setShow(true);
            setShowPhoneModal(false);
        }else{
            setPhotosArr(photos);
            setShowPhoneModal(true);
            setShow(false);
        }
    }
        
    return(
        <div className="w-full flex justify-center flex-col items-center mb-5">
            <h1 className={`font-archivo w-[95%] p-1 py-2 text-sm md:text-2xl lg:text-3xl font-bold flex px-3 text-left rounded-t-lg
                    `}
                style={{color:headerBCol || 'purple'}}
                >
                {title}
            </h1>
            
            <div className="bg-gray-300 min-h-[250px] max-h-[50vw] overflow-y-auto w-[95%]
            ">  
            { loading?
             <PulseLoader loading={loading} size={80} color="#36d7b7" />:
             <AlbumPhotosCustomised photosGal={photos}  handleSelectedPhoto={handleSelectedPhoto}/>
            }
              {isBigScreen?
                <CustomizeModal show={show}>
                    <EnlargeView closeModal={()=>{setShow(false); setPhotoIsClicked(false)}}  
                        photoSrc={photo.src} photosGal={photos} seltdIndex={seltdIndex}
                        handleSelectedPhoto={handleSelectedPhoto}
                        title={details}/>
                </CustomizeModal>
                :                
                <PhotoViewerForPhone show={showPhoneModal} photosGal={photosArr}
                    closeModal={()=>{setShowPhoneModal(false); setPhotoIsClicked(false)}}
                    photoIsClicked={photoIsClicked}
                    details={details}/>
                    }
            </div>
        </div>
    );
}


export default AlbumMain;