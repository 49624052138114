import React, {useEffect, useState} from 'react';
import OnlineOffPublishTemplate from './OnlineOffPublishTemplate';
import OnlineOffManualFiling from './OnlineOffManualFiling';
import { client } from '../../../../../client';
import useOnline from 'use-online';

const OnlineOffPublishManual = ({resetUploads, }) => {
    const [allPayersData, setAllPayersData] = useState({});
    const [payerDataDisplay, setPayerDataDisplay] = useState([]);
    const isOnline = useOnline();
    const [programOpts, setProgramOpts] = React.useState([]);

    function getProgramsHandler(){
        fetchQuery(queryOnlineOfferingSettings).then((res)=>{
            setProgramOpts(res[0].arrayofstrings);
            //console.log('res[0].arrayofstrings')
        });
    }
    

    function viewAllPayersHandler(){
        fetchPayersData().then((res)=>{
            const sorted = res.sort((a,b)=>{ return a.sn - b.sn})
            const payersData = sorted.reduce((res, item)=>{
                return {...res, names:[...res?.names, item.name], familyID:{...res?.familyID, [item.name]:item.familyID}}
            },{names:[], familyID:{}});
            setAllPayersData(payersData);
        });
   }
   function resetUploadsHandler(){
        resetUploads();
        setPayerDataDisplay([]);
   }
   

   useEffect(()=>{
    viewAllPayersHandler();
   },[isOnline]);

   
    return (
    <div className="mb-3 p-3 mt-5 border-2 border-solid border-teal-700"
        >
        <p className="bg-[indigo] text-white pb-2 underline font-bold p-3">
            Publish Online Offering- Manually</p>

        <OnlineOffManualFiling
          opts={allPayersData?.names}
          allPayersData={allPayersData}
          payerDataDisplay={payerDataDisplay}
          setPayerDataDisplay={setPayerDataDisplay}/>
        <br/>
        <OnlineOffPublishTemplate
            prosUploadsForPublish={payerDataDisplay}
            resetUploads={resetUploadsHandler}
            hideTitles
            optsProg={programOpts}
            getPrograms={getProgramsHandler}
            opts={['OFFERING', 'REMITTANCE',]}/>
    </div>
  )
}

export default OnlineOffPublishManual;



async function fetchPayersData(){    
    const response = await client.fetch(queryPayers); //id = query
    return response;
}

const queryPayers =
`
    *[_type == "onlineOfferingPayers"] | order(date desc){
        name,
        familyID,
        sn,
    }
 `;

 async function fetchQuery(query){    
    const response = await client.fetch(query); //id = query
    return response;
}

const queryOnlineOfferingSettings =
   `
       *[_type == "combination" && title== "Online Offering Settings"] | order(date desc){
        arrayofstrings,
        description,
        title
       }
`;