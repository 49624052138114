import React, {useState} from 'react';
import { Button } from '../components/Components';
import { client } from '../../../../client'; 
//import { deleteUsedAssetsQuery } from '../../admin/membersData/queries';
//import { BsInfo } from 'react-icons/bs';

const SanityDocQuery = () => {
  const [queryDocs, setQueryDocs] = useState([]);
  //const [unrefAssetsQueried, setUnrefAssetsQueried] = useState(false);
  const [docName, setDocName] = useState('');
  const [docTypeName, setDocTypeName] = useState('');
  const [docTypeType, setDocTypeType] = useState('');
  //const [indexToDelete, setIndexToDelete] = useState('');
  const [selectedIdToDel, setSelectedIdToDel] = useState('');
  //const [docTypeNameDelete, setDocTypeNameDelete] = useState('');
  const [info, setInfo] = useState({show:false, msg:''});
  //const [isDeleting, setIsDeleting] = useState({show:false, msg:''});

  

  const queryDocHandler=()=>{
    if(docName){
        const query = `
            *[ _type == '${docName}' ]{
                _id,}`;
        setInfo({show:true, msg:'Generating data, please wait...'});
        const fetchProgRes = fetchContents(query);
        fetchProgRes.then((res)=>{
        setQueryDocs(res);
        setInfo({show:false, msg:''});
    }); 
    }else{
        setInfo({show:true, msg:'Please, set the document name!'})
    } 
  }

  const queryDocTypeHandler=()=>{
    if(docTypeName && docTypeType){
        const query = `
            *[ _type == '${docTypeName}' && _id== '${docTypeType}']{
                _id,}`;
        setInfo({show:true, msg:'Generating data, please wait...'});
        const fetchProgRes = fetchContents(query);
        fetchProgRes.then((res)=>{
        setQueryDocs(res);
        setInfo({show:false, msg:''});
    });
    }else{
        setInfo({show:true, msg:'Please, set the document name and type'})
    } 
  }

  async function fetchContents(query) {
    const response = await client.fetch(query);
    return response;
  }

  function deleteDocHandler(){
    if(selectedIdToDel && docTypeName){
        setInfo({show:true, msg:'Deleting document, please wait...'})
    client
        .delete(selectedIdToDel)
        .then(() => {
            setInfo({show:true, msg:'Document successfully deleted!'});
            setSelectedIdToDel('');
        })
        .catch((err) => {
          setInfo({show:true, msg:'Delete failed: ,'+ err.message});
          setSelectedIdToDel('');
        })

    }else{
        setInfo({show:true, msg:'Please, set the document to be deleted!'})
    }
   }

   React.useEffect(()=>{
    setInfo({show:false, msg:''});
    setSelectedIdToDel('');
   },[docName,docTypeName, docTypeType]);
  return (
    <div className='m-2 border border-blue-800 p-2'>
      <p className='block font-bold p-4 pt-0 text-red-600 underline'>Sanity Document Query</p>
    
    
      <div className='ml-4'>
        <div className='mb-3'>
         <p className='block font-bold text-red-500'>
            {info.show? info.msg : ''}
         </p>
         <div className={`font-bold pb-2 text-[red]`}>
           <p className='underline'>Selected document ID to delete</p>
          <p>
            1. {selectedIdToDel}
          </p>
         </div>
          {
            queryDocs?.map((item,i)=>{

              return(
                <p key={`${i}+unrefs`}
                    className="cursor-pointer"
                    onClick={()=>setSelectedIdToDel(item._id)}>
                 {i+1}. {item._id}
                </p>
              );
            })
          }
        </div>
       <div className='block gap-2'>
         <div className='flex flex-row flex-wrap gap-2 mb-1'>
            <InputText 
                placeholder='Enter document name'
                value={docName}
                onChange={e=>setDocName(e.target.value)}/>
            <Button title='Query Document'
            onClickHandler={queryDocHandler}/>
         </div>
         <div className='flex flex-row flex-wrap gap-2 my-2'>
            <InputText 
                placeholder='Document name'
                value={docTypeName}
                onChange={e=>setDocTypeName(e.target.value)}/>
            <InputText 
                placeholder='Document type'
                value={docTypeType}
                onChange={e=>setDocTypeType(e.target.value)}/>
            <Button title='Query Doc Type'
            onClickHandler={queryDocTypeHandler}/>
         </div>
         <div className='flex flex-row flex-wrap gap-2 my-2'>
            <Button title='Delete Doc Type'
                bgCol="bg-[maroon]"
                bgHov="hover:bg-[red]"
                btnStyle="active:bg-[maroon]"
                onClickHandler={deleteDocHandler}
            />
         </div>
         <div className='mt-3'>
            <Button title='Reset Container'
                bgCol="bg-gray-700"
                btnStyle="active:bg-[maroon]"
                onClickHandler={()=>setQueryDocs([])}
            />
         </div>
          
       </div> 
      </div>

      
    </div>
  )
}

export default SanityDocQuery;



const InputText =({value, onChange, placeholder})=>{

    return(
        <input
            className='border border-solid border-blue-500 pl-3'
            value={value}
            onChange={onChange}
            placeholder={placeholder}
        />
    );
}