
function generateMStatement(el){
    const memberStmt = getProgramsKeys(el);
    return memberStmt
}



function getProgramsKeys(obj){
    let actArr = [];
    var totalSum = 0;
    for (const key in obj) {
        if(key.includes('|')){
            //progKeys.push(key);
            const dateAct = key.split('|');
            const amount = obj[key];
            totalSum = parseFloat(totalSum) + parseFloat(amount);
            const act = {date: dateAct[0], details: dateAct[1], amount, balance: totalSum};
            actArr.push(act);
        }
    }
    const sumTotalLine = {date: '', details: 'TOTAL', amount:totalSum, balance: totalSum};
    //actArr = [...actArr, sumTotalLine];
    const res = {name:obj.name, familyID:obj.familyID, Total:obj.Total, activities:[...actArr, sumTotalLine]}
    const finalRes = getShortActivities(res);
    return finalRes
}

function getShortActivities(obj){
    const fullStmt = obj.activities;
    let actArr = [];
    [...fullStmt].forEach((item, i)=>{
        if(i ===0 || item.amount !== 0){
            actArr.push(item)
        }
    });
    return {...obj, activitiesShort:actArr}
}

export {generateMStatement}

const defs = {familyID:'james.dada.ruth', name:'James',
'2023-01-01|B/fwd from 2022':100,
'2023-01-02|Anointing Service Day-2':300,
'2023-01-03|Victory Day2':300,
'Total':700,
};

/*
TransforM:
{date:'', docID:'', familyID:'', name:'', program:'', type:'',
    '2023-01-01|B/fwd from 2022':0,
    '2023-01-02|Anointing Service Day-2':0,....,
    'Total':0,
}

To:
{date:'', docID:'', familyID:'', name:'', program:'', type:'', Total:0,
   activities: [
        {date: '2023-01-01', details: 'B/fwd from 2022', amount:0, balance:0},
        {date: '2023-01-02', details: 'Anointing Service Day-2' amount:0, balance:0}
}

*/