import React, { useState } from "react";
import { useSelector, } from "react-redux";
import Collapsible from "../../../Collapsible";
import {txtFieldsKeys} from '../../assets/textFieldsMain';
import txtFields from "../../assets/textFields";
import HorizontalLine from "../../../HorizontalLine";
import { getFormField } from '../../assets/textFieldsMain';
import ViewField from "../../components/FormViewField";
import { client } from "../../../../client";

const MemberViewReadOnly =({memberData})=>{
    
    const {memberTableViewAll, adminUser} = useSelector((state)=>state.dev);
    const [formValues, setFormValues] = useState({});
    const [editToggle, setEditToggle] = useState("Edit");
    const [user, setUser] = useState({type:"DEVELOPER"});
    const txtFieldsHidden = getFormField('txtFieldsHidden');
    const txtFieldsHiddenArr = txtFieldsHidden?.fields?.filter((item)=>item !== 'photo');
    const [updating, setUpdating] = useState({msg:"", editing:false});
    

    let onChangeHandler = (e, i, imgSrc) => {
        if(!adminUser){
            handleChange();
            
        }
    function handleChange(){
        setUpdating({...updating, editing:true});
        let newFormValues = {...formValues};
        if(e.target.name==="photo"){
            newFormValues[e.target.name] = `${imgSrc}`;
        }else if(e.target.type==="radioSpec"){
            newFormValues[e.target.name] = e.target.value;
        }else{
            newFormValues[e.target.name] = e.target.value;
        }
        setFormValues(newFormValues);
        }
        //dispatch(disFormDataPosted(newFormValues));
      };
    //console.log(formValues);
    const onSubmitHandler=(e)=>{
        e.preventDefault();
        replaceMultipleFiles(formValues);
        //console.log(formValues);
    }
    const replaceMultipleFiles = async (memberForm) => {
        if(memberForm.surname && updating.editing){
            setUpdating({...updating, msg:'Updating document, please wait....'});
            const doc = memberForm;
            await client.createOrReplace(doc).then(res => {
                setUpdating({msg:'Update successful!'});
                setFormValues({});
            }).catch(e => {
                console.log(e.error)
            });
        }else{
            setUpdating({msg:'No form update was made!', editing:false})
        }
      }
    React.useEffect(()=>{
        setFormValues(memberData);
        setUpdating({msg:'', editing:false})
    },[memberData]);

    const userInfo = formValues.surname? `${formValues.surname} ${formValues.firstname}'s Form` : "Member's Form";
    return(
        <div className=''>
            <form onSubmit={onSubmitHandler}>
                <div>
                    
                    <Collapsible
                        contStyle="my-3 mx-2 sm:mx-9 border-2 border-solid border-teal-700"
                        titleStyle={`${formValues.surname? 'bg-blue-800' : 'bg-teal-600'} text-white`}
                        iconCol="fill-white"
                        iconHoverCol="hover:fill-[yellow]"
                        hideDeleteIcon
                        addedInfo={userInfo}
                        >
                        <div className="flex w-full justify-center items-center mt-1">
                            <div className="w-[100px] h-[100px] rounded-full bg-gray-500 flex justify-center items-center">
                                <img src={formValues.photo || ""} alt="" className="w-[98px] h-[98px] rounded-full"/>
                            </div>
                        </div>
                        <HorizontalLine widths={95} margTop={5} bColor="teal" margBot={5}/>

                        <div className={`py-2 grid grid-cols-1 lg:grid-cols-2`}>
                        {
                        txtFieldsKeys.map((item, index)=>{
                            var fld = txtFields[item];
                            
                            return(

                                  <ViewField item={item} key={`${index}+formIn`} 
                                    title={`${fld['title']}`}
                                    type={'text'}
                                    onChange={onChangeHandler}
                                    name={item}
                                    value={formValues[item]}
                                    //defSelected={formValues.title}
                                    //isReadOnly={(isReadOnly && item==="genderStat") || (isReadOnly && item==="matStat"&& indvForm.matStat==="Married" && indvForm.famStat==="parent")}
                                    //onEdit={onEdit}
                                    //onEditToggle={()=>setOnEditName(item)}
                                    //editToggle={editToggle}
                                    //autoComplete={autoComplete}
                                    //required={requiredFlds}
                                    //optArr={txtFields[item].optArr}
                                />
                                
                            );
                        })
                    }
                        </div>
                        <div className={`${memberTableViewAll? "" : 'hidden'}`}>
                            <div className={`py-2 grid grid-cols-1 lg:grid-cols-2`}>
                                <HorizontalLine bColor="red" margBot={10} margTop={10}/>
                                
                                {
                                    txtFieldsHiddenArr?.map((fld, index)=>{
                                        return(
                                        <ViewField item={fld} key={`${index}+formIn`} 
                                            //type={txtFieldsHidden[fld].type}
                                            type="text"
                                            title={txtFieldsHidden[fld].title}
                                            onChange={onChangeHandler}
                                            name={txtFieldsHidden[fld].name}
                                            placeholder={txtFieldsHidden[fld].pholder}
                                            value={formValues[fld]}
                                            //onEditToggle={()=>setOnEditName(item)}
                                            editToggle={editToggle}
                                        />
                                        );
                                    })
                                }
                            </div>
                            <div className="">
                                <p className="my-2 ml-3 text-[maroon]">
                                    {updating.msg}
                                </p>
                                <button type="button" 
                                    className="bg-blue-600 text-sm py-2 px-8 active:bg-gray-500 hover:bg-[cyan] hover:border-[mediumblue] border-2 border-solid border-[coral] rounded-lg text-white  ml-2"
                                    onClick={onSubmitHandler}>
                                    Update
                                </button>
                                <div className="h-12"></div>
                            </div>
                        </div>
                    </Collapsible>
                </div>                       
            </form>
        </div>
    );
}

export default MemberViewReadOnly;