import React from 'react';
import {BsCloudDownload, BsFillPinAngleFill} from 'react-icons/bs';
import {client} from '../../client';
import pairKeyValue from '../../utils/pairKeyValue';
import { useDispatch, useSelector } from 'react-redux';
import { disFectedPrograms, disFectedControls  } from '../../context/reducers/dev';
//import { useAnimation, motion } from "framer-motion";
//import { useInView } from "react-intersection-observer";
//import useSanityListener from '../../utils/hooks/useSanityUpdates';
//import { upcomingProgQuery,} from '../../utils/queries/queries';
//import bgImage from '../../assets/salvation/salvation2.jpg';
import { useInView } from "framer-motion";


const UpcomingPrograms = () => {
  const {fetchedControls, fetchedPrograms} = useSelector((state)=>state.dev);
    const dispatch = useDispatch();
    const [showPub, setShowPub] = React.useState(false);
    const [showFlyer, setShowFlyer] = React.useState(false);
    //const [fetchedProgram, setFetchedProgram] = React.useState({});
    //const [fetchedControls, setFetchedControls] = React.useState({});
    const [showVideoClip, setShowVideoClip] = React.useState(false);
    const [flyerUrl, setFlyerUrl] = React.useState('');
    const [videoClipUrl, setVideoClipUrl] = React.useState('');
    const [bgImageUrl, setBgImageUrl] = React.useState('');
    const [autoPlay, setAutoPlay] = React.useState(false);
    const [muted, setMuted] = React.useState('');
    const [inviteText, setInviteText] = React.useState({inviteMsg:''});
    const vidRef = React.useRef(null);
    //const controls = useAnimation();
    const ref = React.useRef(null);
    const isInView = useInView(ref, { once: true });

    //console.log(showVideoClip)
    //const { ref, inView, entry } = useInView({
        /* Optional options */
    //    threshold: 0,
    //  });
    //const {fetchedDatas, setFetchedDatas} = useSanityListener(client);

    //console.log(bgImageUrl);
    //console.log(fetchedPrograms)
    async function fetchContents(query) {
      const response = await client.fetch(query);
      // waits until the request completes...
      return response;
    }
    /*async function fetchMultiplContents(query1, query2) {
      const [response1, response2] = await Promise.all([
        client.fetch(query1),
        client.fetch(query2),
      ]);
      return [response1, response2];
    }*/
    React.useEffect(()=>{
      const fetchProgRes = fetchContents(queryProgram);
      fetchProgRes.then((res)=>{
        dispatch(disFectedPrograms(res));
        //setFetchedProgram(res);
        //console.log(res)
      });  

      const fetchControlsRes = fetchContents(queryControl);
      fetchControlsRes.then((res)=>{
          const advertControls = res[0]['advertcontrols'];
          const pairRes = pairKeyValue(advertControls, ['name', 'toggle']);
          dispatch(disFectedControls(pairRes));
          //setFetchedControls(pairRes);
      });

      fetchContents(queryControlOthers).then((res)=>{
          setInviteText(res);
        });  
      
      },[]);
      

    React.useEffect(()=>{
        //Fetched data
        const flyerUrl = fetchedPrograms[0]?.flyer?.asset?.url;
        const videoUrl = fetchedPrograms[0]?.videoclip?.asset?.url;
        const isFlyerUrl = fetchedPrograms[0]?.flyer? true :false;
        const isVideoUrl = fetchedPrograms[0]?.videoclip? true :false;

        const bgImageUrl = fetchedPrograms[1]?.bgImage?.asset?.url;


        setFlyerUrl(flyerUrl);
        setVideoClipUrl(videoUrl);
        setBgImageUrl(bgImageUrl);
        
        //Fetched data- true or false
        const showFlyers = fetchedControls.showflyer;
        const showVideos = fetchedControls.showvideoclip;
        const showConts = fetchedControls.showcontainer;

        //Only show if url is available
        const showFlyerRes = isFlyerUrl? showFlyers : false;
        const showVideoRes = isVideoUrl? showVideos : false;
        
        setShowFlyer(showFlyerRes);
        setShowVideoClip(showVideoRes);
        setShowPub(showFlyerRes || showVideoRes? showConts : false);
        //console.log(showFlyers, showVideos, showConts);    
    },[fetchedControls, fetchedPrograms]);

    const handlePlay = () => {
      vidRef.current.play();
    };
    const handlePause = () => {
      vidRef.current.pause();
    };

  /*React.useEffect(() => {
    if (inView) {
      setMuted('');
    }else{
      setMuted('Muted')
    }
  }, [inView]);*/ 
  //bg-[#444]
  return (
    <div className={`${showPub? 'flex' : 'hidden'} flex-col bg-center bg-cover
        ${fetchedControls.showupcomingprogramtitle? '' :''}`}
        style={{backgroundImage:`url(${flyerUrl})`,
            transform: isInView ? "none" : "translateY(200px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s"
        }}
        ref={ref}>
        <div className='w-full h-full bg-gradient-to-r from-[mediumblue]/80 to-[red]/80 backdrop-blur-[2px]'>
          <div className={`my-3 ml-3 w-fit font-bold flex flex-row  pr-10  py-2 bg-slate-200 text-blue-700 text-center text-lg
                ${fetchedControls.showupcomingprogramtitle? '' :'hidden'}`}>
            <BsFillPinAngleFill size={32} color="#e23c9a" className='mr-5 ml-5'/>  
            <h1 className='text-lg md:text-3xl'>Featured</h1>
          </div>
          <div className={`${showVideoClip? 'lg:grid-cols-2' : ''} grid grid-cols-1 gap-2 
            `}>
              <div className={`${showFlyer? 'flex' : 'hidden'} flex-1 relative justify-center`}>
                  <img 
                      src={flyerUrl} 
                      alt="Upcoming program flyer" 
                      className='flex max-h-[80vh] md:max-h-[90vh]'/>
                  <div className='absolute bottom-12 right-16 bg-gray-500 p-3 rounded-full active:bg-gray-200 hover:bg-blue-700'>
                      <a className='' 
                          href={`${fetchedPrograms[0]?.flyer?.asset?.url}?dl=`}
                          download
                          onClick={e=>e.stopPropagation()}>
                          <BsCloudDownload size={20} color="white"
                              className='cursor-pointer'/>
                      </a>
                  </div>
              </div>
              <div className={`${showVideoClip? 'flex' : 'hidden'} flex-1 relative mt-3 md:mt-0 
                  bg-center bg-contain`}
                  style={{backgroundImage:`url(${bgImageUrl})`}}>
                  <div className='bg-[rgb(120,100,150,0.9)] w-full h-full flex justify-center'
                    >
                    <video
                        src={videoClipUrl}
                        type="video/mp4"
                        loop
                        controls
                        //controls={false}
                        muted={muted}
                        autoPlay
                        //width='700'
                        //height="550"
                        className=" max-h-[80vh] object-contain"
                    />
                  </div>
                  <div className='hidden absolute bottom-12 right-16 bg-gray-500 p-3 rounded-full active:bg-gray-200 hover:bg-blue-700'>
                      
                      <a className='' 
                          href={`${fetchedPrograms[0]?.videoclip?.asset?.url}?dl=`}
                          download
                          onClick={e=>e.stopPropagation()}>
                          <BsCloudDownload size={20} color="white"
                              className='cursor-pointer'/>
                      </a>
                  </div>
              </div>
          </div>
          <h1 className='flex italic pr-6 justify-end text-white text-lg pt-5 pb-4'>
              {inviteText.inviteMsg || '...kindly make it a date with us!'}
          </h1>
        </div>
    </div>
  )
}

export default UpcomingPrograms

const queryControl = `*[ _type == 'controls' && title == 'Show Advert' ]{
  'advertcontrols': togglecontrol[group == 'showadvert']{name,title, toggle},
}
`;

const queryControlOthers = `*[ _type == 'controls' && title == 'Show Advert' ][0]{
  'inviteMsg': keyvaluepair[pair == 'InviteMessage'][0].value,
}
`;

const queryProgram = `*[ _type == 'upcomingPrograme' && name=="UpcomingEvent"] | order(_createdAt desc){
    videoclip{
      asset->{
        url
      }
    },
    flyer{
        asset->{
          url
        }
      },
    bgImage{
        asset->{
          url
        }
      },
  }`;


  

/*
  const queryControls  = `*[ _type == 'controls' && title == 'Show Advert' ] | order(_createdAt desc){
    title,
    'advertcontrol': togglecontrol[group == 'showadvert']{name, toggle},  
  }`;


  
*[ _type == 'nestingtest'] | order(_createdAt desc){
    _id,
    nestimages,
    containerkeyvaluepair,
    'keys': keyvaluepair[].key,
     'keyvalue': keyvaluepair[]{key, value},
    'allUrl':nestimages[].asset->url,
    'photosUrl':nestimages[_type == 'Photos'].asset->url
  }



  *[ _type in ['nestingtest', 'controls']]{
  _type == "controls" =>[]{
      _id,
      title, date, pass
},  
 _type == "nestingtest"=>{
   'nesting': {
     _id,
    nestimages,
    containerkeyvaluepair,
    'keys': keyvaluepair[].key,
     'keyvalue': keyvaluepair[pair == 'maxfilesize']{key, value},
    'allUrl':nestimages[].asset->url,
    'photosUrl':nestimages[_type == 'Photos'].asset->url
   }
   
},

//Get all the Asset files
*[ _type in ["sanity.imageAsset", "sanity.fileAsset"] ]{
  _id, 
  originalFilename,
  size,
  "refs": count(*[ references(^._id) ])
 }
 //Delete unused Assets: https://www.sanity.io/schemas/delete-unused-assets-2ef651b5
  
 
 //Filter array
 *[ _type == 'controls' && title=="Show Advert" ]{
  _id, title,
   togglecontrol[group=="showadvert"]
  }
 
 */