import React, {useState} from 'react';
import { client } from '../../../../../client';
import { TextField, ViewField, ViewSelection } from './Components';
import { createOfferingDoc, queryOfferingsCount, } from './_Modules';


const OnlineOffPublishTemplate = ({prosUploadsForPublish, resetUploads, type,opts,hideTitles,optsProg, getPrograms,
        }) => {
    
    const [date, setDate] = useState('');
    const [program, setProgram] = useState('');
    const [dateDetails, setDateDetails] = useState('');
    const [total, setTotal] = useState('');
    const [selected, setSelected] = useState('');
    //const [selectedProg, setSelectedProg] = useState('');
    const [warning, setWarning] = useState({show:false, msg:''});
    const [readForPublish, setReadForPublish] = useState({ready:false, msg:'Uploaded data not yet ready to be published!'});

    function publishHandler(e){
        e.preventDefault();  //2023-01-05|Anointing Service Day-5'
        if(readForPublish.ready){
            const prosUploadsNew = prosUploadsForPublish?.map(item=> {return {...item, amount:parseInt(item.amount)}});
            const dataToPublish = {date, program, type:selected, dateDetails, total,
                        data:prosUploadsNew};
            // console.log(dataToPublish)
            setReadForPublish({...readForPublish.msg, msg:'Publishing data, please wait...'});
            publishDoc(dataToPublish);
        }else{
            setWarning({show:true, msg:'Please, set all the fields!'})
        }
    }

    const publishDoc = async (newDoc) => {
            await client.fetch(queryOfferingsCount).then((res)=>{
                const no = res.count || 0;
                    const doc = createOfferingDoc(newDoc, no);
                    publish(doc);
            });
        
        function publish(doc){
            client.createIfNotExists(doc).then(res => {
                setReadForPublish({ready:false, msg:'Uploaded document successfully published!.'});
                resetUploads();
                resetInputs();
            }).catch(e => {
                console.log(e.error)
            });
        }
        }
    
    function resetInputs(){
        setDate(''); setTotal(''); setProgram(''); 
        setSelected(''); setDateDetails('');
    }
    React.useEffect(()=>{
        setProgram('');
    },[selected]);

    React.useEffect(()=>{
        if(date || program){
        const dateDetail = `${date}|${program}`;
        setDateDetails(dateDetail);
        }
    },[date, program,]);

    React.useEffect(()=>{
        setWarning({show:false, msg:""})
        },[prosUploadsForPublish, selected,]);
    

    React.useEffect(()=>{
        if(date && program !=="Select Type" && program !=="" && total && prosUploadsForPublish.length && 
            (selected==="OFFERING" || selected ==="REMITTANCE")){
                setReadForPublish({ready:true, msg:'Uploaded data ready to be published.'})
            }else{
                setReadForPublish({ready:false, msg:'Uploaded data not yet ready to be published!'})
            }
        },[date, program, total, selected, prosUploadsForPublish]);
    
    //console.log(selected)
    return(
        <div>
            <p className={`underline font-bold text-red-900 mb-3 ${hideTitles? 'hidden':''}`}>Publish online offering - {type}</p>
            <p className={`mb-2 ${readForPublish.ready? 'text-green-500' : 'text-red-500'}`}>{readForPublish.msg}</p>
            <form onSubmit={publishHandler}>
                <div className='gap-2 grid grid-cols-1 mdc:grid-cols-2 xl:grid-cols-3'>
                    <TextField title="Date" pholder="Enter date" required type='date'
                        value={date} onChange={e=>setDate(e.target.value)}/>
                    {selected==='REMITTANCE'?
                    <TextField title="Program" pholder="Enter total amount" required type='text'
                        value={program} onChange={e=>setProgram(e.target.value)}/>
                    :<ViewSelection title="Program" setSelected={setProgram}
                        opts={optsProg} clickable onClick={getPrograms}/>
                    }
                    <TextField title="Total" pholder="Enter total amount" required type='number'
                        value={total} onChange={e=>setTotal(e.target.value)}/>
                    <ViewSelection title="Type" setSelected={setSelected} opts={opts}/>
                    <ViewField title="DateDetails" pholder="Enter program" value={dateDetails}/>
                    <ViewField title="Data" pholder="Data is ready to publish" value={prosUploadsForPublish.length? 'Data uploaded' : "No data"}/>
                </div>
                <p className='text-red-500'>{warning.msg}</p>
                <input
                    type='submit' value="Submit"
                    className={`my-3 ${readForPublish.ready? 'bg-blue-800 hover:bg-sky-500 accent-indigo-500': 'bg-stone-300'} text-white py-2 px-5 border border-gray-500 border-solid`}/>
            </form>
    </div>
  )
}

export default OnlineOffPublishTemplate;