import React from 'react';
import MemberStatement from './components/MemberStatement';
import useOnline from 'use-online';
import { BsWifiOff } from 'react-icons/bs';


const OnlineOfferingIndex = () => {
  const isOnline = useOnline();  
  
  if(!isOnline){
    return (
      <div className='text-red-700 flex justify-center pt-[60px]'>
          <BsWifiOff size={30} color="red" className='mr-3 inline-block'/>
          Connection error!. Please, check your network.
      </div>
    );
  }
  return (
    <div>
        <MemberStatement/>
    </div>
  )
}

export default OnlineOfferingIndex