import dramaEvening1 from './DramaEvening2017-1.jpg';
import dramaEvening2 from './DramaEvening2017-2.jpg';
import dramaEvening3 from './DramaEvening2017-3.jpg';
import dramaEvening4 from './DramaEvening2017-4.jpg';
import dramaEvening5 from './DramaEvening2017-5.jpg';
import dramaEvening6 from './DramaEvening2017-6.jpg';
import dramaEvening7 from './DramaEvening2017-7.jpg';
import dramaEvening8 from './DramaEvening2017-8.jpg';
import dramaEvening9 from './DramaEvening2017-9.jpg';
import dramaEvening10 from './DramaEvening2017-10.jpg';
import dramaEvening11 from './DramaEvening2017-11.jpg';
import dramaEvening12 from './DramaEvening2017-12.jpg';
import dramaEvening13 from './DramaEvening2017-13.jpg';
import dramaEvening14 from './DramaEvening2017-14.jpg';
import dramaEvening15 from './DramaEvening2017-15.jpg';
import dramaEvening16 from './DramaEvening2017-16.jpg';
import dramaEvening17 from './DramaEvening2017-17.jpg';
import dramaEvening18 from './DramaEvening2017-18.jpg';
import dramaEvening19 from './DramaEvening2017-19.jpg';
import dramaEvening20 from './DramaEvening2017-20.jpg';
import dramaEvening21 from './DramaEvening2017-21.jpg';
import dramaEvening22 from './DramaEvening2017-22.jpg';
import dramaEvening23 from './DramaEvening2017-23.jpg';


const dramaEvening2017 = [
      {sn:1, src:dramaEvening1,title:'Drama Evening 2017', desc:"Drama Evening titled 'The Ark' held on 24 July 2017", 
            bCol:'blue', date:'2017-07-24'},
      {sn:2, src:dramaEvening2,},{sn:3, src:dramaEvening3,},{sn:4, src:dramaEvening4,},
      {sn:5, src:dramaEvening5,},{sn:6, src:dramaEvening6,},{sn:7, src:dramaEvening7,},
      {sn:8, src:dramaEvening8,},{sn:9, src:dramaEvening9,},{sn:10, src:dramaEvening10,},
      {sn:11, src:dramaEvening11,},{sn:12, src:dramaEvening12,},{sn:13, src:dramaEvening13,},
      {sn:14, src:dramaEvening14,},{sn:15, src:dramaEvening15,},{sn:16, src:dramaEvening16,},
      {sn:17, src:dramaEvening17,},{sn:18, src:dramaEvening18,},{sn:19, src:dramaEvening19,},
      {sn:20, src:dramaEvening20,},{sn:21, src:dramaEvening21,},{sn:22, src:dramaEvening22,},
      {sn:23, src:dramaEvening23,}
      ];
    
    
export default dramaEvening2017