import getBirthDay from "../../../../utils/getBirthDay";

function createMemberDoc(form) {
    const {
        loginID,
        sn,
        title,
        surname,
        firstname,
        othernames,
        addressRes,
        dob,
        genderStat,
        email,
        phoneNo,
        occupation,
        addressOff,
        school,
        instiStat,
        memberStat,
        matStat,
        wedAnn,
        batStat,
        ageGroup,
        membership,
    
        select,
        edit,
        photo,
        family,
        famStat,
        id,
        info,
        filledBy,
        filledDate,
        editedBy,
        accesspermit,
        accessdenied,
        notification,
        notifToggle,     } = form;
    
        const doc = {
            _id: `${id}.no${sn}`,
            _type: 'members',
            surname,
            loginID,
            sn,
            title,
            
            firstname,
            othernames,
            addressRes,
            dob,
            birthday:getBirthDay(dob),
            genderStat,
            email,
            phoneNo,
            occupation,
            addressOff,
            school,
            instiStat,
            memberStat,
            matStat,
            wedAnn,
            wedAnns:getBirthDay(wedAnn),
            batStat,
            ageGroup,
            membership,
        
            select:false,
            edit:false,
            //photo:'https://',
            family,
            famStat,
            id:`${id}.no${sn}`,
            info,
            filledBy,
            filledDate,
            editedBy,
            accesspermit,
            accessdenied,
            notification,
            notifToggle:false,
        }  
    return doc
  }


function deleteUsedAssetsQuery(){
  const query = `
  *[ _type in ["sanity.imageAsset", "sanity.fileAsset"] ]
  {_id, "refs": count(*[ references(^._id) ])}
  [ refs == 0 ]
  ._id
`
return query
}


  export {createMemberDoc, deleteUsedAssetsQuery}
  