function getConfirmValsSchema(){
    const confirmValsSchema = {
        'dob':{name:'dob', value:'',type:"text", pholder:"Date of birth in digits- MM/DD"},
        'wedAnn':{name:'wedAnn', value:'',type:"text", pholder:"Wedding Annv in digits- MM/DD"},
        'email':{name:'email', value:'',type:"email", pholder:"Email"},
        'phoneNo':{name:'phoneNo', value:'',type:"tel", pholder:"Phone number"},

    }
return confirmValsSchema
}


export default getConfirmValsSchema