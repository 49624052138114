import React from "react";
import { useNavigate } from "react-router-dom";


const Error =()=>{
    const navigate = useNavigate();
    function onClickHandler(){
        navigate("/");
    }


    return(
          <div className="error-page-cont">
            <h3>Error Page: 404!</h3>
            
            <button  className="w3-button w3-border w3-hover-red"
            onClick={onClickHandler}>Home</button>
          </div>
    );
}


export default Error


