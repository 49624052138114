import React from 'react'
import HorizontalLine from '../HorizontalLine';
import { BsFillPersonFill } from 'react-icons/bs';

const Main =({form, print, hideFamily, reportTitle, filteredDataObj})=>{
    const colStyle = `grid grid-cols-1 sm:grid-cols-2`;
    

    if(!form){return}
    const userTitle = form?.title?.includes('Pastor')? "Pastor" : form?.title; 
    const formHeader = filteredDataObj?.familySort? `${form?.surname?.toUpperCase()}'S FAMILY` 
        : `${userTitle} ${form?.surname}'s Form`;
    //console.log([form, print, hideFamily]);
    return(
        <div className=''>
            <h1 className={`${hideFamily? 'hidden' : ''} font-bold text-blue-800 text-xl pl-5 underline`}>
                {formHeader}
            </h1>
            <div className='flex justify-center'>
                <div className='w-[100px] h-[100px] border border-gray-500 flex justify-center items-center rounded-full'>
                    {   form?.photo?
                        <img src={form?.photo || ""} alt="myPicture" className="w-[90px] h-[90px] rounded-full"/> 
                        :<BsFillPersonFill size={'90%'} color="silver"/>}
                </div>
            </div>
            <div className='z-[20]'>
                <div className={colStyle}>
                    <Filed label="Title" name={form.title} print={print}/>
                    <Filed label="Surname" name={form.surname} print={print}/>
                    <Filed label="Firstname" name={form.firstname} print={print}/>
                    <Filed label="Othernames" name={form.othernames} print={print}/>
                    <Filed label="Gender" name={form.genderStat} print={print}/>
                    <Filed label="Date of Birth" name={getDateStrings(form.dob)} print={print}/>
                    <Filed label="Phone number" name={form.phoneNo} print={print}/>
                    <Filed label="Marital Status" name={form.matStat} print={print}/>
                </div>
                <Filed label="Email" name={form.email} print={print}/>
                <Filed label="Residential Address" name={form.addressRes} print={print}/>
                <div className={colStyle}>
                    <Filed label="Baptismal Status" name={form.batStat} print={print}/>
                    <Filed label="Occupation" name={form.occupation} print={print}/>
                    <Filed label="Membership Status" name={form.memberStat} print={print}/>
                    <Filed label="School" name={form.school} print={print}/>
                </div>
                    <Filed label="Educational Qualification" name={form.instiStat} print={print}/>
                    <Filed label="Office Address" name={form.addressOff} print={print}/>
                <div className={colStyle}> 
                    <Filed label="Wedding Anniversary" name={form.wedAnns} print={print}/>
                </div>
            </div>
        </div>
    );
}


const Spouse =({form, childComp, print, reportTitle, filteredDataObj})=>{
    const userTitle = form?.title?.includes('Pastor')? "Pastor" : form?.title; 
    const formHeaderSpouse = filteredDataObj?.familySort? 'Spouse Information' : `${userTitle} ${form?.surname}'s Form`;
    const formHeaderChild = filteredDataObj?.familySort? 'Children Information' : `Form Data`;
    //console.log(form)
    return(
        <div className=''>
            <div className={`${form? '' : 'hidden'}`}>
                <HorizontalLine widths={90} margTop={40} margBot={10}/>
                <h1 className='font-bold text-blue-800 text-xl pl-5 '>
                    {formHeaderSpouse}
                </h1>
                <Main form={form} hideFamily/>
            </div>
            <div className={`${childComp? '' : 'hidden'}`}>
                <h1 className='font-bold text-blue-800 text-xl pl-5 pt-12'>
                {formHeaderChild}
                </h1>
                <HorizontalLine widths={90} margTop={15} margBot={20}/>
            </div>
        </div>
    );
}

const Child =({form, index, print, reportTitle})=>{
    const colStyle = `grid grid-cols-1 sm:grid-cols-2`;
    return(
        <div className=''>
            <div className='z-[20]'>
                {index === 1? "" : <HorizontalLine widths={90} margTop={40} margBot={10}/>}
                <h1 className=' text-xl pl-5'>{index}.</h1>
                <div className={colStyle}>
                    <div className='hidden sm:block'>
                        <Filed label="Title" name={form.title} print={print}/>
                        <Filed label="Surname" name={form.surname} print={print}/>
                    </div>
                    <div className='flex justify-center'>
                        <div className='w-[100px] h-[100px] border border-gray-500 flex justify-center items-center rounded-full'>
                            {   form?.photo?
                                <img src={form?.photo || ""} alt="myPicture" className="w-[90px] h-[90px] rounded-full"/> 
                                :<BsFillPersonFill size={'90%'} color="silver"/>}
                        </div>
                    </div>
                    <div className='block sm:hidden'>
                        <Filed label="Title" name={form.title} print={print}/>
                        <Filed label="Surname" name={form.surname} print={print}/>
                    </div>
                    <Filed label="Firstname" name={form.firstname} print={print}/>
                    <Filed label="Othernames" name={form.othernames} print={print}/>
                    <Filed label="Gender" name={form.genderStat} print={print}/>
                    <Filed label="Date of Birth" name={getDateStrings(form.dob)} print={print}/>
                </div>
                <Filed label="Email" name={form.email} print={print}/>
                <Filed label="Residential Address" name={form.addressRes} print={print}/>
                <div className={colStyle}>
                    <Filed label="Phone number" name={form.phoneNo} print={print}/>
                    <Filed label="Occupation" name={form.occupation} print={print}/>
                    <Filed label="Membership Status" name={form.memberStat} print={print}/>
                    <Filed label="School" name={form.school} print={print}/>
                    
                </div>
                <Filed label="Educational Qualification" name={form.instiStat} print={print}/>
                <div className={colStyle}>
                    <Filed label="Baptismal Status" name={form.batStat} print={print}/>
                    <Filed label="Marital Status" name={form.matStat} print={print}/>
                </div>
            </div>
        </div>
    );
}


const Filed =({label, name, print})=>{
    //text-xs sm:text-[2vw] mdc:text-lg
    const defLabStyle = `pr-2 px-3 text-xs sm:text-sm mdc:text-base 
    sm:whitespace-nowrap font-bold pt-[3px] text-[maroon]
        `;
    const printLabStyle = `pr-2 text-2xl w-fit font-bold pt-[3px] text-[maroon]
        whitespace-nowrap
        `;
    const defNameStyle = `bg-[#e5e5e5] text-xs sm:text-sm mdc:text-base w-[100%] pb-[2px] px-3 
        border-b-2 border-dashed border-black`;

    const printNameStyle = `bg-[#e5e5e5] pb-[2px] 
        border-b-2 border-dashed border-black w-full`;


    return(
        <div className={`${print? 'mx-3 text-xl' : 'text-lg sm:pb-3'} flex flex-row m-2`}>
            <p className={print? printLabStyle : defLabStyle}>
                {label}:
            </p>
            <p className={print? printNameStyle : defNameStyle}>
                {name}
            </p>
        </div>
    );
}

function getDateStrings(date){
   let res =  new Date(`${date}`).toDateString().replace('1900', ', ****');
   return res
}
export {Main, Spouse, Child}