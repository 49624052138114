import { createSlice } from '@reduxjs/toolkit';
//import { txtFieldsEmptyArr } from '../../components/userpage/textFieldsMain';

//const defaultForm = txtFieldsEmptyArr;

const initialState = {
  showSidebar:true,
  seltdTab: [0,0,{name:'Home',title:'RECORDABOUTHOME'}], //[parentTabIndex, selfIndex, {name:'Home',title:'RECORDABOUTHOME'}],
  formDataPosted:[{}],
  formDataState:{submitted:false, },
  snackbar:{bColor:'red', msg:'Show Snackbar', duration:3000, show:false},
  tableData:{display: [], report:[],},
  allData:[],
  freshRegistration:{start:false},
  celebrantsData:{birthday:[], wedAnns:[]},
  celebrants:{birthday:{month:[], today:[]}, wedAnns:{month:[], today:[]},},
  celebBirthday:{month:[], today:[]}, 
  celebWedAnns: {month:[], today:[]},
  updateRecord:{startUpdate:false,user:'', recordToUpdateCount:0, recordToUpdate:[]},
  confirmIndv:{clicked:false},
  userRecords:[],
}

export const userReducer = createSlice({
  name: 'user',
  initialState,
  
  reducers: {
    disShowSidebar: (state, action) => {
      state.showSidebar = action.payload
    },
    disSeltdTab: (state, action) => {
      state.seltdTab = action.payload
    },
    disFormDataPosted: (state,action) =>{
      state.formDataPosted = action.payload
    },
    disFormDataState: (state,action) =>{
      state.formDataState = action.payload
    },
    disSnackbar: (state, action) => {
      state.snackbar = action.payload
      },
    disShowSnackbar: (state, action) => {
      state.snackbar = {...state.snackbar, show:action.payload}
      },
    disTableData: (state, action) => {
      state.tableData = action.payload
      },
    disUpdateRecord: (state, action) => {
      state.updateRecord = action.payload
      },
    disFreshRegistration: (state, action) => {
      state.freshRegistration = action.payload
      },
    disConfirmIndv: (state, action) => {
      state.confirmIndv = action.payload
      },
    disAllData: (state, action) => {
      state.allData = action.payload
      },
    disCelebrantsData: (state, action) => {
      state.celebrantsData = action.payload
      },
    disCelebrants: (state, action) => {
      state.celebrants = action.payload
      },
    disCelebrantsBirthday: (state, action) => {
      state.celebBirthday = action.payload
      },
    disCelebrantsWedAnns: (state, action) => {
      state.celebWedAnns = action.payload
      },
    disResetUserState:(state)=>{
      state.formDataPosted =[{}];
      state.formDataState={submitted:false, };
      state.tableData={display: [], report:[],};
      state.allData=[];
      state.freshRegistration={start:false};
      state.updateRecord={startUpdate:false,user:'', recordToUpdateCount:0, recordToUpdate:[]};
      //state.confirmIndv={clicked:false};
      state.userRecords=[];
    }
  },
})


export const { disShowSidebar, disTableData, disSeltdTab, disFormDataPosted, 
  disFormDataState, disSnackbar, disShowSnackbar, disUpdateRecord,
  disFreshRegistration, disConfirmIndv, disAllData, disCelebrantsData,
  disCelebrants, disCelebrantsBirthday, disCelebrantsWedAnns,
  disResetUserState } = userReducer.actions

  
export default userReducer.reducer