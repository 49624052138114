import newness1 from './newness2022-1.jpg';
import newness2 from './newness2022-2.jpg';
import newness3 from './newness2022-3.jpg';
import newness4 from './newness2022-4.jpg';
import newness5 from './newness2022-5.jpg';
import newness6 from './newness2022-6.jpg';
import newness7 from './newness2022-7.jpg';
import newness8 from './newness2022-8.jpg';
import newness9 from './newness2022-9.jpg';
import newness10 from './newness2022-10.jpg';
import newness11 from './newness2022-11.jpg';
import newness12 from './newness2022-12.jpg';
import newness13 from './newness2022-13.jpg';
import newness14 from './newness2022-14.jpg';
import newness15 from './newness2022-15.jpg';
import newness16 from './newness2022-16.jpg';
import newness17 from './newness2022-17.jpg';
import newness18 from './newness2022-18.jpg';
import newness19 from './newness2022-19.jpg';
import newness20 from './newness2022-20.jpg';
import newness21 from './newness2022-21.jpg';
import newness22 from './newness2022-22.jpg';
import newness23 from './newness2022-23.jpg';
import newness24 from './newness2022-24.jpg';
import newness25 from './newness2022-25.jpg';
import newness26 from './newness2022-26.jpg';
import newness27 from './newness2022-27.jpg';
import newness28 from './newness2022-28.jpg';
import newness29 from './newness2022-29.jpg';
import newness30 from './newness2022-30.jpg';
import newness31 from './newness2022-31.jpg';
import newness32 from './newness2022-32.jpg';
import newness33 from './newness2022-33.jpg';
import newness34 from './newness2022-34.jpg';
import newness35 from './newness2022-35.jpg';






const newness2022 = [
      {sn:1, src:newness1,title:'Newness 2022- Elolam', desc:'Newness 2022 [Elolam] held on 26 Dec., 2022', 
        bCol:'purple', date:'2022-12-26'},
      {sn:2, src:newness2,},{sn:3, src:newness3,},{sn:4, src:newness4,},
      {sn:5, src:newness5,},{sn:6, src:newness6,},{sn:7, src:newness7,},
      {sn:8, src:newness8,},{sn:9, src:newness9,},{sn:10, src:newness10,},
      {sn:11, src:newness11,},{sn:12, src:newness12,},{sn:13, src:newness13,},
      {sn:14, src:newness14,},{sn:15, src:newness15,},{sn:16, src:newness16,},
      {sn:17, src:newness17,},{sn:18, src:newness18,},{sn:19, src:newness19,},
      {sn:20, src:newness20,},{sn:21, src:newness21,},{sn:22, src:newness22,},
      {sn:23, src:newness23,},{sn:24, src:newness24,},{sn:25, src:newness25,},
      {sn:26, src:newness26,},{sn:27, src:newness27,},{sn:28, src:newness28,},
      {sn:29, src:newness29,},{sn:30, src:newness30,},{sn:31, src:newness31,},
      {sn:32, src:newness32,},{sn:33, src:newness33,},{sn:34, src:newness34,},
      {sn:35, src:newness35,},
    ];
    

export default newness2022




























