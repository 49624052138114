import React from 'react'
import { client } from '../../../../../client';
import TableTemplate from './TableTemplate';
import { Button1 } from '../../../about/components/InputsComponent';

const MembersInfoTable = () => {
    const [membersDataInfo, setMembersDataInfo] = React.useState([]);    
    async function fetchMembersInfo() {
        await client.fetch(queryMembersInfo).then((res)=>{
          setMembersDataInfo(res);
        }); 
  }

    const header = {surname:'Surname', firstname:'Firstname', memberNo: 'MemberNo', personalID:'PersonalID', familyID:'FamilyID'};
    const queryMembersInfoHandler=()=>{
        if(!membersDataInfo.length){
            fetchMembersInfo();
        }
    }

 return (
    <div className='bg-[#eee] p-3 border border-solid border-gray-600 my-2'>
        <Button1 title="Query Members Info"
          btnStyle={'mb-3 bg-teal-700 active:bg-gray-200'}
          onClick={queryMembersInfoHandler}/>
        <TableTemplate
            tableHeader={Object.values(header)}
            tableBody={membersDataInfo}
            dataKeys={Object.keys(header)}
        />
    </div>
  )
}


export default MembersInfoTable;



const queryMembersInfo = `*[ _type == 'members']{
    'personalID':_id,
    'familyID':family,
    surname, firstname,
    'memberNo': sn,
    }
  `;
  






const TableRow =({member})=>{
    const memberKeys = Object.keys(member);
    return(
        <div className='flex flex-row p-3'>
            {
                memberKeys?.map((item, i)=>{

                    return(
                        <TableCell key={`${i}+memberkey`}
                            title={member[item]}
                            index={i}
                        />
                    );
                })
            }
        </div>
    );
}


const TableCell =({title, index})=>{

    return(
        <div className={`px-3 py-1 
            ${index%2===0? 'bg-teal-500' : 'bg-teal-300'}`}>
            {title}
        </div>
    );
}