import { client } from "../../../client";
import login from "../../../context/reducers/login";


const updateUserDeletedLoginID = async (id) => {
    await  client
        .patch(id) // Document ID to patch
        .set({'loginID':"" , 'accesspermit':''}) // Shallow merge
        .commit() // Perform the patch and return a promise
        .then((updatedDoc) => {
            //console.log(updatedDoc)
        })
        .catch((err) => {
            console.error('Oh no, the update failed: ', err.message)
        })
  }


  const updateUserDeletedAccess = async (id, updatedAccess) => {
    await  client
        .patch(id) // Document ID to patch
        .set({'accesspermit':updatedAccess }) // Shallow merge
        .commit() // Perform the patch and return a promise
        .then((updatedDoc) => {
            //console.log(updatedDoc)
        })
        .catch((err) => {
            console.error('Oh no, the update failed: ', err.message)
        })
  }


  const replaceUserLoginID = async (id, loginID) => {
    await  client
        .patch(id) // Document ID to patch
        .set({'loginID':loginID}) // Shallow merge
        .commit() // Perform the patch and return a promise
        .then((updatedDoc) => {
            //console.log(updatedDoc)
        })
        .catch((err) => {
            console.error('Oh no, the update failed: ', err.message)
        })
  }



  export {updateUserDeletedAccess, updateUserDeletedLoginID, replaceUserLoginID}

