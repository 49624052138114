import React from 'react'
import { ToggleButton, Button } from '../components/Components';
import { client } from '../../../../client'; 
import pairKeyValue from '../../../../utils/pairKeyValue';


const AdvertControl = () => {
  const [toggleCont, setToggleCont] = React.useState(false);
  const [toggleShowFlyer, setToggleShowFlyer] = React.useState(false);
  const [toggleShowVideo, setToggleShowVideo] = React.useState(false);
  const [serverLoaded, setServerLoaded] = React.useState(false);
  const [advertContents, setAdvertContents] = React.useState({flyer:'', video:''});
  const [updateStatus, setUpdateStatus] = React.useState({msg:'', updated:'', show:false});



  function setAdvertHandler(){ 
        client
          .patch('85b40987-e9dc-4d99-a037-339a57672851') // Document ID to patch
          .set({'togglecontrol[0].toggle':toggleCont, 'togglecontrol[1].toggle':toggleShowFlyer, 'togglecontrol[2].toggle':toggleShowVideo})
          .commit()
          .then((updated) => {
            setUpdateStatus({msg:'Update Successful.', updated: true, show:true});            
          })
          .catch((err) => {
            setUpdateStatus({msg:'Update failed.', updated:false, show:true})
          });

    setTimeout(() => {
      setUpdateStatus({show:false, msg:''})
    }, 5000);
  }
  async function fetchContents(query) {
    const response = await client.fetch(query);
    return response;
  }

  React.useEffect(()=>{
    const fetchProgRes = fetchContents(queryProgram);
    fetchProgRes.then((res)=>{
      const flyerUrl = res[0]?.flyer?.asset?.url;
      const videoUrl = res[0]?.videoclip?.asset?.url;
      setAdvertContents({flyer:flyerUrl || 'nil', video:videoUrl||'nil'});
    });  

    const fetchControlsRes = fetchContents(queryControl);
    fetchControlsRes.then((res)=>{
        const advertControls = res[0]['advertcontrols'];
        const pairRes = pairKeyValue(advertControls, ['name', 'toggle']);
        setToggleCont(pairRes.showcontainer);
        setToggleShowFlyer(pairRes.showflyer);
        setToggleShowVideo(pairRes.showvideoclip);
        setServerLoaded(true);
        
    });
    },[]);


  return (
    <div className='m-2 border border-blue-800 p-2'>
        <p className='font-bold text-blue-700'>Server loaded: <span className={`${serverLoaded? 'text-[lime]' : 'text-[red]'}`}>{serverLoaded? 'TRUE' : "FALSE"}</span></p>
        <p className='text-[magenta] font-bold'>NOTE: Toggle the controls and click UPDATE in order to update the server.</p>
        <ToggleButton
         setToggleVal={()=>setToggleCont(!toggleCont)}
         toggleVal={toggleCont}
         title="Advert-container"
         labelStyle="pr-2"
         titleSub={toggleCont? 'ON' : 'OFF'}
         labelSubStyle={toggleCont? 'text-[lime]' : 'gray'}
         />
         <ToggleButton
         setToggleVal={()=>setToggleShowFlyer(!toggleShowFlyer)}
         toggleVal={toggleShowFlyer}
         title="Show-Flyer"
         labelStyle="pr-2"
         titleSub={toggleShowFlyer? 'ON' : 'OFF'}
         labelSubStyle={toggleShowFlyer? 'text-[lime]' : 'gray'}
         />
         <ToggleButton
         setToggleVal={()=>setToggleShowVideo(!toggleShowVideo)}
         toggleVal={toggleShowVideo}
         title="Show-Video"
         labelStyle="pr-2"
         titleSub={toggleShowVideo? 'ON' : 'OFF'}
         labelSubStyle={toggleShowVideo? 'text-[lime]' : 'gray'}
         />
          <p className='flex overflow-x-auto text-red-400'><span className='text-blue-800'>FlyerUrl</span>:{advertContents.flyer}</p> 
          <p className='flex overflow-x-auto text-red-400'><span className='text-blue-800'>VideoUrl</span>:{advertContents.video}</p>
          <p className='font-bold text-blue-700'>Update Status: <span className={`${updateStatus.updated? 'text-[lime]' : 'text-[red]'}`}>
            {updateStatus.msg}</span>
          </p>
         <Button title='Update'
          onClickHandler={setAdvertHandler}/>
          
    </div>
  )
}


export default AdvertControl;



const queryControl = `*[ _type == 'controls' && title == 'Show Advert' ]{
  'advertcontrols': togglecontrol[group == 'showadvert']{name,title, toggle},
}
`;

const queryProgram = `*[ _type == 'upcomingPrograme' ] | order(_createdAt desc){
    videoclip{
      asset->{
        url
      }
    },
    flyer{
        asset->{
          url
        }
      },
  }`;