
function shuffleArray(array){
    const newArr = array.slice();
    for(var i =newArr.length - 1; i > 0; i--){
        var rand = Math.floor(Math.random() * (i+1));
        [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
    }
    return newArr
}

const selectRandomItem =(arr)=>{
    const randItem = arr[Math.floor(Math.random() * arr.length)];
    return randItem
}

//prosData = [{sn:'', question:'',answer:'','1':'','2':'','3':'','4':''},{}]
const createObjFromArrays =(arrs)=>{
    //arr = [['S/N', 'Ques', 'Opt1', 'Opt2', 'Opt3', 'Opt4'], [1. Q1, Opt1, Opt2, Opt3, Opt4],[]]
    //Create an object from each inner array (rows) and push to the questions_Obj
    const memberDatas = [];
    for(var i = 1; i < arrs.length; i++ ){
        const headerRow = arrs[0];
        const memberRow = arrs[i];
        let memberData = {};
        for(let fld =0; fld < headerRow.length; fld ++){
            const cell = memberRow[fld]? memberRow[fld] : ""; //Check for empty cell
            memberData = {...memberData, [headerRow[fld]]: cell,}
        }
        memberDatas.push(memberData)
    }

    return memberDatas
}


const createQuesObjFromManyArrays =(obj)=>{
    //obj = {ques: genQues, opt1: genOpt1, opt2: genOpt2, opt3: genOpt3, opt4: genOpt4}
    const questions_Obj = [];
    for(var i = 0; i < obj.ques.length; i++ ){
        const opts = [obj.opt1[i], obj.opt2[i], obj.opt3[i], obj.opt4[i], ];
        const shuffleOpts = shuffleArray(opts);
        const eachRow = {
            sn: i+1, 
            question: obj.ques[i],
            answer:obj.opt1[i],  
            '1': shuffleOpts[0], '2': shuffleOpts[1], 
            '3': shuffleOpts[2], '4': shuffleOpts[3]};
        questions_Obj.push(eachRow);
    }

    return questions_Obj
}


export {createObjFromArrays, createQuesObjFromManyArrays}