import womenConfe1 from './womenConfe-1.jpg';
import womenConfe2 from './womenConfe-2.jpg';
import womenConfe3 from './womenConfe-3.jpg';
import womenConfe4 from './womenConfe-4.jpg';
import womenConfe5 from './womenConfe-5.jpg';
import womenConfe6 from './womenConfe-6.jpg';
import womenConfe7 from './womenConfe-7.jpg';
import womenConfe8 from './womenConfe-8.jpg';
import womenConfe9 from './womenConfe-9.jpg';
import womenConfe10 from './womenConfe-10.jpg';
import womenConfe11 from './womenConfe-11.jpg';
import womenConfe12 from './womenConfe-12.jpg';
import womenConfe13 from './womenConfe-13.jpg';
import womenConfe14 from './womenConfe-14.jpg';
import womenConfe15 from './womenConfe-15.jpg';
import womenConfe16 from './womenConfe-16.jpg';
import womenConfe17 from './womenConfe-17.jpg';
import womenConfe18 from './womenConfe-18.jpg';
import womenConfe19 from './womenConfe-19.jpg';
import womenConfe20 from './womenConfe-20.jpg';
import womenConfe21 from './womenConfe-21.jpg';
import womenConfe22 from './womenConfe-22.jpg';
import womenConfe23 from './womenConfe-23.jpg';
import womenConfe24 from './womenConfe-24.jpg';
import womenConfe25 from './womenConfe-25.jpg';
import womenConfe26 from './womenConfe-26.jpg';
import womenConfe27 from './womenConfe-27.jpg';
import womenConfe28 from './womenConfe-28.jpg';
import womenConfe29 from './womenConfe-29.jpg';
import womenConfe30 from './womenConfe-30.jpg';
import womenConfe31 from './womenConfe-31.jpg';
import womenConfe32 from './womenConfe-32.jpg';
import womenConfe33 from './womenConfe-33.jpg';
import womenConfe34 from './womenConfe-34.jpg';
import womenConfe35 from './womenConfe-35.jpg';




const womenConfe2022 = [
    {sn:1, src:womenConfe1,title:'Women Conference 2022', desc:'Women Conference 2022- The Shinning Light held between 11th - 13th Nov., 2022', 
        bCol:'goldenrod', date:'2022-11-13'},
    {sn:2, src:womenConfe2,},{sn:3, src:womenConfe3,},{sn:4, src:womenConfe4,},
    {sn:5, src:womenConfe5,},{sn:6, src:womenConfe6,},{sn:7, src:womenConfe7,},
    {sn:8, src:womenConfe8,},{sn:9, src:womenConfe9,},{sn:10, src:womenConfe10,},
    {sn:11, src:womenConfe11,},{sn:12, src:womenConfe12,},{sn:13, src:womenConfe13,},
    {sn:14, src:womenConfe14,},{sn:15, src:womenConfe15,},{sn:16, src:womenConfe16,},
    {sn:17, src:womenConfe17,},{sn:18, src:womenConfe18,},{sn:19, src:womenConfe19,},
    {sn:20, src:womenConfe20,},{sn:21, src:womenConfe21,},{sn:22, src:womenConfe22,},
    {sn:23, src:womenConfe23,},{sn:24, src:womenConfe24,},{sn:25, src:womenConfe25,},
    {sn:26, src:womenConfe26,},
    {sn:27, src:womenConfe27,},{sn:28, src:womenConfe28,},{sn:29, src:womenConfe29,},
    {sn:30, src:womenConfe30,},{sn:31, src:womenConfe31,},
    {sn:32, src:womenConfe32,},{sn:33, src:womenConfe33,},
    {sn:34, src:womenConfe34,},{sn:35, src:womenConfe35,},
    ];



    export default womenConfe2022