import home0 from "./home00.JPG";
import home00 from "./home000.JPG";
import home1 from "./home1.jpg";
import home2 from "./home2.JPG";
import home2b from "./home2b.jpg";
import home3 from "./home3.jpg";
import home4 from "./home4.JPG";
import home5 from "./home5.jpg";
import home6 from "./home6.jpg";
import home7 from "./home7.jpg";
import home8 from "./home8.jpg";
import home9 from "./home9.jpg";
import home9b from "./home9b.jpg";
import home10 from "./home10.JPG";
import home11b from "./home11b.jpg";

import home13a from "./home13a.jpg";
import home13b from "./home13b.jpg";
import home13c from "./home13c.jpg";
import home13d from "./home13d.jpg";
import home14 from "./home14.jpg";
import home15 from "./home15.jpg";



const homeImg = [
    home0, home00, home1, home2, home2b,
    home3, home4, home5, home6, home7,
    home8, home9, home9b, home10, home11b,

    home13a,home13b,home13c,home13d,home14, 
    home15,

]


export default homeImg