import photo1 from './PastorCorner1.jpg';
import photo2 from './PastorCorner2.jpg';
import photo3 from './PastorCorner3.jpg';
import photo4 from './PastorCorner4.jpg';
import photo5 from './PastorCorner5.jpg';
import photo6 from './PastorCorner6.jpg';
import photo7 from './PastorCorner7.jpg';
import photo8 from './PastorCorner8.jpg';
import photo9 from './PastorCorner9.jpg';


const pastorCornerPhotos = [
    {sn:1,src:photo1,name:''},
    {sn:2,src:photo2,name:''},
    {sn:3,src:photo3,name:''},
    {sn:4,src:photo4,name:''},
    {sn:5,src:photo5,name:''},
    {sn:6,src:photo6,name:''},
    {sn:7,src:photo7,name:''},
    {sn:8,src:photo8,name:''},
    {sn:9,src:photo9,name:''},
];

export default pastorCornerPhotos