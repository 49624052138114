
import React, { useEffect, useState} from 'react';
import TableStatementTemplate from './TableStatementTemplate';
import SelectionTag, { DateTag } from './SelectionTag';
import { getShortDate } from '../../../../../dates';
import { getOfferingData } from '../modules/offeringsModule';
import { generateMStatement } from '../modules/generateMStatement';
import { useReactToPrint } from 'react-to-print';
import PrintReport from './print/PrintStatement';
import Collapsible from '../../../../../Collapsible';
import { useSelector, useDispatch } from 'react-redux';
import { disTableDataOff, disLastStmtDateSet, } from '../../../../../../context/reducers/adminReducer';


const IndexMemberStatement = ({seltdMemberFamID, type, }) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState('');
  const [todayDateSet, setTodayDateSet] = useState(false);
  const [seltdMemberStmt, setSeltdMemberStmt] = useState(defStmt);
  const [lastDateManualSet, setLastDateManualSet] = useState(false);
  const [, setLastOpenDateManualSet] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(''); //End
  const [selectedOpenDate, setSelectedOpenDate] = React.useState(''); //Beginning
  const {tableDataOff, onlineOffPayers, onlineOfferings, lastStmtDateSet, lastStmtDate, lastStmtDateYYMMDD} = useSelector((state)=>state.admin);
  const componentRef = React.useRef();
  const [resetDate, setResetDate] = useState(0);

  //const seltdMemberStmtRes = type==='USER'? tableBodyUser : seltdMemberStmt; 
  //console.log([tableBodyUser, seltdMemberStmt])
  
  //const seltdMemberStmtRes = seltdMemberStmt;
  const dateFmt = "2023-05-26";  
  //console.log([selectedOpenDate, selectedDate]);
  const setSelectedOpenDateHandler =(el)=>{
    if(todayDateSet){
      //setSelectedOpenDate(el)
    }
  };
  
  const setFirstDateOfTheMonth=()=>{
    const date = new Date();
    const yr = date.getFullYear();
    const mnth = parseInt(date.getMonth()+1);
    const dt = parseInt(date.getDate());
    //const todayDt = `${yr}-${mnth<10? '0'+mnth : mnth}-${dt<10? '0'+dt : dt}`;
    const fstOfMnth = `${yr}-${mnth<10? '0'+mnth : mnth}-01`;
    setSelectedOpenDate(fstOfMnth)
  }
  
  let tableBody = [];
    if(selected.includes("All")){
      tableBody = seltdMemberStmt?.activities;
    }else{
      tableBody = seltdMemberStmt?.activitiesShort;
    }
 
    const printReportHandler = useReactToPrint({
      pageStyle: pageStyle,
      content: () => componentRef.current,
    });
    function printReportCheck(){
      if(tableBody?.length){
        printReportHandler()
      }
    }
    
    const selectedMemberFamIDHandler=(famID)=>{
      const selectedDataObj = tableDataOff.tableBody.find(item=> item.familyID === famID);
        //console.log(selectedDataObj);
        const mStmt = generateMStatement(selectedDataObj);
        setSeltdMemberStmt(mStmt);
   }
   React.useEffect(()=>{
    setFirstDateOfTheMonth(); //Call on initial render
   },[]);
   React.useEffect(()=>{
    //Auto update Member Statement when TableData changes
    if(seltdMemberFamID?.includes('.') || seltdMemberFamID?.includes('-')){selectedMemberFamIDHandler(seltdMemberFamID)}
  },[tableDataOff.tableBody]);

  React.useEffect(()=>{
    if(seltdMemberFamID?.includes('.') || seltdMemberFamID?.includes('-')){
      selectedMemberFamIDHandler(seltdMemberFamID)
    }else{ //For none online offering payer member
      setSeltdMemberStmt([]) }
  },[seltdMemberFamID]);

  //console.log(onlineOffPayers)
  React.useEffect(()=>{
    if(onlineOffPayers.length){
       //const reportDate = selectedDate? selectedDate : lastStmtDateSet;
       const {tableHeaders, tableBodyKeys, tableBody}= getOfferingData(
          onlineOffPayers, onlineOfferings, selectedDate, selectedOpenDate);
       dispatch(disTableDataOff({tableHeaders, tableBodyKeys, tableBody}));
  
       if(selectedDate){
          const lastDate = getFmtDate(selectedDate);
          dispatch(disLastStmtDateSet(lastDate));
       }else{
        dispatch(disLastStmtDateSet(lastStmtDate));  
       }
    }
  },[selectedDate, selectedOpenDate]);
  
    
    function dispatchLastDocDate(type){
          const tableBody = tableDataOff.tableBody; 
          const lastItem = tableBody?.find(item=> item.name === 'TOTAL');
          const lastDate = getFmtDate(lastItem.lastDate);
          dispatch(disLastStmtDateSet(lastDate));
          
          if(type==="INITSET"){
            dispatch(disLastStmtDateSet(lastDate));
          }
    }
    React.useEffect(()=>{
          if(tableDataOff.tableBody.length){
            if(!lastDateManualSet){
              //Before change the date period
              dispatchLastDocDate('INITSET');  
            }
          }
    },[tableDataOff]);
    
  
   let openingBal = 0;
   if(tableBody?.length){
     const openingBalSearch = tableBody?.find((item)=> item.details.includes('BroughtFwd from 2022') || item.details.includes('Opening balance'));
     openingBal = openingBalSearch?.amount? openingBalSearch.amount : 0; 
   }else{openingBal = 0}
   const closingBal = seltdMemberStmt?.Total? getCurrencyFmt(seltdMemberStmt?.Total) : 0;
   const closingBalInNo = seltdMemberStmt?.Total? seltdMemberStmt?.Total : 0;
   const openingDate = selectedOpenDate? getFmtDate(selectedOpenDate) : 'Sunday, 1 Jan 2023';
  
   return (
    <div className='m-3 border-solid border-teal-600 border-4 pb-5 pt-2 bg-orange-100'>
      <p className='pl-2 font-bold text-[mediumblue] decoration-[red] underline text-lg'>Online Offering Account Statement from  {openingDate} to {lastStmtDateSet}</p>
      <Field title="Family" value={seltdMemberStmt?.name}/>
      <Field title="Title" value="Online Offering"/>
      <Field title="Opening balance" value={openingBal}/>
      <Field title="Closing balance" value={closingBal}/>  
      <p className='text-red-500 pl-2'>Please note that your closing balance is the amount due to be paid!</p>    
      
      <div className='flex flex-row gap-2 flex-wrap'> 
        <DateTag title="Set period start:" setSelectedDate={setSelectedOpenDate}
          setLastDateManualSet={setLastOpenDateManualSet}
          minDate={"2023-01-01" }
          maxDate = {selectedDate || lastStmtDateYYMMDD}
          resetDate={resetDate}
          setTodayDateSet={setTodayDateSet}
          todayDateSet={todayDateSet}
          setFirstDateOfTheMonth={setFirstDateOfTheMonth}/>
        <DateTag title="end:" setSelectedDate={setSelectedDate}
          setLastDateManualSet={setLastDateManualSet}
          minDate={selectedOpenDate || "2023-01-01"}
          maxDate = {lastStmtDateYYMMDD}
          resetDate={resetDate}
          setTodayDateSet={setTodayDateSet}
          todayDateSet={todayDateSet}
          setFirstDateOfTheMonth={setFirstDateOfTheMonth}/>
      </div>
      <p className=" ml-3 font-bold text-white bg-[maroon] active:bg-[red] cursor-pointer px-4 flex w-fit"
          onClick={()=>setResetDate(resetDate+1)}>Reset Date</p>
      
      <SelectionTag setSelected={setSelected}/>
      <p className={`py-1 px-4 w-fit text-white ml-3 mb-3
            ${tableBody?.length? 'bg-[blue] active:bg-slate-400 cursor-pointer' : "bg-[silver]"}`}
        onClick={printReportCheck}>
        Print Statement
      </p>
        
          <Collapsible
                contStyle={`mb-3 mx-3 border-2 border-solid border-teal-700 ${type==="USER"? 'hidden': ''}`}
                titleStyle={` bg-gray-500 text-white pb-2 underline font-bold`}
                iconCol="fill-white"
                iconHoverCol=""
                hideDeleteIcon
                //collapse={collapse}
                addedInfo={`View Print`}
                iconType="plus"
            >
                 <PrintReport
                    reportData={tableBody} print={true} closeReport={true} 
                    ref={componentRef} hidePrintBtn
                    seltdMemberStmtRes={seltdMemberStmt}
                    openingBal={openingBal}
                    closingBal={closingBal}
                    closingBalInNo= {closingBalInNo}
                    openingDate={openingDate}
                    lastDate={lastStmtDateSet}
                    statementType={selected.includes("All")? 'Full Statement': 'Mini Statement'}
                    />
                <br/>
          </Collapsible>
          <Collapsible
                contStyle={`mb-3 mx-3 border-2 border-solid border-teal-700 ${type==="USER"? 'hidden': ''}`}
                titleStyle={` bg-teal-700 text-white pb-2 underline font-bold`}
                iconCol="fill-white"
                iconHoverCol=""
                hideDeleteIcon
                //collapse={collapse}
                addedInfo={`${seltdMemberStmt?.name} Statement`}
                iconType="plus"
            >
            <TableStatementTemplate
                  tableHeader={['Date', 'Details', 'Amount', 'Balance']}
                  tableBody={tableBody} 
                  dataKeys={['date', 'details', 'amount', 'balance']}
                  //selectedMemberDataHandler={selectedMemberDataHandler}
            />
          </Collapsible>
    </div>
  )
}

export default IndexMemberStatement;

const selectedMemberDataHandler=(selData)=>{
  const mStmt = generateMStatement(selData);
  return mStmt
}

const defStmt ={
  name:'',
  data:'',
  activities:[],
  activitiesShort:[],
  Total:'',
}

function getFmtDate(el){
  const dateStrArr = getShortDate(el);
  const dateStr = `${dateStrArr[0]} ${dateStrArr[2]} ${dateStrArr[1]}, ${dateStrArr[3]}`;
  return dateStr;
}

function getCurrencyFmt(el){
  const display = new Intl.NumberFormat('en-US', 
    { style: 'currency', currency: 'USD' }).format(el);
   return display
}

const Field =({title, value})=>{

  return(
    <div className='flex flex-row pl-2'>
      <p className='font-bold text-[navy]'>{title}:  </p>
      <p className='text-[blue]'>{value}</p>
    </div>
  );
};

export {getFmtDate, getCurrencyFmt}

const pageStyle = `
  @page {
    size: 210mm 297mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
