import React from 'react'
//import { client } from '../../../../../../client';
import { Button } from '../../../components/Components';
import { SelectionTagCont } from './components/SelectionTag';
import { txtFldHeader } from '../../../../assets/textFieldsMain';
import { getHeaders } from './components/modules';
import DownloadExcel from '../../components/DownloadExcel';
import UploadExcel from '../../components/UploadExcel';
import Collapsible from '../../../../../Collapsible';


const txtFldKeys = Object.keys(txtFldHeader);
const txtFldsHeaders = getHeaders(txtFldHeader, txtFldKeys);
//const membersDataKeysHeaders = {keys:txtFldKeys, headers:txtFldsHeaders, title:"Membership Data"};
const MembersDataDisplay = ({ memberIDs, uploadedData, setUploadedDataHandler, onChangeHandler,
         selectionCheck, updateFormsHandler, isUpdating, clearUploadHandler}) => {
        
        const header = uploadedData?.data.length? uploadedData?.data[0][0] : '-';
        const field = uploadedData?.data.length? uploadedData?.data[0][1] : '-';
    return (
        <div className='border border-teal-700 p-3'>
            <p>Select data in the server to export to excel</p>
            <div className='flex flex-row flex-wrap'>
            <DownloadExcel contStyle={''}
                title="Download Members IDs"
                fetchedDataMembersIDs={memberIDs}
                //membersDataKeysHeaders={membersDataKeysHeaders}
                />
            </div>
            
            <p className='mt-5 underline text-white font-bold bg-gray-600 p-2'>
                Update Membership Form Data Field
            </p>

            <p className='mt-4 text-[maroon]'>Data to upload must be in excel format and have two columns only. The first row must be the header: members id & the field to update. The field to update must be the same with the Selected Field to Update</p>
            <div className='mb-4 flex flex-row flex-wrap items-center'>
                <UploadExcel forwardUploadedData={setUploadedDataHandler}/>
                <div 
                  className='p-1 bg-[maroon] text-white ml-2 px-3 cursor-pointer active:bg-gray-200'
                onClick={clearUploadHandler}>
                    Clear Upload
                </div>
                
            </div>

            <Collapsible
                contStyle={`my-2 border-2 border-solid 
                    'border-[mediumblue]' : 'border-[gray]'}`}
                titleStyle={`bg-gray-400 text-white`}
                iconCol="fill-white"
                iconType="plus"
                addedInfo={`Uploaded Data  - ${uploadedData?.noError? 'Ready to upload' : 'Not ready for upload'} 
                    [${header} - ${field}]`}
                hideDeleteIcon
                item={[]}
            >
            <div className='w-full'>
                {uploadedData?.data?.map((item, i)=>{
                return(
                    <div key={`${i}+key`} className='w-full'>
                    {i===0?
                    <div key={`${i}+key`} className='flex flex-row w-full bg-sky-600 text-white
                        border-b-2 border-white'>
                        <p className='flex border-r-2 w-12 border-white p-3'>SN</p>
                        <p className='flex flex-1 border-r-2 border-white p-3'>{item[0]}</p>
                        <p className='flex flex-1 p-3'>{item[1]}</p>
                    </div>
                    :<div key={`${i}+key`} className='flex flex-row w-full bg-sky-200
                        border-b-2 border-sky-600'>
                        <p className='flex border-r-2 w-12 border-sky-600 p-2'>{i}</p>
                        <p className='flex flex-1 border-r-2 border-sky-600 p-2'>{item[0]}</p>
                        <p className='flex flex-1 p-2'>{item[1]}</p>
                    </div>
                    }
                    </div>
                );
                })}
            </div>
            </Collapsible>

            <div className={`grid grid-cols-1 md:grid-cols-2 md:gap-5`}>
                <SelectionTagCont
                    title="Select Header to Update"
                    optsArr = {txtFldsHeaders}
                    onChange={e=>onChangeHandler('HEADER', e)}
                    defValue="Select"
                    contStyle={`flex flex-1 ml-3`}
                />
                <SelectionTagCont
                    title="Select Field to Update"
                    optsArr = {txtFldKeys}
                    onChange={e=>onChangeHandler('FIELD', e)}
                    defValue="Select"
                    contStyle={`flex flex-1 ml-3 mt-3 md:mt-0`}
                />
            </div>
            <p className={`${selectionCheck?.isOkay? 'text-lime-500' : 'text-red-500'}`}>{selectionCheck.msg}</p>
            <Button title='Run Form Update'
             btnStyle={'mt-5'}
             bgCol={`${isUpdating? 'bg-stone-300' : 'bg-teal-800'}`}
             onClickHandler={updateFormsHandler}
            />
            
        </div>
  )
}

export default MembersDataDisplay;