import React from "react";
import { client } from "../../../../client";
import { getAdminPassCall } from "../../admin/membersData/modules/module";


const AdminPass =()=>{
    const [passVal, setPassVal] = React.useState('');
    const [sPass, setSPass] = React.useState('');
    const [adminPassVal, setAdminPassVal] = React.useState({admin:'656394', dev:'23AZ45'});

    const len = 9;
    function setAdminPassHandler(){ 
        if(passVal.length ===len){
            const doc = {
            _id:'adminDoc.main',
            _type: 'administrator',
            adminPass:passVal,
            };
            client.createOrReplace(doc).then(response => {
                //console.log(response);
            }).catch(e => {
                //console.log(e);
            });
         }
      }
    function getAdminPassHandler(){
        const res = getAdminPassCall(passVal);
        setAdminPassVal(res);
    }
    function fetchDoc(){
        client.getDocument('adminDoc.main').then((res) => {
            setSPass(res.adminPass);
        });
    }

    React.useEffect(()=>{
        //fetchDoc();
    },[]);

    return(
        <div className=" mt-10">
                <div className="px-2  py-1 border-2 border-blue-700 mb-2 border-solid flex flex-col">
                    <div className="flex-wrap flex flex-row">
                        <h1 className="pr-2">Server:</h1> 
                        <div className="bg-white py-1 px-2">{sPass}</div>
                        <div className="ml-2 bg-blue-900 py-1 px-4 active:bg-slate-300 hover:bg-blue-600 rounded-lg text-white cursor-pointer"
                            onClick={fetchDoc}>
                            Retrieve
                        </div>
                    </div>
                    <div className="flex-wrap flex flex-row mt-2">
                        <input placeholder="Set AdminPass" maxLength={9} type="text" 
                            className="w-28 px-2"
                            value={passVal}
                            onChange={e=>setPassVal(e.target.value)}/>
                        <h1 className="px-1">{passVal.length}-{9}</h1>
                        <span className="ml-2 bg-blue-900 py-1 px-4 active:bg-slate-300 hover:bg-blue-600 rounded-lg text-white cursor-pointer"
                            onClick={setAdminPassHandler}>
                            Set New
                        </span>
                    </div>
                </div>
                <div className="px-2 py-1 border-2 border-blue-700 mb-2 border-solid flex flex-row flex-wrap">
                    <span className="ml-2 bg-blue-900 py-1 px-4 active:bg-slate-300 hover:bg-blue-600 rounded-lg text-white cursor-pointer"
                        onClick={getAdminPassHandler}>
                        Generate AdminPass
                    </span>
                    <span className="bg-white ml-2 py-1 px-2 text-gray-500">
                        {adminPassVal.admin}|{adminPassVal.dev}
                    </span>
                </div>

        </div>
    );
}

export default AdminPass