import React from 'react'
import { BsCloudDownload, BsFileEarmarkPdf, BsFileEarmarkWord } from 'react-icons/bs';
import {FaHandPointRight} from 'react-icons/fa';
import { client } from '../../client';
import { motion } from "framer-motion";
//import { bibleStudyUploadsQuery } from '../../utils/queries/queries';



const Index = () => {
  const [fetchedResources, setFetchedResources] = React.useState([]);

  React.useEffect(()=>{
    client.fetch(query).then((data) => {
          setFetchedResources(data);
      });
  },[]);

  //scrollbar-hide
  return (
    <div className='my-10 w-full bg-slate-200 flex max-h-[500px] min-h-[300px] flex-col 
        md:flex-row '>
        <div className='flex flex-[40%] items-center mb-4 md:mb-0 md:mr-2 p-4 justify-center'>
          <motion.div
            initial={{ scale: 0.5 }}
            animate={{ scale: 1.3 }}
            transition={{ duration: 3, repeat: Infinity }}
            className="square"
          >
            <FaHandPointRight size={38} color="#a40abf" className='mr-3'/>
          </motion.div> 
          <h1 className='text-2xl text-center text-[mediumblue] items-center font-bold flex-row flex'>       
              Resources for your download
          </h1>
        </div>
        
        <div className='flex flex-[60%] bg-slate-100 md:ml-2 p-4  
          overflow-y-auto mx-4 flex-col rounded-lg my-2 items-center '>
            {fetchedResources.length>1?
              fetchedResources?.map((file, i)=>{
                
                return(
                  <Resource topic={file.topic} file={file} key={`${i}+key`}/>
                );
              })
              :
              <div className='text-[maroon] font-bold mt-[100px]'>
                Please, check your internet connection!
              </div>
            }
      

            
        </div>
    </div>
  )
}



const Resource = ({topic, file}) => {
  const docType = file.documenttype === 'pdf'? true : false;
  //console.log(file);

  return (
    <a className='flex  max-h-12 w-[90%] items-center flex-row justify-between 
        border boder-2 border-blue-500 bg-slate-50 hover:bg-sky-400 active:bg-slate-100 p-3 
        rounded-lg my-2 '
        href={`${file?.fileuploaded?.asset?.url}?dl=`}
        download
        onClick={e=>e.stopPropagation()}
        >
        {docType?
          <BsFileEarmarkPdf size={24} color="red"
            className='cursor-pointer'/> :
          <BsFileEarmarkWord size={24} color="mediumblue"
            className='cursor-pointer'/>
            }
          <span className='w-[85%] whitespace-nowrap text-ellipsis overflow-hidden'>{topic}</span>
        <BsCloudDownload size={24} color="#444"
            className='cursor-pointer'/>
    </a>
  )
}


export default Index 


const query  = `*[ _type == 'bibleStudyUploads'] | order(date desc){
  fileuploaded{
      asset->{
        url
      }
    },
  _id,
  date, 
  topic, 
  documentId, 
  uploadedby,
  documenttype, 
}`;