import React, { useEffect, useRef, useState } from "react";
import Comment from "./Comment";


const Comments =({comments})=> {
    let commentNodes = <div className="comment-list">{getComments()}</div>;


      
      /*const sortArray=(arr,order)=>{
        const sorted = arr.sort((a, b)=>{
            return order==="as" || order===""? (a.id - b.id) : (b.id - a.id);
          });
          return sorted
      }*/
      //[{author:'', comment:"", datePosted:"", postedBy:{image:"", userName:""}}]

      /*function addComment(newComment) {
        const date = getPostDate();
        const [name, email, userComment] = newComment;
        const comment = {
          id: comments.length + 1,
          date : date,
          author: name,
          email : email,
          body: userComment,
        };    
    }*/
      
      //console.log(comments)
      function getComments() {    
        return comments?.map((comment, index) => { 
          return (
            <Comment
              key={`${index}+coment`} 
              author={comment?.postedBy.userName} 
              body={comment?.comment} 
              date={comment?.datePosted}
              userImage={comment?.postedBy.image}
              userName={comment?.postedBy.userName}
              />
          ); 
        });
      }
      
      function getCommentsLength(commentCount) {
        if (commentCount === 0 || !commentCount) {
          return 'No comments yet';
        } else if (commentCount === 1) {
          return "1 comment";
        } else {
          return `${commentCount} comments`;
        }
      }

      return(
        <div className="pl-4">
           <h3 className="text-xl">Comments</h3>
            <div className="text-[#dedede] py-4">
                {getCommentsLength(comments?.length)}
            </div>
            <div>
                {commentNodes}
            </div>
        </div>  
      );
    
  } 
  

  
export default Comments;