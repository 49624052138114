const offeringVictory = [
    {docID:1, date:'2023-01-02', program:'Victory Day-1', type:'OFFERING',dateDetails:'2023-01-02|Victory Day-1', total:'',
        data:[
            {name:'Omoniyi', familyID:'omoniyi.francis.esther', amount:500}, 
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Akinmutola', familyID:'akinmutola.john.mercy', amount:200}, 
            {name:'Oladokun', familyID:'oladokun.augustine.oluwakemi', amount:500}, 
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:500}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:200}, 
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:300}, 
            {name:'Miller', familyID:'miller.henry.comfort', amount:400},                       
        ],    
    }, 
    {docID:2, date:'2023-01-03', program:'Victory Day-2', type:'OFFERING',dateDetails:'2023-01-03|Victory Day-2', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Udegbe', familyID:'udegbe.marcus.clara', amount:500}, 
            {name:'Akinmutola', familyID:'akinmutola.john.mercy', amount:300}, 
            {name:'Oladokun', familyID:'oladokun.augustine.oluwakemi', amount:500}, 
            {name:'Marcus', familyID:'marcus.garuba.faith', amount:300}, 
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:500}, 
            {name:'Akinyemi', familyID:'akinyemi.ebenezer.adeyinka', amount:200}, 
            {name:'Chuckwudi', familyID:'chukwudi.samson.single', amount:200}, 
            {name:'Sis Obioha', familyID:'obioha.leonard.florence', amount:300}, 
            {name:'Amoos', familyID:'amoo.segun.mary', amount:200},
        ],    
    },
    {docID:3, date:'2023-01-04', program:'Victory Day-3', type:'OFFERING',dateDetails:'2023-01-04|Victory Day-3', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:500}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:200}, 
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:200}, 
            {name:'Miller', familyID:'miller.henry.comfort', amount:200}, 
            {name:'Amoos', familyID:'amoo.segun.mary', amount:100}, 
            {name:'Ojo', familyID:'ojo.modupe.grace', amount:100}, 
           
        ],    
    },
    {docID:4, date:'2023-01-05', program:'Victory Day-4', type:'OFFERING',dateDetails:'2023-01-05|Victory Day-4', total:'',
        data:[
            {name:'Marcus', familyID:'marcus.garuba.faith', amount:300}, 
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:500}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:500},            
        ],    
    }, 
    {docID:5, date:'2023-01-06', program:'Victory Day-5', type:'OFFERING',dateDetails:'2023-01-06|Victory Day-5', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Akinmutola', familyID:'akinmutola.john.mercy', amount:200}, 
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:500}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:500}, 
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:200}, 
            {name:'Miller', familyID:'miller.henry.comfort', amount:300}, 
            {name:'Akinyemi', familyID:'akinyemi.ebenezer.adeyinka', amount:200}, 
            {name:'Ugbada', familyID:'ugbada.jacob.janet', amount:200}, 
            {name:'Popoola', familyID:'the-Popoolas', amount:200}, 
            {name:'Ariyibi', familyID:'ariyibi-oke.oluwaseyi.opeyemi', amount:200},                       
        ],    
    },
    {docID:6, date:'2023-01-07', program:'Victory Day-6', type:'OFFERING',dateDetails:'2023-01-07|Victory Day-6', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Marcus', familyID:'marcus.garuba.faith', amount:400}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:500}, 
            {name:'Ugbada', familyID:'ugbada.jacob.janet', amount:100}, 
            {name:'Sis Obioha', familyID:'obioha.leonard.florence', amount:100},                        
        ],    
    },
    {docID:7, date:'2023-01-09', program:'Victory Day-8', type:'OFFERING',dateDetails:'2023-01-09|Victory Day-8', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Marcus', familyID:'marcus.garuba.faith', amount:300}, 
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:500}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:500}, 
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:200}, 
            {name:'Miller', familyID:'miller.henry.comfort', amount:200}, 
            {name:'Sis Obioha', familyID:'obioha.leonard.florence', amount:200},                                    
        ],    
    },
    {docID:8, date:'2023-01-10', program:'Victory Day-9', type:'OFFERING',dateDetails:'2023-01-10|Victory Day-9', total:'',
        data:[
            {name:'Oladokun', familyID:'oladokun.augustine.oluwakemi', amount:200},
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:200},                                     
        ],    
    }, 
    {docID:9, date:'2023-01-11', program:'Victory Day-10', type:'OFFERING',dateDetails:'2023-01-11|Victory Day-10', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:500}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:300}, 
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:200}, 
            {name:'Miller', familyID:'miller.henry.comfort', amount:400},                                   
        ],    
    },
    {docID:10, date:'2023-01-12', program:'Victory Day-11', type:'OFFERING',dateDetails:'2023-01-12|Victory Day-11', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Oladokun', familyID:'oladokun.augustine.oluwakemi', amount:200},
            {name:'Marcus', familyID:'marcus.garuba.faith', amount:300}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:500},  
            {name:'Chukwudi', familyID:'chukwudi.samson.single', amount:400},
            {name:'Adelabi', familyID:'the-Adelabis', amount:200},                                     
        ],    
    },
    {docID:11, date:'2023-01-13', program:'Victory Day-12', type:'OFFERING',dateDetails:'2023-01-13|Victory Day-12', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:500},  
            {name:'Miller', familyID:'miller.henry.comfort', amount:200}, 
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:200},
            {name:'Popoola', familyID:'the-Popoolas', amount:200}, 
            {name:'Chukwudi', familyID:'chukwudi.samson.single', amount:200},                                  
        ],    
    },
    {docID:12, date:'2023-01-14', program:'Victory Day-13', type:'OFFERING',dateDetails:'2023-01-14|Victory Day-13', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Marcus', familyID:'marcus.garuba.faith', amount:200}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:400},  
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:300},
            {name:'Adelabi', familyID:'the-Adelabis', amount:200},                                     
        ],    
    },
    {docID:13, date:'2023-01-16', program:'Victory Day-15', type:'OFFERING',dateDetails:'2023-01-16|Victory Day-15', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000},  
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:200},  
            {name: 'Bro Oloko', familyID: 'oloko.olusegun.single', amount:200},
            {name: 'Bro Chuckwudi', familyID: 'chukwudi.samson.single', amount:200},                                     
        ],    
    },
    {docID:14, date:'2023-01-17', program:'Victory Day-16', type:'OFFERING',dateDetails:'2023-01-17|Victory Day-16', total:'',
        data:[
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:200},  
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:200},
            {name:'Miller', familyID:'miller.henry.comfort', amount:200},
        ],    
    },
    {docID:15, date:'2023-01-18', program:'Victory Day-17', type:'OFFERING',dateDetails:'2023-01-18|Victory Day-17', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Oladokun', familyID:'oladokun.augustine.oluwakemi', amount:500},
            {name:'Akinmutola', familyID:'akinmutola.john.mercy', amount:200}, 
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:500}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:200},  
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:300},
            {name: 'Bro Oloko', familyID: 'oloko.olusegun.single', amount:200}, 
            {name:'Popoola', familyID:'the-Popoolas', amount:200},                                   
        ],    
    },
    {docID:16, date:'2023-01-19', program:'Victory Day-18', type:'OFFERING',dateDetails:'2023-01-19|Victory Day-18', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:200}, 
            {name:'Adelabi', familyID:'the-Adelabis', amount:200},                                  
        ],    
    }, 
    {docID:17, date:'2023-01-20', program:'Victory Day-19', type:'OFFERING',dateDetails:'2023-01-20|Victory Day-19', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Oladokun', familyID:'oladokun.augustine.oluwakemi', amount:500},
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:200}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:200},  
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:200},
            {name:'Miller', familyID:'miller.henry.comfort', amount:300},                                   
        ],    
    },  
    {docID:18, date:'2023-01-21', program:'Victory Day-20', type:'OFFERING',dateDetails:'2023-01-21|Victory Day-20', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:500}, 
            {name:'Oladokun', familyID:'oladokun.augustine.oluwakemi', amount:500},
            {name:'Udegbe', familyID:'udegbe.marcus.clara', amount:200}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:200},  
            {name:'Miller', familyID:'miller.henry.comfort', amount:400},
            {name:'Chuckwidi', familyID:'chukwudi.samson.single', amount:200},
            {name:'Sis Obioha', familyID:'obioha.leonard.florence', amount:100},
            {name:'Ikenebomhe', familyID:'oseykael.ikenebomhe.single', amount:200},                                   
        ],    
    }, 
    {docID:19, date:'2023-01-23', program:'Victory Day-22', type:'OFFERING',dateDetails:'2023-01-23|Victory Day-22', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:700},
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:200},  
            {name:'Chuckwidi', familyID:'chukwudi.samson.single', amount:500},
            {name:'Miller', familyID:'miller.henry.comfort', amount:200},                                
        ],    
    },
    {docID:19, date:'2023-01-24', program:'Victory Day-23', type:'OFFERING',dateDetails:'2023-01-24|Victory Day-23', total:'',
        data:[
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:200},  
            {name:'Miller', familyID:'miller.henry.comfort', amount:300}, 
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:200}, 
            {name:'Sis Obioha', familyID:'obioha.leonard.florence', amount:200},                                 
        ],    
    }, 
    {docID:20, date:'2023-01-25', program:'Victory Day-24', type:'OFFERING',dateDetails:'2023-01-25|Victory Day-24', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Akinmutola', familyID:'akinmutola.john.mercy', amount:300}, 
            {name:'Marcus', familyID:'marcus.garuba.faith', amount:200}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:200},  
            {name:'Miller', familyID:'miller.henry.comfort', amount:200}, 
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:200}, 
            {name:'Otun', familyID:'otun.aanuoluwaposimi.single', amount:100},                                  
        ],    
    },
    {docID:21, date:'2023-01-26', program:'Victory Day-25', type:'OFFERING',dateDetails:'2023-01-26|Victory Day-25', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Oladokun', familyID:'oladokun.augustine.oluwakemi', amount:500},
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:1100},
            {name:'Miller', familyID:'miller.henry.comfort', amount:200}, 
            {name:'Popoola', familyID:'the-Popoolas', amount:200}, 
            {name:'Sis Obioha', familyID:'obioha.leonard.florence', amount:200},                                   
        ],    
    },
    {docID:22, date:'2023-01-27', program:'Victory Day-26', type:'OFFERING',dateDetails:'2023-01-27|Victory Day-26', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:400},  
            {name:'Miller', familyID:'miller.henry.comfort', amount:200}, 
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:200},                                    
        ],    
    },
    {docID:23, date:'2023-01-28', program:'Victory Day-27', type:'OFFERING',dateDetails:'2023-01-28|Victory Day-27', total:'',
        data:[
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:500},  
            {name:'Oladokun', familyID:'oladokun.augustine.oluwakemi', amount:500},                              
        ],    
    },
    {docID:24, date:'2023-01-30', program:'Victory Day-29', type:'OFFERING',dateDetails:'2023-01-30|Victory Day-29', total:'',
    data:[
        {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
        {name:'Marcus', familyID:'marcus.garuba.faith', amount:300}, 
        {name:'Aare', familyID:'aare.lasisi.folasade', amount:500},  
        {name:'Popoola', familyID:'the-Popoolas', amount:200}, 
        {name:'Chuckwidi', familyID:'chukwudi.samson.single', amount:500},                                  
     ],    
    }, 
    {docID:25, date:'2023-01-31', program:'Victory Day-30', type:'OFFERING',dateDetails:'2023-01-31|Victory Day-30', total:'',
    data:[
        {name:'Oladokun', familyID:'oladokun.augustine.oluwakemi', amount:500},
        {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:500}, 
        {name: 'Bro Oloko', familyID: 'oloko.olusegun.single', amount:200},                              
     ],    
    },         
];





export default offeringVictory