import React from 'react'
//import { HiOutlineMail, HiOutlineMailOpen } from 'react-icons/hi';

const NonCollapsible = ({children,childrenSub, hideDeleteIcon, contStyle, 
    iconHoverCol, disableDelete, removeForm,titleStyle, addedInfo,
    showCake,
    }) => {

  
  //const mailUnread = <HiOutlineMail size={22} color="red" className='inline-block uppercase -right-1 -top-3 bg-white'/>;
  //const mailOpen = <HiOutlineMailOpen size={22} color="gray" className='inline-block uppercase -right-1 -top-2 bg-white'/>;
      
  return (
    <div className={`h-auto bg-slate-100 ${contStyle}`}
       >
        <div className={`px-3 py-2 text-sm sm:text-base flex justify-between ${titleStyle}`}>
            <div className='flex cursor-pointer'>
                <h1 className=''>{addedInfo}</h1>
            </div>
        </div>
        <div className=''>
            <div>
                {children}
            </div>
        </div>
    </div>
  )
}

export default NonCollapsible