

const queryPayers  = `{'offeringPayers':*[ _type == 'onlineOfferingPayers'] | order(sn asc){
    sn, name, familyID   
  },
  'offerings':*[ _type == "onlineOffering"]{
    _id, docID, 
     date, program, type, total, 
     dateDetails,
     data[]{amount, 'familyID':onlinePayer._ref}
  }
  }`;

function getPayersQuery(){
    return queryPayers
}

const queryOfferings = `
  *[ _type == "onlineOffering"]{
      _id, docID, 
       date, program, type, total, 
       dateDetails,
       data[]{amount, 'familyID':onlinePayer._ref}
  }
`;


export {getPayersQuery}