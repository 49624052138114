import React from 'react';
//import TableTemplate from '../../../controlPanel/membersQuery/components/TableTemplate';
import TableSpecialTemplate from './TableSpecialTemplate';
import IndexMemberStatement from './memberStatement/_Index';
import { useSelector, useDispatch} from 'react-redux';
import { BsWifiOff } from 'react-icons/bs';
import useOnline from 'use-online';
import {  disRefreshCtrl } from '../../../../../context/reducers/adminReducer';


const OnlineOffering = () => {
 const dispatch = useDispatch();
 const isOnline = useOnline();
 const [seltdMemberFamID, setSeltdMemberFamID] = React.useState('a');
 //const [refreshingData, setRefreshingData] = React.useState(false);
 const {tableDataOff, } = useSelector((state)=>state.admin);

  //console.log(tableDataOff)
  const totalAmount = tableDataOff?.tableBody.filter(item=>item.name === 'TOTAL')[0]?.Total;
  const totalAmountFmt = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalAmount || 0);

  if(!isOnline){
    return (
      <div className='text-red-700 flex justify-center pt-[60px]'>
          <BsWifiOff size={30} color="red" className='mr-3 inline-block'/>
          Connection error!. Please, check your network.
      </div>
    );
  }
  return (
    <div>
        <IndexMemberStatement
            seltdMemberFamID={seltdMemberFamID}
             //selectedDate={selectedDate}
            //setSelectedDate={setSelectedDate}
          />
        <div className='flex flex-row gap-2 flex-wrap'>
          <p className='ml-3 mb-4 bg-red-800 text-white py-1 px-3 active:bg-red-500 cursor-pointer w-fit'
            onClick={()=> setSeltdMemberFamID('aa')}>
            Clear Selection
          </p>
          <p className='ml-3 mb-4 bg-green-800 text-white py-1 px-3 active:bg-green-500 cursor-pointer w-fit'
            onClick={()=>dispatch(disRefreshCtrl())}>
            Refresh TableData
          </p>
          <p className='text-red-500 hidden'>{'Refreshing table data, please wait...'}</p>
        </div>
        <p className='text-[maroon]'>Total outstanding: {totalAmountFmt}</p>
        <TableSpecialTemplate
            tableHeader={[...tableDataOff.tableHeaders]}
            tableBody={[...tableDataOff.tableBody]} 
            dataKeys={[...tableDataOff.tableBodyKeys]}
            selectedMemberFamID={setSeltdMemberFamID}
        />
        
    </div>
  )
}

export default OnlineOffering;