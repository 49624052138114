import React from 'react';
import TablePrint from './TablePrint';
import FooterIndex from '../../../../../record/onlineOffering/components/StatementFooter';


const PrintReport = React.forwardRef((props, ref) => {
   
    return (
        <div className={`bg-[#eee] overflow-auto m-3 mt-12 p-2`}
         ref={ref}>
            <div className={`flex justify-center flex-col items-center py-4 font-bold bg-gradient-to-b from-blue-400 to-blue-200 `}>
                <p className={`${props.print? 'text-3xl' : 'text-xl'} text-center text-[navy]`}>The Gospel Faith Mission International</p>
                <p className={`${props.print? 'text-xl' : 'text-lg'}  text-center text-[maroon]`}>Pacesetters Assembly, Ogba District Headquarters</p>
                <p className={`${props.print? 'text-lg' : ''}  text-center `}>7 Odekunle Street, Off Oluyemi Erinoso Street, Behind County Hospital</p>
                <p className={`${props.print? 'text-lg' : ''}  text-center`}>Aguda, Ogba, Lagos</p>
                <p className={`${props.print? 'text-xl' : 'text-lg'}  text-center text-[#a22da2] pt-3`}>Online Offering Statement</p>
            </div>
            <div className='pl-[5%] mb-3 text-lg'>
                <p className='pl-2 font-bold text-[maroon] underline'>Online Offering Statement from  {props.openingDate} to {props.lastDate}</p>
                <Field title="Family" value={props.seltdMemberStmtRes?.name}/>
                <Field title="Opening balance" value={props.openingBal}/>
                <Field title={`Closing balance ${parseInt(props.closingBalInNo)> 0? '(Outstanding amount to be paid)' : ""}`}
                    value={`${props.closingBal} ${parseInt(props.closingBalInNo)>0? 'Dr' : parseInt(props.closingBalInNo) <0? 'Cr' : ''}`} />
                <Field title="Statement Type" value={props.statementType}/>
                {parseInt(props.closingBalInNo) > 0?
                  <>
                    <Field title="Pay to this Account" value="The Gospel Faith Mission, Ogba"/>
                    <Field title="Bank" value="Access Bank- 0019824192"/>
                  </>
                  :<></>
                  }  
            </div>
            <div className='text-lg'>
                <TablePrint
                     tableHeader={['Date', 'Details', 'Amount', 'Balance']}
                     tableBody={props.reportData} 
                     dataKeys={['date', 'details', 'amount', 'balance']}/>
            </div>
            <FooterIndex/>
        </div>
  )
});



export default PrintReport;




const Field =({title, value})=>{

    return(
      <div className='flex flex-row pl-2'>
        <p className='font-bold text-[navy]'>{title}:  </p>
        <p className='text-[blue]'>{value}</p>
      </div>
    );
  }