const offeringHBreakthrough = [
    {docID:1, date:'2023-01-02', program:'Anointing Service Day-2', type:'OFFERING',dateDetails:'2023-01-02|Anointing Service Day-2', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:2000}, 
            {name:'Miller', familyID:'miller.henry.comfort', amount:300}, 
            {name:'Akinyemi', familyID:'akinyemi.ebenezer.adeyinka', amount:200}, 
            {name:'Popoola', familyID:'the-Popoolas', amount:300},            
        ],    
    },
    {docID:2, date:'2023-01-03', program:'Anointing Service Day-3', type:'OFFERING',dateDetails:'2023-01-03|Anointing Service Day-3', total:'',
        data:[
            {name:'Omoniyi', familyID:'omoniyi.francis.esther', amount:500}, 
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:500}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:200}, 
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:300}, 
            {name:'Akinyemi', familyID:'akinyemi.ebenezer.adeyinka', amount:200}, 
            {name:'Popoola', familyID:'the-Popoolas', amount:200}, 
            {name:'Adelabi', familyID:'the-Adelabis', amount:100},                                   
        ],    
    },  
    {docID:3, date:'2023-01-04', program:'Anointing Service Day-4', type:'OFFERING',dateDetails:'2023-01-04|Anointing Service Day-4', total:'',
        data:[
            {name:'Ariyibi', familyID:'ariyibi-oke.oluwaseyi.opeyemi', amount:200},                                    
        ],    
    }, 
    {docID:4, date:'2023-01-05', program:'Anointing Service Day-5', type:'OFFERING',dateDetails:'2023-01-05|Anointing Service Day-5', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Oladokun', familyID:'oladokun.augustine.oluwakemi', amount:500}, 
            {name:'Marcus', familyID:'marcus.garuba.faith', amount:200}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:400}, 
            {name:'Miller', familyID:'miller.henry.comfort', amount:200}, 
            {name:'Adelabi', familyID:'the-Adelabis', amount:100},                                                
        ],    
    }, 
    {docID:5, date:'2023-01-06', program:'Anointing Service Day-6', type:'OFFERING',dateDetails:'2023-01-06|Anointing Service Day-6', total:'',
        data:[
            {name:'Omoniyi', familyID:'omoniyi.francis.esther', amount:500}, 
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Akinmutola', familyID:'akinmutola.john.mercy', amount:300}, 
            {name:'Oladokun', familyID:'oladokun.augustine.oluwakemi', amount:500}, 
            {name:'Marcus', familyID:'marcus.garuba.faith', amount:300}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:400}, 
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:300}, 
            {name:'Miller', familyID:'miller.henry.comfort', amount:200}, 
            {name:'Akinyemi', familyID:'akinyemi.ebenezer.adeyinka', amount:500}, 
            {name:'Popoola', familyID:'the-Popoolas', amount:200},                                                            
        ],    
    },
    {docID:6, date:'2023-01-09', program:'Hour of Breakthrought', type:'OFFERING',dateDetails:'2023-01-09|Hour of Breakthrought', total:'',
        data:[
            {name:'Omoniyi', familyID:'omoniyi.francis.esther', amount:500}, 
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Udegbe', familyID:'udegbe.marcus.clara', amount:500}, 
            {name:'Oladokun', familyID:'oladokun.augustine.oluwakemi', amount:200}, 
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:500}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:400}, 
            {name:'Akinyemi', familyID:'akinyemi.ebenezer.adeyinka', amount:200}, 
            {name:'Popoola', familyID:'the-Popoolas', amount:200}, 
            {name:'Ogechi', familyID:'the-Ogechis', amount:100},                                                            
        ],    
    },
    {docID:7, date:'2023-01-16', program:'Hour of Breakthrought', type:'OFFERING',dateDetails:'2023-01-16|Hour of Breakthrought', total:'',
        data:[
            {name:'Omoniyi', familyID:'omoniyi.francis.esther', amount:500}, 
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Akinmutola', familyID:'akinmutola.john.mercy', amount:200}, 
            {name:'Oladokun', familyID:'oladokun.augustine.oluwakemi', amount:500}, 
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:500}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:200}, 
            {name:'Akinyemi', familyID:'akinyemi.ebenezer.adeyinka', amount:200},
            {name: 'Bro Chuckwudi', familyID: 'chukwudi.samson.single', amount:500},                                                           
        ],    
    }, 
    {docID:8, date:'2023-01-23', program:'Hour of Breakthrought', type:'OFFERING',dateDetails:'2023-01-23|Hour of Breakthrought', total:'',
        data:[
            {name:'Omoniyi', familyID:'omoniyi.francis.esther', amount:500}, 
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Akinmutola', familyID:'akinmutola.john.mercy', amount:200}, 
            {name:'Oladokun', familyID:'oladokun.augustine.oluwakemi', amount:500}, 
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:1000}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:200}, 
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:300},
            {name:'Otun', familyID:'otun.aanuoluwaposimi.single', amount:100}, 
            {name:'Adio', familyID:'adio.taiwo.ruth', amount:300}, 
            {name: 'Bro Chuckwudi', familyID: 'chukwudi.samson.single', amount:1000},
            {name:'Popoola', familyID:'the-Popoolas', amount:200},  
            {name: 'Bro Oloko', familyID: 'oloko.olusegun.single', amount:200},                                                          
        ],    
    },
    {docID:9, date:'2023-01-30', program:'Hour of Breakthrought', type:'OFFERING',dateDetails:'2023-01-30|Hour of Breakthrought', total:'',
        data:[
            {name:'Omoniyi', familyID:'omoniyi.francis.esther', amount:500}, 
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Udegbe', familyID:'udegbe.marcus.clara', amount:500},
            {name:'Oladokun', familyID:'oladokun.augustine.oluwakemi', amount:500}, 
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:1000}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:500}, 
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:300},
            {name:'Miller', familyID:'miller.henry.comfort', amount:200}, 
            {name:'Akinyemi', familyID:'akinyemi.ebenezer.adeyinka', amount:200}, 
            {name: 'Bro Chuckwudi', familyID: 'chukwudi.samson.single', amount:500},
            {name:'Popoola', familyID:'the-Popoolas', amount:200},  
            {name: 'Bro Oloko', familyID: 'oloko.olusegun.single', amount:200},                                                          
        ],    
    },     
];



export default offeringHBreakthrough