import React, {useState} from 'react';
import { BsFileEarmarkPdf, BsCloudDownload} from 'react-icons/bs';
import { client } from '../../../../client';
import { TooltipCustom2 } from '../../../TooltipCustomised';

const AccountUnitDocsIndex = () => {
  const [fetchedResources, setFetchedResources] = React.useState([]);


  
  React.useEffect(()=>{
    client.fetch(query).then((data) => {
          setFetchedResources(data);
      });
  },[]);
  
  return (
     <div>
      <p className='text-red-700 font-bold mx-4'>Church documents- Receipts, Invoice, etc. Hover on it for more details</p>
      <div className='flex max-h-[550px] min-h-[300px] flex-col 
        md:flex-row border border-solid border-teal-500 mx-2  bg-slate-100'>
                
        <div className='flex flex-[60%] md:ml-2 scrollbar-hide
          overflow-y-auto mx- flex-col rounded-lg py-16 items-center '>
            {fetchedResources.length>0?
              fetchedResources?.map((file, i)=>{
                
                return(
                  <div key={`${i}+key`} className='flex flex-row w-11/12 items-center'>
                   {i+1}. <Resource name={file.name} file={file} key={`${i}+key`}/>
                  </div>
                );
              })
              :
              <div className='text-[maroon] font-bold mt-[100px]'>
                Please, check your internet connection!
              </div>
            }
        </div>
    </div>
  </div>
  )


}



const Resource = ({name, file}) => {
  
  return (
    <div className='flex  max-h-12 items-center flex-row justify-between 
        border boder-2 border-blue-500 bg-slate-50 hover:bg-sky-100 active:bg-slate-100 p-3 
        rounded-lg my-2 w-10/12 md:w-8/12 ml-3'>
      <TooltipCustom2 className='flex flex-row flex-1'
        texthover={`${name}`}
        hovertext={file.description}
      />
      <a className=''
          href={`${file?.file}?dl=`}
          download
          onClick={e=>e.stopPropagation()}
          >
          <BsCloudDownload size={24} 
              className='cursor-pointer hover:text-[red] text-[#444]'/>
      </a>
    </div>
  )
}


const query  = `*[ _type == 'churchAssets'] | order(date desc){
  upload{
      asset->{
        url
      }
    },
  'file':upload.asset->url,
  _id,
  name,
  date,
  description  
}`;

export default AccountUnitDocsIndex;




/*
  return(
    <div className='bg-rose-300 flex justify-center'>
      <AllPages 
        //pdf={pdfDoc}
        pdf={{ url: 'https://cdn.sanity.io/files/lrfq9wxv/production/08f2d5f5a377bbfb1e8f26105af1716f57d26269.pdf'}} 
      />
    </div>
  );
*/