import getConfirmValsSchema from "./confirmValsSchema";

function infoToConfirmFunction(seltdIndv){

    const indivObj = {...seltdIndv};
    const confirmedKeysArr = [];
    const keysToConfirm = ['dob','wedAnn', 'phoneNo', 'email'];
    for(let i=0; i<keysToConfirm.length; i++){
        const confirmKey = keysToConfirm[i];
        if(indivObj[confirmKey]){
            const res =  getConfirmValsSchema();
            confirmedKeysArr.push(res[confirmKey]);
        }
    }
    let confirmValsRes =[]; let infoToConfirmRes = null; 
    if(confirmedKeysArr.length < 3){
        confirmValsRes = [];
        infoToConfirmRes = false       
    }else{
        confirmValsRes = confirmedKeysArr;
        infoToConfirmRes = true;
    }
    return {confirmValsRes, infoToConfirmRes}
}


function extractInfoToConfirm(seltdIndv){

    const indivObj = {...seltdIndv};
    let confirmedKeysObj = {};
    const confirmKeys = ['dob','wedAnn', 'phoneNo', 'email'];
    for(let i=0; i<confirmKeys.length; i++){
        const confirmKey = confirmKeys[i];
        const confirmValue = indivObj[confirmKey];
        if(confirmValue){
          confirmedKeysObj=  {...confirmedKeysObj, [confirmKey]:confirmValue};
        }
    }
    confirmedKeysObj = {...confirmedKeysObj, len:Object.keys(confirmedKeysObj).length}
    return confirmedKeysObj
}



export {infoToConfirmFunction, extractInfoToConfirm}