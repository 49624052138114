import installation1 from './InstallationOfPstOgundare2018-1.JPG';
import installation2 from './InstallationOfPstOgundare2018-2.JPG';
import installation3 from './InstallationOfPstOgundare2018-3.JPG';
import installation4 from './InstallationOfPstOgundare2018-4.JPG';
import installation5 from './InstallationOfPstOgundare2018-5.JPG';
import installation6 from './InstallationOfPstOgundare2018-6.JPG';
import installation7 from './InstallationOfPstOgundare2018-7.JPG';
import installation8 from './InstallationOfPstOgundare2018-8.JPG';
import installation9 from './InstallationOfPstOgundare2018-9.JPG';
import installation10 from './InstallationOfPstOgundare2018-10.JPG';
import installation11 from './InstallationOfPstOgundare2018-11.JPG';
import installation12 from './InstallationOfPstOgundare2018-12.JPG';
import installation13 from './InstallationOfPstOgundare2018-13.jpg';
import installation14 from './InstallationOfPstOgundare2018-14.jpg';
import installation15 from './InstallationOfPstOgundare2018-15.jpg';
import installation16 from './InstallationOfPstOgundare2018-16.jpg';
import installation17 from './InstallationOfPstOgundare2018-17.jpg';
import installation18 from './InstallationOfPstOgundare2018-18.jpg';
import installation19 from './InstallationOfPstOgundare2018-19.jpg';
import installation20 from './InstallationOfPstOgundare2018-20.jpg';
import installation21 from './InstallationOfPstOgundare2018-21.jpg';






const pstOgundareInstallation = [
      {sn:1, src:installation1,title:"Pastor Ogundare's Installation Service", desc:'Installation of Pastor Ogundare as Resident Pastor of GOFAMINT PS Ogba on 11 Feb., 2018',
        bCol:'maroon', date:'2018-02-11'},
      //{sn:2, src:installation2,}, wrong pictures
      {sn:3, src:installation3,},{sn:4, src:installation4,},
      {sn:5, src:installation5,},{sn:6, src:installation6,},{sn:7, src:installation7,},
      {sn:8, src:installation8,},
      //{sn:9, src:installation9,},
      {sn:10, src:installation10,},
      {sn:11, src:installation11,},{sn:12, src:installation12,},
      {sn:13, src:installation13,},{sn:14, src:installation14,},{sn:15, src:installation15,},
      //{sn:16, src:installation16,},
      {sn:17, src:installation17,},{sn:18, src:installation18,},
      {sn:19, src:installation19,},{sn:20, src:installation20,},{sn:21, src:installation21,},
    ];
    

export default pstOgundareInstallation