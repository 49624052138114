import React from 'react'
//import { useSelector } from 'react-redux';
import { getFormField } from '../../../assets/textFieldsMain';

export const MemberDataTableMain = (props) => {
    //const {tableData} = useSelector((state)=>state.user);
    
    function selectedMemberHandler(fm, i){
        props.handleClickMember(fm);
        props.setSeltdIndex(i);
    }
    const hiddenHeadersArr = getFormField('txtFieldsHidden');
    let headers = Object.keys(props.formFlds);
    let headersCopy = [...headers];
    const noOfHiddenFlds = hiddenHeadersArr.fields.length; //11;
    let visibleHeaders = Object.keys(props.formFlds).slice(3,headersCopy.length-noOfHiddenFlds);    
    

    const frozenCols = `sticky  top-0  text-white  min-h-[30px] h-[30px] 
        text-left px-[5px]  
        p-0 outline-[2px] outline-[dodgerblue] outline border-none outline-offset-[-1px]`;
    const hiddenFlds = `${props.showHiddenFlds? '' : 'hidden'} 
            whitespace-nowrap  min-w-[100px] bg-[navy] ${frozenCols}`;
    const zIndex = 'z-[10]';
  return (
        <div className="w-[95vw] sm:w-[90vw] h-[400px] overflow-auto border-2 border-solid resize-y">
        <table className="border-spacing-0 p-0 border-[1px] text-[11px] sm:text-[13px] border-solid border-[blue]" width="700px">
            <thead>
                <tr className='min-h-[25px] even:bg-[#dfdfdf]'>
                    <th 
                        className={`min-w-[30px] w-[30px] sm:min-w-[40px] sm:w-[40px] left-0 bg-[#333] ${zIndex} ${frozenCols}`}>
                        {props.formFlds.sn.header}
                    </th>
                    <th  
                        className={`min-w-[70px] w-[70px] sm:min-w-[100px] sm:w-[100px] ${zIndex} ${props.toggleFreeze? 'left-[30px] sm:left-[40px]' : frozenCols} bg-[#333] cursor-pointer ${frozenCols}`}
                         >
                        {props.formFlds.surname.header}
                    </th>
                    <th  
                        className={`min-w-[70px] w-[70px] sm:min-w-[100px] sm:w-[100px] ${zIndex} ${props.toggleFreeze? 'left-[100px] sm:left-[140px]'  : frozenCols} bg-[#333] cursor-pointer ${frozenCols}`}
                        >
                        {props.formFlds.firstname.header}
                    </th>
                    {
                        visibleHeaders.map((keys, i)=>{
                            return(
                                <th key={`${i}+header`}
                                    className={`whitespace-nowrap
                                    ${frozenCols} ${keys==="Edit" || keys==="Select"? 'min-w-[50px] bg-[#280184]' : 'min-w-[100px] bg-[#555]'}`}
                                    style={{cursor:i===3? 'pointer' : ""}}>
                                    {props.formFlds[keys].header}
                                </th>
                            );
                        })
                    }
                    <th  className={`whitespace-nowrap  min-w-[100px] bg-[#555] ${frozenCols}`}>
                        {props.formFlds.membership.header}</th>

                    <th  className={hiddenFlds}>{props.formFlds.select.header}</th>
                    <th  className={hiddenFlds}>{props.formFlds.edit.header}</th>
                    <th  className={hiddenFlds}>{props.formFlds.photo.header}</th>
                    <th  className={hiddenFlds}>{props.formFlds.family.header}</th>
                    <th  className={hiddenFlds}>{props.formFlds.famStat.header}</th>
                    <th  className={hiddenFlds}>{props.formFlds.id.header}</th>
                    <th  className={hiddenFlds}>{props.formFlds.info.header}</th>
                    <th  className={hiddenFlds}>{props.formFlds.loginID.header}</th>
                    <th  className={hiddenFlds}>{props.formFlds.filledBy.header}</th>
                    <th  className={hiddenFlds}>{props.formFlds.filledDate.header}</th>
                    <th  className={hiddenFlds}>{props.formFlds.editedBy.header}</th>
                    <th  className={hiddenFlds}>{props.formFlds.accesspermit.header}</th>
                    <th  className={hiddenFlds}>{props.formFlds.accessdenied.header}</th>
                    <th  className={hiddenFlds}>{props.formFlds.notification.header}</th>
                    <th  className={hiddenFlds}>{props.formFlds.notifToggle.header}</th>
                </tr>
            </thead>
            <tbody>
                {
                    props.uploadedData.map((form, i)=>{
                        const even = i%2 === 1? true : false;
                        const rows = `p-0 px-[5px] outline-[1px] outline border-none outline-offset-[-1px] outline-[dodgerblue] whitespace-nowrap`;
                        const frozenCol = `
                             ${rows} ${even? 'bg-[silver]' : "bg-[aliceblue]"}
                             ${props.seltdIndex === i? 'text-[red] font-bold' : "text-black"} 
                            `;
                        const frozenSn = `
                            ${rows} ${even? 'bg-[silver]' : "bg-[aliceblue]"}
                            ${props.seltdIndex === i? 'text-[red] font-bold' : "text-[blue]"} 
                           `;
                        
                        return(
                            <tr className="min-h-[25px] h-[25px] even:bg-[#dfdfdf] " key={`${i}+tab`}>
                                {/*<td className={`left-0 ${frozenCol} sticky`} >{i+1}</td>*/}
                                <td className={`left-0 ${frozenSn} sticky  cursor-pointer font-bold hover:text-[red]
                                    `}
                                    onClick={()=>selectedMemberHandler(form, i)}>{i+1}</td>

                                <td className={`${props.toggleFreeze? 'left-[30px] sm:left-[40px] sticky' : ""} ${frozenCol}`} >
                                    {form.surname}</td>    
                                <td className={`${props.toggleFreeze? 'left-[100px] sm:left-[140px] sticky' : ''} ${frozenCol}`} >
                                    {form.firstname}</td>    
                                <td className={`${rows}`}>{form.othernames}</td>
                                <td className={`${rows}`}>{form.title}</td>
                                <td className={`${rows}`}>{form.genderStat}</td>
                                <td className={`${rows}`}>{form.ageGroup}</td>
                                <td className={`${rows}`}>{form.addressRes}</td>
                                <td className={`${rows}`}>{form.dob}</td>
                                <td className={`${rows}`}>{form.birthday}</td>
                                <td className={`${rows}`}>{form.phoneNo}</td>
                                <td className={`${rows}`}>{form.email}</td>
                                <td className={`${rows}`}>{form.occupation}</td>
                                <td className={`${rows}`}>{form.offRes}</td>
                                <td className={`${rows}`}>{form.school}</td>
                                <td className={`${rows}`}>{form.instiStat}</td>
                                <td className={`${rows}`}>{form.matStat}</td>
                                <td className={`${rows}`}>{form.memberStat}</td>
                                <td className={`${rows}`}>{form.batStat}</td>
                                <td className={`${rows}`}>{form.wedAnn}</td>
                                <td className={`${rows}`}>{form.wedAnns}</td>
                                <td className={`${rows}`}>{form.membership}</td>
                                
                                <td className={`${rows} ${props.showHiddenFlds? '' : 'hidden'}`}
                                 >
                                    <input type="checkbox" checked={false} 
                                        onChange={()=>props.handleCheckBox(i)}/>
                                </td>
                                <td className={`${rows} ${props.showHiddenFlds? '' : 'hidden'}`}>Edit</td>
                                <td className={`${rows} ${props.showHiddenFlds? '' : 'hidden'}`}>{form.photo}</td>
                                <td className={`${rows} ${props.showHiddenFlds? '' : 'hidden'}`}>{form.family}</td>
                                <td className={`${rows} ${props.showHiddenFlds? '' : 'hidden'}`}>{form.famStat}</td>
                                <td className={`${rows} ${props.showHiddenFlds? '' : 'hidden'}`}>{form.id}</td>
                                <td className={`${rows} ${props.showHiddenFlds? '' : 'hidden'}`}>{form.info}</td>
                                <td className={`${rows} ${props.showHiddenFlds? '' : 'hidden'}`}>{form.loginID}</td>
                                <td className={`${rows} ${props.showHiddenFlds? '' : 'hidden'}`}>{form.filledBy}</td>
                                <td className={`${rows} ${props.showHiddenFlds? '' : 'hidden'}`}>{form.filledDate}</td>
                                <td className={`${rows} ${props.showHiddenFlds? '' : 'hidden'}`}>{form.editedBy}</td>
                                <td className={`${rows} ${props.showHiddenFlds? '' : 'hidden'}`}>{form.accesspermit}</td>
                                <td className={`${rows} ${props.showHiddenFlds? '' : 'hidden'}`}>{form.accessdenied}</td>
                                <td className={`${rows} ${props.showHiddenFlds? '' : 'hidden'}`}>{form.notification}</td>
                                <td className={`${rows} ${props.showHiddenFlds? '' : 'hidden'}`}>{form.notifToggle}</td>
                            </tr>
                        );
                    })
                }
            </tbody>

        </table>
    </div>
  )
};


export default MemberDataTableMain