
const validateFieldsInputs=(forms)=>{
    let errorMsgs = {msg:'', error:false};
    for(let i=0; i<forms.length; i++){
        const form = forms[i];
        const {title, genderStat, ageGroup, instiStat, memberStat,matStat, batStat, firstname, } = form;
       if(title === "" || title === "Select" || title === null){
         return   errorMsgs = {msg:"Please, select 'Title' for "+firstname+'!', error:true}}
       if(genderStat === "" || genderStat ==="Select" || genderStat === null){
         return errorMsgs = {msg:"Please, select 'Gender' for "+firstname+'!', error:true}}
       if(ageGroup === "" || ageGroup ==="Select" || ageGroup === null){
         return errorMsgs = {msg:"Please, select 'Age group' for "+firstname+'!', error:true}}
       if(instiStat==="" || instiStat === null){
         return errorMsgs = {msg:"Please, select 'Qualification' for "+firstname+'!', error:true}}
       if(memberStat === "" || memberStat === "Select" || memberStat === null){
         return errorMsgs = {msg:"Please, select 'Membership status' for "+firstname+'!', error:true}}
       if(matStat === "" || matStat === "Select" || matStat === null){
         return errorMsgs = {msg:"Please, select 'Marital Status' for "+firstname+'!', error:true}}
       if(batStat === "" || batStat === "Select" || batStat === null){
         return errorMsgs = {msg:"Please, select 'Baptismal Status' for "+firstname+'!', error:true}}
    }
    return errorMsgs
}


const validateUploadedPhotos=(name)=>{
  let errorMsgs = {msg:'', error:false};
  var imageType = name.split('.');
  imageType = imageType[imageType.length-1];
  const fmts = ['jpg', 'jpeg', 'png'];
  if(!fmts.includes(imageType)){
    errorMsgs = {msg:"Wrong image format! Your image format must be in 'jpeg', 'jpg' or 'png' formats.", error:true}
  }else{
    errorMsgs = {msg:'', error:false}
  }
  
    return errorMsgs
}


export {validateFieldsInputs, validateUploadedPhotos}