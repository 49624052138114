import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  pstCornerData:[],
  selectedMsg:{},
  anchor:'',
  faithDeclaration:'',
  anchorBgImages:{imageBase:'', imageSub:''},
}

export const pstCornerReducer = createSlice({
  name: 'pstCornerMsg',
  initialState,
  
  reducers: {
    disPstCornerMsg: (state, action) => {
      state.pstCornerData = action.payload
      },
    disSelectedMsg: (state, action) => {
      state.selectedMsg = action.payload
      },
    disAnchor: (state, action) => {
      state.anchor = action.payload
      },
    disFaithDeclaration: (state, action) => {
      state.faithDeclaration = action.payload
      },
    disAnchorBgImages: (state, action) => {
      state.anchorBgImages = action.payload
      },
  },
})


export const { disPstCornerMsg, disSelectedMsg,
    disAnchor, disFaithDeclaration, disAnchorBgImages } = pstCornerReducer.actions

export default pstCornerReducer.reducer