import React from 'react'
//import styles from './Styles.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { disShowSnackbar } from '../context/reducers/user';

const Snackbar = () => {
    const dispatch = useDispatch();
    const {snackbar} = useSelector((state)=>state.user);
    const {msg, show, bColor, textColor} = snackbar;
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    //console.log(snackbar)
    React.useEffect(()=>{
        if(show){
            setShowSnackbar(true);
        };
        setTimeout(()=>setShowSnackbar(false), 3000);
        dispatch(disShowSnackbar(false))
    },[show, dispatch]);

    return(
        <div className={`w-full ${showSnackbar? 'animate-slide-snackbar' : 'hidden'} -translate-y-16 absolute justify-center overflow-x-hidden overflow-y-auto z-[5]`}
            >
            <div className={`relative flex  w-full justify-center`}>
                <div className="py-2 px-3 rounded-lg overflow-x-auto w-auto flex-wrap" 
                    style={{
                        backgroundColor: bColor? bColor : '#333',
                        color: textColor? textColor : 'white'}}
                        >
                    {msg}-{show}
                </div>
            </div>
        </div>
    );
}

export default Snackbar