import React from 'react'
import Index from './new/Index';



const Card = ({photo,postedDate, msg, topic, comment, likes, bibleRef, 
    prayer, styles, item}) => {
           
    return (
        <Index
            photo={photo}
            postedDate={postedDate}
            msg={msg}
            topic={topic}
            comment={comment}
            likes={likes}
            bibleRef={bibleRef}
            prayer={prayer}
            styles={styles}
            item={item}
        />
    )
}


export default Card