import { TypeAnimation } from 'react-type-animation';

const TypeAnimationComponent = ({sequence}) => {

  return (
    <TypeAnimation
      sequence={sequence}
        /*[
        text.anchorType1, // Types 'One'
        1000, // Waits 1s
        text.anchorType2, // Deletes 'One' and types 'Two'
        2000, // Waits 2s
        text.anchorType3, // Types 'Three' without deleting 'Two'
        2000, // Waits 2s
        text.anchorType4, // Types 'Three' without deleting 'Two'
        1000, // Waits 2s
        text.anchorType5, // Types 'Three' without deleting 'Two'
        1000, // Waits 2s
        text.anchorType6, // Types 'Three' without deleting 'Two'
        3000
        /*() => {
          console.log('Done typing!'); // Place optional callbacks anywhere in the array
        }
      ]}*/
      wrapper="div"
      cursor={true}
      repeat={Infinity}
      style={{ textAlign: 'center' }}
    />
  );
};

export default TypeAnimationComponent;

const text = {
  anchorType1:'Year 2023',
  anchorType2:'Our Year',
  anchorType3:'Our Year of',
  anchorType4:'Our Year of Abundant',
  anchorType5:'',
  anchorType6:'Our Year of Abundant Life',
}

/*
const text = {
  anchorType1:'Yes, the LORD will give what is good; And our land will yield its increase. Psalm 85:12',
  anchorType2:'Yes, the LORD will give',
  anchorType3:'Yes, the LORD will give what is good; And our land',
  anchorType4:'',
  anchorType5:'Yes, the LORD will give what is good; And our land will yield its increase. Psalm 85:12',
}
*/