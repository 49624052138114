import React, { useState, } from 'react';
import { useLocation } from 'react-router-dom';
import { getPathName } from './navs';
import useScrollDirection from '../../utils/useScrollDirection';
import { useSelector, useDispatch } from 'react-redux';
import Header from './Header'
import HeaderTabs from '../userpage/headerTabs/HeaderBar';
import Snackbar from '../Snackbar';
import { client } from '../../client';
import { processPastorCornerMessages, getQueryPstCorner } from '../pcorner/pstCornerQueries';
import { disPstCornerMsg, disAnchor, disFaithDeclaration, disAnchorBgImages } from '../../context/reducers/pstCornerMsg';
import useOnline from 'use-online';
//import { fetchCelebrantsData } from '../userpage/_home/celebrantsDataModules';
//import { disCelebrantsData } from '../../context/reducers/user';
//import useSessionStorage from '../../utils/hooks/useSessionStorage';
import useLocalStorageSessionStorage from '../../utils/hooks/useLocalStorageFromSessionStorage';
import { disLoginForm, disLoginUser, disUser, disUserType } from '../../context/reducers/login';
import { getQueryAnchorFaithDeclaration } from './queries';

const HeaderContainer = () => {
  const isOnline = useOnline();
  const dispatch = useDispatch();
  const location = useLocation();
  const activePage = getPathName(location.pathname);
  const scrollDirection = useScrollDirection();
  const {showSidebar, celebrantsData,} = useSelector((state)=>state.user);
  const { pstCornerData} = useSelector((state)=>state.pstCornerMsg);
  const {loginForm,userType, user, loginUser, showAnnouncement } = useSelector((state)=>state.login);
  const { value, set } = useLocalStorageSessionStorage("psOgba_User", 0);
  const [devArrs, setDevArrs] = React.useState([]);
 
  const [hideHeader, setHideHeader] = useState(false);
    //console.log(value)
    async function fetchContents() {
        const query = getQueryPstCorner();
        const response = await client.fetch(query);
        //setFetchedData(response);
        if(response.length>0){
          const processRes = processPastorCornerMessages(response);
          dispatch(disPstCornerMsg(processRes));
        }
      }
    //console.log(user);
    async function fetchAnchorAndFaithDeclaration() {
        const query = getQueryAnchorFaithDeclaration();
        await client.fetch(query).then((res)=>{
          if(res){
            const anchor = res.ANCHOR.key;
            const faithDecl = res.FAITHDECLARATION;
            const imageBase = res.IMAGEBASE;
            const imageSub = res.IMAGESUB;
            const sequence = faithDecl.reduce((fRes, item, i)=>{
                return  [...fRes, item.key, parseInt(item.value)]
            },[]);
            dispatch(disAnchor(anchor));
            dispatch(disFaithDeclaration(sequence));
            dispatch(disAnchorBgImages({imageBase, imageSub}))
          }
        });
    }

    
  
  //console.log(celebrantsData);
  React.useEffect(() => {  
    if(pstCornerData.length <1){
      fetchContents();
    }
    if(isOnline){
      //celebrantsDataHandler();
      //console.log('bdCelebrants')
    }
  }, [isOnline, loginForm.isOnline]);

  function loginHandler(){
      const {family_name, given_name,name, picture, email, sub} = value.decoded;
      dispatch(disLoginForm({
        ...loginForm, isOnline:true, picture, checkedMember:value.checkedMember, adminPass:value.adminPass, show:false})
        );
      dispatch(disUser({surname:family_name,firstname:given_name,fullName:name, title:'',
          email:email,loginID:sub,famID:"",userID:sub, imageUrl:picture})
        );
  }

  async function fetchDev() {
    const query = devQuery;
    const response = await client.fetch(query);
    if(response.length>0){
      const devArrs = response[0].keyvaluepair.filter(item => item.pair === "Developers");
      const devArr = devArrs.reduce((res, item)=> {
         return [...res, item.value]
      },[]);
      setDevArrs(devArr);
    }
  }

  React.useEffect(()=>{
    //Managing login UserType: USER, ADMIN or DEVELOPER
    if(loginForm.isOnline){
       const userType = loginForm?.adminPass && !devArrs.includes(user.email)? 'ADMIN' : 
              loginForm?.adminPass && devArrs.includes(user.email)? 'DEVELOPER' : 'USER';
       dispatch(disUserType(userType));

       //loginUser:{user:'', code:0, }
       const guestType = loginUser.guest? "GUEST": "None Member";
       const nonAdminType = loginForm.checkedMember === 'member'? "Member": guestType;
       const loginType = loginForm?.adminPass &&  !devArrs.includes(user.email)? 'ADMIN' : 
              loginForm?.adminPass &&  devArrs.includes(user.email)? 'DEVELOPER' : nonAdminType;
       dispatch(disLoginUser({...loginUser, user:loginType}));
    }
    //console.log(loginForm.isOnline); user?.userID ==="102523766629932282547"?
  },[isOnline, loginForm.isOnline, user.userID, loginUser.guest, devArrs]);

  React.useEffect(()=>{
    //Retrieving Session Storage
    if(value?.user===true){
        loginHandler()
    }
    fetchDev();
  },[]);
  React.useEffect(()=>{
    fetchAnchorAndFaithDeclaration();
  },[isOnline]);

  return (
      <div className={`sticky ${ scrollDirection === "down" ? "-top-[200px]" : "top-0"} 
        transition-all duration-500 headerShadow z-50 overflow-hidden
        ${showAnnouncement? 'hidden' :''}`} //'fixed w-full z-50 headerShadow'
        >
        {activePage === "User" && <Snackbar />}
          
        <Header hide={hideHeader} set={set}/>
        {activePage==="User" &&
          <HeaderTabs
            scrollDirection={scrollDirection}
            showSidebar={showSidebar}
            hideHeader={hideHeader}
            setHideHeader={setHideHeader}
          />
          }  
          <p className='hidden'>LoginForm- IsOnline:{loginForm.isOnline}, Show:{loginForm.show} AdminPass:{loginForm.adminPas}, 
            UserType:{userType}, UserID:{user.userID}</p>      
      </div>
  );
};

export default HeaderContainer;

const devQuery = `
  *[ _type == "controls" && title == "AdminPass"]{
    keyvaluepair
  }
`;

//const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep","Oct","Nov","Dec"];
