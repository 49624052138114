import { client } from "../../../../client";
import { createMemberDoc, deleteUsedAssetsQuery } from '../../admin/membersData/queries';

/*
const replaceMultipleFiles = async (membersData) => {
    if(membersData.length >0){
        for (let index = 0; index < membersData.length; index++) {
        const form = membersData[index];
        const stdDoc = createStdMemberDoc(form);
        const doc = stdDoc;
        await client.createOrReplace(doc).then(res => {
            console.log(`File no. ${res.sn} successfully uploaded!`)
        }).catch(e => {
            console.log(e.error)
        });
        }
        console.log('All FILES UPLOADED!');
        return {result: 'SUCCESSFUL'}
    }else{
        console.log('No data to upload!')
    }
  }
*/
const createMultipleFiles = async (membersData) => {
    if(membersData.length >0){
        for (let index = 0; index < membersData.length; index++) {
        const doc = createMemberDoc(membersData[index]);
        await client.createIfNotExists(doc).then(res => {
            console.log(`File no. ${res.sn} successfully uploaded!`)
        }).catch(e => {
            console.log(e.error)
        });
        }
        console.log('All FILES UPLOADED!')
    }else{
        console.log('No data to upload!')
    }
  }


const  processMultipleImageAssets = async(filesArr, fileType) =>{
    const fileAssets = [];
    for (let index = 0; index < filesArr.length; index++) {
        const file = filesArr[index];
        
       await client.assets
          .upload(fileType, file, { contentType: file.type, filename: file.name })
          .then((document) => {
            fileAssets.push(document);
          })
   }
   
   return fileAssets;
 }

 const  processPdfDocAssets = async(file) =>{
    //const fileAsset = [];
       const result = await client?.assets
        .upload('file', file, { contentType: file.type, filename: file.name });
        //.then((document) => {
        //fileAsset.push(document);
        //console.log(document)
        //});
   return result;
 }


 function deleteMultipleDoc(){
    client
        .delete({query: '*[_type == "members"][0...300]'})
        .then(() => {
            console.log('Members doc deleted')
        })
        .catch((err) => {
            console.error('Delete failed: ', err.message)
        })
 }

  const updateMultipleFiles = async (membersData) => {
    if(membersData.length >0){
        for (let index = 0; index < membersData.length; index++) {
            const docID = membersData[index].id;
        await  client
            .patch('bike-123') // Document ID to patch
            .set({inStock: false}) // Shallow merge
            .commit() // Perform the patch and return a promise
            .then((updatedDoc) => {
                console.log(`File no. ${updatedDoc.sn} successfully updated!`)
            })
            .catch((err) => {
                console.error('Oh no, the update failed: ', err.message)
            })
        }
    }else{
        console.log('No data to upload!')
    }
  }




  /*const uploadImage = (e) => {
    const selectedFile = e.target.files[0];
    // uploading asset to sanity
    if (selectedFile.type === 'image/png' || selectedFile.type === 'image/jpeg') {
      setWrongImageType(false);
      setLoading(true);
      client.assets
        .upload('image', selectedFile, { contentType: selectedFile.type, filename: selectedFile.name })
        .then((document) => {
          setImageAsset(document);
          console.log(document)
          setLoading(false);
        })
        .catch((error) => {
          console.log('Upload failed:', error.message);
        });
    } else {
      setLoading(false);
      setWrongImageType(true);
    }
  };*/

    function queryMembers(){
        const mn = 11; const month = `*-${mn}-*`; 
        const query = `*[_type == "members" && dob match '${month}']{_id, surname, firstname, dob}`;
        
        const today = new Date().toISOString().split('T')[0];
        client.fetch(query, {today}).then(res => {
            console.log(res)
        })
    }

   function deleteUsedAssets(){
    const query = deleteUsedAssetsQuery();
    client
        .fetch(query)
        .then(ids => {
            if (!ids.length) {
            console.log('No assets to delete')
            return true
            }

            console.log(`Deleting ${ids.length} assets`)
            return ids
            .reduce((trx, id) => trx.delete(id), client.transaction())
            .commit()
            .then(() => console.log('Done!'))
        })
        .catch(err => {
            if (err.message.includes('Insufficient permissions')) {
            console.error(err.message)
            console.error('Did you forget to pass `--with-user-token`?')
            } else {
            console.error(err.stack)
            }
        })
   }


export { createStdMemberDoc, processMultipleImageAssets, deleteUsedAssets, processPdfDocAssets}


function createStdMemberDoc(form) {
    const {
        _id,
        _type,
        loginID,
        sn,
        title,
        surname,
        firstname,
        othernames,
        addressRes,
        dob,
        birthday,
        genderStat,
        ageGroup,
        email,
        phoneNo,
        occupation,
        addressOff,
        school,
        instiStat,
        memberStat,
        membership,
        matStat,
        wedAnn,
        wedAnns,
        batStat,
        imageUrl,
    
        select,
        edit,
        photo,
        family,
        famStat,
        id,
        info,
        filledBy,
        filledDate,
        editedBy,
        accesspermit,
        accessdenied,
        notification,
        notifToggle,     } = form;
    
        let doc = {
            _id,
            _type,
            surname,
            loginID,
            sn,
            title,
            firstname,
            othernames,
            addressRes,
            dob,
            birthday,
            genderStat,
            ageGroup,
            email,
            phoneNo,
            occupation,
            addressOff,
            school,
            instiStat,
            memberStat,
            matStat,
            wedAnn,
            wedAnns,
            batStat,
            membership,
            //photo,
            //imageUrl:null,
        
            select,
            edit,
            family,
            famStat,
            id,
            info,
            filledBy,
            filledDate,
            editedBy,
            accesspermit,
            accessdenied,
            notification,
            notifToggle,
        };

    doc = form.photo?.asset? {...doc, photo} : doc; 
    return doc
  }
