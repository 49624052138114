import React from 'react';


const TextField = ({title, pholder,required, value, onChange, type})=>{

 return(
     <div className='flex flex-row flex-wrap border border-solid border-blue-500 px-2 py-1'>
         <label className='mr-2'>{title}</label>
         <input placeholder={pholder}
             value={value} onChange={onChange}
             required={required || ""}
             type={type}
             autoComplete='true'
             className="flex flex-1 h-8 px-3 text-gray-600"
             />
     </div>
 );
}


const ViewField = ({title, pholder, value,})=>{

 return(
     <div className='flex flex-row flex-wrap border border-solid border-blue-500 px-2 py-1'>
         <label className='mr-2'>{title}</label>
         <p className='bg-white text-gray-600 flex flex-1 h-8 px-3 whitespace-nowrap overflow-x-hidden'>{value? value: pholder}</p>
     </div>
 );
}


const ViewSelection = ({title, setSelected, opts, onClick, clickable})=>{
 const onOptionChangeHandler = (event) => {
     setSelected(event.target.value);
 }
 return(
     <div className='flex flex-row flex-wrap border border-solid border-blue-500 px-2 py-1'>
         <label className={`mr-2 ${clickable? 'hover:text-[mediumblue] active:text-red-700 font-bold cursor-pointer' : ''}
                ${clickable && opts?.length? 'text-[mediumblue]':''}`}
          onClick={clickable && onClick}>
            {title}
         </label>
         <select onChange={onOptionChangeHandler} 
             className="bg-white text-gray-800 px-2 flex flex-1">
             <option className='text-red-500'>{clickable? "Click 'Program' for dropdown" : 'Select Type'}</option>
             {opts?.map((item,i)=>{
                return(
                    <option key={`${i}+key`} className="text-sm">{item}</option>
                );
             })}
         </select>
     </div>
 );
}



export {TextField, ViewField, ViewSelection}