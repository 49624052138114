import React, {useEffect, useState} from "react";
import AlbumMain from "./AlbumMain";
import { useSelector, useDispatch } from "react-redux";
import CustomLoader from "../userpage/components/CustomLoader";
import { getPhotosGalArr } from "./photos";
import { disPhotos } from "../../context/reducers/photoGalleries";
import { client } from "../../client";
import { sortDatesByYrMn } from "../../utils/sortData";
//import { CustomizeModalForPhone } from "./PhotoViewerForPhone";


function processPhotos(arrs){
    const photoArrs = [];
    for (let index = 0; index < arrs.length; index++) {
        const arr = arrs[index];
        const {event, date, description, backgroundColor, photos} = arr; 
        
        const photoArr = [];
        for (let i = 0; i < photos?.length; i++) {
            const photo = photos[i];
            const imgSrc = photo.asset.url;
            const photoObj = {sn:i+1, src:imgSrc,title:event, desc:description, 
                                bCol:backgroundColor, date:date};
            photoArr.push(photoObj);
        }
        photoArrs.push(photoArr);
    }
    return photoArrs
}



const Gallery =()=>{
    const dispatch = useDispatch();
    const {photos} = useSelector((state)=>state.photoGalleries);
    
    const photosGalArr = photos;
    const [photosRetrieved, setPhotosRetrived] = useState(false);



async function fetchOnlinePhotos() {
    const staticPhotos = getPhotosGalArr();
    const query = getQueryPhotos();
    let response = await client.fetch(query);
    
    if(response[0]?.photos?.length){
        //console.log(response)
        const processedPhotos = processPhotos(response);
        const allPhotos = [...processedPhotos, ...staticPhotos];
        const sortPhotos = sortDatesByYrMn(allPhotos, 'FROMRECENT');
        dispatch(disPhotos(sortPhotos));
        setPhotosRetrived(true);
        //setPhotosRetrived(true);
    }
  }     
        
 useEffect(() => {
    const staticPhotos = getPhotosGalArr();
    const sortPhotos = sortDatesByYrMn(staticPhotos, 'FROMRECENT');
    dispatch(disPhotos(sortPhotos));
    },[]);

    useEffect(() => {
        fetchOnlinePhotos();
    },[]);


    const isLoading = !photosGalArr?.length;
        if(isLoading){
        return <CustomLoader loadingMsg="Loading Photo galleries, please wait!" color="blue" 
                    loading={isLoading? true : false}/>
        }

    return(
        <div>
            {
                photosGalArr?.map((item, i)=>{   
                    return(
                        <AlbumMain key={`${i}+key`}
                            title={item[0].title}
                            details={item[0].desc}
                            photos={item}
                            loading={item? false : true}
                            headerBCol={item[0].bCol}    //{i===1? 'bg-rose-600' : i===2? 'bg-[magenta]' : i===3? 'bg-[gray]' : 'bg-blue-700'}
                        />
                    );
                })
            }
        </div>
    );
}


export default Gallery;




function getQueryPhotos(){

    const memberData = `
    *[_type == "photoGallery" ] | order(_createdAt desc) {
        event, date, description, backgroundColor,
        photos[]{
            asset->{url}
          },
     }
  `;
    return memberData
}



/*
const memberData = `
    *[_type == "photoGallery" ] | order(_createdAt desc) {
        _id,
        _type,
        event,
        date,
        backgroundColor,
        description,
        photos,
        'photo':photo.asset->url,
     }
  `;
  */