

function getBiblePsg(strg){
   const psg =  "(" + strg.split('(')[1];

    return psg
}



function getTodayMonth(){
  const d = new Date();
  let mnth = months[d.getMonth()];
  
  return [parseInt(d.getMonth())+1, mnth]
}

function getTodayDate(){
  const d = new Date();
  let todayDay = d.getDate();
  
  return [todayDay]
}

function getTodayDateStr(){
  const d = new Date();
  let todayDay = d.getDate();
  todayDay = todayDay < 10? '0'+todayDay : todayDay;
  
  return [todayDay]
}


export {getBiblePsg, getTodayMonth, getTodayDate, getTodayDateStr}




const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];