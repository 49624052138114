import React, {useState} from 'react';
import { client } from '../../../../../client';
import { checkForNewPayers } from '../modules/modules1';
import OnlineOfferingPublishByUpload from './OnlineOfferingPublishByUploads';
import OnlineOffPublishManual from './OnlineOffPublishManual';
import OnlineOfferingPayerManualUpload from './OnlineOfferingPayer';
import MembersInfoTable from './MembersInfoTable';

const OnlineOffering = ({selectedTabName}) => {
    const [newPayers, setNewPayers] = useState(false);
    const [uploadedData, setUploadedData] = useState([]);
    const [allPayerData, setAllPayersData] = useState([]);
    const [prosUploadsForPublish, setProsUploadsForPublish] = useState([]);
    const [uploadDataDisplay, setUploadedDataDisplay] = useState('');
    const [currentStep, setCurrentStep] = useState({no:0, details:''});
    const [seudoUpload, setSeudoUpload] = useState([]);
    const [warningMsg, setWarningMsg] = useState({show:false, msg:"Start", step:0});

    const [fetchedPayers, setFetchedPayers] = useState([]);

    
    
    const isNewPayer = uploadedData?.find(item=> item.newPayer)?.familyID;
    const publishNewPayershandler=()=>{
        if(!isNewPayer)return  
        //function fetchContents(){
            client.fetch(queryPayersCount).then((res)=>{
                if(res.totalPayers){
                    const newPayersArr = uploadedData.filter(item =>item.newPayer);
                    //setUploadStarted(true);
                    createMultipleFiles(newPayersArr, res.totalPayers);
                }
            });
        //}
    }

   function getPayersDataHandler(uploadData){ 
            fetchPayersData().then((res)=>{
                setCurrentStep({no:1, details:'Checking for new payers.'});
                setFetchedPayers(res);
                const processedUploads = checkForNewPayers(res, uploadData);
                setUploadedData(processedUploads);
                let isNewPayer = processedUploads.find(item=> item.newPayer);
                isNewPayer = isNewPayer? true : false; 
                setNewPayers(isNewPayer);
                if(isNewPayer){
                    setWarningMsg({show:true, msg:'New Payers are highlighted red.', step:1});
                }else{
                    viewAllPayersHandler();
                    setProsUploadsForPublish([...processedUploads]);
                    setWarningMsg({show:true, msg:'There are no new Payers!', step:1});
                }
            });   
   }

   function resetUploadFileHandler(){
     setUploadedData([]);
     setWarningMsg({show:false, msg:'', step:0});
     setCurrentStep({no:0, details:'Reset'});
     setAllPayersData([]);
     setProsUploadsForPublish([]);
     setUploadedDataDisplay([]);
     setFetchedPayers([]);
   }

   function viewAllPayersHandler(){
        fetchPayersData().then((res)=>{
            const sorted = res.sort((a,b)=>{ return a.sn - b.sn})
            setAllPayersData(sorted);
        });
   }
   function toggleAllPayersNewFileHandler(){
        if(isNewPayer || !uploadedData.length) return

        if(uploadDataDisplay==="FILE"){
            setUploadedData([...allPayerData]);
            setUploadedDataDisplay('ALL');
        }else{
            setUploadedData([...prosUploadsForPublish]);
            setUploadedDataDisplay('FILE');
        }
   }

   React.useEffect(()=>{
      if(uploadedData.length && currentStep.no===0){
        getPayersDataHandler(uploadedData);
        setUploadedDataDisplay('');
      }
   },[uploadedData]);

   const createMultipleFiles = async (arr, lstNo) => {
    if(arr.length >0){
        for (let index = 0; index < arr.length; index++) {
        const doc = createOfferingPayersDoc(arr[index], lstNo+(index+1));
        await client.createIfNotExists(doc).then(res => {
           // console.log(`File no. ${res.sn} successfully uploaded!`)
        }).catch(e => {
            console.log(e.error)
        });
        }
        //console.log('All FILES UPLOADED!');
        //const processedUploads = 
        setWarningMsg({show:true, msg:'New Payers successfully published and offering ready to be published!', step:2});
        setCurrentStep({no:2, details:'New Payers successfully published!'})
        const processedUploadsNew = uploadedData.map(({name, familyID, amount})=> { return {name, familyID, amount}})
        setUploadedData(processedUploadsNew);
        setProsUploadsForPublish([...processedUploadsNew]);
        setUploadedDataDisplay('FILE');
        viewAllPayersHandler();
        //setUploadStarted(false)
    }else{
        //console.log('No data to upload!');
        //setUploadStarted(false)
    }
  }
    const tableBodyData = uploadedData?.filter(item=> item.familyID !== "");
    const renderedTab = {
        publishUpload:<OnlineOfferingPublishByUpload
            warningMsg={warningMsg}
            currentStep={currentStep}
            uploadedData={uploadedData}
            publishNewPayershandler={publishNewPayershandler}
            setSeudoUpload={setSeudoUpload}
            setUploadedData={setUploadedData}
            resetUploadFileHandler={resetUploadFileHandler}
            toggleAllPayersNewFileHandler={toggleAllPayersNewFileHandler}
            isNewPayer={isNewPayer}
            uploadDataDisplay={uploadDataDisplay}
            tableBodyData={tableBodyData}
            prosUploadsForPublish={prosUploadsForPublish}
            resetUploads={resetUploadFileHandler}
        />,
        publishManual:<OnlineOffPublishManual
            resetUploads={resetUploadFileHandler}
            type="Manually"
            allPayerData={allPayerData}
        />,
        publishPayers: <OnlineOfferingPayerManualUpload/>,
        queryPayers:<MembersInfoTable/>,
    }
    
    
    return (
        <div className='mb-4 bg-[#eee] p-3 border border-solid border-gray-600'>
           {renderedTab[selectedTabName]}            
        </div>
    )
}


export default OnlineOffering;


async function fetchPayersData(){    
      const response = await client.fetch(queryPayers); //id = query
      return response;
  }



  function createOfferingPayersDoc(form, no) {
    const {name, familyID } = form;
      
    const doc = {
        _id:familyID,
        _type: 'onlineOfferingPayers',
        name,
        familyID,
        sn:no,
      };
  
    return doc
  }
  
  const queryPayers =
  `
      *[_type == "onlineOfferingPayers"] | order(date desc){
          name,
          familyID,
          sn,
      }
   `;
   
  

   const queryPayersCount = `
   {
     "totalPayers":count(*[ _type == "onlineOfferingPayers" ]),
   }
 `;

 