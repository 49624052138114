//https://codepen.io/paulobrien/pen/LBrMxa
import React, {useState} from 'react';
import {MemberDataTableMain} from './components/MemberDataTableMain';
import getBirthDay from '../../../../utils/getBirthDay';
import sortArray, { sortByMembersStatus } from '../../../../utils/sortData';
import TableButtons from './tableTabs/TableButtons';
//import MemberView from './MemberView';
import {filterData} from '../../../../utils/filterData';
import { useReactToPrint } from 'react-to-print';
import { getFormField } from '../../assets/textFieldsMain';
//import { groupArrOfObjToArr, groupArrOfObjsToArrOfObjs} from '../../../../utils/groupArrElements';
import IndexReport from '../../../report/Index';
import Report from '../../../report/Report';
import { useSelector, useDispatch } from 'react-redux';
import useLocalStorageNew from '../../../../utils/hooks/useLocalStorageNew';
//import { disMembersData } from '../../../../context/reducers/adminReducer';
import TableButtonsSub from './tableTabs/TableButtonsSub';
import generateReportFunction from '../../../../utils/generateReportFunction';
import MemberViewReadOnly from './MemberViewReadOnly';
import { fetchContents } from '../../about/fetchDatamodules';
//import { ButtonCustom1 } from '../../components/Buttons';
//import DataUpload from './DataUpload';
//import { disAllData } from '../../../../context/reducers/user';
import { disMembersDataAll, disFilteredData, disFilteredDataObj } from '../../../../context/reducers/membersDataAll';
import CustomLoader from '../../components/CustomLoader';


const MembersDataView = () => {
    const dispatch = useDispatch();
    //const [uploadedData, setUploadedData] = useState([]);
    //showAllTableFlds:false,
  //memberTableViewAll:false,
    const {membersDataAll, filteredData, filteredDataObj} = useSelector((state)=>state.membersDataAll);
    const [selectedMemView, setSelectedMemView] = useState({});
    //const defData = membersDataAll || []
    const [uploadedDataDisplay, setUploadedDataDisplay] = useState([]);
    const [refreshTableDataCount, setRefreshTableDataCount] = useState(0);
    const [reportData, setReportData] = useState([]);
    const [refreshing, setRefreshing] = useState(false);

    const [toggleFreeze, setToggleFreeze] = useState(true);
    const [sortBy, setSortBy] = useState('');
    //const [showHiddenFlds, setShowHiddenFlds] = React.useState(true);
    const [seltdIndex, setSeltdIndex] = React.useState('');
    //const [reportReady, setReportReady] = useState(false);
    const [showAllFields, setShowAllFields] = useState(false);
    const [filterStart, setFilterStart] = useState(false);
    const [excludeHeaders, setExcludeHeaders] = useState(false);
    const [excludedHeadersArr, setExcludedHeadersArr] = useState([]);
    const [persistState, setPersistState] = useLocalStorageNew('@gofamintPSOgba', {defVal:'This is a defValue'});
    const [filteredInfo, setFilteredInfo] = useState({fld:'', val:''});
    const [filterTypeSelect, setFilterTypeSelect] = useState('general');
    
    //const componentRef = React.useRef();
    //console.log();

    const formFlds = getFormField('txtFldsHeader');
    const [closeReport, setCloseReport] = useState(true);
    const displayDataMainRef = React.useRef([]); 
    const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    //content: () => componentRef.current,
  });
  const handleClickMember =(fm)=>{
    setSelectedMemView(fm);
    //console.log(fm);
  }
  function handleShowAllFields(){
    setShowAllFields(!showAllFields);
  }
  function uploadedDataCall(data){
    const dataCopy = [...data];
    const newData = dataCopy.map((form)=>{
      return {...form, birthday: getBirthDay(form.dob),select:false, wedAnns:getBirthDay(form.wedAnn)}
    })
    //setUploadedData(newData);
    setUploadedDataDisplay(newData);
    displayDataMainRef.current = [...newData];
    setPersistState({...persistState, uploadedData: newData});
  }
  React.useEffect(()=>{
    //TODO: All Members data should only fetched if Admin. Resolved: This Page will only display is Admin
     setRefreshing(true); 
    // if(!membersDataAll.length){
        fetchContents('ALLMEMBERSDATA').then((res)=>{
          dispatch(disMembersDataAll(res.reverse()));
          //console.log(res)
          setRefreshing(false);
        });
      //}
  },[refreshTableDataCount]);

  //console.log(uploadedDataDisplay);
  function refreshDataTableHandler(){
    setRefreshTableDataCount(prev=>prev+1);
  }
  React.useEffect(()=>{
    if(membersDataAll.length){
      displayDataMainRef.current = [...membersDataAll];
      setUploadedDataDisplay(membersDataAll);
    }
  },[membersDataAll]);
  
  function handleCheckBox(i){
    const dataCopy = [...uploadedDataDisplay];
    dataCopy[i]['checked'] = !dataCopy[i]['checked'];
    setUploadedDataDisplay(dataCopy);
  }
  function clearSeltdMember(){
    setSelectedMemView({}); 
    setSeltdIndex("")
  }
  function sortHandler(order){
    //console.log(order);
    let sortOrder = {surname:'surname', firstname:'firstname', birthday:'dob', family:'family'};
    sortOrder =  sortOrder[order]? sortOrder[order] : false;
    if(uploadedDataDisplay.length>1 && sortOrder){
      const res = sortArray(uploadedDataDisplay, sortOrder);
    setUploadedDataDisplay(res);
    //console.log(sortOrder)
    }
    if(uploadedDataDisplay.length>1 && order === 'membership'){
      const sortRes = sortByMembersStatus(uploadedDataDisplay);
      setUploadedDataDisplay(sortRes);
    }
  }

  function filterHandler(el){
    setFilterStart(true);
    if(el.name === 'Filter none'){
      dispatch(disFilteredData('Members'));
     }else{
      dispatch(disFilteredData(el.name))
    };
    dispatch(disFilteredDataObj(el));
     
    const {fld, val} = el;
    const displayDataMainCopy = displayDataMainRef.current? [...displayDataMainRef.current] : []; 
    if(fld === 0){
        if(displayDataMainCopy.length>0){setUploadedDataDisplay(displayDataMainCopy);}
      }else{
        if(displayDataMainCopy.length>0){
          if(displayDataMainCopy[1].hasOwnProperty(fld)){
            const res = filterData(displayDataMainCopy, fld, val);
            setUploadedDataDisplay(res);
          }
        }
      }
  }

  //TODO: Membership field on report. ie.  "if(filteredData.includes('Members')){"
  function generateReport(){
    const data = [...uploadedDataDisplay];
    if(data.length>0){
      let reportRes = []; 
      if(filteredDataObj?.familySort){
        reportRes = generateReportFunction(data, 'family', 'family', 'famStat');
       }else{
        reportRes = uploadedDataDisplay.map(item=> { return {parents:[item], children:[]} });
       }
      setReportData(reportRes);
    }
    setTimeout(()=>setCloseReport(false), 300);
  }

  
  const handlePrintReport = useReactToPrint({
    pageStyle: pageStyle,
    content: () => componentRef.current,
  });
  
  const isLoading = uploadedDataDisplay.length < 1;
  if(isLoading && !filterStart){
    return <CustomLoader loadingMsg="Loading Members data, please wait!" color="blue" loading={isLoading}/>
  }

  return (
      <div className=''>
         
          {!closeReport && <IndexReport closeReport={()=>setCloseReport(true)} reportData={reportData} reportTitle={filteredInfo.val}/>}
          
          {/*<MemberView memberData={selectedMemView} 
            clearSeltdMember={clearSeltdMember}
            seltdIndex={seltdIndex}
            setSeltdIndex={()=>setSeltdIndex('')}/>*/}
          <MemberViewReadOnly
            memberData={selectedMemView} 
            clearSeltdMember={clearSeltdMember}
            seltdIndex={seltdIndex}
            setSeltdIndex={()=>setSeltdIndex('')}
          />
          <div className='p-2 cursor-pointer ml-10 mb-2 border border-blue-700 bg-[maroon] 
              active:bg-red-600 font-bold text-white w-fit flex flex-row items-center'
            onClick={refreshDataTableHandler}>
              Refresh Table Data
              {refreshing && <CustomLoader size={24} color="white" contPad='p-1'/>}
          </div> 
          <TableButtons toggleFreeze={toggleFreeze} setToggleFreeze={setToggleFreeze}
            sortBy={sortBy} setSortBy={sortHandler} 
            setUploadedData={uploadedDataCall}
            tableData={uploadedDataDisplay} 
            formFlds={formFlds}
            setFilter={filterHandler}
            generateReport={generateReport}
            printReport={handlePrintReport}
            showAllFields={showAllFields}
            handleShowAllFields={handleShowAllFields}
            excludeHeaders={excludeHeaders}
            setExcludeHeaders={setExcludeHeaders}
            excludedHeadersArr={excludedHeadersArr}
            filterTypeSelect={filterTypeSelect}
            setFilterTypeSelect={setFilterTypeSelect}
            />
          <TableButtonsSub excludeHeaders={excludeHeaders}
            excludeHeadersArr={arr=>setExcludedHeadersArr(arr)}/>
          <div className='w-full flex justify-center pb-10 bg-gray-100'>
          <MemberDataTableMain 
              uploadedData={uploadedDataDisplay} sortHeader={sortHandler} 
              toggleFreeze={toggleFreeze} sortBy={sortBy} handleClickMember={handleClickMember}
              formFlds={formFlds}
              //ref={componentRef}
              handleCheckBox={handleCheckBox}
              showHiddenFlds={showAllFields}
              seltdIndex={seltdIndex}
              setSeltdIndex={setSeltdIndex}/>
          </div>
          <div style={{display:'none'}}>
            <Report reportData={reportData} print={true} closeReport={true}
              reportTitle={[filteredInfo.val]} 
              ref={componentRef} hidePrintBtn/>
          </div>
      </div>
  );
};


const pageStyle = `
  @page {
    size: 210mm 297mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;



export default MembersDataView;

const datas = [{surname: 'James', firstname:'Olalekan', dob:'1900-10-5', addressRes: "PLOT 435, MICHAEL OKPARA WAY",},
  {surname: 'Bosede',firstname:'Damilare', dob:'2005-12-23', addressRes: "PLOT 435, MICHAEL OKPARA WAY, ABUJA, NIGERIA",},
  {surname: 'Fred', firstname:'Paul', dob:'', addressRes: "PLOT 435, MICHAEL OKPARA WAY, VICTORIAL ISLAND, LAGOS NIGERIA",},
  {surname: 'Abiodun',firstname:'Kelly', dob:'1900-1-22', addressRes: "PLOT 435, MICHAEL OKPARA WAY"},
  {surname: 'Aina',firstname:'Kemi', dob:'1900-12-25', addressRes: "MICHAEL OKPARA WAY",}];