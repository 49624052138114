import React from 'react';
import ChartCards from './ChartCards';
//import ReportTemplate from '../../../report/template/ReportTemplate';


const Dashboard = () => {

      
  return (
    <div className=''>
      <ChartCards/>
    </div>
  )
}


export default Dashboard;
