import React from "react";
import { Button1 } from "./InputsComponent";
import { SpanHLight } from "./TextSpans";



const ProceedToRegister =({surname, firstname, goToPage, type, hideProceedBtn})=>{
    

    return(
        <div className={``}>
            <div className='text-red-500'> {`${types[type][0]} `}   
                <SpanHLight col="white" bCol="red" text= {surname +" "+firstname}/>
                {` ${types[type][1]}`}
            </div>
            <p className='text-blue-600'>{notes.contactAdmin}</p>
            <div className={`${hideProceedBtn? 'hidden' : ""}`}>
                <Button1 title="New Registration"
                    onClick={()=>goToPage("RECORDREGISTER")}/>
            </div>
        </div>
    );
}


const CheckForParentInfo =({surname, firstname, goToPage, type, hideProceedBtn})=>{
    

    return(
        <div className={``}>
            <div className='text-red-500'> {`${types[type][0]} `}   
                <SpanHLight col="white" bCol="red" text= {surname +" "+firstname}/>
                {` ${types[type][1]}`}
            </div>
            <p className='text-blue-600'>{notes.contactAdmin}</p>
            <div className={`${hideProceedBtn? 'hidden' : ""}`}>
                <Button1 title="New Registration"
                    onClick={()=>goToPage("RECORDREGISTER")}/>
            </div>
        </div>
    );
}



export {ProceedToRegister, CheckForParentInfo};

const notes ={
    noInfo1:"We are sorry, we don't have enough information about",
    noInfo2: "on our database for you to confirm.",
    noInfo3:"We are sorry, you can't proceed further!. The information you supplied about", 
    noInfo4: "does not agree with what is on our database.",
    contactAdmin:"You can contact the Admin to grant you permission to update this record or click on the button below to do a new registration",
};

const types = {
    TYPE1:[notes.noInfo1, notes.noInfo2],
    TYPE2:[notes.noInfo3, notes.noInfo4],
}
