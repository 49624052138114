import React from "react";
import { client } from "../../../../../client";
import { getQueryAllMembersData } from "../../../admin/queries/queryMembersAllData";
import CustomLoader from "../../../components/CustomLoader";
import { getQueryPstCorner } from "../../../../pcorner/pstCornerQueries";
import { getHeaders } from "../sections/membersData/components/modules";
import { txtFldHeader } from "../../../assets/textFieldsMain";

const XLSX = require('xlsx');
const getQueryMembersDataInfos = `
*[ _type ==  "members"]{
  _id,id, surname, firstname,
}
`;


const queryDataMembersAll = getQueryAllMembersData();
const queryDataMembersInfos = getQueryMembersDataInfos;
const queryPstCorner = getQueryPstCorner(); 


const DownloadExcel =({contStyle, fileName, })=> {
    const [value, setValue] = React.useState('Members_IDs');
    const [downloading, setDownloading] = React.useState(false);
    const [dataToExport, setDataToExport] = React.useState([]);
    //console.log(membersData);   
    const states = {
      dataHeader: ["Membership Data"],
      selection: {
        Members_IDs: membersDataInfosKeysHeaders,
        Members_DataAll:membersDataKeysHeaders,
        Pastor_Corner:pCornerKeysHeaders,
      }
    };
  
    function fetchDataFromServer(query){
      fetchContents(query).then((res)=>{
        setDataToExport(res);
        exportToExcel(res, states.selection[value]);
      })
   }
  const onChangeHandler =(e)=>{
    const val = e.target.value;
    setValue(val);
  }

  React.useEffect(()=>{
    //fetchDataFromServer(state.selection[value].query);
    //console.log(value);
  },[value]);
  function resetDownloading(){
      setDownloading(false);
      setValue('Members_IDs');
  }
  function download() {
        if(downloading){ resetDownloading(); return}
        setDownloading(true);
        fetchDataFromServer(states.selection[value].query);
        //if(!fetchedDataMembersIDs.length){return} 

  }
  function exportToExcel(xportDatas, state){
    const isFileName = fileName || "SampleFile";
      const aoas =  [states.dataHeader, [state.title]]; //[state.dataHeader];
        aoas.push(state.headers);
        for (let i = 0; i < xportDatas?.length; i++) {
          //const {_id, id, surname, firstname} = fetchedDataMembersIDs[i];
          const xportData = xportDatas[i];
          let memberData = [];
          for (let j = 0; j < state.keys.length; j++) {
            const fld = xportData[state.keys[j]];
            memberData = [...memberData, fld];
          }
          const pushVal = state.name === "MEMBERSALL"? memberData : [i+1, ...memberData];
          aoas.push(pushVal);
        }
        const workSheet = XLSX.utils.aoa_to_sheet(aoas);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "Data");
        XLSX.writeFile(workBook, `.${isFileName}/File.xlsx`);
        //console.log(aoas)
        setDownloading(false)
  }

    return (
        <div className="border flex flex-row border-[maroon]">
          <div className="">
            <select className="w-full py-1 px-2 bg-sky-100"
              value={value} onChange={onChangeHandler}>
              <option>Members_IDs</option>
              <option>Members_DataAll</option>
              <option>Pastor_Corner</option>
             
            </select>
          </div>
          <div className={`px-2 active:bg-gray-300 
              flex flex-row justify-center items-center 
              ${downloading? 'bg-stone-300 text-gray-700' : 'bg-blue-600 text-white cursor-pointer'} 
              ${contStyle}`}
              onClick={download}
            >
            <p className="pr-2 py-1">Export to Excel</p>
            {downloading && <CustomLoader size={24} color="white" contPad='p-1'/>}
          </div>
      </div>
	  )
}

export default DownloadExcel;

const membersDataInfosKeysHeaders = {keys:['_id', 'id', 'surname', 'firstname'],
  headers:["SN", "_id", "id", "Surname", "Firstname"], title:"Membership info data",
  query:queryDataMembersInfos, name:"MEMBERSINFO"};

const pCornerKeysHeaders = {keys:['_id', 'messageID','sn', 'topic','date', 'bibleRef', 'body','pray', 'likes', 'comments'],
  headers:["SN", '_ID', 'MessageID','SN', 'Topic','Date', 'BibleRef', 'Body','Pray', 'Likes', 'Comments',],
  title:"Pastor Corner Messages",
  query:queryPstCorner, name:"PASTORCORNER"};

const txtFldKeys = Object.keys(txtFldHeader);
const txtFldsHeaders = getHeaders(txtFldHeader, txtFldKeys);
const membersDataKeysHeaders = {keys:txtFldKeys, headers:txtFldsHeaders, 
    title:"Membership Data", query:queryDataMembersAll, name:"MEMBERSALL"};


async function fetchContents(queryMembersDataInfos) {
  const response = await client.fetch(queryMembersDataInfos);
  return response;
}



