import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import IndexMemberStatement from '../../../admin/account/components/memberStatement/_Index';
import { fetchContents } from '../../../about/fetchDatamodules';
import { getQueryLoginMemberIDAndAccessIDs } from '../../../admin/queries/queryMembersAllData';
import { client } from '../../../../../client';
import { SpanHLight } from '../../../about/components/TextSpans';
import { disSeltdTab } from '../../../../../context/reducers/user';
import { tabsNav } from '../../../headerTabs/headerSchema';
import CustomLoader from '../../../../userpage/components/CustomLoader';
import login from '../../../../../context/reducers/login';

const MemberStatement = () => {
 const dispatch = useDispatch();
 const {onlineOffPayers, familyIDToFind} = useSelector((state)=>state.admin);
 //const [seltdMemberStmt, setSeltdMemberStmt] = React.useState(defStmt);
 const [famID, setFamID] = React.useState('');
 const [seltdMemberFamID, setSeltdMemberFamID] = React.useState('none');
 ///const {userRecords, userFetchedData, userFetchedDataOthers, } = useSelector((state)=>state.userSub);
 const {user, loginForm } = useSelector((state)=>state.login);
 const [membersObjData, setMembersObjData] = React.useState([]);
 const [infoMsg, setInfoMsg] = React.useState({show:false, msg:''});
 const [seltdIndex, setSeltdIndex] = React.useState('');
 const [memberInfo, setMemberInfo] = React.useState('');
 const [toDisplay, setToDisplay] = React.useState(true);

 
 function familyIDToFindHandler(famId, index){
  //TODO: Check for the name first in onlinePayers
      setSeltdIndex(index);
      const famIDFound = onlineOffPayers.find(item=> item.familyID === famId);
      if(famIDFound?.name){
        setSeltdMemberFamID(famId);
        setInfoMsg({show:false, msg:''})
      }else{
        setSeltdMemberFamID('none');
        setInfoMsg({show:true, msg:'Selected Family has no online offering record!'})
      }
 }
 function goToHome(){
  dispatch(disSeltdTab(tabsNav['RECORDABOUTHOME']));
 }
 //console.log(onlineOffPayers);
  const quickQuide = (
    memberInfo==="NODATA"?
    <div>
      <p className='text-red-600'>You can't view your Statement?</p>
      <p className='text-gray-800'>You probably have not updated or registered your record.</p>
      <div className='text-gray-600'> Click on <span onClick={goToHome}><SpanHLight text="Home" col='black' bCol="#a6dcdc" border cursor/></span> tab above and follow the steps to update or register your records.
      </div>
    </div>
    :<p className='font-bold underline'>{memberInfo}</p>
  );
  const quickQuideOnline = loginForm.isOnline? quickQuide
      :(<p className='font-bold text-red-500'>Login to view your statement</p>);

   //console.log([membersObjData, memberInfo]);
   const quickQuideDisplay =  toDisplay? (<CustomLoader loadingMsg="Checking for your online offering data, please wait!" textCol='blue' color="blue" 
    loading={true} size={30}/>) : quickQuideOnline;
   

   React.useEffect(()=>{
      if(membersObjData.length>0){setToDisplay(true)};
      function setDisplay(){
        setToDisplay(false); 
      }
      setTimeout(setDisplay, 5000);
    },[]);

  React.useEffect(()=>{
    if(user.loginID){
      const query =  getQueryLoginMemberIDAndAccessIDs(user.loginID);
      //console.log(user);
      fetchContents('QUERY', query).then((res)=>{
        if(res.length){
          //console.log(user);
          const {id, accesspermit } = res[0];
          const otherIds = accesspermit.split('|');
          const allIDs = [id, ...otherIds];
          fetchMultiplContents(allIDs);
        }
      });
      }
  },[user.loginID]);

  async function fetchMultiplContents(arr) {
    const membersData = [];
    for (let i = 0; i < arr.length; i++) {
      const memberPersonalID = arr[i];
      const queryId = getQueryMembersData(memberPersonalID);
      await client.fetch(queryId).then((res)=>{
        if(res.length){
          membersData.push({family:res[0]?.surname, familyID:res[0]?.family});
        }
      })
      }
      setMembersObjData(membersData);
  }

  React.useEffect(()=>{
    if(membersObjData.length){
      setMemberInfo('Click on a family to view their Statement if any');
    }else{
      setMemberInfo('NODATA');
    }
  },[membersObjData]);
  return (
    <div>
        <div className='my-4 flex-row hidden'>
          <div className='bg-[blue] text-white px-5 py-1 mr-3'
            onClick={()=>familyIDToFindHandler(famID)}>Enter</div>
           
          <input placeholder='FamilyID to Find' className='border border-solid border-blue-600'
            value={famID} onChange={e=>setFamID(e.target.value)}/>
            <p>FamilyIDToFind:{familyIDToFind}</p>
        </div>
        
        <div className='ml-5 '>
           {
              membersObjData.length?
              <OnlineDisplay
                membersObjData={membersObjData}
                seltdIndex={seltdIndex}
                familyIDToFindHandler={familyIDToFindHandler} 
                infoMsg={infoMsg}
                seltdMemberFamID={seltdMemberFamID}
                loginForm={loginForm}
              />
              :quickQuideDisplay       
           }

        </div>
        
    </div>
  )
}


const OnlineDisplay=({membersObjData, seltdIndex, familyIDToFindHandler, 
    infoMsg, seltdMemberFamID, loginForm})=>{
  
  if(!loginForm.isOnline){ return (<></>)}
  return(
    <div>
        {
            membersObjData?.map((item, i)=>{
              return (
                <p key={`${i}+memb`}
                  className={`pl-2 rounded-md font-bold cursor-pointer active:text-[cyan] text-[mediumblue] mb-1 max-w-[250px]
                      ${seltdIndex===i? 'bg-[cyan]' : 'hover:bg-[cyan]'}`}
                  onClick={()=>familyIDToFindHandler(item.familyID, i)}>
                  {i+1}. The {item.family}s
                </p>
              );
            })
          }
          <p className='text-red-500'> {infoMsg.msg}</p>
        <IndexMemberStatement
            seltdMemberFamID={seltdMemberFamID}
            //setSelectedDate={setSelectedDate}
            type='USER'
        />
    </div>
  )
}
export default MemberStatement;

function getQueryMembersData(id){

  const memberData = `
  *[_type == "members" && id == '${id}'] | order(_createdAt desc) {
      id,
      accesspermit,
      surname,
      family,
   }
`;
  return memberData
}

/*
async function fetchMultiplContentss(arr) {
  console.log(arr)
  const membersData = [];
  for (let i = 0; i < arr.length; i++) {
    const memberPersonalID = arr[i];
    const queryId = getQueryLoginMemberIDAndAccessIDs(memberPersonalID);
    await client.fetch(queryId).then((res)=>{
      console.log(res)
      membersData.push({family:res.surname, familyID:res.family});
    });
    }
  return membersData
}

const defStmt ={
    name:'',
    data:'',
    activities:[],
    activitiesShort:[],
    Total:'',
}


const queryPayers  = `{'offeringPayers':*[ _type == 'onlineOfferingPayers'] | order(sn asc){
    sn, name, familyID   
  },
  'offerings':*[ _type == "onlineOffering"]{
    _id, docID, 
     date, program, type, total, 
     dateDetails,
     data[]{amount, 'familyID':onlinePayer._ref}
  }
}`;
 
const queryOfferings = `
  *[ _type == "onlineOffering"]{
      _id, docID, 
       date, program, type, total, 
       dateDetails,
       data[]{amount, 'familyID':onlinePayer._ref}
  }
`;*/