import React from 'react';
import ChevronUpdown from '../../../assets/svg/ChevronUpdown';
import { useDispatch, useSelector } from 'react-redux';
import logo from "../../../assets/gofamintLogo.png";
import { disSeltdTab } from '../../../context/reducers/user';
import { FaUser } from 'react-icons/fa';
import {tabList, tabListBasic, tabListAdmin, tabListGuest, tabListNoneMember} from './tabList';
import {MdOutlineArrowBackIos,  MdOutlineArrowForwardIos} from 'react-icons/md';
import { Link } from 'react-router-dom';
import { tabsNav } from './headerSchema';

const HeaderBar = ({scrollDirection, hideHeader, setHideHeader}) => {
    const dispatch = useDispatch();
    //const userState = useSelector((state)=>state.user);
    const {seltdTab} = useSelector((state)=>state.user);
    const {userType, loginForm, loginUser} = useSelector((state)=>state.login);
    //const [selTab, setSelTab] = React.useState([0,0,{name:'Home',title:'RECORDABOUTHOME'}]);
    const [hideBTabs, setHideBTabs] = React.useState(false);
    const scrollRef = React.useRef(0);
    const isNotmember = loginForm.checkedMember === "nonmember";

    function tabsHandler(tabs){
      //selTab = [parentTabNo, childTabNo, {name:'tabName', title:'tabTitle'}]102523766629932282547
      //setSelTab(tabs);
      dispatch(disSeltdTab(tabs))
    }
    React.useEffect(()=>{
      //Always return to About Home Tab if NOTAMEMBER
      if(isNotmember){
        const aboutHomeTab = tabsNav.RECORDABOUTHOME;
        dispatch(disSeltdTab(aboutHomeTab))
      }
    },[isNotmember, loginUser.user, dispatch]);
    const memberType = loginForm.checkedMember === 'member'?  tabListBasic : tabListNoneMember;
    const guestType = loginUser.user === "GUEST"? tabListGuest : memberType;
    //SecurePage- 3 of 3
    //const tabLists = tabList;
    
    const tabLists = userType === 'DEVELOPER'? tabList : userType==="ADMIN"? 
          tabListAdmin : guestType; //loginForm.adminPass && user.userID !=="102523766629932282547"? tabListAdmin : loginForm.adminPass && user.userID==="102523766629932282547"? tabList : tabListBasic; 

    function handleScroll(scrollOffset){
      
      if(scrollOffset==='LEFT'){
        scrollRef.current.scrollLeft -= 100;
      }else{
        scrollRef.current.scrollLeft += 100;
      }
    }
    function goToHomeHandler(){
    }
    const isNoneMember = tabLists[0].name === "";
    //if(isNoneMember) return
    
  return (
        <div className={` ${ scrollDirection === "down" ? "fixed top-0 w-full" : ""}  
             ${isNoneMember? 'hidden' : ''} border-t-2 border-gray-200 border-solid 
            py-0 pl-0 px-0 bg-[#01013c] text-white flex border-b-blue-700 border-b-[3px]
             `}>
              
                <div className='flex px-2 justify-center flex-col items-center bg-[#01013c] w-[45px]'>
                  {!hideBTabs && 
                    <Link to="/">
                      <img src={logo} alt="logo" className="w-[30px] cursor-pointer active:bg-white" 
                        onClick={goToHomeHandler}/>
                    </Link>}
                  <ChevronUpdown size={22} name={hideHeader? "down" : 'up'} 
                    className='cursor-pointer fill-white  hover:fill-[#0000ff]'
                    onClick={()=>setHideHeader(hideHeader? false : true)}/>
                </div> 
                <div className={`px-1 sm:px-2 self-end cursor-pointer  ${userType==='USER'? 'hidden' : ''}`}>
                  <MdOutlineArrowBackIos className='text-white active:text-gray-400' size={30}
                    onClick={()=>handleScroll('LEFT')}/>
                </div>
                <div className='overflow-x-auto flex w-[calc(100%-87px)]' ref={scrollRef}>  
                {tabLists?.map((tabs, index)=>{
                  
                  return (
                     
                    <Tabs 
                      key = {`${index}+item`} index={index} tabNo= {seltdTab[1]}  
                      tabs={tabs?.tabs}
                      selTabs={seltdTab[0]===index? true : false} 
                      tabsHandler={tabsHandler}
                      hideBTabs={hideBTabs}
                      noOfTabs={tabLists.length}>
                      {
                        <div className='flex text-base justify-center px-2'>
                          <FaUser size={22} name={hideHeader? "down" : ''} 
                            className='cursor-pointer ml-1  hover:fill-[black]'
                        />
                        {tabs?.name}
                        </div>
                      }
                    </Tabs>
                    
                    );
                  })
                }
                </div>
                <div className='px-1 sm:px-2 self-end cursor-pointer'>
                  <MdOutlineArrowForwardIos className={`text-white active:text-gray-400 ${userType==='USER'? 'hidden' : ''}`} size={30}
                    onClick={()=>handleScroll('RIGHT')}/>
                </div>
                <div className='px-1 sm:px-2 self-end bg-gray-300 w-[40px]'>
                  <ChevronUpdown size={22} name={hideHeader? "down" : 'up'} 
                    className='cursor-pointer  hover:fill-[black]'
                    onClick={()=>setHideBTabs(!hideBTabs)}/>
                </div> 
        </div>       
  );
};

export default HeaderBar;





const Tabs=({children, hideBTabs, tabs,tabsHandler, tabNo, index, selTabs, noOfTabs})=>{

   const tabscont = `${hideBTabs && 'hidden'} border-t-[#94d0cf] text-black border-solid border-t-[2px] overflow-x-auto c-scrollbar whitespace-nowrap transition-all duration-300 flex flex-row items-center cursor-pointer`;
   const tabStyle= `flex px-2 text-[14px] sm:text-base justify-center items-center flex-1 bg-[#94d0cf] hover:bg-[cyan]`;
  return(
    <div className={`${selTabs? 'bg-[#3f9a98] text-white' : 'bg-[#226a6a] text-black'} z-20 mb-[1px] mr-[3px] py-0  border-2  border-solid 
        ${noOfTabs ===1? 'w-[98%]' :''}`}>

        <div className="block ">
            <div className="flex justify-center">{children}</div>
            <div className={tabscont}>
              {tabs?.map((tab, i)=>{
                return (
                  <h1  key = {`${i}+tab`} 
                    className={`${tabStyle} ${tabNo===i && selTabs? 'bg-[cyan]': 'bg-[#a6dcdc]'} mx-[1.5px] py-[2px]`}
                    onClick={()=>tabsHandler([index, i, tab])} >
                    {tab.name}
                  </h1>
                );
              })}
            </div>
           
        </div>
    </div>
  );
}