const pastorCornerLatest = [        
    {sn:	51,
    title:	`The Power of His Resurrection`,
    bibleRef:	`"All I want is to know Christ and to experience the power of his resurrection, to share in his sufferings and become like him in his death” (Philippians 3:10 GNB).`,
    body:	[`Apostle Paul in his letter to the Philippian Christians talked of knowing the power of the resurrection of Christ Jesus and having the fellowship of His suffering. Paul not only desired to know the power of Christ, but also have a fellowship of Christ's sufferings. The GNB says “to share in His sufferings”. Knowing Jesus therefore also means knowing His power and the fellowship of His sufferings. Jesus suffered that humanity can be reconciled to God through Him. Being children of God, believers and followers of Christ therefore makes that suffering of Christ part of our heritage. We must truly  be part of the family of suffering. `,
        `In Romans 8:17, Apostle Paul made this point of the power and sufferings of Christ parts of our heritage when he wrote that: “...if children, then heirs' of God and fellow heirs with Christ, provided we suffer with Him in order that we may also be glorified with him.” What are those sufferings? They include being rejected because we would not bow to the world standard and all political correctness that contradicts the word of God, just as Christ was rejected even by the so called leaders of religion. `,
        `His sufferings that we share in also includes daring to stand on the side of God and the truth even as Shadrach Meshack and Abednego did; even in the face of threat of death and they were bold to king Nebuchadnezzar. Daniel 3:16-18 says: “Your threat means nothing to us. If you throw us in the fire, the God we serve can rescue us from your roaring furnace and anything else you might cook up, O king. But even if he doesn’t, it wouldn’t make a bit of difference, O king. We still wouldn’t serve your gods or worship the gold statue you set up” Daniel 3:16-18 (MSG). In the little corner of our professions, occupations, vocations, callings, jobs, employments, careers and businesses, how much are we willing to share in the sufferings of Christ by refusing to conform with the world? `,
        `This was the desire of Apostle Paul who said of himself when he spoke about the real believers in Philippians 3:3-6. If we are more controlled by our impressive credentials and influences and would rather compromise Faith than loosing them, Apostle Paul then talks of his pedigree that: “though I myself have reason for confidence in the flesh also. If anyone else thinks he has reason for confidence in the flesh, I have more...” (vs.4). He goes on to say that he was circumcised on the eighth day, being an Israelite, that he is of the tribe of Benjamin, he is a Hebrew of Hebrews; and that when it comes to the law, he is a Pharisee. That he was so zealous about everything Jewish that he persecuted the church. And that when it comes to righteousness under the law, he was blameless. (Vs.5-6) `,
        `He concluded by saying in verses 7-8 that: “The very credentials these people are waving around as something special, I’m tearing up and throwing out with the trash, along with everything else I used to take credit for. And why? Because of Christ. Yes, all the things I once thought were so important are gone from my life. Compared to the high privilege of knowing Christ Jesus as my Master, firsthand, everything I once thought I had going for me is insignificant—dog dung. I’ve dumped it all in the trash so that I could embrace Christ and be embraced by him. (MSG)`,
        `That is the fellowship of His sufferings. Are we doing that as believers of today? I pray that we will truly be prepared to suffer for the sake of Him who suffered for us to be reconciled to God and experience His power.`],
    pray:	`Heavenly Father, I thank You for this day and I thank You for giving us Jesus to die for our sins. Father, help us to truly be willing to know Him and share in His sufferings in Jesus name. Amen.`,
    comments:	[],
    details:	{author:"Admin", date:"Apr, 2022"}},
        
    {sn:	52,
    title:	`Taste and See`,
    bibleRef:	`“O taste and see that the Lord is good: blessed is the man that trusteth in him.” (Psalms 34:8 KJV)`,
    body:	[`The definitions of taste are: (1) the flavour of something, or (2) the ability of a person or animal to recognize different flavours:`,
        `In Psalm 34:8, King David writes: “taste and see”. Why did he not say that we should “see and taste?” The answer is found in what Jesus said to one of His disciples called Thomas in John 20:29 , "Because you have seen me, you have believed; blessed are those who have not seen and yet have believed."`,
        `To taste before you have the time to see is a matter of trust, faith and reliance. I have heard of new restaurants holding what is called tasting session whereby they invite people to attend the tasting sessions. It simply an invitation to have a taste of their delicacies freely and enjoy it, and thereby keep coming back for more. It is assumed that once you taste their food, you will come back for more.  This can be likened to our text of today. The Word says: “Taste And See That The Lord Is Good.” `,
        `To “taste” God is to experience His goodness, His Mercy and His love when we can truly trust Him, and He shows forth His power over our situations in an amazing way. It also means that we take a leap of faith; and something that validates the love of God for us and His power of deliverance happen to us. `,
        `Prior to your tasting God, there is always an old expression of doubt. This is the kind of Thomas’s doubt in  John 20:25. Because we cannot see God, the subconscious pushes man to only believe in the things that we can see. `,
        `The life of any believer in Christ is a matter of trust. Always trusting God no matter the situation because we cannot see the future; but God can. And He already says in Romans 10:11 that: “...Everyone who believes in him will not be put to shame." `,
        `And so, trusting our lives and future to the One who holds that future is the only sane decision that man should actually take. `,
        `No matter how much any other person talks about how sumptuous a meal is or how sweet the pudding is, such can only stir out taste buds; it can never translate to our own experience. Therefore King David says, take a step of faith! Trust in this God (because I have)! And you will see that He is good. King David is saying: Try Him out. Just like you would be invited to taste the meals in a new restaurant; and He affirmatively says you will see that God is good. `,
        `Psalm 34 is a Psalm of praise and a Psalm of thanksgiving, but it’s also a Psalm of invitation. David wrote Psalm 34 just after he had been saved from a situation where death seemed likely (see 1 Samuel 21) David was saved and he is full of praises to God. David knew that there were others who were also suffering, others who were afflicted and some others who are in one kind of problem or the other, but who in doubt of God’s care for them. And so he is appealing to such people to turn to God and see that He is a good God. `,
        `Brethren, are you in any kind of problem or challenge take to the invitation of king David. The only credential you need is to be connected to Him through Christ Jesus, the Messiah and you will not be disappointed. If God has spoken a word concerning your situation, take the Word, hold unto it in the name of Jesus and your testimony will be so amazing that you will be the next Andrew in John 1:40 or the next king David in Psalm 34:8; inviting others to God through Jesus Christ; so that they too can “Taste and see that God is good” and Jesus is real.`],
    pray:	`Heavenly Father, I thank You for Your Word, I thank You For Your Goodness and I thank You for giving us Jesus; Lord Jesus, thank You for Your death and Your resurrection. I commit my life and situation to You Lord and I thank You for being good to me in Jesus name. Amen.`,
    comments:	[],
    details:	{author:"Admin", date:"May, 2022"}},
        
    {sn:	53,
    title:	`The Enemy Will Be Destroyed`,
    bibleRef:	`“Woe (judgment is coming) to you, O destroyer, You who were not destroyed, And he who is treacherous, while others did not deal treacherously with him. As soon as you finish destroying, you will be destroyed; As soon as you stop dealing treacherously, others will deal treacherously with you.” (Isaiah 33:1 AMP)`,
    body:	[`When we face problems and antagonisms that make us to wonder  whether there is going to be an end to the attacks, be rest assured that God sees it all and He’s on top of the situation if we are in Him. `,
        `There was a time in history that believers were being picked up and killed by king Herod. Believers were slaughtered or thrown to the lions for preys while the king and his apologists watched and laughed. Also, in Biblical history,  Judah also had terrible attack from the Assyrians; and in Isaiah chapter 33 the Prophecy of Isaiah came with a warning of “Woe to you, destroyer, you who have not been destroyed! Woe to you, betrayer, you who have not been betrayed...” (NIV) `,
        `The Assyrians of the Bible time were extremely treacherous people. They were so wicked that they would often mutilate their prisoners of war, cutting off their tongues or gorging out their eyes. The cruel treatment of the captives of the Assyrians were so much that whenever the Assyrian army surrounded any city, the inhabitants of the city would commit suicide rather than to be taken captives by the Assyrians and be exposed to the humiliating torture of the Assyrians. Isaiah 33:1-8 revealed further that the Assyrians had also taken captive many of the people of the cities of Judah. But Judah knew what to do. Judah called on their God and God responded to Judah by revealing to Judah, what He would do to the Assyrians. God said: “Now I will act, I will show how powerful I am. You make worthless plans and everything you do is useless. My spirit is like a fire that will destroy you. You will crumble like rocks burnt to make lime, like thorns burnt to ashes. Let everyone near and far hear what I have done and acknowledge my power” (Isaiah 33:10-13 GNB). God was going to burn the wicked enemy of His Children in His fire. This is how God will deal with our enemies because we are His Children, adopted through the precious Blood of Jesus.`,
        `Brethren, no matter the overwhelming attack of the enemy, we only need to call on God and trust Him. And the enemy that is mocking us today or saying our God cannot deliver us will all fail just as the Assyrian king failed as recorded in 2 Kings 18:28-35. With God on our side, the threats of the enemy is an empty threat. It is like the barking of a toothless bulldog. God is a consuming fire. (See Hebrews 12:29 and Psalm 97:3). The Lion of the tribe of Judah has already prevailed - Revelation 5:5`,
        `Therefore, no matter the attack of the enemies, stand on the Word of God. By the word and power of God you will have the victory and your destroyer will be destroyed in Jesus name.`],
    pray:	`Heavenly Father, thank You for this day,  thank You for Your Word and I thank You Lord for  by Your fire my enemies shall be destroyed in Jesus name. Amen.`,
    comments:	[],
    details:	{author:"Admin", date:"May, 2022"}},
        
    {sn:	54,
    title:	`God’s Result Terminates Insults`,
    bibleRef:	`“ How long will you love what is worthless and go after what is false?”`,
    body:	[`We live in a very snobbish world and a bullying society. There are also people who either just take delight in making life difficult for others or they just want others never to be able to stand up to them. While some others just like to mock their fellow men and never seeing any good in them; even when those ones are putting their best humanly possible efforts. Therefore, the snobbish and bullish people will always do everything to down play the strengths and opportunities of the others. This can certainly be very painful; especially when their best are met with other people’s destructive and negative attitudes towards them.`,
        `The ultimate of these negativities is that the bullies will always put insults upon the injuries already created by them.  And so, King David in our text today questioned that: “How long will people continue in their negativity?" The Bible gives us the situation that Daniel found himself in Babylon as an example of the evil that men can do against another just because that other is doing good. In Babylon, Daniel showed himself as an exceptional leader in the Empire. And for His exceptional qualities they sought to bring him down (See: (Daniel 6: 3-5).`,
        `In the song, “In Christ Alone”, the Composer of that song wrote: “This gift of love and righteousness; Scorned by the ones He came to save”. The people we are trying to help are often the ones who join forces to attack us. Therefore, when we go through this type of battle, we need not be surprised; but only seek the wisdom of God and guidance on how to handle the bullies. And how to handle such bullies and conquer them is by the power of God through His word. At such times, the word of the Psalmist in verse 4 of today’s text wherein he said: “...know that the LORD has set apart the godly for Himself; the LORD hears when I call to him" comes in aid.`,
        `May be you are facing unwarranted antagonism and insult and like king David, you are asking: “How long will this continue...?’ I have good news for you. No matter the bully and antagonism, according to the word of God, you will have victory because God has set you apart for Himself and He has promised to answer when you call Him. He will surely answer. He will lift His countenance upon you as stated in verse 7 and He will bring you an exceptional result no matter the circumstances. `,
        `Brethren, God’s presence in our lives as His children will always bring about exceptional results that will silence all the snobbish attitudes of the bullies and mockers. And, when He does, nobody will be able to argue with or ignore God’s positive results in our lives in Jesus name. And when God’s result manifests, then like the Psalmist, we will sing: “When the Lord restored the fortunes of Zion, we were like those who dream (Psalms 126:1). We will laugh and others will also recognise what God has done for us and they too will acknowledge the great things that God has done for us. Brethren, I encourage us to stand on the word of God and trust Him, like Daniel trusted, like shadrach Meshach and Abednego trusted, and like Joseph also trusted God; and all of them had the last say, we will have the last say because God’s result will  terminate man’s insult in the lives, families and careers of His children.`],
    pray:	`Heavenly Father and my God, I thank You for Your word today. Lord Jesus, let God's divine result silence every bully and terminate every insult in my life in Jesus name. Amen.`,
    comments:	[],
    details:	{author:"Admin", date:"May, 2022"}},
        
        
    {sn:	55,
    title:	`In time of grief, find Christ`,
    bibleRef:	`“Jesus said to her, “I am the resurrection and the life. The one who believes in me will live, even though they die” (John 11:25 NIV).`,
    body:	[`At the time that Jesus made the above statement, it was a time of grief in the family of Mary and Martha, whose brother Lazarus had just passed on. When a loved one passes on suddenly, whether young or old, everything is never all right. The family and friends of the departed do feel the hurt and also they hurt for a long time. We feel the pain because relationships come with a price - i.e. times of joy and times of sorrow. But we all will go through loss of relationship at some point in life. The loss of a relationship therefore brings grief and people grieve for deep and for long. Grieving moment is not something that anyone can treat as if it is a stranger that can be sent away. It is therefore fine to grieve the lost relationship and to also grieve honestly, but patiently. Eventually the cup of grief will get emptied. Grieving and getting the cup emptied is the way back to your hale, healthy, robust, sound, and well appearance.`,
        `In the story behind our main text today, at Bethany, Lazarus had died and everyone was sad. The family also gathered to mourn the loss. This was during the time of Jesus, more than 2,000 years ago. And so, it is not different from how we grief till today. What brings grief is death, but the truth is that every man or woman on this planet earth will die one day and the death of one will affect another man, woman or child regardless of their nationality, wealth or status in life; just as it was a brother that had died in John Chapter 11; leaving behind  his two sisters by names Mary and Martha. They loved their brother very much and so were they very sad.`,
        `Lazarus also had friends, and one of them was our Lord Jesus Christ. So, Jesus was also affected by Lazarus’ death. And so it was recorded in John 11:35 (shortest Bible verse) that: “Jesus wept.” Jesus had done and was well popular for all His miracles all over Israel and so when eventually Jesus arrived at the village of Bethany and the house of Lazarus,  Martha went out of the house to meet Jesus; and then made a bold statement that: “Lord, if only you had been here, my brother would not have died.” `,
        `Brethren, just like Martha, in time of grief we also feel let down. Martha’s statement was an expression of a feeling of let down by Jesus Christ because they had actually sent for Jesus a few days previously when Lazarus was sick (John11:1-7). But Jesus does not let anyone down. If He delays, or does nothing it is that God may be glorified. And, at such times that we think He did nothing or delayed, do we not also express the same words that Martha did? The words: “If only.” If only we had done something different. If only he/she had gotten treatment earlier. If only I had …… If only God had ….. Brethren, the time comes when we must accept realities rather than fill our lives and world with the questions of “if only and if onlys” `,
        `In this world bad things happen to all people, be they good or bad people. And in fact, bad things do happen to good people more than to those we perceive to be bad. Good people get sick, they have accidents, they have heart attacks and all manner of ills of life come to them. And so, sometimes good comes and sometimes bad comes. That is the world we live in. This we also see in the conversation of Job with his wife in Job 2:10 that: “Shall we accept good from God, and not trouble...?” (NIV). This is the reality of our existence. There are times we hope and pray that God would break in – “If only God would …” yet sometimes God would not change the order of things and we are hurt.`,
        `The good news however is that God does not ever leave His own without hope, just as we see in the story of Lazarus that Jesus did not leave Martha without hope. Jesus gave her a promise: “Your brother will rise again. Your brother will live again. Your brother will be with you again.” Such Promises would have brought some faint smile to the face of Martha. Brethren, in our moment of grief, let us bring to heart the truth of the Word of Jesus that somewhere in the future there will be no more pain, no more sorrow, no more disease; and this should bring a smile to our faces. It is the thought of a day when those who are true children and friends of God will live forever. Although, Martha had the same thought and responded that: “I know that Lazarus will rise again in the resurrection at the end of time.” (John11:24). But then, Jesus instantly said to her: “I am the resurrection. I am the life. Whoever believes in me, though he dies, yet shall he live. Everyone who lives and believes in me shall never die. Do you believe this?” Martha acknowledged her belief in Jesus being the “...Messiah, the Son of God, who is to come into the world.” (Vs.27 NIV). `,
        `In a moment of flash, Martha’s life changed. She had started the day being confronted with the death of her brother, but she ended it with having eternal life in Christ. She started the day with doubts and sadness but she ended it with joy and assurance. This was because she put her faith in Jesus and it was the moment that everything changed for her, and she became full of hope, full of joy, even in the midst of her sadness. God touched her in a way that she had never been touched before. Martha believed in the Lordship of Jesus; Martha had faith in eternal life with Jesus.`,
        `Brethren, in times of grief, Christ is available for us to find Him. In the story of Lazarus, it is the miracle of the raising up of Lazarus from death that is often more focussed on; but of a truth, two miracles happened on that day. And the first was Martha’s confession of her faith in Christ and this gave her eternal life. This is the more important of the two miracles. At the time of the grief of the loss of a loved one, it is important for us to check our faith. For us to search our hearts and ask ourselves the question that Martha answered. Do we believe in Jesus’ promise that: Whoever believes in me, though he dies, yet shall he live. Everyone who lives and believes in me shall never die." Are we living in Him? If we believe, then we have hope; If we believe, then we have eternal life. But if you have not believed in Him, it is not too late. Today you can call on Jesus, and say to Him, "Yes Lord I believe." Ask him to come into your life and when you do a miracle will take place and you will receive the promise of eternal life. Because, whether it is now or hundred years to come, every human being is going to die one day and everyone will face eternal life. But whether you will spend it with Christ or not will be dependent on your belief in Him or not. I pray that the Almighty God will strengthen everyone going through a moment of grief. But, in our moment of grief, let us find Christ.`],
    pray:	`Heavenly Father, I thank You for Your Word today; Lord, in the moment of grief, let us find Christ in Jesus name. Amen.`,
    comments:	[],
    details:	{author:"Admin", date:"May, 2022"}},
        
    {sn:	56,
    title:	`With Many Or With a Few`,
    bibleRef:	`“And Asa cried out to the Lord his God, and said, “Lord, it is nothing for You to help, whether with many or with those who have no power; help us, O Lord our God, for we rest on You, and in Your name we go against this multitude. O Lord, You are our God; do not let man prevail against You!” (2 Chronicles 14:11 NKJV).`,
    body:	[`In Chapter 14 of 2 Chronicles, when Asa was king of Judah and the Ethiopians, with vast army numbering millions came against Judah who had just a few soldiers, this fearful army obviously posed a great threat to the kingdom of Judah who had an army of just a total of 580,000 men (2 Chronicles 14:8 GNB). The enemy's army was almost twice as large, but Judah had God. And Asa the king of Judah trusted in the power of God. Asa knew that God’s power was not limited by the small army of Judah. Asa knew what God did for Judah under the reign of Abijah, his father in 2 Chronicles 13:3. And because he trusted in the power of God and not in the strength of the enemy or the smallness of his army, Asa cried out to the Lord his God and prayed. `,
        `Asa understood that the power of God is not enhanced or limited by man’s apparent strength or weakness. King Asa also recognised that this battle belonged to the Lord and so, he called upon God to defend His honour; his prayer was: “Lord, do not let man prevail against You”.`,
        `What Asa did was to remind God that he (Asa) knows that God has the entire responsibility to defend His Honour. And God answered instantly, because He is a God of His word. He will not only defend His children but will defend the Honour of His name. Hence He said in Isaiah 48:11 that: “For My own sake, for My own sake, I will do it; For how should My name be profaned? And I will not give My glory to another.”`,
        `Brethren, as children of God, never forget that God not only has all the powers to defend us and defend His name in our lives, but He has the responsibility to do so and He certainly will do so because He must be glorified in our lives. The battles we face in life as children of God are not ours but God’s battles. The day we are born into the world, we are born into the battle field and the moment anyone gives his/her life to Christ, the battle line is drawn. However, since we have the Jehovah Nisi as our God, victory is as sure as day will follow night. `,
        `And so, because Asa called on His God to defend His honour, the Bible says in verse 12 that “...the LORD struck the Ethiopians and they fled before Asa”. God fought on behalf of King Asa and the kingdom of Judah. I pray that whatever may be the battle that we or any of our loved one is facing as we call on God, He will fight so effectively that all the enemy will be broken before the Lord and we will have the victory in Jesus name. `,
        `Therefore brethren, whether with many or with a few as king Asa prayed, it is nothing for God to help. We only need to call on Him and He will fight and win the battle for us so that We can praise Him and honour will be unto His name alone. God fought for Asa and Judah, and they were not only delivered from the danger, they were also enriched. When the Lord fights and wins our battle for us He will also restore to us all that we might have lost. This is what the Word of God means in Romans 8:37 that we are more than conquerors in the Lord. God not only makes you the conqueror, He also restores to you in multiples, everything that you might have lost. `,
        `So, let us call on Him today and throughout these twenty-one days of fasting and prayer and He will fight for us because, whether with many or with a few, God will always win the battle for His children. Hallelujah!`],
    pray:	`Heavenly Father, I thank You for this day and for Your Word. Lord, I call upon You over this battle (mention it); fight for me Father and take all the glory in Jesus mighty name. Amen.`,
    comments:	[],
    details:	{author:"Admin", date:"Jun, 2022"}},
        
    {sn:	57,
    title:	`It Will End In Praise`,
    bibleRef:	`“Who are you, O great mountain? Before Zerubbabel you shall become a plain. And he shall bring forward the top stone amid shouts of 'Grace, grace to it!" (Zechariah 4:7 ESV).`,
    body:	[`The work of rebuilding the temple became a very big challenge to Zerubbabel that it seemed like a great mountain. In Zechariah 4:3, God said to the Prophet in a vision that, ‘Not by might nor by power, but by My Spirit.' The work of God also needs resources, and not the resources of human might or power.`,
        `God says, “not by the resources of many or one, but by My Spirit." `,
        `Any challenge or problem that confronts the children of God is never overcame by our cleverness, ability, physical strength or connection, but by the Spirit of God. This why God had to send down the Holy Spirit on the day of Pentecost. `,
        `The necessary resource for God’s work is the Holy Spirit and God promised Zerubbabel a rich resource in the Spirit of God to accomplish His work. When we trust in our own resources or power or connection; whether they be small or great in the eyes of men, then we don’t enjoy the full supply of the Spirit. God wanted Zerubbabel to know that the Holy Spirit would do all things. We too must know this. Whatever may be our need or challenge, we cannot rely on our strength, resources or connection but on God. `,
        `In our text today, God promised that by His Spirit, what seemed like a great mountain before zerubabel would be leveled into a plain. The great mountain may have literally been the mountainous pile of rubbles at the temple site. That rubble would be removed and the work will go on and Zerubbabel shall bring forth the capstone with shouts of “Grace, grace to it!” This was God’s assurance to Zerubbabel that not only would the work be finished, but Zerubbabel will  finish it, and declaring that it was all the work of grace.`,
        `When the work is done or a victory is won through human might or power man can take credit for it, but when it is done by the Power of God through the Holy Spirit, then it is all to the glory of God.`,
        `Brethren, what are you passing through? Have things happened in your life that you never believed they could happen? You never envisaged that such a challenge could ever come to you or one of your loved ones. You never thought you will find yourself in the situation that you or your loved ones have found yourself. But it happened. Brethren, cheer up. What you are going through form part of the realities of life and part of the ways of God to get you closer to Him for you to get to the top and fulfil your glorious destiny. Being a Christian does not mean a trouble free -life. We did not come to Christ so that we will not have troubles. But one thing is certain; the trouble will not have us because we have Christ. As Christ’s property, the trouble cannot overwhelm us, rather God will make the way out that will make us to shout “Grace!!!” According to the Word of God, there is a future, and our hope will not be cut off. (Proverbs 23:18)`,
        `The Almighty God did not promise us smooth voyage but a very safe landing. The presence of Christ in the boat did not mean there could not be storms; but Christ will always command the storms to be still and they will obey; and Christ will take you safely to the shore. God said to Jacob: “Alas! for that day is great, There is none like it; It is the time of Jacob’s [unequaled] trouble, But he will be saved from it.” (Jeremiah 30:7 AMP).`,
        `By the Mercy of God, and the Power of The Holy Spirit, that which you you are going through right now will end up in praise and uncommon testimonies. No matter what is happening right now, no matter the report or what the devil has concocted, be assured that what you are going through right now will not kill your destiny, rather it will end in praise. The mountain will be miraculously made plain and you and your family will greatly praise God. It will end in praise!`],
    pray:	`Heavenly Father, I thank You for this day and I thank You for Your Word. Lord, thank You for You will make the challenges of my life to end in praises of Your name, Hallelujah. Thank You Father, in Jesus name. Amen.`,
    comments:	[],
    details:	{author:"Admin", date:"Jun, 2022"}},
        
    {sn:	58,
    title:	`Your Enemy Will Be Destroyed`,
    bibleRef:	`“Woe (judgment is coming) to you, O destroyer, You who were not destroyed, And he who is treacherous, while others did not deal treacherously with him. As soon as you finish destroying, you will be destroyed; As soon as you stop dealing treacherously, others will deal treacherously with you” (Isaiah 33:1 AMP).`,
    body:	[`When we face problems and antagonisms that make us to wonder  whether there is going to be an end to their attacks, be rest assured that God sees it all and He is on top of the situation if you are in Him. `,
        `There was a time in history that believers were being picked up and killed by king Herod. Believers were slaughtered or thrown to the lions for preys while the king and his apologists watched and laughed. Also in Biblical history,  Judah also had terrible attack from the Assyrians; and in Isaiah chapter 33 the Prophecy of Isaiah came with a warning of “Woe to you, destroyer, you who have not been destroyed! Woe to you, betrayer, you who have not been betrayed...” (NIV) `,
        `The Assyrians of Bible time were extremely treacherous people. They were so wicked that they would often mutilate their prisoners of war, cutting off their tongues or gorging out their eyes. The cruel treatment of the captives of the Assyrians were so much that whenever the Assyrian army surrounded any city, the inhabitants of the city would commit suicide rather than to be taken captive by the Assyrians and be exposed to the humiliating torture of the Assyrians. Isaiah 33:1-8 reveals further that the Assyrians had also taken captive many of the people of the cities of Judah. But Judah knew what to do. Judah called on their God and God responded to Judah by revealing to Judah, what He would do to the Assyrians. God said: “Now I will act. I will show how powerful I am. You make worthless plans and everything you do is useless. My spirit is like a fire that will destroy you. You will crumble like rocks burnt to make lime, like thorns burnt to ashes. Let everyone near and far hear what I have done and acknowledge my power.” (Isaiah 33:10-13 GNB). God was going to burn the wicked enemy of His Children in His fire. This is how God will deal with our enemy because we are His Children, adopted through the precious Blood of Jesus.`,
        `Brethren, no matter the overwhelming attack of the enemy, we only need to call on God and trust Him. And the enemy that is mocking us today or saying our God cannot deliver us will all fail just as the Assyrian king failed as recorded in 2 Kings 18:28-35. With God on our side, the threat of the enemy is an empty threat. It is like the barking of a toothless bulldog. Our God is a consuming fire. (See Hebrews 12:29 and Psalm 97:3). The Lion of the tribe of Judah has already prevailed - Revelation 5:5. Therefore, no matter the attack of the enemy, stand on the Word of God. By the word and power of God, you will have the victory and, “Your Destroyer Will Be Destroyed.”`],
    pray:	`Heavenly Father, I thank You for this day and I thank You for Your Word. Thank You Lord, for  by Your fire, my enemy shall be destroyed in Jesus name. Amen.`,
    comments:	[],
    details:	{author:"Admin", date:"Jun, 2022"}},
        
    {sn:	59,
    title:	`They Looked To Him Part 1`,
    bibleRef:	`“They looked to Him and were radiant, And their faces were not ashamed” (Psalms 34:5 NKJV).`,
    body:	[`The above text talks of looking up to God and what happens to those who would look up to Him in all situations, particularly when in trouble or distress. May we continually look up to God. But for the purpose of individual's spirituality, let each one of us ask him/herself the question: “Who do I look up to in time of trouble? A very religious person will say “to God of course”. `,
        `Then the next question will be whether God sees that it is to Him and Him alone to they look up to. In 1 Kings 19:18, God said to Elijah that: “...I will leave 7,000 people alive in Israel; all those who are loyal to Me and have not bowed to Baal or kissed his idol.” If at this minute your heart tells you that if God says the same thing now, you won’t be amongst the 7,000. All you need do now is turn to Him in repentance and ask for forgiveness and mercy. His mercy is renewed day by day. Plunge into the widened door of His mercy and begin to look to Him alone. He has the whole world including you in His hands and He loves you and already have Jesus as sacrifice for every man’s sin. Therefore, wash in the blood of Jesus and take on the newness of His mercy so that the blessings of looking up to Him alone can be yours.`,
        `In many respects man  have passed through, and may be passing through challenges, difficulties and trials. And the experiences (i.e. the intention of the enemy) are always the same but with different facts. This is revealed to us by Our Lord Jesus Christ when He says in John 10:10 that the plan of the enemy is to kill, steal and destroy. Therefore, what we experience in difficulty is because the enemy has the same intention against every believer, but it comes in different ways. In Psalm 34:4a, the Psalmist talks of the first line of experience; which  is “man needing help from God” when man is in trouble. `,
        `The troubles in which we find ourselves as humans range from different cultures and attacks of the enemy; which includes domestic trouble like issues with family member(s) being in trouble; challenges at work, a bereavement, financial difficulty or any other issues that would cause anxieties that we all are liable to. Because of anxiety, some have looked around rather than looking up, while in fear some looked down. But in every situation may we always find grace to look up in Jesus name (Psalm 121). `,
        `When troubles and challenges come, I pray that we continue in the grace to look up to Him alone and not down or around in Jesus name. We will continue next week as we look at what happens as we look up.`],
    pray:	`Heavenly Father, thank You for this day and thank You for Your Word. Lord, in my present challenge, I look up to You alone, please make my face radiant in Jesus name. Amen.`,
    comments:	[],
    details:	{author:"Admin", date:"Jul, 2022"}},
        
    {sn:	60,
    title:	`They Looked To Him Part 2`,
    bibleRef:	`“They looked to Him and were radiant, And their faces were not ashamed” (Psalms 34:5 NKJV).`,
    body:	[`We are still looking at Psalm 34. The Psalmist says: “They looked to Him”. This means that the more we look (i.e., think) upon our Lord, and less upon ourselves and the situation, the better for us. When we look to Him, as He is seated on the right hand of the throne of God, keeps our hearts steady and our head free of stress when we go through the deep waters of affliction, problems and challenges. The Psalmist did not stop there, he went on to tell us what looking upon the Lord makes of us when he said: “And were radiant”.`,
        `Being radiant simply means to be vividly bright, shining and glowing. To have such an appearance in times of challenge means that, as we look upon the Lord in times of difficulties, troubles and challenges, we draw a divine radiance from God’s own glory. This can be seen from what was written of Moses in Exodus chapter 34 at his return from receiving the Ten Commandments from God. The Bible says that: “When Moses went down from Mount Sinai carrying the Ten Commandments, his face was shining because he had been speaking with the LORD; but he did not know it. Aaron and all the people looked at Moses and saw that his face was shining, and they were afraid to go near him.” (Exodus 34:29-30). What a glory poured on us as we fixate our focus on our God.`,
        `In the Epistle of Apostle Paul to the church in Corinth, he also wrote about the effect of looking up to God in 2 Corinthians 3:16-18 that: “Whenever, though, they turn to face God as Moses did, God removes the veil and there they are—face-to-face! They suddenly recognize that God is a living, personal presence, not a piece of chiseled stone. And when God is personally present, a living Spirit, that old, constricting legislation is recognized as obsolete. We’re free of it! All of us! Nothing between us and God, our faces shining with the brightness of his face. And so we are transfigured much like the Messiah, our lives gradually becoming brighter and more beautiful as God enters our lives and mwe become like him” (MSG).`,
        `The radiance that glows from our faces is the evidence that we have truly looked up to Him. In Isaiah 60:4-5, Prophet Isaiah used the word radiant to describe a mother’s face lighting up at the sight of her children, long given up for lost. The radiance that our faces beam even in time of trouble is the reflection of God’s glory rubbed on our faces as we look upon Him. As we keep our focus on Him even in times of trouble, problem and challenge, His glory upon our face will make our faces radiant, glowing with God’s beauty and peace. The result is that while we are at peace, there is confusion for the enemy because the enemy can never understand the source of our beauty and peace. Hallelujah!`],
    pray:	`Heavenly Father, thank You for Your Word, thank You for strengthening me to keep my focus on You, thank You for Your glory rubbed on me, thank You for the confusion in the camp of the enemy as I look up to You in Jesus mighty name. Amen.`,
    comments:	[],
    details:	{author:"Admin", date:"Jul, 2022"}},
        
    {sn:	61,
    title:	`They Looked To Him Part 3`,
    bibleRef:	`“They looked unto him, and were lightened: and their faces were not ashamed” (Psalm 34:5 KJV).`,
    body:	[`Still on the subject of Looking to God, the Psalmist revealed that there is another effect of looking upon the Lord; and this is that “their faces were not ashamed”. King David knew that God would never forsake anyone that trusts in Him so that no shame will come to them. This is more so that His own name will not be profaned (Isaiah 48:11 and Ezekiel 20:9). How God does this is to give to those who trust Him the grace to have confidence in Him during their challenges; so that they can be saved in the end. `,
        `Part of the Psalmist’s looking up is that he cried unto the Lord. This he said in Psalm 34:6 that “This poor man cried, and the Lord heard him and saved him out of all his troubles.” Here, David emphasised his personal experience of these truths. He was the poor man that cried out to God in his distress and God graciously answered. `,
        `The cry of God’s children is often a short one, but not sweet at all.  It can be brief, but bitter. It is the language of pain. It is the cry that is natural and with much meaning but no music. God will instantly hear such cries of His children as can be seen Psalm 10:18 and Psalm 34:17. Also, in 2 Samuel 21:13, David acting as the madman among the Philistines was the poor man. In Psalm 18:6, David declared that, in his distress he called upon God and cried for help and that God heard his voice and his cry reached God’s ears. When a child of God cries to God, it is very precious to God. God sees such a cry as a confession or acknowledgement of His children that they solidly trust in God alone for their help and God will answer speedily. This is in fact the import of God’s Word through the Prophet in Isaiah 49:15. `,
        `One thing that I have realised is that no father ever wants his children to cry. So, if earthly father can be that touched when his child cries, how much more our Heavenly Father. This is revealed in the account of the cry of Ishmael (the child of the bond woman) and how God instantly made a promise to make his descendants great as seen in Genesis 21:17-18:  “God heard the boy crying, and from heaven the angel of God spoke to Hagar, “What are you troubled about, Hagar? Don't be afraid. God has heard the boy crying. Get up, go and pick him up, and comfort him. I will make a great nation out of his descendants.” (GNBUK). So, God honours our cry whenever a challenge makes us to cry. Not to man, but to Him. God sees and feels our pain when we cry to Him as can be seen from the account of what happened when Jesus was on the cross as stated by Luke. God could not bare to look at the pain of His Son on the cross and He heard to turn His Face away. And when God did, the Bible says that: “...darkness came over the whole land...” (Luke 23:44). `,
        `Unfortunately, people often cry to man first and when there’s no help or solution, then they turn to God. Brethren, God wants us to cry to Him if we need to, and not to man. If we make Him our first port of call, He will always instantly answer and will set people or things in motion for our help. `,
        `The next thing that “looking upon the Lord” means is that those who look up are seeking God, as shown in verse 4 where David confessed that he “sought the Lord”. Seeking the Lord is an attitude of the soul that is continuously directed towards God. It is an attitude of saying we look only to the great Friend and Helper; just as we should cry only to Him. And He will answer us as He answered David. Brethren, whatever may be the distress, trouble and/or challenge; let us cry to our Father. Let us seek help from Him alone as we keep our focus on Him, the Great Friend and Helper. And as we do, He will send angels as helpers in human form speedily and save us from shame.`],
    pray:	`Heavenly Father, thank You for Your Word and Your Love. Lord, hear my cry to You over my challenges and troubles, I thank You for answering me and saving me speedily in Jesus name. Amen.`,
    comments:	[],
    details:	{author:"Admin", date:"Jul, 2022"}},
        
    {sn:	62,
    title:	`Enemy Will Not Outwit Us`,
    bibleRef:	`“His enemies will never succeed against him; the wicked will not defeat him” (Psalms 89:22 GNB).`,
    body:	[`King David was a vivid example that God can show mercy, favour and protection against all odds; including one's mistake or sin. In other words, no matter what we have done in the past or even regrettable mistakes; once we turn to God and turn away from our sinful ways, God will have mercy, show favour and protect us from the enemy’s exerting power. Looking at Psalm 89, we see the promises of God to bless and protect in this regard. As Believers, what God desires of us is to totally turn away from all our sinful ways in order for us to appropriate the fullness of His blessings and protection. `,
        `There were two kings in Israel in quick succession i.e. King Saul and King David. There is no doubt that both of them sinned during the time of their reign. However, the difference between king David and King Saul was that one was humble enough to acknowledge his sin and repent of it; the other would rather make excuses and pass the blame. In First Samuel 28:1-25, Saul sees the huge Philistines army and because he knew that they could not match the strength of the Philistines he went to God and asked for help. But, God did not answer because he had disobeyed God in his failure to destroy  the Amalekites. So when God did not answer him, rather than repent of the sin that was blocking answers to his prayer, he went to the Witch of Endor, disguising himself because he knew that it was the law that no one should work with familiar spirits.  `,
        `The question is: “Who do we want to turn to in our time of trouble when it appears to us that God is not answering us? Brethren, at such times, we must look inwards and be resolute to stand with God no matter how long He takes to answer us because God will surely keep His promise to answer us (Habakkuk 2:3). Joshua also says that: “Not one word of all the good promises that the Lord had made to the house of Israel had failed; all came to pass” (Joshua 21:45). `,
        `So, in our main text today, we see that God blessed David with all the blessings in Psalm 89 because David would acknowledge his sin, repent, turn to God and stayed with God. The Psalmist then recounts the promises of God to him in Psalm 89 and particularly Vs. 22-29 that: God gave David the blessing of protection (the enemy shall not outwit him, nor the son of wickedness afflict him - Vs.22). The blessing of vindication (I will beat down his foes before his face, and plague those who hate him Vs.23).`,
        `The blessing of ongoing faithfulness and mercy (My faithfulness and My mercy shall be with him Vs.24). The blessing of exalted strength (in My name his horn shall be exalted Vs.24).`,
        `Today, as the world around us is full of all manners of evils, as we stand on the side of God no matter the fierceness of the storm or powers of darkness, God’s promise that “the enemy shall not outwit us and the the son of wickedness shall not afflict us and that He will strike down those who hate us shall all be fulfilled in our lives in Jesus name.`],
    pray:	`Heavenly Father, thank You for Your Word and Your love and we thank You for not allowing the enemy to outwit us and our children in anyway in Jesus mighty name. Amen.`,
    comments:	[],
    details:	{author:"Admin", date:"Jul, 2022"}},
        
    {sn:	63,
    title:	`Faith That Is Not Choked `,
    bibleRef:	`“On the third day Abraham saw the place in the distance. Then he said to the servants, “Stay here with the donkey. The boy and I will go over there and worship, and then we will come back to you” (Genesis 22:4-5 GNB).`,
    body:	[`When Abraham said to his servants “Stay here with the donkey. The boy and I will go over there and worship and then we will come back to you”; it was a proof that as sure as day will follow night, that he was bringing Isaac back alive. Let us call to mind the fact that in Genesis 22:1-2, God called Abraham and said to him in clear terms that: he called to him: “....Take your son, your only son, Isaac, whom you love so much, and go to the land of Moriah. There on a mountain that I will show you, offer him as a sacrifice to me.” Abraham certainly understands what it means to sacrifice someone. He certainly knows that for any living thing or being to be offered as sacrifice, they have to be killed. Yet he said,  “I will go over there and worship, and then we will come back to you.” As much as God spoke in clear terms to Abraham, he also spoke in clear terms to his servants. But the two statements do not mean the same. God said go and sacrifice your son. He said to his men, I and the son are going to worship and come back. What a faith.  `,
        ` Abraham could only have said this because He knew and trusted the Character and goodness of God. Therefore, Abraham had no fear. Abraham knew that when God said go and sacrifice, He meant worship Me. Whatever we give to God is a form of worship. Simply put, we are called to worship the God with our wealth. And this has two essential aspects: it means obeying God's command (Exodus 19:5) and being wise and discerning stewards in how we use what God gives to us (Matthew 6:19-24).`,
        `So, four things made Abraham’s faith not to be choked, and these are: He knew the nature of God, He trusted the nature and goodness of God, He was obedient, and He was a wise and discerning steward. `,
        `Abraham knew that in the nature of God He does not take back what He gives (Romans 11:29). Secondly, Abraham knew that God is good and trusted in the fact that there is no shadow of turning with God (James 1:17). `,
        `Abraham had always been obedient to God, so it was not difficult for Him to obey God because He remembers what God first told him when God called him out of his father’s house. `,
        `(Genesis 12:1-2). He knew that God was good to him by calling him out of his clan for his blessing and so, he was confident that God can never change from being good to him as there is no evil with God. Lastly, Abraham showed wisdom and discerning spirit. If he told his men exactly what God told him, they could discourage him or even snatch Isaac from him and run with Isaac back to Sarah.`,
        `Brethren, the opposite of all the above four points noted about Abraham are what can choke a believer’s faith. How do you see yourself? Do you know God enough to trust in His character and goodness? Are we walking in obedience, even in little things? Jesus said in John 14:15 that if we love Him we will obey His commandments. Obedience is all about our love for God. Lastly are we wise and discerning enough not to go telling people or asking for their opinion about God’s instructions?`,
        `Brethren, when we do these four things that Abraham did, then we have faith that is not choked.`],
    pray:	`Heavenly Father, I thank You for Your Word and I pray that my faith will not be choked in Jesus name. Amen.`,
    comments:	[],
    details:	{author:"Admin", date:"Aug, 2022"}},
        
    {sn:	64,
    title:	`You Are God’s Zion`,
    bibleRef:	`“On Zion, God's sacred hill, there will be nothing harmful or evil. The land will be as full of knowledge of the LORD as the seas are full of water” (Isaiah 11:9 GNB).`,
    body:	[`Our text of today says: “On Zion, God’s sacred hill, there will be nothing harmful or evil...” The word Zion is used for the first time in 2 Samuel 5:7 and it became synonymous with the city of David. This place became significant immediately David brought the ark of the covenant into this Zion (2 Samuel 6:12). The ark of the covenant was the sacred seat of the Holy of Holies where God used to meet His people in the tabernacle. Therefore, Zion became the centre of worship and of the presence of God. `,
        `When king Solomon moved the ark of the covenant into the temple that he had built the whole of Jerusalem was known as Zion (1 Kings 8:1). Therefore, in the Old Testament, when Zion is mentioned, it is referred to as the city of Jerusalem, the city of God’s presence and the city of great hope for the people of God. The place where God was present and near to His people. The place from which the people expected help. The source of deliverance and salvation. Hence, Psalm 20:2 says: “May He send you help from the sanctuary and grant you support from Zion.” Therefore, in the text of today, Prophet Isaiah revealed what the Lord says of Zion that “...there will be nothing harmful or evil...”.  Zion is the place of God's presence, the place of God's power and blessing and protection, the hope of God's future rule over the earth. What a great future for those who have come to Christ. This is why Apostle Paul could write to the Galatian Christians that: “...the heavenly Jerusalem is free, and she is our mother.” (Galatians 4:26). Zion is such a rich and hope-filled word for the true believing Christians. `,
        `Applying these to the believers' lives, we are God’s Zion because He dwells in us and there must be no evil to us. In other words, when we are subject to affliction,  deprivation, wrong, and deliberate unjust treatment; such to our God is an abomination because we are the Zion where He dwells. Even when God appears to be silent, He sees all because of mercy, He wants repentance for everyone. But just as the earth’s suffering is a temporary one as there is hope of His Heavenly Zion free of every evil; the wrong against us is also a temporary one. And because we are His Zion, God will come forth for us and He will surely punish every unjust treatment meted to us as He says in His Word about every wicked ruler or boss that:  “You are doomed! You make unjust laws that oppress my people. That is how you prevent the poor from having their rights and from getting justice... What will you do when God punishes you? ..Where will you run to find help? Where will you hide your wealth?...the LORD 's anger will not be ended; his hand will still be stretched out to punish” (Isaiah 10:1-4).`,
        `However, God is more concerned that we make it to the Heavenly Zion, and so, we must never drift to the ways of those that are already doomed for destruction (unless they come to Christ). Therefore, we must keep holding unto faith, no matter the apparent injustice or wrongdoings against us. We are a citizen of Heaven; and just as help will always come to an ambassador in a foreign country; God will surely send help to us from the Zion above. And as today’s text says that “there will be nothing harmful or evil”; God will certain not allow the unjust treatment and wrongs against us to become an evil. Whatever you are going through, be comforted with God’s Word. It is temporary and Zion of God (you) will have the lasting joy over your enemy and the wicked ruler or boss.`],
    pray:	`Heavenly Father, I thank You for this day, thank You for Your Word and the assurance that as Your Zion, this present wrong against me will be punished by You.  I thank You for the lasting joy of victory over every injustice in Jesus name. Amen.`,
    comments:	[],
    details:	{author:"Admin", date:"Aug, 2022"}},
        
    {sn:	65,
    title:	`Determine to Serve God`,
    bibleRef:	`“Whatever your hand finds to do, do it with your might; for there is no work or device or knowledge or wisdom in the grave where you are going” (Ecclesiastes 9:10 NKJV).`,
    body:	[`The advice of the Preacher in our text today is not only unique but a very important one. Relating the scripture to the life of the children of God, saved by the blood of Jesus; the question is, after our salvation, what have we put our hands to do? The fact is that God does not save man just for man to sit down, enjoy his/her salvation with no concern for those yet to be saved. He saves man so that man can worship and work in His vineyard while still on earth. `,
        `We all know that there is nothing like non-functioning member of our human body except that part of the body is dead from accident or infirmity. There also ought not to be such thing as a non-functioning member of the Body of Christ, the Church. God saved us from our sin, and also calls us to serve Him in some ways in accordance with the gifts He deposited in everyone. God saved us from the awful judgment we deserved for our sins. We were bought with a price - the precious blood of Jesus. We belong to Him and He is expecting us to put our hands to work for Him. When we get saved, we got conscripted not just volunteers for Jesus, but we are expected to find something for our hands to do as members of the Body of Christ. Having been saved, we have been drafted into Jesus’ army. Soldiers are always up and doing and very faithful to their calling. This is what is expected of us as believers.`,
        `As this year is gradually running out, we need to begin to take stock of what we have put our hands to do for God. There are different ways to serve God, and this is where each of us can determine what our hands find to do. Be it in going out to preach the word; using our money for the gospel or helping the less privileged. The Bible says: “Now there are varieties of gifts, but the same Spirit; and there are varieties of service, but the same Lord” (1 Corinthians 12:4-5). There is less than 5 months to the end of this year, each believer need to ask, “what have I done for the Lord?” “How has my gift been profitable to my Lord?” There are so many souls around us that we need to touch so that when the time to render returns comes, there is something to return for the Lord. Let us remember that the aim of our gifts and our service are to be utilised to bring forth children in the faith i.e. through the gospel. And if this is worthy to do, then it’s worthy for us to do it with all of our might. `,
        `Ephesians 5:16 says that we are to make “the best use of the time, because the days are evil.” Let us not waste any opportunity we have to speak of Jesus to others. It is the last charge which Jesus gave us in Matthew 28:19-20; and we need to work hard in His service. Are you therefore working hard or are you hardly working. Today, I charge each of us to begin to take stock as we begin to count down the year 2022. Let us not be Christians that are rusting out instead of wearing out in serving the Lord Jesus Christ. Therefore, if you have not been working, you can determine today to serve God with all of your might. God rewards everyone no matter when we start (Matthew 20:1-16). Jesus also says: “But Jesus answered them, "My Father is working until now, and I am working" (John 5:17). Let us put our hands to work.`],
    pray:	`Heavenly Father, I thank You for the new Sunday (Bible) School year. Lord God, empower me to serve You better this year than ever before in Jesus name. Amen.`,
    comments:	[],
    details:	{author:"Admin", date:"Aug, 2022"}},
        
    {sn:	66,
    title:	`The Presence of God`,
    bibleRef:	`"And He said, "My presence will go with you, and I will give you rest" (Exodus 33:14 NKJV).`,
    body:	[`In Exodus 33:1-12, there was a conversation between God and Moses, wherein God instructed Moses to take the Israelites to the land of promise. In verse 12, Moses said to the Lord that He has not told him whom He (God) will send to go with him (Moses). And God responded with our text today; promising that His presence with go with Moses.`,
        `Moses did not want to take one step without the fullness of the presence of God. Can this be true of us in our daily life? In Exodus 33:14, God said to Moses: “My presence will go with you, and I will give you rest.” Moses surely knew the best thing to ask for. No man can successfully tread the journey of this life without God. Prophet Jeremiah realised this when he confessed that: `,
        `“I know, O LORD, that the way of man is not in himself, that it is not in man who walks to direct his steps” (Jeremiah 10:23).`,
        `What do we understand by the presence of God? The most common Hebrew term for "presence" is "panim" which means "face", and this implies a close and personal encounter with the Lord. So, when we have the presence of God, His face is actually upon us. In the Book of Numbers, God specifically told Moses to “Speak to Aaron and his sons, saying, Thus you shall bless the people of Israel. And the first blessing is: “the LORD make his face to shine upon you and be gracious to you” (Numbers 6:25). God’s presence is His face shinning upon us. When the face of God is shining on us as His children, any power of darkness that tries to do any harm to us will be like a thief or robber that attempts to sneak into a house surrounded by forge lights and CCTV Cameras. The thief will just quietly walk into his own doom. When Shadrach, Meshach and Abednego were being taken away to be dropped into the fiery burning furnace; it was those that cast them in the fire that got burned. Same for Daniel, those that threw him in the lions’ den eventually were the meat for the lions while Daniel had a nice time relaxing with lions. (See Daniel chapters 3 and 6).`,
        `Moses surely got the best deal in life when God said “My Presence will go with you”. The Psalmist says of the Lord: “You make known to me the path of life; in your presence there is fullness of joy...” (Psalm 16:11a). Fullness of joy is being complete in every way in God's presence, so that even if there’s a challenge, we have the assurance that by reason of the presence of God, victory is the ultimate result. Also, the presence of God brings protection, security, favour and blessings. `,
        `Brethren, I want to encourage us to pray always that: “God, I don’t want to do anything alone today, I don’t want to do anything alone, I want to be in your presence all day long.” We must not live our lives without the presence of God manifested through His Holy Spirit. At Jesus ascension after the resurrection, He made a promise in Matthew 28:20 thus: “...behold, I am with you always, to the end of the age." As much as this promise is for us, we also must ensure that we do not leave His presence through sinful life. Let us remember that God’s eyes cannot behold sin (Habakkuk 1:13).`,
        `The Presence of the Lord is His Holy Spirit in us. Therefore, we must never do anything that will grieve Him, as doing so means departing from His presence. Anyone that departs from God’s presence makes himself/herself vulnerable to the powers of darkness. God has promised that “His presence will go with us", may we remain in His presence always in Jesus name. Amen.`],
    pray:	`Heavenly Father, thank You for Your Word and thank You for the promise of Your presence which today is manifesting in us through the Holy Spirit. In these Ember Months and beyond, the presence of God will continue to be with us in Jesus name.  Amen.`,
    comments:	[],
    details:	{author:"Admin", date:"Sep, 2022"}},
        
    {sn:	67,
    title:	`Contend with the Giant`,
    bibleRef:	`“From the days of John the Baptist until now the kingdom of heaven suffers violent assault, and violent men seize it by force [as a precious prize]” (Matthew 11:12 AMP).`,
    body:	[`The above Scripture is direct by our Lord Jesus Christ and it is as such very sacred and unimpeachable. The kingdom of God requires violence (holy violence) to have as a precious prize because the devil that is already condemned will do all it can to prevent man from getting there. And so, the violent takes it by force. `,
        `If the God’s kingdom can only be taken by those who will be spiritually violent, I believe that the same principle would apply to the glorious destiny that each of us and our children carry. The Bible says: “Everyone who is called by My Name, Whom I have created for My glory, Whom I have formed, even whom I have made”(Isaiah 43:7 AMP). We are called by God’s name and created for His Glory i.e. meaning we have a relationship with God through the only one name He provided for man and by which we are called Christians. See Acts 4:11-12. Each of us has a glorious destiny. And just as the enemy fights against man’s reigning with God in His kingdom after this age, the same enemy will also contend vehemently against the manifestation of the glory that each man carries. `,
        `The enemy contends with man against the glory that man carries not because he (the enemy) needs it, but just so that God is not glorified in man’s life. The enemy knows that if we win the battle, we will testify, and  God will be glorified hence the battle for a glorious destiny becomes a fierce one. This can be confirmed by what the people of old went through. A typical example of one that lost the battle of glory was Samson. His enemy rejoiced over him as the Bible reveals said: “...the lords of the Philistines gathered to offer a great sacrifice to Dagon their god and to rejoice, and they said, "Our god has given Samson our enemy into our hand" (Judges 16:23). May our enemies never rejoice over us and our children in Jesus name. `,
        `Although Samson cried out to God; He received forgiveness and mercy; regained his strength, but he died with his enemies ( Judges 16:30). This is why the battle for destiny also requires holy violence. As we see in the life of Joseph who though suffered but overcame with violent holiness as he responded to the carrot of temptation in Genesis 39:9 with the fear of God, and desire to rather not sin against God. Daniel, Shedrach, Meshach and Abednego all chose not to bow to foreign god and would not defy themselves with choice meals of the king (Daniel 3:16-28). Each of them though suffered a bit, but attained their glorious destinies. See Daniel 2:48 and 3:30. Each of these men had to contend with the enemies of their glorious destiny. I pray that by the mercy of God, we and our children will win in Jesus name. Amen `,
        `God has put His children on earth and called us from darkness into His marvellous light to showcase His Glory (1 Peter 2:9). Our lives on earth is supposed to be a replica of what it is in heaven. But when we fall for the temptation to sin like our forefathers, then the devil begins to lead man to substitute God’s plan with his own plan. Jesus Christ did not leave us uninformed of the contention of the enemy against the glorious destiny of man. Jesus says in John 10:10 that: “The thief’s purpose is to steal, kill and destroy. My purpose is to give them a rich and satisfying life” (NLT). `,
        `The Word of God says in Psalm 91:16 that He will satisfy us with long life and also Show is His salvation. But the truth is that the enemy is not going to fold its arms and be an onlooker for man to achieve this. He is prepared to fight tooth and nail. This is why as much as the kingdom of God suffers violence, the battle for our destinies also suffers the same violence. But we can take it by our violent holiness in obedience to the Word of God. And as we live in obedience to God’s Word, He shows us, leads us and takes us step by step from one level to another level, just as the Bible reveals about the journey of Israel into the Promised Land in Exodus 23:28 that the Hittite will confront them but God sent hornets before the Israelites to drive out the Hivites, the Canaanites, and the Hittites. God will do same for us in the journey to attain our destinies too but we must be willing to please God all the way. `,
        `Brethren, the kingdom of God suffers violence and the violent takes it by force. Same goes for the battle for our glorious destiny. We shall take it by holy violence. We must not look at how the wicked is making it at all. Psalm 73:2:15 shows that David felt the same way. But the Psalmist also reveals that God sets them in slippery places; God makes them to fall to ruin.(Psalms 73:18). As we look to God, stand on His Word in violent obedience, we will contend with the giant against our glorious destinies and win in Jesus name.`],
    pray:	`Heavenly Father, I thank You for Your Word.  Lord God, empower me to contend with the giant of my destiny by my obedience to Your Word in Jesus name. Amen.`,
    comments:	[],
    details:	{author:"Admin", date:"Sep, 2022"}},
    
    {sn:	68,
    title:	`God Will Surprise You`,
    bibleRef:	`“And the angel of the LORD appeared unto him in a flame of fire out of the midst of a bush: and he looked, and, behold, the bush burned with fire, and the bush was not consumed” (Exodus 3:2 ESV).`,
    body:	[`A few people say that they do not like surprises. However, we serve the God of surprises. As such, I pitch my tent with those who like surprises. And I pray for you that the Lord will pleasantly surprise you in Jesus name. `,
        `Surprise is simply an unexpected or astonishing event, fact, etc. With this in mind, let us consider the Biblical record of Moses and the burning bush revealed in our text today. The unusual or unexpected in that story is that the bush was burning but it was not consumed. This certainly is a surprise beyond human understanding. Our God has always been the God of surprises. Examples abound in the word of God as to how God surprises His children. `,
        `Because Moses’ experience was an unusual thing, he said to himself in verse 3, “This is strange...Why isn't the bush burning up? I will go closer and see.” And just as Moses did as he said; behold, there is another surprise in verse 4, as Moses heard a voice from the midst of the bush calling out his name. Moses was watching one thing with amazement, wondering how can this be, then, suddenly he heard his name called out from the midst of the unusual thing. That was certainly a great surprise. `,
        `God also surprised Abraham and Sarah with the birth of Isaac at a time of their ages, particularly Sarah’s age, when it was both medically and physically impossible to get pregnant. At the Red Sea; the children of Israel had concluded that God of Moses had abandoned them. There is no record that they could swim. They have been in slavery for over four hundred years. But the God of surprises showed Himself mighty as Moses stretched out his hand over the sea, and God drove the sea back with a strong east wind and the children of Israel crossed on dry land. (See Exodus 14:21).`,
        `Also, in the New Testament, the seventy that Jesus sent out came back surprised that they could cast out evil spirits in the name of Jesus.(Luke 10). At the tomb of Lazarus; Mary had given up hope. It was a forgone conclusion. Lazarus had been dead for four days; but when Jesus appeared, everyone present, and even those that later heard were surprised at Lazarus woke up from the dead after four days. (See John chapter 11).`,
        `Brethren, whatever looks like an impossibility plaguing your life, and looking like there is no possible solution in sight; the Almighty is saying to you today, that He will surprise you. And for you, just as God said to the children of Israel at the Red Sea, as recorded in Exodus 14:18  that: “...the Egyptians will know that I am the LORD when I have gained my honour...” By the spoken word of God, the desire of your heart that men have said is no longer possible shall be made possible for you.  The Lord will have to gain His Honour in your life, and so God will pleasantly surprise you with your heart desires in Jesus name. The Lord will show up for you. He sure will, He will surprise you.`],
    pray:	`Heavenly Father, I thank You for Your word and I thank You for being the God that never changes. I look unto You to surprise me concerning this issue (mention it) in Jesus name. Amen.`,
    comments:	[1,2,3],
    details:	{author:"Admin", date:"Sep, 2022"}},
        
    {sn:	69,
    title:	`You Can Defeat Your Enemy Part 1`,
    bibleRef:	`“When He had disarmed the rulers and authorities [those supernatural forces of evil operating against us], He made a public example of them [exhibiting them as captives in His triumphal procession], having triumphed over them through the cross” (Colossians 2:15 AMP).`,
    body:	[`The life of man is a life of battles. The battle line was first drawn immediately that God created man after Lucifer fell. Lucifer became satan and has since been after the soul of man to steal the souls of man from God so that man can worship him and not God. Therefore, the day a believer gives his/her life to Christ, the battle begins. And he also employs fellow human beings whose hearts he has already stolen to attack the believer in diverse ways. `,
        `The battles may be physical or spiritual, but one thing is certain; all battles are engineered by man’s arch-enemy, Satan. Because satan is spirit, he needs human agents and so,  he does not attack without human agents. So, the human being that physically antagonises through their actions are mere agents used by Satan to perpetrate his actions against us. While he employs humans, he also employs different strategies for his evils with the intention of keeping us defeated. He can do this by a tracking our finances, career, family and loved ones. And so, there’s need for us as children of God not to let the devil keep us defeated by not allowing him to catch us off our guards. As believers, we can defeat the enemy every time because the enemy has already been defeated by Christ at His resurrection. Therefore; through His resurrection, Jesus has disarmed the enemy. This is what the text of today tells us. But for us to manifestly defeat the enemy’s various attacks, we must have a battle plan. This is because; although defeated at the cross, Satan still employs strategies against the believer because he (Satan) has the control of this world. The Bible says: Woe unto the earth for we send down satan...(Revelation 12:12). So, we need to have the right strategy for the battle. `,
        `In the Military, it is said that no military unit would ever dream of going to war without a battle plan. In other words, no military unit will go to war without a strategy to exploit the enemies’ weaknesses and maximise their own strengths so that they can win. As such, the day the believer makes his/her commitment to giving his/her life to Christ and work in obedience to the word of God, the battle line is drawn. Therefore, the need arises for the believer to have strategies to defeat the enemy because it is a spiritual enemy, Satan, that we have and has continued to wage war against us.`,
        `According to today’s text, Jesus has already defeated Satan for us, but that doesn’t mean Satan is not going to use certain strategies to try and trick us into believing otherwise. Satan’s strategies are intended to keep us defeated and to take over everything that is rightfully ours. We have a duty to stop him. This is why the believer cannot sit, sleep or be docile on the battlefield of life. Jesus Christ warns that the enemy has come to kill, steal and destroy (John 10:10a).`,
        `We must not deceive ourselves by saying that, “The enemy is already defeated,” and therefore lie down and make ourselves comfortable but vulnerable. If as believers, we do not have any responsibility to stand our ground against the devil’s schemes, God’s Word would not have instructed us to: “resist the devil” (James 4:7). Nor would we have been told to use “the weapons of our warfare” (2 Corinthians 10:4). Also, Jesus would not have had any need to give us “authority to trample over all the power of the enemy” (Luke 10:19). All these show that there is a responsibility on our part to actualise the victory revealed in our text today. There are strategies for the believers and there are weapons to employ for the warfare, but none is an AK47. Jesus Christ made it clear in Matthew 26:52 that our weapon is not carnal when He said to His Disciples “Put away your sword...” ( NLT). Without the sword, Jesus defeated satan at His resurrection. As believers, we can defeat every human agent of satan as well. `,
        `Therefore brethren, whatever may be the attack of the enemy, don’t join issues with the enemy by going physical with the human agents through our actions against such human agents. Also, do not compromise your position in Christ by going to beg the enemy or cringe to the human agent. Rather, know your position in Christ and go on your knees before God. We can defeat the enemy on our knees; and by His Power, we will. You will be the victorious one in Jesus name.`],
    pray:	`Heavenly Father, I thank You for Your Word and I thank You Jesus for defeating the enemy. Lord, strengthen my prayer life for the battles ahead in Jesus name. Amen.`,
    comments:	[1],
    details:	{author:"Admin", date:"Oct, 2022"}},
        
    {sn:	70,
    title:	`You Can Defeat Your Enemy Part 2`,
    bibleRef:	`“Then I heard a loud voice shouting across the heavens, “It has come at last - salvation and power and the Kingdom of our God, and the authority of his Christ. For the accuser of our brothers and sisters has been thrown down to earth - the one who accuses them before our God day and night” (Revelation 12:10 NLT).`,
    body:	[`Still on the defeat of the enemy, our text today reveals that Heaven defeated satan and threw him down to the earth. In other words, Satan’s attack started in heaven. But the heavenly hosts defeated him. Having been thrown down to earth, with his power and the fallen agents that joined in his rebellion against God, the attack continued on earth against humans. The aim of the enemy is simply as Jesus Christ reveals in John 10:10 - to kill, steal and destroy. `,
        `When satan was thrown down, the Almighty God did not disposes him of his powers. But thanks to Jesus who went down to the hades to seize power from the enemy of man’s soul (Acts 2:31-32). Therefore, any man that accepts Jesus as his Lord and Saviour can overcome the enemy. `,
        `Man must however not live their lives any how, even after accepting Christ. Man must be deliberate to live thoughtfully and Godly. Because as long as man is on earth, every day is a day of war between man and the enemy, and he will employ other human agents. Hence, the Bible warns that the believer must ”Stay alert! Watch out for your great enemy, the devil. He prowls around like a roaring lion, looking for someone to devour” (1 Peter 5:8 NLT). We must never make light of Satan’s intentions. In the Book, “The Art of War” the author, Sun Tzu, says, “He who exercises no forethought but makes light of his opponents is sure to be captured by them.” We shall not be captured in Jesus name. Amen. `,
        `But, in order not to be captured, every believer must exercise “Forethought” by thinking and planning ahead of the enemy’s attacks. The believer must not be shocked or show dismay when the enemy starts to use his strategies to try and steal, kill and destroy. We must take our roles seriously and not take the strategies of the enemy lightly. God does not intend that the enemy should outsmart any of His children. Hence, the Psalmist reveals the mind of God in Psalm 89:22 that: “The enemy shall not outwit him, Nor the son of wickedness afflict him.” This promise will be fulfilled in our lives in Jesus name. However, we must be deliberate to live by His Word so that we do not make ourselves vulnerable to the enemy. Therefore we must get familiar with the evil schemes of the enemy according to the Word of God in 2 Corinthians 2:11. This is what the Military refers to as intelligence. Psalm 144:1 says that God trains our hands for war. And the enemy’s strategies are also revealed to us through the Word of God and also tells us the plan of counterattack through our prayers. `,
        `Brethren, the enemy wants to kill, steal and destroy man’s destiny, progress, wealth and well-being, peace of mind, prosperity, good health and the entire destiny of man, not because he needs the use of it, but because he does not want man to manifest the glory of God. As such, he is prepared to waste it. But we can defeat him on our knees. So that he is not able to catch us off guard. Hence, the saying goes that: “Pray not be become a Prey”. We must be strong in the Lord and in the power of His might. But only by His Word because His Word which is the sword of the Spirit. (Ephesians 6:10-18). You can defeat the enemy.`],
    pray:	`Heavenly Father, I thank You for Your Word and I thank You Jesus, for by the Power of Your Word, I can defeat the enemy. Lord, help me to daily live by Your Word for my victory over life’s battles in Jesus name. Amen.`,
    comments:	[1,2,3,4,5,6,7],
    details:	{author:"Admin", date:"Oct, 2022"}},
        
    {sn:	71,
    title:	`You Can Defeat Your Enemy Part 2`,
    bibleRef:	`“Then I heard a loud voice shouting across the heavens, “It has come at last - salvation and power and the Kingdom of our God, and the authority of his Christ. For the accuser of our brothers and sisters has been thrown down to earth - the one who accuses them before our God day and night” (Revelation 12:10 NLT).`,
    body:	[`Still on the defeat of the enemy, our text today reveals that Heaven defeated satan and threw him down to the earth. In other words, Satan’s attack started in heaven. But the heavenly hosts defeated him. Having been thrown down to earth, with his power and the fallen agents that joined in his rebellion against God, the attack continued on earth against humans. The aim of the enemy is simply as Jesus Christ reveals in John 10:10 - to kill, steal and destroy. `,
        `When satan was thrown down, the Almighty God did not disposes him of his powers. But thanks to Jesus who went down to the hades to seize power from the enemy of man’s soul (Acts 2:31-32). Therefore, any man that accepts Jesus as his Lord and Saviour can overcome the enemy. `,
        `Man must however not live their lives any how, even after accepting Christ. Man must be deliberate to live thoughtfully and Godly. Because as long as man is on earth, every day is a day of war between man and the enemy, and he will employ other human agents. Hence, the Bible warns that the believer must ”Stay alert! Watch out for your great enemy, the devil. He prowls around like a roaring lion, looking for someone to devour” (1 Peter 5:8 NLT). We must never make light of Satan’s intentions. In the Book, “The Art of War” the author, Sun Tzu, says, “He who exercises no forethought but makes light of his opponents is sure to be captured by them.” We shall not be captured in Jesus name. Amen. `,
        `But, in order not to be captured, every believer must exercise “Forethought” by thinking and planning ahead of the enemy’s attacks. The believer must not be shocked or show dismay when the enemy starts to use his strategies to try and steal, kill and destroy. We must take our roles seriously and not take the strategies of the enemy lightly. God does not intend that the enemy should outsmart any of His children. Hence, the Psalmist reveals the mind of God in Psalm 89:22 that: “The enemy shall not outwit him, Nor the son of wickedness afflict him.” This promise will be fulfilled in our lives in Jesus name. However, we must be deliberate to live by His Word so that we do not make ourselves vulnerable to the enemy. Therefore we must get familiar with the evil schemes of the enemy according to the Word of God in 2 Corinthians 2:11. This is what the Military refers to as intelligence. Psalm 144:1 says that God trains our hands for war. And the enemy’s strategies are also revealed to us through the Word of God and also tells us the plan of counterattack through our prayers. `,
        `Brethren, the enemy wants to kill, steal and destroy man’s destiny, progress, wealth and well-being, peace of mind, prosperity, good health and the entire destiny of man, not because he needs the use of it, but because he does not want man to manifest the glory of God. As such, he is prepared to waste it. But we can defeat him on our knees. So that he is not able to catch us off guard. Hence, the saying goes that: “Pray not be become a Prey”. We must be strong in the Lord and in the power of His might. But only by His Word because His Word which is the sword of the Spirit. (Ephesians 6:10-18). You can defeat the enemy.`],
    pray:	`Heavenly Father, I thank You for Your Word and I thank You Jesus, for by the Power of Your Word, I can defeat the enemy. Lord, help me to daily live by Your Word for my victory over life’s battles in Jesus name. Amen.`,
    comments:	[1,2,3],
    details:	{author:"Admin", date:"Oct, 2022"}},

    
    {sn:	72,
        title:	`Victory Over Strong Enemies`,
        bibleRef:	`“I have many aggressive enemies; they hate me without reason. They repay me evil for good and oppose me” (Psalm 38:19-20 NLT).`,
        body:	[`Our text today was written by the Psalmist, King David. This was a man after the heart of God, yet he had aggressive enemies. Some versions referred to this as “vigorous enemies.” Have you ever felt the same way as David? Have people spoken against you for no reason, betrayed you, tried to keep you from success or tried to prevent you from getting good things or even your entitlement for no just cause? These are the enemies that each of us has to deal with in this life. And, the truth is that they will try you in every possible way.`,
                'The natural response to the enemies is usually to fight back, get even, and put them in their place, or even demand justice. Often times, their actions also get one distressed as we try to figure out why they are against us in the first place. And before you know it, the situation begins to consume all your thoughts and bringing you torments. However, the Almighty God expects us to respond to our enemies with love, prayer, forgiveness and blessing. The fact is that, although the enemy is a human being but he/she is already in Satan’s line of fire and he/she is being controlled by Satan to do havoc. Therefore, as children of God, we must not get ourselves in the same line through our actions or reactions. We must make room for God to handle the justice of the situation by Himself as only He alone can. What we must not do is worry. God has promised to handle them on our behalf. This is the essence of the Psalmist prayer in Psalm 35:1 that: “Contend, O LORD, with those who contend with me; fight against those who fight against me!” When God fights against the enemy the following five (5) things will eventually happen:',
                'First; God will bring everything hidden thing into the light. An enemy can be very sneaky. They will say cutting remarks to you that no one else notices, threaten you when others aren’t around, or try to quietly cheat you out of money, opportunities or relationships. Even so, there is One who sees everything that is done in secret, and He says, “Nothing is secret that will not be revealed, nor anything hidden that will not be known and come to light” (Luke 8:17, NKJV). Concerning that enemy, we only need to obey God and pray (Luke 6:27-36, Luke 8:17) and thank God, He will bring an end to their evil.',
                `Secondly, God will avenge for you. The desire you have for justice can only come from God in whose image we are created. He is the God of justice, and He loves justice. In Romans 12:19 His Word says: ‘Vengeance is mine, I will repay...” (ESV). He did for Daniel, Shadrach, Meshach and Abednego (Daniel chapters 3 and 6)`,
                `Thirdly, God will deal with our enemy according to His Word of Blessing in Deuteronomy 28:7 which says: “The Lord shall cause your enemies who rise up against you to be defeated before your face. God also promised that: “They will come against you one way but flee before you seven ways.” All we need to do is to work in obedience so that we can remain under God’s blessings which gives us automatic victory, however long it may take. He will not let the enemy succeed against you. Our greatest enemy has come to steal, kill and destroy. But he does not work alone. He will use other people (even so called Christians) to carry out his plans. Sometimes, it may look as if the plans of the enemy are succeeding. But God’s promise is sure. He says in Isaiah 54:17 that: “No weapon formed against you shall prosper, and every tongue which rises against you in judgment you shall condemn”. Again, we only need to walk in love toward them, and stand on the Word of God in faith which has no fear. What He did for the children of Israel at the Red Sea (Exodus 15:4), He will do for you.`,
                `Fourthly; God will prepare a table before us. As we keep walking in love and in obedience to God’s Word, no matter who comes against us, they cannot stop the blessings that God has in store for us. Satan will always use other people to try and get the child of God into stride knowing that if he can do that, then he will walk us out of the position to receive the blessings of God. We must therefore stay on the love line and never budge. No matter how much wrong they do to us. We must decide to forgive; as we continue to declare God’s promise to prepare a table before us in the presence of our enemies (Psalm 23:5). Haman planned death of Mordecai but when God took over the battle. Yhe Bible says that: “...Haman took the robes and the horse, and he dressed Mordecai and led him through the square of the city, proclaiming before him, "Thus shall it be done to the man whom the king delights to honor" (Esther 6:11).`,
                `Fifthly; the real enemy is Satan. And God is always there to help us to defeat him. This is why the Bible says in Ephesians 6:12-13, that we do not fight against flesh-and-blood, but against evil spirits. I believe most of us as believers know this truth, but it will take a constant reminder through the Word because we can see life and direct, flesh and blood causing so much trouble for us. But God has given you all authority over the enemy. So, when the enemy tries to send people our way, we must rebuke Satan and send him packing, and back to where he belongs i.e. under our feet through the precious blood of Jesus.`,
                `Brethren, many of us are facing unjust and unimaginable attack from the enemy. And it is as though God is on vacation. No! He is not. He only needs us to stop worrying, He needs us not to try to get justice by ourselves, we must stop gossiping about the situation so shall our deal with Christ remains intact. Therefore, like the Psalmist says: your aggressive enemy may hate you without reason, yours is to stay in obedience, and let God deal with the enemy. And He surely will and you will have the victory.`,
            ],
        pray:	`Heavenly Father, I thank You for Your love for me. Lord, help me to work in obedience to your word and never to toe the line of the enemy. Thank You for fighting the battle Yourself and thank You for giving me victory in Jesus name. Amen.`,
        comments:	[1,2,3,4],
        details:	{author:"Admin", date:"Oct, 2022"}},
    ];    


function getPastorCornerNew(){

    return pastorCornerLatest
}
export default getPastorCornerNew;