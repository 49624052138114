import React  from 'react';
import SEO from '../components/SEO';
import HomePage from '../components/userpage/_home/_HomePage';


const UserPage = () => {
  
  return (
    <>
      <SEO title="User Page - GOFAMINT PS Ogba" />
      <HomePage/>
    </>
  )
}

export default UserPage









