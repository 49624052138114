import React from "react";

const SelectionTag = ({setSelected}) => {
    const onOptionChangeHandler = (event) => {
        setSelected(event.target.value);
    }


    return (
        <div className="my-3 ml-2">
            <div className="flex flex-wrap flex-row">
                <p className="font-bold text-purple-700 underline">View select</p>
                <select onChange={onOptionChangeHandler} 
                    className="border border-solid border-blue-500 py-1 ml-2">
                    <option>Services with offering</option>
                    <option>All services</option>
                </select>
            </div>
        </div>
    );
};



const DateTag = ({setSelectedDate, title, endDate, setLastDateManualSet,minDate, maxDate,
         resetDate, setTodayDateSet, todayDateSet, setFirstDateOfTheMonth}) => {
    const [date, setDate] = React.useState(endDate);

    React.useEffect(()=>{
        if(todayDateSet){
            setSelectedDate(date);
            setLastDateManualSet(true);
        //console.log(date)
        }
    },[date]);

    React.useEffect(()=>{
        setFirstDateOfTheMonth();
        //setDate('')
    },[resetDate]);
    
    const setDateHandler=(el)=>{
        setTodayDateSet(true);
        setDate(el)
    }
    //console.log(date);

    return (
        <div className="my-3 ml-2">
            <div className="flex flex-wrap flex-row">
                <p className="font-bold text-purple-700 underline">{title}</p>
                <input  className="border border-solid border-blue-500  ml-2" 
                    type="date" 
                    id="start" 
                    name="dateTag"
                    value={date}
                    min={minDate}
                    max={maxDate}
                    onChange={e=>{setDateHandler(e.target.value); }}/>
            </div>
        </div>
    );
};
  
export default SelectionTag;
export {DateTag}