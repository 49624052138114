

function phoneNoExtract(phoneNo){
    let res = "";
    if(phoneNo){
        const fstDigit = phoneNo.at(0);
        const secDigit = phoneNo.at(1);
        const lstDigit = phoneNo.at(-1);
    res = `${fstDigit}${secDigit}xxxxxxxx${lstDigit}`;
    }
    return res
}

function getCompareResult(formVals, confirmResult){
    let toConfirm  = {...formVals};
    let confirmRes = {...confirmResult};
    
    if(formVals.dob){
        toConfirm = {...toConfirm, dob:processDates(formVals.dob)};
        confirmRes = {...confirmRes, dob:processDates(confirmResult.dob)}
    }
    if(formVals.wedAnn){
        toConfirm = {...toConfirm, wedAnn:processDates(formVals.wedAnn)};
        confirmRes = {...confirmRes, wedAnn:processDates(confirmResult.wedAnn)};
    }
    //console.log([toConfirm, confirmRes]);
    const compareObjs = compareTwoObjs(toConfirm, confirmRes);
    return compareObjs;

};

function processDates(date){
    const splitRes = date.includes('-')? date.split('-') : date.split('/');
    const  mmdd = `${parseInt(splitRes.at(-2))}/${parseInt(splitRes.at(-1))}`;
    return mmdd
}

function compareTwoObjs(obj1, obj2){
    let check = true;
    Object.entries(obj1).forEach(([key, value]) => {
        if(obj2[key] !== value) check = false;
    });
    return check 
}



function validateBODWedAnn(dob, wedAnn){
    console.log(dob, wedAnn);
    const valdob = validate(dob);
    const valwedann = validate(wedAnn);
    return valdob? valdob : valwedann? valwedann : false
    
    function validate(vals){
        let error = false;
        const spltVals = vals.toString().split('/');
        const s1 = spltVals[0].replace(/\D/g,'');
        const s2 = spltVals[1].replace(/\D/g,'');

        if(isNaN(parseInt(s1)) || isNaN(parseInt(s2))){return error = true}

        if((s1+s2).length !== 4){return error = true}
        if(parseInt(spltVals[0])> 12){return error = true}
        if(parseInt(spltVals[1])> 31){return error = true}
        if(!vals.includes('/')){return error = true}
        return error
    }
    
}


function validateDateField(fldVals){
    let vals = fldVals.toString();
    let error = false;
    if(vals.length !== 5){return error = true}
    if(vals.charAt(2) !== "/"){return error = true}

    const spltVals = vals.toString().split('/');
    const s1 = spltVals[0].replace(/\D/g,'');
    const s2 = spltVals[1].replace(/\D/g,'');

    if(isNaN(parseInt(s1))){return error = true}
    if(isNaN(parseInt(s2))){return error = true}

    if((s1+s2).length !== 4){return error = true}
    if(parseInt(spltVals[0])> 12){return error = true}
    if(parseInt(spltVals[1])> 31){return error = true}
    
    return error
}



function getRecords(allData, family){
    const  filterRes = allData.filter((item)=>{
            return item.family === family
        });
    
    return [filterRes.length, filterRes]
};


export {phoneNoExtract, getCompareResult, validateBODWedAnn, 
     getRecords, validateDateField}