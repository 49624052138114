import React from 'react';


const TabsOnlineOffering = ({setSelectedTab}) => {
    const [seltdIndex, setSltdIndex] = React.useState(0);
    const tabs = [
        {name:tabsNames.publishUpload, title: 'Publish Uploads'},
        {name:tabsNames.publishManual, title: 'Publish Manual'}, 
        {name:tabsNames.publishPayers, title: 'Publish Payers'},
        {name:tabsNames.queryPayers, title: 'Query Payers'}
    ];


    function handleClick(el){
        const {index, tab} = el;
        setSltdIndex(index); 
        setSelectedTab(tab);
    };
    return (
    <div className='border-2 mx-1 overflow-x-auto bg-slate-100 border-solid border-gray-500 flex flex-row'>
        {
            tabs.map((item, i)=>{

                return ( 
                <div key={`${i}+tab`} 
                    className={`${ seltdIndex === i? 'bg-[#06e8b3] text-[mediumblue]': 'bg-[#555] text-white'} 
                        cursor-pointer py-1 m-1 text-[10px] px-2 border border-blue-600 border-solid sm:text-base
                         relative whitespace-nowrap justify-center flex
                          w-full`}
                    onClick={()=>handleClick({index:i, tab:item})}>
                        {item.title}
                </div>)
            })
        }
    </div>
  )
}

export default TabsOnlineOffering;


const tabsNames = {
    publishUpload:'publishUpload',
    publishManual:'publishManual',
    publishPayers:'publishPayers',
    queryPayers:'queryPayers',
};
 export {tabsNames}