

const txtFieldsKeys = [
    'title',
    'surname',
    'firstname',
    'othernames',
    'addressRes',
    'dob',
    'genderStat',
    'ageGroup',
    'email',
    'phoneNo',
    'occupation',
    'addressOff',
    'school',
    'instiStat',
    'memberStat',
    'matStat',
    'wedAnn',
    'batStat',
    'photo',
];

/*const txtFieldsEmptyArr = {
    'info':'User',
    'title': "Brother",
    'surname':"",
    'firstname':"",
    'othernames':"",
    'addressRes':"",
    'dob':"2022-01-19",
    'genderStat':"Male",
    'email':"",
    'phoneNo':"",
    'occupation':"",
    'addressOff':"",
    'school':"",
    'instiStat':"",
    'memberStat':"",
    'matStat':"Single",
    'wedAnn':"",
    'batStat':"Baptised",
    'photo':"",

    //Hidden Info
    'family':'',
    'famStat':'',
};*/

const txtFieldsSpouse = [
    'title',
    'surname',
    'firstname',
    'othernames',
    'addressRes',
    'dob',
    'ageGroup',
    'email',
    'phoneNo',
    'occupation',
    'addressOff',
    'school',
    'instiStat',
    'memberStat',
    'batStat',
    'photo',
];

const txtFieldsChild = [
    'title',
    'surname',
    'firstname',
    'othernames',
    'addressRes',
    'dob',
    'genderStat',
    'ageGroup',
    'email',
    'phoneNo',
    'occupation',
    'addressOff',
    'school',
    'instiStat',
    'memberStat',
    'matStat',
    'batStat',
    'photo',
];

/*['S/N','Surname','Firstname', 'Othernames', 'Address', 'Date of Birth',
    'Birth Day', 'Phone Number', 'Email', 'Check', 'Edit'];*/

const txtFldHeader = {
    sn:{field:'sn', header:'S/N',},
    surname:{field:'surname', header:'Surname',},
    firstname:{field:'firstname', header:'Firstname',},
    othernames:{field:'othernames', header:'Othernames',},
    title:{field:'title', header:'Title',},
    genderStat:{field:'genderStat', header:'Gender',},
    ageGroup:{field:'ageGroup', header:'Age group'},
    addressRes:{field:'addressRes', header:'Residential Address',},
    dob:{field:'dob', header:'Date of Birth',},
    birthday:{field:'birthday', header:'Birthday',},
    phoneNo:{field:'phoneNo', header:'Phone Number',},
    email:{field:'email', header:'Email',},
    occupation:{field:'occupation', header:'Occupation',},
    addressOff:{field:'addressOff', header:'Office Address',},
    school:{field:'school', header:'School',},
    instiStat:{field:'instiStat', header:'Qualification',},
    matStat:{field:'matStat', header:'Marital Status',},
    memberStat:{field:'memberStat', header:'Membership Status',},
    batStat:{field:'batStat', header:'Baptismal Status',},
    wedAnn:{field:'wedAnn', header:'Date of Wedding',},
    wedAnns:{field:'wedAnns', header:'Wedding Anniversary',},
    
    
    select:{field:'select', header:'Select',},
    membership:{field:'membership', header:'Membership',},
    edit:{field:'edit', header:'Edit',},
    photo:{field:'photo', header:'Photo',},
    family:{field:'family', header:'Family',},
    famStat:{field:'famStat', header:'Family Status',},
    id:{field:'id', header:'ID',},
    info:{field:'info', header:'Info',},
    loginID:{field:'loginID', header:'Login ID',},
    filledBy:{field:'filledBy', header:'Filled By',},
    filledDate:{field:'filledDate', header:'Filled Date',},
    editedBy:{field:'editedBy', header:'Edited By and Date',},
    accesspermit:{field:'accesspermit', header:'Access Permit',},
    accessdenied:{field:'accessdenied', header:'Access Denied',},
    notification:{field:'notification', header:'Notification',},
    notifToggle:{field:'notifToggle', header:'Notice Toggle',},
};

const txtFieldsHidden = {
    fields:['select', 'membership', 'edit', 'photo','family','famStat', 'id', 'info', 'loginID', 'filledBy', 'filledDate','editedBy', 
            'accesspermit', 'accessdenied', 'notification', 'notifToggle'],

    select:{name:"select", title:'Select', pholder:"Select", type:"checkbox"},
    membership:{name:'membership', title:'Membership', pholder:"Membership: Existing member, Nonexisting member, Transferred, Relocated, Non-member, Late", type:'text'},
    edit:{name:"edit", title:'Edit', pholder:"Edit", type:"text"},
    photo:{name:"photo", title:'Photo', pholder:"Photo", type:"file"},
    family:{name:"family", title:'Family', pholder:"FamilyID: surname.fathername.mothername", type:"text"},
    famStat:{name:"surname", title:'Family Status', pholder:"FamStat: Parent, Child1, Child2, etc", type:"text"},
    id:{name:"id", title:'ID', pholder:"ID: surname.firstname.othername || gender", type:"text"},
    info:{name:"info", title:'Info', pholder:"Info: Father, Mother, Child1, Child2, etc", type:"text"},
    loginID:{name:"loginID", title:'LoginID', pholder:"LoginID- autoset", type:"text"},
    filledBy:{name:"filledBy", title:'Info filled by', pholder:"Info filled by- autoset", type:"text"},
    filledDate:{name:"filledDate", title:'Date of info entry', pholder:"Date of info entry", type:"date"},
    editedBy:{name:"editedBy", title:'Info edited by and date', pholder:"Info edited by and date- autoset", type:"text"},
    accesspermit:{name:"accesspermit", title:'FamID Access Permit', pholder:"FamilyID Access Permit granted", type:"text"},  
    accessdenied:{name:"accessdenied", title:'FamID Access Denied', pholder:"FamilyID Access Denied", type:"text"},
    notification:{name:"notification", title:'User notification', pholder:"User notification", type:"text"},
    notifToggle:{name:"notifToggle", title:'Notification toggle', pholder:"Notification toggle", type:"text"},  
 };

 
 const getMembershipArr = ()=>{
    const membershipArr = ["Existing member", "None-existing member", "New member", "Transferred", "Relocated", "None-member", "Late"];
    
    return membershipArr
 }

 const txtFieldsEmptyArr = {
    'info':'',
    'title':"",
    'surname':"",
    'firstname':"",
    'othernames':"",
    'addressRes':"",
    'dob':"",
    'genderStat':"",
    'ageGroup' :"",
    'email':"",
    'phoneNo':"",
    'occupation':"",
    'addressOff':"",
    'school':"",
    'instiStat':"",
    'memberStat':"",
    'matStat':"",
    'wedAnn':"",
    'batStat':"",
    'photo':"",

    //Hidden Info
    'family':'',
    'famStat':'',
};

function getFormField(type){

    
    const txtFldsHeader = {...txtFldHeader};

    const spouseForm ={
        info:'', famStat:'',family:'', title:"",
        surname: "", firstname:"", othernames:"", addressRes:"", 
        dob:"", genderStat:"",  ageGroup:"", email:"", phoneNo:"", occupation:"", 
        addressOff:"", school:"", instiStat:"", memberStat:"", batStat:"",
        wedAnn:"", photo:"" ,
    };
    const childForm ={
        info:'', childCount:"", famStat:'',family:'',title:"",
        surname: "", firstname:"", othernames:"", addressRes:"",
        dob:"", genderStat:"", ageGroup:"", email:"", phoneNo:"", occupation:"", 
        addressOff:"", school:"", instiStat:"", memberStat:"", batStat:"",
        photo:"",};  
    
    const formFields = {txtFieldsKeys,spouseForm, childForm, txtFieldsEmptyArr, 
        txtFieldsChild, txtFieldsSpouse, txtFldsHeader, txtFieldsHidden};
    
    return formFields[type];
};

//{info:'Spouse', title:"Sister",batStat:"Baptised",}
 export {txtFieldsKeys, txtFieldsEmptyArr, txtFieldsSpouse, txtFieldsChild, getFormField, txtFldHeader, getMembershipArr};