import dedication1 from './ChurchDedication2019-1.JPG';
import dedication2 from './ChurchDedication2019-2.JPG';
import dedication3 from './ChurchDedication2019-3.JPG';
import dedication4 from './ChurchDedication2019-4.JPG';
import dedication5 from './ChurchDedication2019-5.JPG';
import dedication6 from './ChurchDedication2019-6.JPG';
import dedication7 from './ChurchDedication2019-7.JPG';
import dedication8 from './ChurchDedication2019-8.JPG';
import dedication9 from './ChurchDedication2019-9.JPG';
import dedication10 from './ChurchDedication2019-10.JPG';
import dedication11 from './ChurchDedication2019-11.JPG';
import dedication12 from './ChurchDedication2019-12.JPG';
import dedication13 from './ChurchDedication2019-13.JPG';
import dedication14 from './ChurchDedication2019-14.JPG';
import dedication15 from './ChurchDedication2019-15.JPG';
import dedication16 from './ChurchDedication2019-16.JPG';
import dedication17 from './ChurchDedication2019-17.JPG';
import dedication18 from './ChurchDedication2019-18.JPG';
import dedication19 from './ChurchDedication2019-19.JPG';
import dedication20 from './ChurchDedication2019-20.JPG';
import dedication21 from './ChurchDedication2019-21.JPG';
import dedication22 from './ChurchDedication2019-22.JPG';
import dedication23 from './ChurchDedication2019-23.JPG';
import dedication24 from './ChurchDedication2019-24.JPG';
import dedication25 from './ChurchDedication2019-25.jpg';
import dedication26 from './ChurchDedication2019-26.jpg';



const churchDedication2019 = [
      {sn:1, src:dedication1,title:'Church dedication', desc:'GOFAMINT PS Ogba Church dedication by G.O (represented by GS) on 31 Mar., 2019', 
            bCol:'gray', date:'2019-03-31'},
      {sn:2, src:dedication2,},{sn:3, src:dedication3,},{sn:4, src:dedication4,},
      {sn:5, src:dedication5,},{sn:6, src:dedication6,},{sn:7, src:dedication7,},
      {sn:8, src:dedication8,},{sn:9, src:dedication9,},{sn:10, src:dedication10,},
      {sn:11, src:dedication11,},{sn:12, src:dedication12,},{sn:13, src:dedication13,},
      {sn:14, src:dedication14,},{sn:15, src:dedication15,},{sn:16, src:dedication16,},
      {sn:17, src:dedication17,},{sn:18, src:dedication18,},{sn:19, src:dedication19,},
      {sn:20, src:dedication20,},{sn:21, src:dedication21,},{sn:22, src:dedication22,},
      {sn:23, src:dedication23,},{sn:24, src:dedication24,},{sn:25, src:dedication25,},
      {sn:26, src:dedication26,},
     ];
    
export default churchDedication2019