import React, { useState } from 'react';
import { Button1, TextInput } from './InputsComponent';
import { SpanHLight } from './TextSpans';
import { getCompareResult, phoneNoExtract, validateDateField } from '../../modules/modules';
import { ProceedToRegister } from './Components';
import { infoToConfirmFunction } from '../../modules/infoToConfirm';
import { searchParentRecordHandler } from '../../modules/searchRecords';
import { useDispatch} from 'react-redux';
import { disCurrentStep, disSaveMyRecords } from '../../../../context/reducers/userSub';
//import { current } from '@reduxjs/toolkit';

const ConfirnInformation = ({seltdIndv,goToPage,allMembers, currentStep, setIsConfirmed, updateRecordCall, showUpdateRecBtn}) => {
    const dispatch = useDispatch();
    const [confirmVals, setConfirmVals] = React.useState([]);
    const [infoToConfirm, setInfoToConfirm] = React.useState(false);
    const [seltdIndvSub, setSeltdIndivSub] = React.useState({user:'USER', data:{}});
    const [confirmSuccessful, setConfirmSuccessful] = React.useState(false);
    const [confirmUnSuccessful, setConfirmUnSuccessful] = React.useState(false);
    const [wrongFmt, setWrongFmt] = useState(false); 
    const {surname, firstname,phoneNo, genderStat} = seltdIndv;
    const phoneNoSub = seltdIndvSub?.data?.phoneNo;
    //const [hide, setHide] = React.useState(true);
    const [showUpdateBtn, setShowUpdateBtn] = useState({show:true});
    

function onChangeInputsHandler(e, i){
    const {value, name} = e.target;
    let newConfirnVals = [...confirmVals];
    newConfirnVals[i].value = value;
    setConfirmVals(newConfirnVals);
    setWrongFmt(false);
    setConfirmSuccessful(false);
    setConfirmUnSuccessful(false);
    setInfoToConfirm(true);
    setIsConfirmed(false);
    dispatch(disCurrentStep({step:2, about:"CONFIRMINFO", details:""}));
    setShowUpdateBtn({show:true});
    //seltdIndivClickedCall(false)
}
function updateRecordHandler(){
    updateRecordCall();
}

function saveMyRecords(el){
    if(el){
        setShowUpdateBtn({show:false});
        dispatch(disSaveMyRecords(true));
    }else{setShowUpdateBtn({show:false});}
}

function confirmInfoHandler(e){

    e.preventDefault();
    if(currentStep.step !== 2){ return}
    const seldIndvToConfirm = seltdIndvSub.user === "USER"? seltdIndv : seltdIndvSub.data;
    const originVals = confirmVals.reduce((res, item)=>{ 
        return {...res, [item.name]: seldIndvToConfirm[item.name]}
    },{});
    const valsToConfirm = confirmVals.reduce((res, item)=>{ 
        return {...res, [item.name]: item.value}
    },{});
    
    let dateFldsValidation = false;
    if(valsToConfirm.dob){
        if(validateDateField(valsToConfirm.dob)) dateFldsValidation = true}
    if(valsToConfirm.wedAnn){if(validateDateField(valsToConfirm.wedAnn)) dateFldsValidation = true}
    setWrongFmt(dateFldsValidation);
    if(!dateFldsValidation){
        var isSame =  getCompareResult(valsToConfirm, originVals);
        setConfirmSuccessful(isSame);
        setConfirmUnSuccessful(!isSame);
        setIsConfirmed(isSame);
        //console.log('Comfirmed-'+isSame);
        //setInfoToConfirm(false);
        //console.log(isSame)
    }else{
        setIsConfirmed(false);
    }
    if(isSame){
        dispatch(disCurrentStep({step:3, about:"CONFIRMSUCCESSFUL", details:"", showBtn:'UPDATERECORD'}))
    }else{dispatch(disCurrentStep({step:3, about:"CONFIRMNOTSUCCESSFUL", details:"", showBtn: 'NEWREG'}))}
}


React.useEffect(()=>{
    const {confirmValsRes, infoToConfirmRes} = infoToConfirmFunction(seltdIndv);
    if(infoToConfirmRes){
        confirmInfo(confirmValsRes, infoToConfirmRes, {user:'USER'})
    }else{
        const {parent,parentData, confirmValsRes, infoToConfirmRes} = searchParentRecordHandler(seltdIndv, allMembers);
        if(infoToConfirmRes){    
            confirmInfo(confirmValsRes, infoToConfirmRes, {user:parent, data:parentData});
        }else{
            //Fallback to the user if no parent info
            confirmInfo(confirmValsRes, infoToConfirmRes, {user:'USER'})
        }
    }
    function confirmInfo(confVals, infoToConf, user){
        setConfirmVals(confVals);
        setInfoToConfirm(infoToConf);
        setSeltdIndivSub(user);
    }
    
    
},[seltdIndv]);

return (
    <div className={`${seltdIndv.surname? "flex flex-col" : "hidden"} mt-2`}>
    
        <form onSubmit={confirmInfoHandler}>
        {infoToConfirm?
            <>
                <div>
                    {seltdIndvSub.user === "USER"?
                        <p>Enter the following fields for <SpanHLight col="blue" bold text= {surname +" "+firstname}/> 
                        and click 'CONFIRM' to verify your identify.</p>:
                        <div>
                            <p>We do not have enough inforamtion about <SpanHLight col="blue" bold text= {surname +" "+firstname}/> 
                                in our database to confirm {genderStat==="Male"? 'his': 'her'} identity. We will use {genderStat==="Male"? 'his': 'her'}
                                <SpanHLight col="blue" bold text= {seltdIndvSub.user+"'s"}/> inforamtion for confirmation.
                            </p>
                            <p>Enter <SpanHLight col="blue" bold text= {firstname}/> <SpanHLight col="blue" bold text= {seltdIndvSub.user+"'s"}/> inforamtion and click 'CONFIRM' to verify your identify.</p>
                        </div>
                    }
                </div>
                    
                <p className={`${wrongFmt? 'visible text-[red] text-sm' : 'invisible'}`}>
                    Format for date of birth (dob) and wedding anniversary must be MM/DD. eg 05/21
                </p>
                <div className={`grid grid-cols-1 sm:grid-cols-2 gap-2 w-full`}>
                {
                    confirmVals?.map((item, i)=>{
                    
                        return(
                            <TextInput key={i+"Keys"}
                                value={confirmVals[i].value}
                                name={item.name}
                                type={item.name==="email"? 'email' : item.name==="phoneNo"? 'tel' : 'text'}
                                placeholder={item.name==="phoneNo"? item.pholder+"- "+phoneNoExtract(phoneNo || phoneNoSub) :
                                    item.pholder}
                                onChangeHandler={e=>onChangeInputsHandler(e, i)}
                                required
                                autoComplete="autoComplete"
                                maxLength={item.name==="dob" || item.name==="wedAnn"? 5 : 50}
                            />
                        );
                    })
                }
                </div>
                <input type='submit' value="Confirm" className={currentStep.step===2? submitBtn.active : submitBtn.nonActive}/>
            </>:
            <ProceedToRegister
                surname={seltdIndvSub.user==="USER"? surname : seltdIndvSub.data.surname}
                firstname={seltdIndvSub.user==="USER"? firstname : seltdIndvSub.data.firstname}
                goToPage={goToPage}
                type="TYPE1"
            />
        }
        
        </form>
        {confirmSuccessful && confirmVals.length >2?    
        <div className={``}>
            <p className='text-[green]'>
                <span className='text-[red] font-bold underline pr-2'>Step 3:</span>
                Confirmation successfull!. You may proceed to Update Record
            </p>
            <div className={`${showUpdateBtn.show? 'hidden' : 'hidden'} mt-2`}>
              <p>Do you want to save your form in <SpanHLight underline col="magenta" text="My Records"/>? If you do, you will automatically be able to view your records in your next login.</p>
                <div className='flex flex-row flex-wrap gap-2'>
                    <Button1 title="Save" btnStyle="my-1 bg-blue-500"
                        onClick={()=>saveMyRecords(true)}
                        />
                    <Button1 title="Cancel" btnStyle="my-1 bg-stone-300 text-gray-800"
                        onClick={()=>saveMyRecords(false)}
                        />
                </div>
            </div>
            <div className={`${showUpdateRecBtn? '' : 'hidden'}`}>
                <Button1 title="Update Record" btnStyle="my-1 bg-[seagreen]"
                    onClick={updateRecordHandler}
                    />
            </div>
        </div>:
        <div className={`${confirmVals.length > 2 && confirmUnSuccessful && infoToConfirm? "" : 'hidden'}`}>
          <ProceedToRegister
            surname={seltdIndvSub.user==="USER"? surname : seltdIndvSub.data.surname}
            firstname={seltdIndvSub.user==="USER"? firstname : seltdIndvSub.data.firstname}
            goToPage={goToPage} 
            type="TYPE2"
            />
        </div>
        }
    </div>
  )
}

export default ConfirnInformation;
//{seltdIndv.genderStat==="Male"? " his" : " her"}

const submitBtn ={
    active:'py-2 px-6 text-white my-3 bg-[seagreen] hover:bg-[green] active:bg-[green]',
    nonActive:'py-2 px-6 text-black my-3 bg-[silver] hover:bg-gray-300 active:bg-gray-300',
};