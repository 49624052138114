import React from "react";
import ViewField from "../../components/FormViewField";
import {txtFieldsKeys, } from "../../assets/textFieldsMain";
import txtFields from "../../assets/textFields";
import HorizontalLine from "../../../HorizontalLine";


const FormView =({onChangeHandler,uploadingImage,uploadingImageFormIndex, setOnEditName, defSelected, required, autoComplete,
    formValues,indvForm,isReadOnly, imageSrc, onEdit,  editToggle, initRecordsMatStat, formIndex})=>{


    function getReadType(item, type){
        let readOnly = false;
        if(item==="genderStat" && type !== "NEWFORM"){
           readOnly = true;
        }else if(item==="matStat" && type !=="NEWFORM" &&  initRecordsMatStat==="Married"){
            readOnly = true
        }else{ readOnly = false}
        return readOnly
    }
    return(
        <div className='border-2 border-gray-200 bg-gray-50 border-solid'>
                <div className="flex w-full justify-center items-center">
                    <div className={`${formValues.photo? 'bg-[lime]' : 'bg-gray-500'} w-[95px] h-[95px] rounded-full  flex justify-center items-center`}>
                        <img src={formValues.photo || ""} alt="" className="w-[90px] h-[90px] rounded-full"/>
                    </div>
                </div>
                <div className={`flex w-full justify-center items-center ${uploadingImage && (uploadingImageFormIndex===formIndex)? '':'hidden'}`}>
                    <p>Uploading image, please, wait...</p>
                </div>
                <HorizontalLine widths={95} margTop={5} bColor="teal" margBot={10}/>
                <div className="grid grid-cols-1 lg:grid-cols-2">
                    {
                        txtFieldsKeys.map((item, index)=>{
                            var fld = txtFields[item];
                            const requiredFlds = item==="surname" || item==="firstname" || item==="dob" || item==="genderStat" || item==="instiStat" 
                                    || item==="memberStat" || item==="matStat" || item==="batStat" || item==="title"? "required" : "";
                            return(
                                
                                <ViewField item={item} key={`${index}+formIn`} 
                                    title={`${fld['title']}${requiredFlds? '*' : ""}`}
                                    type={fld["type"]}
                                    onChange={onChangeHandler}
                                    name={item}
                                    value={indvForm[item]}
                                    defSelected={formValues[item]}
                                    isReadOnly={getReadType(item, indvForm.type)}
                                    //onEdit={onEdit}
                                    //onEditToggle={()=>setOnEditName(item)}
                                    //editToggle={editToggle}
                                    autoComplete={autoComplete}
                                    required={requiredFlds}
                                    optArr={txtFields[item].optArr}
                                />
                                
                            );
                        })
                    }
                </div>
                
        </div>
    );
}

export default FormView;