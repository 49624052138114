import React from 'react';
import Collapsible from '../../../../Collapsible';
import Message from './Message';

const FeedbackMessages = ({fetchContents}) => {
  return (
    <div className='p-3'>
        <p className='font-bold text-[maroon] pl-5 underline'>Feedback Messages from Users</p>
        <div>
          {
            fetchContents?.map((item, i)=>{
              return (
                <div key = {`${i}+key`}>   
                    <Messages item={item}/>
                </div>
              );
            })
          }
        </div>
    </div>
  )
}

export default FeedbackMessages;




const Messages =({item})=>{
 
    return(
      <Collapsible
          contStyle={`mx-1 sm:mx-3 mb-3 mx-1 w-full border-2 border-solid 
            ${item.newMessage? 'border-[mediumblue]' : 'border-[gray]'}`}
          titleStyle={`bg-slate-300 ${item.newMessage? 'text-[mediumblue]' : 'text-gray-700'}`}
          iconCol="fill-gray"
          iconType="plus"
          addedInfo={`${item.title} -${item.datePosted}`}
          hideDeleteIcon
          item={item}
      >
        <Message item={item}/>
      </Collapsible>
    );
  }
  
  
const Field =({title, main, flexCol, noUnderline, fldMagT, color, titleUnder})=>{

    return (
        <div className={`flex mb-1 ${flexCol? 'flex-col' :'flex-row'}`}>
            <p className={`pr-1 text-gray-700 ${fldMagT? fldMagT :''} ${titleUnder? titleUnder :''}`}>{title}</p>
            <p className={`text-justify ${noUnderline? '' : 'underline'} ${ color? color : 'text-[#191949]'}`}>
                {main}
            </p>
        </div>
    );
}