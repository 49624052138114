import {client} from '../../../client';


async function fetchCelebrantsData(cel, mn){
    const query = queryCelebrationMnth(cel, mn);
      const response = await client.fetch(query);
      //console.log(response);
        return response;
  }

  function getUserForm(loginFm, logout){
    const user={surname:loginFm.surname,firstname:loginFm.firstname,
        fullName:`${loginFm.surname} ${loginFm.firstname}`, title:'',
        email:loginFm.email,loginID:'',userID:loginFm.userID, imageUrl:"", famID:loginFm.famID};
    
      return user
  }
  /*async function retrieveAndDispatch() {
    const response = await persistState?.uploadedData;
    dispatchData(response);
    console.log(response);
    //return response;
  }*/

   /*React.useEffect(()=>{
    if(appIsOnline){
      const mnthFig = new Date().getMonth();
        const mnth = months[mnthFig];
        //retrieveAndDispatch();
        fetchCelebrantsData('birthday', mnth).then((res)=>{
          dispatch(disCelebrantsData({...celebrantsData, birthday:res}));
      }); 
        fetchCelebrantsData('wedAnns', mnth).then((res)=>{
          dispatch(disCelebrantsData({...celebrantsData, wedAnns:res}));
      });
    } 
   },[appIsOnline, loginForm.isOnline]);*/

   /* React.useEffect(()=>{
    if(formDataPosted[0].surname){
       const names =  getNames(formDataPosted);
       setRegisterdNames(names);

    }else{
        setRegisterdNames(['No one has been registered'])
    }
  },[formDataPosted]);*/


 
  
export {fetchCelebrantsData, getUserForm}




  
function queryCelebrationMnth(cele, mn){
    
  const query = `
  *[ _type == "members" && ${cele} match '${mn}']{
    title,
    surname,
    firstname,
    othernames,
    birthday,
    wedAnn,
    wedAnns,
    famStat,
    genderStat
  }
`;
return query
}

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep","Oct","Nov","Dec"];