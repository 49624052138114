import React  from 'react';
import { Button1 } from './InputsComponent';
import ConfirnInformation from './ConfirnInformation';
import { useDispatch, useSelector } from 'react-redux';
import {disConfirmIndv } from '../../../../context/reducers/user';
import { disCurrentStep } from '../../../../context/reducers/userSub';


const SearchRecords = ({searchVals, filterRecord, filterRecordsOthers, setIsConfirmed,
    beginSearch, seltdIndiv, setSeltdIndiv, goToPage, searchedData,  updateRecordCall, showUpdateRecBtn}) => {

  const dispatch = useDispatch();
  const {confirmIndv } = useSelector((state)=>state.user);
  const {currentStep } = useSelector((state)=>state.userSub);
  const inlineSpan = "bg-white p-1";
  const indivRecordFound = filterRecord[0]?.surname? true :false;
  const otherRecordsFound = filterRecordsOthers?.length>0? true :false;
  const searchFullName = searchVals?.surname +" "+searchVals?.firstname;
  const filterdFullName = filterRecord[0]?.surname + " "+filterRecord[0]?.firstname +" "+filterRecord[0]?.othernames;
  const recordFoundInfo = <h1 className='text-[seagreen]'> Congratulation <span className={inlineSpan}>{filterdFullName}</span> record was found </h1>;
  const recordNotFoundInfo = <h1 className='text-[maroon]'>Unfortunately, <span className={inlineSpan}>{searchFullName}</span> record could not be found!</h1>;
  
  function seltdIndivClickedHandler(val){
    dispatch(disConfirmIndv({...confirmIndv, clicked:val}))
  }

    let recordNotFound = "";
    if(otherRecordsFound){
        recordNotFound =( 
                <div>
                    <p>{notes.otherRecFound}</p>
                    <SearchRecordsDisplay searchRecords={filterRecordsOthers}
                        indivFound={indivRecordFound}
                        setSeltdIndiv={setSeltdIndiv}
                        />
                </div>);
    }else{
        recordNotFound =( 
            <div>
                <p>{notes.noRecFound}</p>
                <Button1 title="New Registration"
                    onClick={()=>goToPage("RECORDREGISTER")}
                    //btnStyle='bg-[red]'
                    />
            </div>);
    }

    return (
            <div>
                <div className={`${beginSearch? "flex" : "hidden"}`}>
                    {indivRecordFound?
                      <div>
                        <div className={`flex text-[seagreen] bg-slate-200 px-2 py-1`}>
                            {recordFoundInfo}
                        </div>
                        <div>
                            <p className={`${otherRecordsFound? "flex" : "hidden"}`}>
                                {notes.recFound}
                            </p>
                            <p><span className='text-[red] font-bold underline pr-2'>Step 2:</span>
                                {notes.proceed}
                            </p>
                        </div>
                        <div className={`flex`}>
                            <SearchRecordsDisplay searchRecords={[...filterRecord, ...filterRecordsOthers]}
                             indivFound={indivRecordFound}
                             setSeltdIndiv={setSeltdIndiv}/>
                        </div>
                       </div>
                    :
                      <div>
                        <div className={`flex text-[maroon]'} bg-slate-200 px-2 py-1`}>
                            {recordNotFoundInfo} 
                        </div>
                        <div className='pb-2'><span className='text-[red] font-bold underline pr-2'>Step 2:</span>
                            {recordNotFound}
                        </div>
                        <div className={`${confirmIndv.clicked? 'hidden' :''}`}>
                            <Button1 title="New Registration"
                                onClick={()=>goToPage("RECORDREGISTER")}/>
                        </div>
                      </div>
                    }
                </div>
                <ConfirnInformation 
                    seltdIndv={seltdIndiv}
                    allMembers={searchedData}
                    goToPage={goToPage}
                    updateRecordCall={updateRecordCall}
                    seltdIndivClickedCall={seltdIndivClickedHandler}
                    setIsConfirmed={setIsConfirmed}
                    showUpdateRecBtn={showUpdateRecBtn}
                    currentStep={currentStep}
                    />
            </div>
  )
}


export default SearchRecords;



function SearchRecordsDisplay({searchRecords, indivFound, setSeltdIndiv,}){
    const dispatch = useDispatch();
    const {confirmIndv } = useSelector((state)=>state.user);
    function dispatchCall(){
        dispatch(disConfirmIndv({...confirmIndv, clicked:true}))
      }
    return(
        <div>
            {
                searchRecords?.map((record, i)=>{

                    return(
                        <p key={`${i}+key`}
                            className={`${indivFound && i===0? "text-[green]" : "text-[mediumblue]"}  font-bold py-1 hover:text-blue-500
                            cursor-pointer active:bg-transparent`}
                            onClick={()=>{
                                setSeltdIndiv(record); dispatchCall();
                                dispatch(disCurrentStep({step:2, about:"CONFIRMINFO", details:""})) 
                                }}>
                            {i+1}. {record.surname} {record.firstname} {record.othernames || ""} 
                        </p>
                    );
                })
            }
        </div>
    );
}


const notes={
    
    recFound:"We also found some other names",
    proceed:"Click on the name to do identify confirmation before proceeding  to view/update the record.",
    otherRecFound: "We however found some other records. You can click on it to confirm and proceed for update or click on the button bellow to do a fresh registration.",
    noRecFound:"Click on the button bellow to do a new registration.",
}