import React from 'react'
import { Chart } from "react-google-charts";


const ChartCard = ({options, data, legends}) => {
  return (
    <div className=' m-4 boxShadow'>
       <Chart
        chartType="PieChart"
        data={data}
        options={options}
        width={"100%"}
        height={"400px"}
        //width={'800'}
        //height= {'600'}
        //chartArea= {width: "50%", height: "70%" }
        legendToggle
      />
      {legends?
        <div className={`ml-5 mb-5`}>
          <p className='font-bold text-lg text-red-600 underline'>
            Guide
          </p>
          {legends.map((item)=>{

            return(
            <div className='flex flex-row pt-2'>
              <p className='font-bold underline pr-2'>{item.title}:</p>
              <p>{item.value}</p>
            </div>);
          })}
      </div> : <></>}
    </div>
  )
}

export default ChartCard;


export const data = [
  ["Members", "Registered Members"],
  ["Males", 101],
  ["Females",120],
];

export const options = {
  title: "Total Number of Registered Members",
  is3D: true,
};

