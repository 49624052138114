import React from 'react';
import { Main, Spouse, Child } from './Components'
import { useSelector } from 'react-redux';
//import { filterData } from '../../utils/filterData';

//var defVal = "defVal";
const Report = React.forwardRef((props, ref) => {
    const {filteredData, filteredDataObj} = useSelector((state)=>state.membersDataAll);

    //console.log(props.reportData);
    return (
        <div className={`bg-[#eee] overflow-y-auto ${props.hidePrintBtn? "" : "m-3 mt-12 p-2 h-screen"}`}
         ref={ref}>
            <div className={`flex justify-center flex-col items-center py-4 font-bold bg-gradient-to-b from-blue-400 to-blue-200 `}>
                <p className={`${props.print? 'text-3xl' : 'text-xl'} text-center text-[navy]`}>The Gospel Faith Mission International</p>
                <p className={`${props.print? 'text-xl' : 'text-lg'}  text-center text-[maroon]`}>Pacesetters Assembly, Ogba District Headquarters</p>
                <p className={`${props.print? 'text-lg' : ''}  text-center `}>7 Odekunle Street, Off Oluyemi Erinoso Street, Behind County Hospital</p>
                <p className={`${props.print? 'text-lg' : ''}  text-center`}>Aguda, Ogba, Lagos</p>
                <p className={`${props.print? 'text-xl' : 'text-lg'}  text-center text-[#a22da2] pt-3`}>
                    Membership Forms: {filteredData} Data
                </p>
            </div>
            <>
                {
                    props?.reportData?.map((fam, i)=>{
                        //var parent = [], children = [];
                        const user = fam?.parents[0], spouse = fam?.parents[1]? fam?.parents[1] : "";
                        const children = fam?.children;
                        const childComp = fam?.children?.length? true : false;

                        return (
                            <div key={`${i}+par`} className='bg-[#eee] m-3 py-5 border-2 border-solid 
                                border-[blue] '>
                                <Main form={user} print={props.print} reportTitle={filteredData} filteredDataObj={filteredDataObj}/>
                                <div className="reactprt-nxtpage-start" />
                                <Spouse form={spouse} childComp ={childComp} print={props.print} reportTitle={filteredData} filteredDataObj={filteredDataObj}/>
                                <div className="reactprt-nxtpage-start" />
                                {
                                    children?.map((form, j)=>{
                                        return( 
                                        <div key={`${j}+par`}>
                                            <Child form={form} index={j+1} print={props.print} reportTitle={filteredData} filteredDataObj={filteredDataObj}/>
                                            <div className="reactprt-nxtpage-start" />
                                        </div>
                                        )
                                    })
                                }
                            </div>
                            );
                    })   
                }
            </>
        </div>
  )
});


export default Report