import React, {useEffect, useState, useRef} from "react";
//import { createObjFromArrays } from "./createObjFromUploads";
var XLSX = require("xlsx");


function FileUploaderCustomed({btnStyle, title, setUploadedData}){
 const [uploadStatus, setUploadStatus] = useState(false);
 const [fileData, setFileData] = useState({raw:'', processed:''});
  const uploadRef = useRef();
    //console.log(fileData)
  function handleChange(e) {
    const files = e.target.files;
    const fileExtension = e.target.value.split('.')[1];
    const fileUploaded = e.target.value.length > 0? 1 : '';
      if (files && files[0]){
        const file = e.target.files[0];
        const fileName = e.target.files[0].name;
        readSpreedSheetFile(file, fileUploaded, fileName, fileExtension);
      }
      setUploadStatus(fileUploaded);
    }
  useEffect(()=>{
    if(fileData.raw.length){
      const res = validateUploadFile(fileData.raw);
       setUploadedData({data:fileData.raw, noError:res});
       //console.log({data:fileData.raw, noError:res});
    }
  },[fileData.raw]);
  function readSpreedSheetFile(file, fileUploaded, fileName, fileType) {
    const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      reader.onload = e => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        setFileData({raw:data, });
        //const res = createObjFromArrays(data);
        //setUploadedData(res);
      };
      if(rABS){
        reader.readAsBinaryString(file);
        }else {
            reader.readAsArrayBuffer(file);
    }
  }
    return (
      <div className="w-fit">
            <input
              type="file"
              accept={SheetJSFT}
              onChange={handleChange}
              name="testing"
              ref={uploadRef}
              className="hidden"
            />
            <div
              onClick={()=>uploadRef.current.click()}
              className={btnStyle}>
                {title || 'Upload Data'}
            </div>
      </div>
    );
}

export default FileUploaderCustomed

function validateUploadFile(file, keyHeader){
  let isNoError = false;
  file.forEach(item => {
    if(item.length !== 2){
      isNoError = false; return
    }else{ isNoError = true} 
  });
  return isNoError
}

/* list of supported file types */
const SheetJSFT = [
  "xlsx",
  "csv",
  "xls",
  "txt",
  /*"xlsb",
  "xlsm",
  "xml",
  "txt",
  "ods",
  "fods",
  "uos",
  "sylk",
  "dif",
  "dbf",
  "prn",
  "qpw",
  "123",
  "wb*",
  "wq*",
  "html",
  "htm"*/
]
  .map(function(x) {
    return "." + x;
  })
  .join(",");