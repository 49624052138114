import { createSlice } from '@reduxjs/toolkit';
//import { txtFieldsEmptyArr } from '../../components/userpage/textFieldsMain';

//const defaultForm = txtFieldsEmptyArr;

const initialState = {
  fetchedPrograms:{},
  fetchedControls:{},
  showAllTableFlds:false,
  memberTableViewAll:false,
  adminUser:true,
}


export const devReducer = createSlice({
    name: 'dev',
    initialState,
    
    reducers: {
      disFectedPrograms: (state, action) => {
        state.fetchedPrograms = action.payload
      },
      disFectedControls: (state, action) => {
        state.fetchedControls = action.payload
      },
      disShowAllTableFlds: (state, action) => {
        state.showAllTableFlds = action.payload
      },
      disMemberTableViewAll: (state, action) => {
        state.memberTableViewAll = action.payload
      },
      disAdminUser: (state, action) => {
        state.adminUser = action.payload
      },
    }
});



export const { disFectedPrograms, disFectedControls, disShowAllTableFlds, 
    disMemberTableViewAll, disAdminUser,
 } = devReducer.actions
  
    
  export default devReducer.reducer
