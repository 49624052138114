import React, { useState, useEffect } from 'react';
import { BsArrowLeft, BsArrowRight} from 'react-icons/bs';
import CheckboxCustomise from '../../../../../assets/svg/CheckboxCustomise';


const TableButtonsSub = ({excludeHeaders, excludeHeadersArr}) => {
  const [checked, setChecked] = useState(headersDefChecked);
  const contRef = React.useRef(null);
  const elRef = React.useRef(null);
  function scroll(el){
    //const scrollOffset = 

    if(el==='FIRSTEL'){
      contRef.current.scrollTo({left: 0,  behavior: 'smooth' });
    }else{
      elRef.current.scrollIntoView({behavior: 'smooth' });
    }
  }
  function handleChecked(el){
    setChecked(prev=>({...prev, [el]:!prev[el]}));
  }
  
  useEffect(()=>{
    let selected = [];
    for(let check in checked){
      if(checked[check]){
       selected = [...selected, check];
      }
    }  
    excludeHeadersArr(selected);
  },[checked]);


  useEffect(()=>{
    //Reset if Not Checked
    if(!excludeHeaders){
      //setChecked(headersDefChecked);
      //excludeHeadersArr([]);
    }
  },[excludeHeaders, excludeHeadersArr]);

  let count = 0;
  for(let check in checked){
    if(checked[check]){
      count +=1;
    }
  }
  

  return (
    <div className={`${!excludeHeaders? 'hidden' : 'flex'} justify-center`}>
        <div className='w-[95vw] sm:w-[88vw] h-8 border-2 border-solid border-gray-800
            flex justify-between text-white'>
            <div className='bg-gray-800 h-full px-1 flex justify-center items-center cursor-pointer'
              onClick={()=>scroll('FIRSTEL')}>
              <BsArrowLeft size={20} color="white"/>
              <span className='whitespace-nowrap text-sm border-l-2 border-solid border-white pl-2'
              >
              Don't export {count}
            </span>
            </div>
              <div className='border-2 
                flex items-center overflow-x-scroll hide-scrollbar'
                ref={contRef}
              >
                {
                  headers.map((header, i)=>{

                    return(
                      <div key={`${i}+sub`} className={`${checked[header]? 'text-[red]': 'text-[blue]'} items-center flex bg-slate-300 px-2 flex-row mx-1`}>
                        <label className='pr-1'>{header}</label>
                        <CheckboxCustomise 
                          checked={excludeHeaders? checked[header] : false}
                          setChecked={()=>handleChecked(header)}
                        />
                      </div>
                    );
                  })
                }
              <div ref={elRef}
                  className='w-2 h-full'>    
              </div>
              </div>
              <div className='bg-gray-800 h-full px-1 flex justify-center items-center cursor-pointer'
                onClick={()=>scroll('LASTEL')}
                >
                <BsArrowRight size={20} color="white"/>
              </div>
        </div>
    </div>
  )
}

export default TableButtonsSub;


const headers = [
    'title',
    'othernames',
    'addressRes',
    'dob',
    'birthday',
    'genderStat',
    'ageGroup',
    'email',
    'phoneNo',
    'occupation',
    'addressOff',
    'school',
    'instiStat',
    'memberStat',
    'matStat',
    'batStat',
    'wedAnn',
    'wedAnns',
];

const headersDefChecked = {
  'title':false,
  'othernames':false,
  'addressRes':false,
  'dob':false,
  'genderStat':false,
  'ageGroup':false,
  'email':false,
  'phoneNo':false,
  'occupation':false,
  'addressOff':false,
  'school':false,
  'instiStat':false,
  'memberStat':false,
  'matStat':false,
  'batStat':false,
  'wedAnn':false,
  'wedAnns':false,
  'birthday':false,
};
