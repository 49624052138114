import React, { useState } from 'react';
//import {groupArrOfObjToObj, groupByObjArrs} from '../../../utils/groupArrElements';
import Tabs from './Tabs';
import Collapsible from '../../Collapsible';
import CelebrantsBirthday from './components/CelebrantsBirthday';
import CelebrantsWeddingAnn from './components/CelebrantsWeddingAnn';
import UserGuide from './components/UserGuide';
import ContactAdmin from './components/ContactAdmin';
import { useSelector, useDispatch } from 'react-redux';
import {disCelebrantsBirthday, disCelebrantsData, disCelebrantsWedAnns} from "../../../context/reducers/user";
import {disOnline,disUser,} from "../../../context/reducers/login";
import { celebrantsBirthday, celebrantsWeddingAnn } from './modules';
//import useOnline from 'use-online';
import MyInbox from './components/MyInbox';
import { fetchCelebrantsData, getUserForm } from './celebrantsDataModules';
import NonCollapsible from '../../NonCollapsible';
import { client } from '../../../client';
import { getPayersQuery } from '../admin/account/components/modules/queries';
import { disOnlineOffPayers, disOnlineOfferings, disTableDataOff, disLastStmtDateYYMMDD, disLastStmtDate } from '../../../context/reducers/adminReducer';
import { getOfferingData } from '../admin/account/components/modules/offeringsModule';
import useOnline from 'use-online';
import { getFmtDate } from '../admin/account/components/memberStatement/_Index';


const Dashboard = () => {
  const dispatch = useDispatch();
  const isOnline = useOnline();
  const {celebrantsData, celebBirthday} = useSelector((state)=>state.user);
  const {loginForm} = useSelector((state)=>state.login);
  const [closeReport, setCloseReport] = useState(false);
  const [collapse, setCollapse] = React.useState(0);
  const [selectedTab, setSelectedTab] = useState({name:'userguide', title: 'User Guide'});
  const bdTodayCelebrant = celebBirthday?.today?.length;
  const {myInbox} = useSelector((state)=>state.userSub);
  const unreadMsgs = myInbox.filter(item =>  item.newMessage).length;
  const {onlineOffPayers, onlineOfferings, refreshCtrl} = useSelector((state)=>state.admin);
  const isNonMember = loginForm.checkedMember === "nonmember";

  function goOnline(loginFm, logout){
    const user= getUserForm();
    dispatch(disUser(user));
    if(logout){
      dispatch(disOnline(false));
    }else{dispatch(disOnline(true));}
  }

  async function celebrantsDataFetchHandler() {
      const mnthFig = new Date().getMonth();
        const mnth = months[mnthFig];
      await  fetchCelebrantsData('birthday', mnth).then((res)=>{
          dispatch(disCelebrantsData({...celebrantsData, birthday:res}));
      }); 
      await  fetchCelebrantsData('wedAnns', mnth).then((res)=>{
          dispatch(disCelebrantsData({...celebrantsData, wedAnns:res}));
      });
  }

  React.useEffect(()=>{
    if(isOnline && !celebrantsData.birthday.length){
      celebrantsDataFetchHandler();
    }
  },[isOnline]);
  
   React.useEffect(()=>{
      if(celebrantsData.birthday.length){
        const {mnthCelebrants, todayCelebrants} = celebrantsBirthday(celebrantsData.birthday);
        dispatch(disCelebrantsBirthday({month:mnthCelebrants, today:todayCelebrants}));
      }
      if(celebrantsData.wedAnns.length){
        const {mnthCelebWA, todayCelebWA} = celebrantsWeddingAnn(celebrantsData.wedAnns);
        //console.log([mnthCelebrants, todayCelebrants])
        dispatch(disCelebrantsWedAnns({month:mnthCelebWA, today:todayCelebWA}));
      }
   },[celebrantsData]);

  // console.log(celebrantsData);
  const displayTab = {
   userguide: <UserGuide goOnline={goOnline}/>,
   birthday: <CelebrantsBirthday
        //birthdayCelebrants={celebrantsData.birthday}
        //mnthCelBd={celebBirthday.month}
        //todayCelBd={celebBirthday.today}
        />,
   weddingann:  <CelebrantsWeddingAnn 
        //wedAnnsCelebrants={celebrantsData.wedAnns}
        //mnthCelWA={celebWedAnns.month}
        //todayCelWA={celebWedAnns.today}
        />,
   myInbox: <MyInbox myInbox={myInbox}/>,
   contactAdmin: <ContactAdmin/>,
  };

  //------------ONLINE OFFERING-------------------//
  function updateTableDataOff(){
      const {tableHeaders, tableBodyKeys, tableBody}= getOfferingData(onlineOffPayers, onlineOfferings);
      dispatch(disTableDataOff({tableHeaders, tableBodyKeys, tableBody}));

      const lastItem = tableBody?.find(item=> item.name === 'TOTAL');
      const lastDate = getFmtDate(lastItem.lastDate);
      dispatch(disLastStmtDate(lastDate));
      dispatch(disLastStmtDateYYMMDD(lastItem.lastDate));
  }
  React.useEffect(()=>{
    const queryPayers = getPayersQuery();
    //if(isOnline){
     client.fetch(queryPayers).then((data) => {
         const {offeringPayers, offerings} = data;
         dispatch(disOnlineOffPayers(offeringPayers || []));
         dispatch(disOnlineOfferings(offerings || []));
       });
    //}
   },[isOnline, refreshCtrl]);
 
   React.useEffect(()=>{
    if(onlineOffPayers.length){
      updateTableDataOff();
    }
   },[onlineOffPayers, onlineOfferings]);
  
 
  return (
    <div className='p-2 mt-2 mx-2 shadow-xl border-2'
    >
        <Tabs closeReport={()=>setCloseReport(false)}
            setSelectedTab={setSelectedTab}
            closeCollapse={()=>setCollapse(prev=>prev+1)}
            unreadInbox={unreadMsgs}
            isNonMember={isNonMember}/>
        {selectedTab.name === "myInbox"?
         <NonCollapsible
              contStyle="mb-3 mx-1 border-2 border-solid border-teal-700"
              titleStyle={` bg-teal-600 text-white`}
              iconCol="fill-white"
              iconHoverCol="hover:fill-[yellow]"
              hideDeleteIcon
              collapse={collapse}
              addedInfo={selectedTab.title}
              iconType="plus"
              >
            {displayTab[selectedTab.name]}
          </NonCollapsible> :
          <Collapsible
            contStyle="mb-3 mx-1 border-2 border-solid border-teal-700"
            titleStyle={` bg-teal-600 text-white`}
            iconCol="fill-white"
            iconHoverCol="hover:fill-[yellow]"
            hideDeleteIcon
            collapse={collapse}
            addedInfo={selectedTab.title}
            iconType="plus"
            showCake={selectedTab.name === "birthday" && bdTodayCelebrant}
            >
          {displayTab[selectedTab.name]}
        </Collapsible>
        }
    </div>
  )
}

export default Dashboard

const Collapsible2 = ({children})=>{

  return(
    <div className={`mb-3 mx-1 border-2 border-solid border-teal-700`}>
      <div className={` bg-teal-600 text-white`}>Testing Message</div>
      {children}
    </div>
  );
}

function getNames(regLst){
    let names = [];
    regLst.forEach((item, index)=>{
      const name = (index+1)+ ". " + item.surname + " " + item.firstname;
      names = [...names, name]
    });
   return names
}

//const mn = 11; const month = `*-${mn}-*`; 
//const query = `*[_type == "members" && dob match '${month}']{_id, surname, firstname, dob}`;

function queryCelebrationMnth(cele, mn){
    
    const query = `
    *[ _type == "members" && ${cele} match '${mn}']{
      title,
      surname,
      firstname,
      othernames,
      birthday,
      wedAnn,
      wedAnns,
      famStat,
      genderStat
    }
  `;
  return query
}

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep","Oct","Nov","Dec"];
