import React from "react";
import { BsToggle2Off,BsToggle2On, } from "react-icons/bs";

const InputText = ({labelStyle,contStyle, inputStyle, name, type, placeholder, onChangeHandler,
        title, value})=>{


    return(
        <div className={`${contStyle} flex flex-row, `}>
            <label className={`${labelStyle}`}>
                {title}
            </label>
            <input
                className={`${inputStyle}`}
                placeholder={placeholder}
                name={name}
                value={value}
                type={type}
                onChange={onChangeHandler}
            />

        </div>

        );
}


const ToggleButton=({setToggleVal, toggleVal, contStyle, labelStyle, title, titleSub, labelSubStyle})=>{
  

    return(
        <div className={`${contStyle} flex flex-row, items-center w-fit`}>
            <label className={`${labelStyle}`}>
                {title}
                <span className={`${labelSubStyle} font-bold pl-1`}>{titleSub}</span>
            </label>
            <div onClick={()=>setToggleVal()} 
                className="items-center">
                {toggleVal? <BsToggle2On color="mediumblue" size={32}/> :<BsToggle2Off size={32} color="silver"/>}
            </div>
        </div>
    );
}


const Button =({title, onClickHandler, btnStyle, bgCol, bgHov})=>{

    return(
        <div className={`cursor-pointer p-2 px-5 rounded-sm text-white 
           active:bg-stone-200 w-fit  
           ${bgCol? bgCol : 'bg-[#3a3a5f]'} ${bgHov? bgHov : 'hover:bg-[#28285d]'}
           ${btnStyle}
           `}
         onClick={onClickHandler}>
            {title}
        </div>
    );
}
export {InputText, ToggleButton, Button}