import React from 'react';
/*import RandomBibleVerses from './BibleVerseDisplay';
import { bibleRefsArr } from './data';
import VideoPlayerAuto from './PlayVideoAuto';
import VideoPlayerNoAutoRaw from './PlayVideoAutoRaw';
import { useSelector } from 'react-redux';
*/
import VideoPlayerAutoRaw from './PlayVideoAutoRaw';
//import bgVideo from '../../assets/videos/PassionOfChristTrillerConverted.mp4';
//import CustomLoader from '../userpage/components/CustomLoader';

const VideoContainer = () => {
  /*const {bgVideo} = useSelector((state)=>state.admin);
  const [muted, setMuted] = React.useState('');
  const [videoEnded, setVideoEnded] = React.useState(false);
  const [videoMedia, setVideoMedia] = React.useState(bgVideo);
  const [replay, setReplay] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);
  const [videoStarted, setVideoStarted] = React.useState(false);
  //const [replay, setReplay] = React.useState(false);
  //const [videoStarted, setVideoStarted] = React.useState(false);

  function setVideoMediaHandler(act){
      if(act==='PLAY'){
        setVideoMedia(bgVideo);
      }else{
        setVideoMedia('')
      }
  }*/
  

  return (
    <div className='flex items-center justify-center flex-col mx-1 sm:mx-5 2xl:mr-0'
        >
        <div className='px-5 mt-5 mb-5 text-justify min-h-[200px] hidden'>
            {/*<div className='text-base  text-bold sm:text-xl md:text-2xl text-[#74efed] hover:text-slate-200'>
                <RandomBibleVerses bibleRefs={bibleRefsArr}  len={bibleRefsArr.length-1}/>
            </div>
            <p className='hidden text-white'>Video Playing:{playing? 'TRUE' : 'FALSE'} || 
                Video Ended: {videoEnded? 'TRUE' : 'FALSE'}
                || VideoMedia: {videoMedia? 'TRUE' : 'FALSE'}
            </p>*/}
        </div>
        <>
          <VideoPlayerAutoRaw
            //No auto play
           /*muted={muted} setMuted={setMuted}
           videoEnded={videoEnded} setVideoEnded={setVideoEnded}
           videoMedia={videoMedia} setVideoMedia={setVideoMediaHandler}
           videoStarted={videoStarted} setVideoStarted={setVideoStarted}
           replay={replay} setReplay={setReplay}
           playing={playing} setPlaying={setPlaying}*/
           />
        </>
    </div>
  )
}

export default VideoContainer;

