function getQueryAnchorFaithDeclaration(){ 
   const query = `
   *[_type == "combination" && title == 'AnchorAndFaithDeclaration'][0] {
    'ANCHOR': keyvaluepair[pair match 'ANCHOR'][0],
     'FAITHDECLARATION': keyvaluepair[pair match 'FAITHDECLARATION']| order(number asc),
     'IMAGEBASE':photo1.asset->url,
     'IMAGESUB':photo2.asset->url
  }
    `;
    return query
}


export {getQueryAnchorFaithDeclaration}