import React from 'react'
import { BsFillPersonFill } from 'react-icons/bs';
import HorizontalLine from '../../HorizontalLine';


const Main =({form, print, hideFamily})=>{
    const colStyle ={display:'grid', gridColumn:'auto',};

    const hideFamilyInfo = hideFamily? 'none' : 'block';
    
    
    return(
        <div style={{fontSize:'12px'}}>
            <h1 
                style={{fontWeight:'bold', display:hideFamilyInfo, color:'blue', paddingLeft:'20px', textDecorationLine:'underline'}}>
                    {form?.surname?.toUpperCase()}'S FAMILY
            </h1>
            <div style={{display:'flex', flexDirection:'row', justifyItems:'center', justifyContent:'center'}}>
                <div className='w-[100px] h-[100px] border border-gray-500 flex justify-center items-center rounded-full'>
                    {   form?.photo?
                        <img src={form?.photo || ""} alt="myPicture" className="w-[90px] h-[90px] rounded-full"/> 
                        :<BsFillPersonFill size={'90%'} color="silver"/>}
                </div>
            </div>
            <div style={{zInex:5}}>
                <div style={colStyle}>
                    <Filed label="Title" name={form.title} print={print}/>
                    <Filed label="Surname" name={form.surname} print={print}/>
                    <Filed label="Firstname" name={form.firstname} print={print}/>
                    <Filed label="Othernames" name={form.othernames} print={print}/>
                    <Filed label="Gender" name={form.genderStat} print={print}/>
                    <Filed label="Date of Birth" name={getDateStrings(form.dob)} print={print}/>
                    <Filed label="Phone number" name={form.phoneNo} print={print}/>
                    <Filed label="Marital Status" name={form.matStat} print={print}/>
                </div>
                <Filed label="Email" name={form.email} print={print}/>
                <Filed label="Residential Address" name={form.addressRes} print={print}/>
                <div style={colStyle}>
                    <Filed label="Baptismal Status" name={form.batStat} print={print}/>
                    <Filed label="Occupation" name={form.occupation} print={print}/>
                    <Filed label="Membership Status" name={form.memberStat} print={print}/>
                    <Filed label="School" name={form.school} print={print}/>
                </div>
                    <Filed label="Educational Qualification" name={form.instiStat} print={print}/>
                    <Filed label="Office Address" name={form.addressOff} print={print}/>
                <div cstyle={colStyle}> 
                    <Filed label="Wedding Anniversary" name={form.wedAnns} print={print}/>
                </div>
            </div>
        </div>
    );
}


const Spouse =({form, childComp, print})=>{
    return(
        <div >
            <div>
                <HorizontalLine widths={100} margTop={40} margBot={10}/>
                <h1 
                    style={{fontWeight:'bold', color:'blue', fontSize:'14px', paddingLeft:'20px'}}
                >
                    Spouse Information</h1>
                <Main form={form} hideFamily/>
            </div>
            <div>
                <h1 style={{fontWeight:'bold', color:'blue', fontSize:'14px', paddingLeft:'20px'}}>
                    Children Information</h1>
                <HorizontalLine widths={100} margTop={15} margBot={20}/>
            </div>
        </div>
    );
}

const Child =({form, index, print})=>{
    
    return(
        <div >
            <div style={{zIndex:5, fontSize:'12px'}}>
                {index === 1? "" : <HorizontalLine widths={100} margTop={40} margBot={10}/>}
                <h1 style={{fontSize:'14px', paddingLeft:'20px'}}>{index}.</h1>
                <div
                    style={{display:'grid', gridColumn:'auto'}}>
                    <div>
                        <Filed label="Title" name={form.title} print={print}/>
                        <Filed label="Surname" name={form.surname} print={print}/>
                    </div>
                    <div style={{display:'flex', justifyContent:'center'}}>
                        <div className='w-[100px] h-[100px] border border-gray-500 flex justify-center items-center rounded-full'>
                            {   form?.photo?
                                <img src={form?.photo || ""} alt="myPicture" className="w-[90px] h-[90px] rounded-full"/> 
                                :<BsFillPersonFill size={'90%'} color="silver"/>}
                        </div>
                    </div>
                    <div>
                        <Filed label="Title" name={form.title} print={print}/>
                        <Filed label="Surname" name={form.surname} print={print}/>
                    </div>
                    <Filed label="Firstname" name={form.firstname} print={print}/>
                    <Filed label="Othernames" name={form.othernames} print={print}/>
                    <Filed label="Gender" name={form.genderStat} print={print}/>
                    <Filed label="Date of Birth" name={getDateStrings(form.dob)} print={print}/>
                </div>
                <Filed label="Email" name={form.email} print={print}/>
                <Filed label="Residential Address" name={form.addressRes} print={print}/>
                <div style={{display:'grid', gridColumn:'auto'}}>
                    <Filed label="Phone number" name={form.phoneNo} print={print}/>
                    <Filed label="Occupation" name={form.occupation} print={print}/>
                    <Filed label="Membership Status" name={form.memberStat} print={print}/>
                    <Filed label="School" name={form.school} print={print}/>
                    
                </div>
                <Filed label="Educational Qualification" name={form.instiStat} print={print}/>
                <div style={{display:'grid', gridColumn:'auto'}}>
                    <Filed label="Baptismal Status" name={form.batStat} print={print}/>
                    <Filed label="Marital Status" name={form.matStat} print={print}/>
                </div>
            </div>
        </div>
    );
}


const Filed =({label, name, print})=>{
    //text-xs sm:text-[2vw] mdc:text-lg
    const defLabStyle = `pr-2 px-3 text-xs sm:text-sm mdc:text-base 
    sm:whitespace-nowrap font-bold pt-[3px] text-[maroon]
        `;
    const printLabStyle = `pr-2 text-2xl w-fit font-bold pt-[3px] text-[maroon]
        whitespace-nowrap
        `;
    const defNameStyle = `bg-[#e5e5e5] text-xs sm:text-sm mdc:text-base w-[100%] pb-[2px] px-3 
        border-b-2 border-dashed border-black`;

    const printNameStyle = `bg-[#e5e5e5] pb-[2px] 
        border-b-2 border-dashed border-black w-full`;


    return(
        <div
         style={{marginLeft:'12px', marginRight:'12px', display:'flex', flexDirection:'row', margin:'8px'}}>
            <p
                style={{paddingRight:'8px', fontSize:'12px', whiteSpace:'nowrap', fontWeight:'bold', color:'maroon', }}>
                {label}:
            </p>
            <p 
                style={{backgroundColor:'#e5e5e5',borderBottomWidth:"1px",paddingBottom:'5px', borderColor:'gray', borderStyle:'dashed', width:'100%'}}>
                {name}
            </p>
        </div>
    );
}

const styles ={
    image:{width:'60px', height:'60px', border:'2px solid gray', 
    justifyContent:'center', borderRadius:'50%'},
}

function getDateStrings(date){
   let res =  new Date(`${date}`).toDateString().replace('1900', ', ****');
   return res
}
export {Main, Spouse, Child}