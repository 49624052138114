

function getHeaders(obj, fldsArr,){
    
    const headers = fldsArr.map((item)=>{    
        return obj[item].header
    });
    return headers
};

export {getHeaders}