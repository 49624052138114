import React, { useState, } from 'react';
import { HiMenu } from 'react-icons/hi';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Link,} from 'react-router-dom';
import logo from '../../assets/gofamintLogo.png';
import {Navbar } from '../../components';
import UserLoginprofile from './UserLoginProfile';
import { useLocation } from 'react-router-dom';
import LoginForm from '../login/Login';
import { getPathName } from './navs';



const Header = ({hide, set}) => {
  const location = useLocation();
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [user] = useState({});
  
  return (
    <div className={`${hide && 'hidden' } flex flex-row relative  bg-[rgb(1,1,60)] shadow-2xl w-full`}>
          <div className="p-2 w-full flex flex-row justify-between items-center shadow-md">
            <HiMenu fontSize={30} className="cursor-pointer md:hidden ml-3 text-white" onClick={() => setToggleSidebar(true)} />
            <Link to="/">
              <img src={logo} alt="logo" className="w-10 xsmc:w-16 smc:w-22 md:w-28" />
            </Link>
            <h2 className="flex md:hidden text-white">
              
              {getPathName(location.pathname)}
            </h2>
          <div className='flex flex-row justify-center items-center'>
            <div className="hidden md:flex justify-end">
              <Navbar user={user && user} />
            </div>
            <UserLoginprofile/>
          </div>
          </div>
          {toggleSidebar && ( //Sidebar
          <div className="fixed w-full bg-blackOverlay h-screen z-50 top-0" onClick={() => setToggleSidebar(false)}>
            <div className="fixed w-4/5 bg-white h-screen overflow-y-auto shadow-md z-10 animate-slide-in">
              <div className="absolute w-full flex justify-end items-center p-2">
                <AiFillCloseCircle fontSize={42} color="ivory" className="cursor-pointer" onClick={() => setToggleSidebar(false)} />
              </div>
                <Navbar closeToggle={setToggleSidebar} user={user && user} />
            </div>
          </div>
          )}
          <LoginForm set={set}/>
    </div>
  );
};

export default Header;
