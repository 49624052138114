import React from 'react';
import { Link,} from 'react-router-dom';
import logo from '../../assets/gofamintLogo.png';
import NavsLink from './NavsLink';
import { navName, pathNames } from './navs';
import { useSelector } from 'react-redux';
//import { categories } from '../utils/data';

const isNotActiveStyle = 'flex items-center px-2 mdc:px-4 gap-3 text-gray-500 md:text-gray-400 hover:text-black md:hover:text-white transition-all duration-200 ease-in-out hover:border-b-4 border-red-500 capitalize';
const isActiveStyle = 'md:text-white flex items-center px-2 mdc:px-4 gap-3 font-extrabold   transition-all duration-50 ease-in-out border-b-4 border-[#f55b23] capitalize';



const Navbar = ({ closeToggle}) => {
  const handleCloseSidebar = () => {
    if (closeToggle) closeToggle(false);
  };
  const {loginForm } = useSelector((state)=>state.login);
  
  //SecurePage- 2 of 3
  //const navNames = navName;
  const navNames = loginForm.isOnline? navName : navName.filter((nav)=>nav !== "User");

  return (
    <div className="flex flex-col justify-between h-full 
        overflow-y-scroll min-w-210 hide-scrollbar mr-0">
      <div className="flex flex-col  md:flex-row">
        <div className=" mb-5  flex justify-center bg-blue-900 md:hidden">
            <Link
              to="/"
              className="flex px-5 gap-2 my-6  pt-1 w-190 items-center"
              onClick={handleCloseSidebar}
              >
              <img src={logo} alt="logo" className="w-full" />
            </Link>
        </div>
        {navNames.map((nav, i)=>{
            return(
              <NavsLink
                key={`${i}+nav`}
                name={nav} 
                handleCloseSidebar={handleCloseSidebar} 
                isActiveStyle={isActiveStyle} 
                isNotActiveStyle={isNotActiveStyle}
                linkTo={pathNames[i]}
                index={i}/>
            );
        })}
      </div>
    </div>
  );
};

export default Navbar;
