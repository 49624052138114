import React from 'react';
import HorizontalLine from '../../../../HorizontalLine';
import { getShortDate } from '../../../../dates';

const Index = () => {
    const dateArr = getShortDate();
    const genDate = `${dateArr[0]} ${dateArr[1]} ${dateArr[2]} ${dateArr[3]}`; 
    const genTime = new Date().toTimeString();
    //console.log([getShortDateFull(), nn.toTimeString()])
  return (
     <div className="mt-[60px] ">
        <HorizontalLine widths={100} bColor="gray"/>
        <p className='flex justify-end text-[navy]'>Report generated on {genDate} @{genTime}</p>
        <footer className="text-center text-white bg-[#aaa] mt-3">
            <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                
            </div>

            <div className="text-center text-xs sm:text-base p-4 py-6 relative" style={{backgroundColor:'rgba(0, 0, 0, 0.2)'}}>
                <p className='absolute top-0 right-2 text-red-700'>This is a confidential Information</p>
                <p>For further enquiry, contact the Admin or the Account Department</p>
                <p className='pt-3'>
                  © 2023 GOFAMINT PS Ogba Account Department
                </p>
            </div>
        </footer>
    </div>
  )
}

export default Index