import perfectLove1 from './perfectLove2022-1.jpg';
import perfectLove2 from './perfectLove2022-2.jpg';
import perfectLove3 from './perfectLove2022-3.jpg';
import perfectLove4 from './perfectLove2022-4.jpg';
import perfectLove5 from './perfectLove2022-5.jpg';
import perfectLove6 from './perfectLove2022-6.jpg';
import perfectLove7 from './perfectLove2022-7.jpg';
import perfectLove8 from './perfectLove2022-8.jpg';
import perfectLove9 from './perfectLove2022-9.jpg';
import perfectLove10 from './perfectLove2022-10.jpg';
import perfectLove11 from './perfectLove2022-11.jpg';
import perfectLove12 from './perfectLove2022-12.jpg';
import perfectLove13 from './perfectLove2022-13.jpg';
import perfectLove14 from './perfectLove2022-14.jpg';
import perfectLove15 from './perfectLove2022-15.jpg';
import perfectLove16 from './perfectLove2022-16.jpg';
import perfectLove17 from './perfectLove2022-17.jpg';
import perfectLove18 from './perfectLove2022-18.jpg';



const perfectLove2022 = [
      {sn:1, src:perfectLove1,title:'Special Youth Service- Perfect Love 2022', desc:'Special Youth Service- Perfect Love 2022', 
            bCol:'tomato', date:'2022-03-20'},
      {sn:2, src:perfectLove2,},{sn:3, src:perfectLove3,},{sn:4, src:perfectLove4,},
      {sn:5, src:perfectLove5,},{sn:6, src:perfectLove6,},{sn:7, src:perfectLove7,},
      {sn:8, src:perfectLove8,},{sn:9, src:perfectLove9,},{sn:10, src:perfectLove10,},
      {sn:11, src:perfectLove11,},{sn:12, src:perfectLove12,},{sn:13, src:perfectLove13,},
      {sn:14, src:perfectLove14,},{sn:15, src:perfectLove15,},{sn:16, src:perfectLove16,},
      {sn:17, src:perfectLove17,},{sn:18, src:perfectLove18,},
      ];
    
export default perfectLove2022