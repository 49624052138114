import React, {useState, useEffect} from 'react';
import { TextField, ViewField, ViewSelection } from './Components';


const OnlineOffManualFiling = ({opts,allPayersData, payerDataDisplay, setPayerDataDisplay,
        }) => {
    
    const [familyIDUser, setFamilyIDUser] = useState('');
    const [totalAmount, setTotalAmount] = useState('');
    const [payerData, setPayerData] = useState({name:'', familyID:'', amount:''});

    const [selectedName, setSelectedName] = useState('');
    const [fillAllInfo, setFillAllInfo] = useState(false);
    const [errorMsg, setErrorMsg] = useState({msg:'This Payer has already been entered!', show:false});
    
    function duplicateCheck(name){
        const isDuplicate = payerDataDisplay.filter(res=> res.name === name);
        return isDuplicate.length>0
    }
    const onChangeHandler=(e, type)=>{
        const payerExist = duplicateCheck(e);
        if(payerExist){
          setErrorMsg({show:true, msg:'This Payer has already been entered!'})
        }else{
          if(type==='AMOUNT'){
            setPayerData({...payerData, amount:e})
          }else{
            const familyID = allPayersData.familyID[e];
            setPayerData({...payerData, name:e, familyID});
          }
          setFillAllInfo(false);
          setErrorMsg({show:false, msg:''})
        }
    }

    const onSubmitHandler=(e)=>{
        e.preventDefault();
        if(payerData.name ==="" || payerData.familyID ==="" || payerData.amount ===""){ 
            setFillAllInfo(true);
            return}
          setPayerDataDisplay([...payerDataDisplay, payerData]);
          setPayerData({name:'', familyID:'', amount:''});
    }
    function deleteHandler(indx){
      const payerDataNew = payerDataDisplay.filter((_, i)=> i !== indx);
      setPayerDataDisplay(payerDataNew);
    }
    useEffect(()=>{
       if(selectedName){
        setFamilyIDUser(allPayersData.familyID[selectedName])
       }
    },[selectedName]);
    
    function sumUpTotal(arr){
        const total = arr.reduce((res,item)=>{
          return res+parseInt(item.amount)
        },0);
        return total
    }
    useEffect(()=>{
      if(payerDataDisplay.length){
        const res =sumUpTotal(payerDataDisplay);
        setTotalAmount(res);
      }else{
        setTotalAmount(0);
      }
   },[payerDataDisplay]);

    return (
    <div className=''>
            <p className='underline font-bold text-red-900 mb-3'>Publish online offering - Manually</p>
            <p className={`mb-2 `}>Payers' Record to be uploaded</p>
            <div className='mb-5 overflow-x-auto'>
              <Record sn="SN" name="Name" familyID="FamilyID" amount="Amount"
                contStyle='bg-teal-500 text-white w-full' hideDel/>
              {payerDataDisplay?.map((item, i)=>{
                return(
                  <Record sn={i+1}  key={`${i}+key`}
                      name={item.name} 
                      familyID={item.familyID} 
                      amount={item.amount}
                      contStyle='bg-teal-100 w-full'
                      index={i}
                      deleteHandler={deleteHandler}/>
                );
              })}
              {payerDataDisplay.length? 
                <Record  name={'TOTAL'} 
                  familyID={''} 
                  amount={totalAmount}
                  contStyle='bg-teal-200 w-full'
                  hideDel
                  //index={i}
                  //deleteHandler={deleteHandler}
                  />: <></>}
            </div>
            <p className={`text-red-500 ${errorMsg.show? '' :'hidden'}`}>{errorMsg.msg}</p>
            {
              <form onSubmit={onSubmitHandler}>
                <div className='gap-2 grid grid-cols-1 mdc:grid-cols-2 lg:grid-cols-3'>
                    <ViewSelection title="Name" setSelected={onChangeHandler} opts={opts}
                      />
                    <ViewField title="FamilyID" pholder="FamilyID" value={payerData.familyID}/>
                    <TextField title="Amount" pholder="Enter amount" required type='number'
                        value={payerData.amount} onChange={e=>onChangeHandler(e.target.value, 'AMOUNT')}/>      
                </div>
                <p className={`text-red-600 ${fillAllInfo? '':'hidden'}`}>Please fill all the fields!</p>
                <input
                    type='submit' value="Enter"
                    className={`my-3 bg-blue-800 hover:bg-sky-500 accent-indigo-500 text-white py-2 px-10 border border-gray-500 border-solid`}/>
              </form>
             }
            
    </div>
  )
}

export default OnlineOffManualFiling;


const Record =({index, hideDel, sn, name, familyID, amount, contStyle, deleteHandler})=>{

  const cellStyle = `px-2 flex border border-solid border-x-white text-sm smc:text-base`;
  return(
    <div className={`flex w-full py-1  flex-row border border-solid border-gray-500 ${contStyle}`}>
      <p className={` w-[15%] ${cellStyle}`}>{sn}</p>
      <p className={` overflow-x-hidden  w-[25%] ${cellStyle}`}>{name}</p>
      <p className={` overflow-x-hidden w-[40%] ${cellStyle}`}>{familyID}</p>
      <p className={`min-w-[70px] overflow-x-hidden w-[20%] ${cellStyle} justify-between`}>
        <span>{amount}</span>
        <span className={`text-[red] active:text-[silver] cursor-pointer ${hideDel? 'hidden' : ''}`}
          onClick={()=>deleteHandler(index)}>Del</span>
      </p>
    </div>
  );
}