import {txtFieldsKeys } from "./textFieldsMain";


const txtFields = {
   [txtFieldsKeys[0]]:{name:"title", title:'Title', optArr:["Select", "District Pastor", "Resident Pastor", "Asst. Resident Pastor", "Pastor", "Elder", "Deacon", "Deaconess", "Brother", "Sister"], type:'opts'},
   [txtFieldsKeys[1]]:{name:"surname", title:'Surname', pholder:"Enter surname", type:"text"},
   [txtFieldsKeys[2]]:{name:"firstname", title:'Firstname', pholder:"Enter firstname", type:"text"},
   [txtFieldsKeys[3]]:{name:"othernames", title:'Othernames', pholder:"Enter othernames", type:"text"},
   [txtFieldsKeys[4]]:{name:"addressRes", title:'Residential Address', pholder:"Enter residential address", type:"text"},
   [txtFieldsKeys[5]]:{name:"dob", title:'Date of birth: ', type:"date", subTitle:"mm/dd/yyy"},
   [txtFieldsKeys[6]]:{name:"genderStat", title:'Gender', opt1:"Male", opt2: "Female", type:'radio', optArr:['Select','Male', "Female"]},
   [txtFieldsKeys[7]]:{name:"ageGroup", title:'Age Group', optArr:["Select", "Baby (0-2yrs)","Child (3-16yrs)", "Young Adult (17-30yrs)", "Middle-aged Adult (31-45yrs)", "Old Adult (above 45yrs)"], type:'opts'},
   [txtFieldsKeys[8]]:{name:"email", title:'Email', pholder:"Enter email", type:"email"},
   [txtFieldsKeys[9]]:{name:"phoneNo", title:'Phone Number', pholder:"Enter phone number", type:"number"},
   [txtFieldsKeys[10]]:{name:"occupation", title:'Occupation', pholder:"Enter occupation or profession", type:"text"},
   [txtFieldsKeys[11]]:{name:"addressOff", title:'Office Address', pholder:"Enter office address", type:"text"},
   [txtFieldsKeys[12]]:{name:"school", title:'School', pholder:"Enter your school", type:"text"},
   [txtFieldsKeys[13]]:{name:"instiStat", title:'Qualification', type:"opts",
        optArr:["Select your highest qualification ", "Professor", "Phd", "Masters", "BSc", "HND", "OND", "NCE", "SSCE", "Primary School", "Others"]
    },
   [txtFieldsKeys[14]]: {name:"memberStat", title:"Membership Status", optArr:["Select", "Minister", "Worker", "Member", "Non-member (Not a member PS Ogba)"], type:'opts'},
   [txtFieldsKeys[15]]:{name:"matStat", title:'Marital Status', type:"radio",opt1:"Single", opt2:"Married", optArr:['Single', "Married"]},
   [txtFieldsKeys[16]]:{name:"wedAnn", title:'Wedding Anniversary: ', type:"date", subTitle:"mm/dd/yyy"},
   [txtFieldsKeys[17]]:{name:"batStat", title:'Baptismal Status', type:"radio",opt1:"Baptised", opt2:"Not baptised", optArr:['Select','Baptised', "Not baptised"]},
   [txtFieldsKeys[18]]:{name:"photo", title:'Photo upload', subTitle:"Max size-100kb", pholder:"Upload your photo: ", type:"file"},
};


const titlesAbbrev = {
   "District Pastor": 'Pst.', 
   "Resident Pastor": 'Pst.', 
   "Asst. Resident Pastor": 'Pst.', 
   "Pastor": 'Pst', 
   "Elder": 'Eld.', 
   "Deacon":'Dn.', 
   "Deaconess":'Dcn.', 
   "Brother":'Bro.', 
   "Sister":'Sis.',
};


export {titlesAbbrev};

export default txtFields;