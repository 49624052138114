import React from 'react';
import { disLoginUser } from '../../../context/reducers/login';
import { useSelector, useDispatch } from 'react-redux';
import { client } from '../../../client';



const AboutHomeDisplayNonMember = ({user, }) => {
  const [value, setValue] = React.useState('');
  const dispatch = useDispatch();
  const {loginUser} = useSelector((state)=>state.login);
  const [info, setInfo] = React.useState({msg:"", show:false, correctCode:false});

  async function fetchGuestCode() {
    const query = guestQuery;
    const response = await client.fetch(query);
    if(response.length>0){
      const guestCodeRev = response[0].keyvaluepair.filter(item => item.pair === "Guest_Code")[0];
      if(guestCodeRev.value=== value){
        dispatch(disLoginUser({...loginUser, guest:true}));
        setInfo({...info, show:true, msg:"Congratulation, access granted!", correctCode:true});
      }else{
        setInfo({...info, show:true, msg:"Wrong pass code!", correctCode:false});
        dispatch(disLoginUser({...loginUser, guest:false}));
      }
    }
  }
  function onChangeHandler(e){
    setValue(e.target.value);
    setInfo({...info, show:false, msg:"", correctCode:false});
  }
  function onClickHandler(){
    setInfo({...info, show:true, msg:"Verifying guest code, please wait....",});
    fetchGuestCode();
  }
  //console.log(loginUser)

  return (
    <div className='p-3 m-2 rounded-sm bg-gradient-to-b from-blue-100 to-white'>
      <p className='font-bold text-[goldenrod] pb-3'>
          Welcome, {user?.title} {user?.surname}!
      </p>
      <p className='font-bold text-blue-600 pb-2'>
        {notes.about}
      </p>
      <p className={`${info.correctCode? 'text-green-600' : 'text-red-600'}`}>
        {info.show && info.msg}</p>
      {loginUser.user === "GUEST" &&
        <p className='text-[mediumblue] font-bold py-1 my-3 w-fit border border-b-4 border-b-red-600'>Click on Account Tab</p>}
      <GuestLogin
        onClickHandler={onClickHandler}
        value={value}
        onChangeHandler={onChangeHandler}
      />
      <p className='font-bold text-red-600 mt-10'>
        {notes.member}
      </p>
    </div>

  )
}


export default AboutHomeDisplayNonMember


const GuestLogin =({onClickHandler, value, onChangeHandler})=>{

  return(
    <div>
        <input
          placeholder='Enter guest code'
          name="guestLogin"
          className='outline outline-sky-500 p-1'
          value={value}
          maxLength={7}
          onChange={onChangeHandler}
        />
        <p className='bg-sky-600 text-white py-2 px-8 rounded-md text-center 
          w-fit my-3 hover:bg-blue-800 active:bg-slate-400 cursor-pointer'
          onClick={onClickHandler}>
          Enter
        </p>
    </div>
    )
}

const notes = {
  about:"Contact Admin if you need any information or provide a Guest Code to login as a guest.",
  member:"Logout and relogin as a Member of PS Ogba if you are one.",
};


const guestQuery = `
  *[ _type == "controls" && title == "AdminPass"]{
    keyvaluepair
  }
`;

