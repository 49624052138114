import sortRecords from "./sortRecord";


function validateFormInputs(formInputs, initMatStat){
    const len = formInputs.length;
    const parentsForm = formInputs.filter(item=>item.famStat==='parent');
    const parent1Form = parentsForm[0];     
    const parent2Form = parentsForm[1];
    let errorMsgs = {msg:'', error:false};

    if(len <=1 && formInputs[0].matStat==="Married" && initMatStat==="Married"){
        //Married without spouse form
        return {msg: errorsInfo.error3, error:true};
    }
    if((parent1Form || parent2Form) && len <= 1){
        //Married but One Form Test. Spouse form must be filled
        return {msg: errorsInfo.error1, error:true};
    }
    if(parent2Form && (parent1Form.genderStat === parent2Form.genderStat)){
        //Parents Gender Test
        return {msg: errorsInfo.error2, error:true};
           
    }
    for(let i=0; i<formInputs.length; i++){
        const indvForm = formInputs[i];
        const title = indvForm.title; const gen = indvForm.genderStat;
        if(gen === "Male" && !malesTitle.includes(title)){
             return {msg: `There is a missmatch of title and gender for ${indvForm.firstname}!`, error:true,};
        }else if(gen === "Female" && !femalesTitle.includes(title)){
          return {msg: `There is a missmatch of title and gender for ${indvForm.firstname}!`, error:true,};
        }
    };
    const  {father, mother, children} = sortRecords(formInputs);
    if(father.surname && mother.surname){
        const surnameError = father.surname !== mother.surname;
        const wedAnnError = father.wedAnn !== mother.wedAnn || father.wedAnn==="" || mother.wedAnn ==="";
        const matStatError = father.matStat !== mother.matStat || father.matStat==="" || mother.matStat === "";

    switch (true) {
        case surnameError=== true:
          return {msg: "Your surname shouldn't be different from your spouse's.", error:true};
        case wedAnnError === true:
          return {msg: "Your wedding anniversary date shouldn't be different or nil!", error:true};
        case matStatError === true:
            return {msg: "Marital status shouldn't be diiferent or nil!", error:true};
        default:
            return  {msg:"", error:false};
      }
    }
    return  {msg:"", error:false};
}


export default validateFormInputs;

const errorsInfo ={
    error1:"You are to either Add your Spouse, Child or both Forms or set your Marital Status to 'Single'.",
    error2:"Both Parents can not be of the same gender!",
    error3:"Your are married!. Kindly Add your Spouse, Child or both Forms and fill in order to proceed.",
}

const malesTitle = [
    "District Pastor", 
   "Resident Pastor", 
   "Asst. Resident Pastor", 
   "Pastor", 
   "Elder", 
   "Deacon",  
   "Brother", 
];

const femalesTitle = [ 
   "Pastor", 
   "Deaconess",  
   "Sister",
];