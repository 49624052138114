
function getLocalStorageData(value){
    let res1 = [];
    let res2 = [];
    
    const keys = Object.keys(value);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if(key !== 'decoded'){
            const val = value[key] === true? 'true' : value[key] === false? 'false' : value[key];
            res1.push({key:key, value:val});
        }else{
            const keysDecode = Object.keys(value.decoded);
            for (let j = 0; j < keysDecode.length; j++) {
                const key = keysDecode[j];
                const val = value.decoded[key] === true? 'true' : value.decoded[key] === false? 'false' : value.decoded[key];
                res2.push({key:key, value: val});
            }
        }
      }
      return {res1, res2};
}




export {getLocalStorageData}