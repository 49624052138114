import { jsPDF } from "jspdf";
import React, { useRef, useState } from "react";
import ReportTemplate from "./ReportTemplate";
//https://dev.to/m3cv1no/how-to-use-jspdf-in-react-and-add-styling-to-it-36jc

const GeneratePdf = ({btnName, btnStyle, prepareReportDataCall}) => {
  const templateRef = useRef(null);
  const [showTemplate, setShowTemplate] = useState(false);
  const [reportData] = useState([]);
  //const pdfTitle = `${reportData[0]? reportData[0]?.surname : 'Reg'}s Form`;

  const handleGeneratePdf = () => {
    setShowTemplate(true);
    const doc = new jsPDF({
      format: "a4",
      unit: "px"
    });

    // Adding the fonts
    //doc.setFont("Anton-Regular", "normal");

    doc.html(templateRef.current, {
      async callback(doc) {
        // save the document as a PDF with name of Memes
         doc.save('Membership Form');
      }
    });
    setTimeout(()=>setShowTemplate(false), 1000)
  };

 function prepareReportData(){
    prepareReportDataCall()
      //setReportData(res);
      //console.log(res);
      handleGeneratePdf();
  }

  return (
    <div
    >
      <button
        className={btnStyle}
        onClick={prepareReportData}
      >
        {btnName || 'Generate Pdf'}
      </button>
      <div ref={templateRef} style={{position:'fixed', zIndex:-5,}}
        className={`${showTemplate? '' : 'hidden'}`}>
        <ReportTemplate hideNo={-10} reportData={reportData}
        />
      </div>
    </div>
  );
};

export default GeneratePdf;
