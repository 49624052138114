

function processPastorCornerMessages(msgArr){

    const allMsgsManualNew = msgArr.map((item,i)=>{
        
        return {...item, title:item.topic, body:item?.body?.split('\n\n'), msgID:item.messageID,
            likes:item.likes || [], comments: item?.comments || [], 
            details:{author: "Admin", date:item.date}}
    });
    
    const allMsgs = [...allMsgsManualNew];
    const sortedMsg = allMsgs.sort((a, b)=>b.sn - a.sn); //Descending Order
    
    return sortedMsg
}

function getQueryPstCorner(){

    const queryPstCorner = `
    *[_type == "pastorCorner"] | order(_createdAt desc) {

        _id,
        topic,
        messageID,
        sn,
        pray,
        bibleRef,
        body,
        date,
        likes[]{
        _key,
        postedBy->{
            _id,
            userName,
            image
        },
        },
        comments[]{
        _key,
        comment,
        datePosted,
        postedBy->{
            _id,
            userName,
            image
        },
        },
    }
    `;
    return queryPstCorner
}


export {processPastorCornerMessages, getQueryPstCorner}