import React from 'react';
//import { HiOutlineMail, HiOutlineMailOpen } from 'react-icons/hi';
import Collapsible from '../../../Collapsible';
import { client } from '../../../../client';
//import { v4 as uuidv4 } from 'uuid';
import { disMyInbox } from '../../../../context/reducers/userSub';
import { useDispatch } from 'react-redux';
import { Message } from './MyInboxComponents';
import { sortArrOfObjs } from '../../../../utils/sortData';
import useOnline from 'use-online';
import { BsWifiOff } from 'react-icons/bs';

const MyInbox = ({myInbox,}) => {
  const dispatch = useDispatch();
  const isOnline = useOnline();
  const unreadMsgsCount = myInbox.filter(item=> item.newMessage).length;
  
  //TODO: Check what is dispatch after mail open function wrt inbox querried with email
  function updateMyInboxMsg(updatedMsgs){
      //Filter and replace updated opened mail
      const mails = myInbox.filter((item)=> item._id !== updatedMsgs._id);
      const mailsNew = [...mails, updatedMsgs];
      dispatch(disMyInbox(mailsNew));
  }
  //console.log(myInbox);
  const openMailHandler =(mail,)=>{
    //Set newMessage to false on Click of an unopened mail
    if(mail.newMessage){ 
      client
      .patch(mail._id)
      .set({"newMessage":false })
      .commit()
      .then((res) => {
        //Get the open email from the inbox mail to extract certain things
        const openEmail = myInbox.filter((item)=> item._id === res._id);
        const messageArr = res.message?.split('\n');
        //Replace attachedFile and message keys. AttachedFile in res will return folder not url
        const updatedMsgs = {...res, attachedFile:openEmail.length? openEmail[0].attachedFile :'', 
            message:messageArr};
        updateMyInboxMsg(updatedMsgs);
      });}
  }
  
  const myInboxSorted = myInbox.length? sortArrOfObjs(myInbox, 'messagesCount', 'desc') : [];

  if(!isOnline){
    return (
      <div className='text-red-700 flex justify-center pt-[15px]'>
          <BsWifiOff size={30} color="red" className='mr-3 inline-block'/>
          Connection error!. Please, check your network.
      </div>
    );
  }
  return (
    <div className='p-2'>
       <div className={`pl-3 text-blue-700`}>
         <p>You have {unreadMsgsCount || 0} unread {unreadMsgsCount ===1? 'message.' : 'messages.'}</p>
       </div>
        {
          myInboxSorted?.map((item, i)=>{
            return(
              <div key={`${i}+key`} 
                className={ `text-gray-700 ${item?.newMessage? 'font-bold hover:text-blue-700' : ''} pt-1 mb-2
                rounded shadow-md hover:shadow-lg flex flex-row items-center justify-between`}
              onClick={()=>openMailHandler(item, i)}>
                {/*item.newMessage? mailUnread : mailOpen*/}
                
                <Messages item={item} i={i} message={item?.message}/>
              </div>
            );
          })
        }
    </div>
  )
}



const Messages =({item})=>{
 
  return(
    <Collapsible
        contStyle={`mx-1 sm:mx-3 mb-3 mx-1 w-full border-2 border-solid 
          ${item.newMessage? 'border-[mediumblue]' : 'border-[gray]'}`}
        titleStyle={`bg-slate-300 ${item.newMessage? 'text-[mediumblue]' : 'text-gray-700'}`}
        iconCol="fill-gray"
        iconType="plus"
        addedInfo={`${item.title} -${new Date(item.date).toUTCString()}`}
        showFileClip={`${item.attachedFile? 'TRUE' : 'FALSE'}`}
        hideDeleteIcon
        item={item}
        mailInbox

    >
      <Message item={item}/>
    </Collapsible>
  );
}
  

export default MyInbox;
