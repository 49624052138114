const offeringVigils = [
    {docID:1, date:'2023-01-27', program:'Victory Month General Vigil Offering', type:'OFFERING',dateDetails:'2023-01-27|Victory Month General Vigil Offering', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:1000}, 
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:500}, 
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:800}, 
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:200},  
            {name:'Amoos', familyID:'amoo.segun.mary', amount:200},
            {name:'Ariyibi', familyID:'ariyibi-oke.oluwaseyi.opeyemi', amount:300},                                
        ],  
    }, 
];



export default offeringVigils;