
import sortArray from '../../../utils/sortData';
import { groupArrOfObjToArr, groupArrOfObjsToArrOfObjs } from '../../../utils/groupArrElements';
import { titlesAbbrev } from '../assets/textFields';
import { getTodayMonth, getTodayDateStr} from '../../../utils/miscens';


function groupResult(arrs){
    let sortedArr =  sortArray(arrs, 'surname');
    const arrsOfFam = groupArrOfObjToArr(sortedArr, 'surname');
    const arrOfFamgrouped = groupArrOfObjsToArrOfObjs(arrsOfFam, 'famStat');
     
  
    const sortedArrGen = arrOfFamgrouped.reduce((res, item)=> { 
      const indv1 = item?.parents[0]?.genderStat?.toUpperCase();
      //const indv2 = item.parents[1].genderStat.toUpperCase();
      let title = 'Bro';
      if(item.parents.length>1){
        title = indv1==="MALE"? titlesAbbrev[item.parents[0].title] +' & '+titlesAbbrev[item.parents[1].title] :
        titlesAbbrev[item.parents[1].title] +' & '+titlesAbbrev[item.parents[0].title];
      }else{
        title = titlesAbbrev[item.parents[0].title];
      }
      const wedAnns = item.parents[0].wedAnns || item.parents[1].wedAnns;
      
      
      
      //{[indv1]: item.parents[0], [indv2]: item.parents[1], title:title} 
          //{titles:title, name:item.parents[0].surname, wedAnns:wedAnns}
  
      return [...res, `${title} ${item.parents[0].surname} - ${wedAnns}`]
    },[]);
    //console.log(sortedArrGen)
    return sortedArrGen
  };




  function celebrantsBirthday(birthdayCelebrants){
    
    //if(birthdayCelebrants.length){
        var birthdayCelebrantsNew = birthdayCelebrants.map((item)=>{ return {...item, ddmmyy: item.birthday+'-2022'}});
        var sorted = birthdayCelebrantsNew.sort((a,b)=>{ return new Date(a.ddmmyy) - new Date(b.ddmmyy)});

        var mnthCelebrants = sorted.reduce((res, item, i)=>{
          return [...res, `${titlesAbbrev[item.title]} ${item.firstname} ${item.surname} - ${item.birthday}`]
        },[]);

        const today = `${getTodayDateStr()}-${getTodayMonth()[1].slice(0,3)}`;
        //console.log(today)
        var todayCelebrants = mnthCelebrants.filter((value, i) => {
            return  value.includes(today);
        });
    //  }
    //console.log(mnthCelebrants, todayCelebrants)
    return {mnthCelebrants, todayCelebrants}
  }
  
  function celebrantsWeddingAnn(wedAnnsCelebrants){
    //console.log(wedAnnsCelebrants)
    const mnthCelebWA = groupResult(wedAnnsCelebrants);
    const today = `${getTodayDateStr()}-${getTodayMonth()[1].slice(0,3)}`;
    
    const todayCelebWA = mnthCelebWA.filter((value, i) => {
      return  value.includes(today)
    });
    return {mnthCelebWA, todayCelebWA}
}

  export {celebrantsBirthday, celebrantsWeddingAnn}