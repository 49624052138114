import React from 'react'
import CustomModal2 from '../CustomModal2';
import Report from './Report';

const Index = ({reportData, closeReport, hidePrintBtn}) => {
    //reportData => [{parents:[{},{}], children:[{},{},{}]},{parents:[{}], children:[{}]},...]
    //const {tableData} = useSelector((state)=>state.user);
    //console.log(reportData)
    return (
    <div>
        <CustomModal2
            show={closeReport}
            contStyle="flex justify-center"
            fullHeight>
            <div className='bg-[rgba(50,50,80,0.6)] border border-[blue] w-full'>
                <div className={`${hidePrintBtn? 'hidden' : ''} absolute right-5 text-white top-3 px-2 py-1 active:bg-white cursor-pointer hover:bg-red-400 hover:text-black`}
                    onClick={closeReport}
                    >
                    X
                </div>
                <Report reportData={reportData} print={false}/>  
            </div>
        </CustomModal2>
    </div>
  )
};


export default Index




const Filed =({label, name})=>{

    return(
        <div className='flex flex-row m-2 text-lg'>
            <p className='pr-2 px-3 font-bold text-[maroon] pt-[3px] whitespace-nowrap'>
                {label}:
            </p>
            <p className='bg-[#e5e5e5] w-full pb-[2px] px-3 border-b-2 border-dashed border-black'>
                {name}
            </p>
        </div>
    );
}

