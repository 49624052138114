import React from 'react';
import UserAvatar from './UserAvatar';
import { useSelector, useDispatch } from 'react-redux';
import { disLoginForm, disLogout } from '../../context/reducers/login';
import { googleLogout } from '@react-oauth/google';
//import useSessionStorage from '../../utils/hooks/useSessionStorage';
import useLocalStorageSessionStorage from '../../utils/hooks/useLocalStorageFromSessionStorage';
import { disLogoutUserSubReset } from '../../context/reducers/userSub';
import { disResetUserState } from '../../context/reducers/user';

const UserLoginProfile= ()=>{
  //const classLi ="dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-800 hover:bg-gray-200 cursor-pointer flex flex-row items-center";
  const dispatch = useDispatch();
  const {loginForm} = useSelector((state)=>state.login);
  const { remove } = useLocalStorageSessionStorage("psOgba_User", 0);
  
  function logoutHandler(){
    dispatch(disLoginForm({...loginForm, isOnline:false, adminPass:'', userType:'',
        checkedAdmin:false, picture:'', checkedMember:'nonmember', checkedPin:false,}));
    dispatch(disLogout());
    remove();
    googleLogout();
    dispatch(disLogoutUserSubReset());
    dispatch(disResetUserState());
  }
  function userActionHandler(){
    //window.location.reload();
    !loginForm.isOnline && dispatch(disLoginForm({...loginForm, show:true, checkedAdmin:false}))
  }

  return(
    <div className="flex justify-center group"
      >
        <div className="dropdown relative">
          <div
            className="
              dropdown-toggle"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={()=>userActionHandler()}
          >
            <UserAvatar loginForm={loginForm}/>
          </div>
          <div className={`${loginForm.isOnline && 'group-hover:flex'} ${loginForm.isOnline && 'group-hover:""'} bottom-[0.2rem] md:bottom-[-1.2rem] hidden bg-gray-100 left-[-4rem] md:left-[-3.5rem]  text-[red] absolute px-2 py-1 rounded-full cursor-pointer hover:bg-[red] hover:text-white`}
            onClick={logoutHandler}>
            Logout
          </div>
          <div className={`${!loginForm.isOnline && 'group-hover:flex'} ${!loginForm.isOnline && 'group-hover:""'} bottom-[0.2rem] md:bottom-[-1.2rem] hidden bg-gray-100 left-[-4rem] md:left-[-3.5rem]  text-[blue] absolute px-2 py-1 rounded-full cursor-pointer hover:bg-[mediumblue] hover:text-white`}
            onClick={()=>userActionHandler()}>
            Login
          </div>
          {/*<ul
            className="
              dropdown-menu
              min-w-max
              absolute
              
              bg-slate-100
              text-base
              z-[50]
              float-left
              py-2
              list-none
              text-left
              rounded-lg
              shadow-lg
              mt-[10px]
              m-0
              bg-clip-padding
              border-none
            "
            aria-labelledby="dropdownMenuButton1"
          >
            <li className= {classLi} onClick={()=>userActionHandler('MEMBER')}>
               <FaUser/> <span className='pl-2'>Member Login</span>
            </li>
            <li className= {classLi} onClick={()=>userActionHandler('ADMIN')}>
                <FaUserEdit/> <span className='pl-2'>Admin Login</span>
            </li>
            <li className= {classLi} onClick={()=>userActionHandler('LOGOUT')}>
                <BiLogOutCircle/> <span className='pl-2'>Logout</span>
            </li>
            
          </ul>*/}
        </div>
    </div>
  );
}


export default UserLoginProfile;  


