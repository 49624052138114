import React from 'react'
import { getTodayMonth,  } from '../../../../utils/miscens';
import { useSelector } from 'react-redux';
import useOnline from 'use-online';
import { BsWifiOff } from 'react-icons/bs';


const CelebrantsWeddingAnn = () => {
  const {celebWedAnns,} = useSelector((state)=>state.user);
  const isOnline = useOnline();
  const todayCelebrants = celebWedAnns.today; 
  const wdCelebrants = celebWedAnns.month;   

  if(!isOnline){
    return (
      <div className='text-red-700 flex justify-center pt-[15px]'>
          <BsWifiOff size={30} color="red" className='mr-3 inline-block'/>
          Connection error!. Please, check your network.
      </div>
    );
  }
    return (
    <div className='flex flex-col py-2 px-3 text-gray-700'>
        <p className='text-[blue] font-bold'>
            {getTodayMonth()[1]} Wedding Anniversary Celebrants
        </p>
        <div className='flex flex-col'>
          {
            wdCelebrants?.map((item,i)=>{
            const isToday = todayCelebrants.includes(item);
              return (
                <p key={`${i}+tab`}
                  className={`${isToday?'bg-yellow-200 text-black' : ''} pl-2 p-1 mb-1 rounded-md`}>
                  {i+1}. {item}
                </p>
              );
            })
          }
        </div>
    </div>
  )
}

export default CelebrantsWeddingAnn;