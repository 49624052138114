import React, { useState } from "react";
//import { FcNext, FcPrevious } from "react-icons/fc";
//import PlayPause from "../../assets/svg/PlayPause";
//import homeImg from "../../assets/home/_index";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

/*const images = [
  {image: homeImg[5] },
  //{image: homeImg[6] },
  {image: homeImg[20] },
  {image: homeImg[7] },
  //{image: homeImg[10] },
  //{image:homeImg[11] },
  {image: homeImg[19] },
  {image:homeImg[15] },
  {image:homeImg[16] },
  {image:homeImg[17] },
  {image:homeImg[18] },
];*/
const delay = 7000;

const Slideshow = () => {
    const navigate = useNavigate();
    const [index, setIndex] = useState(0);
    const timeoutRef = React.useRef(null);
    const [pause, setPause] = useState(false);
    const {homeImg } = useSelector((state)=>state.photoGalleries);

    const images = [
      {image: homeImg[5] },
      {image: homeImg[20] },
      {image: homeImg[7] },
      {image: homeImg[19] },
      {image:homeImg[15] },
      {image:homeImg[16] },
      {image:homeImg[17] },
      {image:homeImg[18] },
    ];

    function resetTimeout() {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
    function togglePulseSlide(){
        setPause(!pause);
    }
    function changeSlide(act){
        if(act==="PREV"){
            setIndex(prev=>prev===0? images.length-1 : prev -1);
        }
        if(act==="NEXT"){
            setIndex(prev=>prev=== images.length-1? 0 : prev +1);
        }
        if(!isNaN(act)){
            setIndex(act);
        }
        setPause(false);
    }
    React.useEffect(() => {
      resetTimeout();
      if(pause){
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          } 
      }else{
        timeoutRef.current = setTimeout(
            () =>
              setIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
              ),
            delay
          );
      }
  
      return () => {
        resetTimeout();
      };
    }, [index, pause]);

    if(!images){
      return <div>Loading page, please wait....</div>
    }
    return (
      <div className="overflow-hidden bg-fuchsia-400 w-full pb-5"
        style={{clipPath:"polygon(0 0,100% 0, 100% 90%, 50% 100%, 0 90%)"}}>
        <div className="overflow-hidden w-full relative"
          style={{clipPath:"polygon(0 0,100% 0, 100% 90%, 50% 100%, 0 90%)"}}>
          <div
            className="transition duration-1000 ease-linear whitespace-nowrap"
            style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
          >
            {images.map((image, index) => (
              <img key={`${index}+slides`} 
                  className="inline-block w-full rounded-b-lg min-h-[270px] h-[43vw]" src={image.image} 
                  alt="birdimage"/>
            ))}
          </div>
          <div className="text-center absolute bottom-5 w-full flex flex-row justify-center">
            {images.map((_, idx) => (
              <div
                key={`${idx}+slidesDots`}
                className={`inline-block h-3 w-3 sm:h-4 sm:w-4 border-[#c4c4c4] border-solid border-2 rounded-full cursor-pointer m-2  ${index === idx ? "bg-[#6a0dad]" : "bg-transparent"}`}
                onClick={() => {
                  changeSlide(idx);
                }}
              ></div>
            ))}
          </div>
          <FloatText navigate={navigate}/>
          {/*<div className="absolute top-[50%] left-2 sm:left-10 text-gray-200 hover:text-[#6a0dad]" onClick={()=>changeSlide('PREV')}>
            <FcPrevious size={32} className="prevslide"/>
          </div>
          <div className="absolute top-[50%] right-2 sm:right-10 text-gray-200 hover:text-[#6a0dad]" onClick={()=>changeSlide('NEXT')}>
            <FcNext size={32} className="nextslide"/>
          </div>
          <div className="absolute bottom-10 sm:bottom-14 md:bottom-16 lg:bottom-32 left-6 sm:left-10 text-[#679ef6] cursor-pointer" 
            onClick={togglePulseSlide}>
            <PlayPause size={32} color={pause? '#acacac' : '#679ef6'}  
              act={pause? 'PLAY' : 'PAUSE'}/>
          </div>*/}
        </div>
      </div>
    );
  };


export default Slideshow;
//export {images}


const FloatText=({navigate})=>{

  return(
      <section className="hidden xxsmc:flex scale-1 lg:scale-125 carousel-indicators rounded-md 
          justify-center items-center flex-col absolute left-[-30px] top-[8%] smc:top-[25%] 
          p-5 mb-4 text-fuchsia-50 shadow-md z-10 animate-slide-in-text scale-125">
              <h2 className='text-[#f7be43] text-center font-bold my-2 sm:3 lg:5 text-sm sm:text-base lg:text-2xl '>Welcome to GOFAMINT PS Ogba</h2>
                <>
                  <h2 className='text-xs sm:text-sm lg:text-base'>Oh, send out Your light and Your truth!</h2>
                  <h2 className='text-xs sm:text-sm lg:text-base'> Let them lead me; Let them bring me to Your holy hill</h2>
                  <h2 className='text-xs sm:text-sm lg:text-base'>And to Your tabernacle. (Psalm 43:3 NKJV)</h2>
                </>
              <div className='bg-red-600 p-2 sm:3 lg:p-4 rounded-lg hover:bg-red-400 mt-2 text-xs sm:text-sm lg:text-base cursor-pointer'
                onClick={()=>navigate("/contactUs")}>Connect with us Today</div>
      </section>
  );
}

/*
className="hidden xxsmc:flex scale-1 lg:scale-125 carousel-indicators rounded-md 
          justify-center items-center flex-col absolute left-[-30px] top-[8%] smc:top-[25%] 
          p-5 mb-4 text-fuchsia-50 shadow-md z-10 animate-slide-in-text scale-125"

*/