import React, { useEffect, useState } from 'react';
//import DataFormOld from './PrevDataForm';
import Collapsible from '../../../Collapsible';
import { getFormField } from '../../assets/textFieldsMain';
import { useDispatch, useSelector } from 'react-redux';
import { disFreshRegistration, disSeltdTab } from '../../../../context/reducers/user';
import { tabsNav } from '../../headerTabs/headerSchema';
import { AddInfoButton } from '../../components/InputComponents';
import sortArray from '../../../../utils/sortData';
import { BsCloudUpload, BsExclamationTriangleFill } from 'react-icons/bs';
import { validateFieldsInputs, validateUploadedPhotos } from '../../modules/validateFieldsInputs';
import validateFormInputs from '../../modules/validFormInputs';
import useOnline from 'use-online';
//import processUpdateForms from '../../modules/_processUpdateForm';
import DataFormNew from './DataForm';
import { client } from '../../../../client';
import { fetchContents } from '../../about/fetchDatamodules';
import { assignFamilyID, assignOtherHiddenInfo, assignPersonalID } from '../../modules/assignSubInfo';
import { createStdMemberDoc } from '../../modules/sanityDocOperations/updateAndDeleteDoc';


const DataForms = () => {
    const dispatch = useDispatch();
    const isOnline = useOnline();
    var defaultForm = getFormField('txtFieldsEmptyArr'); //defaultForm ={...defaultForm, famStat:'user'} 

    const {formDataState, freshRegistration} = useSelector((state)=>state.user);
    const {user} = useSelector((state)=>state.login);
    const [formData, setFormData] = React.useState([defaultForm]);
    const [children, setChildren] = React.useState([]);
    const [showAddBtns, setShowAddBtns] = React.useState(false);
    const [famStats, setFamStats] = useState({parentsCount:0, childCount:0});
    const [isError, setIsError] = React.useState({msg:"", show:false});
    const [loadingMsg, setLoadingMsg] = React.useState({msg:"", loading:false});
    const [imageAsset, setImageAsset] = React.useState([]);
    const [loadingImage, setLoadingImage] =React.useState(false);
    const [uploadingImageFormIndex, setUploadingImageFormIndex] =React.useState("");
    const [startUpdateMsg, setStartUpdateMsg] = useState(updateMsg1);

    let onChangeHandler = (e, i) => {
        //console.log([e.target.type, e.target.value]);
        const {name, type, value} = e.target;
        //console.log([name,type, value])
        let newFormValues = [...formData];
        if(type==="file"){
            const selectedFile = e.target.files[0];
            if (!selectedFile) { return;}else{uploadImage(e, i)}
            //newFormValues[i]['photo'] = e.target.value;
        }else{ 
            newFormValues[i][name] = value;
        }
        //newFormValues = [...newFormValues, {...newFormValues[i], photo: '5555'}];
        setFormData(newFormValues);
        setIsError({show:false, msg:''});
        setLoadingMsg({loading:false, msg:''});
        
      };
      const uploadImage = (e,i) => {
        const selectedFile = e.target.files[0];
        const {name, size, type} = selectedFile;
        const valRes = validateUploadedPhotos(name);
        if(valRes.error){ 
            setIsError({show:true, msg:valRes.msg});
            e.target.value = null; return; 
        }else{
        setUploadingImageFormIndex(i);
        setLoadingImage(true);
          client.assets
            .upload('image', selectedFile, { contentType: type, filename: name })
            .then((imageAsset) => {
              setImageAsset(imageAsset); 
              formData[i].photo = imageAsset.url;
              formData[i].imageUrl  = imageAsset._id;  //To store image_ref: imageAsset._id. This only happen when there's a new current upload
              setLoadingImage(false);
            })
            .catch((error) => {
              console.log('Upload failed:', error.message);
            });
        }
      };

      let addForm = (info) => {
        const childNo = formData.filter((item)=>item.famStat.includes("child")).length+1;
        //const childNoArr = Array.from({ length: childNo }, (_, i) => ++i);
        const childCount = `child${parseInt(childNo)}`;
        
        const spouseForm ={
            info:'', famStat:'parent',family:'',
            surname: formData[0].surname, firstname:"", othernames:"", addressRes:formData[0].addressRes, 
            dob:"", genderStat:getField(formData[0]), email:"", phoneNo:"", occupation:"", 
            addressOff:"", school:"", instiStat:"", memberStat:"", batStat:"Baptised",
            wedAnn:formData[0].wedAnn, photo:"",
        };
        const childForm ={
            info:'', famStat:childCount,family:'',
            surname: formData[0].surname, firstname:"", othernames:"", addressRes:formData[0].addressRes,
            dob:"", genderStat:"Male", email:"", phoneNo:"", occupation:"", 
            addressOff:"", school:"", instiStat:"", memberStat:"", batStat:"Baptised",
            photo:"",
        };
        if(info === "CHILD"){
            const included = children.includes(childCount);
            const newChildren = [...children, childCount];
            if(!included){setChildren(newChildren);}
            setFormData([...formData, childForm]);
        }else{
            if(famStats.parentsCount <2){
                const newForm = [...formData, spouseForm];
                setFormData(newForm);
            }
        };
        //const childCount = formData.filter((item)=>item.famStat.includes('child')).length;     
      };
    
      let removeForm = (i, famStat) => {
        let forms = [...formData];
        if(famStat.includes('child')){
            const newChildren = children.filter((item)=>item !== famStat);
            //const childrenArrNew = Array.from({length:childCount}, (_,i)=> 'child'+(i+1));
            const resortedChildren = sortArray(newChildren, 'ARRAY');
            setChildren(resortedChildren);
        }

        forms.splice(i, 1);
        setFormData(forms);
        //console.log(newChildren, info);
        
      };
      
      //console.log(formData);
      function handleSubmit(e){
        e.preventDefault();
        //exitViewPage(); return;
        //console.log(formData);
        const validateFldsRes = validateFieldsInputs(formData); //Validate required fields
        const validateRes = validateFormInputs(formData, ''); //Validate input regularities
            if(validateFldsRes.error){
                setIsError({show:true, msg:validateFldsRes.msg});
                return 
            }else{setIsError({show:'', msg:''});}

            if(validateRes.error){
                setIsError({show:validateRes.error, msg:validateRes.msg});
                return //if(formValues[0].matStat==="Married"){setShowAddSCBtn(true)}else{setShowAddSCBtn(false)};
            }else{
                setIsError({show:'', msg:''});
                processUpdateForms(formData); //console.log(formData)
            };
     }
     const processUpdateForms=(forms)=>{
        fetchContents('MEMBERSCOUNT').then((countRes)=>{
            if(countRes.totalMembers){
                const formsWithPID = assignPersonalID(forms, countRes.totalMembers);
            
                const formsWithPIDFID = assignFamilyID(formsWithPID);
                
                const formsWithPIDFIDOInfo = assignOtherHiddenInfo(formsWithPIDFID, countRes.totalMembers, user);
                //console.log(formsWithPIDFIDOInfo);
                replaceMultipleFiles(formsWithPIDFIDOInfo);
            }
            //setTimeout(()=>)
        });
    }

    const replaceMultipleFiles = async (membersData) => {
        if(membersData.length >0){

            for (let index = 0; index < membersData.length; index++) {
            //const doc = membersData[index];
            const form = membersData[index];
            const stdDoc = createStdMemberDoc(form); //console.log(form);
            const doc = stdDoc;
            await client.createOrReplace(doc).then(res => {
                setIsError({show:'', msg:''});
                setStartUpdateMsg(updateMsg2);
                exitViewPage();
            }).catch(e => {
                console.log(e.error);
                setLoadingMsg({loading:true, msg:'File upload failed!', type:'FAILED'})
            });
            }
            setLoadingMsg({loading:false, msg:'', type:''})
        }else{
            console.log('No data to upload!')
        }
      }
      function exitViewPage(){
        dispatch(disFreshRegistration({...freshRegistration, start:false}))
    }

    function goToPage(page){
        dispatch(disSeltdTab(tabsNav[page]));
    }

    useEffect(()=>{
        const user = formData[0];
        if(user.matStat === "Married"){setShowAddBtns(true);
        }else {setShowAddBtns(false);}
        //Watch Spouse mistakenly added
        const parentsCount = formData.filter((item)=>item.famStat==="parent");
        const parentsCountLen = parentsCount.length
        if(parentsCountLen>2){
            const lastAdded = parentsCount.at(-1);
            console.log(lastAdded);
            const lastAddedIndex = formData.indexOf(lastAdded);
            formData.splice(lastAddedIndex, 1);
        }
    },[formData]);
    useEffect(()=>{
        const childCount = formData.filter((item)=>item.famStat.includes('child')).length;
        const parentsCount = formData.filter((item)=>item.famStat==="parent").length;
        setFamStats({childCount:parseInt(childCount) ||0, parentsCount:parseInt(parentsCount) ||0});
        setIsError({show:false, msg:""});
    },[formData]);

    useEffect(()=>{
        const user = formData[0];
        if(user.matStat === "Single"){
            const userNew = {...user, famStat:'user'};
            setFormData([userNew]);
        }else{
            const userNew = {...user, famStat:'parent'};
            setFormData([userNew]);
        }
    },[formData?.[0]?.matStat]);

    //4. SecurePage- Developer. freshRegistration.start? should be
    //i). freshRegistration.start? 'hidden' : 'flex' 
    //ii). freshRegistration.start? 'flex' : 'hidden'
    //iii). freshRegistration.start? 'flex' : 'hidden'

  return (
    <div className="w-full flex flex-col">
        <div className={`${freshRegistration.start? 'hidden' : 'flex'} w-full items-center flex-col justify-center mt-[100px] text-red-700 font-bold text-xl`}>
            <p className={`text-center px-4`}>{startUpdateMsg}</p>
            <p className='my-5 bg-blue-700 py-2 px-5 rounded-md hover:bg-blue-500 text-white cursor-pointer w-fit'
             onClick={()=>goToPage("RECORDABOUTHOME")}>
                Go to Home</p>
        </div>
        <div className={`w-full flex-col ${freshRegistration.start? 'flex' : 'hidden'} mx-[18px] text-[red] p-2`}>
            <p className='text-white bg-[red] w-fit p-1 text-lg font-bold underline'>Important Notice</p>
            <p className='pb-1'>{notes.note1}</p>
            <p className='underline'>{notes.note2}</p>
            <p className=''>{notes.note3}</p>
        </div>
        <form onSubmit={handleSubmit} 
            className={`w-full flex-col ${freshRegistration.start? 'flex' : 'hidden'}`}>
            {formData.map((form, formIndex)=>{
                const childNo = children.indexOf(form.famStat);
                const famStat = formData[formIndex].famStat;
                const user = famStat==="parent"? `${formIndex===0? 'User Information' :'Spouse Information'}` :
                                famStat.includes('child')? `Child ${childNo +1} Information` : "Member's Information";
                const userStat = famStat==="parent"? `${form.firstname}'s Info ${formIndex===0? '(Parent)':'(Spouse)'}` : 
                                famStat.includes('child')?  `${form.firstname}'s Info (Child)` : `${form.firstname}'s Info (User)`;
                const userInfo = form.firstname? userStat : user;

                return(
                    <div key={`${formIndex}+forms`} className=''>
                        <Collapsible
                            contStyle="mx-4 mb-1 pb-5"
                            titleStyle="bg-teal-700 text-white py-[10px]" 
                            removeForm={()=>removeForm(formIndex, form.famStat)}
                            //addedInfo={form.firstname? `${form.firstname}'s Information` : user}
                            addedInfo={userInfo}
                            iconCol='fill-[#fff]'
                            iconHoverCol='hover:fill-[#fff]'
                            noCollapse={formIndex===0? true : false}
                            hideDeleteIcon={formIndex===0? true : false}
                            >
                            <DataFormNew 
                                form={form} 
                                formIndex={formIndex}
                                onChangeHandler={e=>onChangeHandler(e, formIndex)}
                                //onChangeHandler={onChangeHandler} 
                                formData={formData}
                                handleSubmit={handleSubmit}
                                indivForm={formData[formIndex]}
                                uploadingImageFormIndex={uploadingImageFormIndex}
                                uploadingImage={loadingImage}
                                //info={info}
                                
                            />
                        </Collapsible>
                    </div>
                );
            })}
            <div className='pl-7'>
                <div className={`${isError.show? 'visible' : 'invisible'} flex flex-row pb-1 px-4 text-red-700`}>
                    <BsExclamationTriangleFill color="red" size={28}/> 
                    <p className="inline-block ml-3">{isError.msg}</p>
                </div>
                <div className={`${loadingMsg.loading? '' : 'hidden'} ${loadingMsg.type==="FAILED"? 'text-red-700' : 'text-green-700'} flex flex-row pb-1 px-4 `}>
                    <BsCloudUpload color="blue" size={28}/> 
                    <p className="inline-block ml-3">{loadingMsg.msg}</p>
                </div>
                <div className={`flex flex-row ${loadingImage? '':'hidden'}`}>
                    <BsCloudUpload color="blue" size={28}/>
                    <p className='ml-3'>Uploading image, please, wait...</p>
                </div>
            </div>
            <div className={`${showAddBtns? '':'hidden'} mt-4 ml-7`}>
                {/*<p>Parents-{famStats.parentsCount}|| Child-{famStats.childCount} ||Children(famStats)-{children.length}</p>*/}
                    <AddInfoButton 
                        onClickAddInfo={addForm} 
                        title={'Add Form'}
                        addSpouse="Spouse"
                        addChild="Child"
                        deActivateSpouseBtn={famStats.parentsCount >=2? true :false}
                        //spouseAdded={spouseAdded}
                        />
            </div>  
            <button className='hover:bg-[green] ml-7 mb-10 w-fit active:bg-gray-600 bg-blue-800 text-white py-2 px-10 m-3 text-center'
                type="submit">Register</button>
          </form>
    </div>
  )
}


function getField(ev){
    let res = "";
    if(ev === "Male"){res = "Female";}
    if(ev === "Female"){res = "Male"};
    return res;
}
export default DataForms;


const notes={
    note1:`This Membership Form is meant for a SINGLE family or individual. Two or more families' information
        should not be filled on the same Form. Fill one and submit before filling another if assisting a family to register.
        `,
    note2:`If registering for the family, fill the first parent information FIRST and then add Spouse to fill, before adding Child/Children.
     Fill your own information in the form if you are not married.`,
    note3: `You will be able to add Spouse or Child's Form when 'Marital Status' is set to 'Married'.`,
}
const updateMsg1 = "Go back to Home and follow the instruction if you want to Register!";
const updateMsg2 = "Congratulations, Form registration was successful!";
