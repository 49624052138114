import React from 'react';
import Collapsible from '../../../Collapsible';
import AccountUnitDocsIndex from './AccountUnitDocsIndex';
import OnlineOffering from './components/OnlineOffering';


const IndexAccount = () => {
  return (
    <div className=''>
          <Collapsible
              contStyle="mb-3 mx-3 border-2 border-solid border-teal-700 p-1 md:p-3 hidden"
              titleStyle={` bg-[teal] text-white pb-2 underline font-bold`}
              iconCol="fill-white"
              iconHoverCol=""
              hideDeleteIcon
              //collapse={collapse}
              addedInfo={`Online Offering`}
              iconType="plus"
          >
               
              <br/>
          </Collapsible>
          <div className='mb-8 mx-3 border-2 border-solid border-teal-700 p-1 md:p-3'>
            <OnlineOffering/>
          </div>
          <Collapsible
                contStyle="mb-3 mx-3 border-2 border-solid border-teal-700"
                titleStyle={` bg-[teal] text-white pb-2 underline font-bold`}
                iconCol="fill-white"
                iconHoverCol=""
                hideDeleteIcon
                //collapse={collapse}
                addedInfo={`Account Documents`}
                iconType="plus"
            >
                 <AccountUnitDocsIndex/>
                <br/>
            </Collapsible>

            
    </div>
  )
}


const IndexAccountDocs = () => {

  return(
    <Collapsible
      contStyle="mb-3 mx-3 border-2 border-solid border-teal-700"
      titleStyle={` bg-[teal] text-white pb-2 underline font-bold`}
      iconCol="fill-white"
      iconHoverCol=""
      hideDeleteIcon
      //collapse={collapse}
      addedInfo={`Account Documents`}
      iconType="plus"
  >
      <AccountUnitDocsIndex/>
      <br/>
    </Collapsible>
  )
}


export default IndexAccount;
export {IndexAccountDocs}