
const pastorCorner = [
    {sn:0,
        title: 'Ten Wonders of God’s Mercy',
        bibleRef: `For he said to Moses, “I will have mercy on anyone I wish; I will take pity on anyone I wish.” So then, everything depends, not on what human beings want or do, but only on God\'s mercy” (Romans 9:15-16 GNB).`,	
        body:['There are so many great and uncountable things that Mercy can do for man. Here are ten of them:',
            '1. You will be preferred above others Genesis 25:23.',
            '2. Mercy makes your life attractive. It is a magnetic force that adds attractiveness to everything you do. It draws the right kind of people to you.',
            '3. Mercy singles you out of the crowd.',
            '4. Mercy links your path with your helpers that will bless you and those that will assist you. Psalm 118:7 (KJV) "am too blessed to be common."',
            '5. Mercy commands supernatural favour. Mercy precedes favour. Favour will not come until they have mercy on you.',
            '6. Mercy gives you access into places you ordinarily couldn’t enter.',
            '7. Mercy covers your errors – you will make mistakes but no one will notice it. May mercy overrule your errors.',
            '7. Mercy prevails over deserved judgement James 2:13. There are people around you, called fault-finders. They publicise and announce your mistakes. They that wait for the day of your shame, they will be disappointed in Jesus name.',
            '8. Mercy gives you divine settlement. Your parents can be there, but can’t take you there; but God’s Mercy will  take you to the levels beyond your imaginations.',
            '9. Mercy silences your adversaries.',
            '10. Mercy humiliates your adversaries. It makes them change their testimonies about you. What my enemies want me to be, I will disappoint them.',
            'Therefore Brethren, if you must ask God for anything in the remaining days of this year, let mercy be the first after thanking Him for giving you Jesus. His Mercy will open to you today, the door that you have knocking for days, months or years in the mighty name of Jesus.',],
        pray: 'Heavenly Father, thank You for Jesus. Lord, show me Your Divine Mercy today in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Dec, 2021"}},	
        {sn:1,	
        title: 'God Answers Prayers',	
        bibleRef: '“Ask, and you will receive; seek, and you will find; knock, and the door will be opened to you. For everyone who asks will receive, and anyone who seeks will find, and the door will be opened to those who knock” (Matthew 7:7-8 GNB).',	
        body:['Countless of stories are told of answered prayers: revealing testimonies of diseases cured, illnesses healed, forgiveness granted, relationships restored, hungry children fed, bills paid and lives and souls saved through the efficacy of prayer. So, there are evidences around us as proofs that God answers prayer. But because most of these evidences are personal; many who think of “evidence” as the only person observable, measurable, and reproducible proofs cannot understand how faith people live by the notion that God answers prayers. The truth however is that God’s answer to your prayers is not dependent on whether the godless man would believe it or not.',
            'The text of today is Jesus’ word, and this is the same thing that the Psalmist reveals that the Almighty God says i.e.“call on me and I will answer”(Psalm 50:15). God is God, and He is not dependent on anyone. If He says it, surely He will do it. But, the answer to the prayer of one who decides to pray would also depend on whether such a person believes in the ability of God to answer his/her prayer. This is why the writer of the Book of Hebrews says: “...for he who comes to God must believe that he exists, and that he is a rewarder of those who diligently seek him" (Hebrews 11:6).',
            'If God says: “call on me...I will answer” and Jesus Christ also says: “Ask and you will receive”; brethren, it is a done deal. No matter what comes your way, all you need to do is to call on God, and wait for His answer. He will surely answer. Because He says: “My Word shall not return to me void,” in other words, His promise to answer cannot be in vain; it is sacrosanct and nothing can change it. This is why John in his letter explains that: “...whatever we ask we receive from him, because we keep His commandments and do what pleases Him (1 John 3:22). That which pleases Him is your faith. Your faith is the proof that you know that He cannot lie, you know that He does not fail in His promises; you know that since He gave Jesus, there is nothing He wouldn’t give. And so, Your Faith pleases Him and it brings forth your answer. (Romans 8:32)',
            'In the scriptures, we read that Elijah prayed for fire from heaven and it came down (2 Kings 1:12), Hezekiah prayed for deliverance and He received it (2 Kings 19:19), the Apostles prayed for boldness and they were bolden (Acts 4:29). Even Gideon prayed to see signs and God showed him signs as recorded in Judges 6:33-40. All these examples were written by eyewitnesses to the events, they are therefore clear evidence of answered prayers.',
            'Brethren, whatever you have been looking onto God for in the past year, trust Him, He has you on His agenda, because you dared to ask Him. He will surely do it. Remember that He is your creator and He knows the right time for everything to come your way with perfect peace attending it. So, in this victory month of fasting and prayer, be engaged in serious praying and keep trusting. God answers prayers. He will answer yours. Because you trusted God and waited on Him, the time for your favour is now (Psalms 102:13).',],
        pray:'Heavenly Father, thank You once more for the new year and the beginning of new things in my life. Thank You Lord for there shall be manifestation of the long awaited answers to my specific prayers and requests in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Jan, 2021"}},		
        {sn:2,	
        title: 'Strength Of God In Challenges',
        bibleRef:'"Every test that you have experienced is the kind that normally comes to people. But God keeps his promise, and he will not allow you to be tested beyond your power to remain firm; at the time you are put to the test, he will give you the strength to endure it, and so provide you with a way out.” (1 Corinthians 10:13 GNB)',	
        body:['As humans on this terrestrial side of life, no matter how great or low we may be, and no matter our status or position, wealth or knowledge that we possess, challenges will come. This is simply because challenge is a part of life. Hence today’s scripture says that every test that you have experienced is the kind that normally comes to people. So, there is no new challenge coming to you that someone has not experienced and God will not allow you to be tested beyond your power.',
            'One basic fact about challenges is that it can come from any area of a man’s life. In other words, challenges can be personal, marital, educational, work place, relationship, financial or career. It can even be challenges with children, etc. So, no one is immuned to challenges. They will come at some point in one\'s life. Another fact is that there is no end to facing challenges until a man breathes his last breath. This is why there is no end to praying until the life of man ends.',
            'How do we overcome challenges? We overcome when we connect with God. Therefore, anyone that connects with God will not be overcomed by challenges. Rather than being overcomed, the challenges become the tests that will eventually turn to testimonies.',
            'Every human being faces challenges on daily basis; hence today’s text says that with every test, God will give you the strength to endure it, and also provide you with a way out. God’s word also says in Romans 8:37 that: “No, in all these things we are more than conquerors through Him who loved us”.',
            'There is however no gainsaying that challenges can really overwhelm one. But when you draw strength from God, you can go through all the challenges and come out victoriously without you breaking down or your loved ones becoming destroyed or damaged along the way or as a result of any negative actions on your part. Example of this is the story Samson in Judges 16:23-31. Samson had challenges but it was not  because of his challenge that he failed but he failed because he did not draw strength from God in his time of challenge. Samson not only got damaged but he also destroyed a whole set of people.',
            'Another example is Daniel, Shadrach, Meshach and Abednego. These four Hebrew boys had challenges but because they drew strength from God none of them was destroyed or damaged. In fact, they ended up drawing the king and the nation to the Almighty God. See the Book of Daniel chapters 3 and 6. God is always glorified when we hold unto Him all through our challenges.',
            'The Children of Israel had diverse challenges while in Egypt. They also had challenges as they journeyed out of Egypt to the promised land. By reason of how they respectively drew strength from their God during all the challenges only the two people (Joshua and Caleb) absolutely trusted God and drew strength from Him and those that were children got to the promised land. All the others who did not connect to drawing strength from God did not get there. (See Numbers 14:20-24). May we all get to our promised land in Jesus name.',
            'The only source of strength for man to go through challenges in life and come out victorious is the Almighty God. However, we have a role to play to draw the strength from God. This is why some have come out victorious while some have not, but became casualties. You will not become a casualty of the challenges of life in Jesus name.',
            'Scripturally, there are Ways by which we can find strength in God in times of challenges. One of such ways is our relationship with God through Jesus Christ. May we have the grace to draw strength in God when challenges come in Jesus name. Amen.',],
        pray:'Heavenly Father, thank You for Your Word. Lord I thank You for in Christ Jesus I have the strength to go through the challenges of life victoriously in Jesus. Amen.',
        comments: [],
        details:{author:"Admin", date:"Jan, 2021"}},	
        {sn:3,	
        title: 'Strength In God Through Prayer',	
        bibleRef:'“On the day I called, You answered me; And You made me bold and confident with [renewed] strength in my life” (Psalms 138:3 AMP).',
        body:['To draw strength in God, prayer can be a very powerful tool. The Psalmist says on the day he called, God answered him, made him bold and renewed his strength. This is what prayer does. It’s like when a child needs something, the child cries to his/her parent; and the moment the parent says okay, I will give you, there is a kind of peace that comes on the child.',
            'In Jeremiah 33:3, God says: “Call unto me, and I will answer you...” It is as simple as that. We all can remember our school days particularly if you happened to be a boarding student. Our parents have told us to call or write letter when we needed anything. If you needed something and you didn’t call or write, it would be your fault. Even though God knows our needs, but He says, “call on me”. Jesus said in Matthew 7:7 that “Ask and you shall receive”',
            'We also need to learn to increase the time we spend on our knees in prayer. The hour of prayer is the moment of drawing strength from God in prayer. Allocate more time to worship God even without asking for anything from Him. It will strengthen you in your journeys of life.',
            'In extending hours of prayer, we also need to spend part of the time listening to whatever God has to tell us. When we do, God’s Spirit will minister to our spirit and we will hear and understand Him. This is why Jesus says that His sheep (we) hear His voice, and He knows them (us), and that we will follow Him (John 10:27). When you take time to listen, you will hear God speaks to you. Prayer is a two-way communication with God. Not only do we need to speak to God but God also desires to speak to us as well.',
            'Whenever we have gotten to the end of our ropes and feel like one cannot go on anymore, you will realise that it is because we had allowed other things to get in the way of our communication with God. We concentrated more on the situation than on God.',
            'Brethren, your prayer life is part of the tool with which you can draw strength from God. In this challenging times that we are living, I pray that the Almighty God will strengthen our prayer lives in Jesus name.',],
        pray:'Heavenly Father, I thank You for Your Word. Lord, Strengthen my prayer life in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Jan, 2021"}},	
        {sn:4,	
        title: 'Drawing Strength Through Worship',
        bibleRef:'“Now when they began to sing and to praise, the LORD set ambushes against the people of Ammon, Moab, and Mount Seir, who had come against Judah; and they were defeated.” (2 Chronicles 20:22 NKJV)',	
        body:['As believers we know the power of worship. To worship God is to show a lot of love and adoration for Him. When you truly worship God, you express what is your love for Him. Your worship is a kind of unquestioning devotion, this is why you move the hands of God faster when you praise and worship Him. In your worship, you create a platform for God’s presence. In fact, His Word says God is looking for worshippers who will worship Him in spirit and in truth (John 4:23)',
            'Worship is therefore another area that believers can draw strength in God. You do this by worshipping Him all through to your victory.  In Exodus 15:2 it is written: “The Lord is my strength and my song; He has given me victory. This is my God, and I will praise Him, my father’s God, and I will exalt him”. Worshipping and singing praises to God in the midst of your struggles is a great source of strength in God. In our text today, taken from the story of Jehoshaphat and how the Israelites employed singing praises to God  to conquer the three nations of Moabitites, Amorites and the People of Mt. Seir. With praise and worship, we disarm our enemies and overcome our challenges.',
            'It is often said that music has a kind of terrific power. Singing can touch people’s hearts in ways that nothing else can. As such, when we we combine the power of worship with the power of prayer, we will have fantastic results. Anytime you are going through challenges and struggles, merely listening to choruses and hymns of praise and worship will restore your soul and give you new strength. King David in Psalms says that- “I will praise you, my defender. My refuge is God, the God who loves me.” (Psalm 59:17 GNB).',
            'Let us consider the way that the psalmist combines praising God with God being his Defender. Apparently, he is receiving strength in the midst of singing praises to God because when God is your defender your victory is sure. It is important for us to spend quality time singing to God as between you and God. The Psalmist says that God is holy, “Enthroned in the praises of Israel.” (Psalms 22:3 NKJV). We also must enthrone God in our lives and over our situations through praising and worshiping Him. And when we do, we will draw in Him, the strength that we need to overcome every difficulty. God’s hands are moved through our Worship. Therefore, draw strength from God through your worship of Him. Hallelujah!',],
        pray:'Heavenly Father, thank You for Your Word; Lord help me to recognise and draw strength from you through worship in Jesus name. Amen.', 
        comments: [],
        details:{author:"Admin", date:"Jan, 2021"}},	
        {sn:5,	
        title: 'Positioned to Receive',	
        bibleRef:'“If you believe, you will receive whatever you ask for in prayer.” (Matthew 21:22 GNB).',
        body:[ 'The promises of God all through the Bible are so numerous and you only need to find them in the scriptures and claim each of them. They are the promises of what God has already put in place for us. Jesus said when it comes to receiving what you ask in prayer, it is already yours but you have to believe. Hence Apostle Paul in his letter to the Christians in Ephesus said: “God has blessed us with every spiritual blessing.” (Ephesians 1:3). And to the Colossians he wrote that “God has made us worthy.” (Colossians 1:12). To the Romans he also wrote in Romans 8:32 that, God, who did not even keep back His own Son, but offered Him for us will also freely give us all things. (GNB). And when it comes to Godly living, Almighty God gives us all we need to attain it as written in 2 Peter 1:3 that: “God\'s divine power has given us everything we need to live a truly religious life through our knowledge of the one who called us to share in his own glory and goodness.”(GNB)',
            'Therefore, going by the Scriptures, God has already positioned us to receive everything: be it physical, material or spiritual. There is also mercy for any mistake or error we would ever make. God already took care of it. He paid the ultimate price through the death, burial and resurrection of His Son, our Lord Jesus Christ; so that, as far as God is concerned, He has done His part. Now, it is up to us to do our part. And what do we do? Jesus said: “So do not start worrying: ‘Where will my food come from? or my drink? or my clothes? Your Father in heaven knows that you need all these things. Instead, be concerned above everything else with the Kingdom of God and with what he requires of you, and he will provide you with all these other things.” (Matthew 6:31-33 GNB). Once this part of the equation is done, all you need to do is to work and walk in faith. Because the scripture also says in 2 Thessalonians 3:10 that: “...The one who is unwilling to work shall not eat.". But God will multiply and bless the work of our hands (Deuteronomy 28:12). He will also prosper the works of our hands (Deuteronomy 30:9).',
            'Therefore, as far as receiving everything good in life is concerned, God already positioned His children to receive. You already have it; yours is to walk in it by aligning yourself with His word; believe Him and keep thanking Him. Open your heart in faith today and believe His word over what you need. Trust Him and keep claiming the word and thanking Him. The Psalmist knows this hence he also made us to know that: “...The LORD is our protector and glorious king, blessing us with kindness and honour. He does not refuse any good thing to those who do what is right.” (Psalms 84:11 GNB). So, if what you are desiring is a good thing and you do what is right; you are in the position He placed you to receive and you will certainly have it. So, keep thanking Him in faith. You are positioned to receive from Him, hallelujah!',],
        pray:'Almighty God, I thank You for this day, I praise You for Your word and for Your faithfulness. I receive Your word which is life and strength to my soul and I thank You for positioning me to receive...(mention your requests). Thank You Lord God in Jesus  name, amen.',
        comments: [],
        details:{author:"Admin", date:"Feb, 2021"}},	
        {sn:6,	
        title: 'Divine Protection',	
        bibleRef:'“A thousand may fall at your side And ten thousand at your right hand, But danger will not come near you.” (PSALMS 91:7 AMP)',
        body:['One of the benefits or blessings of the presence of God being with man is protection.',
            'The word ‘protect’ is a verb word which means to “fortify or to provide a place with defensive works as protection against attack." Protection also means shielding or to shelter, to preserve, to keep safe, to safeguard to security, or to provide refuge. By the Dictionary, it also means being immuned to danger. All these definitions gives one a deeper sense and understanding of what the presence of God does for the believer. Little wonder, King David wrote in Psalm 23 that:”Even though I walk through the valley of the shadow of death, I will fear no evil, for you are with me...” (Psalms 23:4 ASV). The Presence of God is therefore an unfailing assurance of protection from all evils. King David also revealed in Psalm 91:4&7 that "For He will rescue you from every trap and protect you from deadly disease. Though a thousand fall at your side, though ten thousand are dying around you, these evils will not touch you.” (AMP). What a mighty God we serve.',
            'Thousands of years ago, the Almighty God made great promises to a man of faith and an obedient servant of God by name Abraham that He (God) will give him wealth, blessings, greatness and protection (Genesis 12:2-3; 14:18-20; 22:17-18). And these are what all Abraham enjoyed. (See Genesis 24, 26-28, 35, 48; Numbers 24; Deuteronomy 1, 7-8, 11, 15-16, 28, 30, 33). As such up till our generation, Israel has never been overcome by any nation of the world. Yes, there were times in the Bible records that their enemies gained upper hands. But these only happened when Israel or Judah fell into sin. But even when that happened, God would in the end deal with the enemies; once the children of Israel repented and turned back to Him. The Almighty God is still the same God today.',
            'Although, we are not geographically the Israelites, but by reason of Jesus’ redemptive work, we have become adopted children of God and so, we are the spiritual Israelites. And so, by adoption and through the divine covenant of the blood of Jesus, we also have God’s presence the moment we acceptsl Jesus as our Lord and Saviour. Hence, the scripture says: “And if you are Christ\'s, then you are Abraham\'s offspring, heirs according to the promise”. (Galatians 3:29).',
            'Brethren, God’s presence is an assurance of safety, no matter the dangers around you. He has assigned ministering angels to His children and their households. All you need to do is to be sure that that you have accepted Jesus as your Lord and Saviour. Be circumspect in all that you do because Jesus, Your Master says to you "watch and pray.” (Matthew 26:41). Also, you must daily send these angels in the name of Jesus to continually minister the protection of God on you and your entire household. Psalm 91:11 says: “For he will command his angels concerning you to guard you in all your ways”. (AMP)',
            'As long as man does not depart from the presence of God like Jonah did as recorded in Jonah 1:3, God never departs from you and He will keep the promise of His presence and divine protection. May you or any of your loved ones never depart from the Presence of God in Jesus name.',
            'In the midst of all the insecurity in our country and the world at large, God remains faithful. His presence and power of protection through the blood of Jesus shall keep you safe. The Lord is on your side, if He did not leave David as a shepherd boy and as a king, as prey to the teeth of the enemies as declared by David in Psalm 124:6; He will never leave you as prey to the enemies in Jesus name. Therefore, in this new week and beyond, His divine presence will give you and all your loved ones His divine coverage and protection from physical and or spiritual attacks in Jesus\' mighty name.',],
        pray: 'Heavenly Father, I thank You for this day and I thank You for Your word. I thank You for Your presence with me and Your protection every day of my life in Jesus\' mighty name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Feb, 2021"}},	
        {sn:7,	
        title: 'Victory through Jesus Christ',	
        bibleRef: '"...But thanks be to God who gives us the victory through our Lord Jesus Christ!” (1 Corinthians 15:57 GNB)',	
        body:['Apostle Paul in the above scripture reminds us of the victory which believers have through Jesus Christ. In other words, our victory is not an ordinary victory. In fact, our victory is not a product of our will, goodness, ability or capability; rather, it is the victory through the only victorious One, Jesus Christ. It is victory over death, it is victory every issue of life, it is victory over any kind of challenge and all the schemes of Satan. In 1 John 5:4, Apostle Paul’s writing says: “For everyone who has been born of God overcomes the world. And this is the victory that has overcome the world; our faith.” The Faith refers to is our faith in God through Christ. No matter what the enemy presents as a problem; no matter how recalcitrant the problem makes itself to look. The main equation is where your faith lies for victory. If your faith is in none other but Christ; then, congratulations, you are already an overcomer waiting to be announced for God’s glory.',
            'Romans 8:37 says: “No, in all these things we are more than conquerors through him who loved us.” Your faith for victory through Christ must however be backed up by your acknowledgment of your sin, confessing it to God, repentance and penitence before God if your challenge is as a result of a sin or wrong doing. The Psalmist says: “If I had ignored my sins, the Lord would not have listened to me.” (Psalms 66:18 GNB). Also, in Romans 6:1, Apostle Paul wrote that we cannot continue in sin, that grace may abound. Therefore don’t truncate your faith by trying to hide your sin. Nothing is hidden from God (Psalm 139:7-12). But He wants us to confess it to Him (See 1John 1:8-10).',
            'If you believe it is the power of the enemy in operation, like it was with Mordecai, you faith must be backed up by fervent prayer and His word, confessing the word of God over the situation. In the book of Esther, the Queen realised that the battle of Mordecai was not ordinary, but a battle against the Jews, so, Queen Esther declared a fast - Esther 4:16.',
            'If your challenge is due to human envy or hatred, because you won’t compromise the word of God to please men, back your faith up with standing on whatever the word of God says. In the book of Daniel, the three Hebrew boys would rather die than compromise the word. They said: “But even if he doesn\'t, Your Majesty may be sure that we will not worship your god, and we will not bow down to the gold statue that you have set up.”(Daniel 3:18 GNB). They were resolute on the commandment of God that says they (and we) must not bow to any other gods - Genesis 20:3&5. This strengthened their faith and they came out victorious. God has promised that no matter what we pass through, He will see us through. No matter what our circumstances may be, God already has it planned out to give us victory, hence He said when we pass through water or fire, He is with us. The water will not swallow us and the fire will not burn us (Isaiah 43:2). Not just the promises in the Old Testament, but God also gave Jesus Christ through whom we have a new covenant. God sacrificed Jesus for our sins, our healing and our victory. Jesus’ coming to the world was not only for us to make heaven, but for us to also have victory on earth. This is why Jesus’ word is: “...I give you power to tread on serpents and scorpions, and over all the power of the enemy: and nothing shall by any means hurt you” (Luke 10:19).',
            'Jesus also made promise of abundant life in John 10:10b. To have abundant life includes abundance of peace, abundance of joy and abundance of resources and victories upon victories.',
            'Today, Brethren, no matter what the battles may be, we can in faith walk shoulders high and heads up because we already have victory through Christ. He alone overcame Satan and He alone is the Prince of peace.',],
        pray: 'Father, thank you for today, thank you for Your word and I thank You for I have victory through Jesus Christ over all the issues of life including the present one (mention it) in the name of Jesus. Amen.',
        comments: [],
        details:{author:"Admin", date:"Mar, 2021"}},	
        {sn:8,	
        title: 'Provoke God to remember you',	
        bibleRef: '“Remember me, Lord, when you show favor to your people, come to my aid when you save them,” (Psalms 106:4 NIV).',
        body:[ 'Disappointments often lead to hopelessness and discouragement. But, some disappointments can be blessings in disguise. However, more often than not, disappointments are orchestrated by evil forces against the children of God. However, no matter what powers may be behind your challenges or disappointments, God always has a way of turning every evil orchestration of the enemies  to the blessings of His children. But, we need to call on God. We need to learn to provoke God to remember us.',
            'When you have a great prophecy over your life and it does not seem to be coming to anything near materialisation, there is no need to complain or mourn. But, rather go into the realm of fervent prayers to provoke God to remember you. Do not begin to doubt the word of God through prophecy. But, provoke God by asking him to remember you and reconsider you.',
            'The story of Hezekiah, King of Judah, in 2 Kings 20:3 was typical example. King Hezekiah was not only sick, but Isaiah has come to reveal to him that death was going to be the end result of his illness. The Bible says that Hezekiah turned to God and prayed saying: “...LORD, please remember how I have walked before you in faithfulness and with a whole heart, and have done what is good in your sight." And God remembered Hezekiah and sent word back to him in vs. 5 that: "...have heard your prayer; I have seen your tears. Behold, I will heal you...”',
            'Brethren, you need to provoke God concerning that which you have done for Him and in His name. This is what King Hezekiah did for God to remember him. Moses also in Exodus 32:11 provoked God concerning His Own power. The Bible says: But Moses implored God and said, "O LORD, why does your wrath burn hot against your people, whom you have brought out of the land of Egypt with great power and with a mighty hand? That is calling God to remember what He has done in the past. Prophet Elijah in 2 Kings 1:10 also provoked God when he was confronted by p',
            'messengers of king Ahab: But Elijah answered the captain of fifty, "If I am a man of God, let fire come down from heaven and consume you...”. Elijah called God to remember that he is a man of God. The result was fire instantly coming down from heaven and consumed the captain and his fifty men. One of the thieves on the Cross also called Jesus to remember him in His Father’s kingdom in Luke 23:42. Before this thief called for remembrance, he had done something, as we see in verse 40 where he rebuked the other thief that was mocking Jesus. So, in effect, the thief provoked Jesus to remember that he spoke well of Jesus. That he did not mock Him.',
            'You too can provoke the Lord to remember all that  you have been doing. Tell God as the people of Malachi did that you should not live a life of reproach. You can provoke God for your provision when you remind God that you have been doing His word in Malachi 3:9-12 and the enemy has no right over your finances. Gideon also provoked God in Judges 6:12-14',
            'Brethren, what is that situation that needs to change about you or your loved ones? What is that blessing that you have long been praying for and there has been an ungodly delay? What is the issue that the enemy intends to use to bring shame to you as a child of God? Rise up today and provoke God to remember you. There is one thing that you have done for God or in the name of God, He will remember it and hear you. Remember that God does not owe anybody. Proverb 6:10 says that: “...God is not unjust so as to overlook your work...”. God will remember you today.',],
        pray: 'Heavenly Father, I thank You for Your Word.  Lord, I know that I cannot serve you and be in this situation; Lord remember me today and perform your word in my life in Jesus mane. Amen.',
        comments: [],
        details:{author:"Admin", date:"Mar, 2021"}},	
        {sn:9,	
        title: 'Stay Under His Cover',	
        bibleRef: '“He that dwells in the secret place of the Most High Shall abide under the shadow of the Almighty.” (Psalms 91:1 ASV)',	
        body:[ 'The Midrash & Tehini, the ancient commentary on parts of the Hebrew Scriptures which are attached to biblical texts reveal that Psalm 91 was actually composed by Moses on the day he completed the building of the Tabernacle in the desert. And that while Moses was ascending into the cloud hovering over Mount Sinai, he recited these words as protection from the angels of destruction.',
            'In Jewish thought, this Psalm conveys the themes of God\'s protection and His Power to rescue from danger. For us as Christians, the Psalm has become a great source of comfort and protection in times of problems or danger or suffering.',
            'There are lots of mishaps and insecurity everywhere and every day. Airplanes have been hijacked and people taken as hostages. People have been kidnapped from public and private places and from private and commercial vehicles on the road. There have been Ship wrecks and other unimaginable mishaps coming to people irrespective of their age, class or status in life. There have been all manners of attacks of the enemies, both physically and spiritually. People have been burned up in their houses. Children have been kidnapped from schools. All manners of calamities happening on daily basis.  ',
            'Even people who were employed as security guards have become predators, preying on the hands that feed them and they have committed unimaginable crimes against their employers. Some so called men of God have turned around to prey on the flock that Jesus entrusted to them',
            'Children have betrayed their parents, giving up their parents for ritual money. Parents have been found to have sold out their children. Siblings have murdered their own siblings like Cain did to Abel. House maids and house boys have molested their master\'s children and some have poisoned their employers and the entire family. Co-workers have planned the deaths of fellow co-workers. Friends have deceived their so called friends and led them into places of danger to get rid of the unsuspecting friends. The long and short of the story of this world is that NOWHERE is safe. We all are living in the midst of dangers and at times we are living with dangerous people.',
            'These are not new, the dangerous situation of the world that humans live in has been from ancient times. And we find examples of the wickedness of man to fellow man also in the Bible. Examples are Cain that killed his brother Abel, Joseph whose elder brothers sold into slavery, Rebekah who betrayed her own son Esau to favour his twin brother Jacob (both from her womb). Uncle Laban deceived his nephew Jacob into Marrying Lea instead of Rachael, etc.',
            'Judah slept with his daughter-in-law, Tamar. Samson was betrayed by the trusted love of his life, Delilah and thereby lost his life and destiny. Prophet Eli\'s Children defied the temple. King Saul envied little shepherd-boy, David and made frantic efforts to kill him. King David also killed Uriah because of Uriah\'s wife. Jezebel encouraged King Ahab to kill Naboth because of his vineyard. Judas Iscariot followed our Lord Jesus for three years, and yet, at the end he sold Jesus for thirty pieces of silver. Peter, the rock, denied Jesus. The list is endless and it will continue until the world ends.',
            'The word of God also tells us that:  "The heart is deceitful above all things, and desperately wicked: who can know it?" (Jeremiah 17:9). But, in the midst of all these,  we are not hopeless. And we can turn to such scriptures as Psalm 91 that says: "he that dwells in the secret place of the Most High..." It is only God that we can totally trust and solely rest on. And we know that His secret place is His word. In the midsts of all the dangerous, deceitful and wicked people, we have to dwell daily in His word and live by His word. This is however not for everyone but only those that believe in Him and have acknowledged Jesus.',
            'Jesus is the word and He alone is our security. The scripture makes us to know this in the book of John that: “In the beginning was the Word, and the Word was with God, and the Word was God...And the Word became flesh and dwelt among us, and we have seen his glory, glory as of the only Son from the Father, full of grace and truth. (John 1:1 &14). So, Jesus is the Word.',
            'Brethren if we are in Christ, there is no fear because we have the God that is God of His word. The Psalmist declares that “Some trust in chariots and some in horses, but we trust in the name of the LORD our God” (Psalms 20:7 ASV). To exhibit our trusting in God, we need to study His word with passion and know it for us to be able to use it in time of problems or dangers. People who rely on charms do not joke with their incantations, they take time to know it by heart so that when confronted with dangerous situations they are quick to start all their incantations. They use with passion that which cannot save.',
            'As Christians, the situation around us is even more dangerous because we are also fighting unseen battles. But, we can rely on the promises of God in the midst of all the evils of this world that: "a thousand will fall at our side and ten thousand at our right hand, it will not come near us...". But, we need to spend time with His word so that we know His word by heart and be able to use it against every attack and scheme of the enemy. A child of God that lives without the word is like one trying to get out of an aircraft in the air without a parachute. We shall not become casualties or preys for the agents of the enemy in this wicked world in Jesus name. Therefore, dwell in God’s secret place by dwelling in His word and remain under His cover.',],
        pray: 'Heavenly Father, I thank You for this day, I thank You Lord for Your Word and I thank You for making me and my family securely protected by Your Word. Thank You Lord, for the coverage of the blood of Jesus Christ in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Mar, 2021"}},	
        {sn:10,	
        title: 'Look to God and be radiant',	
        bibleRef: '“Those who look to Him are radiant; their faces are never covered with shame.” (Psalms 34:5 NIV).',	
        body:[ 'In my research, I found the following as description of a radiant face: “Someone who is so happy that the happiness shows on the face.” The Dictionary also says it is "an expression on the face marked by or expressive of love and confidence”. The Psalmist in our text today says “they look to Him and are radiant”. In the previous verse, the Psalmist says: “I sought the LORD, and He heard me, And delivered me from all my fears.” (Vs.4). When you are in dare need of something, like your life is dependent on it, and you ask someone for it. Just imagine what the looks on your face will be like the moment the thing is handed into your hands. Sure, your face will reveal joy mixed with peace and love for the person.',
            'Let us consider the story of Hannah in 1 Samuel 1:9-10, Hannah got up from her husband who could not give her the comfort of a child and she went into the presence of God and prayed like she has never done before. She poured her heart at the feet of the Lord. God did did not ignore Hannah. Because as far as it is with God, ‘their faces shall never be ashamed!’ God blessed Hannah with not only prophet Samuel but also 5 more children – “Hannah conceived and bore three sons and two daughters.” (1 Samuel 2:21). How Radiant would Hannah’s face have been looking at her six children? I believe it would have been this kind passionate looks of: ‘Lord, I will never take my eyes off You’ looks or “I love you more than anything Lord” looks. Just like king David wrote: “I long for you like a deer that pants for water….." The look that is glowing with the beauty of God. Brethren, your face shall be radiant in Jesus name.',
            'Brethren, are you calling on God  concerning a matter, an issue or a need. Try fixing your looks on God with passionate love, knowing beyond any doubt that He can never fail you. Because, God cannot resist such looks of love. Never! He would incline His ears to listen to you, just as He has promised. His love for you is  massive and immeasurable. No one but God gave their blood and life for you. And so He will give you that which you are looking to Him for. See (Romans 8:32) Try looking at God and trusting Him like when baby birds look at their mother bird for food with beaks open and looking only at their mother. Look only at God. He would not ignore you.',
            'Praise be to Jesus for super love of God that gives God’s children everything - victory, deliverance, achievement, provision, success’s and everything. Show your love for God and trust in Him through your praying throughout the night and praising Him even though your answers are yet to arrive. That is the show of your trust and God honours it.',
            'Brethren, what king David wrote in our text today that their faces were not ashamed is repeated in Paul’s letter in Romans 10:11 that: “Anyone who believes in him will never be put to shame.” (NIV). Just as David said that  ”Those who look to Him are radiant”, that is how your eyes will brighten with joy and your face glowing when your victory, deliverance, breakthrough, favour, justification and success come.',
            'Trust Him! It will surely come because He knows that it is Him alone and none other that you look to over all  issues. Your radiance comes from God and God alone.',],
        pray: 'Heavenly Father, I thank You for Your Word. I look to You alone and I am confident that in You alone, I have my victory in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Apr, 2021"}},	
        {sn:11,	
        title: 'God Will Surprise You',	
        bibleRef: '“The Angel of the LORD appeared to him in a blazing flame of fire from the midst of a bush; and he looked, and behold, the bush was on fire, yet it was not consumed.” (Exodus 3:2 AMP).',
        body:[  'There are a few people who say that they don’t like surprises. But a large number of people enjoy pleasant surprises. Our God is also the God of pleasant surprises. I pitch my tent with those who like pleasant surprises. God surprises His people every day.',
            'What is a surprise? The dictionary defines it as: “an unexpected or astonishing event, fact..."',
            'One of the ways in which God surprises us is the record of Moses and the burning bush as seen in our text today. The unusual or unexpected thing was that the bush burned but it was not consumed. This indeed was a big surprise and it was beyond Moses’ comprehension.',
            'There are examples in the Scriptures of how God surprised His children. Moses was surprised, and so he said to himself in verse 3, “I will now turn aside, and see this great sight, why the bush is not burnt." And as he moved closer to see the wonders, another surprise unfolded as recorded in verse 4 that: God called unto Moses out of the midst of the bush, saying: Moses! Moses!!',
            'Imagine you are watching something with amazement, still wondering how can this be, and suddenly you hear your name being called out from the midst of the unusual thing. I believe anyone would almost jump out of his/her skin; and I believe that Moses couldn’t have been any different. No one had ever seen such a sight; neither had Moses, before that fateful day. In the same vein, God also surprised Abraham and Sarah with the birth of Isaac at an age which was past child bearing for any woman. There are also other surprises in the New Testament, starting with the declaration that the Angel made to Mary, the mother of Jesus - “The Virgin Mary to bear a child.” At the tomb of Lazarus; Mary had given up hope. It was a forgone conclusion. Lazarus had been dead for 4 days; but when Jesus appeared, everyone there, and even those that later heard were surprised as recorded in John chapter 11.',
            'Going back to the Old Testament; when the children of Israel got to the Red Sea; they most certainly have concluded that the end had come for them, since there is no record that they had ever had the opportunity to swim in their lives. They had been in slavery for over 400 years, and swimming exercise was not recorded in the Bible as being part of their curriculum or activities. But the God of surprises showed Himself mighty: “Moses stretched out his hand over the sea, and all that night the LORD drove the sea back with a strong east wind and turned it into dry land. The waters were divided” (Exodus 14:21). The Almighty God said to Moses, “...the Egyptians will know that I am the LORD...” (Exodus 14:18). I pray for you that whatever may be your situation, your adversaries will know that the Lord is God in your life in Jesus name.',
            'God surprised the people of old and gave them outstanding victories. He is still the same unchangeable God. His love for you has not diminished from the love with which He loved the children of Israel. His word says, that because we belong to Jesus Christ, we are joint heirs according to His promises (Galatians 3:29).',
            'Today, I rejoice with you for the pleasant surprises coming your way in every area that you have been looking unto God for His intervention in Jesus name. God will prove Himself mighty to surprise you. Your enemy will be shocked when His glory manifests in your life in Jesus name. God will surprise you.',],
        pray: 'Heavenly Father I thank You for Your word today and I thank You for the pleasant surprises that I have seen and for the many more pleasant surprises coming my way in the days ahead in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Apr, 2021"}},	
        {sn:12,	
        title: 'Secured Goodness and Mercy',	
        bibleRef: '“Surely goodness and mercy shall follow me all the days of my life; And I will dwell in the house of the LORD forever” (Psalms 23:6 NKJV).',
        body:[  'When something is said to be sure or surely, it means the thing is secured, assured, firm or confidently unchangeable. The Psalmist pointedly tells of the unshakeable goodness of God following him. In Jeremiah 32:40, Prophet Jeremiah reveals the mind of God as it concerns the assuredness of His goodness wherein the Almighty God says: “...I will make an everlasting covenant with them: I will never stop doing good for them...”(NLT). This is God Himself giving His Word. And in Isaiah 55:11, the Almighty God says that the word that goes out from His mouth shall not return to Him empty, but it shall accomplish that which He purposed, and that it shall succeed in the thing for which He (God) sent it.',
            'It is this assurance of y mercy of God that king David is also confident of when says: “Surely...”. In other words, nothing can stop us from His mercy following us, except the one that decides to fall out of line. But even at that, the moment there is repentance, mercy draws the person back into God’s favour.',
            'What is Mercy? It is the covenant word which in the scriptures is also referred to as ‘steadfast love’ of God. The Psalmist therefore says that we can count on the mercy (steadfast love of God) together with the goodness (kindness) of God. Brethren, when the Lord is our shepherd, we have these twin guardian angels (goodness and mercy) always with us at our backs and our beck and calls.',
            'As children of God, we are Princes and Princesses - Royalties. Hence the scripture says we are Royal Priesthoods and Peculiar people (1 Peter 2:9). Therefore, just as Princes and Princesses of earthly great kingdoms do not travel unattended to by maids and servants, so it is with us as believers. Mercy and goodness of God are the two angels following us and attending to us every second of our lives.',
            'The Psalmist concludes by saying that he will dwell in the house of the Lord forever. This is also an assurance that as children of God and believers, when the Lord is our shepherd, we would enjoy the presence of the Lord forever i.e. here on earth and hereafter. Moses said to the Lord: “If Your presence does not go with us, do not bring us up from here” (Exodus 33:15). Moses understood that the presence of God is all that a man needs to excel in life. And God responded that: “...This very thing that you have spoken I will do..." (Exodus 33:17). The same promise is for us, because we have found favour in God through Jesus Christ.',
            'Charles Spurgeon, the great Baptist Preacher says of Psalm 23:5b that: “While I am here I will be a child at home with my God; the whole world shall be His house to me; and when I ascend into the upper chamber, I shall not change my company, nor even change the house; I shall only go to dwell in the upper storey of the house of the Lord for ever.” In other words, in the presence of God here on earth and also hereafter, we will continue to enjoy the presence of God. What a great privilege to have God as our shepherd.',
            'Brethren, Psalm 23 is an encompassing prayer for our spirit, soul and body. It is a prayer that covers both the physical and spiritual needs of a man. It is a prayer that takes care of us while on earth and gives us an assurance that our hereafter is already taken care of. I pray the totality of Psalm 23 shall be manifested in our lives and in the lives of all our loved ones in Jesus name. Amen.',],
        pray: 'Heavenly Father, I bless Your Name and I am grateful for the assurance of Your goodness and mercy upon my life and the lives of all my loved ones all the days of our lives in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Apr, 2021"}},	
        {sn:13,	
        title: 'Mercy Is God’s Voice',	
        bibleRef: '“So then God’s choice is not dependent on human will, nor on human effort [the totality of human striving], but on God who shows mercy [to whomever He chooses—it is His sovereign gift]” (Romans 9:16 AMP).',	
        body:[ 'Mercy, a very powerful word! But what does it mean? The word mercy has been defined severally and one of its definitions is “unusual favour”. The synonyms of the word mercy also include leniency, clemency, compassion, grace, pity, charity, forgiveness, etc. I also found another definition of mercy which is: “Divine Partiality”. Partiality in itself means unfair bias in favour of one person or thing; it also means favouritism.',
            'Going by the definition of partiality, mercy therefore means Almighty God, i.e. “Divinity” deciding to break every protocol or rule because of one person for a group of people. There are numerous examples of such in the Bible.',
            'Apostle Paul referring to what God said to Moses, wrote in Romans 9:15 that: God says to Moses, "I will have mercy on whom I will have mercy, and I will have compassion on whom I will have compassion." This shows that Mercy of God does not require any qualification, it is not based on ones rights or any goodness of anyone. In fact, the Bible says no one is good except God. So, there is no good in any man to qualify him for mercy, but just divinity deciding to be partial towards one.',
            'When mercy is at work for someone, it wont matter whether he/she is the last to arrive, such a persons will just somehow be the first to be considered.',
            'When we look at mercy in terms of divine favouritism, human laws are overruled for the person for whom mercy operates. Mercy also operates as divine acceptance, so that the one operating under mercy will be the only one acceptable no matter the level of human rejection. When divine favouritism is in operation even when the one upon whom mercy operates has been ruled out and deleted from a list, divine mercy will suddenly appear and put the person’s name on the list and right at the top.',
            'When Mercy operates by way of divine interest, everybody will be interested in the person’s favour without explanation. When mercy shows up as unusual intervention; even when such a person’s case or matter has been decided and judgement sealed, something beyond man’s control will happen and such a person will walk home free, come out justified and in fact be celebrated; and every other human schisms will be overtaken by divine intervention.',
            'When mercy operates, somehow the one that is qualified by man’s ratings is removed for the one that man has adjudged unqualified. We can see examples of this in the life of Esther who by every human law and standard did not qualify to be in the palace; but even if somehow she found herself there, her position would have already been determined by her status; because she was one of the captive Jews and so would have just been a slave girl there.',
            'But because divine mercy was in operation for Esther, Vashti the Queen misbehaved and was removed to be replaced by Esther, and she occupied the Palace as a Queen (Esther 2:15-17).',
            'Brethren, have others gone ahead of you? There is no need for you as a child of God, bought with the precious blood of Jesus to fret because mercy is going to speak for you and you will be singled out before you know it.',
            'By the time Esther appeared before the King, divine mercy had gone ahead of her to prepare the way for her selection. And in the midst of all her competitors, Esther who was a mere slave girl was put in a class of her own; a divine class. She was favoured. The Bible records that: “...Esther also was taken into the king\'s palace and put in custody of Hegai, who had charge of the women...” (Esther 2:8).',
            'Divine partiality for Esther started with Hegai; the Bible says that “Hegai liked Esther and took a special interest in her. Right off, he started her beauty treatments, ordered special food, assigned her seven personal maids from the palace, and put her and her maids in the best rooms in the harem. Esther didn’t say anything about her family and racial background because Mordecai had told her not to (Esther 2:9-10). Esther did not ask or lobby for this favouritism, but divinity arranged all for her by mercy. After the order of Esther, I decree that the word of God in Isaiah 54:7 that “For a small moment have I forsaken thee; but with great mercies will I gather thee” shall begin to operate for us in Jesus name. No matter your unqualified status, no matter the politicking, today the Voice of God’s mercy shall be heard in your favour in Jesus name.',],
        pray: 'Heavenly Father, I thank You for Your Word; Lord, I thank You For Your Mercy towards mankind in the person of Jesus Christ. Today, Lord God, Let Your Mercy speak for me and my family in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"May, 2021"}},	
        {sn:14,	
        title: 'Find Jesus at a Time like this',	
        bibleRef: '"Jesus said to her, I am the resurrection and the life. Whoever believes in me, though he die, yet shall he live" (John 11:25 JKV).',	
        body:[ 'The days we are in now are indeed tough and sorrowful for quite a sizeable number of people around us. There have been losses of lives; people missing; hunger everywhere. It is not always good to say everything is all right, because truly it is not. People are hurting.',
            'In the John chapter 11, Martha was expressing those feelings of grief on that day. And today, we might also be saying, “If only.” If only someone had done something different. If only he had had gotten treatment earlier. If only I had …… If only God had ….. But brethren, we cannot live in a world filled with only “if only”. We need to live in a world of reality.',
            'The world of reality is one where bad stuff happens to good people. It is a world where good people get sick or have an accident and they die. Sometimes good comes and sometimes bad comes; that is the world we live in. It is the process we call life. Just recently, innocent students who went to University to build a glorious future for themselves were slaughtered like chicken by some elements who themselves have no future. Parents who had hopes of bright future for their children were suddenly thrown into mourning by some elements whose parents probably did not care if they live or not. These are the reality of our time.',
            'There are times we hope and pray that God would break in – “If only God would …” yet sometimes He doesn’t change the order of things and we get hurt.',
            'But thanks to God that we are never abandoned by God in our sorrowful state. Jesus did not leave Martha without hope. He gave her this promise. “Your brother will rise again. Your brother will live again. Your brother will be with you again.” I believe that the thought might have brought a faint smile to her tear stained face.',
            'Thinking of a day – somewhere in the future where there will be no more pain, no more sorrow, no more disease should bring a smile to all of our faces. To think of a day when every tear we have ever shed will be dried up and forgotten – should bring us some peace. To think of a day when those who are friends of God will live again should bring hope to our hearts; even on a day like today.',
            ' Today, as believers, we can trust in Christ. Today we can believe His promise that: "Whoever believes in me, though he dies, yet shall he live. Everyone who lives and believes in me shall never die"(John 11:26).',
            'Both we and the people perpetrating evil will all come to the end of life. We all will face death at some point. Yet, Jesus’ words will never change. “I am the resurrection and the life. Whoever believes in me, though he die, yet shall he live. Do you believe this?” If we believe, we have hope. If we believe, we have eternal life.',
            'My brethren, I urge you today to be an encouragement to those that are hurting one way or the other due to loss of a loved one or are hurting because their loved one is still missing. Let us hold them up in our prayers. Ask Him to give you and them the comfort and peace that nothing can toil with even in times like this. May the peace of God be with us all. Let us find Jesus in time like this.',],
        pray: 'Heavenly Father, thank You for everything. We ask that You uphold us all in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"May, 2021"}},	
        {sn:15,	
        title: 'Mercy of God Overcomes',	
        bibleRef: '“Lot hesitated. The LORD, however, had pity on him; so the men took him, his wife, and his two daughters by the hand and led them out of the city” (Genesis 19:16 GNB).',	
        body:[ 'Preceding the Bible Text above, we see in Genesis 18:17, after the two Angels from God had delivered the message of Sarah having a child in a year\'s time for which Sarah laughed considering her age, God then said that He will not hide from Abraham the next thing He (God) was going to do regarding Sodom. God then revealed His intention to destroy Sodom because the terrible accusations against Sodom and Gomorrah, and their sin were so great. Abraham then approached God and questioned if God was going to destroy the innocent with the guilty. (Vs. 18-23 GNB). And as the said two Angels went towards Sodom, Abraham started to negotiate numbers with God from 50 until it got to 10.',
            'Then in chapter 19, the Angels had an encounter with the perverse men that wanted to sleep with them, not knowing that the men were actually Angels, but which Lot prevented, offering those perverse men his own virgin daughters instead. Thereafter the Angels said to Lot that God had sent them to destroy Sodom because of their terrible sin. (19:13). And in verse 15, the Bible reveals that the Angels tried to make Lot hurry and said to Lot, “Quick! Take your wife and your two daughters and get out, so that you will not lose your lives when the city is destroyed.” (GNB). However, what do we see Lot do? He hesitated.',
            'This sometimes is the attitude of man till today; unnecessary or sometimes very foolish hesitation. When one is warned that danger is coming, and yet they drag their feet in taking necessary action. These had often led to dastardly results as it would have to Lot and His family but for mercy of God. If we cast our minds back to the start of the virus in 2019 before it became a pandemic in 2020, one of the things that the scientists said was that the virus infection would not have spread so fast to all the nations of the world to become pandemic if Governments of different nations did not hesitate to take action. Hesitation can destroy or even rob one of blessings.',
            'Brethren, have you been hesitant in your life and now fast with the result of your hesitation. Mercy is still available. The help of God will come to you by mercy as this was what saved Lot and his two daughters. The Bible says that when Lot failed to take instant action despite the warning, but rather hesitated: “...The LORD, however, had pity on him; so the men took him, his wife, and his two daughters by the hand and led them out of the city.” (Vs.16 GNB). The Lord will have mercy, on us and deliver us from every injury or dangerous situations we have put ourselves due to foolish hesitation in Jesus name.',
            'The Word of God says: “...I will have mercy on anyone I wish; I will take pity on anyone I wish.” So then, everything depends, not on what human beings want or do, but only on God\'s mercy.” (Romans 9:15-16 GNB). Mercy of God will speak for us and our loved ones in Jesus name.',
            'The truth of the mercy of God is not dependent on anything we do or fail to do; and not also dependent on our weakness can also be seen in the story of the widow of Nain in Luke 7:11-15. The Bible says that',
            'Jesus arrived at the gate of the town called Nain and there was, a funeral procession of the death of the only son of a widow. And that when Jesus saw her, his heart was filled with compassion for her. Jesus then said to her, “Don\'t cry.” And he walked to the coffin, touched it, and said to the dead son, “Young man! Get up” and the dead son came back to life and Jesus gave him back to his mother.',
            'This was a completely hopeless and helpless situation. The son was already dead. The woman was no longer asking for help. She didn’t even notice Jesus. The disciples did not ask Jesus to help. It was just Mercy that came to her. This means that even in the most helpless and hopeless situation, the mercy of God can show up and change the narratives. May the mercy of God come to us and our loved ones in Jesus name.',
            'Brethren, just as God’s word says, His mercy is not dependent on man. God’s mercy is God Himself. No matter the foolishness of man in hesitation and not acting promptly even in the face of danger, no matter the hopeless and helpless situation which you think can never change, when the mercy of God shows up; your story will changed. Therefore, if you will not ask for anything, let us learn to ask for the operation of the mercy of God in our lives and the lives of our loved ones. Mercy of God will overcome every weakness, helplessness and hopelessness in our lives in Jesus name. Amen.',],
        pray: 'Lord, thank You for Your Word. Lord let your mercy overcome my helpless and hopeless situation and let Your mercy overcome my weaknesses in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"May, 2021"}},	
        {sn:16,	
        title: 'Mercy Is God’s Voice',	
        bibleRef: '“So then God’s choice is not dependent on human will, nor on human effort [the totality of human striving], but on God who shows mercy [to whomever He chooses—it is His sovereign gift]” (Romans 9:16 AMP).',	
        body:[ 'Mercy, a very powerful word! But what does it mean? The word mercy has been defined severally and one of its definitions is “unusual favour”. The synonyms of the word mercy also include leniency, clemency, compassion, grace, pity, charity, forgiveness, etc. I also found another definition of mercy which is: “Divine Partiality”. Partiality in itself means unfair bias in favour of one person or thing; it also means favouritism.',
            'Going by the definition of partiality, mercy therefore means Almighty God, i.e. “Divinity” deciding to break every protocol or rule because of one person for a group of people. There are numerous examples of such in the Bible.',
            'Apostle Paul referring to what God said to Moses, wrote in Romans 9:15 that: God says to Moses, "I will have mercy on whom I will have mercy, and I will have compassion on whom I will have compassion." This shows that Mercy of God does not require any qualification, it is not based on ones rights or any goodness of anyone. In fact, the Bible says no one is good except God. So, there is no good in any man to qualify him for mercy, but just divinity deciding to be partial towards one.',
            'When mercy is at work for someone, it wont matter whether he/she is the last to arrive, such a persons will just somehow be the first to be considered.',
            'When we look at mercy in terms of divine favouritism, human laws are overruled for the person for whom mercy operates. Mercy also operates as divine acceptance, so that the one operating under mercy will be the only one acceptable no matter the level of human rejection. When divine favouritism is in operation even when the one upon whom mercy operates has been ruled out and deleted from a list, divine mercy will suddenly appear and put the person’s name on the list and right at the top.',
            'When Mercy operates by way of divine interest, everybody will be interested in the person’s favour without explanation. When mercy shows up as unusual intervention; even when such a person’s case or matter has been decided and judgement sealed, something beyond man’s control will happen and such a person will walk home free, come out justified and in fact be celebrated; and every other human schisms will be overtaken by divine intervention.',
            'When mercy operates, somehow the one that is qualified by man’s ratings is removed for the one that man has adjudged unqualified. We can see examples of this in the life of Esther who by every human law and standard did not qualify to be in the palace; but even if somehow she found herself there, her position would have already been determined by her status; because she was one of the captive Jews and so would have just been a slave girl there.',
            'But because divine mercy was in operation for Esther, Vashti the Queen misbehaved and was removed to be replaced by Esther, and she occupied the Palace as a Queen (Esther 2:15-17).',
            'Brethren, have others gone ahead of you? There is no need for you as a child of God, bought with the precious blood of Jesus to fret because mercy is going to speak for you and you will be singled out before you know it.',
            'By the time Esther appeared before the King, divine mercy had gone ahead of her to prepare the way for her selection. And in the midst of all her competitors, Esther who was a mere slave girl was put in a class of her own; a divine class. She was favoured. The Bible records that: “...Esther also was taken into the king\'s palace and put in custody of Hegai, who had charge of the women...” (Esther 2:8).',
            'Divine partiality for Esther started with Hegai; the Bible says that “Hegai liked Esther and took a special interest in her. Right off, he started her beauty treatments, ordered special food, assigned her seven personal maids from the palace, and put her and her maids in the best rooms in the harem. Esther didn’t say anything about her family and racial background because Mordecai had told her not to (Esther 2:9-10). Esther did not ask or lobby for this favouritism, but divinity arranged all for her by mercy. After the order of Esther, I decree that the word of God in Isaiah 54:7 that “For a small moment have I forsaken thee; but with great mercies will I gather thee” shall begin to operate for us in Jesus name. No matter your unqualified status, no matter the politicking, today the Voice of God’s mercy shall be heard in your favour in Jesus name.',],
        pray: 'Heavenly Father, I thank You for Your Word; Lord, I thank You For Your Mercy towards mankind in the person of Jesus Christ. Today, Lord God, Let Your Mercy speak for me and my family in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"May, 2021"}},	
        {sn:17,	
        title: 'Words Are Seeds Sown',	
        bibleRef: '“Do not be deceived: God cannot be mocked. A man reaps what he sows” Galatians 6:7 NIV)',	
        body:[  'Everything that a man does daily is a seed being planted. There will always be the time of harvest. It is not just a natural law of sowing and reaping but the commandment of God that: “While the earth remains, seedtime and harvest...shall not cease." (Genesis 8:22). That being the case, the word of God also says: “A man shall eat well by the fruit of his mouth..” (Proverbs 13:2 NKJV). Fruits are by products of seed; therefore every word spoken is a seed. A typical example comes from the children of Israel when they were to go and possess the land of Canaan. The Bible recorded that twelve spies were sent to survey the land, out of which ten of them gave negative reports. But Joshua and Caleb gave a report based on their faith and trust in God.( Numbers 13:25-33). The negative reports led to the children of Israel grumbling against Moses and Aron and said: “...It would have been better to die in Egypt or even here in the wilderness; Why is the LORD taking us into that land? We will be killed in battle, and our wives and children will be captured. Wouldn\'t it be better to go back to Egypt?” So they said to one another, “Let\'s choose a leader and go back to Egypt”(Numbers 14:2-4 GNB).',
            'These were people who saw the miraculous deliverance of God at the Red Sea, yet because of the negative reports of the ten spies, their brains suddenly became dementia with no memory of how God had delivered them by His power; nor any memory of the power of God to deliver at all times. God therefore responded to them in Numbers 14:28 that: “As surely as I live, declares the Lord, I will do to you the very thing I heard you say” (Numbers 14:28 NIV).',
            'Brethren, our words are not just powerful and creative, but they are seeds. And when a seed is planted, there will be harvest some day. Today, there have been a lot of negatives going on around us. The question for us as believers is: What are we uttering from our mouths? Apostle Paul in his letter to the Galatians quoted in the main text of today started his sentence by the warning “Do not be deceived...a man reaps what he sows”. The wise king Solomon also says in proverbs 13:2 that man will eat the fruit of his mouth. Therefore, we must not think that we can just say anything and it won’t have any effect. Whether you are a believer or not, every man reaps the fruits of what he says.',
            'Brethren, what are you saying about your challenges? Be it personal, family, communal, national or the challenge of a race?  What is your confession? The children of Israel had a challenge caused by what the ten of the twelve spies said. The negative reports of the ten spies led to the negative murmuring of the children of Israel; and the result of God hearing what they said. My Brethren, what is God hearing you say over your present situation?',
            'Every time we speak, seeds are sown and must be harvested at some point. Therefore, in this season, what we, our family, or race are going through; our words must be what we truly desire. Let us constantly evaluate our words to make sure we are depositing good seeds into the soil of our lives and the lives of others, so that we can reap good harvests in the future.',
            'Remember what Jesus Christ said in John 6:63b that: “...the very words I have spoken to you are spirit and life.” Our words can also produce life not death because we are Christ’s and so must align our words with the Word of God no matter how bad, frightening, ugly or messy the situation may seem to be.',
            'Therefore, I charge us to deliberately plant the word of God in the soil of your life and the lives of your loved ones and your community. The good harvest shall be bountiful in Jesus name. Remember the words of the wise king Solomon in Proverbs 18:21 that death and life lies in the power of the tongue. Therefore, choose not to deceive yourself, rather choose to sow good seeds through your word and reap good harvest.',],
        pray: 'Heavenly Father, I thank You for this day and I thank You for Your word. Help me to sow good seeds with my word over my life, my family, my Nation and my race in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"May, 2021"}},	
        {sn:18,	
        title: 'God Will Give Victory',	
        bibleRef: '“Jehoshaphat led his troops back to Jerusalem in triumph, because the LORD had defeated their enemies.” (2 Chronicles 20:27 GNB)',
        body:[   'The Bible is replete with the diverse promises of God and its coming to pass in the lives of the children of God. One of such examples is what today’s text revealed - Jehoshaphat and his troop went back to Jerusalem triumphantly because God defeated their enemies. God will always give victory to His Children. However, victories from God are also, always the result of God’s children obeying His Word. The story behind today’s text is that God gave specific instructions to Israel and Judah for the battle. The instruction was that they would not have to fight the battle. They were just to take up their positions and wait...(vs.17). And while the people were still and waiting to hear from God, King Jehoshaphat bowed to worship God. All the people also bowed with him and they worshipped God. While they worshipped, the Levites (the male Jews who assist the Priests in worship in the Jewish temple) all stood up and with a loud shout, they praised the Lord God of Israel. (Vs.18-19).',
            'It should be noted that it was in their being still before God that they could truly worship God, while the king appointed some of them to march ahead of the army, singing the praise of God and saying: "Give thanks to the LORD, for his steadfast love endures forever".  This was a strange way to fight a battle. But the truth of the victory of the children of God lies in the praise of their God. There is power in praise. The Scripture says: “And when they began to sing and praise, the LORD set an ambushment against the men of Ammon, Moab, and Mount Seir, who had come against Judah, so that they were routed (confused)." (vs.22).',
            'My brothers and sisters, when God fights your battle, your enemy will be confused. In the confusion of the three enemies of the Israelites, they waged war against themselves. The men of Ammon and Moab first rose against the people of Mount Seir, ravaged them to destruction. Thereafter; when they had killed all the people of of Seir, they now helped to destroy one another. In other words, after Ammon and Moabites had killed the people of Seir, they faced each other and now killed one another. (Vs.23).',
            'As you wait on God and trust Him in this season of fasting and prayer; by His Might, every enemy of your life, your progress, your breakthrough and the enemy of the progress of your spouse and children will all help to destroy themselves in Jesus name. The Almighty God is the One that will fight your battle; and the only thing that you will need to do is to worship and praise Him.',
            'Coming back to Jehoshaphat, the Bible reveals that in the end, God did for the children of Israel what He had promised. That is: fight for them without their having to lift a finger in the battle. And God was praised. All the kingdoms of the earth knew that God is the one who delivers. By the same token, I pray for you that, no matter what your trials and challenges may be, no matter how fierce the battle is, as you remain still, listening for God’s instruction, obeying and trusting Him, your victory is sure.',
            'Brethren, I encourage you today to trust God. No matter the threats of the enemy over your life, family, clan, tribe, or race, the promise of God is that He will deliver you, He will not allow any evil or the enemy to overcome you because nothing can overcome God. God has specific instructions for each  battle as can be seen in the scriptures. All you need to do is to listen to God. The same God that spoke to the generation of the children of Israel coming out of Egypt in Exodus 14:13, has not changed and can never change. He is the same God that said to another generation of the same Israelites in 2 Chronicles 20:15 i.e. “Fear not, stand firm, and see the salvation of the LORD”.',
            'Brethren, God has the track record of deliverance for all His children that will trust Him. He has never disappointed anyone and you cannot be the first for Him to disappoint. But you have the part of trust to play all through your challenges, your trials, and even the the chaotic insecurity of this time. No matter what you hear people say; no matter what the world is screaming, all you need to do is to trust the word of God. Trust His promises as written in Psalm 46:1-3, 10-11; Psalm 91:7-10, 14 and many more. You are a victor and not a victim if you can listen to Him, trust and praise Him.',
            'Brethren, you have learnt from Jehoshaphat. The scripture says that whatever was written in former days happened to them as an example, but were written for our instruction, that by endurance and encouragement through the Scriptures we also might have hope. (See Romans 15:4 and 1 Corinthians 10:6, 11). Therefore, "Fear not, I am the one who helps you." (Isaiah 41:13) says the Lord. You will defeat every enemy of your peace and existence, and God will give you victory in Jesus name. Amen.',],
        pray: 'Heavenly Father I thank You for Your Word and I thank You for being the God over my battles; Lord I trust you and I thank You for being my help. Thank You Lord for fighting my present battle in your own way and I thank You for You will grant me victory in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Jun, 2021"}},	
        {sn:19,	
        title: 'The Victory in Christ',	
        bibleRef: '“But thanks be to God who gives us the victory through our Lord Jesus Christ!” (1 Corinthians 15:57 GNB).',	
        body:[    'Let us assume that an athlete was told convincingly that he/she had already won the race even before the race was started? How would that affect the athlete’s performance at the contest? I believe he/she would run the hardest to ensure that the victory a becomes reality. This is can be likened to what is called catch-22. (Catch-22 is a paradoxical situation from which an individual cannot escape because of contradictory rules or limitations). Examples are things like “I couldn\'t start my own business until I have got money and I couldn\'t get the money until I start my own business.”  In other words, you know that once you start your business, you will have  money; but you must have money to start the business. Also, like an establishment or corporate body will say you need experience to get a job with them; but you also have to work to get the experience. In other words, no matter how solid your ultimate victory is, you still have to do what is necessary to get it. This is often seen as weird, but that is what Jesus’ situation was like. He is God, He knew He was going to win, yet He had to play a certain way and do it perfectly in order to have the victory. And as we read in the book of Ephesians that being in Christ we have the victory, and it is the only way that there can be victory for humanity.',
            'The one that is in Christ is already predestined according to the purpose of Him who works all things according to the counsel of His will. Being in Christ, we are given the Holy Spirit, the guarantee or down payment of this inheritance until we possess it. In other words, with the power of the Holy Spirit, we have victory over sin and death through Jesus Christ, but we have to play by the rules. In other words, we must live our lives on God\'s principles. These principles are not just thou shall not steal, thou shall not lie; the principles are the embodiment of Christ in us and how it is exhibited in our relationship with Christ, in the things we do (i.e. our deeds, words or action) and also in our inactions.',
            'Paul therefore says: "Finally, brethren, whatsoever things are true, whatsoever things are honest, whatsoever things are just, whatsoever things are pure, whatsoever things are lovely, whatsoever things are of good report; if there be any virtue, and if there be any praise, think on these things" (Philippians 4:8).',
            'Are there things that we find ourselves doing that are contrary to the victory that we already have in Christ? We need to recognize what those things are, and drop them.',
            'This is the Catch-22 of the believer\'s life. We have victory through Jesus no doubt, but we must play by the rules so that we can live in that victory. I pray that our lives will manifest our victory in Him, so that we do not become sign posts that lead people in the right direction, but itself not heading to any destination. Amen. Thanks be to God who gives us the victory through our Lord Jesus Christ.',],
        pray: 'Heavenly Father, I thank You for this day, I thank You for Your Word and I thank You for the victory that I have through Jesus Christ. Holy Spirit, empower me to play by Your rules that I may manifest my victory in Christ in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Jun, 2021"}},	
        {sn:20,	
        title: 'Ask For Mercy Of God',	
        bibleRef: '“...I will have mercy on anyone I wish; I will take pity on anyone I wish.” So then, everything depends, not on what human beings want or do, but only on God\'s mercy.” (Romans 9:15-16 GNT)',	
        body:[  'The final plea that a lawyer makes before the Court when a defendant is convicted is usually to ask the Judge or Magistrae to “Temper Justice with Mercy”. In other words, to allow mercy to step down the weight of punishment. Often times it works. And, that is human court.',
            'With the Court of God, mercy can remove even capital punishment of the dark world because God has already placed our punishment on His Son; Jesus Christ. Hence the word says, there is therefore no condemnation to them who are in Christ (Romans 8:1)',
            'The Bible describes the mercy of God in the following five (5) ways:',
            '1.  God’s mercy is GREAT- (Isaiah 54:7). It speaks over every evil judgment and nothing can counter balance it.',
            '2.  The mercies of God are SURE. You can depend on it, it is an everlasting covenant sealed with the Blood of Jesus (Isaiah 55:3).',
            '3.  The mercy of God is ABUNDANT. (1 Peter 1:3), Apostle Peter says: God brought us into a live of hope by His abundant mercy through Jesus’ resurrection.',
            '4.  The mercies of God is TENDER (Psalm 25:6). The Psalmist says the tender mercy of God has been of old.',
            '5.  The mercies of God are NEW every morning (Lamentation 3:22-23). Therefore, God’s mercy can never be exhausted. As the day breaks there is a new level of God’s mercy. It does not ever fail. Without the mercy of God, man’s destiny will be completely messed up by the enemy. It was mercy that spoke for Lot, mercy spoke for Joseph, mercy spoke for David, mercy spoke for Apostle Paul, despite all that he did as Saul. Today, no matter what you are facing or have to face, mercy will speak for you in Jesus name.  ',
            'The only thing that gives meaning to life is the mercy of God. It is Divine. It is not dependent on man’s will or effort. It is not dependent on man’s qualification or otherwise. It does not depend on man’s connection or lack of it. Mercy is God Himself in action. Therefore when mercy speaks, protocols are set aside. Mercy is simply, Divine Partiality. In Luke 18:35-43, the Bible speaks of a blind beggar at Jericho, and how he received his sight. The blind beggar was always sitting by the roadside begging. But one faithful day, although he could not see, but he heard movements of a crowd and he asked what was happening. Then someone told him that "Jesus of Nazareth is passing by" (Vs.37). The Bible says that immediately, the man cried out, "Jesus, Son of David, have mercy on me!" (Vs.38). Although other people tried to shut him up, but He cried louder that: "Son of David, have mercy on me”. That cry for mercy made Jesus to stop. And that day, the blind man received His sight.',
            'Today, no matter how terrible your situation may be. You only need to shout to God for mercy. Also, no matter how simple or easy you think your own situation is, you still need to ask for mercy over the schemes of man. Remember that the enemy always tries to turn tables the negative way; and he just needs one man to yield to his evil suggestions. But if you can cry to God for mercy; the mercy of God will speak for you to rubbish every scheme of the enemy. As the Word of God says, nothing  in life is dependent on man’s effort or desire. It all depends on mercy. I pray that mercy will speak for you and speak for your children and loved ones in Jesus name. Amen.',],
        pray: 'Heavenly Father, I thank You for this day and I thank You for Your Word. Lord, above all things today, let Your mercy speak for me, my family and my loved ones in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Jun, 2021"}},	
        {sn:21,	
        title: 'Your Time To Reap All',	
        bibleRef: '“Your crops will be so plentiful that you will still be harvesting corn when it is time to pick grapes, and you will still be picking grapes when it is time to sow corn. You will have all that you want to eat, and you will live in safety in your land” (Leviticus 26:5 GNBUK).',	
        body:[ 'Probably you have been praying continuously and fervently over a particular situation or an issue in your life, and it appears like answers are not coming or as though God is not hearing you at all. Probably you have even claimed and confessed series of promises of God in the scriptures, but the situation remains unyielding and not willing to abate. Brethren, do do not despair. Our text today says: “Your crops will be so plentiful that you will still be harvesting corn when it is time to pick grapes, and you will still be picking grapes when it is time to sow corn. You will have all that you want to eat, and you will live in safety in your land”',
            'Your threshing season is your prayer season. And according to the Word, your harvest is going to overlap with grape harvest. If you can just believe God’s Word, He is going to accelerate His work in your life. And what seems to have taken days, weeks, months or even years, will be done for you in a moment. This means that, in a moment, your breakthrough will come forth; in an instant you will be promoted to your long deserved level; in an instant the miraculous conception will happen; in an instant there will be a divinely opened door of opportunities that will make your financial struggles a thing of the past; in an instant your child or ward will receive divine healing that only God will take the Glory. In an instant, the prodigal child will receive the mind of Christ and change for God’s glory. And while you are still rejoicing over one miracle, another one will be announced for you. Then, the word fullness of joy will, in an instant be you lot. And suddenly, your nickname will be JUDAH. Then, people will begin to seek after your God. So shall it be for you and I, in Jesus name.',
            'God is about to God take us further than we ever thought possible and faster than we ever thought possible. He has in His hands our times and season for glorious things to begin to fall head over heels for us by His own design. God is without any shadow of doubt working behind the scenes on our behalf and in our favour. Just as His Word has said, by Mercy, God will accelerate your season and thrust upon your life immeasurable blessings that He had prepared for you from when He created you in Jesus name.',
            'Brethren, when God issues the order for your season of manifestation of all your long awaited blessings and miracles, even the devil will have no option but to bow in obedience to God’s order issued on your behalf. Remember that Haman had to announce the lifting of Mordecai (Esther 6:6-14). That is how God glorifies His name in the lives of His children. And, It would not matter what is happening in Your locality, community or even nation. Remember also the biblical history of Isaac in the time of famine. There was terrible famine in the land, but in that same year, the Bible says that “Isaac planted crops in that land and the same year reaped a hundredfold, because the Lord blessed him” (Genesis 26:1, 12).',
            'God is not controlled by anything or anyone but He is in control of all things and everyone. And if He says to you that your crops will be so plentiful that “you will still be harvesting corn when it is time to pick grapes, and you will still be picking grapes when it will be time to sow corn. You will have all that you want to eat, and you will live in safety in your land”; you only have to believe Him and live right. His promises never fail. Your season of reaping is now. Hallelujah!',],
        pray:    'Father, I thank You for Your faithfulness in my life and I thank You for working behind the scenes on my behalf. I receive and believe Your Word that my season of reaping and change is here, thank You Father,  in Jesus’ name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Jul, 2021"}},	
        {sn:22,	
        title: 'God Will Perfect All',	
        bibleRef: '“The LORD will accomplish that which concerns me; Your [unwavering] lovingkindness, O LORD, endures forever. Do not abandon the works of Your own hands.” (Psalm 138:8 AMP)',	
        body:[    'To “accomplish” means to “achieve or complete successfully”. It also means “to finish something successfully”. The ESV Bible version of today’s Text says: “God will fulfil His plan.” Simply put: That which God has in plan for you will be perfected by Him; it will be total, thorough and real. Amen',
            'In the preceding verse of today’s text, (verse 7), we can see why the Psalmist made the confession that “God will accomplish what concerns him”. And the reason is that he recognises that in life there are troubles and daily we walk in the midst of trouble. Trouble is not the friend of any man, trouble can bring the life of a man to a halt or a total end. And so the Psalmist says that even though he “walks in the midst of trouble”, God will revive him. God will stretch out His hand against the wrath of the enemies, and God’s right hand will save him. This shall be your portion in Jesus name. Amen',
            'Brethren, it is a fact of life that man daily walks in midst of trouble. It is also a fact of life that there is the wrath of the enemy in operation. Man’s enemy being Satan. And this is why calamities do happen to people. But no calamity will come to us because God will perfect that which concerns us in Jesus name. Trouble means distressing or dangerous situation, and this can come to man as consequences of man’s own disobedience. Saul the king is a typical example. He was chosen by God, but his disobedience to God’s specific instruction led him into trouble; and this caused him his throne as king and also his life (1 Samuel Chapter 9 and chapter 15). Trouble can also come to man through the wrath of the enemy. This is the kind of trouble that comes by satanic wickedness which is borne out of envy. Trouble can also come through schemes of the enemy (Matthew 13:25). And it can also come when as children of God, we fail to apply knowledge and operate with wisdom. (Hosea 4:6).',
            'But, Psalm 138 is another promise of God to deliver; so that whether you are in trouble due to your own wrong doing, fault, mistake or disobedience like king Saul; or you are in trouble from the trap of the enemy; or probably just due to the wrath of the enemy borne out of envy or wicked anger; by reason of the covenant of the Blood of Jesus, God will revive you in Jesus name. God will stretch out His hand against the enemies and He will save you with His right hand. And, His perfection shall come into your life.',
            'Brethren, perfection is the original plan of God for man, and He will work out that perfection in your life and in your family and in the lives of your loved ones  in Jesus name. Every trouble comes to man because the life of man was corrupted by Satan. Thereby man keeps facing troubles, trials, tribulations, lack, limitations, dejection, rejection and diverse negative things. But no matter what the corruption is, God remains God if only we will turn to Him and call on Him. He says in Malachi 3:6 that',
            '"I am the LORD, and I do not change. That is why you descendants of Jacob are not already destroyed." Just run to God and your situation will receive Divine touch.',
            'The popular Hymn “What a Friend We Have In Jesus” says in its 3rd stanza thus:',
            '“Have we trials and temptations? Is there trouble anywhere? We should never be discouraged. Take it to the Lord in prayer.” We only need to  take it to Him and trust Him. This is why Apostle Paul also writes that: "Everyone who believes in him will not be put to shame." Romans 10:11. By God’s mercy, you will not be put to shame, and your testimony shall be great because God Almighty in His word has promised to perfect all that concerns you; and He will surely do as His word says.',],
        pray:  'Heavenly Father, I thank you for this day, I thank You for Your Word and I thank You Lord for perfecting that which concerns me and my family in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Jul, 2021"}},	
        {sn:23,	
        title: 'Emotional Maturity',	
        bibleRef: '“But none of these things move me; nor do I count my life dear to myself, so that I may finish my race with joy, and the ministry which I received from the Lord Jesus, to testify to the gospel of the grace of God” (Acts 20:24 NKJV).',	
        body:[    'It is quite interesting that Apostle Paul in all his travails, persecutions and negative reactions from people he preached to, did not concentrate so much on these challenges than he did on his prayer that God would give him the strength to go through each challenge with a good attitude. Apostle Paul sought emotional maturity. Emotional maturity is what every believer needs to pray to have. This is because, the time of challenge is the time that our level of emotional maturity will be made obvious. In other words, it is a period that how much it takes for one to get us upset will be visible. The wise King Solomon says: “If you are weak in a crisis, you are weak indeed” (Proverbs 24:10).',
            'What is emotional level? Emotion is simply one\'s feelings. It includes happiness, love, fear, anger, or hatred. And, emotional level simply is how long it takes for anyone’s character or behaviour  to be excessively affected by their feelings, particularly negative feelings. An adage goes that “A small pot boils the quickest.” Application of this will mean that if one does not get their emotional levels to that of Christ, one will always get stressed up at the slightest challenge and we will find ourselves making negative utterances and confessions and thereby making a mountain out of molehills.',
            'Parents usually loose their cool because their child or ward failed to do something right. Some people get aggravated because somebody didn’t do something they wanted them to do. And because of another man’s failure, they loose their own peace. When we keep doing all these as Christians, then we may not be passing the test of faith through challenges.',
            'Though as humans, we would tend not to be able to keep calm when people do things so awkwardly or irritatingly wrong. However, as Christians, we are not to allow our emotions to rule over us. This is why the Bible says that we should not worry about anything...(Philippians 4:6-7). There’s need for us to grow and mature to the point that we can say, like Apostle Paul said that: “None of these things move me.” Apostle Paul in effect said: “Because I trust God, it is no big deal. I will not worry about this because Jesus pointed out series of things for which He says we should : “Worry Not...” (Matthew 6:25-34). We all have a destinies to fulfil than to worry about what other people do or do not do to the extent of us getting us stressed out.',
            'People will certainly do things wrong. Your children will do things you don’t expect them to do, just as we also sometime get things wrong with God. Therefore, let us choose to begin to see things from God’s perspective, and ask for grace to be calm rather than get emotionally stressed. We must learn not to focus on other people’s flaws so that they do not get us stressed. People’s attitudes can really be irritating, particularly when we believe that they ought to know how to do it right. But if we look at such issues from the way we also do things that God’s Word says do not do, e.g. anger, resentment etc., but the Almighty God rather than throw us away, helps us to have a positive turn around and do the right thing, we also must give the people in our lives the chance to make amends, rather than getting so angered and resentful. We are to be Christlike.',
            'As believers, when we dwell so much on the faults of others, and get emotionally stressed up, we are really forgetting the work of the gospel in us. Apostle Paul said to the Christians in Corinth that: “Who made you superior to others?...” (1 Corinthians 4:7a GNBUK). The Holy Spirit will help us all to get to the level that Jesus set for us in Jesus name. By His mercy and power, our emotions will not get us to negative turns because of another person’s flaws, wrongs or shenanigans in Jesus name. Amen.',],
        pray :   'Heavenly Father, I thank You for doing a great work in my life, thank You for molding me and shaping me into Your image; Lord, thank You for helping me to stand strong and keep my emotions in check in Jesus’ name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Jul, 2021"}},	
        {sn:24,	
        title: 'God Does New Things',	
        bibleRef: '“However, as the scripture says, “What no one ever saw or heard, what no one ever thought could happen, is the very thing God prepared for those who love him.” (1 Corinthians 2:9 GNT)',
        body:[    'In our text today, the scripture says that the thing which eyes have not seen, nor ears heard or that has ever come to the mind of man is what God has prepared for those that love Him. The question now is: Do you love God? If your answer is yes! Then you are about to enter into the book of records of great and mighty things of God that had never been seen or heard nor ever came to the mind of man, happening in your life.',
            'The month of August is the 8th month of the year and the number 8 biblically means new beginning. Remember that God’s word also says there’s time for everything. So the season of the month of August is the time for new beginnings. May we have the grace to key into it in Jesus name. Amen.',
            'When God promise, He never fails. And, every time, God is doing new things in the lives of people. History has also revealed that on an August day in history, new things have put the names of some men in history permanently for good; in that spectacular things were done by them which had never been done by any man. Examples of which are:',
            '(1). Charles Guille who in the New York City on 2nd August 1819 made The first parachute jump from a balloon.  ',
            '(2).  On 2nd August 1820, John Tyndall, British physicist and the first scientist to show why the sky is blue was born to his parents.     ',
            '(3). On 2nd August, 1847 William A. Leidesdorff launches the first steam boat in San Francisco Bay.',
            'The names of these men have since remained permanently in history for good. The key to bringing to pass in our lives God’s promise to do the thing that eye has not seen, ears not heard nor coming to the mind of man is our obedience to His word as proof of our love for Him.',
            'What Prophet Joshua said to the Israelites on their way to breaking new grounds in the promised land also applies to us as children of God bought with the blood of Jesus. The Prophet wrote in Joshua 1:8 that we should be sure that the book of the Law (Bible) is always read. And that we study it day and night, and also obey it. So that we can be prosperous and have good success.',
            'Our Lord Jesus Christ capped it up by saying in John 14:15 that: “If ye love me, keep my commandments.” Keeping His commandments simply means obedience to His word.',
            'Do you desire a new thing in your life, your finances and even in your children in this month of new neginning? The key is to show God that you love Him by obeying His words in every area of your life. God will do that new thing in your life because God does new things for those that love Him.',],
        pray: 'Heavenly Father, I thank You for Your word. Lord, help me to walk in obedience to Your word as a proof of my love for You. Thank You for doing a new thing in my life and family henceforth in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Aug, 2021"}},	
        {sn:25,	
        title: 'Trust In The Lord',	
        bibleRef: '“Trust in and rely confidently on the Lord with all your heart And do not rely on your own insight or understanding. In all your ways know and acknowledge and recognize Him, And He will make your paths straight and smooth [removing obstacles that block your way]” (Proverbs 3:5-6 AMP).',	
        body:[    'The wise king says, in Proverbs 3:5 that we should trust in the LORD with all our hearts and that we must not lean not on our own understanding. And in verse 6, he adds that in all our ways we should acknowledge God, and God will make our paths straight. In the Scriptures, we have examples of what it means to “trust in the Lord with all your heart”. These include what Noah did, building a great big boat in the middle of dry land when there was no cloud in the sky. And despite all the ridicules of his neighbours, Noah continued in building the Ark just because God said so. Abraham also packed up everything he had, left his home land at the age of 75 because God told him to do so. In the New Testament, Apostle Peter stepped out of the boat to walk towards Jesus and walked on the water, because Jesus told him to do so also is proof of trusting in the Lord and not leaning on our own understanding.',
            'When an ordinary Christian declared that “Jesus is Lord” in a Roman Empire which only recognized one Lord whose name was Caesar; is proof of the trust of the Christian in the Lordship of and faith in Jesus.',
            'Trusting in the Lord means Faith in Him. And this will sometimes mean going out on one limb for God. The Psalmist also says in Psalm 37:3-5 that: “Trust in the LORD and do good; dwell in the land and enjoy safe pasture. Delight yourself in the LORD and he will give you the desires of your heart. Commit your way to the LORD; trust in him and he will act:” The proof that we are trusting in God to act is that, if He doesn’t act, we will fall flat on our faces.',
            'What have you done in the last week, or last month, or last year, which has depended for success or failure on the intervention of Almighty God? What have you done where the outcome would have been different depending on whether God actually existed or not? In fact most of the time we don’t  rely on God for what we do. We rely on our human understanding. We rely on our natural abilities and our skills and all our experience. We rely on our bank accounts which we believe are always there to fall back on if things don’t go as well as we planned.',
            'But the Prophet said in Jeremiah 9: 23-24 that: “This is what the LORD says: “Let not the wise man boast of his wisdom or the strong man boast of his strength or the rich man boast of his riches, but let him who boasts boast about this: that he understands and knows me, that I am the LORD, who exercises kindness, justice and righteousness on earth,',
            'for in these I delight,”',
            'We are trusting God with all our hearts when the only thing we have to boast about is not our wisdom or our strength or our riches, but simply that we know God and He knows us and cares for us. When we are delighting ourselves in the Lord, we receive the desires of our hearts.',
            'Sometimes we spend lots and lots of time and energy and thinking, trying to work out what we should be doing in our own lives and in the lives of our loved ones. In our relationships, or careers, or hobbies, or family life. We think about what we want and how we should get it. But, in all these areas of life, we need to learn how to trust God and let Him guide us. We also need to learn how not to lean on our own understanding.',
            'Brethren, even when facing challenges or oppositions, your opponent may be relying on some economic influence they believe that they have; or even at worse still, they may trust in some marine powers, witchcraft powers or other demonic powers. They may also rely on the power of their wealth and connection to men of authority and power. But all these will fail when God rises up in your defence because you are trusting in Him and Him alone. Therefore, brethren, whatever may be your challenge today, trust in the Lord.',],
        pray: 'Heavenly Father, thank You for Your Word. I trust in You and I know that in You there is no failure or disappointment but victory all the time in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Aug, 2021"}},	
        {sn:26,	
        title: 'You Are The Epistle',	
        bibleRef: '“You yourselves are the letter we have, written on our hearts for everyone to know and read.” (2 Corinthians 3:2 GNBUK)',
        body  :['Dictionary defines an Epistle as “a poem or other literary work in the form of a letter or series of letters”. Therefore, all the Epistles of the Apostle are letters which today we read to teach us the truth, to rebuke us when we are wrong, to correct our faults, and to give us instructions for right ways of living. (2 Timothy 3:16).',
            'The bible in several ways in the teachings of our Lord Jesus Christ has compared those who follow Him including today’s believers to many things. E.g. Jesus compared us to branches when He says in John 15:5 that: “I am the true vine, ye are the “branches”.  He also compared us to salt that: “Ye are the salt of the earth.” - Matthew 5:13. Jesus also compared us to light and said: “Ye are the light of the world.” - (Matthew 5:14). Jesus also compared us to sheep when He says “My sheep know my voice and a stranger will they not hear” in John 10:27.',
            'In our text today, Apostle Paul also compared us to Epistles i.e. letters. As stated earlier, an epistle as defined by the dictionary is a letter. Therefore, as a believer, you are a Letter and I am also a letter, written with the spirit of the living God.',
            'Every letter carries a message or some messages. Some letters are friendly, some are just letters to say “thank you”; other letters carry message of congratulations; some are letters of sympathy, some are love letters and and some are not good letters e.g. a sack letter or warning letters.',
            'So, there are many kinds of letters. But as believers, we are meant to be the living letters of Jesus Christ, carrying messages of good tidings of great joy unto this dying world.',
            'In our text, Apostle Paul said that the Corinthians are the letters that they have written on the heart of mean. In other words, other people who were not followers of Christ are going to read the lives of the Corinthian Christians and whatever they read is what will draw them to the God that these Christians worshipped or push them away from God.',
            'The same principle still applies in our world of today. People are no longer reading their Bibles. But you as a Christian is the Bible that all the people around you will read; whether you relate with them or not. They will read your behaviours and attitudes, your faith or lack of it, and take a decision to come to Christ or not.',
            'Every believer therefore needs to ask  himself/herself “what attitudes or behaviours am I exhibiting that is hindering the delivery of the message of Christ. Attitudes of selfishness, attitudes of non-commitment, attitudes of pride,  attitudes of I don’t like the way he spoke to me; attitudes of I don’t like the way she looked at me etc.  Unfortunately it is our attitude that determines our altitude. In other words, how high we can go in Christ depends on each person’s attitude towards Christ. If you have a low attitude you will have a low altitude. And so, your attitude or behaviour towards others also determine how many you are able to draw to Christ or push away from Christ.',
            'The Bible shows in several ways how God calls us to live a life of holiness; as we see in Romans 12:1, “Present your body as a living sacrifice, holy and acceptable unto God.” Ephesians 1:4 “We should be Holy and without Blame”. 1 Peter 2:5 “Be ye holy for I am holy”. All these are not only for our own sake but for the sake of those who will only read you and I and take a decision to do or not to do something. And if they read the wrong thing and take the wrong step, they may never have another opportunity to change their ways. If that happens, the Word of God says, it such a Christian that has caused those ones to fall. Jesus also reveals that: “It would be better for them to be thrown into the sea with a millstone tied around their neck than to cause one of these little ones to stumble.” (Luke 17:2). May that not be our portion in Jesus name.',
            'Today, the Word has come to us to check our ways and ensure that in our behaviours and attitudes we are not sending people away from God Almighty because we are the Epistle that they are reading daily.',],
        pray: 'Heavenly Father, I thank You for Your word to me, Lord help me to live my life in a way that my character and behaviours will bring people to Christ in Jesus  name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Aug, 2021"}},	
        {sn:27,	
        title: 'Blessed Ember Season',	
        bibleRef: '“He has made everything beautiful in its time. He has also set eternity in the human heart; yet no one can fathom what God has done from beginning to end.” (Ecclesiastes 3:11 NIV)',	
        body:[    'The Bible says God makes everything beautiful in its own time. Therefore this last season of the year comprising of September, October, November and December called the Ember months are of its own time. God says in Isaiah 41:10 that “Fear not, for I am with you; Be not dismayed, for I am your God...”. Also Jesus Christ says in Matthew 28:20 that behold I am with you always, therefore as children of God we are not alone as we journey to the end of this year and into the next glorious year. All we need to do is to plan with God because He already has us in mind and has already planned for our protection hence He says He will watch over our coming and going both now and forevermore” (Psalms 121:8).',
            'Therefore, no matter the insecurity in the land, because you are secured in Him, you are protected. Keep dwelling in His presence and there will be no fear of the terror of the night, nor any arrow of kidnappers that flies by day. Because God had already given His Angels charge to watch over us. He also says He will satisfy us with long life and show us His salvation (Psalms 91:1-16). Therefore plan every journey and every step and take action with God. Also, no matter how tough or hard the situation around us may be, we must believe that since God is with us, things are not just going to be good for us but will get better and best for us in Jesus name. Let us keep seeking Him and continue to declare to ourselves by faith every day that this will be my best season of the year.',
            'I pray that the angels of God assigned for our protection shall arise and defend us every day, guide us in all our ways, and bring us to the place of breakthrough, continued rest, and peace in Jesus name. We will never be involved in any form of accident, nor be victims of any kidnapping or assault by any demonic agents of darkness in Jesus name. There is no obstacle of failure for us and all our loved ones in Jesus name.',
            'It is written, “From now on let no one cause me trouble, for I bear on my body the marks of Jesus. (Galatians 6:17). Because the seal of Jesus is on us, no agent of the wicked one in the form of man or woman will be able to touch us or cause us any trouble.',
            'Just as the Bible says in Acts 27:22, that: “there will be no loss of life among you...”, I decree that no loss of any kind, be it material, physical or spiritual is permitted in any of our families in Jesus name. Because God’s word says in 1 Kings 2:3 that, "and keep the charge of the LORD your God, walking in his ways and keeping his statutes, his commandments, his rules...that you may prosper in all that you do and wherever you turn...” That will be our portion in this season. Because we will plan with God, whatever we put our hands to do in this season shall prosper much more than ever before and yield abundant profits in Jesus name. The only things permitted to follow us this season are goodness and mercy, because God’s Word says so. Therefore, we are disconnected from every evil network that says otherwise. As His word says: “surely goodness and mercy shall follow me all the days of my life: and I will dwell in the house of the LORD forever and ever, in Jesus name”(Psalm 23:6).',],
        pray: 'As we plan you Oh Lord God, victory is for us and all our family members and our loved ones all through the ember months and into the next year in Jesus mighty name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Sep, 2021"}},	
        {sn:28,	
        title: 'Divine Intervention',	
        bibleRef: '“Now there is in Jerusalem near the Sheep Gate a pool, which in Aramaic is called Bethesda and which is surrounded by five covered colonnades” (John 5:2 NIV).',
        body:[    'Situations or occasions do happen in the life of a man when there will be no help from fellow men either because they have become tired of helping the man or the situation is beyond human help.',
            'In Psalm 27:10 king David said: "When my father and mother forsake me then the Lord will take me up." This simply proves that father and mother may even forsake one.',
            'There are also situations where those that man has trusted to help or that have even promised to help have failed. This is also written by Prophet Isaiah in Isaiah 49:15-16 where God asked a question, "Is it possible for a woman to forget a sucking child? That she should not have compassion upon the child of her womb? And God answered and said, “Yes, she may forget.” Therefore people that even want to help one may forget or fail.',
            'The third situation is when it is beyond the ability of man to help as written in Isaiah 43:2 that: Almighty God says "when you are passing through the rivers I will be with you, when you are passing through the water it won’t overflow you. He said even when you are passing through fire; the fire will not burn you because I will be there by your side." It is a fact of life that no friend will open his/her eyes and with his/her right senses enter into fire with another friend, and so in such situations, nobody wants to help.',
            'The conclusion of the matter is that everyone, at some point in their lives would need divine intervention; and I pray that whatever may be our situation or circumstances today, God will intervene for us in Jesus name.',
            'The text of today taken from the story in the Book of John 5:1-15, about the man at the pool of Bethesda, needing healing in the pool. But there was no one to help him get into the pool as everyone was there for their own miracles too. However, when Jesus appeared, it was a divine intervention and his story changed.',
            'Also, in Luke 18:27 Jesus says that those things that are impossible with men are possible with God. Also in Luke 1:37 the Bible says that, with God all things are possible. Therefore, no matter what your situation may be, and no matter how long you have been in it, just like the man in the main text of today that has been at the pool for 38 years, there will be divine intervention for you in Jesus name because God in fact says in Jeremiah 32:17 that: "Behold, I am the God of all flesh, is there anything too hard for me?" All that you need to do is to answer the question and say over your situation that: No! Nothing! And so the situation is not hard for God to change.',
            'The areas that we may need divine intervention are: Our physical lives, our financial lives, and even our marital lives. The man at the pool of Bethesda in John 5:2-9, was in need of divine intervention for his physical life that had remained unchanged and without help for 38 yeas. There was no man ready to help him, but Jesus Christ, the helper of the helpless appeared in his situation. I pray that today, Jesus will divinely meet you at the points of your needs. All you need to do is call on Him and trust Him.',
            'In Matthew 17:14-18 when the disciples failed to help the man that brought his son to them for healing, Jesus did what the disciples were unable to do. In every way that man has failed, that even pastors have failed to help, God will divinely intervene on your behalf in Jesus name.',
            'There can also be need for financial help but no one is helping. This was what happened in the life of the widow in 2 Kings 4:1-7. She was the wife of a Prophet who had died, and so, she inherited her husband\'s debt, her two sons were going to be taken from her and sold in order to pay the debt. There was no one to help her. All the people around her had empty vessels which shows that even if they wanted to help they had no means. She ran to Elisha who in fact asked her "what do you want me to do?" (2 kings 4:2). But God divinely intervened. Is your situation  such that the people you are running to asking out the same question? God is saying to you today was that He will divinely intervene.',
            'In our marital lives, God can divinely intervene in that situation that has lasted so long and you are almost giving up. The story of Hannah in 1 Samuel 1:4-20 shows that she had a husband that loved her, but yet could not help her in her situation of childlessness. There is no doubt that her husband would have done all that he could because of his love for her, but human efforts failed Hannah. The fact that Peninnah (the second wife) had children is proof that  Hannah\'s inability to have children was not the fault of the husband. But when Hannah cried to God, there was divine intervention. Whatever may be the challenge in your marriage, the God that has solution to all problems of life will divinely intervene for you in Jesus name.  ',
            'Divine intervention is God in Himself bringing solution to the problems of man be it physical, financial or even marital; that has defied all human solution, or that no one wants to help with, or that the help of man has failed. Today, I pray that divine intervention will appear for you no matter the situation or how long it has been and your testings will suddenly become big testimonies in Jesus name.',],
        pray: 'Heavenly Father, I thank You for Your love and Your Power to divinely help me. Lord, I commit my situation (mention it) into your hand and I ask for divine intervention in Jesus name. Thank You Lord for answering me in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Sep, 2021"}},	
        {sn:29,	
        title: 'God Is Working Behind The Scene',	
        bibleRef: '"Your threshing will continue until grape harvest and the grape harvest will continue until planting, and you will eat all the food you want and live in safety in your land.” (Leviticus 26:5 NIV)',	
        body:[  'I believe firmly in the word of God, and as long as we put our hands to work and not just be waiting for manna from above, His word will certainly work for us in every aspect of our lives. Probably, you have been praying continuously and fervently over an issue or some issues of your life, and it seems that answers are not coming; you might have even claimed and confessed series of promises of God, but it appears the situation is refusing to yield; brethren, do not despair, look again at our main text today. It is His word for you.',
            'Your threshing is your prayer. The word says it will overlap with grape harvest. In this season, God will accelerate His work in our lives! What seems to have taken days, weeks, months or even years, God will do in an instant in this season. In an instant, you will receive your breakthrough. In an instant, you will be promoted to your next level, in an instant, the cry of your infant baby will again fill your home and joy will fill your heart like never before. In an instant, you will receive your healing. In an instant, you will receive divine supernatural financial breakthrough. In an instant, the prodigal child will receive the mind of Christ and change for God’s glory.',
            'Today, I believe that God will take us further than we ever thought possible, and faster than we ever can imagine. Our times are certainly in His hands and He is the God of His word and He is able to do all things. He is working behind the scenes on our behalf. Just like in the days of old, God keeps working behind the scene and He will accelerate our season and thrust us into the blessing He has prepared for us. Know for sure that when God\'s order for your season of change is issued, even the devil will obey and he will have no option but to bow to you God!',
            'Therefore, don’t be moved by what is happening around you. Only be moved by God’s word and keep your  threshing going because your time of harvest is just around the corner because God is working behind the scene.',],
        pray: 'Father, thank You for Your word and Your faithfulness in my life and I thank You for working behind the scenes on my behalf as to keeping my threshing going; my harvest will manifest and never cease again in Jesus’ name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Oct, 2021"}},	
        {sn:30,	
        title: 'Knowing Jesus',	
        bibleRef: '“All I want is to know Christ and to experience the power of his resurrection, to share in his sufferings and become like him in his death” (Philippians 3:10 GNB).',	
        body:[    'Apostle Paul in his letter to the Philippian Christians made the above profound statement. Before Paul’s conversion, he being a Jew, was a member of the Synagogue and as he speaks of his pedigree in Philippians 3:4-6, that he was a strict and devout adherent to God’s law; a defender of the purity of the Jewish religion. And this he did with so much passion to the point of persecuting the followers of Christ because he believed that the Christians were not observers of everything set down in God’s law. Also being a Pharisee, Paul was a very important member of the Synagogue. But when he got converted, he encountered a new life.',
            'And so, in our main text today, Paul said his desire was to know Jesus. This should be the desire of every believer. A lot of us Christians are still in the category that Paul was before his conversion. We go to church regularly, we observe the 30 days victory month fasting and prayer year in year out. In fact, very many of us are ardent observers of the Ten Commandments; we are well respected leaders in the church. In some cases, church decisions will not be taken in the absence of some of us because of how much such people’s opinions are respected. But for these people, all that they glory in is self righteousness. The Bible says that by strength shall no man prevail - 1 Samuel 2:19. The Bible says says it is not of works lest any man should boast - Ephesians 2:9',
            'To know Christ is not the same as knowing His historical life; it is not the same as knowing the correct doctrines regarding Jesus; it is not the same as knowing His moral exemplary life, and it is not the same as knowing His great work on our behalf. It is just as the Psalmist made a distinction between Moses’ relationship with God and that of the children of Israel with God in Psalm 103:7 that God made His ways known to Moses, but His works (miracles) to the people of Israel. So to know Jesus takes more than just to know the miracles he performed. This was the desire of Paul and should be the same for us as believers.',
            'Take for example, we can say that we know someone because we recognise him/her; because we can distinguish what is different about that person compared to other people. We can also say that we know someone because we are acquainted with what he does; ( like how the Israelites knew God). And just like we know the shoe cobbler because we take our shoes to him to mend etc.',
            'But beyond all these, there is a way of that we know Jesus Christ which also includes all of the other ways that we know people and also goes beyond those knowings. The way of knowing for every believer includes who Jesus is to you personally. Charles Spurgeon, the powerful Baptist preacher says of how he knows Christ when he wrote as follows: “They tell me he is a refiner, that he cleanses from spots; he has washed me in his precious blood, and to that extent I know him. They tell me that he clothes the naked; he hath covered me with a garment of righteousness, and to that extent I know him. They tell me that he is a breaker, and that he breaks fetters, he has set my soul at liberty, and therefore I know him. They tell me that he is a king and that he reigns over sin; he hath subdued my enemies beneath his feet, and I know him in that character. They tell me he is a shepherd: I know him for I am his sheep. They say he is a door: I have entered in through him, and I know him as a door. They say he is food: my spirit feeds on him as on the bread of heaven, and, therefore, I know him as such.”',
            'The Founder of Methodist Church had been a minister for some years, but there came a day in his life that he had a different encounter and while he was describing the change which God works in the heart through faith in Christ, he said: “I felt my heart strangely warmed. I felt I did trust in Christ, Christ alone for salvation; and an assurance was given me that He had taken away my sins, even mine, and saved me from the law of sin and death."',
            'Brethren, the question for us today is “Do we know Him?” Apostle Paul not only desired to know Jesus but also to know the power of His resurrection. To  Know Jesus means knowing this power, i.e. the new life that is imparted to us now, not when we die. We also need to know in an experiential way the power of Christ’s resurrection. To experience the same power that raised Christ from the dead in every situation of our lives as well. The power of His resurrection is an evidential power. It is the evidence and seal that everything Jesus did and said was true.The power of His resurrection is a justifying power. It is the receipt and proof that the sacrifice of the cross was accepted as payment in full. The power of His resurrection is a life-giving power which means that the believer who is connected with Jesus Christ receives the same resurrected life. The power of His resurrection is a consoling and comforting power. This is knowing Christ and the power of His resurrection. As believers, I pray that we will truly know Him.',],
        pray: 'Heavenly Father,  thank You for this day and thank You for giving us Jesus. Father, help us to truly know Jesus and the power of His resurrection in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Oct, 2021"}},	
        {sn:31,	
        title: 'Sharing in His Sufferings',	
        bibleRef: '"All I want is to know Christ and to experience the power of his resurrection, to share in his sufferings and become like him in his death” (Philippians 3:10 GNB).',	
        body:[    'Apostle Paul in his letter to the Philippian Christians talked of having the fellowship of the sufferings of Christ. Paul not only desired to know Christ, but also have a fellowship of Christ\'s sufferings. The GNB says “to share in His sufferings”. Knowing Jesus therefore also means knowing this fellowship of His sufferings. Jesus suffered that humanity can be reconciled to God through Him. Being children of God, believers and followers of Christ therefore makes that suffering of Christ part of our heritage. We must truly  be part of the family of suffering.',
            'In Romans 8:17, Apostle Paul made this point of the sufferings of Christ part of our heritage when he wrote that: “...if children, then heirs\' of God and fellow heirs with Christ, provided we suffer with Him in order that we may also be glorified with him.” What are those sufferings? They include being rejected because we would not bow to the world standard and all political correctness that contradicts the word of God, just as Christ was rejected even by the so called leaders of religion.',
            'His sufferings that we share in also includes daring to stand on the side of God even as Shadrach Meshack and Abednego did; even in the face of threat of death and they were bold to to king Nebuchadnezzar. Daniel 3:16-18 says: “Your threat means nothing to us. If you throw us in the fire, the God we serve can rescue us from your roaring furnace and anything else you might cook up, O king. But even if he doesn’t, it wouldn’t make a bit of difference, O king. We still wouldn’t serve your gods or worship the gold statue you set up” Daniel 3:16-18 (MSG).',
            'Today, we also have the story of Leah Shuaibu who was not released by Boko Haram because she would not deny the name of Jesus. That is the sharing in the sufferings of Christ.',
            'In the little corner of our professions, occupations, vocations, callings, jobs, employments, careers and businesses, how much are we willing to share in the sufferings of Christ by refusing to conform with the world?',
            'This was the desire of Apostle Paul who said of himself when he spoke about the real believers in Philippians 3:3-6. If we are more controlled by our impressive credentials and influences and would rather compromise Faith than loosing them, Apostle Paul then talks of his pedigree that: “though I myself have reason for confidence in the flesh also. If anyone else thinks he has reason for confidence in the flesh, I have more...” (vs.4). He goes on to say that he was circumcised on the eighth day, being an Israelite, that he is of the tribe of Benjamin, he is a Hebrew of Hebrews; and that when it comes to the law, he is a Pharisee. That he was so zealous about everything Jewish that he persecuted the church. And that when it comes to righteousness under the law, he was blameless. (Vs.5-6)',
            'He concluded by saying in verses 7-8 that: “The very credentials these people are waving around as something special, I’m tearing up and throwing out with the trash, along with everything else I used to take credit for. And why? Because of Christ. Yes, all the things I once thought were so important are gone from my life. Compared to the high privilege of knowing Christ Jesus as my Master, firsthand, everything I once thought I had going for me is insignificant—dog dung. I’ve dumped it all in the trash so that I could embrace Christ and be embraced by him. (MSG)',
            'That is the fellowship of His sufferings. Are we doing that as believers of today? I pray that we will truly be prepared to suffer for the sake of Him who suffered for us to be reconciled to God.',],
        pray: 'Heavenly Father, I thank You for this day and I thank You for giving us Jesus. Father, help us to truly be willing to share in His sufferings in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Oct, 2021"}},	
        {sn:32,	
        title: 'The Mindset of Christ',	
        bibleRef: '“In your relationships with one another, have the same mindset as Christ Jesus” (Philippians 2:5 NIV).',
        body:[    'The Word of God instructs us in 2 Corinthians 10:5 that we should take captive every thought in obedience to Christ. Our text today also says we must have Christ’s mind set. This simply means to transform our minds in such a way that we think the way Christ would think so that we can act the way Christ would act.',
            'Brethren, to transform any aspect of ones life, particularly our attitude and mindset that develop because of what another person does to us can sometimes be like an overwhelming task. This is because we are all humans and with human nature. But since it is what Our Heavenly Father expects of us, them it becomes a task that must be accomplished. The saying goes that:  “How do you eat an elephant? One bite at a time.” The way to  change our mindset is only with the help of God but, we must also have a determination to have a change; and so, on our part it will be by “one thought at a time”.',
            'Our thoughts set the direction of our lives. If we need to change the direction we are heading in or how we relate with a wicked or harsh boss, we simply need to change our thoughts in that area. God desires that we have good relationship with people and to have peace of mind at work. We may not please everyone, but we can strive to please God and this helps us in the way we relate with others with the mindset of Christ.',
            'Therefore, when you are oppressed by a wicked or harsh boss and thought like “this wicked boss wants to kill me” comes to your mind, it is the “one thought” that must be taken captive at a time and instantly cast it out by not allowing it to linger further. Not giving it a chance. And immediately also,  replacing such thoughts with what God’s Word says.',
            'When one is labouring under an oppressive and harsh boss, the tendency is for one to get negative due to the feelings of frustration; and this may lead to all manner of negative thoughts coming to one\'s mind. Such negative thoughts can be replaced with what God says in the scriptures like: “...None will make me afraid...” (Leviticus 26:6). “I will lie down unafraid, and many will look to me for help. ..” (Job 11:19). Those many that will look to us for help will end up including even the oppressive boss by reason of the power of the word of God. Because God can allow the boss  to find himself in a situation that only people at your level can help. Just like the story of the lion, the rat. As big and ferocious the lion is. One day he found himself caged in a mesh net. As powerful as the lion is, it could not help himself. But the rat was close by, and the rat offered to help. The tiny teeth of the small tiny rat was what was all that the rat used to help in  freeing the lion. The word of God is that powerful to turn things around.',
            'Brethren, evil abound in our world today and ungodly bosses have become more devious in their wickedness. Their wicked actions can also cause a person to have negative thoughts. But because we are children of God, we cannot allow their own wickedness to cause our thoughts to run afoul of what our Father expects of us. Therefore, we must encourage ourselves to be aggressive in taking captive all negative thoughts by the word of God. Having the mindset of Christ is also by the wisdom of God, as it pertains to dealing with wicked or harsh bosses. Therefore we must also pray for wisdom. It is one of the greatest things we need to pray for while working with harsh bosses. Wisdom is the main thing that Solomon prayed for right from the time He became the king. Solomon wanted to know how to rule wisely. So if we want to know how to handle or relate with ungodly harsh bosses; with the mindset of Christ in order to please and glorify God, then we need to ask Him for wisdom. May we all have God’s wisdom and Christ’s mindset in Jesus name.',],
        pray: 'Heavenly Father, I thank You for Your Word, I thank You for my work and for all the bosses. Lord, give wisdom to all your children working under wicked and harsh bosses on how to work with the mindset of Christ in Jesus’ name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Oct, 2021"}},	
        {sn:33,	
        title: 'The Proud Obstacles',	
        bibleRef: '“We destroy every proud obstacle that keeps people from knowing God. We capture their rebellious thoughts and teach them to obey Christ” (2 Corinthians 10:5 NLT).',	
        body:[    'The above Text is a revelation that there are obstacles in life that keeps people from knowing God. These obstacles are not mere stones or rocks but dark powers working against the thoughts of our minds. Hence Apostle Paul says that whatever is true, whatever is honorable, whatever is just, whatever is pure, whatever is lovely, commendable, of excellence, and worthy of praise, are the things we should think about (Philippians 4:8).',
            'Life generally is a battle, and if one is going to engage in wrestling, boxing, karate or judo, contest, there is no doubt that such persons will ensure that they are well prepared lest they go on a suicide mission. In the same manner, the battle of the mind is a serious battle and it is at this battle field (the mind) that proud obstacles raise themselves against the knowledge of God. When we do not have the knowledge of the power of God, we cannot trust Him. Just as if we don’t know the strength of a man and he tells you, don’t worry I’m right behind you, it will be difficult to trust such promises. But when you have the knowledge of that person\'s authority, might and power, there will be an unwavering confidence in you, borne out of firm trust in that authority. This is why the knowledge of God is very important in the life of every Christian. As such, Apostle Paul also wrote: “...that I might know Him and the Power of His Might...” Philippians 3:10.',
            'In our text today, Apostle Paul tells us to destroy (Pull down strongholds) and in this context, he is talking about the wrong thoughts and perceptions in a man that contradicts the true knowledge of God and the nature of God. He, in effect, tells us to take hold of our thoughts because the mind is the battlefield where Satan engages to control our actions, and once a man is conquered in his mind, he will be conquered in the physical. Therefore, all our thoughts must be brought into obedience of Christ. When our minds are in obedience to Christ, we will truly be like Him. This is why the Book of Proverbs says: “As a man thinks in his heart, so he is” ( Proverbs 23:7). If the mind is not obedient to Christ, it becomes proudly, and the Bible says: “Haughty eyes, a proud heart, and evil actions are all sin.” (Proverbs 21:4 NLT). Also the Almighty God opposes the proud (James 4:6). In order for us to destroy every proud obstacle and capture rebellious thoughts, we must abide in Christ. For He alone overcame the world. Hence He says: “...For apart from me you can do nothing.” (John 15:5)',
            'Our victory over every scheme of Satan comes when we have the knowledge of His word, and this can only be as we dig deeper into the scriptures. May we have the grace to capture every rebellious thought and thereby destroy every proud obstacle in our lives in Jesus name.',],
        pray: 'Heavenly Father, I thank You for this day and I thank You for Your word. Lord, I surrender all my thoughts to You, help me to feed my heart with Your word that I may be a victor in my thoughts in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Nov, 2021"}},	
        {sn:34,	
        title: 'Obey and Fulfill Righteousness',	
        bibleRef: '“Jesus says: “If anyone [really] loves Me, he will keep My word (teaching); and My Father will love him, and We will come to him and make Our dwelling place with him. One who does not [really] love Me does not keep My words. And the word (teaching) which you hear is not Mine, but is the Father’s who sent Me” (John 14:23-24 AMP).',	
        body:[    'A popular Children’s Sunday school choruses says "Obedience is the very best way to show that you believe and doing exactly what the Lord commands.” The truth is that we all know that we must obey God, but somehow we still act in disobedience and unfortunately, we often land in trouble as a result of our disobedience. Now, we all need to ask why, after knowing what God wants us to do, we still act otherwise. Is it that we don’t really understand the meaning of obedience or we forget how important it is to walk in obedience? Or do we need help on how to obey? These questions I believe every child of God need to ruminate over.',
            'Obedience means exactly as the Sunday school song puts it - doing exactly what we are commanded to do. And, as Christians, obedience is the hallmark of our salvation. Although we are saved by grace through the redemptive work of Christ and His blood. It is our obedience that sets us apart as authentic children of God. When we obey God, we show Him that we love Him. And through our obedience we are enabled to enjoy God’s love. But if we fail to obey God and we claim to be Christians, then we will be making God a liar to the unbelievers that will be judging the love of our God by what we go through. But when we walk in obedience, God reveals Himself to us, and it activates the power of God to work in our lives. And our lives become the testimony that draws others to Him. This is revealed in our text today as what Jesus requires of us.',
            'Brethren, the world system is completely against God’s word and standard. But we are not of this world, hence we must make a difference by our obedience. If we believe God and we love Him, then we must live in obedience to His word and commandments. As children of God who claim to love God, we must demonstrate that love by obeying His commands. God has to be able to truly count us as His Children through our obedience by aligning our daily lives and actions with His word as an act of our worship of Him. This is what brings blessings without any sorrow into our lives as revealed in Proverbs 10:22 that: “The blessing of the LORD makes rich, and he adds no sorrow with it” (Proverbs 10:22).',
            'Our obedience to God must also be revealed in our obedience to authority. Hence the Scripture says in Romans 13:1-2 that: “Let every person be subject to the governing authorities. For there is no authority except from God [granted by His permission and sanction], and those which exist have been put in place by God. Therefore whoever resists [governmental] authority resists the ordinance of God. And those who have resisted it will bring judgment (civil penalty) on themselves” (AMP). Obedience to authority is therefore evidence of our obedience to God, so that as Jesus said to John the Baptist:  Paul says: “Let it be so now; it is proper for us to do this to fulfill all righteousness” (Matthew 3:15 NIV). Our obedience to God and authority is the fulfilment of righteousness. And by so doing, we also disarm the enemy. May we all walk in obedience in Jesus name.',],
        pray: 'Heavenly Father, I thank You for Your word. Lord, help me to fulfill righteousness by walking in obedience to Your word in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Nov, 2021"}},	
        {sn:35,	
        title: 'Don’t Be Tired',	
        bibleRef: '“Even youths will become weak and tired, and young men will fall in exhaustion. But those who trust in the Lord will find new strength. They will soar high on wings like eagles. They will run and not grow weary. They will walk and not faint.” (Isaiah 40:30-31 NLT)',
        body:[    'When an ugly situations persist for too long, it is normal for humans in confusion, to look up and say “Lord I’m tired”. The confession of running out of energy, running out of solution to a problem that appears to be malignant can take the strength off any man. But the truth is, there is no problem that confronts us as children of God for which God does not have a solution. As it is commonly said that “What God cannot do does not exist”. And so, when a problem or issue persists, it’s time to fervently call on the creator Himself for divine solution.',
            'From the beginning of creation, every work of man was intended to be in partnership with God. The Almighty God knows that man by his effort will run out of energy, out of answers, out of resources and out of solution. This is not to say that God intentionally set man up to fail. No! Absolutely not! But, He set us up to learn to depend on Him and not on our strength for success.',
            'In our text today, the word of God says: “Even youths will become weak and tired, and young men will fall in exhaustion. But those who trust in the Lord will find new strength...” Usually, when one is physically tired out of exhaustion from a busy day, the way to regain strength is to rest. God shows this by the fact that He also rested on the seventh day. But because God knows that we will get to the stage of emotional exhaustion, He says, “those who trust in the Lord will find new strength.”',
            'Therefore when our situations get to the stage that we look up and about to throw our hands away in frustration and we look up in desperation, at that time, God says: “If you will just rest on Me”. Because the long standing situation is becoming a matter of the heart. But His Word has already gone ahead of such situations in Isaiah 26:3 that: “You keep him in perfect peace whose mind is stayed on you, because he trusts in you.” The Word of Christ also says: “Come to me, all you who are weary and burdened, and I will give you rest. Take my yoke upon you and learn from me, for I am gentle and humble in heart, and you will find rest for your souls. For my yoke is easy and my burden is light." (Matt 11:28-30 NIV)',
            'Whatever may be your long standing problem or issue, whatever may be the challenge; however long it has been tormenting you; there is solution to it with God, if only you can just rest your heart on Him. He will renew your inner strength until the solution manifests. And, you can never be a casualty. He is a Master at fulfilling dreams. Relax your grip on the situation and let Him have the complete grip. You will be amazed at the result. Therefore, don’t be tired.',],
        pray: 'Heavenly Father, I thank You for being my God and I thank You for Jesus. Lord, I remove my grip from my problems and I leave them to You, Thank You Lord for the peace of mind I have in You in Jesus name. Amen.',
        comments: [],
        details:{author:"Admin", date:"Nov, 2021"}},	
        	
        {sn:36,	
            title: 'The Power Of God’s Presence',	
            bibleRef: '“The Lord replied, “My Presence will go with you, and I will give you rest” Exodus 33:14 NIV).',
            body:[    'The Presence of God; what a great promise by the Almighty God. The New Living Translation puts it in these words: “I will personally go with you, Moses, and I will give you rest—everything will be fine for you”(NLT). The benefits and blessings of the presence of God is uncountable. Understanding this should give us peace over every challenge that confronts us.',
                'Let us consider a few of the benefits and blessings of that great gift of the presence of God:',
                '(1) Satan is automatically put in his rightful place as far as we are concerned. Therefore we must not fall for the distractions of Satan. Rather, we must focus on the awesome presence  of God that Satan cannot contend with and so he can only scare but can no longer bite us. Remember Joshua, Caleb and the remaining 10 spies in Joshua 14:6-7. The 10 spies were scared but Joshua and Caleb were not because they understood the power of God’s presence.',
                '(2) Peace of mind; just as the Almighty God said to Moses that: “...and everything will be fine" (NLT). Jesus Christ also declares: “I am leaving you with a gift—peace of mind and heart. And the peace I give is a gift the world cannot give. So don’t be troubled or afraid” John 14:27 NLT. Peace of mind is also guaranteed by the presence of God. We must not allow our minds to be troubled. Jesus knows that Satan will trouble our minds with unbelief, but we must not allow it. In the presence of God, there should be no unbelief because unbelief is not of God, and where God is, unbelief has to fail. James 1:6-7. In His presence is God Himself; and He cannot tell a lie. His Word says: “...everything will be fine for you” It just has to be fine. How it will be is not our concern, it is with Him, the Almighty.',
                '(3) The presence of God gives us protection. David reveals this in Psalm 91:3 that: "...He will rescue you from every trap...”. And in Psalm 136:24 that “...He freed us from our enemies...” because His love endures forever. Therefore, in the situation that we may find ourselves today, remember that because you are in Christ, you have the presence of God with you. No matter what the trap or snare of the enemy, His presence is bringing you out unscathed. With His presence, we can never be casualties because if He needs to destroy the agent that Satan is using to entrap us, God will do it. Hence the Psalmist in Psalm 136:17-21. Enjoy all the Benefits of the power of His presence today for your victory.',],
            pray: 'Heavenly Father, I thank You for Your word, I thank You for Your Presence with me according to Your Word and I thank You for the peace of mind and protection in Jesus name. Amen.',
            comments: [],
            details:{author:"Admin", date:"Dec, 2021"}},
        {sn:37,	
            title: 'Your Time Is Now',	
            bibleRef: '“You will arise and have compassion on Zion, For it is time to be gracious and show favor to her; Yes, the appointed time [the moment designated] has come” (Psalms 102:13 AMP).',	
            body:[    'Psalm 102 reveals that the Psalmist went through deep affliction that really overwhelmed him. He poured out his heart to God because he had steadfast confidence that God would act and show him mercy again. In verse 8-10 of this Psalm, in pouring out his heart to God, the Psalmist said even his enemies are mocking him day after day and cursed him; and that he ate ashes for food (i.e. mourning); and tears running down into his drink. That affliction was so deep that he concluded that the affliction was because of God’s anger and wrath against him. He even said God had picked him up and thrown him out.',
                'Brethren, affliction is another weapon that the enemy employs to oppress  the children of God. This is why the Preacher says in Ecclesiastes 7:7a that “Surely oppression makes a wise man mad...” Affliction comes in diverse ways, but no matter the affliction in your life, I pray that today, the power of God will arise and deliver you, because your set time is now in Jesus name.',
                'The affliction that the Psalmist faced came from more than just poor health his enemies also set against him; he was constantly rejected and disapproved and mocked. Thomas Hartwell Horne, a Bible Theologian of the 18th century explained verse 8 in the following words: “The scoffs and reproaches of men are generally added to the chastisements of God; or rather, perhaps are a part, and sometimes the bitterest part of them.” I pray that God will show us mercy in any affliction that has come to us because of God’s chastisement in Jesus name. This was why in 2 Samuel chapter 24:10-17, when God gave David three options of punishment for his sin, David said in Vs. 14 that: “...Let us fall into the hand of the LORD, for his mercy is great; but let me not fall into the hand of man." Charles Spurgeon, the great Baptist Preacher also explained Vs.10 to mean that the Psalmist felt that God was treating him as wrestlers treat one another, when a man deliberately lifts up his opponent in order that he may give him the worse fall. It is a fact that God would sometimes allow the enemy to afflict one by way of His punishment. Therefore, I pray that in anyway that we or our family is being afflicted as a result of our own wrong doing, God will show us mercy now according to verse 13 in Jesus name.',
                'The Psalmist recognises that no matter how far a man has gone in his infraction against God’s word, the Almighty God endures forever in remembrance of His name to all generations. We too can turn to God and ask Him in remembrance of His name to show us mercy. Because no matter the numbers of days of man’s affliction, God\'s mercy endures forever waiting for man to repent. And this is why the Psalmist can reject every self-reliance and held on to true reliance on God and calling on Him to remember His name to all generations and when the victory comes, His name will be declared. And as seen in the main text of today, the Psalmist confesses that God will arise and have mercy on Zion. Even though, he was in deep affliction, the Psalmist had steadfast confidence that God would act and show mercy once again. And that the set time has come. For everything that we ask from God, be it breakthrough, deliverance or healing, God has a set time for us. He has a set time for our restoration and so He would not forever leave us in desolation.',
                'Brethren, just as there was an appointed time for the Jews in Babylon, and when the weeks were fulfilled no bars could hold them any longer in captivity. In the same  way, Jesus has already paid the ransom for our breakthroughs, deliverance and healing; and so nothing can hold us down anymore. This is why we can declare that no matter how long the affliction has lasted, according to the Word of God, the set time is now, because God will arise and have compassion on us. Rise up today and declare your freedom from affliction by reason of the ransom paid by Jesus. Your set time is now. Hallelujah!',],
            pray: 'Heavenly Father, I thank You for Your Word. I declare that my set time is now for you to favour me in Jesus name. Amen.',
            comments: [],
            details:{author:"Admin", date:"Dec, 2021"}},
        {sn:38,	
            title: 'Unwrap Your Gift',	
            bibleRef: '"He who did not spare his own Son, but gave him up for us all—how will he not also, along with him, graciously give us all things?"(Romans 8:32 NIV)',	
            body:[ 'A gift is usually something you did not purchase with your money, something you did not work for, something which the giver spent quality time to buy, wraps or packages beautifully and presents to you. A gift is something which the giver thinks about before buying, considers it to be precious, of good quality and valuable. It is something that first comes from the heart because the giver thinks of you before and at the time he/she buys it. However, no matter how precious or expensive the gift may be; no matter the good intention of the giver, the gift is not complete until it is received by the recipient and the value is not appreciated until it is unwrapped. And it is also after it has been unwrapped and appreciated that the recipient derives pleasure from: (1) the fact of being loved and appreciated, (2) that the gift is now his/her own priced possession.',
                'A gift can also be something you have  been given the opportunity to choose and tell the giver this is what I want for my birthday or graduation or even Christmas. But even though you specifically asked for that particular thing as gift, and even at that, you still have to unwrap, before it can truly be appreciated and get the satisfaction of “yes, I got what I wanted.” Whether the gift is what we ask for or what the giver decides to present as a gift, on getting to the recipient it will be unwrapped. But leaving our gifts under the Christmas tree or just somewhere in the corner of our room would not do the us any good because we would most likely never appreciate the gift and the giver unless we take the time to unwrap it.',
                'In the same manner, Jesus Christ is the gift from the Almighty God to man, and wrapped up in Him are the good things of life. The scripture says: His Divine Power has granted to us all things that pertain to life and godliness...” (2 Peter 1:3 ESV). God’s gifts for us are all there, wrapped up in His Word. His Word is Jesus Christ (John 1:1). We receive Christ as gift from God when we accept Jesus as our Lord and Saviour. However, we “unwrap” Him on daily basis as we meditate on the Word (Jesus) and obey what He commands. Just like the gifts we receive during celebrations; it is one thing to unwrap them, but another thing to appreciate it by turning it to our priced possession that we admire and make use of for our pleasure. For instance, a book is given to us for birthday or graduation, we not only unwrap it, we read and keep reading it; same for any other material thing we receive as gifts. And anytime we take the gift, our hearts go to the giver.',
                'Today, we thank God that we have accepted Jesus as our Lord and Saviour; we praise His Holy Name, but the question is, how well are we enjoying the benefit of everything that pertains to life, wrapped up in Him for us. How well do we daily meditate and live by Him to enjoy Him.',
                'But if you have not received this gift, today is another chance. Call Him into Your life to be your Lord and Saviour, and begin to daily unwrap Him through His Word. Don’t allow a single day to pass without going into His Word so you can live live by it. For, in His Word we have  good health, peace of mind, victory, provision, protection, prosperity, promotion, long life etc. This is what Jesus Christ means when He says, He has come to give us abundant life. Jesus is the precious gift of God to us. May you receive the grace to daily appreciate the precious gift.',],
            pray: 'Heavenly Father, today I receive every good and perfect promise You have for me. I choose to take a step of faith to activate Your Word in my life. Teach me, by Your Spirit, to walk and live according to Your precepts in Jesus’ name. Amen.',
            comments: [],
            details:{author:"Admin", date:"Dec, 2021"}},		
        {sn:39,	
            title: 'You Will Be Congratulated',	
            bibleRef: '“Ask, and you will receive; seek, and you will find; knock, and the door will be opened to you. For everyone who asks will receive, and anyone who seeks will find, and the door will be opened to those who knock” (Matthew 7:7-8 GNB).',	
            body:[    'Countless of stories are told of answered prayers revealing testimonies of diseases cured, illnesses healed, forgiveness granted, relationships restored, hungry children fed, great deliverance experienced, bills paid, debts cancelled and lives and souls saved through the efficacy of prayer. So, we do have real proofs that God answers prayer. But because most of these evidences are personal; many who think of “evidence” as being only observable, measurable and reproducible proofs cannot understand how faith people live by the notion that God answers prayers. The truth however remains that God answers to your prayers.',
                'The main text of today is Jesus’ word and this is the same thing that the Psalmist revealed in Psalm 50:15 where God says “call on me and I will answer.” Brethren, God is God, He is not dependent on anyone. If He says it, surely He will do it. As long as you are able to pray to Him and you believe in His ability to answer your prayer. This is why the writer of the Book of Hebrews says: “...for he who comes to God must believe that he exists, and that he is a rewarder of those who seek him (Hebrews 11:6). If God says “call on me...I will answer” and Jesus Christ also says “Ask and you will receive”; brethren, that thing which you have asked in prayer is a done deal. Every prayer you pray in this victory month will equally be answered. Whatever the enemy tries to show you after you have prayed is a distraction of the enemy that must be ignored; look on God’s word and not what the enemy is showing you. Therefore, as we wait for His answer to our prayers, He will surely answer us. Because His word shall not return to Him void” (Isaiah 55:11). This means His promise to answer our prayers will never return to Him unfulfilled. And nothing can change it. Hence, John in his letter explained that: “...whatever we ask we receive from him, because we keep His commandments and do what pleases Him (1 John 3:22). That which pleases God is your faith. Because our faith is the proof that we know that He cannot lie, we know that He does not fail in His promises; we know that since He gave us Jesus, there is nothing He would not give. And so, our faith pleases Him and it brings forth answers to our answers. (Romans 8:32).',
                'Because God answers prayers, we see that: (1) Elijah prayed for fire from heaven and it came down in 2 Kings 1:12; (2), Hezekiah prayed for deliverance and He received it in 2 Kings 19:19-20; (3) the Apostles prayed for boldness and they were emboldened in Acts 4:29; (4) Gideon prayed to see signs and God showed him signs as recorded in Judges 6:33-40; (5) Our Lord Jesus Christ prayed and gave thanks to God and called Lazarus from death to life and Lazarus came forth (John 11:38-44). Jesus also healed diverse of diseases and infirmities by calling on God. The Bible says that these examples were written by eyewitnesses to the events, they are therefore clear evidence of answered prayers (1 Corinthians 10:11).',
                'Brethren, whatever you have been looking onto God for in the past year, trust Him to answer in this victory month of fasting and prayer, He has you on His agenda because you dared to ask Him, He will surely do it. Remember that He is your creator and He will do it earlier than you expected to give you perfect peace. Trust God, He answers prayers and He will surely answer yours. And, because you trust God and wait on Him alone this season, the time for your favour is now (Psalms 102:13). Hallelujah!',],
            pray: 'Heavenly Father, thank You once more for this new year and the beginning of new things in my life. Thank You Lord, for it is time for manifestation of the long awaited answers to my specific requests in prayers in Jesus\' mighty name. Amen.',
            comments: [],
            details:{author:"Admin", date:"Jan, 2022"}},              
        
        {sn:40,	
            title: 'God’s Honour and Glory',	
            bibleRef: '“As for Me...I will be glorified and honored through Pharaoh and all his army, and his war-chariots and his horsemen” (Exodus 14:17 AMP).',	
            body:[    'Honour is defined as “high respect; or great esteem” while one of the definitions of glory is “magnificence or great beauty”. The plan of the Almighty God is for His Children to have honour over their enemies. When God does this, His name is honoured and glorified and the lives of His Children are beautified.',
                'Because of disobedience, honour and glory are part of the things that Satan stole from man, but Jesus took it from the enemy and gave it back to man. And, as believers, even when an unbeliever is in authority, we must continue to keep giving honour to God the author of all positions for allowing such persons to be in power, even though they do not deserve the position. Jesus said to Pilate: “...You would have no authority over Me, unless it had been given you from above; for this reason he who delivered Me to you has the greater sin" (John 19:11). Also, the Psalmist says “For not from the east, nor from the west, nor from the desert comes exaltation...” (Psalms 75:6 AMP). When an ungodly person is in power, God knows about it and He will glorify Himself in the lives of His own children who are under such ungodly leaders. As we keep glorifying God despite the wickedness of the ungodly person in power, the appointed time of God will manifest to show forth His power over such wicked authority; just as it was with Pharaoh. His Word says “... the vision awaits its appointed time; it hastens to the end, it will not lie. If it seems slow, wait for it; it will surely come; it will not delay (Habakkuk 2:3). God promised to deliver the children of Israel from slavery and one day, the appointed time came and Pharaoh and all his power was buried in the Red Sea. Therefore, no matter the situation around you, keep giving honour to God who has the ultimate power.',
                '“The Almighty God declares that “.. those who honor Me I will honor, and those who despise Me will be insignificant and contemptible” (1 Samuel 2:30). The wicked man in power does not honour God, and certainly God’s word must be fulfilled that such persons are insignificant and contemptible. So let the believer keep honouring God. Daniel, Shadrach, Meshach and Abednego all honoured God, despite the wicked man in power, and  in the end, they, i.e. Shadrach, Meshach and Abednego were promoted because God must be honoured in the lives of His children.',
                'Brethren, the inheritance is for us as believers to be honoured, therefore as we honour God in an uncomfortable situation or under an ungodly authority, He will honour Himself in our lives. God will glorify His name in our lives even through what the enemy thinks he is using to victimise us and the honour and glory of God will manifest in our lives for the world around us to see.',],
            pray: 'Heavenly Father, I thank You for this day and I thank You for Your Word. Lord Jesus, thank You for receiving Honour and Glory for me and I choose to live by Your Word so that in all that I do, You will be honoured and glorified in my life my life in Jesus name. Amen.',
            comments: [],
            details:{author:"Admin", date:"Jan, 2022"}},
        {sn:41,	
            title: 'Our Words Must Build',	
            bibleRef: '“Don’t use foul or abusive language. Let everything you say be good and helpful, so that your words will be an encouragement to those who hear them” (Ephesians 4:29 NLT).',
            body:[    'The words we speak are life. It is always backed up by the power of creation because everything that God made was brought into manifestation by God’s spoken word. This is why we must avoid negative words. Spoken words last long in the memory of people than the physical things we do for them. If we all cast our minds back on the memory lane to our teenage years, or former workplace or some family feuds in the past, while one may not remember who said a particular good thing, but people will remember certain things that people have said that were very bad or demeaning. And any negative word spoken to us directly and in careless ways are never completely forgotten. Somehow those words keep lingering in ones memory. This is why Apostle Paul advised that we speak with grace and  season our words with salt (Colossians 4:6).',
                'Therefore, we must remember that the tongue is a powerful tool which must be handled with great care in order not to throw it in a way that it destroys the one it lands on. What we are expected to do are contained in these words of advice:',
                '1. Know the power of your word as a tool.',
                '2. Make sure that you Keep guards in place on your word so that it does not come out any how and hurt others.',
                '3. Be careful using your word around children because they won’t forget easily.',
                '4. Keep idle (unprofitable) words locked up permanently.',
                '5. Never use your word in an explosive atmosphere (i.e. in heat of passion or anger); rather keep quiet.',
                'The lesson from today\'s test is that unprofitable and destructive words last longer in the minds of people it is spoken to. Therefore, to avoid keeping in the memory of our loved ones and friends words that could never build or repair, let us take to the advice of Apostle  Paul by using our words as encouragement to those who hear us.',],
            pray: 'Lord, thank You for this day and thank You for Your Word. Lord, help me to use my words always to build in Jesus name. Amen.',
            comments: [],
            details:{author:"Admin", date:"Jan, 2022"}},
        {sn:42,	
            title: 'All Will Work for Our Good',	
            bibleRef: '“And we know [with great confidence] that God [who is deeply concerned about us] causes all things to work together [as a plan] for good for those who love God, to those who are called according to His plan and purpose.” (Romans 8:28 AMP)',
            body:['God’s plan is to take every adversity and every hardship we go through and use it for our good. God is not just going to fold His Arms and allow the enemy to beat us down and make our lives miserable. No! Not our loving and caring God. His plan is to take the difficult situation and supernaturally turn it to the raw material for our good. He uses the tough times that we find ourselves in to bring us out stronger in faith, more matured, more blessed and prepared for greater promotion, more goodness and exhibition of His unfailing love. These are God’s good plans for those that have been redeemed by the blood of Jesus.',
                'Brethren, what are you going through? No doubt, you may not understand everything that is going on in your life; you may not understand why you have to go through such things; you might even claim to have been doing all that is within your power to live right, just as the scripture says of Zachariah and Elizabeth that: “They both were righteous (approved) in the sight of God, walking blamelessly in all the commandments and requirements of the Lord. But they were childless...” (Luke 1:6-7 AMP). But one thing is as sure and certain as day will follow night; God is working something out for your blessing and for His Glory.',
                'Whatever might be your challenge today or the disappointment of yesterday, just keep being faithful. Keep doing the right thing and keep trusting God; knowing that in the end God is going to turn things around in your favour. Remember that as long as God is for us, no one can be against us and go free. Remember the story of Mordecai and Haman. The humiliation and threat to the life of Mordecai and all the Jews became the raw material for Mordecai’s promotion and the enlargement of the Jews in foreign land. (Esther 10). Also remember the story of Lazarus that had been dead and buried for four days, but when the Lord of life Himself appeared, the mourning songs of his family changed to songs of celebration and rejoicing (John 11).',
                'The word of God for us today is, we must not despair, in all, we are more than conquerors because God is working things out for our ultimate good.',],
            pray: 'Heavenly Father, I thank You for this day and I thank You for Your Word. Lord, I thank You for working things out for my ultimate good and the good of my family in Jesus name. Amen.',
            comments: [],
            details:{author:"Admin", date:"Jan, 2022"}},
        
        {sn:43,	
            title: 'God’s Open Option',	
            bibleRef: '“But if you refuse to serve the Lord, then choose today whom you will serve. Would you prefer the gods your ancestors served beyond the Euphrates? Or will it be the gods of the Amorites in whose land you now live? But as for me and my family, we will serve the Lord” (Joshua 24:15 NLT). “Then Elijah stood in front of them and said, “How much longer will you waver, hobbling between two opinions? If the Lord is God, follow him! But if Baal is God, then follow him!” But the people were completely silent” (1 Kings 18:21 NLT).',
            body:[    'Open option is simply freedom to choose. Without freedom of choice obedience is not true obedience but compulsion. We therefore cannot truly claim to be obedient and neither can one be accused of disobedience when there is no option of choice. In our texts today, Joshua told the people to choose whom they will serve. But he already made his choice to serve the Lord. We see another example where the prophet Elijah asked the people: “How much longer will you waver, hobbling between two opinions? If the Lord is God, follow him!” The Bible records that nobody answered him. The reason is that they could not make up their minds on the choice to follow God or Baal?',
                'From the start of humanity as seen in the Garden of Eden, Adam and Eve had freedom to choose. This is because if God was not giving them the option to choose between obedience and disobedience, God wouldn’t have put the tree of life in a reachable position for them. God is God, He knew they will be tempted, with the forbidden fruit, He however gave them the option to choose to obey without being compelled but out of love and reverence for God, or do otherwise (Genesis 2:17). The first humans blew the freedom to choose and today man continues to do the same until when one totally commits Himself to God through Christ, the only one who is worthy.',
                'In the story of Elijah and the people of Israel, in 1 Kings 18:21, the Bible describes a crucial moment of decision. It was the final showdown between the God of Israel and a false god called Baal. And so Elijah calls God’s people to choose once and for all between the living God who delivered them, and the false god who has captured their affections. But the people seemed unable or unwilling to make a choice. They want to hedge their bets, sit on the fence, and keep their options open. Joshua also chose to obey God alone in serving Him.',
                'Brethren, what challenges are you confronted with that Satan is suggesting the other option to you? Let us not be like the Israelites in the time of Elijah, who wanted to be on the fence. That itself is indirect rejection of God. The enemy will always make the other option to look good, attractive or rewarding, but the wise king Solomon says of such that: “There is a path before each person that seems right, but it ends in death” (Proverbs 14:12 NLT). We will not be destroyed in Jesus name.',
                'Brethren, to refuse to choose obedience to God and allow Him to be master over your life. The other option is to choose slavery to sin. This is what Apostle Paul pointed out in his letter to Titus in Titus 3:3 that: “For we also once were foolish ourselves, disobedient, deceived, enslaved to various lusts and pleasures, spending our life in malice and envy, hateful, hating one another.” (NASB). The two options i.e. obedience or disobedience have their consequences; and a lot of time, we find ourselves suffering under the consequences of those choices we have made in life that were not in line with obedience to the Word of God. ( See Deuteronomy 30:15-20). May we always have the Grace to choose right through the Power of Christ who used Himself as an example of obedience and says: “If you keep my commandments, you will abide in my love, just as I have kept my Father\'s commandments and abide in his love (John 15:10).',
                'Today, let us choose to walk in obedience continually and we will easily appropriate into our lives all the blessings that He has prepared for us. The open option of choice to obey is good because God desires that whoever comes to Him comes whole heartedly and not by compulsion. May we willingly choose to obey God in all our ways in Jesus name.',],
            Pray: 'Heavenly Father, thank You for the option given to me to choose; Lord help me to always choose right in all things and in accordance to Your Word in Jesus name. Amen.',
            comments: [],
            details:{author:"Admin", date:"Feb, 2022"}},
        {sn:44,	
            title: 'Trust God! We Can Trust Him',	
            bibleRef: '“For all of God’s promises have been fulfilled in Christ with a resounding “Yes!” And through Christ, our “Amen” (which means “Yes”) ascends to God for his glory. It is God who enables us, along with you, to stand firm for Christ. He has commissioned us, and he has identified us as his own by placing the Holy Spirit in our hearts as the first instalment that guarantees everything he has promised us” (2 Corinthians 1:20-22 NLT).',	
            body:[    'When man speaks, they say: “trust me”. If man can say trust me, when in fact God warns us from trusting man, because man can change like weather, how can we not completely trust God. We are not put our trust in a man because the world that we live in is a world of broken promises. It is common thing to find politicians make all manner of promises when seeking elective offices only to turn away from what they promised as soon as they get there. We have also seen that marriages end over trivial disputes. Siblings turn away from each other; friends betray themselves etc.; and people’s lives have been shattered as a result of broken promises. In Numbers 23:19, Balam declares to Balak that: “God is not a man, so he does not lie. He is not human, so he does not change his mind. Has he ever spoken and failed to act? Has he ever promised and not carried it through?” (Numbers 23:19 NLT).',
                'Trust also means the “firm belief in the reliability, truth, or ability of someone or something.” It is only God that can be so trusted. His promises are based on Himself and His unfailing love for man. This is why the writer of the Book of Hebrews says that: “So God has given both his promise and his oath. These two things are unchangeable because it is impossible for God to lie. Therefore, we who have fled to him for refuge can have great confidence as we hold to the hope that lies before us” (Hebrews 6:18 NLT). The text of today reveals that the Holy Spirit that God fills us with is God’s first instalment that guarantees everything He has promised us. The Holy Spirit is the Spirit of God. As such God gives Himself as the guarantee for His promises.',
                'Brethren, is there anything that is of any challenge in your life and you have done all that is within your power, and man has even failed you, as they would. It is time to turn it to God and trust Him. Take time to look into the scriptures for His Word that meets your situation. Stay on the Word, claim it and keep trusting God. He cannot fail. He has never failed. He says: "I am the Lord, and I do not change. That is why you descendants of Jacob are not already destroyed” (Malachi 3:6 NLT). Brethren, the same promise is for us today through Jesus Christ\'s redemptive work. As written in Galatians 3:29 that:  “...if you are Christ\'s, then you are Abraham\'s offspring, heirs according to promise.” Therefore tell all to God today and Trust Him. That which the enemy intends for your destruction will fail because it failed from the moment Jesus was substituted for you on the cross of Calvary.',
                'Therefore, trust God because the Holy Spirit is the first instalment that guarantees everything He has promised us. On this holy day, begin to express your love for God by your firm trust in Him. He alone can be trusted.',],
            pray: 'Heavenly Father, I thank You for this day and I thank You for Your Word. Lord, thank You for I can trust You for everything and I trust You Lord God in Jesus name. Amen.',
            comments: [],
            details:{author:"Admin", date:"Feb, 2022"}},	
        {sn:45,	
            title: 'How does God Sees Your Heart',	
            bibleRef: '“Jesus saw Nathanael coming toward him and said of him, "Behold, an Israelite indeed, in whom there is no deceit!" (John 1:47 ASV).',	
            body:[    'In John 1:43-51, we read about Nathaniel and how Philip found him and told him about Jesus. When Philip called Nathaniel about Jesus of Nazareth, we see that Nathaniel’s response was a sceptic one. A kind of scepticism which can be interpreted as doubt.  This interpretation is deduced from how Philip responded that: “if you doubt, you need to come and see”. Philip was saying that Nathaniel needed to see before he could believe that his perception of Nazareth has nothing to do with who Jesus Christ is. Perception can be misleading.',
                'Brethren; are you worried about men’s perception of you? It happened to Jesus, don’t be worried. If your heart is right with God in all that you do, the wrong perception of men has no place with God. Just keep doing the right thing and doing your good work for the glory of God. There can only be danger for where you have the general perception of men that what you are doing is good; and so you fail to see how God sees your heart. God sees and knows everyone’s heart no matter the perception of men. When God sees your heart as something else from what cronies are saying to you; it’s time to run to the Lord in repentant humility and ask Him to recreate you with His redemptive  power of recreation.',
                'God says: "I the LORD search the heart and test the mind..." (Jeremiah 17:10 ESV). Also in the house Jesé the father of David, when Samuel considered Eliab because of Eliab’s looks; God told Prophet Samuel not to look on his appearance or the height of his stature and that: “...the LORD sees not as man sees: man looks on the outward appearance, but the LORD looks on the heart." (1 Samuel 16:7 ESV). In our text today, Jesus saw Nathaniel differently because Jesus considers not appearance but the heart. Brethren, how or what people especially cronies say to you about yourself is irrelevant to how and what the Almighty God knows of you. It is what God knows of you that counts when the chips are down.  ',
                'God looks at the heart not the height or position, therefore don’t allow people who are cronies or your position deceive you. Be conscious of the fact that everyone’s end journey in life is to be accountable to God; and God does not look at position or glamour created by position, but God looks at the heart. The Psalmist explains the man that will dwell in God’s holy hill in Psalm 24:3. Whether you are a Minister of God or a Minister of the Government, or even a student or an office messenger, or what ever your position may be, God knows who you really are. And whatever man says to you contrary to what God sees is mere deceit by the devil to get you destroyed in the end.',
                'Jesus taught us what God expects of us in Matthew 5:16 that: “...let your light shine before others, so that they may see your good works and give glory to your Father who is in heaven”. What Jesus said of Nathaniel in our text today is: “...a man in whom there is no deceit”. Brethren, in the position that God has placed you, what does God knows about you? No matter what the praise of men may be, it is what God knows of you that is important. Will Jesus say of you that this is a man/woman in whom there is no deceit? The Psalmist prayed to God in Psalm 139:23 asking God to examine him and look at his heart and for God to lead him on the eternal path (CEB). May God lead us all to eternal path. How does God sees your heart?',],
            pray: 'Heavenly Father, I thank You for this day and I thank You for Your Word. Lord, search my heart today, purge me and lead me on the path of eternal life in Jesus name. Amen.',
            comments: [],
            details:{author:"Admin", date:"Feb, 2022"}},    
        {sn:46,	
            title: 'Getting to Our Promise Land',	
            bibleRef: '“And the LORD said to Moses and Aaron, "Because you did not believe in me, to uphold me as holy in the eyes of the people of Israel, therefore you shall not bring this assembly into the land that I have given them" (Numbers 20:12 NLT).',	
            body:[    'Moses is hailed as the leader of the Exodus; he being the one through whom God delivered the children of Israel. In fact, in Deuteronomy 34:10-12, it was recorded of Moses that there is “none like him for all the signs and the wonders that the LORD sent him to do in the land of Egypt, to Pharaoh and to all his servants and to all his land, and for all the mighty power and all the great deeds of terror that Moses did in the sight of all Israel.” Yet, he could not achieve that singular thing that all Israelites look up to: “Getting to the promise land.” Why not?The Bible did not leave us oblivious of God’s reasons, in Deuteronomy 32:51-52, God said to Moses: “For both of you betrayed me with the Israelites at the waters of Meribah at Kadesh in the wilderness of Zin. You failed to demonstrate my holiness to the people of Israel there. So you will see the land from a distance, but you may not enter the land I am giving to the people of Israel”(Deuteronomy 32:51-52.',
                'The incident referred to by the Almighty God at at the waters of Meribah at Kadesh was recorded in Numbers chapter 20. When after wandering in the wilderness for almost 40 years, the Israelites came to the Wilderness of Zin. There was no water, and the people’s anger turned against Moses and Aaron. They (Moses and Aaron) then went to the tent of meeting, prostrated before God and God responded to them and told them to gather the assembly and “speak” to the rock and water would come forth. But what did Moses do? The Bible says that Moses actually gathered the people as instructed, he took the staff as instructed but, seemingly in anger, Moses said to the people: “Listen, you rebels, must we bring you water out of this rock?” Moses then struck the rock twice with his staff (Numbers 20:10-11). Water actually came out from the rock, in line with what God had promised. But, Moses action did not please God. And, God said to Moses and Aaron that, “…Because you didn’t “trust” me, didn’t treat me with holy reverence in front of the People of Israel, you two aren’t going to lead this company into the land that I am giving them”(Numbers 20:12). The key words here are: “Trust & Honour." Are we, as children of God not guilty of same today?',
                'God desires that we trust Him. Although Moses’ punishment may appear harsh to us, but, when we look closely we can see several mistakes of Moses which many of us are guilty of today. The most obvious of these mistakes is disobedience. Moses disobeyed God’s direct command to “Speak” to the rock when he struck the rock with his staff. There have been other instance where God had brought water from a rock by instructing Moses to strike it with a staff in Exodus chapter 17. But at Meribah, there was a clear instruction to speak to the rock. To God, this was lack of trust in God and failure to Honour Him before the people.',
                'Brethren, there is a promise land for every child of God born through the precious Blood of Jesus, but God requires our trust in Him. When we trust Him totally as we bring Honour to him before people of very little or no faith. God did not want Moses to use force; which would have meant his strength. He only needed to Trust God and obey God; knowing that God would always be true to His promise. When Moses said to the people: “Must we bring you water out of this rock?, in Numbers 20:10, he seemed to be taking for himself and Aron, the credit for the miracle instead of attributing it to God. As children of God, we must learn to trust God and Honour Him before men and bring them to trust God as well. The ESV version of the Bible puts the instruction as follows: “Take the staff, and assemble the congregation, you and Aaron your brother, and tell the rock before their eyes to yield its water” (Numbers 20:8). To tell the Rock could have meant a picture of prayer. Brethren, like Moses, how many times do we fret rather than pray in the face of challenges?',
                'God has given us Jesus Christ as the water-giving Rock that we must trust as recorded in 1 Corinthians 10:4 that: “…they drank from the spiritual rock that traveled with them, and that rock was Christ.” Failure to trust God amounts to disobedience, pride, and failure to acknowledge Christ’s sacrifice through whom we receive salvation. We all have a promise land for each of God’s children, but God requires us to trust Him. By so doing we bring honour to Him before men. I pray that whatever we have been looking unto God for, we will totally trust and obey Him, no matter how loud the negatives being shouted by the world around us. God is faithful and ever faithful. By Mercy, we will get Our Promise Land.',],
            pray: 'Heavenly Father, thank You for this day and thank You Lord, for Your word. Lord, help me to walk in obedience and totally trust You no matter the circumstances in Jesus name. Amen.',
            comments: [],
            details:{author:"Admin", date:"Mar, 2022"}},
        {sn:47,	
            title: 'God’s Open Option',	
            bibleRef: '“But if you refuse to serve the Lord, then choose today whom you will serve. Would you prefer the gods your ancestors served beyond the Euphrates? Or will it be the gods of the Amorites in whose land you now live? But as for me and my family, we will serve the Lord”(Joshua 24:15 NLT).',
            body:[    '“Then Elijah stood in front of them and said, “How much longer will you waver, hobbling between two opinions? If the Lord is God, follow him! But if Baal is God, then follow him!” But the people were completely silent” (1 Kings 18:21 NLT).',
                'Open option is simply freedom to choose. Without freedom of choice, obedience is not true obedience but compulsion. We therefore cannot truly claim to be obedient and neither can one be accused of disobedience when there is no option of choice. In the first text, Joshua told the people to choose whom they will serve. But he already made his choice to serve the Lord. We see another example in the second text where the Prophet Elijah asked the people: “How much longer will you waver, hobbling between two opinions? If the Lord is God, follow him!” (NLT). The Bible records that nobody answered him. The reason is that they could not make up their mind on the choice to follow God or Baal?',
                'From the start of humanity as seen in the Garden of Eden, Adam and Eve had freedom to choose. This is because if God was not giving them the option to choose between obedience and disobedience, God wouldn’t have put the tree of life in a reachable position for them. God is God, He knew they will be tempted with the forbidden fruit, He however gave them the option to choose to obey without being compelled but out of love and reverence for God, or do otherwise (Genesis 2:17). The first humans blew the freedom to choose and today man continues to do the same until when one totally commits Himself to God through Christ, the only one who is worthy.',
                'In the story of Elijah and the people of Israel, in 1 Kings 18:21, the Bible describes a crucial moment of decision. It was the final showdown between the God of Israel and a false god called Baal. And so Elijah calls God’s people to choose once and for all between the living God who delivered them, and the false god who has captured their affections. But the people seemed unable or unwilling to make a choice. They want to hedge their bets, sit on the fence and keep their options open. Joshua also chose to obey God alone in serving Him.',
                'Brethren, what challenges are you confronted with that Satan is suggesting the other option to you? Let us not be like the Israelites in the time of Elijah who wanted to be on the fence. That in itself, is an indirect rejection of God. The enemy will always make the other option look good, attractive or rewarding, but the wise king Solomon says of such that: “There is a path before each person that seems right, but it ends in death.”(Proverbs 14:12). We will not be destroyed in Jesus name.',
                'Brethren, to refuse to choose obedience to God and allow Him to be the Master over our lives is to choose slavery to sin. This is what Apostle Paul points out in his letter to Titus in Titus 3:3 that: “For we also once were foolish ourselves, disobedient, deceived, enslaved to various lusts and pleasures, spending our life in malice and envy, hateful, hating one another”.',
                'The two options i.e. obedience or disobedience have its consequences; and a lot of time, we find ourselves suffering under the consequences of those choices we have made in life that were not in line with obedience to the word of God. See Deuteronomy 30:15-20. May we always have the grace to choose rightly through the power of Jesus Christ, who used Himself as example of obedience and says: “If you keep my commandments, you will abide in my love, just as I have kept my Father\'s commandments and abide in his love (John 15:10).',
                'Today, let us choose to walk in obedience continually and we will easily appropriate into our lives all the blessings that God has prepared for us. The open option of choice to obey is good because God desires that whoever comes to Him comes wholeheartedly and not by compulsion. May we willingly choose to obey God in Jesus name. Amen.',],
            pray: 'Heavenly Father, thank You for the option given to me to choose; Lord, help me to always choose rightly in all things and in accordance to Your word in Jesus name. Amen.',
            comments: [],
            details:{author:"Admin", date:"Mar, 2022"}},
        {sn:48,	
            title: "It won't end in Shame",	
            bibleRef: '“Israel has been saved by the LORD With an everlasting salvation; You will not be put to shame or humiliated for all eternity” (Isaiah 45:17 AMP).',
            body:[    'The word of God in our text today gives us the assurance that God is delighted in us for the simple reason that we are in Christ. Apostle Paul wrote to the Roman Christians like us that: “...For the Scripture says, "Everyone who believes in him will not be put to shame" - Romans 10:11). Apostle Paul’s letter is so clear and direct. He also wrote to the Galatian Christians that “...if you are Christ\'s, then you are Abraham\'s offspring, heirs according to the promise” (Galatians 3:29).',
                'In Christ, we become joint heirs to every promise of God to the children of Israel. Promises that includes: that we will not be put to shame. This means God in His might and power, and for His own name sake, will prevent the shame that the enemy intends; just as He promises in Isaiah 48:11, that: “For my own sake, for my own sake, I do it, for how should my name be profaned? My glory I will not give to another.” The Lord recognises that it is with a price that He redeemed us (1 Corinthians 6:20). Therefore, He and He alone will take the glory, not the devil.',
                'Brethren, whatever may be the challenges that we are facing at the moment, however long the battle might have been on, no matter the length of our waiting; as long as we believe in the eternal and everlasting  God that determines the end from the beginning for all those who trust in Him; the storyline will end in our favour and for His praise. And, just as Prophet Joel puts it in Joel 2:27, we will know that He that is in the midst of Israel is your God and you will never be put to shame. And instead of the shame that the enemy planned and orchestrated, instead of dishonor, they shall rejoice in our lot; and we will have everlasting joy (Isaiah 61:7).',
                'What the main scripture of today reveals through prophet Isaiah, is the same thing that Apostle Paul quotes in Romans 10:11. Therefore, His promises have never failed nor has He ever changed His Word. Examples abound of people of old who firmly trusted God and their faces were never ashamed. (See Genesis 37:18-36 and 43:5-7; Exodus 14: 10-31; Daniel 3:19-23, 28-30; 6:16-17, 26-28).',
                'Let us keep trusting the only unfailing God who has never failed anyone; and He will never fail. Our victory is just round the corner. Marianne Williamson is quoted as saying: “Behind every dark cloud there is an every-shining sun. Just wait. In time, the cloud will pass.”',
                'For us as believers, our shinning sun is Jesus Christ in us and He has already overcome darkness, difficulties and all manners of challenges for us. Hence, John’s revelation explicitly says: “...Weep no more; behold, the Lion of the tribe of Judah, the Root of David, hath prevailed..” (Revelation 5:5).',
                'Brethren, we serve the God that says: “...I am the LORD your God and there is none else...”(Joel 2:27). We will trust Him, we will be the victors in the end and it will never end in shame, disgrace or sorrow that the enemy has planned, in Jesus name.',],
            pray: 'Heavenly Father, I thank You for this day and I thank You for Your Word which is Yea and amen. I am confident that no matter what, I will not be put to shame in Jesus name. Amen.',
            comments: [],
            details:{author:"Admin", date:"Mar, 2022"}},
        {sn:49,
            title: 'The Lord, My Shepherd',
            bibleRef: '“The LORD is my shepherd; I shall not want” (Psalms 23:1 NKJV)',
            body:[ 'Psalm 23 is a great Psalm which a lot of us know like we know the back of our hands. And in fact, a lot of unbelievers also know and quote, at least, the first verse which is our main text of today.',
            'Reading through the whole of Psalm 23 and meditating on each verse, one can see that there are some blessings revealed by the Psalmist as that which would come the way of any believer who allows God to be his/her shepherd. We will look at all the blessings as time goes by, as they cannot all be compressively discussed in one day.',
            'The first is seen in verse 1 which says that: “The LORD is my shepherd; I shall not want”. This first verse is an assurance of provision. When we talk of provision, people tend to look in the direction of financial or material provisions. But the assurance in verse 1 is beyond that. This assurance stems from what God did at creation as revealed in Genesis 1:3, 6, 9-10, 11, 14-18, 19-21 and 25-26; wherein God made complete provisions for everything that man will need for his life and his survival. And it was after all these were in place that God made man in Genesis 1:26 and also gave the man dominion over all the other things that He had created. In other words God made provisions and located man in and over all that He provided. Man had no impute as to how these things came about.', 
            'The Amplified Bible Version of Psalm 23:1 says the Lord is my shepherd: “...to feed, to guide and to shield me”. Since man did not have a hand in how the provisions came about, man certainly would need the maker for him to be able to actualise them. Psalm 23:1 is therefore an assurance of what we will have both physically, materially, and spiritually when the Lord is our shepherd. And since the shepherd is the maker of these things in the first place, He alone knows where all the necessities of life are located and He alone can lead His sheep to them. There is an absolute need for us to allow God to be our shepherd lest we continue to struggle without having the fullness of what God has provided for man before man was made. And so the Psalmist says, when He is our shepherd, we will not lack anything.', 
            'Brethren, consider the sheep and their shepherd. The Psalmist being a shepherd right from the youth knows that as a shepherd, he is responsible for everything the sheep needs to be alive, to be healthy and to look good. Therefore, he says that when you allow God to be the shepherd of your life, you will have no lack for anything; and not lacking in anything means having everything. We need to ask for mercy of God in the so many ways that, even after accepting Jesus as the Lord and Saviour of our lives, we have strayed away like a lost sheep. We have continued to struggle on our own and not allowing Him to be the shepherd of our lives. And so, despite all our struggles and strivings, most of us do not have much to show for it financially, materially and physically or even spiritually. All because, we have not allowed the shepherd to be in total control. Jesus says in Matthew 6:33 that we should seek first the kingdom of God and his righteousness, and every other thing will be added to us. Seeking first the kingdom of God is the acceptance of Jesus as Lord and Saviour, while His righteousness is our total submission to God’s leading just as the sheep does in total submission to the shepherd. And when this is not there, we short-changed ourselves. May Lord have mercy on us.',
            'Also, in the Old Testament, Moses revealed the mind of God to the children of Israel that God says: “all these blessings shall come upon you and overtake you if you will obey God.” (Deuteronomy 28:2). What are these blessings? They include what Jesus revealed in Matthew 6:31 as the things that all men are anxious about i.e. "What shall we eat?" or "What shall we drink?" or "What shall we wear?" All these things can truly be our source of concern and sometimes burden to any human being. And no matter how much we labour, they can never be fully met or satisfied by our human strength or wisdom. Because until a man drops dead, man’s physical, material and financial needs can never be fully met or satisfied. And so man on his own, like a run away sheep, keeps striving but never having enough. This is what brings stress to a man’s life and makes him depressed. May the Lord open our understanding.',
            'The Psalmist therefore found and revealed the secret to having satisfaction; which is, just to allow the Lord to be our shepherd. So that, when we allow God to be our shepherd, there can never be a lack of anything in our lives, since the Shepherd alone knows where the supply is located. And so in Psalm 103:5, the Psalmist further reveals that God will feel our lives with good things. On this Easter Sunday, let us allow God to be our shepherd.',],
            pray: 'Heavenly Father, I thank You for Your Word today, I repent of the so many ways I have tried to help myself and not allowing you to shepherd me; Lord have mercy on me as I surrender all areas of my life to you for you to completely shepherd me. Lord, thank You for getting rid of every lack in my life and family in Jesus name. Amen.',
            comments: [],
            details:{author:"Admin", date:"Apr, 2022"}},
        {sn:50,	
            title: 'Fulfilling the Law of Christ',	
            bibleRef: '"Carry each other\'s burdens, and in this way you will fulfill the law of Christ."(Galatians 6:2)',	
            body:[    'Living in peace and unity with all men pleases God. When we love and serve others, putting their needs above ours, we actually honour God. And when we carry each other’s burdens, we fulfil the law of Christ, which is centred on love for others.',
                'What does it mean to carry each other’s burdens? There are many ways to reach out to people and help shoulder their loads, so to speak. Just by giving an encouraging word or smile, we can help lighten the loads for someone. When we pray and intercede for others in their day of difficulty, we are helping to carry their burdens.',
                'There are also other ways to help the people around us. We can pay someone’s child’s school fees or even give someone cash to help the family, as long as you have the means. And anytime we help others with reaching out to their needs, the scripture says we are directly lending a hand to God Himself (Proverbs 19:17).',
                'The story of Prophet Elijah is a typical example. God said to Elijah: “Arise, go to Zarephath, which belongs to Sidon, and dwell there. Behold, I have commanded a widow there to feed you” (1 kings 17:9). God prepared a widow to feed Elijah because God’s intention was to use Elijah to prosper the widow.',
                'Today, consider those that God has positioned in your life for you to be of help to them; to lift up their hands just as Aron lifted up the hands of Moses in the battle against the Amalekites in Exodus 17:12-14. Let us look for ways to lighten the load for one person that God has placed around us. Let us be the burden lifter for the glory of God to manifest in our own lives too. In God’s kingdom what we sow, we will reap; and God owes no man. God is the only true rewarder (Hebrews 11:6). When we help others, God promises to repay us. He is the God of His Word (Jeremiah 1:12). He will pour out His prosperity and blessing on us all the days of our lives when we honour Him by doing His will to help others because it is the law of Christ. In the spirit of the season that we remember Christ’s sacrifice for us, let us determine to help someone today and fulfil Christ’s law.',],
            pray: 'Heavenly Father, I thank You for this day and I thank You for Your Word. Lord, I choose to fulfil Your law of love by serving and helping others, show me how to be a blessing to the people around me that I may fulfil Your law always in Jesus’ mighty name, amen.',
            comments: [],
            details:{author:"Admin", date:"Apr, 2022"}},
    
                            
    ];
    
    function getPastorCorner(){

        return pastorCorner
    }

    export default getPastorCorner;