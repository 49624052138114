import React, {useState} from 'react';
import { Button } from '../components/Components';
import { client } from '../../../../client'; 
import { deleteUsedAssetsQuery } from '../../admin/membersData/queries';

const SanityAssets = () => {
  const [unrefAssets, setUnrefAssets] = useState([]);
  const [unrefAssetsQueried, setUnrefAssetsQueried] = useState(false);
  const [allAssets, setAllAssets] = useState([]);
  const [isDeleting, setIsDeleting] = useState({show:false, msg:''});

  const retrieveUnRefAssetsHandler=()=>{
    const fetchProgRes = fetchContents(unrefAssetsQuery);
    fetchProgRes.then((res)=>{
      if(res.length>0){
        setUnrefAssets(res);
        setIsDeleting({show:false, msg:``});
      }else{
        setIsDeleting({show:true, msg:`There is no unref assets in your sanity dataset!`});
      }
    }); 
    setUnrefAssetsQueried(true);
  }

  const retrieveAllAssetsHandler=()=>{
    const fetchProgRes = fetchContents(allAssetsQuery);
    fetchProgRes.then((res)=>{
      setAllAssets(res);
   }); 
  }

  async function fetchContents(query) {
    const response = await client.fetch(query);
    return response;
  }

  function deleteUnrefAssets(){
    if(!unrefAssetsQueried){
      setIsDeleting({show:true, msg:`Query unref assets first before deleting!`});
      return
    }
    const query = deleteUsedAssetsQuery();
    client
        .fetch(query)
        .then(ids => {
            if (!ids.length) {
            setIsDeleting({show:true, msg:'No assets to delete'});
            return true
            } 

            setIsDeleting({show:true, msg:`Deleting ${ids.length} assets`});
            return ids
            .reduce((trx, id) => trx.delete(id), client.transaction())
            .commit()
            .then(() => {
              setIsDeleting({show:true, msg:`Done!`});
              retrieveUnRefAssetsHandler();
            })
        })
        .catch(err => {
            if (err.message.includes('Insufficient permissions')) {
            console.error(err.message)
            console.error('Did you forget to pass `--with-user-token`?')
            } else {
            console.error(err.stack)
            }
        })
   }


  return (
    <div className='m-2 border border-blue-800 p-2'>
      <p className='block font-bold p-4 pt-0 text-red-600 underline'>Sanity Assets Control</p>
    
    
      <div className='ml-4'>
        <div className='mb-3'>
         <p className='block font-bold text-red-900'>1. Unreference Assets</p>
         <p className='block font-bold text-blue-700'>
          Unref assets are assets in the database that are not been used anywhere on the web. They consume space. 
         </p>
         <p className={`font-bold pb-2 text-[red] ${isDeleting.show? 'block': 'hidden'}`}>
          {isDeleting.msg}
         </p>
          {
            unrefAssets?.map((item,i)=>{

              return(
                <p key={`${i}+unrefs`}>
                 {i+1}. {item.originalFilename} -Size: {item.size/1000}kb
                </p>
              );
            })
          }
        </div>
       <div className='gap-4 flex flex-row flex-wrap'>
         <Button title='Query Unref-Assets'
          onClickHandler={retrieveUnRefAssetsHandler}/>
          <Button title='Delete Unref-Assets'
            bgCol="bg-[maroon]"
            bgHov="hover:bg-[red]"
            btnStyle="active:bg-[maroon]"
            onClickHandler={deleteUnrefAssets}
          />
       </div> 
      </div>

      <div className='ml-4 mt-4'>
        <div className=''>
         <p className='block font-bold pb-2 text-red-900'>2. All Assets</p>
          {
            allAssets?.map((item,i)=>{

              return(
                <p key={`${i}+unrefs`}>
                 {i+1}. {item.originalFilename} -Size: {item.size/1000}kb
                </p>
              );
            })
          }
        </div>
       <Button title='Query All-Assets'
        onClickHandler={retrieveAllAssetsHandler}/> 
      </div>
    </div>
  )
}

export default SanityAssets;



const unrefAssetsQuery = `
  *[ _type in ["sanity.imageAsset", "sanity.fileAsset"] ]{
    _id,
    originalFilename,
    size, 
    "refs": count(*[ references(^._id) ])
   }[ refs == 0 ]{
      _id,
      originalFilename,
      size,
    }
    
 `;

 const allAssetsQuery = `
  *[ _type in ["sanity.imageAsset", "sanity.fileAsset"] ]{
    _id,
    originalFilename,
    size,
   }
 `;

 //Delete unused Assets: https://www.sanity.io/schemas/delete-unused-assets-2ef651b5
  