const offeringSundayWorship = [
    {docID:1, date:'2023-01-02', program:'Sunday School Online Prep Offering', type:'OFFERING',dateDetails:'2023-01-02|Sunday School Online Prep Offering', total:'',
        data:[
            {name:'Ogundare', familyID:'ogundare.kehinde.abimbola', amount:500}, 
            {name:'Marcus', familyID:'marcus.garuba.faith', amount:200}, 
            {name:'Miller', familyID:'miller.henry.comfort', amount:200}, 
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:200},  
            {name:'Ariyibi', familyID:'ariyibi-oke.oluwaseyi.opeyemi', amount:200},
            {name:'Hassan', familyID:'hassan.kehinde.yetunde', amount:200},                                  
        ],  
    },
    {docID:2, date:'2023-01-08', program:'Sunday School Online Prep Offering', type:'OFFERING',dateDetails:'2023-01-08|Sunday School Online Prep Offering', total:'',
        data:[
            {name:'Aare', familyID:'aare.lasisi.folasade', amount:400}, 
            {name:'Akinmutola', familyID:'akinmutola.john.mercy', amount:200}, 
            {name:'Ugbada', familyID:'ugbada.jacob.janet', amount:100}, 
            {name:'Alade', familyID:'alade.abiodun.ibukun', amount:200}, 
            {name:'Miller', familyID:'miller.henry.comfort', amount:300}, 
            {name:'Adegboye', familyID:'adegboye.sunday.faith', amount:200},                                    
        ],  
    },    
];



export default offeringSundayWorship;