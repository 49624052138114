import React from 'react';
import {BrowserRouter, Routes, Route, } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './context/store';
import { GoogleOAuthProvider } from '@react-oauth/google';

import Home from './container/Home';
import About from './container/About';
import ContactUs from './container/ContactUs';
import Media from './container/Media';
import UserPage from './container/UserPage';
import PastorCorner from './container/PastorCorner';
import ReadPastorCorner from './container/ReadPastorCorner';
import Error from './container/Error';
import { HeaderContainer } from './components';
import { HelmetProvider } from 'react-helmet-async';
//import ImportantAnnouncement from './components/announcement/_Index';
//import ReactGA from 'react-ga';

//const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKINGID;    //"UA-XXXXX-X"; // OUR_TRACKING_ID
//ReactGA.initialize(TRACKING_ID);

const App = () => {
  
  //useEffect(() => {
 //   ReactGA.pageview(window.location.pathname + window.location.search);
 // }, []);

  return (
    <HelmetProvider>
      <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_API_TOKEN}`}>
        <Provider store={store}>
          <BrowserRouter>
            <HeaderContainer/>
            <Routes>
                <Route index path="/"  element={<Home/>} />
                <Route  path="about" element={<About/>} />
                <Route  path="media" element={<Media/>} />
                <Route  path="contactus" element={<ContactUs/>} />
                <Route path="user" element={<UserPage/>} />
                <Route path="pastorcorner" element={<PastorCorner/>}/>
                <Route path="pastorCorner/:pastorCornerId" element={<ReadPastorCorner/>} />
                <Route path="*" element={<Error/>} />
            </Routes>
          </BrowserRouter>
      </Provider>
    </GoogleOAuthProvider>
  </HelmetProvider>
  );
};

export default App;



/*
const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
            <Route path='/*'  element={<GofamintPS/>} />
            <Route path="user" element={<UserPage/>} />
        </Routes>
      </BrowserRouter>
  </Provider>
  );
};


const GofamintPS = () => {

  return (
    <Fragment>
        <HeaderContainer/>
        <Routes>
            <Route index path="/"  element={<Home/>} />
            <Route  path="about" element={<About/>} />
            <Route  path="media" element={<Media/>} />
            <Route  path="contactus" element={<ContactUs/>} />
            <Route path="user" element={<UserPage/>} />
            <Route path="pastorcorner" element={<PastorCorner/>}/>
            <Route path="pastorCorner/:pastorCornerId" element={<ReadPastorCorner/>} />
            <Route path="*" element={<Error/>} />
        </Routes>
  </Fragment>
  );
};
*/