import React from 'react';
import {BsPersonCircle} from 'react-icons/bs';


const Message = ({item}) => {
    const msgsArr = item?.message?.split('\n');
    
    return ( 
        <div className='flex flex-col flex-wrap shadow-lg bg-slate-50 mb-3 rounded-md p-3 md:p-10 md:m-5'>
            {item?.postedBy?.image?
                <img src={item?.postedBy?.image} alt="Gofamint Logo" className="block w-20 h-20 rounded-full"/>:
                <div className='mb-3'><BsPersonCircle className="text-[teal] w-20 h-20"/></div>
            }
            <Field 
                title="Sender:"
                main={item?.userName}
            />
            <Field 
                title="Email:"
                color="text-[mediumblue]"
                main={item?.userEmail}
            />
            <Field 
                title="Phone no:"
                main={item?.phoneNo}
            />
            <Field 
                title="Other Info:"
                main={item?.additionalInfo}
                color={item?.additionalInfo? "text-[green]" : ""}
            />
            <Field 
                title="Date:"
                main={item?.datePosted}
            />

            <Field 
                flexCol noUnderline
                titleUnder="underline"
                fldMagT="mt-3"
                title="Message:"
                main={msgsArr[0]}
            />
            {
                msgsArr?.map((msg,i)=>{

                    return(
                        <Field  key={`${i}+key`}
                            flexCol noUnderline
                            main={i !== 0 && msg}
                        />
                    );
                })
            }
        </div>
  )
}

export default Message;


  
const Field =({title, main, flexCol, noUnderline, fldMagT, color, titleUnder})=>{

    return (
        <div className={`flex mb-1 ${flexCol? 'flex-col' :'flex-row'}`}>
            <p className={`pr-1 text-gray-700 ${fldMagT? fldMagT :''} ${titleUnder? titleUnder :''}`}>{title}</p>
            <p className={`text-justify ${noUnderline? '' : 'underline'} ${ color? color : 'text-[#191949]'}`}>
                {main}
            </p>
        </div>
    );
}