
function getQueryParentAllData(famID){

    const memberData = `
    *[_type == "members" && family == '${famID}'] | order(_createdAt desc) {
        _id,
        _type,
        surname,
        loginID,
        sn,
        title,
        firstname,
        othernames,
        addressRes,
        dob,
        birthday,
        genderStat,
        ageGroup, membership,
        email,
        phoneNo,
        occupation,
        addressOff,
        school,
        instiStat,
        memberStat,
        matStat,
        wedAnn,
        wedAnns,
        batStat,
        'photo':photo.asset->url,
        'photoAsset':photo,
    
        select,
        edit,
        family,
        famStat,
        id,
        info,
        filledBy,
        filledDate,
        editedBy,
        accesspermit,
        accessdenied,
        notification,
        notifToggle,
     }
  `;
    return memberData
}


function getQueryLoginMemberAllData(email, loginID){

    const memberData = `
    *[_type == "members" && (email == '${email}' || loginID == '${loginID}')] | order(_createdAt desc) {
        _id,
        _type,
        surname,
        loginID,
        sn,
        title,
        firstname,
        othernames,
        addressRes,
        dob,
        birthday,
        genderStat,
        ageGroup, membership,
        email,
        phoneNo,
        occupation,
        addressOff,
        school,
        instiStat,
        memberStat,
        matStat,
        wedAnn,
        wedAnns,
        batStat,
        'photo':photo.asset->url,
        'photoAsset':photo,
    
        select,
        edit,
        family,
        famStat,
        id,
        info,
        filledBy,
        filledDate,
        editedBy,
        accesspermit,
        accessdenied,
        notification,
        notifToggle,
     }
  `;
    return memberData
}


function getQueryLoginMemberByLoginID(loginID){

    const memberData = `
    *[_type == "members" && loginID == '${loginID}'] | order(_createdAt desc) {
        _id,
        _type,
        surname,
        loginID,
        sn,
        title,
        firstname,
        othernames,
        addressRes,
        dob,
        birthday,
        genderStat,
        ageGroup, membership,
        email,
        phoneNo,
        occupation,
        addressOff,
        school,
        instiStat,
        memberStat,
        matStat,
        wedAnn,
        wedAnns,
        batStat,
        'photo':photo.asset->url,
        'photoAsset':photo,
    
        select,
        edit,
        family,
        famStat,
        id,
        info,
        filledBy,
        filledDate,
        editedBy,
        accesspermit,
        accessdenied,
        notification,
        notifToggle,
     }
  `;
    return memberData
}

function getQueryMembersAllData(surname){

    const memberData = `
    *[_type == "members" && surname == '${surname}'] | order(_createdAt desc) {
        _id,
        _type,
        surname,
        loginID,
        sn,
        title,
        firstname,
        othernames,
        addressRes,
        dob,
        birthday,
        genderStat,
        ageGroup, membership,
        email,
        phoneNo,
        occupation,
        addressOff,
        school,
        instiStat,
        memberStat,
        matStat,
        wedAnn,
        wedAnns,
        batStat,
        'photo':photo.asset->url,
        'photoAsset':photo,
    
        select,
        edit,
        family,
        famStat,
        id,
        info,
        filledBy,
        filledDate,
        editedBy,
        accesspermit,
        accessdenied,
        notification,
        notifToggle,
     }
  `;
    return memberData
}


function getQueryByMemberPersonalID(id){

    const memberData = `
    *[_type == "members" && id == '${id}'] | order(_createdAt desc) {
        _id,
        _type,
        surname,
        loginID,
        sn,
        title,
        firstname,
        othernames,
        addressRes,
        dob,
        birthday,
        genderStat,
        ageGroup,
        membership,
        email,
        phoneNo,
        occupation,
        addressOff,
        school,
        instiStat,
        memberStat,
        matStat,
        wedAnn,
        wedAnns,
        batStat,
        'photo':photo.asset->url,
        'photoAsset':photo,
    
        select,
        edit,
        family,
        famStat,
        id,
        info,
        filledBy,
        filledDate,
        editedBy,
        accesspermit,
        accessdenied,
        notification,
        notifToggle,
     }
  `;
    return memberData
}

function getQueryByMemberEmail(email){

  const memberData = `
  *[_type == "members" && email == '${email}'] | order(_createdAt desc) {
      _id,
      _type,
      surname,
      loginID,
      sn,
      title,
      firstname,
      othernames,
      addressRes,
      dob,
      birthday,
      genderStat,
      ageGroup, membership,
      email,
      phoneNo,
      occupation,
      addressOff,
      school,
      instiStat,
      memberStat,
      matStat,
      wedAnn,
      wedAnns,
      batStat,
      'photo':photo.asset->url,
      'photoAsset':photo,
  
      select,
      edit,
      family,
      famStat,
      id,
      info,
      filledBy,
      filledDate,
      editedBy,
      accesspermit,
      accessdenied,
      notification,
      notifToggle,
   }
`;
  return memberData
}


function getQueryLoginMemberDataAndFamData(email, loginID){
    const memberData = `
    *[_type == "members"&& (email == '${email}' || loginID == '${loginID}')] | order(_createdAt desc){
      'userData':{
        _id,
        _type,
        surname,
        loginID,
        sn,
        title,
        firstname,
        othernames,
        addressRes,
        dob,
        birthday,
        genderStat,
        ageGroup, membership,
        email,
        phoneNo,
        occupation,
        addressOff,
        school,
        instiStat,
        memberStat,
        matStat,
        wedAnn,
        wedAnns,
        batStat,
        'photo':photo.asset->url,
        'photoAsset':photo,
    
        select,
        edit,
        family,
        famStat,
        id,
        info,
        filledBy,
        filledDate,
        editedBy,
        accesspermit,
        accessdenied,
        notification,
        notifToggle,
      },
      'familyData':*[_type == "members" && family == (^.family)]{
        _id,
        _type,
        surname,
        loginID,
        sn,
        title,
        firstname,
        othernames,
        addressRes,
        dob,
        birthday,
        genderStat,
        ageGroup, membership,
        email,
        phoneNo,
        occupation,
        addressOff,
        school,
        instiStat,
        memberStat,
        matStat,
        wedAnn,
        wedAnns,
        batStat,
        'photo':photo.asset->url,
        'photoAsset':photo,
    
        select,
        edit,
        family,
        famStat,
        id,
        info,
        filledBy,
        filledDate,
        editedBy,
        accesspermit,
        accessdenied,
        notification,
        notifToggle,
      },
      'inboxArr':*[ _type == 'memberNotification' && (memberID == (^.id) || senderEmail== '${email}') ]  | order(messagesCount asc) {
        _id,
        surname,
        firstname,
        title,
        message,
        newMessage,
        messagesCount,
        memberID,
        "attachedFile": attachedFile.asset->url,
        attachedFileName,
        date 
      }
    }
  `;
    return memberData
}

function getQueryNonRegisteredUserMails(email){
  const memberData = `
    *[ _type == 'memberNotification' && senderEmail== '${email}' ]  | order(messagesCount asc) {
      _id,
      surname,
      firstname,
      title,
      message,
      newMessage,
      messagesCount,
      memberID,
      "attachedFile": attachedFile.asset->url,
      attachedFileName,
      date 
  }
`;
  return memberData
}

function getQueryByMemberPersonalIDLimtedData(id){
  const memberData = `
  *[_type == "members" && id == '${id}'] | order(_createdAt desc) {
      _id,
      _type,
      surname,
      loginID,
      firstname,
      email,
      'photo':photo.asset->url,
      family,
      id,
   }
`;
  return memberData
}

function getQueryByUserLoginIDLimtedData(loginID){
  const memberData = `
  *[_type == "members" && loginID == '${loginID}'] | order(_createdAt desc) {
      _id,
      _type,
      surname,
      loginID,
      firstname,
      email,
      'photo':photo.asset->url,
      family,
      id,
   }
`;
  return memberData
}


function getQueryByMemberPersonalIDAndFamData(id){
    const memberData = `
    *[_type == "members" && id == '${id}'] | order(_createdAt desc){
      'userData':{
        _id,
        _type,
        surname,
        loginID,
        sn,
        title,
        firstname,
        othernames,
        addressRes,
        dob,
        birthday,
        genderStat,
        ageGroup, membership,
        email,
        phoneNo,
        occupation,
        addressOff,
        school,
        instiStat,
        memberStat,
        matStat,
        wedAnn,
        wedAnns,
        batStat,
        'photo':photo.asset->url,
        'photoAsset':photo,

        select,
        edit,
        family,
        famStat,
        id,
        info,
        filledBy,
        filledDate,
        editedBy,
        accesspermit,
        accessdenied,
        notification,
        notifToggle,
      },
      'familyData':*[_type == "members" && family == (^.family)]{
        _id,
        _type,
        surname,
        loginID,
        sn,
        title,
        firstname,
        othernames,
        addressRes,
        dob,
        birthday,
        genderStat,
        ageGroup, membership,
        email,
        phoneNo,
        occupation,
        addressOff,
        school,
        instiStat,
        memberStat,
        matStat,
        wedAnn,
        wedAnns,
        batStat,
        'photo':photo.asset->url,
        'photoAsset':photo,
    
        select,
        edit,
        family,
        famStat,
        id,
        info,
        filledBy,
        filledDate,
        editedBy,
        accesspermit,
        accessdenied,
        notification,
        notifToggle,
      },
    }
  `;
    return memberData
}



function getQueryAllMembersData(){

  const memberData = `
  *[_type == "members"] | order(_createdAt desc) {
      _id,
      _type,
      surname,
      loginID,
      sn,
      title,
      firstname,
      othernames,
      addressRes,
      dob,
      birthday,
      genderStat,
      ageGroup, membership,
      email,
      phoneNo,
      occupation,
      addressOff,
      school,
      instiStat,
      memberStat,
      matStat,
      wedAnn,
      wedAnns,
      batStat,
      'photo':photo.asset->url,
      'photoAsset':photo,
  
      select,
      edit,
      family,
      famStat,
      id,
      info,
      filledBy,
      filledDate,
      editedBy,
      accesspermit,
      accessdenied,
      notification,
      notifToggle,
   }
`;
  return memberData
}


function getQueryLoginMemberIDAndAccessIDs(loginID){

  const memberData = `
  *[_type == "members" && loginID == '${loginID}'] | order(_createdAt desc) {
      id,
      accesspermit,
      surname,
      family,
   }
`;
  return memberData
}



export {getQueryMembersAllData, getQueryLoginMemberAllData, 
    getQueryParentAllData, getQueryLoginMemberByLoginID, getQueryByMemberPersonalID,
    getQueryByMemberPersonalIDAndFamData, getQueryLoginMemberDataAndFamData,
   getQueryAllMembersData, getQueryLoginMemberIDAndAccessIDs,
   getQueryByMemberEmail, getQueryNonRegisteredUserMails,
   getQueryByMemberPersonalIDLimtedData, getQueryByUserLoginIDLimtedData};


    
/*{
  "totalReg":count(*[ _type == "members" ]),
  "males":count(*[ _type == "members" && genderStat == "Male" ]),
   "females": count(*[ _type == "members" && genderStat == "Female" ]),
  "ministers": count(*[ _type == "members" && memberStat == "Minister" ]),
    "workers": count(*[ _type == "members" && memberStat == "Worker" ]),
  "nonWorkers": (count(*[ _type == "members" ]) - count(*[ _type == "members" && memberStat == "Worker" ])),
    "baptised": count(*[ _type == "members" && batStat == "Baptised" ]),
    "nonBaptised": (count(*[ _type == "members" ]) -count(*[ _type == "members" && batStat == "Baptised" ])),
    "noBirthdayStat": count(*[ _type == "members" && dob == "" ]),
  "birthdayStat": (count(*[ _type == "members" ]) - count(*[ _type == "members" && dob == "" ])),

}*/


