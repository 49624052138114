import { configureStore } from '@reduxjs/toolkit';


import loginReducer from "./reducers/login";
import userReducer from './reducers/user';
import persistReducer from './reducers/persistReducer';
import adminReducer from './reducers/adminReducer';
import  devReducer from './reducers/dev';
import pstCornerReducer from './reducers/pstCornerMsg';
import userSubReducer from './reducers/userSub';
import membersDataAllReducer from './reducers/membersDataAll';
import photoGalleriesReducer  from './reducers/photoGalleries';
//import onlineOfferingReducer  from './reducers/onlineOffering';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    user: userReducer,
    userSub: userSubReducer,
    persist: persistReducer,
    admin: adminReducer,
    dev:devReducer,
    pstCornerMsg:pstCornerReducer,
    membersDataAll: membersDataAllReducer,
    photoGalleries: photoGalleriesReducer,
 },
});
