import React, { useEffect, useState } from 'react';
import {useSelector} from 'react-redux';
import {client} from '../../../../client';
import FeedbackMessages from './components/FeedbackMessages';
import gof1 from '../../../../assets/gofamint1.jpg';
import logo from '../../../../assets/gofamintLogo.png';

const Index = () => { 
  const {loginForm} = useSelector((state)=>state.login);
  const isOnline = loginForm.isOnline;
  const [fetchContents, setFetchContents] = useState([]);
  const [isFetched, setIsFetched] = useState(false);

  async function fetchFeedbackMsgs() {
        await client.fetch(queryFeedbackMsgs).then((res)=>{
          if(res.length>0){
            setFetchContents(res);
          };
        }); 
  }

useEffect(()=>{
  if(!isFetched){
    fetchFeedbackMsgs();
    setIsFetched(true)
  }
},[isOnline]);


return (
    <div className='bg-[#ddd] mt-3'>
        <FeedbackMessages
          fetchContents={fetchContents}
        />
    </div>
  )
}

export default Index;


const queryFeedbackMsgs =
`
  *[_type == "feedbackMessages"] | order(date desc){
      _id,
      title,
      userName,
      userEmail,
      phoneNo,
      message,
      additionalInfo,
      postedBy->{
        _id,
        userName,
        image
      },
      datePosted
  }
`;





const msgs = [
  'Countless of stories are told of answered prayers: revealing testimonies of diseases cured, illnesses healed, forgiveness granted, relationships restored, hungry children fed, bills paid and lives and souls saved through the efficacy of prayer. \n\nSo, there are evidences around us as proofs that God answers prayer. But because most of these evidences are personal; many who think of “evidence” as the only person observable, measurable, and reproducible proofs cannot understand how faith people live by the notion that God answers prayers. The truth however is that God’s answer to your prayers is not dependent on whether the godless man would believe it or not.',
  'The text of today is Jesus’ word, and this is the same thing that the Psalmist reveals that the Almighty God says i.e.“call on me and I will answer”(Psalm 50:15). God is God, and He is not dependent on anyone. If He says it, surely He will do it. \nBut, the answer to the prayer of one who decides to pray would also depend on whether such a person believes in the ability of God to answer his/her prayer. \nThis is why the writer of the Book of Hebrews says: “...for he who comes to God must believe that he exists, and that he is a rewarder of those who diligently seek him" (Hebrews 11:6).',
  'If God says: “call on me...I will answer” and Jesus Christ also says: “Ask and you will receive”; brethren, it is a done deal. No matter what comes your way, all you need to do is to call on God, and wait for His answer. He will surely answer. Because He says: “My Word shall not return to me void,” in other words, His promise to answer cannot be in vain; it is sacrosanct and nothing can change it. This is why John in his letter explains that: “...whatever we ask we receive from him, because we keep His commandments and do what pleases Him (1 John 3:22). That which pleases Him is your faith. Your faith is the proof that you know that He cannot lie, you know that He does not fail in His promises; you know that since He gave Jesus, there is nothing He wouldn’t give. And so, Your Faith pleases Him and it brings forth your answer. (Romans 8:32)',
  'In the scriptures, we read that Elijah prayed for fire from heaven and it came down (2 Kings 1:12), Hezekiah prayed for deliverance and He received it (2 Kings 19:19), the Apostles prayed for boldness and they were bolden (Acts 4:29). Even Gideon prayed to see signs and God showed him signs as recorded in Judges 6:33-40. All these examples were written by eyewitnesses to the events, they are therefore clear evidence of answered prayers.',
  'Brethren, whatever you have been looking onto God for in the past year, trust Him, He has you on His agenda, because you dared to ask Him. He will surely do it. Remember that He is your creator and He knows the right time for everything to come your way with perfect peace attending it. So, in this victory month of fasting and prayer, be engaged in serious praying and keep trusting. God answers prayers. He will answer yours. Because you trusted God and waited on Him, the time for your favour is now (Psalms 102:13).',
];

const defMsg = [
  {
    title:'Contact Us messages',
    userName:'Bola Adebayo',
    userEmail:'bolabayo@gmail.com',
    phoneNo:'08064523512',
    message:msgs[0],
    datePosted: new Date().toUTCString(),
    additionalInfo:'Please, contact me',
    postedBy:{
      image:logo,
    }
  },

  {
    title:'Contact Us messages',
    userName:'Bola Adebayo',
    userEmail:'bolabayo@gmail.com',
    phoneNo:'08064523512',
    message:msgs[1],
    datePosted: new Date().toUTCString(),
    additionalInfo:'Please, contact me',
    postedBy:{
      image:gof1,
    }
  },
  {
    title:'Contact Us messages',
    userName:'Bola Adebayo',
    userEmail:'bolabayo@gmail.com',
    phoneNo:'08064523512',
    message:msgs[2],
    datePosted: new Date().toUTCString(),
    additionalInfo:'Please, contact me',
    postedBy:{
      image:'',
    }
  }
];
