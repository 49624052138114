import React from 'react';
import { getShortDate } from '../../../../dates';

export const TableSpecialTemplate = ({tableHeader, tableBody, dataKeys, selectedMemberFamID}) => {
     const [selectedIndex, setSelectedIndex] = React.useState('');
    
     
     const getCurrency=(val)=>{
        let display = "-";
        if(val===0){
            display = "-"
        }else{
            display = new Intl.NumberFormat('en-US', 
            { style: 'currency', currency: 'USD' }).format(val);
        }
        return display
     }
      return (
        <div className="w-[95vw] sm:w-[95vw] h-[400px] overflow-auto border-2 border-solid resize-y">
        <table className="border-spacing-0 p-0 border-[1px] text-[11px] sm:text-[13px] border-solid border-[blue]" width="700px">
            <thead>
                <tr className='min-h-[25px] even:bg-[#dfdfdf]'>
                    <th 
                        className={`min-w-[30px] w-[30px] sm:min-w-[40px] sm:w-[40px] left-0 bg-[#333] ${zIndex} ${frozenCols}`}>
                        {'SN'}
                    </th>
                    <th  
                        className={`min-w-[70px] w-[70px] sm:min-w-[100px] sm:w-[100px] ${zIndex} 
                            left-[30px] sm:left-[40px] bg-[#333] cursor-pointer ${frozenCols}`}
                         >
                        {tableHeader[0]}
                    </th>
                    <th  
                        className={`whitespace-nowrap ${frozenCols}  bg-[#353486] min-w-[100px]`}
                         >
                        {'Total'}
                    </th>
                    {
                        tableHeader?.slice(1).map((keys, i)=>{
                            
                            const isPayment = keys.includes('payment') || keys.includes('transfer')? true : false;
                            const isProgram = keys.includes('|');
                            const header = isProgram? keys.split('|') : keys; 
                            let dateStr = "";
                            if(isProgram){
                                const dateStrArr = getShortDate(header[0]);
                                dateStr = `${dateStrArr[0]} ${dateStrArr[2]} ${dateStrArr[1]}, ${dateStrArr[3]}`;
                            }
                             const headerCol = `${keys === "Total"? 'bg-[#353486]' : 'bg-[#555]'}`;
                             const unFrozen = `${isPayment? 'bg-[coral]': headerCol} min-w-[100px]`; 
                            return(
                                <th key={`${i}+header`}
                                    className={`whitespace-nowrap
                                    ${frozenCols} ${unFrozen}`}
                                    >
                                    {isProgram? 
                                        <div className='flex flex-col justify-center items-center flex-wrap'>
                                            <span>{dateStr}</span>
                                            <span>{header[1].substring(0, 18)}</span>
                                        </div> 
                                        :header}
                                </th>
                            );
                        })
                    }
                    
                </tr>
            </thead>
            <tbody>
                {
                    tableBody?.map((body, index)=>{
                        
                        const even = index%2 === 1? true : false;
                        const rows = `p-0 px-[5px] outline-[1px] outline border-none outline-offset-[-1px] outline-[dodgerblue] whitespace-nowrap`;
                        const rowsTotal = `p-0 px-[5px] outline-[1px] outline border-none outline-offset-[-1px] outline-[dodgerblue] whitespace-nowrap font-bold`;
                        const frozenCol = `
                             ${rows} ${even? 'bg-[silver]' : "bg-[aliceblue]"}`;
                        const frozenSn = `
                            ${rows} ${even? 'bg-[silver]' : "bg-[aliceblue]"}
                            ${"text-[blue]"} 
                           `;
                        const selectedHlight = `${selectedIndex === index? 'text-[red]' : ""}`;
                        const sumTotalLine = tableBody.length-1 === index;
                        //const sumTotalLinePayment = tableBody.length-1 === index && ;
                        //const lastKey = 3 === index;

                        return(
                            <tr className="min-h-[25px] h-[25px] even:bg-[#dfdfdf] " key={`${index}+tab`}>
                             <td className={`left-0 ${frozenSn} ${selectedHlight} sticky  cursor-pointer font-bold hover:text-[red]
                                `}
                                onClick={()=>{selectedMemberFamID(tableBody[index].familyID); setSelectedIndex(index)}}>
                                    {sumTotalLine? "" : index+1}
                                </td>
                                <td className={`left-[30px] sm:left-[40px] sticky ${frozenCol} ${selectedHlight}
                                    `}>
                                    {body[dataKeys[0]]}</td> 
                                <td className={`${rowsTotal} ${sumTotalLine? 'bg-[navy] text-white' : 'text-[navy]'}
                                    `}>
                                    {getCurrency(body.Total)}
                                </td>   
                                {
                                    dataKeys?.slice(1).map((keys, idx)=>{
                                        const notNAN = isNaN(body[keys])? true : false;
                                       
                                        var noType = "";
                                        if(!notNAN){ //If is a number
                                            if(body[keys]<0){
                                                noType = 'NEG'
                                            }else{ noType = "POS"}
                                        }
                                        var display = ""; //isNaN(body[keys])? body[keys] : 
                                                //new Intl.NumberFormat('en-US', 
                                               // { style: 'currency', currency: 'USD' }).format(body[keys]);
                                        if(isNaN(body[keys])){
                                            display = body[keys]
                                        }else{
                                            display = getCurrency(body[keys]);
                                        }
                                        
                                        let totalRow;
                                        if(keys==="Total"){
                                            if(sumTotalLine){
                                                totalRow = "text-white"
                                            }else{totalRow = 'text-[navy]';}
                                        }

                                        return(
                                            <td key={`${idx}+header`}
                                                className={`${rows} ${totalRow} ${noType==="NEG" && !sumTotalLine? 'text-[red]' : ''}
                                                    ${sumTotalLine? 'bg-[navy] text-white' : ''}`}>
                                                {display}
                                            </td>
                                        );
                                    })
                                }           
                                
                            </tr>
                        );
                    })
                }
            </tbody>

        </table>
    </div>
  )
};


export default TableSpecialTemplate;



const frozenCols = `sticky  top-0  text-white  min-h-[30px] h-[30px] 
        text-left px-[5px]  
        p-0 outline-[2px] outline-[dodgerblue] outline border-none outline-offset-[-1px]`;
    const hiddenFlds = `whitespace-nowrap  min-w-[100px] bg-[navy] ${frozenCols}`;
    const zIndex = 'z-[10]';
