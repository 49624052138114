import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import FormView from "./FormView";
import Collapsible from '../../../Collapsible';
import Confirmation from "../../components/Confirmation";
import { disSeltdTab, disUpdateRecord } from "../../../../context/reducers/user";
import { getFormField } from "../../assets/textFieldsMain";
import { tabsNav } from "../../headerTabs/headerSchema";
import validateFormInputs from "../../modules/validFormInputs";
import { BsCloudUpload, BsExclamationTriangleFill } from "react-icons/bs";
import { validateFieldsInputs } from "../../modules/validateFieldsInputs";
import { client } from "../../../../client";
import { assignPersonalID, assignFamilyID, assignOtherHiddenInfo} from '../../modules/assignSubInfo';
import { fetchContents } from "../../about/fetchDatamodules";
import useOnline from 'use-online';
import { createStdMemberDoc } from "../../modules/sanityDocOperations/updateAndDeleteDoc";

const FormsView =()=>{
    const dispatch = useDispatch();  //updateRecord:{start:false, recordToUpdate:[]},
    const {updateRecord,} = useSelector((state)=>state.user);
    const { recordToUpdate} = updateRecord;
    const {user} = useSelector((state)=>state.login);
    const [formValues, setFormValues] = useState([]);
    const [initRecords, setInitRecords] = useState({count:0});
    const {recordToUpdateCount, startUpdate} = updateRecord;
    const [startUpdateMsg, setStartUpdateMsg] = useState(updateMsg1);
    const [famStats, setFamStats] = useState({parentsCount:0, childCount:0});
    //const [user, setUser] = useState({parentsCount:0, childCount:0});
    const [onEdit, setOnEdit] = useState("");
    const [editToggle, setEditToggle] = useState("Edit");
    const [children, setChildren] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [showAddSCBtn, setShowAddSCBtn] = React.useState(false);
    const [isError, setIsError] = React.useState({msg:"", show:false});
    const [loadingMsg, setLoadingMsg] = React.useState({msg:"", loading:false});
    const [pendingAct, setPendingAct] = React.useState({});
    const [imageAsset, setImageAsset] = React.useState([]);
    const [loadingImage, setLoadingImage] =React.useState(false);
    const [uploadingImageFormIndex, setUploadingImageFormIndex] =React.useState("");
    const isonline = useOnline();
    const [updateReady, setUpdateReady] = useState({isReady:false});

    const imageSrc = "";
    //console.log(updateRecord)
    const dispatchCall =(type)=>{
        if(type==="UPDATESUCCESS"){
            //dispatch(disUpdateRecord(
            //    {...updateRecord, startUpdate:false, user:"", recordToUpdateCount:0, recordToUpdate:[]}
            //    ))
        }
    }
    let onChangeHandler = (e, i, imgSrc) => {
        let newFormValues = [...formValues];
        if(e.target.name==="photo"){
            uploadImage(e, i)
        }else if(e.target.type==="radioSpec"){
            newFormValues[i][e.target.name] = e.target.value;
        }else{
            newFormValues[i][e.target.name] = e.target.value;
        }
        setFormValues(newFormValues);
        setLoadingMsg({loading:false, msg:''});
        setUpdateReady({...updateReady, isReady:true});
      };
    
      const uploadImage = (e,i) => {
        const selectedFile = e.target.files[0];
        const {name, size, type} = selectedFile;
        setUploadingImageFormIndex(i);
        setLoadingImage(true);
          client.assets
            .upload('image', selectedFile, { contentType: type, filename: name })
            .then((imageAsset) => {
              setImageAsset(imageAsset); 
              formValues[i].photo = imageAsset.url;
              formValues[i].imageUrl  = imageAsset._id;  //To store image_ref: imageAsset._id. This only happen when there's a new current upload
              setLoadingImage(false);
            })
            .catch((error) => {
              console.log('Upload failed:', error.message);
            });
      };
      
      const addForm = (addFam) => {
        const childCount = "child"+(famStats.childCount +1);
        const spouseForm = getFormField('spouseForm');
        let childForm = getFormField('childForm');
        childForm = {...childForm, famStat:childCount, type:'NEWFORM'};

        if(addFam === "PARENT" && famStats.parentsCount < 2){
            //const parent1Form = formValues.find(form=> form.famStat==="parent");
            const formValuesNew = [...formValues, {...spouseForm, famStat:'parent', type:'NEWFORM'}];
            setFormValues(formValuesNew);
            //console.log(formValuesNew);
        }
        if(addFam === "CHILD"){
            setChildren([...children, childCount]);
            setFormValues([...formValues, childForm]);
        }
      };
       function confirmAction(param1, param2){
            setPendingAct({param1, param2});
            setShowModal(true);
       };
      const removeForm = (i, famStat) => {
            let forms = [...formValues];
            forms.splice(i, 1);
            setFormValues(forms);
            //dispatchCall('UPDATERECORD',forms);

            //dispatch(disFormDataPosted(forms));
            if(famStat.includes('child')){
                const newChildren = children.filter((item)=>item !== famStat);
            setChildren(newChildren);
            }
      };
      function closeModalHandler(takeAct){
        if(takeAct){
            removeForm(pendingAct.param1, pendingAct.param2);
        }
        setShowModal(false);
      }
    const setOnEditName=(el)=>{
        if(editToggle==="Edit"){
            setEditToggle("Save");
            setOnEdit(el);
        }else{
            setEditToggle("Edit");
            setOnEdit("");
        }
    }
    
    const onSubmitHandler=(e)=>{
        e.preventDefault();
        if(!updateReady.isReady || loadingImage){
            setIsError({show:true, msg:"No changes made!"})
        }else{

            const validateFldsRes = validateFieldsInputs(formValues);
            //console.log(initRecords);
            const initMatStat = initRecords.records[0].matStat;
            const validateRes = validateFormInputs(formValues, initMatStat);
            if(validateFldsRes.error){
                setIsError({show:true, msg:validateFldsRes.msg});
                return 
            }else{setIsError({show:'', msg:''});}

            if(validateRes.error){
                setIsError({show:validateRes.error, msg:validateRes.msg});
                return //if(formValues[0].matStat==="Married"){setShowAddSCBtn(true)}else{setShowAddSCBtn(false)};
            }
            if(isonline){
                setLoadingMsg({msg:'File uploading, please wait...!', loading:true, type:'INITIAL'}); 
                processUpdateForms(formValues);
            }else{console.log('Please, login')} 
        }
    }
    const processUpdateForms=(forms)=>{
        fetchContents('MEMBERSCOUNT').then((countRes)=>{
            if(countRes.totalMembers){
                const formsWithPID = assignPersonalID(forms, countRes.totalMembers);
            
                const formsWithPIDFID = assignFamilyID(formsWithPID);
                
                const formsWithPIDFIDOInfo = assignOtherHiddenInfo(formsWithPIDFID, countRes.totalMembers, user);
                //console.log(formsWithPIDFIDOInfo);
                replaceMultipleFiles(formsWithPIDFIDOInfo);
            }
            //setTimeout(()=>)
        });
    }
    
    const replaceMultipleFiles = async (membersData) => {
        if(membersData.length >0){

            for (let index = 0; index < membersData.length; index++) {
            //const doc = membersData[index];
            const form = membersData[index];
            const stdDoc = createStdMemberDoc(form);
            const doc = stdDoc;
            await client.createOrReplace(doc).then(res => {
                //console.log(`File no. ${res.sn} successfully uploaded!`);
                setStartUpdateMsg(updateMsg2);
                setIsError({show:'', msg:''});
                dispatchCall('UPDATESUCCESS');
                exitViewPage();
                //console.log(res);
            }).catch(e => {
                console.log(e.error);
                setLoadingMsg({loading:true, msg:'File upload failed!', type:'FAILED'})
            });
            }
            setLoadingMsg({loading:false, msg:'', type:''})
        }else{
            console.log('No data to upload!')
        }
      }

      
    function goToPage(page){
        dispatch(disSeltdTab(tabsNav[page]));
    }
    function exitViewPage(){
        dispatch(disUpdateRecord(
          {...updateRecord, startUpdate:false, user:'', recordToUpdateCount:0, recordToUpdate:[]}))
      }

    const formHeader = formValues[0]?.surname? `${formValues[0]?.surname}'s Data Form` : 'Membership Data Form';

    React.useEffect(()=>{
        //Watch changes in Marital Status for single View
        if(initRecords.count === 1){
            const formValuesCopy = [...formValues];
            const userInitForm = initRecords.records[0];
            const userForm = formValuesCopy[0];  
            if(userForm.matStat === "Married"){
                setShowAddSCBtn(true);
                const userFormNew = {...userForm, famStat:"parent", family:""}; //Replace famStat and remove famID.
                formValuesCopy[0] = userFormNew; //Replace userform.
                setFormValues(formValuesCopy);
            }else{
                setShowAddSCBtn(false);
                const userFormNew = {...userForm, famStat:userInitForm.famStat, family:userInitForm.family};
                setFormValues([userFormNew]);
            }
        }
    },[formValues[0]?.matStat]);

    React.useEffect(()=>{
        const childCount = formValues.filter((item)=>item.famStat?.includes('child')).length;
        const parentsCount = formValues.filter((item)=>item.famStat==="parent").length;
        setFamStats({childCount:childCount? parseInt(childCount) :0, parentsCount:parentsCount? parseInt(parentsCount) :0});
        setChildren(Array.from({length:childCount}, (_,i)=> 'child'+(i+1)));
        setIsError({show:false, msg:""});
        //Watch if Spouse Added is More than Two
        const parentsForms = formValues.filter((item)=>item.famStat==="parent");
        const parentsCountLen = parentsForms.length
        if(parentsCountLen>2){
            const lastAdded = parentsForms.at(-1);
            //console.log(lastAdded);
            const lastAddedIndex = formValues.indexOf(lastAdded);
            formValues.splice(lastAddedIndex, 1);
        }
    },[formValues]);
    
    React.useEffect(()=>{
        //Initial FormVals set
        const copyData = recordToUpdate?.map((item)=> {return {...item, viewForm:true}});
        const len = copyData?.length;
        if(len > 1){setShowAddSCBtn(true)}else{setShowAddSCBtn(false)};
        setFormValues(copyData);
        const initCopy = [...copyData];
        setInitRecords({count:initCopy.length, records:initCopy});
        setIsError({show:false, msg:""}); 
    },[]);

    //console.log(recordToUpdate)
    //5. SecurePage- Developer. startUpade should be
    //i). startUpdate? 'hidden' : 'flex' ii). startUpdate? '' : 'hidden'
    return(
        <div className={``}>
            <div className={`${startUpdate? 'hidden' : 'flex'} ${startUpdateMsg.includes('Congratulations')? 'text-green-500' : 'text-red-700'}
                w-full items-center flex-col justify-center mt-[100px]  font-bold text-xl`}>
                <p className={`text-center px-4`}>{startUpdateMsg}</p>
                <p className='my-5 bg-blue-700 py-2 px-5 rounded-md hover:bg-blue-500 text-white cursor-pointer'
                    onClick={()=>goToPage("RECORDABOUTHOME")}>
                    Go to Home
                </p>
            </div>
            <div className={`${startUpdate? '' : 'hidden'} m-[1px] sm:m-3 pb-3 border-solid border-blue-900 border-2`}>
                {/*<p className="">Parent:{famStats.parentsCount} || Child:{famStats.childCount}</p>*/}
                <form onSubmit={onSubmitHandler}>
                    <h1 className='bg-purple-800 text-xl text-white p-3'>{formHeader}</h1>
                    {
                        formValues?.map((form, formIndex)=>{
                        //const form = formVal? formVal : {};
                        const childNo = children.indexOf(form.famStat);
                        const famStat = formValues[formIndex]?.famStat;
                        const user = famStat==="parent"? `${formIndex===0? 'Parent Information' :'Spouse Information'}` :
                                     famStat?.includes('child')? `Child ${childNo +1} Information` : 'User Information';
                        const userStat = famStat==="parent"? `${form.firstname}'s Info ${formIndex===0? '(Parent)':'(Spouse)'}` : 
                                     famStat?.includes('child')?  `${form.firstname}'s Info (Child)` : `${form.firstname}'s Info (User)`;
                        const userInfo = form.firstname? userStat : user;
                        

                        return (
                            <div className="" key={`${formIndex}+formIn`}>
                                    <Collapsible
                                      contStyle="my-3 mx-1 sm:mx-3 border-2 border-solid border-teal-700"
                                      titleStyle="bg-teal-600 text-white"
                                      iconCol="fill-white"
                                      iconHoverCol="hover:fill-[yellow]"
                                      removeForm={()=>confirmAction(formIndex, form.famStat)}
                                      addedInfo={userInfo}
                                      disableDelete={formIndex < recordToUpdateCount? true : false}
                                        >
                                        <FormView
                                            onChangeHandler={e=>onChangeHandler(e, formIndex)}
                                            setOnEditName={setOnEditName}
                                            formValues={form}
                                            indvForm={formValues[formIndex]}
                                            imageSrc={imageSrc}
                                            onEdit={onEdit}
                                            setOnEdit={setOnEdit}
                                            editToggle={editToggle}
                                            setEditToggle ={setEditToggle}
                                            famStats={famStats}
                                            autoComplete="autoComplete"
                                            isReadOnly ={form.viewForm}
                                            initRecordsMatStat={initRecords.records[0].matStat}
                                            formIndex={formIndex}
                                            uploadingImage={loadingImage}
                                            uploadingImageFormIndex={uploadingImageFormIndex}
                                            //required={}
                                        />  
                                    </Collapsible>
                            </div>
                        );
                        })
                    }
                    <div className={`${isError.show? 'visible' : 'invisible'} flex flex-row pb-1 px-4 text-red-700`}>
                        <BsExclamationTriangleFill color="red" size={28}/> 
                        <p className="inline-block ml-3">{isError.msg}</p>
                    </div>
                    <div className={`${loadingMsg.loading? '' : 'hidden'} ${loadingMsg.type==="FAILED"? 'text-red-700' : 'text-green-700'} flex flex-row pb-1 px-4 `}>
                        <BsCloudUpload color="blue" size={28}/> 
                        <p className="inline-block ml-3">{loadingMsg.msg}</p>
                    </div>
                    <div className={`${showAddSCBtn? 'flex' : "hidden"} flex-row flex-wrap`}>
                        <div 
                            className={`py-2 px-5 md:px-8 border-2 border-solid border-[silver] rounded-lg 
                            text-white font-bold ml-5 
                            ${famStats.parentsCount < 2? "bg-emerald-800 cursor-pointer active:bg-gray-500 hover:bg-emerald-600 hover:border-[lime]" : 'bg-stone-400'}`}
                            onClick={()=>addForm("PARENT")}>
                            Add Spouse
                        </div>
                        <div  
                            className="bg-emerald-800 py-2 px-5 md:px-8 cursor-pointer active:bg-gray-500 hover:bg-emerald-600 hover:border-[lime] border-2 border-solid border-[silver] rounded-lg text-white font-bold  ml-5"
                            onClick={()=>addForm('CHILD')}>
                            Add Child
                        </div>
                    </div>
                    <button type="submit" 
                        className=
                        {`${!updateReady.isReady || loadingImage? updateBtn.notReady : updateBtn.isReady}`}
                        >
                        Update
                    </button>

                </form>
                <Confirmation 
                    showModal={showModal}
                    closeModal={closeModalHandler}
                    title={`Delete ${formValues[pendingAct?.param1]?.surname? 
                        formValues[pendingAct?.param1]?.surname +' information' : "this information"}`} 
                    msgArr={confirmInfo.delete}
                />
            </div>      
        </div>
    );
}

const updateBtn ={
    isReady:'bg-[mediumblue] py-2 px-6 mt-4 active:bg-gray-500 hover:bg-[cyan] hover:border-[mediumblue] border-2 border-solid border-[coral] rounded-lg text-white font-bold  md:px-20 ml-5',
    notReady:'bg-[silver] py-2 px-6 mt-4 border-2 border-solid border-[gray] rounded-lg text-black font-bold  md:px-20 ml-5',
};
export default FormsView;

const errorMsg ={
    error1:"You are to Add either your Spouse or Child or both Forms and fill or set Marital Status to Single.",
}
const updateMsg1 = "Go back to Home and follow the instruction if you want to View or Update your record!";
const updateMsg2 = "Congratulations, Form update was successful!";


const confirmInfo={
    delete:["Do you really want to delete this information?", 
        "You can cancel or proceed to delete"]
}

function getField(ev){
    let res = "";
    if(ev === "Male"){res = "Female";}
    if(ev === "Female"){res = "Male"};
    return res;
}



const objUser = {
    addressOff: "Ikeja",
    title:'Brother',
    addressRes: "PLOT 435, MICHAEL OKPARA WAY",
    batStat : "Baptised",
    dob: '1989-10-23',
    email: "james@gmail.com",
    family: "",
    firstname: "Monday",
    genderStat: "Male",
    ageGroup:"",
    famStat: "",
    instiStat: "Professor",
    matStat: "Married",
    memberStat: "Worker",
    occupation: "Accountant",
    othernames: "Titilope",
    phoneNo: "08064205333",
    photo: "blob:http://localhost:3000/c51f8c1d-37bb-478f-95af-1b8cedce2df6",
    school: "Uniben",
    surname: "Adegboye",
    wedAnn: "2020-02-15",
};


/*
*[ _type == 'members' && surname == 'Akinola' ]{
  _id,
  surname, firstname,
  matStat, notification, 
  'memberNotif' : *[ _type == 'memberNotification' && id == 'akinola.manaseh.male.no42' ] {
    title,message,date, newNotif
      
  }  
  }
*/