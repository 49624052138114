//import {sortDatesByYrMn} from '../../utils/sortData';


import dramaEvening2017 from '../../assets/mediaPage/dramaEvening/_index';
import hisAndhers2017 from '../../assets/mediaPage/hisAndHers-Dec2017/_index';
import pstOgundareInstallation from '../../assets/mediaPage/pastorInstallation-2018/_index';
import churchDedication2019 from '../../assets/mediaPage/churchDedication-2019/_index';
import newness2021 from '../../assets/mediaPage/newness-Aug2021/_index';
import perfectLove2022 from '../../assets/mediaPage/perfectLove-Mar2022/_index';
import childrenDay2022 from '../../assets/mediaPage/childrenDay-2022/_index';
import newness2022 from '../../assets/mediaPage/newness-Dec2022';
import womenConfe2022 from '../../assets/mediaPage/womenConference2022';

function getPhotosGalArr(){
    const photosGalArr = [
        hisAndhers2017, childrenDay2022, 
        dramaEvening2017, churchDedication2019, 
        newness2021, pstOgundareInstallation, 
        perfectLove2022,newness2022,
        womenConfe2022,
    ];
    
    /*var sortRes = sortDatesByYrMn(photosGalArr, 'FROMRECENT');
    if(onlinePhotos?.length){
        const photosGalArrs = [...onlinePhotos, ...photosGalArr];
        sortRes = sortDatesByYrMn(photosGalArrs, 'FROMRECENT');
    }else*/
    return photosGalArr
}




export { getPhotosGalArr};