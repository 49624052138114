import React from 'react'

const CustomModal2 = ({children, fullHeight, contStyle, show, onClickContainer}) => {
  return (
    <div className={`bg-blackOverlay w-full overflow-x-hidden overflow-y-auto fixed-top fixed-bottom
        ${show? '' :'hidden'} z-3  ${contStyle} ${fullHeight? "" : 'h-screen'}`}
        >
        {children}
    </div>
  )
}

export default CustomModal2

