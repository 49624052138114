//import offeringBfwd from "../onlineoffering/offeringBfwd";
import offeringHBreakthrough from "../onlineoffering/offeringHBreakthrough";
import offeringVictory from "../onlineoffering/offeringVictory";
import offeringSundaySchoolPrep from "../onlineoffering/offeringSundaySchool";
import offeringVigils from "../onlineoffering/offeringVigil";
import { sortFullDate } from "../../../../../../utils/sortData";


const getOfferingDataReport=(allDataArray, reportDate, openingReportDate,)=>{
    /***** DECIDING DATA TO USE FOR REPORT */
    let reportData =[];
    if(reportDate && !openingReportDate){
        reportData = getCutOffDateData(allDataArray, reportDate, 'ONBEFORE');
    }
    if(openingReportDate && !reportDate){
        const openingBalData = getCutOffDateData([...allDataArray], openingReportDate, 'ONBEFORE');
        const openingBalObj = mergeMultipleOfferings(openingBalData, openingReportDate);
        const allDataAfterOpening = getCutOffDateData([...allDataArray], openingReportDate, 'AFTER');
        reportData = [openingBalObj, ...allDataAfterOpening];
    }
    if(openingReportDate && reportDate){
        //const openingReportDate = '2023-01-05'; const reportDate = '2023-01-25';
        const openingBalData = getCutOffDateData([...allDataArray], openingReportDate, 'ONBEFORE');
        const openingBalObj = mergeMultipleOfferings(openingBalData, openingReportDate);
        const allDataAfterOpening = getCutOffDateData([...allDataArray], openingReportDate, 'AFTER');
        const allDataAtClosing = getCutOffDateData(allDataAfterOpening, reportDate, 'ONBEFORE');
        reportData = [openingBalObj, ...allDataAtClosing];
    }
    if(!openingReportDate && !reportDate){
        reportData = allDataArray;
    }
    return reportData
}


function getOfferingData(offPayers, offerings, reportDate, openingReportDate){

    const allData = [offeringVictory, offeringHBreakthrough, offeringSundaySchoolPrep, offeringVigils, offerings];
    const hideFamID = true;
    const defTableHeader = hideFamID? ['Name'] : ['Name', 'FamilyID',];
    const defTableBodyKeys = hideFamID? ['name'] : ['name', 'familyID',];

    const allDataArray = initProcessing(allData);
    const reportData = getOfferingDataReport(allDataArray, reportDate, openingReportDate)

    const allDataArraySorted = sortFullDate(reportData);//Sort allDataArray by date
    const offeringPayers = getNameFamilyID(reportData, [...offPayers]);
    const tableHeaders = getAllHeaders(allDataArraySorted, defTableHeader);
    const tableBodyKeys = getAllHeaders(allDataArraySorted, defTableBodyKeys);
    const tableBody = mapNameToPrograms(allDataArraySorted, offeringPayers);
    
    //console.log(offeringPayers);
    //tableBody (file for each individual)=  [{date:'', docID:'', name:'',familyID:'',program:'',type:'',Total:'', '2023-01-01|B/fwd from 2022':'', '2023-01-02|Anointing Service Day-2':'', etc}, ...]    

    return {tableHeaders, tableBodyKeys, tableBody}
}


function initProcessing(allData){
    const subTotalToAllData = allData.map(item=>{
        return mapSumTotal(item);
    });

    const allDataArray = subTotalToAllData.reduce((res, item)=>{
        //Combine allData (individual meetings) into a single array
        //It will return: [{date:'',docID:'', program:'',total:'',type:'',
        // data:[{name:'', familyID:'', amount:''},{name:'', familyID:'', amount:''}, {}, {}],}]
        return [...res, ...item]
    },[]);

    return allDataArray
}
function getCutOffDateData(allDataArray, specDate, spec){
    //Return all data with dates on or before(ONBEFORE) or AFTER the specified specDate
    const allDataArrayFiltered = allDataArray.filter((item=> { 
        const itemDate = new Date (item.date).getTime();
        const setDate = new Date (specDate).getTime();
        const result = spec === 'ONBEFORE'? setDate >= itemDate : setDate < itemDate;
        return result;
    }));
    return allDataArrayFiltered
}

function mapSumTotal(array){
    const subTotal = array.map((item)=>{ 
         const subTotal = getSumTotal(item.data, 'amount');
         return {...item, total:subTotal}
     });
     return subTotal
 }
 

function getSumTotal(array, key){
   const subTotal = array.reduce((res, item)=>{ 
        return res + parseFloat([item[key]])
    },0);

    return subTotal
}

function getSumTotalMultiKeys(array){
    const values = Object.values(array);
    const sum = values.reduce((accumulator, value) => {
    return accumulator + value;
    }, 0);

     return sum
 }

function getAllHeaders(array, defHeader){
    const headers = array.reduce((res, item)=>{ 
         return [...res, item.dateDetails]
     },[...defHeader,]);
 
     return [...headers, 'Total']
 }

 
function getNameFamilyID(arr, offPayers){
    const nameFamIDs = offPayers;
    for (let index = 0; index < arr.length; index++) {
        const items = arr[index].data;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const onList = nameFamIDs.find(famIDs=> famIDs.familyID === item.familyID);
            if(!onList){
                nameFamIDs.push({name:item.name, familyID:item.familyID});
            }
        }   
    }
    return nameFamIDs
}

function mergeMultipleOfferings(specDataArrSorted, specDate){    
    const returnedRes = {
        docID:0,
        data:[],
        date:specDate,
        program:'Opening balance',
        type:'OFFERING',
        dateDetails:`${specDate}|Opening balance`,
        total:'',
    };

    let prosData = [];
    let prosTotal = 0;
    for (let i = 0; i < specDataArrSorted.length; i++) {
        const {data, total} = specDataArrSorted[i];
        prosTotal += parseFloat(total);
        for (let index = 0; index < data.length; index++) {
                const {name, familyID, amount} = data[index];
                
                const findIndv = prosData.find(fnd=> fnd.familyID === familyID);
                if(findIndv?.name){
                    const newIndv = {...findIndv, amount: parseFloat(amount)+findIndv.amount};
                    const newIndvs = prosData.filter(indv=> indv.familyID !== findIndv.familyID);
                    prosData =  [...newIndvs, newIndv]
                }else{
                    const newIndv = {name, familyID, amount};
                    prosData = [...prosData, newIndv]
                }
        };
    };
     return {...returnedRes, data:prosData, total:prosTotal}   
}



function mapNameToPrograms(allDataArrSorted, allOffPayersArr){
    
    const lastItem = allDataArrSorted.length-1;
    const lastDate = allDataArrSorted[lastItem].date;
    const allOffDataArrRes = [];
    var programsOffTotalObj = {};
    for (let index = 0; index < allOffPayersArr.length; index++) {
        const {name, familyID} = allOffPayersArr[index];
        
        var programsObj = {Total:0}; 
        for (let i = 0; i < allDataArrSorted.length; i++) {
            const {docID, dateDetails, date, program,total, type, data} = allDataArrSorted[i];
            let amount = data?.find(item=> item.familyID===familyID)?.amount;
            amount = amount? parseFloat(amount) : 0;
            const sum = parseFloat(programsObj.Total) + amount;
            programsObj = { ...programsObj, [dateDetails]:amount, Total:sum,
                docID, date, program, type,};
            programsOffTotalObj = {...programsOffTotalObj, [dateDetails]:total};
        }
        //const subTotal = getSumTotalMultiKeys()
        const res = {name, familyID, ...programsObj,};
        allOffDataArrRes.push(res);
    }
    const grandTotal = getSumTotalMultiKeys(programsOffTotalObj);
    const totalSumLine = {name:'TOTAL', familyID:'', ...programsOffTotalObj, 'Total':grandTotal, lastDate};
    
    return [...allOffDataArrRes, totalSumLine]
}

export {getOfferingData, getSumTotal}
