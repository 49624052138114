import React from 'react';
import { Button, InputText, SelectionTag } from './components/Buttons';
import useLocalStorageSessionStorage from '../../../../utils/hooks/useLocalStorageFromSessionStorage';
import { getLocalStorageData } from './components/modules';
import { getQueryByMemberPersonalIDLimtedData, getQueryByUserLoginIDLimtedData } from '../../admin/queries/queryMembersAllData';
import { fetchContents } from '../../about/fetchDatamodules';
import { useSelector } from 'react-redux';

const UserPageLogin = () => {
    const { value, set, remove } = useLocalStorageSessionStorage("psOgba_User", 0);
    const {user, loginForm} = useSelector((state)=>state.login);
    const [items1, setItems1] = React.useState([]);
    const [items2, setItems2] = React.useState([]);
    const [memberID, setMemberID] = React.useState('');
    const [onlineUserID, setOnlineUserID] = React.useState('');
    const [showBtns, setShowBtns] = React.useState(false);
    const [showLoginError, setShowLoginErro] = React.useState(false);
    const [infoMsg, setInfoMsg] = React.useState({msg:'', show:false});
    const decodedItem = {family_name:'', given_name:'',name:'', picture:'', email:'',
      sub:'', iss:'https://accounts.google.com', email_verified:true};
    const valuesDefault = {user:true, adminPass:true, checkedMember:'member', decoded:decodedItem};
    const [queryValues, setQueryValues] = React.useState(valuesDefault);

    function loginToUserPage(){
        const validatedInfo = [queryValues.decoded.family_name, queryValues.decoded.given_name, 
            queryValues.decoded.name, queryValues.decoded.email, queryValues.decoded.sub,];
            const isNotReady  = validatedInfo.findIndex(e => e === undefined || e === "") > -1;
            if(isNotReady){
                setShowLoginErro(true)
            }else{  
                set({user:queryValues.user, adminPass:queryValues.adminPass, 
                    checkedMember:queryValues.checkedMember, decoded:queryValues.decoded});
                    setShowLoginErro(false);
                setTimeout(()=> window.location.reload(), 100);
            }
            //console.log(queryValues);
    }
    function queryDataHandler(val, type){
          if(type==='LSTORAGE'){
            setInfoMsg({...infoMsg, msg:'LocalStorage data successfully retrieved!'});
          }
          if(type==='SETVALUES'){
            setInfoMsg({...infoMsg, msg:'Data successfully retrieved!'});
          }
          //res1 = user, adminPass, checkedMember, res2: decoded
          const {res1, res2} = getLocalStorageData(val);
          setItems1(res1); setItems2(res2);
          setShowLoginErro(false)
    }
   function clearHandler(){
    const decodedItem = {family_name:'', given_name:'',name:'', picture:'', email:'',
      sub:'', iss:'https://accounts.google.com', email_verified:true};
    const valuesDefault = {user:true, adminPass:true, checkedMember:'member', decoded:decodedItem};
    setItems1([]); setItems2([]);
    setQueryValues(valuesDefault);
    setInfoMsg({...infoMsg, msg:''});
    setShowLoginErro(false)
   }
   function resetStorageHandler(act){
    if(act==='PROCEED'){
        remove();
    } setShowBtns(false);
   }
   function selectedHandler(el){
        const {key, value} = el;
        let storageItemsCopy ={};
        if(key === 'user' || key === 'adminPass'){
            storageItemsCopy = {...queryValues, [key]:value==='true'};
        }else{
            storageItemsCopy = {...queryValues, [key]:value};}
        setQueryValues(storageItemsCopy);
   }
   function onChangeHandler(e){
     setMemberID(e.target.value);
     setInfoMsg({...infoMsg, msg:''});
     setShowLoginErro(false)
   }
   function handleMembersIDQuery(){
    setShowLoginErro(false)
    if(memberID){
        clearHandler();
        setInfoMsg({...infoMsg, msg:'Retrieving decoded data, please wait...'});
        const query =  getQueryByMemberPersonalIDLimtedData(memberID);
        fetchContents('QUERY', query).then((res)=>{
            if(res.length){
                const {email,surname, firstname, loginID, photo} = res[0]; 
              const decodedNew =  {...decodedItem, family_name:surname, given_name: firstname, 
                name:`${firstname} ${surname}`, picture:photo, email, sub:loginID};
                const queryValuesNew = {...queryValues, decoded:decodedNew};
                setQueryValues(queryValuesNew);
                queryDataHandler(queryValuesNew, 'SETVALUES');
                setInfoMsg({...infoMsg, msg:'Members data successfully retrieved!'});
            };
        });
     }
   }

  React.useEffect(()=>{
    if(user.loginID){
        const query = getQueryByUserLoginIDLimtedData(user.loginID);
        fetchContents('QUERY', query).then((res)=>{
            if(res.length){
                const {id} = res[0]; 
                setOnlineUserID(id);
            };
        });
    }
  },[user.loginID]);

  return (
    <div className='bg-slate-600'>
        <div className='p-5 text-white'>
            <div className={`border ${loginForm.isOnline? 'border-yellow-500' :'border-gray-400'}
                p-3`}>
                <p className={`${loginForm.isOnline? 'text-[lime]' :'text-gray-300'}`}>
                    Online User: {loginForm.isOnline? 'TRUE' :'FALSE'}
                </p>
                <div className={`${loginForm.isOnline? '' :'hidden'}`}>
                    <p>Name: {user.fullName}</p>
                    <p>Email: {user.email},</p>
                    <p>LoginID: {user.loginID}, MemberID: {onlineUserID}</p>
                    <p>Member: {loginForm.checkedMember}, Admin: {loginForm.adminPass? 'TRUE':'FALSE'}</p>
                </div>
            </div>
            <p className='text-yellow-300 pt-5'>LOGIN TO A USER PAGE</p>
            <p>Query Members data by clicking it after entering his/her personalID, set: user, adminPas, checkedMember and then LOGIN</p>
            <div>
                

                <SelectionTag title="user" opt1={true} opt2={false}
                 setSelected={selectedHandler}/>
                <SelectionTag title="adminPass" opt1={true} opt2={false}
                 setSelected={selectedHandler}/>
                <SelectionTag title="checkedMember" opt1='member' opt2='nonmember'
                 setSelected={selectedHandler}/>

            </div>
            <div className='p-2 border border-gray-200'>
                <p className='text-yellow-300'>QUERIES</p>
                <div className='flex flex-row gap-3 flex-wrap mb-3'>
                    <Button
                        title="Query LocalStorage"
                        onClickHandler={()=>queryDataHandler(value, 'LSTORAGE')}
                    />
                    <Button
                        title="Query SetValues"
                        onClickHandler={()=>queryDataHandler(queryValues, 'SETVALUES')}
                    />
                    <Button
                        title="Clear SetValues"
                        onClickHandler={clearHandler}
                    />
                </div>
                <InputText title="Query Member's Data"
                    contStyle={'bg-teal-900 hover:bg-teal-700 active:bg-gray-500 cursor-pointer py-1 items-center'}
                    labelStyle={'cursor-pointer'}
                    inputStyle={'p-1 text-black'}
                    value = {memberID}
                    type="text"
                    placeholder="Enter membersID to query"
                    onClickTitle={handleMembersIDQuery}
                    onChangeHandler={onChangeHandler}/>

                <div>
                <div className='flex flex-row flex-wrap gap-2 mb-3'>
                    <Button
                        title="Login"
                        contStyle={'border-white border text-white bg-green-800'}
                        onClickHandler={loginToUserPage}
                    />
                    <Button
                        title="Reset StorageData"
                        contStyle={'border-white border text-white bg-red-800'}
                        onClickHandler={()=>setShowBtns(true)}
                    />
                    <div className={`${showBtns? 'flex' :'hidden'} flex-row gap-2`}>
                        <Button
                            title="Cancel"
                            contStyle={'border-white border text-black bg-gray-300'}
                            onClickHandler={()=>resetStorageHandler('CANCEL')}
                        />
                        <Button
                            title="Proceed"
                            contStyle={'border-white border text-white bg-red-800'}
                            onClickHandler={()=>resetStorageHandler('PROCEED')}
                        />  
                    </div>
                </div>
                <p className={`text-red-600 ${showLoginError? '' :'hidden'}`}>Please set all the data before loggin</p>
                <p className='text-yellow-300'>Queries Result- <span>{infoMsg.msg}</span></p>
                {
                    items1.map((item, i)=>{
                        return (
                            <p key={`${i}+key`}>{item.key}: {item.value}</p>
                        )
                    })
                }
                <p className={`${items2.length? '' :'hidden'}`}>decoded:</p>
                {
                    items2.map((item, i)=>{
                        return (
                            <p key={`${i}+key`} className="ml-2">
                                {item.key}: {getDisplay(item)}
                            </p>
                        )
                    })
                }
                </div>
            </div>
        </div>
    </div>
  )
}

function getDisplay(item){
    let res = '';
    if(item.key==='user' || item.key === 'adminPass'){
        res = item.value === true || item.value === 'true';
        res = res? 'true' : 'false'; 
    }else{
        res = item.value;
    }
    return res;
}
export default UserPageLogin;
