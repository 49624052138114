import { createSlice } from '@reduxjs/toolkit';
//import logo from '../../assets/gofamintLogo.png';
const defUser = {surname:'',firstname:'',fullName:'', title:'',
    email:'',loginID:'',famID:"",userID:'', imageUrl:""};
const defLoginForm = {show:false, isOnline:false, picture:'', checkedAdmin:false, 
      checkedMember:'nonmember', checkedPin:false};


const initialState = {
  online:false,
  admin: {checked:false, pin:''},
  user:{surname:'',firstname:'',fullName:'', title:'',
        email:'',loginID:'',famID:"",userID:'', imageUrl:""},
  loginForm:{show:false, isOnline:false, picture:'', adminPass:false, checkedAdmin:false, 
        checkedMember:'nonmember', checkedPin:false,},
  userType:'',
  loginUser:{ user:'', guest:false, code:0, },
  showAnnouncement:false,
}

export const loginReducer = createSlice({
  name: 'login',
  initialState,
  
  reducers: {
    disOnline: (state, action) => {
      state.online = action.payload
    },
    disAdmin: (state, action) => {
        state.admin = action.payload
      },
    disUser: (state, action) => {
    state.user = action.payload
    },
    disLoginForm: (state, action) => {
        state.loginForm = action.payload
        },
    disLogout: (state) => {
      state.loginForm = defLoginForm;
      state.user = defUser;
      state.online = false;
      state.userType='';
      state.admin = {checked:false, pin:''};
      state.userType ="";
      state.loginUser = { user:'', guest:false, code:0, };
      },
    disUserType:(state, action)=>{
      state.userType = action.payload
    },
    disLoginUser:(state, action)=>{
      state.loginUser = action.payload
    },
    disShowAnnouncement:(state, action)=>{
      state.showAnnouncement = action.payload
    },
  },
})


export const { disOnline, disAdmin, disUser, disLoginForm, 
    disLogout, disUserType, disLoginUser, disShowAnnouncement } = loginReducer.actions

export default loginReducer.reducer