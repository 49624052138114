import photo1 from './fiveStar1.png';
import photo2 from './fiveStar2.png';
import photo3 from './fiveStar3.png';
import photo4 from './fiveStar4.png';
import photo5 from './fiveStar5.png';


const fiveStarPhotos = [
    photo1, photo2, photo3, photo4, photo5
 ];


export default fiveStarPhotos