import React from 'react';
import HorizontalLine from '../../components/HorizontalLine';
import Card from '../cards/Card';
import SearchInput from './components/Search';
import ScrollToTopButton from './components/ScrollToTopButton';
import { useSelector } from 'react-redux';
import pastorCornerPhotos from '../../assets/pastorCorner/_index';
import generateRandomBetween from '../../utils/generateRandomBtwn'
import useOnline from 'use-online';
import CustomLoader from '../userpage/components/CustomLoader';


const PastorCorner = () => {
  const {pstCornerData} = useSelector((state)=>state.pstCornerMsg);
  const pastorMsg = [...pstCornerData];
  const [pastorCornerMsgs, setPastorCornerMsgs] = React.useState([...pastorMsg]);
  const isOnline = useOnline();

  const lenPC = pastorCornerPhotos.length;
  const len = 100;
  const header = "text-center md:px-10 text-xl text-[mediumblue] sm:text-4xl font-bold pt-10";
  
  const renderLoader = (<CustomLoader loadingMsg="Loading Pastor's Corner Messages, please wait!" color="blue" 
  loading={true} size={80}/>);
  const renderNoNetwork = (
            <div className='text-[red] font-bold text-lg justify-center p-4 items-center flex flex-col'>
                <p>Error in loading Pastor's Corner messages!</p>
                <p>Please, check your internet connection.</p>
            </div>);
  const renderedItem = isOnline? renderLoader : renderNoNetwork;

  return (
      <div className="w-full text-gray-600 flex justify-center px-5 pt-0 flex-col relative"
        >
          <h2 className={header}>Messages from the Pastor's Corner</h2>
        {pstCornerData.length>0?    
            <>
                <SearchInput
                    pastorCornerMsgs={pastorMsg}
                    setPastorCornerMsgs={setPastorCornerMsgs}
                />
                <HorizontalLine widths={95} margTop={5}/>
                
                <div className={`flex items-center justify-evenly w-full flex-wrap px-2 mt-3`}
                  >
                        {pastorCornerMsgs.map((item, i)=>{
                            const body = item.body.join(' ').substring(0, len).split(/\r?\n/)+"...";
                            const randomPhoto = generateRandomBetween(0,lenPC-1);

                            return(
                                <Card 
                                    key={`${i}+pcorn`}
                                    photo={pastorCornerPhotos[randomPhoto]}  
                                    postedDate={item.details.date}
                                    msg={body}
                                    bibleRef={item.bibleRef}
                                    //prayer ={item.pray} 
                                    topic={item.title}
                                    comment={item?.comments?.length} 
                                    likes={item?.likes?.length}
                                    styles={{marginBottom:'10px', marginTop:'10px'}}
                                    item={item}
                                />
                            );
                        })}

                    </div>
                <ScrollToTopButton/>
                </>
            :
             renderedItem    
            }
      </div>
  )
}

export default PastorCorner;
