
import React from 'react';
import OnlineOffPublishTemplate from './OnlineOffPublishTemplate';
import TableTemplate from './TableTemplate';
import { Button1 } from '../../../about/components/InputsComponent';
//import { client } from '../../../../../client';
import FileUploader from '../../../../fileupload/Fileupload';
import HorizontalLine from '../../../../HorizontalLine';
import DownloadExcel from '../../uploadDownload/components/DownloadExcel';


const OnlineOfferingPublishByUpload =({warningMsg, currentStep, uploadedData, publishNewPayershandler, 
        setSeudoUpload, setUploadedData, resetUploadFileHandler, toggleAllPayersNewFileHandler,
        isNewPayer, uploadDataDisplay, tableBodyData,prosUploadsForPublish, resetUploads,
         })=>{

    return (
        <div className='mb-4 bg-[#eee] p-3 border border-solid border-gray-600'>
            <p className='text-[maroon] font-bold text-lg underline'>Upload and Publish Online Offering Payers</p>
            <p className={''}><span className='text-red-900 font-bold'>Step- {currentStep.no}: </span>{currentStep.details}</p>
            <p className={`text-[red] ${warningMsg.show? '': 'hidden'}`}>{warningMsg.msg}</p>
            <div className='flex flex-row gap-2 mb-2 overflow-x-auto'>
                <FileUploader
                    setUploadedData={uploadedData.length? setSeudoUpload : setUploadedData}
                    btnStyle={`${uploadedData.length? 'bg-slate-300 whitespace-nowrap' :'bg-blue-800 cursor-pointer whitespace-nowrap' }  text-white w-fit py-1 px-3 border border-[gray] border-solid`}
                    title="Upload File"
                />

                <Button1 title="Publish New Payers"
                    onClick={publishNewPayershandler}
                    btnStyle={`whitespace-nowrap`}
                    myStyle={`
                        ${isNewPayer? 'cursor-pointer bg-blue-600 text-white active:bg-blue-300' : 'bg-slate-300'}
                    `}
                    //btnStyle={`${newPayers || uploadedData.length? '' : 'bg-stone-300'}`}
                    />
                
                <Button1 title="Reset Uploads"
                    onClick={resetUploadFileHandler} 
                    btnStyle={`bg-red-900 whitespace-nowrap`}/>

                <Button1 title={`${uploadDataDisplay==='FILE'? 'Payers File' : uploadDataDisplay==='ALL'? 'All Payers': 'Toggle'}`}
                    onClick={toggleAllPayersNewFileHandler} 
                    myStyle={`
                        ${isNewPayer || !uploadedData.length? 'bg-slate-300' :'cursor-pointer bg-green-800 text-white active:bg-green-800'}
                    `}
                    btnStyle={`whitespace-nowrap`}/>
                
                <DownloadExcel title="Download Samplefile"
                    contStyle={`whitespace-nowrap bg-sky-700 text-white active:bg-slate-300 cursor-pointer px-3 py-1 item-center justify-center flex`}/>               

            </div>
            <TableTemplate
                tableHeader={['Name', 'FamilyID', uploadDataDisplay==='ALL'? 'Payer SN':'Amount']}
                tableBody={tableBodyData}
                dataKeys={['name', 'familyID',  uploadDataDisplay==='ALL'? 'sn':'amount', 'newPayer']}
            />
            <HorizontalLine widths={100} bColor="blue" margBot={5} margTop={20}/>
              <OnlineOffPublishTemplate
                prosUploadsForPublish={prosUploadsForPublish}
                resetUploads={resetUploads}
                type="By Uploads"
                opts={['OFFERING', 'REMITTANCE',]}/>
        </div>
    );
}

export default OnlineOfferingPublishByUpload;