import React from 'react';
//import Carousal from '../components/carousel/Carousal';
import PCorner from '../components/pcorner/cardContainer/PCorner_Home';
import Footer from '../components/footer/Index';
import IndexFiveStar from '../components/fivestar/Index';
import FindMap from '../components/FindMap';
import Slideshow from '../components/carousel/ImageSliderGithub';
import UpcomingProgram from '../components/upcomingPrograms/UpcomingPrograms';
import IndexResources from '../components/resources/Index';
//import IndexAnchor from '../components/anchor/Index';
import { client } from '../client';
import { useSelector, useDispatch } from 'react-redux';
import { processPastorCornerMessages, getQueryPstCorner } from  '../components/pcorner/pstCornerQueries';
import { disAnchor, disFaithDeclaration, disPstCornerMsg, disAnchorBgImages } from '../context/reducers/pstCornerMsg';
import { useNavigate } from 'react-router-dom';
import SEO from '../components/SEO';
import { disBackgroundImg } from '../context/reducers/adminReducer';
import Anchor from '../components/anchor/marquee/Marquee';
import YearlyDeclaration from '../components/anchor/YearlyDeclaration';
import IndexSalvation from '../components/salvation/Index';
import { getQueryAnchorFaithDeclaration } from '../components/nav/queries';
//import useSessionStorage from '../utils/hooks/useSessionStorage';


const Home = () => {
  const dispatch = useDispatch();
  const { pstCornerData, anchor, faithDeclaration, anchorBgImages} = useSelector((state)=>state.pstCornerMsg);
  const {backgroundImg, mapUrl} = useSelector((state)=>state.admin);
  const navigate = useNavigate();
  
  async function fetchContents() {
    const query = getQueryPstCorner();
    const response = await client.fetch(query);

    if(response.length>0){
      const processRes = processPastorCornerMessages(response);
      dispatch(disPstCornerMsg(processRes));
    }
  }
  async function fetchBackgroundImg() {
    const query = queryBgImage;
    const response = await client.fetch(query);
    if(response.imageUrl){
      dispatch(disBackgroundImg({fetched:true, image:response.imageUrl}));
    }
  }
  
  async function fetchAnchorAndFaithDeclaration() {
    const query = getQueryAnchorFaithDeclaration();
    await client.fetch(query).then((res)=>{
      if(res){
        //console.log(res);
        const anchor = res.ANCHOR.key;
        const faithDecl = res.FAITHDECLARATION;
        const imageBase = res.IMAGEBASE;
        const imageSub = res.IMAGESUB;
        const sequence = faithDecl.reduce((fRes, item, i)=>{
            return  [...fRes, item.key, parseInt(item.value)]
        },[]);
        dispatch(disAnchor(anchor));
        dispatch(disFaithDeclaration(sequence));
        dispatch(disAnchorBgImages({imageBase, imageSub}))
      }
    });
}


  React.useEffect(() => {
   if(!anchor || !faithDeclaration){
    fetchAnchorAndFaithDeclaration();
   }  
  if(pstCornerData.length <2){
    fetchContents();
  }
   if(!backgroundImg.fetched){
      fetchBackgroundImg();
   }
  },[]);

  return (
    <div className="bg-mainColor flex flex-col overflow-y-hidden">
      <SEO 
        title="GOFAMINT PS Ogba, Lagos Official Website"
        description={gofamintPS[0]+" "+gofamintPS[1]+" "+gofamintPS[2]}
        />
      <Slideshow/>
      <AboutPS navigate={navigate} backgroundImg={backgroundImg}
        anchor={anchor}
        sequence={faithDeclaration}
        anchorBgImages={anchorBgImages}/>
      <UpcomingProgram/>
      <IndexFiveStar/>
      <IndexSalvation/>
      <PCorner/>
      <IndexResources/>
      <FindMap mapUrl={mapUrl}/>
      <Footer mapUrl={mapUrl}/>
     
    </div>
  );
};

export default Home;



const AboutPS =({navigate, backgroundImg, anchor, sequence, anchorBgImages})=>{

  return(
      <div className="w-full text-gray-600 flex justify-center py-10 px-5 flex-col relative overflow-x-hidden">
          {anchor && <Anchor text={anchor}/>}
          <img src={backgroundImg.image} alt="backgroundImage" className="w-[100%] h-[90%] absolute mt-10" style={{opacity:0.03, zIndex:0}}/>
          <div className='flex flex-col xl:flex-row'>
            <div className='xl:w-[50%] z-20'>
              <h2 className="text-center text-xl text-[tomato] sm:text-4xl font-bold pt-10">GOFAMINT Pacesetters Ogba<br/>
                  <span className="text-lg text-gray-600 sm:text-2xl">(District Headquaters)</span>
              </h2>
              <h2 className='text-justify text-lg sm:text-2xl sm:text-left p-[0] py-5 md:px-10'>{gofamintPS[0]}</h2>
              <h2 className='text-justify text-lg sm:text-2xl sm:text-left p-[0] py-5 md:px-10'>{gofamintPS[1]}</h2>
              <h2 className='text-justify text-lg sm:text-2xl sm:text-left p-[0] py-5 md:px-10'>{gofamintPS[2]}</h2>
              <h2 className='text-lg sm:text-2xl text-blue-700 text-bold text-left p-[0] py-5 md:px-10 z-10'>
                <span className='cursor-pointer hover:text-[mediumblue] '
                onClick={()=>navigate("/about")}>Read more...</span>
              </h2>
            </div>
            <div className='flex h-[40vh] xl:h-[] xl:flex-1'>
              {sequence.length> 0? 
                <YearlyDeclaration
                  sequence={sequence}
                  anchorBgImages={anchorBgImages}
                />
              :
              <></>
              }
            </div>
          </div>
      </div>
  );
}


const gofamintPS=[
  "Pacesetters Assembly (PS), is an assembly church of The Gospel Faith Mission International (GOFAMINT).",
  "The Church is the District Headquarters of Ogba District under Region 12. The present residing pastor of the church is Pastor Lawrence Ajagunna and the current District Pastor of Ogba District is Pastor Israel Adedokun",
  "The church is located at Ogba in Ikeja LGA of Lagos State.",
];


const queryBgImage = `*[ _type == 'upcomingPrograme' && name=="BulletinCover" ][0]{
  "imageUrl": flyer.asset->url 
  }
`;

const text = {
  anchor:'Anchor 2023: Yes, the LORD will give what is good; And our land will yield its increase. Psalm 85:12'
}

//<div style={{backgroundSize:'100% 100%',height:'500px',width:'600px',  backgroundPosition:'center', objectFit:'cover', backgroundRepeat:'no-repeat', backgroundColor:'red',  backgroundImage:`url(${bgImage})`}}></div>
/*
*[ _type == 'upcomingPrograme' && name=="BulletinCover" ] | order(_createdAt desc){
  flyer{asset->{url}},
  "imageUrl": flyer.asset->url
}*/