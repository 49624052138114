import React from 'react'
import ChartCard from './ChartCard';
import { useSelector, useDispatch } from 'react-redux';
import { client } from '../../../../client';
import { disMemberStat } from '../../../../context/reducers/userSub';
import ClipLoader from "react-spinners/ClipLoader";

const Cards = () => {
  const dispatch = useDispatch();
  //const {allData} = useSelector((state)=>state.user);
  const {membersStat} = useSelector((state)=>state.userSub);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(()=>{
    client.fetch(queryMembStat).then((res)=>{
      const finalRes = getMembersStat(res);
      dispatch(disMemberStat(finalRes));
      setLoading(false)
    });
  },[membersStat]);
   
  //console.log(membersStat)

  return (
    <div className='p-4'>
      <p className='pl-4 text-[purple] font-bold underline'>Membership Data Statistics</p> 
      {loading?
      <div className='p-4 justify-center items-center flex flex-col'>
        <ClipLoader
          color='tomato'
          loading={true}
          //cssOverride={override}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        <p>Loading members statistics...</p>
      </div>
      :<div className='grid grid-cols-1 lg:grid-cols-2'>   
        <ChartCard options={membersStat?.totalReg?.options}
          data = {membersStat?.totalReg?.data}/>

        <ChartCard options={membersStat?.membershipStat?.options}
          data = {membersStat?.membershipStat?.data}
          legends={membershipLegends}
          />

        <ChartCard options={membersStat?.ministers?.options}
          data = {membersStat?.ministers?.data}/>
        
        <ChartCard options={membersStat?.ageGroupStat?.options}
          data = {membersStat?.ageGroupStat?.data}/>

        <ChartCard options={membersStat?.batismalStat?.options}
          data = {membersStat?.batismalStat?.data}/>
        <ChartCard options={membersStat?.birthdayStat?.options}
          data = {membersStat?.birthdayStat?.data}/>
        </div>}
    </div>
  )
}

export default Cards;

function getMembersStat(stats){
  
  const membersStatDef ={
    totalReg: {
      options:{title:'Total Number of Registered Members-'+(stats.males+stats.females), is3D:true, 
        chartArea: {  width: "85%", height: "65%" },
        legend: {position: 'right', maxLines: 4},
        //isStacked: true,
        //legend: {position: 'top', maxLines: 8},
      },
      data:[
        ["Members", "Registered Members"],
        ["Males- "+stats.males, stats.males], 
        ["Females- "+stats.females,stats.females], ]},
    ministers: {
        options:{title: 'Registered Members Statistics', is3D:true,
          chartArea: {  width: "85%", height: "65%" },
          legend: {position: 'right', maxLines: 4},
          //chartArea: {  width: "80%", height: "65%" },
          //isStacked: true,
          //legend: {position: 'top', maxLines: 8},
        },
        data:[
          ["Members", "Members Statistics"],
          ["Ministers- "+stats.ministers, stats.ministers], 
          ["Workers- "+stats.workers,stats.workers], 
          ["Non-workers- "+stats.nonWorkers,stats.nonWorkers],]},
    batismalStat: {
        options:{title:'Registered Members Baptised', is3D:true,
            chartArea: {  width: "85%", height: "65%" },
            legend: {maxLines: 4}, 
         },
        data:[
          ["Members", "Baptismal Information"],
          ["Baptised members- "+stats.baptised, stats.baptised], 
          ["None baptised- "+stats.nonBaptised,stats.nonBaptised], ]},
    birthdayStat: {
        options:{title:'Registered Members with Birthday information', is3D:true,
          chartArea: {  width: "85%", height: "65%" },
          legend: { maxLines: 4},
        },
        data:[
          ["Members", "Birthday Information"],
          ["Birthday info- "+stats.birthdayStat, stats.birthdayStat], 
          ["No birthday info- "+stats.noBirthdayStat,stats.noBirthdayStat], ]},
    ageGroupStat: {
        options:{title:'Registered Members Age Group Distribution', is3D:true,
          chartArea: {  width: "85%", height: "65%" },
          legend: {maxLines: 4}, 
        },
        data:[
          ["Members", "Age group Information"],
          ["Babies (0-2yrs)- "+stats.babiesStat, stats.babiesStat], 
          ["Children (3-16yrs)- "+stats.childrenStat,stats.childrenStat],
          ["Young Adults (17-30yrs)- "+stats.youngAdultsStat,stats.youngAdultsStat],
          ["Middle-aged Adult (31-45yrs)- "+stats.middleAgeStat,stats.middleAgeStat],
          ["Old Adult (above 45yrs)- "+stats.oldAdultsStat,stats.oldAdultsStat],
          ["No age Info- "+stats.noAgeStat,stats.noAgeStat],
        ]},
    membershipStat: {
      options:{title:'Membership Statistics', is3D:true, 
        chartArea: {  width: "85%", height: "65%" },
        legend: { maxLines: 4}, 
      },
      data:[
        ["Members", "Membership Information"],
        ["Existing members- "+stats.existingMembers, stats.existingMembers], 
        ["None-existing members- "+stats.noneExistingMembers,stats.noneExistingMembers],
        ["New members- "+stats.newMembers,stats.newMembers],
        ["Transferred members- "+stats.transferredMembers,stats.transferredMembers],
        ["Relocated members- "+stats.relocatedMembers,stats.relocatedMembers],
        ["NoneMembers- "+stats.noneMembers,stats.noneMembers],
        ["Members gone to glory- "+stats.membersGoneToGlory,stats.membersGoneToGlory],
        ["Other Members- "+stats.otherMembers,stats.otherMembers],
      
      ]},
  };
  return membersStatDef
}

const dataStats =(arr)=>{
  const totalMembers = arr.length;
  const males= countFunction(arr, 'genderStat', "Male");
  const females= countFunction(arr, 'genderStat', "Female");
  const ministers = countFunction(arr, 'memberStat', "Minister");
  const workers = countFunction(arr, 'memberStat', "Worker");
  const nonWorkers = totalMembers - (ministers + workers);
  const baptised = countFunction(arr, 'batStat', "Baptised");
  const nonBaptised = totalMembers - baptised;
  const noBirthdayStat = countFunction(arr, 'dob', "");
  const birthdayStat = totalMembers - noBirthdayStat;

  const stats = {
    totalReg:totalMembers,
    males,
    females,
    ministers,
    workers,
    nonWorkers,
    baptised,
    nonBaptised,
    birthdayStat,
    noBirthdayStat
  }
  return stats
}




const countFunction =(arr,fld, val)=>{
  const res = arr?.filter((item)=> item[fld] === val)
  return res.length
}


const queryMembStat = `
  {
    "totalReg":count(*[ _type == "members" ]),
    "males":count(*[ _type == "members" && genderStat == "Male" ]),
    "females": count(*[ _type == "members" && genderStat == "Female" ]),
    "ministers": count(*[ _type == "members" && memberStat == "Minister" ]),
    "workers": count(*[ _type == "members" && memberStat == "Worker" ]),
    "nonWorkers": (count(*[ _type == "members" ]) - count(*[ _type == "members" && memberStat == "Worker" ])),
    "baptised": count(*[ _type == "members" && batStat == "Baptised" ]),
    "nonBaptised": (count(*[ _type == "members" ]) -count(*[ _type == "members" && batStat == "Baptised" ])),
    "noBirthdayStat": count(*[ _type == "members" && dob == "" ]),
    "birthdayStat": (count(*[ _type == "members" ]) - count(*[ _type == "members" && dob == "" ])),

    "babiesStat": count(*[ _type == "members" && ageGroup == "Baby (0-2yrs)" ]),
    "childrenStat": count(*[ _type == "members" && ageGroup == "Child (3-16yrs)" ]),
    "youngAdultsStat": count(*[ _type == "members" && ageGroup == "Young Adult (17-30yrs)" ]),
    "middleAgeStat": count(*[ _type == "members" && ageGroup == "Middle-aged Adult (31-45yrs)" ]),
    "oldAdultsStat": count(*[ _type == "members" && ageGroup == "Old Adult (above 45yrs)" ]),
    "noAgeStat": (count(*[ _type == "members" ]) - (count(*[ _type == "members" && ageGroup == "Baby (0-2yrs)" ]) + count(*[ _type == "members" && ageGroup == "Child (3-16yrs)" ]) + count(*[ _type == "members" && ageGroup == "Young Adult (17-30yrs)" ]) + count(*[ _type == "members" && ageGroup == "Middle-aged Adult (31-45yrs)" ]) + count(*[ _type == "members" && ageGroup == "Old Adult (above 45yrs)" ]))),

    "existingMembers": count(*[ _type == "members" && membership == "Existing member" ]),
    "noneExistingMembers": count(*[ _type == "members" && membership == "None-existing member" ]),
    "newMembers": count(*[ _type == "members" && membership == "New member" ]),
    "transferredMembers": count(*[ _type == "members" && membership == "Transferred" ]),
    "relocatedMembers": count(*[ _type == "members" && membership == "Relocated" ]),
    "noneMembers": count(*[ _type == "members" && membership == "None-member" ]),
    "membersGoneToGlory": count(*[ _type == "members" && membership == "Late" ]),
    "othersMembers": (count(*[ _type == "members" ]) - count(*[ _type == "members" && membership == "Existing member" ]) -  
        count(*[ _type == "members" && membership == "None-existing member" ]) - count(*[ _type == "members" && membership == "New member" ]) -
        count(*[ _type == "members" && membership == "Transferred" ]) - count(*[ _type == "members" && membership == "Relocated" ]) -
        count(*[ _type == "members" && membership == "None-member" ]) - count(*[ _type == "members" && membership == "Late" ])),
  }
`;

const membershipLegends = [
  {title:"Existing members", value:"Those are current members of the church"},
  {title:"Non-existing members", value:"Those were once members but are no longer members of the church"},
  {title:"Transferred members", value:"Those that were transferred to other assemblies"},
  {title:"Relocated", value:"Those that have changed their location"},
  {title:"None-members", value:"Those that are not members of the church. Their spouse or parent would have added them"},
];
//'Existing member', 'None-existing member', 'New member', 'Transferred', 'Relocated', 'None-member', 'Late'