import React from 'react';
import CustomModal2 from '../../CustomModal2';



const Confirmation = ({showModal, closeModal,title, msgArr}) => {

    return (
    <CustomModal2 
        show={showModal}
        contStyle="flex justify-center">
        <div className="modal-dialog relative w-auto pointer-events-none slide-in-top">
            <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5 className="text-xl font-medium leading-normal text-gray-800" id="exampleModalLabel">
                    {title}
                </h5>
                <button type="button" className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline" 
                    onClick={()=>closeModal(false)}></button>
            </div>
            <div className="modal-body relative p-4">
                {
                    msgArr?.map((msg,i)=>{
                        return(
                            <p key={i+'msg'} className=''>{msg}</p>
                        )
                    })
                }
            </div>
            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                <button type="button" className="px-6
                    py-2.5
                    bg-purple-600
                    text-white
                    font-medium
                    text-xs
                    leading-tight
                    uppercase
                    rounded
                    shadow-md
                    hover:bg-purple-700 hover:shadow-lg
                    focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0
                    active:bg-purple-800 active:shadow-lg
                    transition
                    duration-150
                    ease-in-out" data-bs-dismiss="modal"
                    onClick={()=>closeModal(false)}>Cancel</button>
                <button type="button" className="px-6
                    py-2.5
                    bg-blue-600
                    text-white
                    font-medium
                    text-xs
                    leading-tight
                    uppercase
                    rounded
                    shadow-md
                    hover:bg-blue-700 hover:shadow-lg
                    focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                    active:bg-blue-800 active:shadow-lg
                    transition
                    duration-150
                    ease-in-out
                    ml-1"
                    onClick={()=>closeModal(true)}>Continue</button>
            </div>
            </div>
        </div>
    </CustomModal2>
  )
}

export default Confirmation

/*
<div className='flex flex-row flex-wrap text-white'>
                <div className='p-2 px-5 bg-purple-800 rounded-lg m-3 w-fit cursor-pointer'>Close</div>
                <div className='p-2 px-5 bg-purple-800 rounded-lg m-3 w-fit cursor-pointer'>Continue</div>
              </div>
*/  