import React from 'react';
import { ToggleButton } from '../components/Components';
//import { client } from '../../../../../client';
//import { deleteUsedAssetsQuery } from '../../../admin/membersData/queries';
import { useDispatch } from 'react-redux';
import { disShowAllTableFlds, disMemberTableViewAll, disAdminUser } from '../../../../context/reducers/dev';
import HorizontalLine from '../../../HorizontalLine';

const TableDataSettings = ({showAllTableFlds, memberTableViewAll, adminUser}) => {
  const dispatch = useDispatch();
  
  
  //const [toggleShowAllTableFlds, setToggleShowAllTableFlds] = useState(false);
  //const [unrefAssetsQueried, setUnrefAssetsQueried] = useState(false);
  //const [allAssets, setAllAssets] = useState([]);
  //const [isDeleting, setIsDeleting] = useState({show:false, msg:''});

  
  function handleShowAllFldsToggle(){
        dispatch(disShowAllTableFlds(!showAllTableFlds))
  }

  function handleViewAllFldsToggle(){
    dispatch(disMemberTableViewAll(!memberTableViewAll))
  }
  function handleAdminUserToggle(){
    dispatch(disAdminUser(!adminUser))
  }

  return (
    <div className='m-2 mb-5 p-2'>
        <ToggleButton
            setToggleVal={handleShowAllFldsToggle}
            toggleVal={showAllTableFlds}
            title="Show All Members Fields- Members Datatable"
            labelStyle="pr-2"
            titleSub={showAllTableFlds? 'ON' : 'OFF'}
            labelSubStyle={showAllTableFlds? 'text-[lime]' : 'gray'}
         />
         <HorizontalLine widths={100} margBot={10}/>

         <ToggleButton
            setToggleVal={handleViewAllFldsToggle}
            toggleVal={memberTableViewAll}
            title="View All Fields- Member View"
            labelStyle="pr-2"
            titleSub={memberTableViewAll? 'ON' : 'OFF'}
            labelSubStyle={memberTableViewAll? 'text-[lime]' : 'gray'}
         />
         <HorizontalLine widths={100} margBot={10}/>
         <ToggleButton
            setToggleVal={handleAdminUserToggle}
            toggleVal={adminUser}
            title="Set Admin User"
            labelStyle="pr-2"
            titleSub={adminUser? 'ADMIN' : 'DEVELOPER'}
            labelSubStyle={adminUser? 'text-[blue]' : 'text-[red]'}
         />
         <HorizontalLine widths={100} margBot={10}/>
    </div>
  )
}

export default TableDataSettings;

