import React from "react";
import {GoCalendar, GoPerson} from "react-icons/go";
import {FaComment, FaFolder, FaHeart} from 'react-icons/fa';
import HorizontalLine from "../components/HorizontalLine";
import {useNavigate, useLocation } from 'react-router-dom';
//import SendEmail from "../components/forms/SendEmail";
import LeaveAComment from '../components/forms/LeaveAComment';
//import generateWordFile from "../utils/generateWordFile/generateFile";
import Footer from '../components/footer/Index';
import { client } from '../client';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from "react-redux";
//import { getDateString } from "../utils/getDateString";
import useFetchData from "../components/pcorner/useFetchData";
import { disLoginForm, } from "../context/reducers/login";
import{ FaThumbsUp} from 'react-icons/fa';
import { sanityDateTimeFormat } from "../utils/getDateString";
import SEO from "../components/SEO";
import ScrollToTopButton from "../components/pcorner/components/ScrollToTopButton";
//import {FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon} from "react-share";
import SocialMediaShare from "../components/readPCorner/SocialMediaShare";
import pstCornerMsg from "../context/reducers/pstCornerMsg";
import CustomLoader from "../components/userpage/components/CustomLoader";


const ReadPastorCorner =({})=>{
    const dispatch = useDispatch();
    const {user, loginForm} = useSelector((state)=>state.login);
    const {pstCornerData, selectedMsg} = useSelector((state)=>state.pstCornerMsg);
    const {loginID,} = user;
    //const location = useLocation();
    const newItem =  selectedMsg;//location.state;
    var {sn,title,bibleRef,body, pray, msgID, comments, details} = newItem;
    const navigate = useNavigate();
    const [fetchedData, refetchData] = useFetchData();
    const [errorMsg, setErrorMsg] = React.useState({show:false, hideBtn:true, msg:'Please, login first'});
    //const [userComment, setUserComment] = React.useState({});
    const [commentSent, setCommentSent] = React.useState({info:""});
    //console.log([pstCornerData, selectedMsg]);
    //const likesArr = pstCornerData?.filter(item=> item.msgID === msgID)?.likes ;
    const liveLikes = pstCornerData?.find(item=> item.msgID === msgID)?.likes ;
    const liveCommentsArr = pstCornerData?.filter(item=> item.msgID === msgID)[0];
    const liveComments = pstCornerData?.find(item=> item.msgID === msgID)?.comments ;
    const [checkingMsg, setCheckingMsg] = React.useState(true);


    React.useEffect(()=>{
        //Auto scroll to top on load
          window.scrollTo({
          top: 0,
          behavior: 'smooth' // for smoothly scrolling
          });
      },[]);

      function returnToMessages(){
        if(!title || title === 'undefined'){
            navigate('/pastorcorner');
          }
      }
      React.useEffect(()=>{
            //To handle when no message
          if(!title || title === 'undefined'){
            setCheckingMsg(true);
            setTimeout(()=> returnToMessages(), 1000);
          }else{
            setCheckingMsg(false);
          }
      },[]);

    function likesHandler(id){
           
            client
            .patch(id)
            .setIfMissing({ likes: [],})
            .insert('after', 'likes[-1]', 
                [{ _key: uuidv4(),  
                    userID: (`${loginID}`),
                    postedBy: { _type: 'postedBy', _ref:loginID } 
                }])
            .commit()
            .then((res) => {
              //console.log(res);
              refetchData();
            });
    }
    function postCommentHandler(comment){
        if(!loginID){ 
            const msg = {show:true, hideBtn:false, msg:'Please, you need to login before you can like or comment on this message.'}
            setErrorMsg(msg);
            setTimeout(()=>setErrorMsg({show:false, msg:''}), 8000);
            return};
        
        const id = msgID;
        client
        .patch(id)
        .setIfMissing({ comments: [],})
        .insert('before', 'comments[0]', 
            [{ _key: uuidv4(),  
                userID: (`${loginID}`),
                datePosted:sanityDateTimeFormat(new Date()),
                comment:comment.body,
                postedBy: { _type: 'postedBy', _ref:loginID } 
            }])
        .commit()
        .then((res) => {
          //console.log(res);
          setCommentSent({...commentSent, info:"POSTED"});
          refetchData();
        });
        //console.log([comment, id]);
        //setCommentSent({...commentSent, info:"POSTED"})
}
    function likesClickHandler(e, id, act){
        //console.log(item)
        e.stopPropagation();
        if(loginID){
            client.fetch(queryLikes(id)).then((data) => {
                const likesUserIDArr = data[0]?.likes?.find(item=> item.postedBy._id === loginID);
                //console.log(likesUserIDArr, loginID)
                if(!likesUserIDArr){
                    likesHandler(id);
                }else{
                    const msg = {show:true, hideBtn:true,  msg:'Sorry, you can only like this message once. Thanks'}
                    setErrorMsg(msg);
                }
            });

        }else{
            const msg = {show:true, hideBtn:false, msg:'Please, you need to login before you can like or comment on this message.'}
            setErrorMsg(msg);
        }
        setTimeout(()=>setErrorMsg({show:false, msg:''}), 8000);
    }
    function loginHandler(){
        dispatch(disLoginForm({...loginForm, show:true}));
        setErrorMsg({show:false, msg:''});
    }
    const errorMsgNodes = (
        <div className={`${ errorMsg.show? 'flex' : 'hidden'} text-left flex-col flex-wrap  w-full text-red-600  pt-2`}>
            <p>{errorMsg.msg}</p>
            <p className={`${errorMsg.hideBtn? "hidden" : ''} bg-blue-600 text-white p-2 px-5 rounded-md shadow-md cursor-pointer active:bg-slate-400 w-fit`}
                onClick={loginHandler}>Login</p>
        </div>
    );
    const style1 = 'flex flex-row flex-wrap justify-start items-center text-[12px] sm:text-[14px]  md:text-[16px]';
    const style2 = 'flex flex-row items-center mr-3 mb-2 text-[rgb(40,42,47)] cursor-pointer';
    const style3 = "w-[22px] h-[20px] sm:w-[28px] sm:h-[28px]";
    const style4 = 'flex flex-row items-center mr-3 mb-2 text-[rgb(40,42,47)]';
    
    if(checkingMsg){
        return(
            <div className="mt-10">
                <CustomLoader loadingMsg="Loading messages, please wait...." color="blue" 
                textCol="blue" loading={true} size={80}/>
            </div>
        )
    }
    return(
        <div>
        <SEO title={title+ " - GOFAMINT PS Ogba"} />
        <div className="pt-[50px] px-[10px] smc:px-[30px] md:px-[50px] lg:px-[70px] flex justify-center flex-col items-center content-center bg-mainColor ">
            <div className="flex justify-center items-center">
             <img src={details?.photo?.src} alt="Gofamint" className="w-[100%]"/>
            </div>
            {errorMsgNodes}
            <div className="flex flex-col w-full pt-[0px]">
                <h1 className='font-archivo py-5 pb-2 text-xl sm:text-2xl md:text-3xl lg:text-5xl text-[rgb(40,42,47)] text-center'>
                    {title?.toUpperCase()}
                </h1>
                
                <div className={style1}>
                    <div className={style2}><GoPerson className={style3}  color="gray"/><span className="pl-2">{'Admin'}</span></div>
                    <div className={style2}><FaFolder className={style3}  color="gray"/><span className="pl-2">{"Pastor's Corner"}</span></div>
                    <div className={style2}><GoCalendar className={style3}  color="gray"/><span className="pl-2">{details?.date}</span></div>
                    <div className={style4}><FaComment  className={`${style3} ${liveComments?.length > 0? 'text-blue-600' : 'text-[gray]'}`}/>
                        <span className="pl-2">{liveComments?.length}</span>
                    </div>
                    <div className={style2}
                    onClick={(e)=>likesClickHandler(e, msgID)}>
                        <FaThumbsUp className={`${style3} ${liveLikes?.length > 0? 'text-yellow-600' : 'text-[gray]'} hover:text-yellow-400`}/><span className="pl-2">{liveLikes?.length || 0}</span>
                    </div>
                    
                </div>
                <HorizontalLine bColor="gray" widths={100} margTop={2}/>
                <div className="text-justify text-[14px] sm:text-[15px]  md:text-[16px] text-[darkslategray]" 
                    >
                    <p className="mb-[-15px] pt-5 pb-3 italic">{newItem?.bibleRef}</p>
                    {body?.map((txt, i)=>{
                        const formated = formatText(txt);
                        return <p className="py-3" key={`${i}++lv`}>
                                    {formated.formatTxt}
                                </p>
                    })}

                    <p className={`mb-[-15px] italic ${newItem.pray.length> 10? "": "hidden"}`}><span className="font-bold">Pray: </span>{newItem.pray}</p>
                </div>
                
                <HorizontalLine bColor="gray" widths={100} margTop={30} margBot={2}/>
                {errorMsgNodes}
                <LeaveAComment 
                 comments={liveCommentsArr.comments}
                 userComment={e=>postCommentHandler(e)}
                 commentInfo={commentSent.info}
                 setCommentSent={()=>setCommentSent({info:""})}/>
                <div className="py-10 flex flex-col gap-3">
                    <button type="button" className="w-fit inline-block px-10 py-3 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                        onClick={() => navigate(-1)}>
                            Back
                    </button>
                    <div className="gap-3 flex flex-row mt-3 flex-wrap">
                        <p className="fond-bold text-lg">Share </p>
                        <SocialMediaShare
                            topic={title}
                            body={body}
                        />
                    </div>
            </div>
            </div>
        </div>
        
        <ScrollToTopButton/>
        <Footer/>
        </div>
    );
}



export default ReadPastorCorner;


function formatText(txt){
    const starText = txt.includes("*");
    const newTxt = txt.replace(/[*]/g, "");

    const formated = {
        //color: starText? "dodgerblue" : "rgb(40, 100, 110)",
        fontStyle: starText? 'italic' : 'normal',
        //fontWeight:starText? 'bold' : 'normal',
    }

    return {formatStyle: formated, formatTxt: newTxt}
}
/*
const commentsArrNew = [
    {author:'', comment:"", datePosted:"", postedBy:{image:"", userName:""}}
];
const commentsArr = [
    {author: "landiggity", body: "This is my first comment on this forum so don't be naive", date:'2022-11-23'},
    {author: "scarlett-jo", body: "That's a mighty fine comment you've got there my good looking fellow...", date:''},
    {author: "rosco", body: "What is the meaning of all of this 'React' mumbo-jumbo?", date:''}
  ];


<div style={{backgroundImage: `url(${gofamint1})`}}
                className="pstcorn-bground-img"></div>



function getCommentsArr(arr){
    const comment ={author:'', body:'', date:'', image:'', userName:''};

    const comments = arr?.map(item=> {
        return {author:item.postedBy.userName, body:item.comment, date:item.datePosted, 
            image:item.postedBy.image, userName:item.postedBy.userName}
    });
    return arr.length> 0? comments : [comment];
}
*/

const queryLikes =(msgID)=>{ 

const query =
`
    *[_type == "pastorCorner" && _id=='${msgID}'] | order(date desc){
        _id,
        topic,
        messageID,
        sn,
        likes[]{
        postedBy->{
            userName,
            _id,
        },
        },
    }
 `;
 return query
}
/*
*[_type == "pastorCorner"] | order(_createdAt desc) {

      _id,
      topic,
      messageID,
      sn,
      pray,
      bibleRef,
      body,
      date,
      likes[]{
        _key,
        postedBy->{
          _id,
          userName,
          image
        },
      },
      comments[]{
        _key,
        comment,
        datePosted,
        postedBy->{
          _id,
          userName,
          image
        },
      },
    }



 */