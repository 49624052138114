import React, {useState} from 'react';
import { SpanBtn, SpanHLight } from './TextSpans';
//import useLocalStorageNew from '../../../../utils/hooks/useLocalStorageNew';
import { TextInput,} from './InputsComponent';
import SearchRecords from './SearchRecords';
import { useSelector, useDispatch } from 'react-redux';
import { disSeltdTab, disFreshRegistration, disUpdateRecord } from '../../../../context/reducers/user';
import { tabsNav } from '../../headerTabs/headerSchema';
import { getRecords } from '../../modules/modules';
import { searchRecordHandler } from '../../modules/searchRecords';
import { client } from '../../../../client';
import { getQueryMembersAllData } from '../../admin/queries/queryMembersAllData';
import { sortFamilyData } from '../../modules/sortRecord';
//import useOnline from 'use-online';
import { disCurrentStep } from '../../../../context/reducers/userSub';
//import { current } from '@reduxjs/toolkit';

const SearchRecordsManager = ({setIsConfirmed, searchRecord, setSearchRecord, seltdIndiv, 
  setSeltdIndiv, updateRecordCall, showUpdateRecBtn}) => {
  const {freshRegistration, updateRecord, } = useSelector((state)=>state.user);
  const {userFetchedData, currentStep} = useSelector((state)=>state.userSub);
  const {loginForm} = useSelector((state)=>state.login);
  const userIsOnline = loginForm.isOnline;
  //const appIsOnline = useOnline();
  const [searchVals, setSearchVals] = useState({surname:'', firstname:''});
  const [filterRecord, setFilteredRecord] = useState([]);
  const [filterRecordsOthers, setFilteredRecordsOthers] = useState([]);
  //const [persistState] = useLocalStorageNew('@gofamintPSOgba');
  const [searchedData, setSearchedData] = useState([]);
  const [beginSearch, setBeginSearch] = useState(false);
  const [loading, setLoading] = useState({start:false});
  
  const [selectSearchInfo, setSelectSearchInfo] = React.useState({show:false, msg:'', type:''});

  //const [isConfirmed, setIsConfirmed] = React.useState(false);
  //const [seltdIndivClicked, setSeltdIndivClicked] = React.useState(false);
  const dispatch = useDispatch();

  async function fetchContents() {
    const surnameTrim = searchVals.surname.trim();
    const query = getQueryMembersAllData(surnameTrim);
    const response = await client.fetch(query);
    setSearchedData(response);
    //console.log(response);
    return response
  }     

    function setSearchRecordHandler(act){
      setSelectSearchInfo({...selectSearchInfo, show:false});
      setSearchRecord(act);
      resetHandler();
    }


    function searchRecordHandlers(e){
      e.preventDefault();
      if(currentStep.step !== 1){ return}
      if(searchRecord===""){
        setSelectSearchInfo({...selectSearchInfo, show:true, type:'SEARCHTYPE'});
         return
      }else if(searchRecord === "SEARCHFORME" && userFetchedData.length >0){//User member data alreay exist
        setSelectSearchInfo({...selectSearchInfo, show:true, type:'USEREXIST'});
          return
      }else{
        setSelectSearchInfo({...selectSearchInfo, show:false, type:''})
      }
      setLoading({...loading, start:true});
          
          fetchContents().then((res)=>{
            const searchValsTrim = {...searchVals, surname:searchVals.surname.trim()};
            //console.log(searchValsTrim);
            const sResult = searchRecordHandler(res, searchValsTrim, 'INDIV');
            setFilteredRecord(sResult.filteredRes);
            setFilteredRecordsOthers(sResult.filteredOthersRes);
            setBeginSearch(sResult.beginSearch);
            if(sResult.filteredRes.length>0){
              dispatch(disCurrentStep({step:2, about:"SEARCHRECORDFOUND",}))
            }else{
              dispatch(disCurrentStep({step:2, about:"SEARCHRECORDNOTFOUND",}))
            }
        });
        setLoading({...loading, start:false});
        
    }
  
    function updateRecordHandler(){
      const {famStat, family} = seltdIndiv;
      const isParent = famStat==="parent"? true : false;
    let filterRes = isParent? getRecords(searchedData, family) : [1, [seltdIndiv]];
    const famData = sortFamilyData(filterRes[1]);
    //if(!famData[0].loginID){setMemberLoginIDHandler(famData[0].id, user.loginID);}
    //console.log(famData);
    dispatch(disUpdateRecord(
      {...updateRecord, startUpdate:true, user:famStat, recordToUpdateCount:filterRes[0], recordToUpdate:famData}))
      goToPage('RECORDVIEW');
    }

    function onChangeInputsHandler(e){
        const {name, value} = e.target;
        let prosVal = value.toLowerCase();
         prosVal = (prosVal.charAt(0)).toUpperCase()+prosVal.slice(1); 
        setSearchVals({...searchVals, [name]:prosVal});
        resetHandler();
        setSearchRecord('');
        dispatch(disCurrentStep({step:1, about:"SEARCHRECORD", details:""}));
    }
    function setSeltdIndivHandler(indv){
      setSeltdIndiv(indv);
    }

    function resetHandler(){
      setBeginSearch(false);
      setFilteredRecord({});
      setFilteredRecordsOthers([]);
      setSeltdIndiv({});
      setSelectSearchInfo({...selectSearchInfo, show:false, type:''})
    }

    function goToPage(page){
        if(page === "RECORDREGISTER"){
          dispatch(disFreshRegistration({...freshRegistration, start:true}));
        }
        dispatch(disSeltdTab(tabsNav[page]));
    }

    if(!userIsOnline){
      return <p className='text-red-700'>Please, login to be able to view and update your record.</p>
    }

    return (
      <div>
        <p className='font-bold text-[red] underline'>Step 1:</p>
        <div><span className='font-bold'>Search for your Record:</span> Enter your surname and firstname and select <SpanBtn text="Search for my Record" icon='radio'/> if searching for your record or select <SpanBtn text="Search for another" icon='radio'/> if for a member.</div>
        
        <div className='flex w-fit flex-row flex-wrap bg-white border-2 border-solid boder-slate-200 px-2 py-1 mt-2'>
          <div className='mr-4 flex items-center border border-solid border-blue-500 px-2'>
            <input type='radio' 
              className='px-3 py-1 h-4 w-4'
              value="SEARCHFORME"
              checked={searchRecord === "SEARCHFORME"}
              onChange={()=>setSearchRecordHandler('SEARCHFORME')}/>
            <span className='pl-1'>Search for my Record</span>
          </div>
          <div className='flex items-center border border-solid border-blue-500 px-2'>
            <input type='radio' 
              className='px-3 py-1 h-4 w-4 '
              value="SEARCHANOTHER"
              checked={searchRecord === "SEARCHANOTHER"}
              onChange={()=>setSearchRecordHandler('SEARCHANOTHER')}/>
             <span className='pl-1'>Search for another</span>
          </div>
        </div>
        <form onSubmit={searchRecordHandlers}>
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-2 max-w-[800px]'>
            <TextInput
                value={searchVals.surname}
                name="surname"
                placeholder="Enter Surname"
                onChangeHandler={e=>onChangeInputsHandler(e)}
                required autoComplete="true"
            />
            <TextInput
                value={searchVals.firstname}
                name="firstname"
                placeholder="Enter Firstname"
                onChangeHandler={e=>onChangeInputsHandler(e)}
                required autoComplete="true"
            />
            </div>
            <p className={`${loading.start? '': 'hidden'}`}>Searching...</p>
            <p className={`${selectSearchInfo.show && selectSearchInfo.type==="SEARCHTYPE"? '' : 'hidden'} text-red-600 mt-2`}>
              Please, select the record you want to search, either for you 
              <SpanHLight text="Search for my Record" border/> or for another 
              <SpanHLight text="Search for another" border/>.
            </p>
            <p className={`${selectSearchInfo.show && selectSearchInfo.type==="USEREXIST"? '' : 'hidden'} text-red-600 mt-2`}>
               Your data alreay exist in <SpanHLight text="My Records!" col="magenta" underline/> 
               You can delete it if it is not your records or select <SpanHLight text="Search for another" border/>.
            </p>
            <input 
                className={currentStep.step===1? submitBtn.clickable : submitBtn.notClickable}
                type='submit'
                value="SEARCH"
            />
        </form>
            <SearchRecords
                searchVals={searchVals}
                filterRecord={filterRecord}
                filterRecordsOthers={filterRecordsOthers} 
                beginSearch={beginSearch}
                seltdIndiv={seltdIndiv}
                setSeltdIndiv={setSeltdIndivHandler}
                goToPage={goToPage}
                searchedData={searchedData}
                updateRecordCall={updateRecordCall}
                setIsConfirmed={setIsConfirmed}
                showUpdateRecBtn={showUpdateRecBtn}
            />
      </div>
  )
}


export default SearchRecordsManager;

const submitBtn = {
  clickable:"bg-teal-600 text-white py-2 px-6 w-fit my-3 active:bg-teal-300 hover:bg-teal-300",
  notClickable:"bg-[silver] text-black py-2 px-6 w-fit my-3 active:bg-gray-300 hover:bg-gray-300",
}