

function checkForNewPayers(existPayers, uploadedPayers){


   const processedUploads = uploadedPayers?.map((item, i)=>{
        const existingPayer = existPayers?.find((fnd, id)=> {return item.familyID === fnd.familyID});
        const payerTest = existingPayer?.name? {...item, name:existingPayer.name, newPayer:false} : {...item, newPayer:true};
        return payerTest 
   });
   //const newPayersData = processedUploads.filter(item=> item.newPayer);
   //const result = {processedUploads, newPayersData}
   
   return processedUploads
}



export {checkForNewPayers}
