import React from 'react'
import Footer from '../components/footer/Index';
import logo from '../assets/gofamintLogo.png';
import IndexPastorCorner from '../components/pcorner/Index_Page';
import SEO from '../components/SEO';





const PastorCorner = () => {
  React.useEffect(()=>{
    //Auto scroll to top on load
      window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
      });
  },[]);
  

  return (
    <div className="bg-mainColor flex flex-col">
      <SEO title="Pastor's Corner - GOFAMINT PS Ogba" />
      <div className='flex justify-center'>
       <img src={logo} alt="logo" className="w-[80px] xsmc:w-[120px] smc:w-[150px] md:w-[220px]" />
      </div>
      <IndexPastorCorner/>
      <Footer/>
    </div>
  )
}

export default PastorCorner;

