import React from 'react';
import { client } from '../../../../../client';

const OnlineOfferingPayerManualUpload = () => {
    const [inputVal, setInputVal] = React.useState({name:'', familyID:'', sn:''});
    const [feedback, setFeedback] = React.useState({show:false, msg:''});
    const [onlinePayersCount, setOnlinePayersCount] = React.useState('');
    const [counting, setCounting] = React.useState({show:false, msg:''});
    
    const publishPayerHandler=(e)=>{
        e.preventDefault();
        setFeedback({show:true, msg:"Publishing payer's file, please wait..."})
        const doc = {
            _id: inputVal.familyID,
            _type: "onlineOfferingPayers",
            familyID: inputVal.familyID,
            name:inputVal.name,
            sn:parseInt(inputVal.sn),
          };
        client.createIfNotExists(doc).then(res => {
            setFeedback({show:true, msg:"Payer's file successfully published."});
            setInputVal({name:'', familyID:'', sn:''});
            setOnlinePayersCount('')
        })
    }

    function setInputValHandler(e){
        setFeedback({show:false, msg:""})
        const {name, value} = e.target;
        setInputVal({...inputVal, [name]:value});
    }
    function onlinePayersCountHandler(){
        setCounting({show:true, msg: 'Counting, please wait...'})
        client.fetch(queryPayersCount).then((res)=>{
            if(res.totalPayers){
                setOnlinePayersCount(res.totalPayers);
                setCounting({show:false, msg: ''})
            }
        });
    }

return (
    <div className="mb-3 p-3 mt-5 border-2 border-solid border-teal-700"
        >
        <p className="bg-[indigo] text-white pb-2 underline font-bold p-3">
            Publish Offering Payer Manually</p>
        <div>
            <form onSubmit={publishPayerHandler}>
                <p className={`${feedback.show? 'visible':'invisible'} text-red-700 mx-4 mt-4`}>{feedback.msg}</p>
                <div className='m-4 mt-1 gap-2 grid grid-cols-1 mdc:grid-cols-2 lg:grid-cols-3'>
                    <TextField title="Name" name='name' required value={inputVal.name} onChange={setInputValHandler}/>
                    <TextField title="FamilyID" name="familyID" required value={inputVal.familyID} onChange={setInputValHandler}/>
                    <TextField title="SN" name='sn' required value={inputVal.sn} onChange={setInputValHandler}/>
                </div>
                <div className="flex flex-row flex-wrap items-center">
                    <input type='button' value = "Generate"
                            className='py-1 px-5 bg-teal-800 text-white m-4 cursor-pointer active:bg-slate-300'
                            onClick={onlinePayersCountHandler}/>
                    <p>{counting.show? counting.msg : 'Online Payers count'}: {onlinePayersCount}</p>
                </div>
                <div className="flex flex-row gap-2 flex-wrap">
                    <input type='submit' value = "Publish"
                        className='py-1 px-5 bg-blue-800 text-white m-4 cursor-pointer active:bg-slate-300'/>
                    <input type='reset' value = "Reset" onClick={()=> setInputVal({name:'', familyID:'', sn:''})}
                    className='py-1 px-5 bg-red-800 text-white m-4 cursor-pointer active:bg-slate-300'/>
                </div>
            </form> 
        </div>
        <br/>
    </div>
  )

};


export default OnlineOfferingPayerManualUpload;




const TextField = ({title, pholder,required, value, onChange, type, name})=>{

    return(
        <div className='flex flex-row flex-wrap border border-solid border-blue-500 px-2 py-1'>
            <label className='mr-2'>{title}</label>
            <input placeholder={pholder}
                value={value} onChange={onChange}
                required={required || ""}
                type={type}
                name={name}
                autoComplete='true'
                className="flex flex-1 h-8 px-3 text-gray-600"
                />
        </div>
    );
}



const queryPayersCount = `
{
  "totalPayers":count(*[ _type == "onlineOfferingPayers" ]),
}
`;