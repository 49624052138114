
import React from 'react'
import { SpanHLight } from '../../about/components/TextSpans';

const UserGuide = () => {
    
  
    return (
    <div className='p-3 max-h-[400px] overflow-y-auto text-[12px]'>
        <p className='text-blue-700 font-bold underline'>User Guide</p>
        <p className='text-rose-700 font-bold mt-3'>This page will provide you (the User) with what you can do on this site and also how you can achieve it once you are logged in.</p>
        <p className='text-gray-700 pt-2'>{guides.text1}</p>
        <p className='text-gray-700 pt-1'>{guides.text2}</p>
        {
            guides.text3.map((item, index)=>{
                const tabs = index>0? <SpanHLight text={guides.text3b[index-1]} col={`${ index> 4? 'black': "white"}`} 
                    bCol={`${ index> 4? '#08adad': "#433"}`} border lineStyle={{fontSize:'10px'}}/> : null;
                return (
                    <div key={`${index}+key`}>
                        <p className='text-gray-700 pt-2'>
                             {index+1}. {item} 
                             {tabs}, </p>
                    </div>
                )
            })
        }
        <p className='text-purple-700 pt-4 font-bold'>Navigating the User Page</p>
        <p className='text-gray-700 pt-1'>We have the <SpanHLight text="Record Tab " bCol="teal" col="white" border lineStyle={{fontSize:'10px'}}/> 
            {guides.text4} 
            <SpanHLight text='Board Tab' col='white' bCol="#433" border lineStyle={{fontSize:'10px'}}/>
            {guides.text4b}</p>
        <p className='text-gray-700 pt-1'>{guides.text5}</p>
        <p className='text-gray-700 pt-1'> Except 
            <SpanHLight text='My Inbox Tab' col='white' bCol="#433" border lineStyle={{fontSize:'10px'}}/>,
            {guides.text6}
        </p>

        <p className='text-[magenta] pt-4 font-bold'>Membership Registeration/Update</p>
        <p className='text-gray-700 pt-1'>{guides.text7}</p>
        <p className='text-gray-700 pt-1'>{guides.text8}
            <SpanHLight text='Home Tab' col='black' bCol='#08adad' border lineStyle={{fontSize:'10px'}}/>
            {guides.text8b}
        </p>
        <p className='text-gray-700 pt-1'>{guides.text9}</p>
        <p className='text-gray-700 pt-1'>{guides.text10}</p>
        <p className='text-gray-700 pt-1'>{guides.text11}</p>
        <p className='text-gray-700 pt-1'>{guides.text12}</p>
        <p className='text-gray-700 pt-1'>
            <span className='font-bold'>Date of Birth: </span>
            {guides.text13}</p>
    </div>
  )
}


export default UserGuide;



const guides= {
 text1: "You must have logged in before viewing this User Page. User Page will be displayed in the header bar only when you are logged in.",
 text2:`As a User who has logged in, you will be able to:`,
 text3: [
    'Comment on and like Pastor Corner messages',
    'View all Birthday Celebrants for the month in ',
    'View all Wedding Anniversary Celebrants for the Month in',
    'Receive messages from the Admin in',
    'Be able to send message to Admin in',
    'Search for and Update your Membership Form in',
    'Ability to do a fresh Registration in',
    'View and generate your online offering payment statement, if any in',
 ],
 text3b: ['Birthday Celebrants Tab', 'WeddingAnn Celebrants Tab', 'My Inbox Tab', 'Contact Admin Tab', 'View Tab', 'Register Tab', 'Online Offering Tab'],
 text4:'(main tab with 4 sub tabs- Home, Register, View & Online Offering) and', 
 text4b: '(User Guide, Birthday Celebrants, Wedding Celebrants, My Inbox & Contact Admin) tabs in the User Page.',
 text5:'Membership registration/update & Online Offering statement generation are done in the main Record Tab, while information view and communication are done in the board tab.',
 text6: 'board tabs are collapsible and and the tabs can be clicked on to view their contents.',
 
 text7: 'There are default members information in the Church database. Most of those information are from the previous physical membership forms. Members with data on the database are to update their records while those not on the database are to do a fresh registration.',
 text8: 'To Register or Update your form, you are to click on the', 
 text8b: 'under Record Tab and follow the instructions.',
 text9: 'Note that in order to avoid duplication of data, you are to search for your information first whether you are doing a fresh registration or updating your form.',
 text10: 'Please, when searching for your information, ensure that your surname is well spelt. You will be required to confirm certain information about you if your data is found. This is to ensure that nobody have access to your information.',
 text11:'For a User who was registered along with his/her parent in the previous physical form and without enough information to confirm, such a user will be required to confirm his/her parent information if available.',
 text12: 'If your information in the database is scanty, you may not be able to confirm and update your record even when it is found. You will be required to contact the Admin to grant you access to that form.',
 text13: 'Members that did not provide their year of birth in their previous physical forms will have a default year of 1900. You can however update this year.'
}


const guidesAdmin= {
    text1:`Beside the navigation and functionality available to the ordinary User, an Admin User will be able to do the following on the ADMIN Tab:`,
    text2: [
       'View membership data statistics (DASHBOAR),',
       'Ability to view all members data (Members Data Tab) and carry out the following operations: Sort, Filter, View Report, Generate pdf Report, Print Report and Export Report, ',
        "Post Pastor's Corner (PastorCorner Tab),",
        'View feedbacks from users (Feedback Tab),',
        'Upload photos to the Media Page (Uploads Tab).',
    ],
   
   }
   
