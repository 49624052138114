import React from 'react'
import HorizontalLine from '../../../HorizontalLine';
import txtFields from '../../assets/textFields';
import {  txtFieldsKeys, txtFieldsSpouse, txtFieldsChild } from '../../assets/textFieldsMain';
import FormRegField from '../../components/FormRegField';

const DataFormNew = ({formIndex,form, indivForm, onChangeHandler,
    info, spouseAdded, uploadingImageFormIndex, uploadingImage}) => {
    const [fileUploadMsg, setFileUploadMsg] = React.useState('');
    const [imageSrc, setImageSrc] = React.useState('');
    
    const onChangeFileHandler=(e, index,)=>{
        if(e.target.files[0]){
            //console.log(e.target.files);
            const {name, size} = e.target.files[0];
            //const sizeFmt = `${parseInt(size/1000)}kb`;
            const imgSrc = URL.createObjectURL(e.target.files[0]);
            setFileUploadMsg(`Upload successful`);
            setImageSrc(imgSrc);
            const event = {target:{name:'photo', value:imgSrc, type:'file'}};
            onChangeHandler(event, formIndex);
            //console.log(imageSrc)
        }else{
            setFileUploadMsg("No photo selected...");
            setImageSrc(null);
        }  
    }
    const onChangeRadioHandler=(e, opt)=>{
        onChangeHandler(e, formIndex, opt);
    }
    const inputFields = info==="Spouse"? txtFieldsSpouse : info==="Child"? txtFieldsChild : txtFieldsKeys;
  return (
    <div className='m-1 mt-2 pb-5 border-2 border-gray-200 bg-gray-50 border-solid'>
        
            <div className='flex flex-col justify-center pt-3 items-center'>
                <div className={`${form.photo? 'bg-[lime]' : 'bg-gray-500'} w-[95px] h-[95px] rounded-full  flex justify-center items-center`}>
                    <img src={form.photo || ""} alt="" className="w-[90px] h-[90px] rounded-full"/>
                </div>
            </div>
            <div className={`flex w-full justify-center items-center ${uploadingImage && (uploadingImageFormIndex===formIndex)? '':'hidden'}`}>
                <p>Uploading image, please, wait...</p>
            </div>
            <HorizontalLine widths={95} margTop={5} bColor="teal" margBot={10}/>
            
            <div className="grid grid-cols-1 lg:grid-cols-2">
                {
                    txtFieldsKeys.map((item, index)=>{
                        var fld = txtFields[item];
                        const requiredFlds = item==="surname" || item==="firstname" || item==="dob" || item==="genderStat" 
                                || item==="ageGroup" || item==="instiStat" || item==="memberStat" || item==="matStat" 
                                || item==="batStat" || item==="title"? "required" : "";
                        return(
                            <FormRegField  key={`${index}+formIn`} 
                                item={item}
                                title={`${fld['title']}${requiredFlds? '*' : ""}`}
                                type={fld["type"]}
                                onChange={onChangeHandler}
                                name={item}
                                value={indivForm[item]}  
                                required={requiredFlds}
                                optArr={txtFields[item].optArr}
                                opt1={txtFields[item].opt1}
                                opt2={txtFields[item].opt2}
                            />
                        );
                    })
                }
            </div>
    </div>
  )
}

export default DataFormNew;
