import React from "react";
import {BsToggle2Off, BsToggle2On} from 'react-icons/bs';
import { useSelector } from "react-redux";
import Collapsible from "../../Collapsible";
import AdminPass from './tabDeveloper/AdminPass';
import AdvertControl from "./tabDeveloper/AdvertControl";
import SanityAssets from "./tabDeveloper/SanityAssets";
import SanityDocQuery from "./tabDeveloper/SanityDocQuery";
import TableDataSettings from "./tabDeveloper/TableDataSettings";


const DeveloperTab =()=>{
    const [bgColor, setBgColor] = React.useState(false);
    const {showAllTableFlds, memberTableViewAll, adminUser} = useSelector((state)=>state.dev);

    return(
        <div className="p-3 h-screen  text-sm relative"
            style={{backgroundColor:bgColor? '#333' : '#eee', color:bgColor? '#fff' : '#000'}}>
            <div onClick={()=>setBgColor(!bgColor)} className="absolute right-3">
                {bgColor? <BsToggle2Off size={32}/> : <BsToggle2On color="goldenrod" size={32}/>}
            </div>
            <AdminPass/>
            
            <Collapsible
                contStyle="mb-3 mx-1 border-2 border-solid border-teal-700"
                titleStyle={` bg-[teal] text-white pb-2 underline font-bold`}
                iconCol="fill-white"
                iconHoverCol="hover:fill-[yellow]"
                hideDeleteIcon
                //collapse={collapse}
                addedInfo={`Advert Control`}
                iconType="plus"
            >
                <AdvertControl/>
                <br/>
            </Collapsible>

            <Collapsible
                contStyle="mb-3 mx-1 border-2 border-solid border-teal-700"
                titleStyle={` bg-[teal] text-white pb-2 underline font-bold`}
                iconCol="fill-white"
                iconHoverCol="hover:fill-[yellow]"
                hideDeleteIcon
                //collapse={collapse}
                addedInfo={`Sanity Assets Control`}
                iconType="plus"
            >
                <SanityAssets/>
                <br/>
            </Collapsible>
            <Collapsible
                contStyle="mb-3 mx-1 border-2 border-solid border-teal-700"
                titleStyle={` bg-[teal] text-white pb-2 underline font-bold`}
                iconCol="fill-white"
                iconHoverCol="hover:fill-[yellow]"
                hideDeleteIcon
                //collapse={collapse}
                addedInfo={`Members TableData Settings`}
                iconType="plus"
            >
                <TableDataSettings
                    showAllTableFlds={showAllTableFlds}
                    memberTableViewAll={memberTableViewAll}
                    adminUser={adminUser}
                />
                <br/>
            </Collapsible>
            <SanityDocQuery/>
        </div>
    );
}

export default DeveloperTab