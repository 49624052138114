import { createSlice } from '@reduxjs/toolkit';
import fiveStarPhotos from '../../assets/fivestar';
import homeImg from '../../assets/home/_index';


const initialState = {
  photos:[],
  fiveStarPhotos:fiveStarPhotos,
  homeImg:homeImg,
}

export const photoGalleriesReducer = createSlice({
  name: 'photoGalleries',
  initialState,
  
  reducers: {
    disPhotos: (state, action) => {
      state.photos = action.payload
    },
  },
})


export const { disPhotos } = photoGalleriesReducer.actions

  
export default photoGalleriesReducer.reducer