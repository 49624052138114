import React from 'react'
import { FcGoogle } from 'react-icons/fc';
import { useSelector, useDispatch } from 'react-redux';
import { disLoginForm, disUser } from '../../context/reducers/login';
import { GoogleLogin,} from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import useOnline from "use-online";
import { client } from '../../client';
import { getShortDateFull } from '../dates';
//import useSessionStorage from '../../utils/hooks/useSessionStorage';
//import { Offline, Online } from "react-detect-offline";

const LoginForm = ({set}) => {
  const dispatch = useDispatch();
  const {loginForm} = useSelector((state)=>state.login);
  const [adminPasses, setAdminPasses] = React.useState('');
  //const [adminPassesRight, setAdminPassesRight] = React.useState(false);
  const [showInfo, setShowInfo] = React.useState(false);
  //const [showGoogleBtn, setShowGoogleBtn] = React.useState(true);
  const isOnline = useOnline();
  //const loginForm = {show:false};
  

  function handleGoogleBtnReload(){
    window.location.reload(false);
  }

  async function fetchContents() {
    const query = queryAdminPass();
    const response = await client.fetch(query);
    return response
  }  

  async function fetchUserData(id) {
    const query = `*[ _type == "user" && userID == '${id}']{userID, loginRecord}`;
    const response = await client.fetch(query);
    return response
  }
  //console.log(adminPasses)
  function loginHandler(res){
        if(loginForm.checkedAdmin){
            fetchContents().then(response=>{
              if(response===adminPasses){
                loginUserHandler(res, true);
                setShowInfo(false);
                setAdminPasses("");
              }else{setShowInfo(true);}
            })
        }else{
          loginUserHandler(res, false);
          setShowInfo(false);
          setAdminPasses("");
        }
  }

  //const tabLists = tabList; //loginForm.adminPass && user.userID !=="102523766629932282547"? tabListAdmin : loginForm.adminPass && user.userID==="102523766629932282547"? tabList : tabListBasic; 
  //console.log(loginForm)
  const loginUserHandler=(res, adminPas)=>{
      setAdminPasses("");
      const decoded = jwt_decode(res.credential);
      const {family_name, given_name,name, picture, email, sub} = decoded;
        dispatch(disLoginForm({
            ...loginForm, isOnline:true, picture:decoded.picture, show:false, adminPass:adminPas}));
          //Set session storage upon login
          set({user:true, adminPass:adminPas, checkedMember:loginForm.checkedMember, decoded:decoded});
          dispatch(disUser({surname:family_name,firstname:given_name,fullName:name, title:'',
              email:email,loginID:sub,famID:"",userID:sub, imageUrl:picture}));
          
          const dates = getShortDateFull();
          const loginDate = `${dates[2]}${dates[1]}${dates[0]}@${dates[4]}:${dates[5]}`;
          const doc = {
            _id:sub,
            _type: 'user',
            userSurname:family_name,
            userFirstname:given_name,
            image:picture,
            userEmail:email,
            userName:name,
            userID:sub,
            loginRecord:loginDate
          };
          fetchUserData(sub).then((res)=>{
            if(res.length){
              const newRecord = `${res[0].loginRecord}|${loginDate}`;
              updateUserDocument(sub, newRecord);
            }else{
              client.createIfNotExists(doc)
            }
          });
          /*
          client.createIfNotExists(doc).then(response => {
                //console.log('Login Successfull');
            }).catch(e => {
                console.log(e);
            });*/
        
  }

  const updateUserDocument = async (id, record) => {
    await  client
        .patch(id) // Document ID to patch
        .set({'loginRecord':record })
        .commit() // Perform the patch and return a promise
        .catch((err) => {
            console.error('Oh no, the update failed: ', err.message)
        })
  }

  function closeFormHandler(){
    setAdminPasses("");
    dispatch(disLoginForm({...loginForm, show:false}))
  }
  function loginTypeHandler(type){
    if(loginForm.checkedAdmin){

    }
    dispatch(disLoginForm({...loginForm, checkedAdmin:type}))
  }
  function pinCheckhandler(){
    dispatch(disLoginForm({...loginForm, checkedPin:!loginForm.checkedPin}))
  }
  function memberCheckHandler(type){
      dispatch(disLoginForm({...loginForm, checkedMember:type}))
  }
 React.useEffect(()=>{
  if(loginForm.checkedAdmin){
    dispatch(disLoginForm({...loginForm, checkedMember:'member'}))
  }else{
    dispatch(disLoginForm({...loginForm, checkedMember:'nonmember'}))
  }
 },[loginForm.checkedAdmin]);
  
  return (
    <div className={`${!loginForm.show && 'hidden'} fixed w-full bg-blackOverlay h-screen z-50 top-0`}>
      <div className={`${!loginForm.show && 'hidden'} flex justify-center items-center  bg-blackOverlay absolute z-50 w-full h-screen`}>
        
        <div className="w-11/12 mx-1 smc:mx-5 smc:min-w-[300px] xsmc:w-auto 
            relative pb-5  
            rounded-lg 
            shadow-lg bg-white animate-slide-in-top">
          <LoginType loginType={loginTypeHandler} adminLogin={loginForm.checkedAdmin}/>
          {/*<div className='absolute top-5 right-7 cursor-pointer hover:bg-red-500 hover:text-white text-[#ff0000] px-3 py-1 rounded-xl font-bold'
            onClick={coloseFormHandler}>X</div>*/}
          <form className='p-4 lg:p-16'>
            <div className={`${loginForm.checkedAdmin && "hidden"} `}>
              <label className="form-check-label flex justify-center text-gray-800 text-center mb-3">
                Are you a member of PS Ogba?
              </label>
              <div className="text-center mb-2">  
                  <input
                    className='scale-[1.3]'
                    type="radio"
                    value="member"
                    checked={loginForm.checkedMember === "member"}
                    onChange={()=>memberCheckHandler('member')}
                  />
                  <label className='pl-2'> 
                  I am a member
                  </label>
              </div>
              <div className="text-center mb-2">
                  <input
                    className='scale-[1.3]'
                    type="radio"
                    value="nonmember"
                    checked={loginForm.checkedMember=== "nonmember"}
                    onChange={()=>memberCheckHandler('nonmember')}
                  />
                  <label className='pl-2'> 
                  I am not a member
                  </label>
              </div>
            </div>
            <div className={`${!loginForm.checkedAdmin && "hidden"} form-group mb-6`}>
              <input type={loginForm.checkedPin? "text" : "password"} 
                maxLength={7}
                value={adminPasses}
                onChange={(e)=>{setAdminPasses(e.target.value); setShowInfo(false)}}
                className="form-control block
                w-11/12
                px-3
                py-1.5
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput91"
                placeholder="PIN"/>
                <input type="checkbox"
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                  id="exampleCheck96"
                  checked={loginForm.checkedPin}
                  onChange={pinCheckhandler}
                  
                />
                <label className="form-check-label inline-block text-gray-800 text-center" htmlFor="exampleCheck96">
                Show
              </label>
              <p className={`text-red-700 text-sm max-w-[230px] ${showInfo? '' :'hidden'}`}>Wrong PIN! <br/>Please, use User Login if you are not an Admin.</p>
            </div>
              
              <div className="flex flex-col justify-center items-center pt-6">
                <button
                  type="button"
                  className="bg-[ivory] hidden border-2 border-slate-300 border-solid shadow-2xl justify-center items-center p-3 rounded-lg cursor-pointer outline-none"
                  //onClick={()=>googleLoginHandler()}
                  //disabled={renderProps.disabled}
                >
                  <FcGoogle className="mr-4" /> Sign in with google
                </button>
              
                  <div className={`flex h-full w-fit justify-center items-center
                      `}>
                  
                  <>
                    <GoogleLogin 
                      onSuccess={(res)=> {
                      loginHandler(res)
                    }}
                    //onError={(res)=> console.log(res)}
                    width="100%"
                    auto_select
                    /> 
                  </>
                  
                  <>
                    <button
                      type="button"
                      className="bg-[ivory] hidden border-2 border-slate-300 border-solid shadow-2xl justify-center items-center px-3 py-2 rounded-lg cursor-pointer outline-none"
                      //onClick={googleLoginHandler}
                      //disabled={renderProps.disabled}
                     >
                      <FcGoogle className="mr-4" /> !Network Error
                    </button>
                  </>
                </div>

                <div className="bg-[maroon] text-white hover:bg-[red] px-5  mt-5 border-2 border-slate-300 border-solid shadow-2xl flex justify-center items-center py-2 rounded-lg cursor-pointer outline-none"
                 onClick={closeFormHandler}>
                  Close
                </div>

                <div className={`flex-col items-center text-red-700 ${isOnline? 'hidden' : ''}`}>
                  <p className='text-center'>Connection error!</p>
                  <p className='text-center'>Please, check your network or reload the page</p>
                </div>
                <div className="hidden bg-teal-700 text-white hover:bg-[red] px-5  mt-5 border-2 border-slate-300 border-solid shadow-2xl justify-center items-center py-2 rounded-lg cursor-pointer outline-none"
                 onClick={handleGoogleBtnReload}>
                  Reload
                </div>
              </div>
          </form>
    </div>
  </div>
</div>
  )
}



function LoginType({loginType, adminLogin}){
  function loginTypehandler(type){
    loginType(type==="ADMIN"? true : false);
  }
  const log = 'cursor-pointer hover:text-white py-2 bg-gray-400 flex-1 items-center flex justify-center  border-white border-solid';
  return(
    <div className=' text-gray-800 w-full flex'>
        <h1 className={`${log} ${!adminLogin && 'bg-blue-400'} border-r-2 rounded-tl-lg text-center`}
          onClick={()=>loginTypehandler('USER')}>User Login</h1>
        
        <h1 className={`${log} ${adminLogin && 'bg-blue-400'} border-l-2 rounded-tr-lg text-center`}
          onClick={()=>loginTypehandler('ADMIN')}>Admin Login</h1>
    </div>
  );
}
export default LoginForm;

function queryAdminPass(){   
  const query = `
  *[ _type == "controls" && title=="AdminPass"][0].pass`;
return query
}

/*
const objUser = {
  userId:"adebolaJames",
  addressOff: "Ikeja",
  addressRes: "PLOT 435, MICHAEL OKPARA WAY",
  batStat : "Baptised",
  dob: "2022-01-19",
  email: "james@gmail.com",
  famStat: 1,
  family: "Father",
  firstname: "James",
  genderStat: "Male",
  info: "User",
  instiStat: "Professor",
  matStat: "Married",
  memberStat: "Worker",
  occupation: "Accountant",
  othernames: "Damilola",
  phoneNo: 23408064205333,
  photo: "",
  school: "Uniben",
  surname: "Adebola",
  wedAnn: "2020-02-15",
};*/