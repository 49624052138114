import React from 'react'
import { getTodayMonth} from '../../../../utils/miscens';
import { MdCake } from 'react-icons/md';
import { useSelector } from 'react-redux';
import useOnline from 'use-online';
import { BsWifiOff } from 'react-icons/bs';


const CelebrantsBirthday = () => {
  const isOnline = useOnline();
  const {celebBirthday,} = useSelector((state)=>state.user);
  const bdCelebrants = celebBirthday.month;
  const todayCelebrants = celebBirthday.today;
  //console.log(mnthCelBd);

  if(!isOnline){
    return (
      <div className='text-red-700 flex justify-center pt-[15px]'>
          <BsWifiOff size={30} color="red" className='mr-3 inline-block'/>
          Connection error!. Please, check your network.
      </div>
    );
  }
    return (
    <div className='flex flex-col py-2 px-3 text-gray-700'
     >
        <p className='text-[blue] font-bold underline'>{getTodayMonth()[1]} Birthday Celebrants</p>
        <div className='flex flex-col'>
          {
            bdCelebrants?.map((item,i)=>{
              const isToday = todayCelebrants.includes(item);
              return (
                <div key={`${i}+tab`}
                  className={`${isToday?'bg-yellow-200 text-black' : ''}  pl-2 p-1 mb-1 rounded-md`}>
                  {i+1}. {item}
                  <MdCake className={`${isToday? "" : 'hidden'} text-[coral] inline-block ml-4   `} size={26}/>
                </div>
              );
            })
          }
        </div>
    </div>
  )
}

export default CelebrantsBirthday;
