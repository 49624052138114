import React  from 'react';
import { useSelector } from 'react-redux';
import useOnline from 'use-online';
import { client } from '../../../../client';
import { BsWifiOff } from 'react-icons/bs';
import { v4 as uuidv4 } from 'uuid';
//import { processPdfDocAssets } from '../../modules/sanityDocOperations/updateAndDeleteDoc';

const ContactAdmin = () => {
  const {user, loginForm} = useSelector((state)=>state.login);
  const [sender,] = React.useState(user.fullName);
  const [senderEmail,] = React.useState(user.email);
  const [subjectMessage, setSubjectMessage] = React.useState({phoneNo:'', subject:'', message:''});
  const [info, setInfo] = React.useState({show:false, msg:''});
  const isOnline = useOnline();
  const [sendingMsg, setSendingMsg] = React.useState({sending:false,})
  const [errorInfo, setErrorInfo] = React.useState({show:false, msg:''});
  const [uploadedFile, setUploadedFile] = React.useState('');
  const [showUploadError, setShowUploadError] = React.useState(false);

  const sendMailHandler=(e)=>{
    e.preventDefault();
    if(!sendingMsg.sending){
    //if(isOnline && )
    
        if(isOnline && loginForm.isOnline){
            setSendingMsg({sending:true});
            
            const inputs = {...subjectMessage, sender, senderEmail};
            postFeedbackMsg(inputs, user, uploadedFile).then((res)=>{
                setInfo({show:true, msg:'Message sent successful!'});
                resetCall();
            })  
        }else{
            setErrorInfo({show:true, msg:'You must login first before you can send message to Admin!'});
            setTimeout(()=>setErrorInfo({show:false, msg:''}), 5000);
        }
    }
  }

  function resetCall(){
    setSendingMsg({sending:false});
    setTimeout(()=>setInfo({show:false, msg:''}), 5000);
    setSubjectMessage({phoneNo:'', subject:'', message:''});
    setUploadedFile('');
    setShowUploadError(false);
  }
  const onChangeHandler=(e)=>{
      const {name, value} = e.target;
      const subjectMessageCopy = {...subjectMessage};
      subjectMessageCopy[name] = value;
      setSubjectMessage(subjectMessageCopy);
  }

  const onChangeFileHandler=(e)=>{
    setShowUploadError(false);
    const {files} = e.target;
    if(files.length){
        const file = files[0];
        let type = file.name.split('.');
        type = type[type.length-1];
        if(type === 'pdf'){
            processPdfDocAssets(file);
            setShowUploadError(false);
        }else{
            setShowUploadError(true);
            setUploadedFile('');
        }
        //console.log(res);
    }
  }
  const processPdfDocAssets = async(file) =>{
       await client?.assets
        .upload('file', file, { contentType: file.type, filename: file.name })
        .then((document) => {
         setUploadedFile(document);
     });
 }
 //console.log(uploadedFile);

  if(!isOnline){
    return (
      <div className='text-red-700 flex justify-center pt-[15px]'>
          <BsWifiOff size={30} color="red" className='mr-3 inline-block'/>
          Connection error!. Please, check your network.
      </div>
    );
  }
  return (
    <div className='my-4 mx-1 sm:mx-3 pb-10'>
        
        <p className='p-1 pb-3 underline font-bold text-blue-800'>Send Mail to Admin</p>
        <form onSubmit={sendMailHandler} className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
                <TextField
                    name="sender"
                    value={sender || 'Sender'}/>
                <TextField
                    name="senderEmail" 
                    value={senderEmail || 'Sender email'}/>
                <InputText
                    name="phoneNo"
                    placeholder="Phone number" 
                    required="required"
                    type='text'
                    value={subjectMessage.phoneNo}
                    onChange={e=>onChangeHandler(e)}
                    />
                <InputText
                    name="subject"
                    placeholder="Subject" 
                    required="required"
                    type='text'
                    value={subjectMessage.subject}
                    onChange={e=>onChangeHandler(e)}
                    />
            </div>
            <TextArea
                name="message"
                placeholder="Message..." 
                required="required"
                rows={7}
                value={subjectMessage.message}
                onChange={e=>onChangeHandler(e)}/>
            
            <InputText
                name="subject"
                placeholder="Subject" 
                //required="required"
                type='file'
                //value={subjectMessage.subject}
                onChange={e=>onChangeFileHandler(e)}
                />
            <p className={`${showUploadError? '' :'hidden'} text-red-700`}>Attached file format must be in pdf</p>
            <p className={`${info.show? 'visible' :'invisible'}`}>{info.msg}</p>
            <p className={`${errorInfo.show? '' :'hidden'} px-2 text-red-600`}>{errorInfo.msg}</p>
            <InputText
                inputStyle={`w-fit text-white active:bg-stone-300 px-5 ${sendingMsg.sending? submitBtn.noneActive : submitBtn.active}`}
                name="sendmail"
                placeholder="Send Mail"
                value="Send Mail" 
                required="required"
                type='submit'
                //onChange={e=>onChangeHandler(e)}
                />
        </form>
    </div>
  )
}

export default ContactAdmin;

const submitBtn = {
    noneActive:'bg-stone-500 border-solid',
    active:'bg-blue-700 focus:text-gray-700 focus:bg-white focus:border-blue-600 border-solid border-gray-300',
}

function postFeedbackMsg(inputData,user, uploadedFile){
    const docId = new Date().toUTCString().toLocaleLowerCase().replace(/ |:|,/g,'.').replace('..','.');
    const {sender, senderEmail,phoneNo, subject, message} = inputData;
    const feedbackDoc = {
    _id:`contactusfeedback_${docId}`,
    _type: 'feedbackMessages',
    title: subject,
    userName:sender,
    userEmail:senderEmail,
    phoneNo:phoneNo,
    message:message,
    datePosted: new Date().toUTCString(),
    attachedFile: uploadedFile? {
        _type: 'file',
        _key: uuidv4(),
        asset: {
          _type: 'reference',
          _ref: uploadedFile?._id,
        },
      } : {},
    postedBy:{ _type: 'postedBy', _ref:user.loginID } ,
    }
    return client.createIfNotExists(feedbackDoc);
  }



const InputText=({onChange, value, placeholder, name, required, type, contStyle, inputStyle, inputStyleSet})=>{
    
    return(
        <div className={`mb-1 sm:mb-2 ${contStyle}`}>
            <input type={type} 
              className={`form-control block
                w-full
                px-3
                py-2
                text-base
                font-normal
                bg-clip-padding
                border
                rounded
                transition
                ease-in-out
                m-0                 
                focus:outline-none
                ${inputStyleSet? '' : 'bg-white focus:text-gray-700 focus:bg-white focus:border-blue-600 border-solid border-gray-300'}
                ${inputStyle}`}

                placeholder={placeholder}
                name={name}
                onChange={onChange} 
                value={value}
                required={required}/>
        </div>
                    
    );
}


const TextField=({value, contStyle, inputStyle})=>{
    
    return(
        <div className={`mb-1 sm:mb-2 ${contStyle}`}>
            <div  
                className={`form-control block
                w-full
                px-3
                py-2
                text-base
                font-normal
                text-gray-700
                bg-white bg-clip-padding
                border border-solid border-gray-300
                rounded
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:bg-white 
                focus:border-blue-600 focus:outline-none
                ${inputStyle}`}>
                {value}
            </div>
        </div>
                    
    );
}



const TextArea =({onChange, value, placeholder, name, required, rows, contStyle, inputStyle})=>{

    return(
        <div className={`mb-1 sm:mb-2 ${contStyle}`}>
            <textarea
                className={`
                    form-control
                    block
                    w-full
                    px-3
                    py-2
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white 
                    focus:border-blue-600 focus:outline-none
                    ${inputStyle}`}
                rows={rows}
                placeholder={placeholder}
                name={name}
                onChange={onChange} 
                value={value}
                required={required}>
            </textarea>
        </div>
    );
}

