import { v4 as uuidv4 } from 'uuid';
import { getShortDate } from '../../../../dates';


const queryOfferingsCount = `
{
  "count":count(*[ _type == "onlineOffering" ]),
}
`;

function createOfferingDoc(newDoc, no) {
 const {date, program, type, dateDetails, total, data} = newDoc;
 const dateStrArr = getShortDate(date);
 const dateStr = `${dateStrArr[0]}${dateStrArr[1]}${dateStrArr[2]}${dateStrArr[3]}`;

   //postedBy: { _type: 'postedBy', _ref:loginID } ,
 const prosData = data?.map(item=> { 
     return {
             _key: uuidv4(),
             _type: "datas",
             amount: item.amount,
             onlinePayer: {
                 _type: "onlinePayer",
                 _ref: item.familyID,
               },
         }
 });
 const doc = {
     _id:`${program.toLowerCase().replace(/\s/g,'')}.${dateStr.toLocaleLowerCase()}`,
     _type: 'onlineOffering',
     program,
     dateDetails,
     date,
     docID:parseFloat(no+1),
     type,
     total:parseFloat(total),
     data:prosData
   };

 return doc
}

function dommyDoc(){

 return {
     _id: uuidv4(),
     _type: "onlineOffering",
     program:'The Hour of breakthrough',
     dateDetails:'2023-01-17|The Hour of breakthrough',
     date:'2023-01-17',
     docID:12,
     data: [
       {
         _key: uuidv4(),
         _type: "datas",
         amount: 400,
         onlinePayer: {
           _type: "onlinePayer",
           _ref: "adediran.olalekan.opeyemi"
         },
       }
     ]
   }
}


export {createOfferingDoc, queryOfferingsCount, dommyDoc}