import React from 'react'
import { useSelector } from 'react-redux';
import DataForms from '../membersData_ViewReg/register/DataForms';
import FormsView from '../membersData_ViewReg/view/FormsView';
import Dashboard from './Dashboard';
import MembersDataView from "../admin/membersData/_MembersDataViewManager";
import AdminDashboard from '../admin/dashboard/Dashboard';
import DeveloperTab from '../controlPanel/Index';
import IndexUpload from '../admin/uploads/Index';
import tabsSchema from '../headerTabs/headerSchema';
import IndexPastorCorner from '../admin/pastorCorner/IndexPastorCorner';
import AboutHome from '../about/AboutHome';
import IndexFeedback from '../admin/feedback/Index';
import HorizontalLine from '../../HorizontalLine';
import AccountIndex from '../admin/account/Index';
import { useNavigate } from 'react-router-dom';
import DeveloperMembersInfo from '../controlPanel/membersQuery/Index';
import UploadDownloadIndex from '../controlPanel/uploadDownload/Index';
import OnlineOfferingIndex from '../record/onlineOffering/Index';
import UserPageLogin from '../controlPanel/userPageLogin/UserPageLogin';
import IndexGuest from '../guest/account/_IndexGuest';

const HomePage = () => {
    const {seltdTab} = useSelector((state)=>state.user);
    const {loginForm, userType, loginUser } = useSelector((state)=>state.login);
    const navigate = useNavigate();
    //const isNotmember = loginForm.checkedMember === "nonmember";
    //titles:['RECORDABOUT','RECORDREGISTER', 'RECORDVIEW']},
    //['ADMINDASHBOARD','ADMINMEMBERSDATA', 'ADMINPASTORCORNER', 'ADMINUPLOADS']},
    //titles:['CONTROLDEVELOPER', 'CONTROLREGISTER',]},
 
    const returnedHeader = {
        [tabsSchema.tabs.RECORDABOUTHOME]:<AboutHome/>,
        [tabsSchema.tabs.RECORDREGISTER]:<DataForms/>,
        [tabsSchema.tabs.RECORDVIEW]:<FormsView/>,
        [tabsSchema.tabs.RECORDONLINEOFFERING]:<OnlineOfferingIndex/>,
        
        [tabsSchema.tabs.ADMINMEMBERSDATA]:<MembersDataView/>,
        [tabsSchema.tabs.ADMINDASHBOARD]:<AdminDashboard/>,
        [tabsSchema.tabs.ADMINUPLOADS]:<IndexUpload/>,
        
        [tabsSchema.tabs.CONTROLONLINEOFFERING]:<DeveloperMembersInfo/>,
        [tabsSchema.tabs.CONTROLDEVELOPER]:<DeveloperTab/>,
        [tabsSchema.tabs.CONTROLUSERPAGELOGIN]:<UserPageLogin/>,
        [tabsSchema.tabs.CONTROLUPLOADDOWNLOAD]:<UploadDownloadIndex/>,

        [tabsSchema.tabs.ADMINPASTORCORNER]:<IndexPastorCorner/>,
        [tabsSchema.tabs.ADMINFEEDBACK]:<IndexFeedback/>,
        [tabsSchema.tabs.ADMINACCOUNT]:<AccountIndex/>,

        [tabsSchema.tabs.GUESTACCOUNT]:<IndexGuest/>,
    };
  
  //SecurePage- 1 of 3
  if(!loginForm.isOnline){
    navigate("/");
  }
 /* React.useEffect(()=>{
    if(isNotmember){
      returnedHeader[seltdTab[2].title]
    }
  },[isNotmember]);*/
  //console.log([loginForm]);

  return (
    <div>
        <Dashboard/>
        <HorizontalLine bColor="red" widths={98} heights={3} margBot={10} margTop={10}/>
        <div>
            <p className='px-4 font-bold text-blue-800 underline text-lg'>
              {seltdTab[2].name}- 
              <span className='text-[mediumblue] no-underline ml-2'>LoginType:</span>
              <span className='text-[maroon] no-underline ml-2'>{`${loginUser.user}`}</span>
            </p>
            {
              returnedHeader[seltdTab[2].title]
            }
        </div>
    </div>
  )
}

export default HomePage

