import React from 'react';
import { getShortDate } from '../../../../../../dates';


export const TablePrint = ({tableHeader, tableBody, dataKeys,}) => {
    
      return (
        <div className="overflow-y-auto border-2 border-solid flex justify-center bg-sky-100">
        <table className="border-spacing-0 p-0 w-[90%] border-[1px] border-solid border-[blue]">
            <thead>
                <tr className='min-h-[25px] even:bg-[#dfdfdf]'>
                    <th 
                       className={`whitespace-nowrap w-[7%] bg-[#ccc] ${frozenCols} `}>
                        {'SN'}
                    </th>
                    <th  
                        className={`whitespace-nowrap w-[20%] bg-[#ccc] ${frozenCols} `}
                         >
                        {tableHeader[0]}
                    </th>
                    {
                        tableHeader?.slice(1).map((keys, i)=>{
                            const isProgram = keys.includes('|');
                            const header = isProgram? keys.split('|') : keys; 
                            let dateStr = "";
                            if(isProgram){
                                const dateStrArr = getShortDate(header[0]);
                                dateStr = `${dateStrArr[0]} ${dateStrArr[2]} ${dateStrArr[1]}, ${dateStrArr[3]}`;
                            }
                            
                            return(
                                <th key={`${i}+header`}
                                    className={`whitespace-nowrap  bg-[#ccc] ${frozenCols} ${i !==0? 'w-[17%]' : ''}`}
                                    >
                                    {isProgram? 
                                        <div className='flex flex-col justify-center items-center flex-wrap'>
                                            <span>{dateStr}</span>
                                            <span>{header[1]}</span>
                                        </div> 
                                        :header}
                                </th>
                            );
                        })
                    }
                    
                </tr>
            </thead>
            <tbody>
                {
                    tableBody?.map((body, index)=>{
                        const even = index%2 === 1? true : false;
                        const rows = `p-0 px-[5px] outline-[1px] outline border-none outline-offset-[-1px] outline-[#ddd] whitespace-nowrap`;
                        const frozenCol = `${rows} ${"text-black"} `;
                        const frozenSn = ` ${rows} ${"text-[blue]"} `;
                        const sumTotalLine = tableBody.length-1 === index;
                        const dateStrArr = getShortDate(body[dataKeys[0]]);
                        const dateFmt = `${dateStrArr[0]} ${dateStrArr[2]} ${dateStrArr[1]}, ${dateStrArr[3]}`;
                        const dateDisplay = body[dataKeys[0]]===""? "" : dateFmt;

                        return(
                            <tr className="min-h-[25px] h-[25px] even:bg-[#eee] " key={`${index}+tab`}>
                             <td className={` ${rows}`}
                              >
                                    {sumTotalLine? "" : index+1}
                                </td>
                                <td className={` ${rows}`}>
                                    {dateDisplay}
                                </td>   
                                {
                                    dataKeys?.slice(1).map((keys, idx)=>{
                                        const notNAN = isNaN(body[keys])? true : false;
                                        var noType = "";
                                        if(!notNAN){ //If is a number
                                            if(body[keys]<0){
                                                noType = 'NEG'
                                            }else{ noType = "POS"}
                                        }
                                        var display = ""; 
                                        if(isNaN(body[keys])){
                                            display = body[keys]
                                        }else{
                                            if(body[keys]===0){
                                                display = "-"
                                            }else{
                                                display = new Intl.NumberFormat('en-US', 
                                                { style: 'currency', currency: 'USD' }).format(body[keys]);
                                            }
                                        }
                                        
                                        return(
                                            <td key={`${idx}+header`}
                                                className={`${rows} ${noType==="NEG"? 'text-[red]' : ''}
                                                    ${sumTotalLine? 'font-bold text-blue-700' : ''}`}>
                                                {display}
                                            </td>
                                        );
                                    })
                                }           
                                
                            </tr>
                        );
                    })
                }
            </tbody>

        </table>
    </div>
  )
};


export default TablePrint;



const frozenCols = `text-black  min-h-[30px] h-[30px] 
        text-left px-[5px]  p-0 outline-[2px] outline-[dodgerblue] outline border-none outline-offset-[-1px]`;
    const hiddenFlds = `whitespace-nowrap  min-w-[100px] bg-[navy] ${frozenCols}`;
    const zIndex = 'z-[10]';
