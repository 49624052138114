import React, { useEffect } from 'react'
import Collapsible from '../../../Collapsible'
//import HorizontalLine from '../../../HorizontalLine'
import { TextInput, TextAreaInput } from '../../components/InputComponents'
import { getPastorCornerFields } from './constants'
import Preview from './Preview'
import pastorCornerPhotos from '../../../../assets/pastorCorner/_index';
//import useAddAndGetPastorMsgs from '../../../pcorner/useAddAndGetPastorMsgs';
import generateRandomBetween from '../../../../utils/generateRandomBtwn';
import { getDateString } from '../../../../utils/getDateString'
import { client } from '../../../../client'
import { generateMessageID } from '../../../pcorner/data'
//import getPastorCorner from '../../../../assets/data/pastorCornerMain'
import getPastorCornerNew from '../../../../assets/data/pastorCornerLatest1'
import useFetchData from '../../../pcorner/useFetchData'
import { useDispatch, useSelector } from 'react-redux'
import { disPstCornerMsg } from '../../../../context/reducers/pstCornerMsg'
import { getQueryPstCorner, processPastorCornerMessages } from '../../../pcorner/pstCornerQueries'


const IndexPastorCorner = () => {
  const pstcornerflds = getPastorCornerFields('pstcornerflds');
  const dispatch = useDispatch();
  //const [pastorMsg, setPastorMsg] = useAddAndGetPastorMsgs();
  const [collapse, setCollapse] = React.useState(0);
  const [seltdImage, setSeltdImage] = React.useState({src:pastorCornerPhotos[0].src, sn:0});
  const [formValues, setFormValues] = React.useState(pstcornerflds.values);
  const [postFeedback, setPostFeedback] = React.useState({error:false,show:false, errorMsg:"Message posted successfully!"});
  const [fetchedData, refetchDataCall] = useFetchData();
  //const [pstCornerLen, setPstCornerLen] = React.useState(0);
  const [postingMsg, setPostingMsg] = React.useState(false);
  
  const {user} = useSelector((state)=>state.login);
  const {loginID,} = user;

  const onChangeHandler =(e)=>{
    const {name, value} = e.target;
    const newValue = {...formValues, [name]:value}
    setFormValues(newValue);
    setCollapse(prev=>prev+1)
  }
  const onSubmitHandler =(e)=>{
    if(postingMsg){return}
    e.preventDefault();
    e.stopPropagation();
    if(loginID){
      getLengthAndCreatePost();
      setPostingMsg(true);
    }else{ 
      const msg = {show:true, error:true, errorMsg:'Please, you need to login before you can make a post.'}
      setPostFeedback(msg);
      setTimeout(()=>setPostFeedback({show:false, error:true, msg:''}), 8000);
    };

    //console.log(formValues);
  }

  /*function fetchDataHandler(){
      refetchDataCall();
  }*/
 
  
  const getLengthAndCreatePost = async ()=>{
    await client.fetch(queryPstCorner).then((data) => {
      const len = data.length;
      createDoc(len);
    });

    function createDoc(len){
      const pastMsg = {
        sn:parseInt(len)+1,
        postedBy: { _type: 'postedBy', _ref:loginID } ,
        topic:formValues.topic,
        bibleRef:formValues.bibleRef,
        body:formValues.messagebody,
        pray:formValues.prayer,
        comments: [],
        details:{author:"Admin", date:formValues.date, }
      }
      //setPastorMsg(pastMsg);
      const doc = createPastorCornerDoc(pastMsg);
      client.createIfNotExists(doc).then(response => {
          setPostFeedback({error:false,show:true, errorMsg:"Message posted successfully!"});
          setPostingMsg(false);
          setFormValues(pstcornerflds.values);
          const queryPstCorner = getQueryPstCorner();
          fetchAndSetData(queryPstCorner);
          //console.log(response)
        }).catch(e => {
          setPostFeedback({error:true,show:true, errorMsg:"Error! Message could not be posted."});
      });
      setTimeout(()=>setPostFeedback({error:false,show:false, errorMsg:""}), 10000);
    }

  }

  const pstCornerNew = getPastorCornerNew();
  /*const createMultipleFiles = async () => {
    for (let index = 0; index < pstCornerNew.length; index++) {
    const doc = createPastorCornerDocs(pstCornerNew[index]);
     await client.createIfNotExists(doc).then(res => {
        console.log(`File no. ${res.sn} successfully uploaded!`)
      }).catch(e => {
        console.log(e.error)
      });
    }
    console.log('All FILES UPLOADED!')
  }*/

  const resetHandler =()=>{
    const pstcornerflds = getPastorCornerFields('pstcornerflds');
    setFormValues(pstcornerflds.values);
  }
  const msgbody = formValues.messagebody.replace(/\r\n/g, '\n').split('\n');
  useEffect(()=>{
    const randNo = generateRandomBetween(0, pastorCornerPhotos.length);
    //const randPhoto = pastorCornerPhotos[randNo].src;
    //onClickImage({src:randPhoto, name:'', sn:randNo, title:''});
  },[]);

  
  function fetchAndSetData(queryPstCorner){
    async function fetchContents(query) {
        const response = await client.fetch(query);
        // waits until the request completes...
        return response;
      }
    const fetchProgRes = fetchContents(queryPstCorner);
    fetchProgRes.then((res)=>{
    const processRes = processPastorCornerMessages(res);
    dispatch(disPstCornerMsg(processRes));
    //console.log(processRes);
    }); 
  }

  return (
    <div className='shadow-lg m-3 pb-10'>
            <form onSubmit={onSubmitHandler}>
                <div>
                    <Collapsible
                        contStyle="my-3 mx-1 border-2 border-solid border-teal-700"
                        titleStyle={`${formValues.surname? 'bg-blue-800' : 'bg-teal-600'} text-white`}
                        iconCol="fill-white"
                        iconHoverCol="hover:fill-[yellow]"
                        hideDeleteIcon
                        collapse={collapse}
                        addedInfo={"Preview"}
                        >
                       
                        <Preview formValues={formValues} 
                          msgbody={msgbody} photo={seltdImage}/>
                      </Collapsible>
                  </div>
                  <div className='hidden'>
                    <p>Select display photo</p>
                    <div className='p-2 flex overflow-y-hidden bg-[aliceblue] overflow-x-auto flex-row items-center boder border-2 border-solid border-gray-500 mb-5 mt-2 h-[200px]'>
                        {
                          pastorCornerPhotos.map((item, i)=>{
                            //{sn:1,src:photo1,name:''},
                            return (
                              <img key={i+'photo'} src={item.src} alt={item.name+'photo'} 
                                className="flex w-[250px] p-[3px] h-full m-2"
                                //onClick={()=>onClickImage({index:i, src:item.src})}
                                style={{backgroundColor:seltdImage.index===i? 'lime' : ''}}/>
                            );
                          })
                        }
                    </div>
                  </div>
                  <div className='grid lg:grid-cols-2'>
                    <div>
                      {
                        pstcornerflds?.fields.slice(0,3).map((fld, i)=>{

                          return (
                            <TextInput
                              key={`${i}+key`}
                              title={fld.title}
                              subTitle={fld.subTitle}
                              placeholder={fld.pholder}
                              type={fld.type}
                              value={formValues[fld.name]} 
                              onChange={onChangeHandler} 
                              required={fld.required}
                              name={fld.name}
                              autoComplete={'autoComplete'}
                            />
                          );
                        })
                      }
                    </div>
                    
                    <div>
                      <TextAreaInput
                          title={pstcornerflds.textarea.fields.title}
                          subTitle={pstcornerflds.textarea.fields.subTitle}
                          placeholder={pstcornerflds.textarea.fields.pholder}
                          type={pstcornerflds.textarea.fields.type}
                          value={formValues[pstcornerflds.textarea.fields.name]}
                          onChange={onChangeHandler} 
                          required={pstcornerflds.textarea.fields.required}
                          name={pstcornerflds.textarea.fields.name}
                          autoComplete={'autoComplete'}
                        />
                      <TextInput
                        title={pstcornerflds.fields[3].title}
                        subTitle={pstcornerflds.fields[3].subTitle}
                        placeholder={pstcornerflds.fields[3].pholder}
                        type={pstcornerflds.fields[3].type}
                        value={formValues[pstcornerflds.fields[3].name]} 
                        onChange={onChangeHandler} 
                        required={pstcornerflds.fields[3].required}
                        name={pstcornerflds.fields[3].name}
                        autoComplete={'autoComplete'}
                      />
                    </div>
                  </div>
                  <p className={`ml-4 my-2 ${postFeedback?.show? '':'hidden' } ${postFeedback?.error? 'text-red-600': 'text-[green]'}`}>
                    {postFeedback?.errorMsg}
                  </p>
                  <div className='w-fit'>
                    <TextInput type="submit" value="POST"
                      contStyle={'sm:w-44 cursor-pointer'}
                      inputStyle={`${postingMsg? 'bg-[silver]': 'bg-blue-800'} text-white hover:bg-blue-500 
                      focus:bg-blue-500 focus:border-slate-600
                      `}/>
                    <TextInput type="button" value="RESET"
                      contStyle={'sm:w-44 cursor-pointer'}
                      inputStyle={`bg-red-800 text-white hover:bg-red-500 
                        focus:bg-red-500 focus:border-blue-600
                        `}
                      onClick={resetHandler}/>
                    <TextInput type="button" value="CREATE DOCS"
                      contStyle={'sm:w-44 cursor-pointer hidden'}
                      inputStyle={`bg-teal-800 text-white hover:bg-teal-500 
                      focus:bg-teal-500
                      `}
                      //onClick={fetchDataHandler}
                      />
                    </div>
              </form>
    </div>

  )
}


export default IndexPastorCorner;


function createPastorCornerDoc(form) {
  const {sn, postedBy, topic, bibleRef, body, pray, details} = form;
  const id = generateMessageID(topic, sn);
  //const bodyText = body.join('\n\n');

  const doc = {
      _id:id,
      _type: 'pastorCorner',
      topic,
      messageID:id,
      sn,
      pray,
      bibleRef,
      body,
      postedBy,
      date:getDateString(details.date),
    };

  return doc
}




/*function createPastorCornerDocs(form) {
  const {sn,details, title, bibleRef, body, pray, } = form;
  const id = generateMessageID(title, sn);
  const bodyText = body.join('\n\n');

  const doc = {
      _id:id,
      _type: 'pastorCorner',
      topic:title,
      messageID:id,
      sn,
      pray,
      bibleRef,
      body:bodyText,
      date:details.date,
    };

  return doc
}*/

const queryPstCorner =
`
    *[_type == "pastorCorner"] | order(date desc){
        _id,
        topic,
        messageID,
        sn,
    }
 `;
