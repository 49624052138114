import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useReactToPrint } from 'react-to-print';
import generateReportFunction from '../../../utils/generateReportFunction';
import { client } from '../../../client';
import { getQueryLoginMemberByLoginID, } from '../admin/queries/queryMembersAllData';
import { sortFamilyData } from '../modules/sortRecord';
import { disUpdateRecord, disSeltdTab } from '../../../context/reducers/user';
import { disUserFetchedData, disUserFetchedDataOthers, disAccesspermitList, disUserRecords, disPdfReportData, 
  disUserSearchedRecords, disCurrentStep, disMyInbox,} from '../../../context/reducers/userSub';
import { tabsNav } from '../headerTabs/headerSchema';
import useOnline from "use-online";
import { setLoginIDToMemberHandler, setAccessPermitToMemberHandler, fetchContents, fetchMultipleFiles, removeDeniedFromPermitAccess, registerLoginMember } from './fetchDatamodules';
import { replaceUserLoginID, updateUserDeletedAccess, updateUserDeletedLoginID } from './updateDataModules';
import AboutHomeDisplay from './AboutHomeDisplay';
import AboutHomeDisplayNonMember from './AboutHomeDisplayNonMember';
//import { useDispatch, useSelector } from 'react-redux';

const AboutHome = () => {
  const dispatch = useDispatch();
  const userOnline = useOnline();
  const {user, loginForm, loginUser} = useSelector((state)=>state.login);
  const {updateRecord} = useSelector((state)=>state.user);
  const {userRecords, userFetchedData, userFetchedDataOthers, accesspermitList, 
    userSearchedRecords} = useSelector((state)=>state.userSub);
  const online = loginForm.isOnline;
  const [searchRecord, setSearchRecord] = useState('');
  const [seltdIndiv, setSeltdIndiv] = React.useState({});
 // const [accMemberSeltd, setAccMemberSeltd] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [closeReport, setCloseReport] = useState(true);
  const [isConfirmed, setIsConfirmed] = React.useState(false);
  const [confirmDeleteMy, setConfirmDeleteMy] = React.useState({accessId:"", show:false});
  const [confirmDeleteOthers, setConfirmDeleteOthers] = React.useState({accessId:"", show:false, index:""});
  //const [currentStep, setCurrentStep] = useState();
  //const [accessdeniedList, setAccessdeniedList] = useState([]);
  const isNotmember = loginUser.user === 'None Member' || loginUser.user === 'GUEST';
  
  const componentRef = React.useRef();
  //console.log(userFetchedDataOthers);
  useEffect(()=>{
    if(online && isConfirmed){
      const {id, surname,} = seltdIndiv; // Selected member data (confirmed indv data)
      const accesspermitSletdIndv = seltdIndiv.id;
      const seltdIndvFamID = seltdIndiv.family;

      const userSurname = user.surname;
      const userLoginID = user.loginID;
      //Senerio: This is for confirmed searched individuals.
      const query =  getQueryLoginMemberByLoginID(userLoginID);
      fetchContents('QUERY', query).then((res)=>{
        const memberWithLoginIDFound = res.length>0;
        if(!memberWithLoginIDFound){
          //1. User loginID NOT found in Members data. (User not exist in Members data)
          //Set user loginID to searched member's data if loginID is not previously set to a member. (avoid duplicate loginID)
          if(surname === userSurname && searchRecord==='SEARCHFORME'){
            setLoginIDToMemberHandler(userLoginID,id);
          }   
         }else{
          //2. User loginID found in Members data.
          //Add searched member id to user's accesspermit
          const {id, accesspermit, family} = res[0]; // User member data
          const accesspermitLst = accesspermit?.split('|');
          const accesspermitOkay = !accesspermitLst?.includes(accesspermitSletdIndv) && seltdIndvFamID !== family;
          if(accesspermitOkay){
            const permitIds = accesspermit? `${accesspermit}|${accesspermitSletdIndv}` : `${accesspermitSletdIndv}`;
            setAccessPermitToMemberHandler(user.loginID,id, permitIds,);
          }
        }
      });
      fetchSelectedIndvForm(id);
    }
  },[isConfirmed, online, userOnline]);

  async function fetchSelectedIndvForm(id){
    await fetchMultipleFiles([id]).then((forms)=>{
      dispatch(disUserSearchedRecords(forms));
    });
  }

  function viewRecordsHandler(reportData){
      setReportData(reportData);
      setTimeout(()=>setCloseReport(false), 300);
  }
  function printReportCall(reportData){
    setReportData(reportData);
    printReportHandler();
  }
  const printReportHandler = useReactToPrint({
    pageStyle: pageStyle,
    content: () => componentRef.current,
  });

  function prepareReportHandler(type){
    const reportData = generateReportFunction(userRecords, 'family', 'family', 'famStat');
    if(type==="VIEW"){viewRecordsHandler(reportData)};
    if(type==="PRINT"){printReportCall(reportData)}
    if(type==="GENERATEPDF"){ //return reportData
      preparePdfReportHandler(reportData)
    }
  }

  function queryMemberHandler(userForm, act){
    const {userData, sortedFam, reportData} = userForm;
        if(act==='UPDATE' && user.loginID){
            /*dispatch(disUpdateRecord(
              {...updateRecord, startUpdate:true, user:userData.famStat, 
                recordToUpdateCount:sortedFam.length, recordToUpdate:sortedFam}));
            goToPage('RECORDVIEW');*/
            dispatchUpdateRecordHandler(userData.famStat, sortedFam);
         }
          if(act==='VIEW'){
            viewRecordsHandler(reportData)}
          if(act==="PRINT"){printReportCall(reportData)};
          if(act==="GENERATEPDF"){ //return reportData
            preparePdfReportHandler(reportData);
          };
  }


  const updateRecordHandler =(type)=>{
    if(type==="USER"){
      const reportData = generateReportFunction(userRecords, 'family', 'family', 'famStat');
      setReportData(reportData);
      if(user.loginID && userRecords.length>0){
        dispatchUpdateRecordHandler(userRecords[0].famStat, userRecords);
      }else{
        //console.log("Login to set Member's LoginID!")
      }
    }
  if(type==="SEARCH"){
    const userForm = userSearchedRecords[0];
    dispatchUpdateRecordHandler(userForm.userData.famStat, userForm.sortedFam);
  }
}
  function dispatchUpdateRecordHandler(famStat, recToUpdate){
    dispatch(disUpdateRecord(
      {...updateRecord, startUpdate:true, user:famStat, recordToUpdateCount:recToUpdate.length, 
        recordToUpdate:recToUpdate}))
      goToPage('RECORDVIEW');
  }
  function deleteRecordHandler(type){
    const userId = userFetchedData[0].id;    
      if(type==="USER"){
        dispatch(disUserFetchedData([]));
        dispatch(disUserRecords([])); 
        setConfirmDeleteMy({accessId:"", show:false});
        //console.log(userId)
        updateUserDeletedLoginID(userId);
        dispatch(disAccesspermitList([]));
        dispatch(disUserFetchedDataOthers([]));
        //console.log(userId)
      }
      if(type==="OTHERS"){
        const idToDelete = confirmDeleteOthers.accessId;
        setConfirmDeleteOthers({accessId:"", show:false, index:''});
        var accessdeniedListNew = [...accesspermitList].filter((item)=>{ 
          return item !== idToDelete;
        });
        dispatch(disAccesspermitList(accessdeniedListNew));
        const accesspermitsNew = accessdeniedListNew.join("|");
        //console.log(type,  confirmDeleteOthers.index, accesspermitList, accessdeniedListNew, accesspermits);
        updateUserDeletedAccess(userId, accesspermitsNew)
        //console.log(userId, accesspermitsNew)
      }
  }
  function preparePdfReportHandler(report){
      //console.log(report)
      dispatch(disPdfReportData(report));
      setReportData(report);
    }
  
  function goToPage(page){
    dispatch(disSeltdTab(tabsNav[page]));
  }
  useEffect(()=>{
    getNonRegUserMailBox();
  },[userOnline, online]);

  function getNonRegUserMailBox(){
    //Fetch mailinbox for non-registered/updated member. This will be replaced in useEffect below if member data is gotten
    fetchContents('NONEREGUSERINBOX', user).then(res=>{
      if(res.length>0){
        //console.log(res);
        const inboxArrNew = res.map((item)=>{ return {...item, message:item?.message?.split('\n') }});
        dispatch(disMyInbox(inboxArrNew));
      }
    }); 
  }
  
  //console.log(user);
  useEffect(()=>{
    //1. Search LoginUser by email || loginID, fecth and assign loginID to his/her Member loginID field
    if(online){fetchContents('INIT_SEARCH_ANDSET', user).then((res)=>{
      if(res.length>0){
        const {userData, familyData, inboxArr} = res[0];
        //console.log(res);
        const accessdeniedArr =  userData?.accessdenied?.split('|') || [];
        const accesspermitArr =  userData?.accesspermit?.split('|') || [];
        const accesspermitArrOkay = removeDeniedFromPermitAccess(accesspermitArr, accessdeniedArr)
        dispatch(disAccesspermitList(accesspermitArrOkay));
        if(!accessdeniedArr.includes('ALL')){ //ALL => All accessdenied1
          dispatch(disUserFetchedData([userData]));
          const sortedFam = sortFamilyData(familyData); 
          dispatch(disUserRecords(sortedFam));
        }
        if(!userData.loginID){ //Set loginID to just a single Member
          setLoginIDToMemberHandler(user.loginID, userData.id);
        }
        if(inboxArr.length>0){
          //console.log(inboxArr);
          const inboxArrNew = inboxArr.map((item)=>{ return {...item, message:item?.message?.split('\n') }});
          //console.log(inboxArr[1].message.replace(/\r\n/g, '\n'));
          dispatch(disMyInbox(inboxArrNew));
          //dispatch(disMyInboxObj({...myInboxObj, docID: inboxArr[0]._id}));
        }

        //Register loginUser
        const memberID = userData.id;
        const docID = 'login.members.list';
        client.fetch(membUsers).then((res)=>{
          const regUsersArr = res[0]?.usersList;
          const existingUser = regUsersArr?.find(item=>item._ref === memberID)? true :false;
          if(memberID && !existingUser){
            registerLoginMember(docID, memberID);
            //console.log('User registered');
          }
        });        
     }else{
      //console.log('User data not found')
      }
    })
  }
  if(online && userOnline){dispatch(disCurrentStep({step:1, about:"SEARCHRECORD", details:''}))}
  },[userOnline, online]);

  useEffect(()=>{
    //2. If the User login email corresponds with the Sanity Memebers email, then Replace loginID
      if(userRecords.length){
        fetchContents('QUERYBYEMAIL', user.email).then((res)=>{
          //console.log([user.email, res[0]?.loginID])
          if(res.length && user.email === res[0]?.email){
            replaceUserLoginID(res[0]._id, user.loginID);
          }
        })    
      }
  },[userRecords]);

  useEffect(()=>{
    //Fetch accesspermit members data
    if(online && accesspermitList.length>0){
      fetchMultipleFiles(accesspermitList).then((forms)=>{
        dispatch(disUserFetchedDataOthers(forms));
      });
    }
  },[online, accesspermitList]);


  const onlineMessage = online?
    (<p className='text-[maroon]'>Sorry, You don't have any saved record(s) yet! <br/>Follow the steps to update  or register your record. <br/>You don't want to follow the process? Then, contact the Admin to link your login email to your Records for fast access.</p>)
    :(<p className='text-[maroon]'>Please, login to view your records.</p>);

  return (
     <>{isNotmember?
        <AboutHomeDisplayNonMember
          user={user}/>
        :
        <AboutHomeDisplay
          user={user}
          userRecords={userRecords}
          userOnline={userOnline}
          online={online}
          onlineMessage={onlineMessage}
          confirmDeleteMy={confirmDeleteMy}
          reportData={reportData}
          prepareReportHandler={prepareReportHandler}
          setConfirmDeleteMy={setConfirmDeleteMy}
          deleteRecordHandler={deleteRecordHandler}
          userFetchedDataOthers={userFetchedDataOthers}
          confirmDeleteOthers={confirmDeleteOthers}
          queryMemberHandler={queryMemberHandler}
          setConfirmDeleteOthers={setConfirmDeleteOthers}
          closeReport={closeReport}
          setCloseReport={setCloseReport}
          setIsConfirmed={setIsConfirmed}
          searchRecord={searchRecord}
          seltdIndiv={seltdIndiv}
          setSearchRecord={setSearchRecord}
          userSearchedRecords={userSearchedRecords}
          updateRecordHandler={updateRecordHandler}
          componentRef={componentRef}
          setSeltdIndiv={setSeltdIndiv}
        />}
      </>
    )
  
}


export default AboutHome


const pageStyle = `
  @page {
    size: 210mm 297mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;


const membUsers = `*[ _type == "membersActivities" && _id == 'login.members.list']{
  usersList
}`;


/*return (
    <div className='p-3 m-2 rounded-sm bg-gradient-to-b from-blue-100 to-white'>
      <p className='font-bold text-[goldenrod] pb-3'>
          Welcome, {user.title} {user.surname}!
      </p>
      <p className='font-bold text-blue-600 pb-2'>
        {notes.about}
      </p>
      <div className={`${userOnline? '' : 'hidden'} bg-white p-3 mb-4`}>
        <p className='text-[magenta] pb-2 underline font-bold'>My Records</p>
        {userRecords.length >0 && online?
          <div>
          {
            userRecords?.map((item,i)=>{
              return (
                <p key={i+"keys"}
                  className="text-[mediumblue] font-bold mb-1">
                    {i+1}. {item.firstname} {item.surname}</p>
                );
            })
          }
          
        </div>
        :
        onlineMessage
        }
        <div className={`text-[red] ${confirmDeleteMy.show? "":'hidden'}`}>
          Your saved records, <SpanHLight underline col="magenta" border text="My Records"/> and <SpanHLight underline col="purple" border text="My Accessable Records."/> will be deleted! Continue?
          </div>
        <div className={`${userRecords.length>0 && online? '': 'hidden'}`}>
            <FormOperations 
              reportData={reportData}
              prepareReportDataCall={()=>prepareReportHandler('GENERATEPDF')}
              //prepareReportDataCall={()=>{return userRecords}}
              onClickView={()=>prepareReportHandler('VIEW')}
              onClickPrint ={()=>prepareReportHandler('PRINT')}
              onClickUpdate={()=>updateRecordHandler('USER')}
              showGeneratePdf={reportData.length>0}
              //onClickDeleteRecord={()=>deleteRecordHandler(userRecords, 'USER', 0)}
              onClickDeleteRecord={()=>setConfirmDeleteMy({accessId:userRecords[0].id, show:true})}
              deleteConf={confirmDeleteMy.show}
              onClickDeleteCancel={()=>setConfirmDeleteMy({accessId:"", show:false})}
              onClickDeleteProceed={()=>deleteRecordHandler('USER')}
              />
        </div>
        <HorizontalLine widths={100} margBot={15} margTop={10}/>
        <div className={`${userFetchedDataOthers?.length>0? "" : 'hidden'}`}>
        <p className='text-[purple] pb-2 underline font-bold'>My Accessable Records</p>
          {
            userFetchedDataOthers?.map((item,i)=>{

              return (
                <div className='flex flex-wrap flex-col mb-3' key={i+"keys"}>
                  <p
                    className="text-[teal] font-bold cursor-pointer"
                    //onClick={()=>queryMemberHandler(item)}
                    >
                      {i+1}. {item?.userData?.firstname} {item?.userData?.surname}
                  </p>
                  <div className={`text-[red] ml-2 ${confirmDeleteOthers.show && confirmDeleteOthers.index === i? "":'hidden'}`}>
                    Do you really want to delete <SpanHLight underline col="teal" text={item.userData.surname+"'s Record"}/> from your Saved records?</div>
                  <FormOperations 
                    reportData={reportData}
                    prepareReportDataCall={()=>queryMemberHandler(item, 'GENERATEPDF')}
                    //prepareReportDataCall={()=>{return userRecords}}
                    onClickView={()=>queryMemberHandler(item, 'VIEW')}
                    onClickPrint ={()=>queryMemberHandler(item, 'PRINT')}
                    onClickUpdate={()=>queryMemberHandler(item, 'UPDATE')}
                    showGeneratePdf={reportData.length>0}
                    //onClickDeleteRecord={()=>deleteRecordHandler(item.userData, 'OTHERS', i)}
                    pads="px-3 py-1" mags="my-1 mx-2"
                    forOthers
                    onClickDeleteRecord={()=>setConfirmDeleteOthers({accessId:item.userData.id, show:true, index:i})}
                    deleteConf={confirmDeleteOthers.show && confirmDeleteOthers.index===i}
                    onClickDeleteCancel={()=>setConfirmDeleteOthers({accessId:"", show:false, index:''})}
                    onClickDeleteProceed={()=>deleteRecordHandler('OTHERS')}
                    />
                </div>
                );
            })
          }
        </div>

        {!closeReport && 
          <IndexReport closeReport={()=>setCloseReport(true)} reportData={reportData}/>
        }
        <div style={{display:'none'}}>
            <ReportToPrint reportData={reportData} print={true} closeReport={true} 
              ref={componentRef} hidePrintBtn/>
        </div>
      </div>
      <p>{notes.steps}</p>
      <p className='pt-2'>{notes.info1}</p>
      <p>{notes.info2}</p>
      <HorizontalLine widths={100} margBot={20}/>
      {userOnline? 
        <SearchRecordsManager
          //setMemberLoginID={form=>setMemberLoginIDHandler()}
          setIsConfirmed={el=>setIsConfirmed(el)}
          searchRecord ={searchRecord}
          setSearchRecord={setSearchRecord}
          seltdIndiv={seltdIndiv}
          setSeltdIndiv={setSeltdIndiv}
          updateRecordCall={()=>updateRecordHandler('SEARCH')}
          showUpdateRecBtn={userSearchedRecords.length>0}
          //searchRecordHandler={searchRecordHandler}
        /> :
        <div className='text-red-700'>
          <BsWifiOff size={30} color="red" className='mr-3 inline-block'/>
          Connection error!. Please, check your network.
        </div>
      }
    </div>
  )*/
