import React from 'react';
import GeneratePdf from '../../../report/generatePdf/GeneratePdf';


const FormOperations = ({onClickView, onClickUpdate, onClickPrint, prepareReportDataCall, 
  reportData, pads, mags, showGeneratePdf, onClickDeleteRecord, forOthers, deleteConf,
  onClickDeleteCancel, onClickDeleteProceed}) => {
  return (
    <div className={`w-fit flex flex-row flex-wrap`}>
        <p className={`bg-blue-800 rounded-md cursor-pointer active:bg-[silver] hover:bg-blue-500  text-white ${pads? pads : 'px-5 py-2'} ${mags? mags : 'my-2 mr-4'}`}
        onClick={onClickView}>View</p>
        <p className={`bg-[#24817c] rounded-md cursor-pointer active:bg-[silver] hover:bg-[#3cb0aa]  text-white  ${pads? pads : 'px-5 py-2'} ${mags? mags : 'my-2 mr-4'}`}
        onClick={onClickUpdate}>Update</p>
        <p className={`bg-[#7e117a] rounded-md  cursor-pointer active:bg-[silver] hover:bg-[#ce1dc8]   text-white ${pads? pads : 'px-5 py-2'} ${mags? mags : 'my-2 mr-4'}`}
        onClick={onClickPrint}>Print</p>
        <div className={`${showGeneratePdf? '' : 'hidden'}`}>
          <GeneratePdf 
          reportData={reportData}
          prepareReportDataCall={prepareReportDataCall}
          btnStyle={`bg-[coral] rounded-md cursor-pointer active:bg-[silver] hover:bg-[#eeb531]   text-white ${pads? pads : 'px-5 py-2'} ${mags? mags : 'my-2 mr-4'}`}
          btnName="Generate Pdf"/>
        </div>
        <p className={`bg-[red] rounded-md cursor-pointer active:bg-[silver] hover:bg-[maroon]  text-white 
           ${pads? pads : 'px-5 py-2'} ${mags? mags : 'my-2 mr-4'} ${deleteConf? 'hidden' : ''}`} 
        onClick={onClickDeleteRecord}>Delete Record</p>
        <div className={`flex flex-row bg-red-300 rounded-md pl-2 ${deleteConf? '' : 'hidden'}`}>
          <p className={`bg-[teal] rounded-md  cursor-pointer active:bg-[gray] hover:bg-[gray]   
              text-white px-3 py-2 my-2 mr-4 ${forOthers? "py-1 my-1" : ""}`}
          onClick={onClickDeleteCancel}>Cancel</p>
          <p className={`bg-[maroon] rounded-md  cursor-pointer active:bg-[silver] hover:bg-[red]   
              text-white px-3 py-2 my-2 mr-4 ${forOthers? "py-1 my-1" : ""}`}
          onClick={onClickDeleteProceed}>Delete</p>
        </div>
    </div>
  )
}


export default FormOperations;
