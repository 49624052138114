import React from "react";
import { BsToggle2Off,BsToggle2On, } from "react-icons/bs";

const InputText = ({labelStyle,contStyle, inputStyle, name, type, placeholder, onChangeHandler,
        title, value, onClickTitle})=>{


    return(
        <div className={`${contStyle} flex flex-row flex-wrap border border-white w-fit pl-2 mb-2 pr-1`}>
            <p className={`${labelStyle} pr-2`}
              onClick={onClickTitle}>
                {title}
            </p>
            <input
                className={`${inputStyle}`}
                placeholder={placeholder}
                name={name}
                value={value}
                type={type}
                onChange={onChangeHandler}
            />
        </div>

        );
}

const SelectionTag = ({setSelected, title, opt1, opt2}) => {
    const onOptionChangeHandler = (event) => {
        const value = event.target.value;
        const key = title;
        setSelected({key, value});
    }

    return (
        <div className="my-3 ml-2 border border-white w-fit pl-2 mb-2">
            <div className="flex flex-wrap flex-row">
                <p className="font-bold">{title}</p>
                <select onChange={onOptionChangeHandler} 
                    className="border border-solid text-black border-blue-500 py-1 ml-2 min-w-[100px]">
                    <option>{opt1===true? 'true' : opt1===false? 'false' : opt1}</option>
                    <option>{opt2===true? 'true' : opt2===false? 'false' : opt2}</option>
                </select>
            </div>
        </div>
    );
};

const ToggleButton=({setToggleVal, toggleVal, contStyle, labelStyle, title, titleSub, labelSubStyle})=>{
  

    return(
        <div className={`${contStyle} flex flex-row, items-center w-fit`}>
            <label className={`${labelStyle}`}>
                {title}
                <span className={`${labelSubStyle} font-bold pl-1`}>{titleSub}</span>
            </label>
            <div onClick={()=>setToggleVal()} 
                className="items-center">
                {toggleVal? <BsToggle2On color="mediumblue" size={32}/> :<BsToggle2Off size={32} color="silver"/>}
            </div>
        </div>
    );
}


const Button =({title,contStyle, onClickHandler,})=>{

    return(
        <div className={`cursor-pointer p-2 px-5 rounded-sm 
            ${contStyle? contStyle : 'text-gray-800 active:bg-stone-400 w-fit bg-white hover:bg-[#dadaff]'}
           `}
         onClick={onClickHandler}>
            {title}
        </div>
    );
}
export {InputText, ToggleButton, Button, SelectionTag}