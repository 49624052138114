import React from 'react';
//import TableTemplate from '../../controlPanel/membersQuery/components/TableTemplate';
import FileUploaderCustomed from  './FileuploadCustomized';

const UploadExcel = ({forwardUploadedData}) => {
    //const [uploadData, setUploadedData] = React.useState([]);

    /*React.useEffect(()=>{
      if(uploadData.length){forwardUploadedData(uploadData);}
    },[uploadData]);*/

   return (
    <div className='flex flex-row items-center'>
        <p className='font-bold mr-2'>Upload Excel</p>
        <div className='bg-blue-800 text-white w-fit py-1 px-3 cursor-pointer'>
            <FileUploaderCustomed
                setUploadedData={forwardUploadedData}
            />
        </div>
    </div>
  )
}

export default UploadExcel;

