import React from 'react';
import { Main, Spouse, Child } from './Components'
import logo from '../../../assets/gofamintLogo.png';
import { useSelector } from 'react-redux';

const ReportTemplate = (props, ref) => {
    const {pdfReportData} = useSelector((state)=>state.userSub);
    //console.log(pdfReportData)
    return (
        <div
         style={{...styles.container, zIndex:props.hideNo ||0}}
         >
            <div
             style={styles.headerCont}>
                <div>
                <img src={logo} alt="Gofamint-logo" style={styles.logo}/>
                </div>
                <p style={{...styles.header, ...styles.headerSub1}}>The Gospel Faith Mission International</p>
                <p style={{...styles.header,...styles.headerSub2, color:'maroon'}}>Pacesetters Assembly, Ogba District Headquarters</p>
                <p style={{...styles.header,...styles.headerSub2,}}>7 Odekunle Street, Off Oluyemi Erinoso Street,</p>
                <p style={{...styles.header,...styles.headerSub2,}}>Behind County Hospital, Aguda, Ogba,</p>
                <p style={{...styles.header,...styles.headerSub2,}}>Ikeja, Lagos</p>
                <p style={{...styles.header,...styles.headerSub2,}}>Membership Forms</p>
            </div>
            <>
                {
                    pdfReportData?.map((fam, i)=>{
                        //var parent = [], children = [];
                        const user = fam?.parents[0], spouse = fam?.parents[1]? fam?.parents[1] : "";
                        const children = fam?.children;
                        const childComp = fam?.children?.length? true : false;

                        return (
                            <div key={`${i}+par`}
                                style={styles.contentCont}>
                                <Main form={user} print={props.print}/>
                                <div className="reactprt-nxtpage-start" />
                                <Spouse form={spouse} childComp ={childComp} print={props.print}/>
                                <div className="reactprt-nxtpage-start" />
                                {
                                    children?.map((form, j)=>{
                                        return( 
                                        <div key={`${j}+par`}>
                                            <Child form={form} index={j+1} print={props.print}/>
                                            <div className="reactprt-nxtpage-start" />
                                        </div>
                                        )
                                    })
                                }
                            </div>
                            );
                    })   
                }
            </>
        </div>
  )
};


export default ReportTemplate;

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden",
        width: "446px",
        //height: "631px",
        backgroundColor:'#eee', 
        overflowY:'auto', 
      },
    headerCont:{
        display:'flex',flexDirection:'column', 
        justifyContent:'center', padding:'15px', paddingTop:'15px',
        position:'relative',
        backgroundColor:'#e0e0fe',
        border:'2px solid blue',
        borderBottomColor:'transparent'
        //background:"linear-gradient(skyblue, white)"
    },
    logo:{width:'60px', height:'60px', position:'absolute', left:'5px', },
    header:{textAlign:'center', fontWeight:'bold'},
    headerSub1:{color:'blue', fontSize:'16px'},
    headerSub2:{fontSize:'13px'},
    contentCont:{border:'2px solid blue', backgroundColor:'#eee', padding:'15px'},
}