import React from 'react'
import HorizontalLine from '../../HorizontalLine';
import SearchRecordsManager from './components/SearchRecordsManager';
import IndexReport from '../../report/Index';
import ReportToPrint from '../../report/Report';
import { BsWifiOff } from 'react-icons/bs';
import FormOperations from './components/FormOperations';
import { SpanHLight } from './components/TextSpans';
import Collapsible from '../../Collapsible';
import CustomLoader from '../../userpage/components/CustomLoader';

const AboutHomeDisplay = ({user, userRecords, userOnline, online, onlineMessage, 
    confirmDeleteMy, reportData, prepareReportHandler, setConfirmDeleteMy, 
    deleteRecordHandler, userFetchedDataOthers, confirmDeleteOthers, queryMemberHandler,
    setConfirmDeleteOthers, closeReport, setCloseReport, setIsConfirmed,
    searchRecord, seltdIndiv, setSearchRecord, userSearchedRecords, updateRecordHandler,
    componentRef, setSeltdIndiv}) => {
   //const userRecordss = [];
    const [toDisplay, setToDisplay] = React.useState(true);

    const onlineMessageDisplay = toDisplay? (<CustomLoader loadingMsg="Checking for your saved data, please wait!" textCol='blue' color="blue" 
    loading={true} size={30}/>): onlineMessage;
    
    React.useEffect(()=>{
      if(userRecords.length>0){setToDisplay(true)};
      function setDisplay(){
        setToDisplay(false); 
      }
      setTimeout(setDisplay, 5000);
    },[]);

  return (
    <div className='p-3 m-2 rounded-sm bg-gradient-to-b from-blue-100 to-white'>
      <p className='font-bold text-[goldenrod] pb-3'>
          Welcome, {user.title} {user.surname}!
      </p>
      <p className='font-bold text-blue-600 pb-2'>
        {notes.about}
      </p>
      <div className={`${userOnline? '' : 'hidden'} bg-white p-3 mb-4`}>
            <p className='text-[magenta] pb-2 underline font-bold'>My Records</p>
            {userRecords.length >0 && online?
            <div>
            {
                userRecords?.map((item,i)=>{
                return (
                    <p key={i+"keys"}
                    className="text-[mediumblue] font-bold mb-1">
                        {i+1}. {item.firstname} {item.surname}</p>
                    );
                })
            }
            
            </div>
            :
            onlineMessageDisplay
            }
            <div className={`text-[red] ${confirmDeleteMy.show? "":'hidden'}`}>
            Your saved records, <SpanHLight underline col="magenta" border text="My Records"/> and <SpanHLight underline col="purple" border text="My Accessable Records."/> will be deleted! Continue?
            </div>
            <div className={`${userRecords.length>0 && online? '': 'hidden'}`}>
                <FormOperations 
                reportData={reportData}
                prepareReportDataCall={()=>prepareReportHandler('GENERATEPDF')}
                //prepareReportDataCall={()=>{return userRecords}}
                onClickView={()=>prepareReportHandler('VIEW')}
                onClickPrint ={()=>prepareReportHandler('PRINT')}
                onClickUpdate={()=>updateRecordHandler('USER')}
                showGeneratePdf={reportData.length>0}
                //onClickDeleteRecord={()=>deleteRecordHandler(userRecords, 'USER', 0)}
                onClickDeleteRecord={()=>setConfirmDeleteMy({accessId:userRecords[0].id, show:true})}
                deleteConf={confirmDeleteMy.show}
                onClickDeleteCancel={()=>setConfirmDeleteMy({accessId:"", show:false})}
                onClickDeleteProceed={()=>deleteRecordHandler('USER')}
                />
            </div>
        </div>
      <Collapsible
            contStyle="mb-3 mx-1 border-2 border-solid border-teal-700"
            titleStyle={` bg-cyan-600 text-white pb-2 underline font-bold`}
            iconCol="fill-white"
            iconHoverCol="hover:fill-[yellow]"
            hideDeleteIcon
            //collapse={collapse}
            addedInfo={`My Accessable Records- ${userFetchedDataOthers.length}`}
            iconType="plus"
            >
        <div className={`${userOnline? '' : 'hidden'} bg-white p-3 mb-4`}>
            <div className={`${userFetchedDataOthers?.length>0? "" : 'hidden'}`}>
            <p className=''></p>
            {
                userFetchedDataOthers?.map((item,i)=>{

                return (
                    <div className='flex flex-wrap flex-col mb-3' key={i+"keys"}>
                    <p
                        className="text-[teal] font-bold cursor-pointer"
                        //onClick={()=>queryMemberHandler(item)}
                        >
                        {i+1}. {item?.userData?.firstname} {item?.userData?.surname}
                    </p>
                    <div className={`text-[red] ml-2 ${confirmDeleteOthers.show && confirmDeleteOthers.index === i? "":'hidden'}`}>
                        Do you really want to delete <SpanHLight underline col="teal" text={item.userData.surname+"'s Record"}/> from your Saved records?</div>
                    <FormOperations 
                        reportData={reportData}
                        prepareReportDataCall={()=>queryMemberHandler(item, 'GENERATEPDF')}
                        //prepareReportDataCall={()=>{return userRecords}}
                        onClickView={()=>queryMemberHandler(item, 'VIEW')}
                        onClickPrint ={()=>queryMemberHandler(item, 'PRINT')}
                        onClickUpdate={()=>queryMemberHandler(item, 'UPDATE')}
                        showGeneratePdf={reportData.length>0}
                        //onClickDeleteRecord={()=>deleteRecordHandler(item.userData, 'OTHERS', i)}
                        pads="px-3 py-1" mags="my-1 mx-2"
                        forOthers
                        onClickDeleteRecord={()=>setConfirmDeleteOthers({accessId:item.userData.id, show:true, index:i})}
                        deleteConf={confirmDeleteOthers.show && confirmDeleteOthers.index===i}
                        onClickDeleteCancel={()=>setConfirmDeleteOthers({accessId:"", show:false, index:''})}
                        onClickDeleteProceed={()=>deleteRecordHandler('OTHERS')}
                        />
                    </div>
                    );
                })
            }
            </div> 

            {!closeReport && 
            <IndexReport closeReport={()=>setCloseReport(true)} reportData={reportData}/>
            }
            <div style={{display:'none'}}>
                <ReportToPrint reportData={reportData} print={true} closeReport={true} 
                ref={componentRef} hidePrintBtn/>
            </div>
        </div>
      </Collapsible>

      <p>{notes.steps}</p>
      <p className='pt-2'>{notes.info1}</p>
      <p>{notes.info2}</p>
      <HorizontalLine widths={100} margBot={20}/>
      {userOnline? 
        <SearchRecordsManager
          //setMemberLoginID={form=>setMemberLoginIDHandler()}
          setIsConfirmed={el=>setIsConfirmed(el)}
          searchRecord ={searchRecord}
          setSearchRecord={setSearchRecord}
          seltdIndiv={seltdIndiv}
          setSeltdIndiv={setSeltdIndiv}
          updateRecordCall={()=>updateRecordHandler('SEARCH')}
          showUpdateRecBtn={userSearchedRecords.length>0}
          //searchRecordHandler={searchRecordHandler}
        /> :
        <div className='text-red-700'>
          <BsWifiOff size={30} color="red" className='mr-3 inline-block'/>
          Connection error!. Please, check your network.
        </div>
      }
    </div>

  )
}


export default AboutHomeDisplay


const notes = {
  about:"You can Search for, View, Update or Add a new record/records for you or another member on this your page.",
  steps:"Follow the following steps if you want to update your record or do a fresh registration. The same process applies if you are doing it for someone else.",
  info1:"Use your personal details to search for your record or use either your Dad's or Mum's details to search if you want to update your family record. If helping a member to register, use his/her information to search.",
  info2:"As a parent, you will be able to update your family's record.",
};


const pageStyle = `
  @page {
    size: 210mm 297mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;


const membUsers = `*[ _type == "membersActivities" && _id == 'login.members.list']{
  usersList
}`;