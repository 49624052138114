


function getBirthDay(dob){
    //dob => YYYY-MM-DD
    const dobArr = dob.split("-");
    const birthDay = `${dobArr[2]}-${birthMn[dobArr[1]-1]}`;
    return dobArr.length === 3? birthDay : "";
}


const birthMn = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep","Oct","Nov","Dec"];
//const birthDay=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];

export default getBirthDay