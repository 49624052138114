import React from 'react';
import bgVideo from '../../assets/videos/PassionOfChristTrillerConverted.mp4';
//import useRefDimensions from '../../utils/useRefDimension';
import useElementDimension from '../../utils/useElementDimension';
import RandomBibleVerses from './BibleVerseDisplay';
import { bibleRefsArr } from './data';
import useWindowDimensions from '../../utils/useWindowDimension';


const VideoPlayerAutoRaw = () => {
    const contRef = React.useRef();
    const dimensions = useElementDimension(contRef);
    let { width } = useWindowDimensions();
    const [fontSize, setFontSize] = React.useState(12);

    React.useEffect(()=>{
      if(dimensions.checked){
        //Use container size to determine fontsize. More accurate
        const fontSize = Math.round(0.043*dimensions.width);
        setFontSize(fontSize);
      }else{
        //Use screen size to determine fontsize
        const fontSize = getFontSize(width);
        setFontSize(fontSize);
      }
    },[dimensions.checked, width, dimensions.width]);

    
  return (
          <div className='w-full bg-center bg-cover h-fit max-h-[600px]
            flex justify-center items-center relative
            border-4 border-solid border-rose-500'
           >
                
                <video width="320" height="240" 
                  //controls={true} 
                  autoPlay loop muted
                  className="w-full h-fit object-cover "
                >
                  <source src={bgVideo} type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              <div className='bg-sky-500/40 w-full h-full absolute
                   flex hover:bg-slate-100/40 group p-3 text-white hover:text-
                   items-center justify-center text-center
                   hover:text-[#74efed]'
                   style={{fontSize: `${fontSize}px`}}
                   ref={contRef}>
                    <RandomBibleVerses bibleRefs={bibleRefsArr}  len={bibleRefsArr.length-1}/>
              </div>  
          </div>
  )
}

export default VideoPlayerAutoRaw;

function getFontSize(screenWidth) {
  let fontSize = 0;    

  if(screenWidth >0 && screenWidth <= 300){
      fontSize = 12;
  }else if(screenWidth >300 && screenWidth <= 400){
      fontSize = 14
  }else if(screenWidth >400 && screenWidth <= 700){
      fontSize = 18
  }else if(screenWidth >700 && screenWidth <= 1023){
      fontSize = 24
  }else if(screenWidth >1023 && screenWidth <= 1200){
      fontSize = 20
  }else if(screenWidth >1200 && screenWidth <= 1500){
      fontSize = 24
  }else{
      fontSize = 24
  }

 return fontSize
}


//const aa =     'and will forgive their sin, and will heal their land. II Chronicles 7:14';