import React from 'react';
import { NavLink, } from 'react-router-dom';
import {IoMdHome, IoMdPhotos, IoMdInformationCircleOutline,} from 'react-icons/io';
import {FaBookReader, FaRegAddressCard, FaUserAlt} from 'react-icons/fa';


const NavsLink = ({ handleCloseSidebar, isActiveStyle, isNotActiveStyle,
  linkTo, name, index }) => {
  const navIcon = [
  <IoMdHome className='md:hidden' size={32}/>,
  <IoMdInformationCircleOutline className='md:hidden' size={32}/>,
  <FaBookReader className='md:hidden' size={32}/>,
  <IoMdPhotos className='md:hidden' size={32}/>,
  <FaRegAddressCard className='md:hidden' size={32}/>,
  <FaUserAlt className='md:hidden' size={32}/>,];

  return (
        <div className="flex flex-col gap-5 px-1 pb-5 lg:px-2 xl:px-5 xl:text-xl">
          <NavLink
            to={linkTo}
            end={name==="Home"? true : ""}
            className={({isActive}) => (isActive ? isActiveStyle : isNotActiveStyle)}
            onClick={handleCloseSidebar}
          >
            {navIcon[index]}
            {name}
          </NavLink>
        </div>
  );
};

export default NavsLink;
