import React from "react";
import { IndexAccountDocs } from "../../admin/account/Index";
import { useSelector } from "react-redux";



const IndexGuest =()=>{
    const [bgColor, setBgColor] = React.useState(false);
    const {showAllTableFlds, memberTableViewAll, adminUser} = useSelector((state)=>state.dev);
    

    return(
        <div className="p-3"
            >
           <IndexAccountDocs/>
        </div>
    );
}

export default IndexGuest