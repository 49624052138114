import React from 'react'
//import UploadExcel from './components/UploadExcel';
//import DownloadExcel from './components/DownloadExcel';
import MembersDataSection from './sections/membersData/MembersDataIndex';
//import PstCornerSection from './sections/pCorner/PstCorner';


const Index = () => {
 // const [uploadedData, setUploadedData]=React.useState([]);
  

//<div className='hidden'><UploadExcel forwardUploadedData={setUploadedData}/></div>
  return (
    <div className='p-5'>
      <MembersDataSection/>
    </div>
  )

}


export default Index;
