const navName = [
    'Home',
    'About',
    "Pastor's Corner",
    'Media',
    'Contact Us',
    'User'
];

const pathNames = [
    '/',
    'about',
    "pastorcorner",
    'media',
    'contactus',
    'user'
];


function getPathName(path){

   const res = path===`${pathNames[0]}`? navName[0] :
            path===`/${pathNames[1]}`? navName[1] :
            path===`/${pathNames[2]}`? navName[2] :
            path===`/${pathNames[3]}`? navName[3] :
            path===`/${pathNames[4]}`? navName[4] : 
            path===`/${pathNames[5]}`? navName[5] :
            path.includes('/pastorCorner/')? "Pastor's Corner": "Gofamint PS";

    return res
}
export { navName, pathNames, getPathName}