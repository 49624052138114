import React, { useState, useEffect } from 'react'
import {BsSortDown, BsFileEarmarkExcel, BsTable, BsArrowLeft, BsArrowRight} from 'react-icons/bs';
import {MdViewQuilt, MdFilterListAlt} from 'react-icons/md';
import UploadDownload from '../../../../fileupload/Fileupload';
import { getTodayMonth } from '../../../../../utils/miscens';
import ExportToExcel from '../components/ExportToExcel';
import {TbReport} from 'react-icons/tb';
import { useSelector } from 'react-redux';
//import { getMembershipArr } from '../../../assets/textFieldsMain';
//console.log(getMembershipArr());


const TableButtons = ({toggleFreeze,formFlds,showAllFieldshandler,printReport, 
    generateReport, setFilter, setToggleFreeze, excludeHeaders, setExcludeHeaders,excludedHeadersArr,
    setUploadedData, setSortBy, tableData, showAllFields, handleShowAllFields,
    filterTypeSelect, setFilterTypeSelect}) => {
  const [mnth, setMnth] = useState([]);
  const [checked, setChecked] = useState(false);
  //const [filterTypeSelect, setFilterTypeSelect] = React.useState('filternone');
  const [types, setTypes] = React.useState(getTypes());
 
  const {showAllTableFlds} = useSelector((state)=>state.dev);
  const [checkedRadio, setCheckedRadio] = useState('excel');
  const contRef = React.useRef(null);
  const elRef = React.useRef(null);

  function filterHandler(el){
    const typesAll = {...types, '23':{name: 'Birthday Celebrants', fld:'dob', val:mnth[0], familySort:false},
       '24':{name: 'Wedding Anniversary', fld:'wedAnn', val:mnth[0], familySort:false}}
    const type = typesAll[el];
    const res = {fld:type.fld, val:type.val, name:type.name, familySort:type.familySort};
    setFilter(res);
  }
  
  function filterBirthdayHandler(el){
    const type = birthdayTypes[el];
    const res = {fld:filterTypeSelect==="general"? 0 : filterTypeSelect, val:type.val, name:type.name, familySort:type.familySort};
    setFilter(res);
  }

  function filterTypeHandler(el){
    //if(el==='general'){setTypes(getTypes())}
    setFilter({name: 'Filter none', fld:0, val:0, familySort:false})
    setFilterTypeSelect(el);
  }

  function scroll(el){
    if(el==='FIRSTEL'){
      contRef.current.scrollTo({left: 0,  behavior: 'smooth' });
    }else{
      elRef.current.scrollIntoView({behavior: 'smooth' });
    }
  }
  function handleChecked(){
    setChecked(!checked)
  }
  
  useEffect(() => {
    const mnth = getTodayMonth();
    setMnth(mnth);
  }, []);
  
  return (
    <div className='flex justify-center '>
        <div className='w-[95vw] sm:w-[88vw] h-8 border-2 border-solid border-gray-800
            flex justify-between text-white'>
            <div className='bg-gray-800 h-full px-1 flex justify-center items-center cursor-pointer'
              onClick={()=>scroll('FIRSTEL')}>
              <BsArrowLeft size={20} color="white"/>
            </div>
            <div className='border-2 bg-gray-800
              flex items-center text-white overflow-x-scroll hide-scrollbar'
              ref={contRef}
            >
                <div className='flex pl-[2px] text-sm items-center bg-gray-800 outline 
                  outline-blue-700 outline-2'>
                    <BsSortDown size={22} color="white"/>
                    <select onChange={e=>setSortBy(e.target.value.toLocaleLowerCase())}
                      className="outline-none cursor-pointer text-[blue]">
                        <option>Sort by</option>
                        <option>Surname</option>
                        <option>Firstname</option>
                        <option>BirthDay</option>
                        <option>Family</option>
                        <option>Membership</option>
                    </select>
                </div>
                <div className={`flex ${toggleFreeze? 'px-[2px]' : 'px-[8px]'} text-sm items-center bg-gray-800 outline 
                  outline-blue-700 outline-2 mx-[7px] py-[1px] cursor-pointer active:outline-cyan-300 active:bg-white
                  hover:bg-gray-300 hover:text-black whitespace-nowrap`}
                  onClick={()=>setToggleFreeze(!toggleFreeze)}>
                    <MdViewQuilt size={22} color="white"/>
                    {toggleFreeze? 'Unfreeze Names' : 'Freeze Names'}  
                </div>
                
                <div className='flex pl-[2px] text-sm items-center bg-gray-800 outline 
                  outline-blue-700 outline-2'>
                      <MdFilterListAlt size={22} color="white"/>
                      <select  className="outline-none cursor-pointer text-[green] font-bold mr-3"
                        onChange={e=>filterTypeHandler(e.target.selectedOptions[0].getAttribute('val'))}>
                        <option val="general">General</option>
                        <option val="dob">BirthDay</option>
                        <option val="wedAnn">Wedding Ann</option>
                      </select>
                    <select
                      className={`outline-none cursor-pointer text-[blue] ${filterTypeSelect ==='general'? '' :'hidden'}`}
                      onChange={e=>filterHandler(e.target.selectedOptions[0].getAttribute('val'))}>
                        {
                          Object.keys(types).map((item, i)=>{

                            return(
                              <option val={item} key={i+"-"}>{types[item].name}</option>      
                            );
                          })
                        }
                        <option val={'23'}>{`${mnth[1]?.toString()?.substring(0,3)}.BirthDay`}</option>
                        <option val={'24'} className="pb-10">{`${mnth[1]?.toString()?.substring(0,3)}.WedAnn`}</option>
                    </select>
                </div>
                <div className={`${filterTypeSelect !== "general"? 'flex' : 'hidden'} pl-[2px] text-sm items-center outline bg-blue-900
                  outline-blue-700 outline-2`}>
                    <select
                      className="outline-none cursor-pointer text-[blue]"
                      onChange={e=>filterBirthdayHandler(e.target.selectedOptions[0].getAttribute('val'))}>
                        {
                          Object.keys(birthdayTypes).map((item, i)=>{

                            return(
                              <option val={item} key={i+"-"}>{birthdayTypes[item].name}</option>      
                            );
                          })
                        }
                    </select>
                </div>
                <div className={`flex px-[3px] text-sm items-center bg-blue-900 outline 
                  outline-blue-700 outline-2 mx-[5px] ml-[10px] py-[1px] cursor-pointer active:outline-cyan-300 active:bg-white
                  hover:bg-gray-300 hover:text-black whitespace-nowrap`}
                  onClick={generateReport}>
                  <TbReport size={20} color="white"/>
                  Generate Report
                </div>
                <div className={`flex px-[3px] text-sm items-center bg-green-900 outline 
                  outline-blue-700 outline-2 mx-[5px] ml-[10px] py-[1px] cursor-pointer active:outline-cyan-300 active:bg-white
                  hover:bg-gray-300 hover:text-black whitespace-nowrap`}
                  onClick={printReport}>
                  <TbReport size={20} color="white"/>
                  Print Report
                </div>
                
                <div className={`px-[3px] text-sm items-center bg-[maroon] outline 
                  outline-blue-700 outline-2 mx-[5px] ml-[10px] py-[1px] cursor-pointer active:outline-cyan-300 active:bg-white
                  hover:bg-gray-300 hover:text-black whitespace-nowrap
                   ${showAllTableFlds? 'flex' :'hidden'}`}
                  onClick={showAllFieldshandler}>
                  <BsTable size={20} color="white"/>
                  Show all Fields
                  <input type="checkbox" className='w-10 h-4'
                    checked={showAllFields}
                    name="showAllFields"
                    onChange={handleShowAllFields}/>
                </div>
                <div className={`flex px-[3px] text-sm items-center bg-[maroon] outline 
                  outline-blue-700 outline-2 mx-[2px] mr-2 py-[1px] cursor-pointer active:outline-cyan-300 active:bg-white
                  hover:bg-gray-300 whitespace-nowrap`}>
                  <BsFileEarmarkExcel size={20} color="white"/>
                  <ExportToExcel allFields={checked} title="Export- " data={tableData} 
                    formFlds={formFlds} format={checkedRadio}
                    excludeHeaders={excludeHeaders}
                    excludedHeadersArr={excludedHeadersArr}/>
                  <div className='px-3 bg-[#300000]'>
                    <label className='pr-1'>Excel</label><input type="radio" value="excel" checked={checkedRadio==="excel"} onChange={()=>setCheckedRadio('excel')}/>
                    <label className='pr-1 pl-2'>Text</label><input type="radio" value="text" checked={checkedRadio==="text"} onChange={()=>setCheckedRadio('text')}/>
                    <label className='pr-1 pl-2 text-sm'>Exclude</label>
                      <input className='' type="checkbox" 
                        checked={excludeHeaders}
                        onChange={()=>setExcludeHeaders(!excludeHeaders)}
                      />
                  </div>
                  <div className=' ml-1 bg-[#300000]'>
                    <span className='ml-2 -mr-[8px]'>All fileds</span>
                    <input type="checkbox" className='w-10 h-4'
                      checked={checked}
                      name="exportAllFields"
                      onChange={handleChecked}/>
                  </div>
                </div>
                <UploadDownload btnStyle={`hidden px-[3px] text-sm items-center bg-[maroon] outline 
                  outline-blue-700 outline-2 mx-[7px] py-[1px] cursor-pointer active:outline-cyan-300 active:bg-white whitespace-nowrap`}
                  setUploadedData={setUploadedData}
                  />
                <div ref={elRef}
                  className='w-2 h-full'></div>
              </div>
              <div className='bg-gray-800 h-full px-1 flex justify-center items-center cursor-pointer'
                onClick={()=>scroll('LASTEL')}
                >
                <BsArrowRight size={20} color="white"/>
              </div>
        </div>
    </div>
  )
}

export default TableButtons;

const types = {'0':{name: 'Filter none', fld:0, val:0, familySort:false}, 
    '1':{name: 'Ministers', fld:'memberStat', val:'Minister', familySort:false}, 
    '2':{name: 'Workers', fld:'memberStat', val:'Worker',familySort:false}, 
    '3':{name: 'Members', fld:'memberStat', val:'Member', familySort:true},
    //'4':{name: 'None members', fld:'memberStat', val:'Non member'}, 
    '4':{name: 'Baptised members', fld:'batStat', val:'Baptised', familySort:false},
    '5':{name: 'None baptised members', fld:'batStat', val:'Not baptised', familySort:false}, 
    '6':{name: 'Married members', fld:'matStat', val:'Married', familySort:false},
    '7':{name: 'Singles', fld:'matStat', val:'Single', familySort:false}, 
    '8':{name: 'Brothers', fld:'title', val:'Brother', familySort:false},
    '9':{name: 'Sisters', fld:'title', val:'Sister', familySort:false}, 
    '10':{name: 'Males', fld:'genderStat', val:'Male', familySort:false},
    '11':{name: 'Females', fld:'genderStat', val:'Female', familySort:false}, 
    
    
    '12':{name: 'Existing members', fld:'membership', val:'Existing member', familySort:true},
    '13':{name: 'None-existing members', fld:'membership', val:'None-existing member', familySort:true},
    '14':{name: 'Transferred members', fld:'membership', val:'Transferred', familySort:true},
    '15':{name: 'Relocated members', fld:'membership', val:'Relocated', familySort:true},
    '16':{name: 'None-members', fld:'membership', val:'None-member', familySort:true},
    '17':{name: 'Members gone to glory', fld:'membership', val:'Late', familySort:false},

    '18':{name: 'Babies (0-2yrs)', fld:'ageGroup', val:'Baby (0-2yrs)'},
    '19':{name: 'Children (3-16yrs)', fld:'ageGroup', val:'Child (3-16yrs)'},
    '20':{name: 'Young Adults (17-30yrs)', fld:'ageGroup', val:'Young Adult (17-30yrs)'},
    '21':{name: 'Middle-aged Adults (31-45yrs)', fld:'ageGroup', val:'Middle-aged Adult (31-45yrs)'},
    '22':{name: 'Old Adults (above 45yrs)', fld:'ageGroup', val:'Old Adult (above 45yrs)'},
};

const months = ["Def", "January","February","March","April","May","June","July","August","September","October","November","December"];


function getTypes(){
  return types
}
const birthdayTypes = {
    '0':{name: 'Filter none', fld:0, val:0, familySort:false},
    '1':{name: 'January Celebrants', fld:'dob', val:1, familySort:false}, 
    '2':{name: 'February Celebrants', fld:'dob', val:2, familySort:false},
    '3':{name: 'March Celebrants', fld:'dob', val:3, familySort:false}, 
    '4':{name: 'April Celebrants', fld:'dob', val:4, familySort:false},
    '5':{name: 'May Celebrants', fld:'dob', val:5, familySort:false},
    '6':{name: 'June Celebrants', fld:'dob', val:6, familySort:false},
    '7':{name: 'July Celebrants', fld:'dob', val:7, familySort:false},
    '8':{name: 'August Celebrants', fld:'dob', val:8, familySort:false},
    '9':{name: 'September Celebrants', fld:'dob', val:9, familySort:false},
    '10':{name: 'October Celebrants', fld:'dob', val:10, familySort:false},
    '11':{name: 'November Celebrants', fld:'dob', val:11, familySort:false},
    '12':{name: 'December Celebrants', fld:'dob', val:12, familySort:false},
}
//'Existing member', 'None-existing member', 'New member', 'Transferred', 'Relocated', 'None-member', 'Late'
//[txtFieldsKeys[7]]:{name:"ageGroup", title:'Age Group', 
//optArr:["Select", "Baby (0-2yrs)","Child (3-16yrs)", "Young Adult (17-30yrs)", "Middle-aged Adult (31-45yrs)", "Old Adult (above 45yrs)"], type:'opts'},

//'13':{name: 'Baby (0-2yrs)', fld:'ageGroup', val:'Baby (0-2yrs)'},
//'14':{name: 'Child (3-16yrs)', fld:'ageGroup', val:'Child (3-16yrs)'},
//'15':{name: 'Young Adult (17-30yrs)', fld:'ageGroup', val:'Young Adult (17-30yrs)'},
//'16':{name: 'Middle-aged Adult (31-45yrs)', fld:'ageGroup', val:'Middle-aged Adult (31-45yrs)'},
//'17':{name: 'Old Adult (above 45yrs)', fld:'ageGroup', val:'Old Adult (above 45yrs)'},
