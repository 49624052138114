import React from "react";
import {FiPaperclip} from 'react-icons/fi';


const Message =({item})=>{
    
    const msgsArr = item?.message;
    
    return(
        <div className="p-3 sm:p-5">
            
        <Field 
            title="Title:"
            main={item?.title}
        />
        <Field 
            title="Date:"
            main={new Date(item?.date).toUTCString()}
        />
        <Field 
            title="Sender:"
            main='Admin'
        />

        <Field 
            flexCol noUnderline
            titleUnder="underline"
            fldMagT="mt-3"
            title="Message:"
            main={msgsArr[0]}
        />
        {
            msgsArr?.map((msg,i)=>{

                return(
                    <Field key={`${i}+key`}
                        flexCol noUnderline
                        main={i !== 0 && msg}
                        //fieldStyle="mt-2"
                    />
                );
            })
        }
        <div className={`${item.attachedFile? '' : 'hidden'} `}>
            <a href={`${item.attachedFile}?dl=`}
                className={`flex flex-row cursor-pointer text-[blue] hover:text-[maroon]
                    w-fit`}>
                <FiPaperclip size={28}/>
            <p className="ml-1">{item.attachedFileName}</p>
            </a>  
        </div>
    </div>
    );
}


const Field =({title, main, flexCol, noUnderline, fldMagT, color, titleUnder, fieldStyle})=>{

    return (
        <div className={`flex mb-1 ${flexCol? 'flex-col' :'flex-row'} ${fieldStyle}`}>
            <p className={`pr-1 text-gray-700 ${fldMagT? fldMagT :''} ${titleUnder? titleUnder :''}`}>{title}</p>
            <p className={`text-justify ${noUnderline? '' : 'underline'} ${ color? color : 'text-[#191949]'}`}>
                {main}
            </p>
        </div>
    );
    }
                          
export {Message}