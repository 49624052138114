import React from 'react'
import { client } from '../../../../../../client';
import { txtFldHeader } from '../../../../assets/textFieldsMain';
import MembersDataDisplay from './MembersDataDisplay';


const MembersDataIndex = () => {
    const [selectedUpdate, setSelectedUpdate] = React.useState({header:'', field:''});
    const [selectionCheck, setSelectionCheck] = React.useState({isOkay:false, msg:''});
    const [memberIDs, setMembersIDs] = React.useState([]);
    const [headeFieldOkay, setHeaderFieldOkay] = React.useState(false);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [uploadedData, setUploadedData] = React.useState({data:[], noError:false});

    //console.log(selectedUpdate)
    function fetchMembersIDs(){
       fetchContents(queryMembersDataInfos).then((res)=>{
        setMembersIDs(res);
        //console.log()
       })
    }
  
    function updateFormsHandler(){
      if(uploadedData.data.length){
        const [, keyUD] = uploadedData.data[0];
        const {header, field} = selectedUpdate;
        const isOkay = confirmMembersIDs(uploadedData.data);
        if(!headeFieldOkay){
          setSelectionCheck({isOkay:false, msg:"Wrong Selection header and field key!"});
          return
        }
        if(!isOkay){
          setSelectionCheck({isOkay:false, msg:"A wrong member's id was oberved. Please, check your uploads"});
          return
        }
        
        if(field===keyUD && isOkay){
          setSelectionCheck({isOkay:true, msg:"Upload & Selection header-key okay. Please, wait while we update..."});
          setIsUpdating(true);
          const el = {idValues: uploadedData.data.slice(1), field:selectedUpdate.field};
          updateMultipleFormsField(el);
        }else{
          setSelectionCheck({isOkay:false, msg:"Wrong Upload & Selection header-key!"});
        }
      }else{
        setSelectionCheck({isOkay:false, msg:"You have not uploaded any data!"});
      }
    }

    const updateMultipleFormsField = async (el)=>{
      const {idValues, field} = el;
        for (let i = 0; i < idValues.length; i++) {
          const [id, value] = idValues[i];
          setSelectionCheck({isOkay:true, msg:`Updating ${i+1+'/'+idValues.length}: ${id} data updating...`}) 
          await client
            .patch(id) // Document ID to patch
            .set({[field]:value}) // Shallow merge
            .commit(); // Perform the patch and return a promise
            /*.then((user) => {
                         
            })
            .catch((err) => {
              console.error('Oh no, the update failed: ', err.message)
            });*/
            //console.log({ [id]:"Updating", [field]: value})
          }
          setIsUpdating(false);
          setSelectionCheck({isOkay:true, msg:"Updated completed successfully"});
          setUploadedData(({data:[], noError:false}));
    }



    function onChangeHandler(type, e){
      if(type==="HEADER"){
          setSelectedUpdate({...selectedUpdate, header:e})
      }else if(type === "FIELD"){
        setSelectedUpdate({...selectedUpdate, field:e})
      }
    }
    function setUploadedDataHandler(el){
        const noError = el.noError;
      if(noError){
        setUploadedData(el)
      }else{setUploadedData({data:[], noError:false})}
    };
    React.useEffect(()=>{
      //if(selectedUpdate.field && selectedUpdate.header){
        //const isOkay = txtFldHeader(selectedUpdate?.field).header === selectedUpdate?.header;
        const headerTrue = txtFldHeader[selectedUpdate.field]?.header;
        const headerTest =selectedUpdate?.header;
        const isOkay = headerTrue === headerTest;
        setHeaderFieldOkay(isOkay);
        setSelectionCheck({isOkay:isOkay, msg:isOkay? "Header and fields selection okay" : "Wrong Header and field combination!"});
     // }
      fetchMembersIDs()
    },[selectedUpdate]);
  
    return (
    <>
        <MembersDataDisplay
          memberIDs={memberIDs}
          uploadedData={uploadedData}
          setUploadedDataHandler={setUploadedDataHandler}
          onChangeHandler={onChangeHandler}
          selectionCheck={selectionCheck}
          updateFormsHandler={updateFormsHandler}
          isUpdating={isUpdating}
          clearUploadHandler={()=>setUploadedData({data:[], noError:false})}
        />
    </>
  )
}

export default MembersDataIndex;


async function fetchContents(queryMembersDataInfos) {
    const response = await client.fetch(queryMembersDataInfos);
    return response;
  }

const queryMembersDataInfos = `
  *[ _type ==  "members"]{
    _id,id, surname, firstname,
  }
 `;

 function confirmMembersIDs(el){
   let isOkay = false;
    const arrs = el.slice(1);
    arrs.forEach((item)=>{
      const id = item[0];
      const dotLen = id.split('.').length;
       if(dotLen !== 4){ isOkay = false; return 
       }else{isOkay = true}
    });
 
    return isOkay
 }

