import React from "react";
import { client } from "../../client";
import {useDispatch} from 'react-redux';
import {disPstCornerMsg} from '../../context/reducers/pstCornerMsg';
//import getPastorCornerMessages from "./data";

function useFetchData(){
    const dispatch = useDispatch();
    const [refetchData, setRefetchData] = React.useState(0);
    const [fetchedData, setFetchedData] = React.useState();
    const [firstFetch, setFirstFetch] = React.useState(false);

    async function fetchContents(query) {
        const response = await client.fetch(query);
        // waits until the request completes...
        return response;
      }

    React.useEffect(()=>{
        if(!firstFetch){
            const fetchProgRes = fetchContents(queryPstCorner);
            fetchProgRes.then((res)=>{
            setFetchedData(res);
            const processRes = processPastorCornerMessages(res);
            dispatch(disPstCornerMsg(processRes));
            //console.log(processRes);
         }); 
        setFirstFetch(true);
        }

        //For Internal Data
       // dispatch(disPstCornerMsg(getPastorCornerMessages()));
    },[]);

    React.useEffect(()=>{
        if(refetchData !==0){
            const fetchProgRes = fetchContents(queryPstCorner);
            fetchProgRes.then((res)=>{
            setFetchedData(res);
            const processRes = processPastorCornerMessages(res);
            dispatch(disPstCornerMsg(processRes));
            //console.log(processRes);
         });
        } 

    },[refetchData]);
    
    function refetchDataCall(){
        setRefetchData(prev=>prev+1);
    }

    return [fetchedData, refetchDataCall]
}


export default useFetchData;



function processPastorCornerMessages(msgArr){

    const allMsgsManualNew = msgArr.map((item,i)=>{
        
        return {...item, title:item.topic, body:item.body.split('\n\n'), msgID:item.messageID,
            likes:item.likes || [], comments: item?.comments || [], 
            details:{author: "Admin", date:item.date}}
    });
    
    const allMsgs = [...allMsgsManualNew];
    const sortedMsg = allMsgs.sort((a, b)=>b.sn - a.sn); //Descending Order
    
    return sortedMsg
}




const queryPstCorner = `
*[_type == "pastorCorner"] | order(_createdAt desc) {

    _id,
    topic,
    messageID,
    sn,
    pray,
    bibleRef,
    body,
    date,
    likes[]{
      _key,
      postedBy->{
        _id,
        userName,
        image
      },
    },
    comments[]{
      _key,
      comment,
      datePosted,
      postedBy->{
        _id,
        userName,
        image
      },
    },
  }
`;