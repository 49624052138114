import { client } from '../../../client';
import generateReportFunction from '../../../utils/generateReportFunction';
import { getQueryByMemberPersonalIDAndFamData, getQueryLoginMemberDataAndFamData, 
  getQueryParentAllData, getQueryAllMembersData, getQueryByMemberEmail, getQueryNonRegisteredUserMails } from '../admin/queries/queryMembersAllData';
import { sortFamilyData } from '../modules/sortRecord';


function setLoginIDToMemberHandler(userLoginID, id ){
    if(id && userLoginID){
        client
          .patch(id) // Document ID to patch
          .set({'loginID':userLoginID}) // Shallow merge
          .commit() // Perform the patch and return a promise
          .then((user) => {
            console.log('LoingID has been set on '+user.surname+ ' successfully.')            
          })
          .catch((err) => {
            console.error('Oh no, the update failed: ', err.message)
          });
      }else{
      console.log("Login to set Member's LoginID!")
    }
  }

  function setAccessPermitToMemberHandler(userLoginID, id, accesspermits,){
    if(id && userLoginID){
      client
        .patch(id) // Document ID to patch
        .set({'accesspermit':accesspermits}) // userLoginID = all accesspermits
        .commit()
        .then((user) => {
          console.log('Access permit has been set on '+user.surname+ ' successfully.');
        })
        .catch((err) => {
          console.error('Oh no, the update failed: ', err.message)
        });
    }else{
      console.log("Login to set Member's LoginID!")
    }
  }


  async function fetchContents(type, id, user){
    if(type==="QUERY"){
      const response = await client.fetch(id); //id = query
      return response;
    }
    if(type==="QUERYBYEMAIL"){
      const query = getQueryByMemberEmail(id) //id == email
      const response = await client.fetch(query);
      return response;
    }
    if(type==="QUERY_FAMILY"){
      const query = getQueryParentAllData(id);
      const response = await client.fetch(query);
      return response;
      //console.log(response)
    }
    if(type==="INIT_SEARCH_ANDSET"){
      const query = getQueryLoginMemberDataAndFamData(id.email, id.loginID); //id= user
        const response = await client.fetch(query);
        return response
    }
    if(type==="NONEREGUSERINBOX"){
      const query = getQueryNonRegisteredUserMails(id.email); //id= user
        const response = await client.fetch(query);
        return response
    }
    if(type==="ALLMEMBERSDATA"){
      const query = getQueryAllMembersData();
      const response = await client.fetch(query); 
      return response;
    }
    if(type==="MEMBERSCOUNT"){
      const query = `{'totalMembers':count(*[_type == 'members'])}`;
      const response = await client.fetch(query); 
      return response;
    }
  }


  const fetchMultipleFiles = async (arr) => {
    //fetch based on accesspermitList;
    const forms = [];
    for (let i = 0; i < arr.length; i++) {
      const memberPersonalID = arr[i];
      const queryId = getQueryByMemberPersonalIDAndFamData(memberPersonalID);
      const res =  await client.fetch(queryId);
      if(res.length>0){
        const {userData, familyData} = res[0];
        let reportData = [], sortedFam =[];
        //console.log({userData, familyData})
        if(familyData.length <= 1){
          //Single individual with no family member in church
          sortedFam = familyData;
          reportData = generateReportFunction(sortedFam, 'family', 'family', 'famStat');
          forms.push({userData, sortedFam, reportData});
        }else{
          if(userData.famStat==="parent"){
            sortedFam = sortFamilyData(familyData); 
            reportData = generateReportFunction(sortedFam, 'family', 'family', 'famStat');
            forms.push({userData, sortedFam, reportData});
          }else{
            //A child (Single individual) with family member in church
            sortedFam = [userData];
            reportData = generateReportFunction(sortedFam, 'family', 'family', 'famStat');
            forms.push({userData, sortedFam, reportData});
          }
        }
      }
      //forms.push(res);
    }    
    //console.log('All FILES UPLOADED!');
    return forms
  }

 function sortAndProcessForms(userForm){
    let reportData = [], sortedFam =[];
    if(userForm?.famStat === 'parent'){
      fetchContents('QUERY_FAMILY', userForm.family).then((res)=>{
       sortedFam = sortFamilyData(res); 
       reportData = generateReportFunction(sortedFam, 'family', 'family', 'famStat');
      });

    }else{
      sortedFam = userForm;
      reportData = generateReportFunction(sortedFam, 'family', 'family', 'famStat');
    }
    return {user:userForm, sortedFam, reportData};
  }


  function removeDeniedFromPermitAccess(accesspermitArr, accessdeniedArr){
    const accesspermitArrOkay = [];
        for(let i=0; i < accesspermitArr.length; i++){
            const el = accesspermitArr[i]
            const truty = accessdeniedArr.includes(el);
            if(!truty){
                accesspermitArrOkay.push(el);
              }
        };
        return  accessdeniedArr.includes('ALL')? [] : accesspermitArrOkay
  }


  function registerLoginMember(docID, memberID){
    //const dateString = new Date().toUTCString();
    const dateKey = new Date().toUTCString().toLocaleLowerCase().replace(/ |:|,/g,'-').replace('--','-');
      client
        .patch(docID)
        .setIfMissing({ usersList: [],})
        .insert('after', 'usersList[-1]', 
            [{ _key: `${dateKey}`,
               _type: 'postedByMembers',
               _ref:memberID, 
            }])
        .commit()
        .then((res) => {
          //console.log(res);
        });
  }


  function createMembersUser() {
    //const bodyText = body.join('\n\n');
    const doc = {
        _id:'login.members.list',
        _type: 'membersActivities',
        title:'Login Members List',
        identity:'login.members.list',
        details:'List of all members that have logged in.',
        usersList:[],
      };
      client.createIfNotExists(doc).then(response => {
            console.log(response);
        }).catch(e => {
            console.log(e);
        });

  }


  export {setLoginIDToMemberHandler, setAccessPermitToMemberHandler, fetchContents, fetchMultipleFiles, 
    removeDeniedFromPermitAccess, registerLoginMember}