
function getAdminPassCall(pass){
    let accessPass = []; //length 9. 
    if(pass.length === 9){
        const arrOfStr = Array.from(String(pass));
        const adminPass = [arrOfStr[0], arrOfStr[5], arrOfStr[6], arrOfStr[3], arrOfStr[4], arrOfStr[1]];
        const devPass = [arrOfStr[0], arrOfStr[5], arrOfStr[7].toUpperCase(), arrOfStr[8].toUpperCase(), arrOfStr[4], arrOfStr[1]];
        accessPass = {admin:adminPass.join(""), dev:devPass.join("")};
        return accessPass
    }else {return {}}
}

export {getAdminPassCall}

