import React from 'react';
//import MembersInfoTable from './components/MembersInfoTable';
import OnlineOfferingIndex from './components/OnlineOfferingIndex';
import useOnline from 'use-online';
import { BsWifiOff } from 'react-icons/bs';
import TabsOnlineOffering from './TabsOnlineOffering';

const Index = () => {
  const isOnline = useOnline();
  const [selectedTab, setSelectedTab] = React.useState({name:'publishUpload', title: 'Publish Uploads'});

  if(!isOnline){
    return (
      <div className='text-red-700 flex justify-center pt-[60px]'>
          <BsWifiOff size={30} color="red" className='mr-3 inline-block'/>
          Connection error!. Please, check your network.
      </div>
    );
  }
  

  return (
    <div className='mx-3'>
        <p>SelectedTap: {selectedTab.name}</p>
        <TabsOnlineOffering
         setSelectedTab={setSelectedTab}/>
        <OnlineOfferingIndex
         selectedTabName={selectedTab.name}/>
    </div>
  )
}


export default Index