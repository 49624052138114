import sortRecords from "./sortRecord";
import { sanityDateFormat } from "../../../utils/getDateString";
import getBirthDay from "../../../utils/getBirthDay";
import { getMembershipArr } from "../assets/textFieldsMain";

function assignIDToNewForms(newForms, familyIDRetrv){
    //FamilyID: surname.fatherfirstname.motherfirstname||childfirstname
    //FamilyID-Single: surname.firstname.matStat
    //PersonalID: surname.firstname.othernames||genderStat
    const len = newForms.length;
    let assignedIDs ={familyID:""};
    if(len===1){
        const {surname, firstname, matStat, othernames, genderStat} = newForms[0];
        const familyID = familyIDRetrv? familyIDRetrv : `${surname}.${firstname}.${matStat}`;
        const personalID = `${surname}.${firstname}.${othernames || genderStat}`;
        assignedIDs = {...assignedIDs, familyID:familyID, [firstname]:personalID};
    }else{
        //newForms > 1 && There must be at least a parent if no familyID is provided (ie, for new entries);
        const {father, mother, children} = sortRecords(newForms);        
        const surname = newForms[0].surname; 
        const fatFstName = father.firstname || "" ; 
        const motFstName = mother.firstname || "" ;
        const fstChildName = children[0].firstname || "";
        const newFamID = familyIDRetrv? familyIDRetrv : 
            `${surname}.${father? fatFstName : motFstName}.${father? motFstName : fstChildName}`;

        //Assign PersonalID
        for(let i =0; i < newForms.length; i++ ){
            const {surname, firstname, othernames, genderStat} = newForms[i];
            const personalID = `${surname}.${firstname}.${othernames || genderStat}`;
            assignedIDs = {...assignedIDs, familyID:newFamID, [firstname]:personalID};
        }
    } 
    return assignedIDs
};

function assignPersonalID(forms, totalMembers){
    //FamilyID: surname.fatherfirstname.motherfirstname||childfirstname
    //FamilyID-Single: surname.firstname.matStat
    //PersonalID: surname.firstname.genderStat.no[formNo]
    const len = forms.length;
    let formsCopy =[...forms];
    if(len===1){
        let form = formsCopy[0];
        if(!form.id || form.id===''){
            const res = assignIDToAForm(form, totalMembers+1);
            formsCopy = [res];
        }else{formsCopy = [form]}
    }
    if(len >1){
        //Assign PersonalID
        const formsWithIDs = forms.filter(form=> form.id);
        const formsWithoutIDs = forms.filter(form=> !form.id || form.id==='');
        

        if(formsWithoutIDs.length){
            const formsCont = [];
            for(let i=0; i < formsWithoutIDs.length; i++ ){ //FormsWithoutIDs
                
                let form = formsWithoutIDs[i];
                const formWithPID = assignIDToAForm({...form}, totalMembers+(i+1));
                formsCont.push(formWithPID);
            }
            if(formsWithIDs.length){
                formsCopy = [...formsCont, ...formsWithIDs];
            }else{formsCopy = formsCont}
        }else{
            //All forms have id
            formsCopy = forms }
    } 
    return formsCopy
};

function assignIDToAForm(form, no){
    const {surname, firstname, genderStat} = form;
    let personalID = `${surname}.${firstname}.${genderStat}.no${no}`;
    personalID = personalID.toLowerCase();
    form = {...form, id:personalID};
    
    return form
}

function assignFamilyID(forms){
    //FamilyID: surname.fatherfirstname.motherfirstname||childfirstname
    //FamilyID-Single: surname.firstname.matStat
    //PersonalID: surname.firstname.othernames||genderStat
    const len = forms.length;
    var formsCopy =[...forms];
    if(len===1){
        let form = formsCopy[0];
        if(!form.family || form.family===''){
            const {surname, firstname, matStat, othernames} = form;
            let familyID =  `${surname}.${firstname}.${othernames || matStat}`;
            familyID = familyID.toLowerCase();
            formsCopy = [{...form, family:familyID}];
        }else{formsCopy = [form]}
    }
    if(len > 1){
        const formWithFamID = forms.find(form=> form.family);
        if(formWithFamID){
            const famID = formWithFamID.family;
            formsCopy = formsCopy.map(form=> { return {...form, family:famID}})
        }else{
            const {father, mother, children} = sortRecords(forms);        
            const surname = forms[0]?.surname; 
            const fatFstName = father?.firstname || "" ; 
            const motFstName = mother?.firstname || "" ;
            const fstChildName = children[0]?.firstname || "";
            let famID = `${surname}.${father? fatFstName : motFstName}.${father? motFstName : fstChildName}`;
            famID = famID.toLowerCase();
            formsCopy = forms.map(form=> { return {...form, family:famID}})
        }
  }
  return formsCopy
}

function assignOtherHiddenInfo(forms, totalMembers, user){
    const otherInfo = [...hiddenInfo];
    const userInfo = `${user.fullName+'-'+user.email}`;
    //const formsCopy = [...forms];
    const formsNew = [];
    var totalMembersNew = totalMembers;
    for(let i=0; i < forms.length; i++){
        var form = forms[i];
        totalMembersNew +=1;
        for(let j=0; j < hiddenInfo.length; j++ ){
            var infos  = otherInfo[j];
            if(!form[infos] || form[infos] ==="" || form.photo || form.imageUrl){
                
                const fldVal = getFieldValue(form, infos, form.id, totalMembersNew, userInfo)
                form = {...form, [infos]:fldVal};
            }
        }
        formsNew.push(form);
    }
    return formsNew
}

const membership = getMembershipArr();
function getFieldValue(form, flds, id, total, userInfo){
    var val;
    const snStr = form?.id?.match(/\d+/)[0];
    const sn = parseInt(snStr);
    switch(flds) {
        case "sn":
            val = sn || total; break
        case "notifToggle":
            val = false; break
        case "filledBy":
            val = userInfo; break
        case "filledDate":
            val = sanityDateFormat(); break
        case "birthday":
            val = getBirthDay(form.dob); break
        case "wedAnns":
            val = form.wedAnn? getBirthDay(form.wedAnn) : ""; break
        case "select":
            val = false; break
        case "membership":
            val = membership[0]; break 
        case "edit":
            val = false; break
        case "photo":  
            //TOFIX: What if there's already an a phone and Not upload?
            if(form.imageUrl){
              val =  {
                _type: 'image',
                asset: {
                    _type: "reference",
                    _ref: form.imageUrl,  //image._id
                }};
            }else{
                if(form?.photoAsset?._type){
                 val =  form.photoAsset }
            }    
            break

        case "loginID":
            val = ''; break 
        //case "imageUrl":  //imageUrl will be set back to null @std doc
        //    val = null; break 

        case "_type":
            val = 'members'; break
        case "_id":
            val = id; break
    
        default:
          val = "";
      }
      return val
}

function assignCelebrationDates(dates){
    //dates => yyyy-mm-dd: 2022-12-23

}

export { assignPersonalID, assignFamilyID, assignOtherHiddenInfo}


const hiddenInfo = [
    'sn',
    'birthday',
    'wedAnns',
    'select',
    'edit',
    'info',
    'loginID',
    'filledBy',
    'filledDate',
    'editedBy',
    'accesspermit', 
    'accessdenied', 
    'notification', 
    'notifToggle',
    'photo',
    '_type',
    '_id',
    'membership',
    'imageUrl', // Last item in order to be set to null

    //'family', 'id', 'famStat',
 ];
const memberInfo = {
    info:'',
    title:"Brother",
    surname:"",
    firstname:"",
    othernames:"",
    addressRes:"",
    dob:"2022-01-19",
    genderStat:"Male",
    email:"",
    phoneNo:"",
    occupation:"",
    addressOff:"",
    school:"",
    instiStat:"",
    memberStat:"",
    matStat:"Single",
    wedAnn:"",
    batStat:"Baptised",
    photo:"",
}

