import hisAndhers1 from './HisAndHers2018-1.JPG';
import hisAndhers2 from './HisAndHers2018-2.JPG';
import hisAndhers3 from './HisAndHers2018-3.JPG';
import hisAndhers4 from './HisAndHers2018-4.JPG';
import hisAndhers5 from './HisAndHers2018-5.JPG';
import hisAndhers6 from './HisAndHers2018-6.JPG';
import hisAndhers7 from './HisAndHers2018-7.JPG';
import hisAndhers8 from './HisAndHers2018-8.JPG';
import hisAndhers9 from './HisAndHers2018-9.JPG';
import hisAndhers10 from './HisAndHers2018-10.JPG';
import hisAndhers11 from './HisAndHers2018-11.JPG';
import hisAndhers12 from './HisAndHers2018-12.jpg';
import hisAndhers13 from './HisAndHers2018-13.jpg';
import hisAndhers14 from './HisAndHers2018-14.jpg';
import hisAndhers15 from './HisAndHers2018-15.jpg';
import hisAndhers16 from './HisAndHers2018-16.jpg';
import hisAndhers17 from './HisAndHers2018-17.jpg';
import hisAndhers18 from './HisAndHers2018-18.jpg';
import hisAndhers19 from './HisAndHers2018-19.jpg';
import hisAndhers20 from './HisAndHers2018-20.jpg';
import hisAndhers21 from './HisAndHers2018-21.jpg';



const hisAndhers2017 = [
      {sn:1, src:hisAndhers1,title:'His & Hers Conference 2017', desc:'His & Hers Conference 2017 with Lady Evang. Tope Alabi', 
            bCol:'seagreen', date:'2017-12-23'},
      {sn:2, src:hisAndhers2,},{sn:3, src:hisAndhers3,},{sn:4, src:hisAndhers4,},
      {sn:5, src:hisAndhers5,},{sn:6, src:hisAndhers6,},{sn:7, src:hisAndhers7,},
      {sn:8, src:hisAndhers8,},{sn:9, src:hisAndhers9,},{sn:10, src:hisAndhers10,},
      {sn:11, src:hisAndhers11,},{sn:12, src:hisAndhers12,},{sn:13, src:hisAndhers13,},
      {sn:14, src:hisAndhers14,},{sn:15, src:hisAndhers15,},{sn:16, src:hisAndhers16,},
      {sn:17, src:hisAndhers17,},{sn:18, src:hisAndhers18,},{sn:19, src:hisAndhers19,},
      {sn:20, src:hisAndhers20,}, {sn:21, src:hisAndhers21,}
      ];
    
export default hisAndhers2017