import React from 'react';


const FormViewField =({title,defSelected, optArr, placeholder, type, value,item, readOnly,onEdit,
    onChange, required, name, onEditToggle, autoComplete, editToggle, isReadOnly})=>{
    
    const inuputStyle = `
    form-control 
    w-full
    px-1 sm:px-3
    py-1
    text-xs sm:text-sm
    font-normal
    text-gray-700
    bg-mainColor bg-clip-padding
    border border-solid border-gray-300
    transition
    ease-in-out
    focus:text-gray-700 focus:bg-white focus:border-[cyan] 
    focus:outline-none
    `;
    return(
        <div className="mx-0 sm:mx-3 mb-2 flex flex-row px-[5px] py-[2px] items-center border-[2px] rounded-md border-solid border-blue-800">
            <label className="form-check-label whitespace-nowrap flex text-sm sm:text-base  text-[teal] px-2"
                >
                {title}
            </label>
            {type === 'radio' || type === 'opts'?
                <SelectionTag
                 optArr={optArr}
                 title={title}
                 defSelected={defSelected} 
                 name={name}
                 onChange={onChange}
                 isReadOnly={isReadOnly}
                /> :
                type === 'file'?
                <input className={inuputStyle}
                    type={'file'} 
                    placeholder={placeholder}
                    onChange={onChange}
                    required= {"" || required}
                    autoComplete={"" || autoComplete}
                    name="photo"
                    accept="image/png, image/jpg, image/jpeg"
                    />:
                <input className={inuputStyle}
                type={type || "text"} 
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                required= {"" || required}
                autoComplete={"" || autoComplete}
                name={name}
                //list={displayLst}
                //readOnly={onEdit===item? false : true}
                //style={onEdit===item? editStyle : noneEditStyle}
                />
            }
            <label className="hidden form-check-label cursor-pointer font-bold  text-[teal] pl-2"
                onClick={onEditToggle}
                >
                {onEdit===item? editToggle : "Edit"}             
            </label>
        </div>
    );
}

const SelectionTag=({optArr, defSelected, title, name, onChange, isReadOnly})=>{
    const [selected, setSelected] = React.useState(defSelected? defSelected : "");
    const optsArr = optArr || [];
    
    
    function onChangeHandler(e){
      if(!isReadOnly){
        setSelected(e.target.value==="Select" || e.target.value.includes("Select")? "" 
        : e.target.value );
        onChange(e);
      }
    }
    return(
        <>
              <select 
                  className="w-full
                  px-3
                  py-1.5
                  text-xs sm:text-sm
                  font-normal
                  text-gray-700
                  bg-mainColor bg-clip-padding
                  border border-solid border-gray-300
                  transition
                  ease-in-out
                  focus:text-gray-700 focus:bg-white focus:border-[cyan] 
                  focus:outline-none" 
                  value={selected} onChange={onChangeHandler} name={name} >
                {optsArr.map((item,index)=>{
                 return (
                  <option key={`${index}+val`} value={item}>{item}</option>                 
                  );
                })}
              </select>
        </>
    );
  }

function getList(item){
    var list;
    switch(item) {
        case "genderStat":
            list = "genderStat"; break
        case "instiStat":
            list = "instiStat"; break
        case "matStat":
            list = "matStat"; break
        case "memberStat":
            list = "memberStat"; break
        case "batStat":
            list = "batStat"; break
        case "wedAnn":
            list = "wedAnn"; break
        default:
          list = null;
      }
      return list
}


export default FormViewField;

/*
<datalist id="genderStat">
                <option value="Male"/>
                <option value="Female"/>
            </datalist>
            <datalist id="matStat">
                <option value="Single"/>
                <option value="Married"/>
            </datalist>
            <datalist id="memberStat">
                <option value="Minister"/>
                <option value="Worker"/>
                <option value="Member"/>
            </datalist>
            <datalist id="batStat">
                <option value="Baptised"/>
                <option value="Not baptised"/>
            </datalist>
            <datalist id="wedAnn">
                {
                    wedAnnList.map((lst, i)=>{
                        return(
                            <option value={lst}/>
                        )
                    })
                }
            </datalist>
            <datalist id="wedAnnDA">
                {
                    wedAnnListDays.map((lst, i)=>{
                        return(
                            <option value={lst}/>
                        )
                    })
                }
            </datalist>
            <datalist id="instiStat">
                {
                    instiStatList.map((lst, i)=>{
                        return(
                            <option value={lst}/>
                        )
                    })
                }
            </datalist>
    */