import React from "react";






const SelectionTagCont=({title, optsArr,  onChange, contStyle})=>{

  return(
    <div className='flex flex-row justify-center items-center'>
        <label className='font-bold'>
          {title}:
        </label>
        <SelectionTag 
          optArr={['Select', ...optsArr]}
          onChange={onChange}
          defValue="Select"
          contStyle={contStyle}
        />
    </div>
  )
}











const SelectionTag=({optArr, defValue, name, onChange, isReadOnly, contStyle})=>{
    const [selected, setSelected] = React.useState(defValue || "");
    const optsArr = optArr || [];
    
    function onChangeHandler(e){
      if(!isReadOnly){
        setSelected(e.target.value==="Select" || e.target.value.includes("Select")? "" 
        : e.target.value );
        //onChange(e);
      }
    }
    React.useEffect(()=>{
      onChange(selected);
    },[selected]);
    return(
        <div className={`${contStyle}`}>
              <select 
                  className="w-full
                  px-3
                  py-1.5
                  text-xs sm:text-sm
                  font-normal
                  text-gray-700
                  bg-mainColor bg-clip-padding
                  border border-solid border-gray-300
                  transition
                  ease-in-out
                  focus:text-gray-700 focus:bg-white focus:border-[cyan] 
                  focus:outline-none" 
                  value={selected} onChange={onChangeHandler} name={name} >
                {optsArr.map((item,index)=>{
                 return (
                  <option key={`${index}+val`} value={item}>{item}</option>                 
                  );
                })}
              </select>
        </div>
    );
  }



  export {SelectionTag, SelectionTagCont}