import React from 'react';
import Card from '../../cards/Card'; 
import { getBiblePsg } from '../../../utils/miscens';
import useWindowDimensions from '../../../utils/useWindowDimension';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import generateRandomBetween from '../../../utils/generateRandomBtwn';
import pastorCornerPhotos from '../../../assets/pastorCorner/_index';
import CustomLoader from '../../userpage/components/CustomLoader';
import useOnline from 'use-online';

const lenPC = pastorCornerPhotos.length;
const PCorner = () => {
    //const navigate = useNavigate();
    let { width } = useWindowDimensions();
    const {pstCornerData} = useSelector((state)=>state.pstCornerMsg);
    let baseSize = 100;
    const isOnline = useOnline();

    if(width > 0 && width <500){
        baseSize = 50
    }else if(width > 500 && width < 900){
        baseSize = 70
    }else{ baseSize = 120};
    const varLen = [baseSize, baseSize+70, baseSize-10, baseSize-15, baseSize+30, baseSize+50, baseSize+40, baseSize+10];

    
    let pastorCornerMsg = [...pstCornerData];
        pastorCornerMsg = pastorCornerMsg.slice(0, 4);
    
    const renderLoader = (<CustomLoader loadingMsg="Loading Pastor's Corner Messages, please wait!" textCol='white' color="white" 
        loading={true} size={80}/>);
        const renderNoNetwork = (<LastCard text="Error in loading Pastor's Corner. Please, check your internet connection."/>);
    const renderedItem = isOnline? renderLoader : renderNoNetwork;

    return (
        <div className="flex w-full flex-col smc:flex-row pt-0 smc:pt-12 bg-[#1c1c9e]">
            {/* For Small Screen*/}
            <div className='smc:hidden text-[28px] text-white font-bold text-center p-5'>
                    Pastor's Corner
                    <div className='text-base font-normal text-[#f9b507]'>
                        <h1>God's word is able to transform your life forever.</h1>
                        <h1>Enrich your heart with this word from the Pastor's Corner.</h1>
                    </div>
            </div>
            <div className='hidden smc:flex justify-center mx-5
                    text-white w-[30%]'>
                <h2 className='font-archivo text-[5vw] font-bold text-center'>
                    Pastor's Corner
                    <div className='font-body text-lg px-5 font-normal text-left text-[#f9b507]'>
                        <h1>God's word is able to transform your life forever.</h1>
                        <h1>Enrich your heart with this word from the Pastor's Corner.</h1>
                    </div>
                </h2>
            </div>
            {pastorCornerMsg.length> 0?
                <div className={`${pastorCornerMsg.length> 0? "" : 'hidden'} flex items-center w-full smc:w-[70%] overflow-x-auto px-2`}>
                {pastorCornerMsg.map((item, i)=>{
                    
                    //const randomNo = generateRandomBetween(0,varLen-1);
                    const body = item.body.join(' ').substring(0, i>varLen.length? varLen[0] : varLen[i]).split(/\r?\n/)+"...";
                    const randomPhoto = generateRandomBetween(0,lenPC-1);
                    return(
                        <Card 
                            key={`${i}+pcorn`}
                            photo={pastorCornerPhotos[randomPhoto]} 
                            postedDate={item.details.date}
                            msg={body}
                            bibleRef={item.bibleRef.substring(0, 90).split(/\r?\n/)+'..."' + getBiblePsg(item.bibleRef)}
                            // ={item.pray}
                            
                            topic={item.title}
                            comment={item?.comments?.length} 
                            likes={item?.likes?.length}
                            item={item}
                            //readMoreHandler={()=>readMoreHandler(item)}
                        />
                    );
                })}
                <LastCard
                    text="View all the messages"
                    type="MORE"
                />
            </div>
            :renderedItem
            }
            <div className={`${pastorCornerMsg.length> 0? "hidden" : 'hidden'}`}>
                <LastCard text="Error in loading Pastor's Corner. Please, check your internet connection."/>
            </div>
        </div>
    )
}



const LastCard =({text, type})=>{
    const navigate = useNavigate();
    const randomPhoto = generateRandomBetween(0,lenPC-1);
    return(
        
        <div className="rounded-lg mx-2 border-4 border-solid border-white  min-w-[250px] w-[30vw] max-w-sm
            h-[350px] cursor-pointer shadow-md bg-center bg-contain"
            style={{backgroundImage:`url(${pastorCornerPhotos[randomPhoto].src})`}}
            onClick={()=>{ if(type==="MORE") navigate('/pastorCorner')}}>
            <p className='bg-[rgba(100,100,200,0.7)] hover:bg-[rgba(100,100,200,0.4)] text-center
                text-white hover:text-[coral] px-3 flex flex-1 h-full justify-center items-center'>
                {text}
            </p>
        </div>
     
    );
}

export default PCorner