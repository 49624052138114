import React from 'react'
import CreateUpload from './CreateUpload'


const Index = () => {
  return (
    <div>
        <CreateUpload/>
    </div>
  )
}


export default Index