import React from "react";
import { getFormField } from "../../../assets/textFieldsMain";

const XLSX = require('xlsx');


const ExportToExcel =({title,data, dataTitle, className, allFields, 
    formFlds, format, excludeHeaders, excludedHeadersArr})=> {
    const defExcluded = getFormField('txtFieldsHidden').fields;
    let excludeHeadersMin = ['sn', ...defExcluded];
    excludeHeadersMin = excludeHeaders? [...excludeHeadersMin, ...excludedHeadersArr] : excludeHeadersMin;
    //['select','edit', 'photo','family','famStat', 'id', 'info', 'loginID', 'filledBy', 'filledDate','editedBy', 
    //'accesspermit', 'accessdenied']
    const newData = data.map((form, i)=>{return {...form, sn:i+1}});
    
    const filteredHeaders = Object.keys(formFlds)
      .filter(key => !excludeHeadersMin.includes(key))
      .reduce((obj, key) => {
        obj[key] = formFlds[key];
        return obj;
      }, {});

    const headerType = allFields? formFlds : filteredHeaders; 

    let formHeader = Object.keys(headerType);
    const headers = formHeader.map((item, i)=>{
      return headerType[item].header
    });

    const state = {
      dataTitle1:['The Gospel Faith Mission International'],
      dataTitle2:['Pacesetters Assembly, District Headquarters'],
      dataTitle3:['7 Odekunle Street, Behind County Hospital, Aguda, Ogba, Lagos'],
      dataTitle4:[`${dataTitle? dataTitle : 'Members'} Data`],
      dataHeader: ["S/N", "Question", "Option 1", "Option 2", "Option 3", "Option 4"],
      dataHeaderObj: {sn:"S/N", ques:"Question",opt1: "Option 1", opt2:"Option 2", opt3:"Option 3", opt4:"Option 4"},
      datas: [
        { sn: 1, ques: "Which animal is known as the 'Ship of the Desert'?", opt1: "Camel",opt2: "Elephant",opt3: "Horse",opt4: "Lion", },
        { sn: 2, ques: "How many days are there in a week?", opt1: "7",opt2: "4",opt3: "14",opt4: "10", },
        { sn: 3, ques: "The first man created on Earth by God was?", opt1: "Adam",opt2: "Eve",opt3: "Satan",opt4: "Abraham", },
        { sn: 4, ques: "Nigeria got independence on what year?", opt1: "1960",opt2: "1963",opt3: "1950",opt4: "1958", },
        { sn: 5, ques: "How many letters are there in the English alphabet?", opt1: "26",opt2: "23",opt3: "27",opt4: "25", },
        { sn: 6, ques: "Rainbow consist of how many colours?", opt1: "7 Colors",opt2: "9 Colors",opt3: "12 Colors",opt4: "5 Colors", },
        { sn: 7, ques: "Baby frog is known as.......", opt1: "Tadpole",opt2: "Tappole",opt3: "Lavar",opt4: "kitten", },
        { sn: 8, ques: "How many vowels are there in the English alphabet?", opt1: "5",opt2: "7",opt3: "6",opt4: "4", },
        { sn: 9, ques: "How many continents are there in the world?", opt1: "7",opt2: "8",opt3: "9",opt4: "6", },
        { sn: 10, ques: "Which is the largest planet in the world?", opt1: "Jupiter",opt2: "Mars",opt3: "Earth",opt4: "Mercury", },   
    ],
    };
  
  //const header = data.
  function download() {
        const fmt = format === 'text'? 'txt' : 'xlsx';
        const aoas = [state.dataTitle1, state.dataTitle2, state.dataTitle3,state.dataTitle4,headers];
        for (let i = 0; i < newData.length; i++) {
          //const arr = Object.values(data[i]);
          const arrs =[];
          for(const prop in headerType){
            arrs.push(newData[i][prop])
          }
          aoas.push(arrs);
        }
        const workSheet = XLSX.utils.aoa_to_sheet(aoas);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "MembersData");
        XLSX.writeFile(workBook, `.GOFAMINTPS/MembersData.${fmt}`); 
  }

    return (
        <div className={className}
            onClick={download}
          >{title? title : "Export to Excel"}
        </div>
	  )
}

export default ExportToExcel