import React from 'react';


export const TableTemplate = ({tableHeader, tableBody, dataKeys, }) => {
    
    //w-[95vw] sm:w-[95vw] 
      return (
        <div className="max-h-[500px] overflow-auto border-2 border-solid resize-y">
        <table className="border-spacing-0 p-0 border-[1px] text-[11px] sm:text-[13px] border-solid border-[blue]" width="700px">
            <thead>
                <tr className='min-h-[25px] even:bg-[#dfdfdf]'>
                    <th 
                        className={`min-w-[30px] w-[30px] sm:min-w-[40px] sm:w-[40px] left-0 bg-[#333] ${zIndex} ${frozenCols}`}>
                        {'SN'}
                    </th>
                    <th  
                        className={`min-w-[70px] w-[70px] sm:min-w-[100px] sm:w-[100px] ${zIndex} 
                            left-[30px] sm:left-[40px] bg-[#333] cursor-pointer ${frozenCols}`}
                         >
                        {tableHeader[0]}
                    </th>
                    {
                        tableHeader?.slice(1).map((keys, i)=>{
                            const isProgram = keys.includes('|');
                            const header = isProgram? keys.split('|') : keys; 
                            return(
                                <th key={`${i}+header`}
                                    className={`whitespace-nowrap
                                    ${frozenCols} ${keys==="Edit" || keys==="Select"? 'min-w-[50px] bg-[#280184]' : 'min-w-[100px] bg-[#555]'}`}
                                    >
                                    {isProgram? 
                                        <div className='flex flex-col justify-center items-center flex-wrap'>
                                            <span>{header[0]}</span>
                                            <span>{header[1]}</span>
                                        </div> 
                                        :header}
                                </th>
                            );
                        })
                    }
                    
                </tr>
            </thead>
            <tbody>
                {
                    tableBody?.map((body, index)=>{
                        const even = index%2 === 1? true : false;
                        const rows = `p-0 px-[5px] outline-[1px] outline border-none outline-offset-[-1px] outline-[dodgerblue] whitespace-nowrap`;
                        const frozenCol = `
                             ${rows} ${even? 'bg-[silver]' : "bg-[aliceblue]"} 
                             ${body.newPayer? 'text-[red]': 'text-black'}
                            `;
                        const frozenSn = `
                            ${rows} ${even? 'bg-[silver]' : "bg-[aliceblue]"}
                            ${"text-[blue]"} 
                           `;
                        

                        return(
                            <tr className="min-h-[25px] h-[25px] even:bg-[#dfdfdf] " key={`${index}+tab`}>
                             <td className={`left-0 ${frozenSn} sticky  cursor-pointer font-bold
                                `}>{index+1}</td>
                                <td className={`left-[30px] sm:left-[40px] sticky ${frozenCol}`} >
                                    {body[dataKeys[0]]}</td>   
                                {
                                    dataKeys?.slice(1).map((keys, idx)=>{
                                        return(
                                            <td key={`${idx}+header`}
                                                className={`${rows} ${body.newPayer? 'text-[red]': ''}`}>
                                                {body[keys]}
                                            </td>
                                        );
                                    })
                                }           
                                
                            </tr>
                        );
                    })
                }
            </tbody>

        </table>
    </div>
  )
};


export default TableTemplate;



const frozenCols = `sticky  top-0  text-white  min-h-[30px] h-[30px] 
        text-left px-[5px]  
        p-0 outline-[2px] outline-[dodgerblue] outline border-none outline-offset-[-1px]`;
    const hiddenFlds = `whitespace-nowrap  min-w-[100px] bg-[navy] ${frozenCols}`;
    const zIndex = 'z-[10]';
