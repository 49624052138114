import newness1 from './newness2021-1.jpg';
import newness2 from './newness2021-2.jpg';
import newness3 from './newness2021-3.jpg';
import newness4 from './newness2021-4.jpg';
import newness5 from './newness2021-5.jpg';
import newness6 from './newness2021-6.jpg';
import newness7 from './newness2021-7.jpg';
import newness8 from './newness2021-8.jpg';
import newness9 from './newness2021-9.jpg';
import newness10 from './newness2021-10.jpg';
import newness11 from './newness2021-11.jpg';
import newness12 from './newness2021-12.jpg';
import newness13 from './newness2021-13.jpg';
import newness14 from './newness2021-14.jpg';
import newness15 from './newness2021-15.jpg';
import newness16 from './newness2021-16.jpg';
import newness17 from './newness2021-17.jpg';
import newness18 from './newness2021-18.jpg';
import newness19 from './newness2021-19.jpg';




const newness2021 = [
      {sn:1, src:newness1,title:'Newness 2021 -Maiden Edition', desc:'Maiden Edition of Newness held on 29 Aug., 2021', 
        bCol:'magenta', date:'2021-08-29'},
      {sn:2, src:newness2,},{sn:3, src:newness3,},{sn:4, src:newness4,},
      {sn:5, src:newness5,},{sn:6, src:newness6,},{sn:7, src:newness7,},
      {sn:8, src:newness8,},{sn:9, src:newness9,},{sn:10, src:newness10,},
      {sn:11, src:newness11,},{sn:12, src:newness12,},{sn:13, src:newness13,},
      {sn:14, src:newness14,},{sn:15, src:newness15,},{sn:16, src:newness16,},
      {sn:17, src:newness17,},{sn:18, src:newness18,},{sn:19, src:newness19,},
    ];
    

export default newness2021




























